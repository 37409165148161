import { Stack } from '@mui/material';
import { FC, memo, useEffect, useMemo } from 'react';
import { BluDialog, BluLoader } from '@bludata/components';
import PointOfSale_interface from '../../../nuova-point-of-sales/interfaces/PointOfSale_interface';
import { Buttons } from './features/buttons/Buttons';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../store';
import { Upload } from './features/upload/Upload';
import { ImportConfirmation } from './features/confirmation/confirmation';
import { UploadError } from './features/uploadError/UploadError';
import { setPointOfSaleId } from '../../store/import';
import { ImportInit } from './features/init/Init';

interface ImportPopUpInterface {
  isSingleCalendarManagement: boolean;
  pointOfSale?: string;
  pointOfSales?: PointOfSale_interface[];
}
export const ImportPopUp: FC<ImportPopUpInterface> = memo(
  ({ isSingleCalendarManagement, pointOfSale, pointOfSales }) => {
    const { step, loading, open, documentFormat } = useSelector(
      (state: RootState) => state.rotaImport
    );

    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
      dispatch(setPointOfSaleId(pointOfSale ?? ''));
    }, [dispatch, pointOfSale]);

    const GetContent = useMemo(() => {
      if (step === 'init') {
        return <ImportInit pointOfSales={pointOfSales!!} />;
      } else if (step === 'import') {
        return (
          <Upload
            isSingleCalendarManagement={isSingleCalendarManagement}
            documentFormat={documentFormat}
          />
        );
      }

      if (step === 'confirm') {
        return <ImportConfirmation />;
      }
      return <UploadError />;
    }, [documentFormat, isSingleCalendarManagement, pointOfSales, step]);

    return (
      <BluDialog
        className="service-popup"
        open={open}
        maxWidth="xs"
        zIndex={200}
        fullScreen={false}
        draggable
        dialogTitle={
          step !== 'confirm' && step !== 'error'
            ? 'Importazione calendario'
            : undefined
        }
        dialogContent={
          <Stack sx={{ p: '5px 20px', height: 'fit-content' }} gap={1}>
            <BluLoader open={loading} sx={{ zIndex: '1400' }} />
            {GetContent}
          </Stack>
        }
        sx={{
          '& .MuiDialogContent-root': {
            pb: '0px !important',
            p: 0,
            height: 'fit-content',
          },
          '& .MuiDialog-paper': {
            height: 'fit-content !important',
            minHeight: 'unset !important',
          },
          zIndex: 200,
        }}
        dialogActions={
          <Buttons
            pointOfSaleId={pointOfSale}
            documentFormat={documentFormat}
          />
        }
      />
    );
  }
);
