/* eslint-disable @typescript-eslint/no-explicit-any */
import { Typography } from '@mui/material';
import {
  ScheduleComponent,
  Inject,
  ViewDirective,
  ViewsDirective,
  RenderCellEventArgs,
  ResourceDirective,
  ResourcesDirective,
  Resize,
  DragAndDrop,
  Day,
  TimelineViews,
  View,
} from '@syncfusion/ej2-react-schedule';
import { useDispatch, useSelector } from 'react-redux';
import { memo, useCallback, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import { StudioInterface } from 'packages/blu-booking/src/interfaces/Studio';
import { RootState } from 'packages/blu-booking/src/store';
import { getConfig } from '../../../utility_objects/WhiteCache';
import {
  AppointmentMouseDown,
  AppointmentMouseUp,
} from '../../../utility_objects/AppointmentMouseUp';
import { CalendarEventTemplate } from '../../../UI/CalendarEventTemplate/CalendarEventTemplate';
import { CalendarEventQuickInfo } from '../../../UI/CalendarEventQuickInfo/CalendarEventQuickInfo';
import { AppointmentRender } from '../../../utility_objects/AppointmentRender';
import { AppointmentResize } from '../../../utility_objects/AppointmentResize';
import { AppointmentDragDrop } from '../../../utility_objects/AppointmentDragDrop';
import { appointmentFormPopupOpen } from '../../../utility_objects/AppointmentFormPopupOpen';
import { StudioCalendarSetSelectedDate } from '../store';

interface SchedulerProps {
  calendarPointOfSale: any;
  shiftPointOfSale: any;
  studi: StudioInterface[];
}

export const Scheduler = memo(
  ({ calendarPointOfSale, shiftPointOfSale, studi }: SchedulerProps) => {
    const { appointments } = useSelector(
      (state: RootState) => state.bluBookingaAppointments
    );
    const {
      selectedDate,
      selectedPointOfSale,
      selectedUsers,
      selectedServices,
      zoom,
      view,
    } = useSelector((state: RootState) => state.bluBookingStudioCalendar);
    const dispatch = useDispatch();

    // const { studi } = useSelector((state: RootState) => state.filters);
    const { sessionProfilo, focus10 } = getConfig();
    const scheduleObj = useRef<ScheduleComponent>(null);

    useEffect(() => {
      const { underline } = getConfig();

      if (
        scheduleObj?.current &&
        dayjs().isSame(dayjs(selectedDate), 'day') &&
        underline === '' &&
        !focus10
      ) {
        try {
          scheduleObj?.current.scrollTo(
            dayjs().subtract(1, 'hour').minute(0).format('HH:mm')
          );
        } catch (error) {
          /* empty */
        }
      }
    }, [
      focus10,
      selectedDate,
      view,
      calendarPointOfSale,
      shiftPointOfSale,
      studi,
    ]);

    const onActionComplete = (args) => {
      if (args.requestType === 'dateNavigate') {
        const schedulerInstance = scheduleObj.current;
        const newDate = schedulerInstance.selectedDate; // Ottieni la nuova data dal componente
        dispatch(StudioCalendarSetSelectedDate(newDate));
      }
    };

    function dateHeaderTemplate(props: any) {
      const date: string = dayjs(props.date).format('ddd D MMMM YYYY');
      return (
        <div
          style={{
            position: 'fixed',
            width: '100%',
            marginTop: '-10px',
          }}
        >
          <div
            style={{
              // position: "fixed",
              width: 'fit-content',
              fontWeight: 'bold',
              paddingLeft: '10px',
            }}
          >
            <Typography
              fontSize="14px"
              fontWeight="500"
              sx={{ color: 'unset' }}
            >{`${date.charAt(0).toUpperCase()}${date.slice(1)}`}</Typography>
          </div>
        </div>
      );
    }

    const getOpen = useCallback(
      (data: Date | undefined, type?: string) => {
        if (!calendarPointOfSale && !shiftPointOfSale) return 0;

        const check = (calendar: any) =>
          calendar &&
          ((time >= calendar.startTime1 && time < calendar.endTime1) ||
            (time >= calendar.startTime2 && time < calendar.endTime2));
        const time = dayjs(data).format('HH:mm:ss');
        const date = dayjs(data).format('YYYY-MM-DD');
        const calendarPointOfSaleWorkHours =
          calendarPointOfSale && date in calendarPointOfSale
            ? calendarPointOfSale[date]
            : undefined;
        const shiftPointOfSaleWorkHours =
          shiftPointOfSale && date in shiftPointOfSale
            ? shiftPointOfSale[date]
            : undefined;

        // ? CHECK DAY OPEN
        if (type && type === 'monthCells') {
          if (calendarPointOfSaleWorkHours) return 2;
          else if (shiftPointOfSale) return 1;
          else return 0;
        }

        const checkCalendar = check(calendarPointOfSaleWorkHours);
        const checkShift = check(shiftPointOfSaleWorkHours);
        if (checkCalendar) {
          return 2;
        } else if (checkShift) return 1;
        return 0;
      },
      [calendarPointOfSale, shiftPointOfSale]
    );

    const getWorkDaysStartEndHours = useCallback(
      (args: RenderCellEventArgs) => {
        if (
          args.elementType === 'workCells' ||
          args.elementType === 'monthCells'
        ) {
          const studiList = Object.values(studi).filter(
            (studio: any) => studio.pointOfSaleId === selectedPointOfSale?.id
          );
          if (args?.groupIndex !== undefined) {
            args.element.setAttribute(
              'studioid',
              studiList[args.groupIndex]?.id
            );
          }
          args.element.id =
            args.date !== null
              ? dayjs(args.date).format('DD/MM/YYYY HH:mm') ?? ''
              : '';
          args.element.addEventListener('mousedown', AppointmentMouseDown, {
            capture: true,
          });
          args.element.addEventListener(
            'mouseup',
            (event: any) => AppointmentMouseUp(event, 'studio'),
            {
              capture: true,
            }
          );
          const result = getOpen(args.date, args.elementType);
          if (result === 0)
            (args.element as any).style.backgroundColor = 'var(--closed-color)';
          else if (result === 1)
            (args.element as any).style.backgroundColor =
              'var(--alt-background-color)';
          else
            (args.element as any).className =
              'e-work-cells e-alternate-cells e-work-hours';
        } else if (args.elementType === 'majorSlot') {
          //? TimeSlot
          args.element.setAttribute(
            'style',
            'font-size:14px; font-weight: 500;'
          );
        }
      },
      [getOpen, selectedPointOfSale?.id, studi]
    );

    const getZoom = useCallback(() => {
      return {
        enable: true,
        interval: zoom?.interval,
        slotCount: zoom?.slotCount,
      };
    }, [zoom]);

    const getModules = useCallback(() => {
      if (!sessionProfilo.agendaModifica) return [Day, TimelineViews];
      return [Day, DragAndDrop, Resize, TimelineViews];
    }, [sessionProfilo.agendaModifica]);

    return (
      <ScheduleComponent
        ref={scheduleObj}
        cssClass={`studio${
          view === 'Day' ? '-day' : ''
        }-schedule-cell-dimension`}
        width="100%"
        height="100%"
        timeScale={getZoom()}
        selectedDate={selectedDate}
        currentView={view as View}
        dateFormat="dd/MM/yyyy"
        locale="it"
        minDate={new Date(2000, 0, 1)}
        actionComplete={onActionComplete}
        eventSettings={{
          dataSource: appointments,
          template: (props: any) => <CalendarEventTemplate {...props} />,
        }}
        popupOpen={(args) =>
          appointmentFormPopupOpen(
            args,
            selectedUsers,
            [args.data.studioId],
            selectedServices,
            selectedPointOfSale?.id
          )
        }
        dragStop={(args: any) =>
          AppointmentDragDrop(args, appointments, 'studi')
        }
        resizeStop={(args: any) => AppointmentResize(args, appointments)}
        firstDayOfWeek={1}
        renderCell={getWorkDaysStartEndHours}
        startHour="05:00"
        eventRendered={(args) => AppointmentRender(args)}
        // workHours={{ start: "00:00", end: "23:59", highlight: true }}
        dateHeaderTemplate={dateHeaderTemplate}
        group={{ byDate: true, resources: ['Studio'] }}
        resourceHeaderTemplate={(props: any) => {
          return (
            <div className="template-wrap">
              <div className="resource-detail">
                <div
                  className="resource-name"
                  style={{ height: 'fit-content', paddingLeft: '10px' }}
                >
                  {props.resourceData.name}
                </div>
              </div>
            </div>
          );
        }}
        quickInfoTemplates={
          {
            header: () => null,
            content: (props: any) => <CalendarEventQuickInfo {...props} />,
            // footer: footerTemplate,
          } as any
        }
      >
        <ResourcesDirective>
          <ResourceDirective
            field="studioId"
            title="Studi"
            name="Studio"
            allowMultiple={true}
            dataSource={studi}
            textField="name"
            idField="studioId"
            colorField="color"
            workDaysField="workDays"
            startHourField="startHour"
            endHourField="endHour"
          />
        </ResourcesDirective>
        <ViewsDirective>
          <ViewDirective option="Day" />
          <ViewDirective option="TimelineDay" />
        </ViewsDirective>
        <Inject services={getModules()} />
      </ScheduleComponent>
    );
  }
);
