import { SessionProfiloInterface } from '../interfaces/SessionProfilo';

interface BluBookingConfigInterface {
  getClient?: any;
  underline: string;
  underlineHour: string;
  isBluBookingActive: boolean;
  firstLoad: boolean;
  sessionProfilo: SessionProfiloInterface;
  selezioneCliente?: (codiceCliente: string) => string;
  token?: string;
  url?: string;
  darkMode?: string;
  maxBookingValue?: number;
  focusWeb?: boolean;
  focus10?: boolean;
  focusCloud?: boolean;
  manageOpenSearch: boolean;
}

const defaultConfig: BluBookingConfigInterface = {
  token: '',
  url: '',
  darkMode: 'light',
  maxBookingValue: 30,
  focusWeb: false,
  focus10: false,
  focusCloud: false,
  underline: '',
  underlineHour: '07:00',
  isBluBookingActive: false,
  firstLoad: false,
  sessionProfilo: {
    agendaInserimento: true,
    agendaModifica: true,
    agendaCancellazione: true,
    turniInserimento: true,
    turniModifica: true,
    turniCancellazione: true,
  },
  manageOpenSearch: false,
};

let bluBookingConfig: BluBookingConfigInterface = {
  ...defaultConfig,
};

export const getConfig = () => bluBookingConfig;
export const setConfig = (
  key: keyof BluBookingConfigInterface,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any | undefined
) => {
  bluBookingConfig = {
    ...bluBookingConfig,
    [key]: value ?? undefined,
  };
};

export const resetConfig = () => {
  bluBookingConfig = {
    ...defaultConfig,
  };
};
