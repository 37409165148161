/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { ShopSignLinkInterface } from '../../interfaces/ShopSignLink';

export const updateShopSignLink = async (
  data: ShopSignLinkInterface
): Promise<string | undefined> => {
  try {
    const params: any = {};
    const keys = [
      'shopSignId',
      'pointOfSaleId',
      'userId',
      'serviceId',
      'studioId',
      // "name",
      // "surname",
      // "email",
      // "telephone",
      // "externalCustomerCode",
      'description',
    ];
    for (const key of keys) {
      if ((data as any)[key] && (data as any)[key] !== '')
        params[key] = (data as any)[key];
    }
    await axios.put(`/api/ShopSignLink/${data?.id}`, params);
    return data?.id;
  } catch (err) {
    console.trace(err);
    return '';
  }
};
