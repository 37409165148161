import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../store';

export interface RotaRootSliceState {
  pointOfSaleId?: string;
  pointOfSaleName?: string;
  selectedDate: Date;
  turniFilter?: 'calendar' | 'shift';
  view: 'month' | 'day';
  fetchPointOfSales: boolean;
  isLoading: {
    pointOfSales: boolean;
    turniPointOfSale: boolean;
    turniUsers: boolean;
  };
  popUpRota?: {
    id: string;
    userId?: string;
    userName?: string;
    calendarType: 'pointOfSale' | 'user';
    date: Date;
    calendar: {
      extraId?: string;
      isClosed: boolean;
      isExtra: boolean;
      isHoliday: boolean;
      startTime1: string | null;
      startTime2: string | null;
      endTime1: string | null;
      endTime2: string | null;
    };
    shift: {
      extraId?: string;
      isClosed: boolean;
      isExtra: boolean;
      isHoliday: boolean;
      startTime1: string | null;
      startTime2: string | null;
      endTime1: string | null;
      endTime2: string | null;
    };
    defaultShift?: {
      startTime1?: string;
      startTime2?: string;
      endTime1?: string;
      endTime2?: string;
    };
    defaultCalendar?: {
      startTime1?: string;
      startTime2?: string;
      endTime1?: string;
      endTime2?: string;
    };
  };
  turnoCopiato?: {
    startTime1?: string;
    startTime2?: string;
    endTime1?: string;
    endTime2?: string;
  };
}

const initialState: RotaRootSliceState = {
  selectedDate: new Date(),
  fetchPointOfSales: false,
  view: 'month',
  isLoading: {
    pointOfSales: false,
    turniPointOfSale: false,
    turniUsers: false,
  },
  // turniFilter: "calendar",
};

const RotaRootSlice = createSlice({
  name: 'RotaRoot',
  initialState,
  reducers: {
    setPointOfSaleId(
      state: RotaRootSliceState,
      action: PayloadAction<string | undefined>
    ) {
      state.pointOfSaleId = action.payload;
    },
    setRotaView(
      state: RotaRootSliceState,
      action: PayloadAction<'month' | 'day'>
    ) {
      state.view = action.payload;
    },
    setFetchPointOfSale(
      state: RotaRootSliceState,
      action: PayloadAction<boolean>
    ) {
      state.fetchPointOfSales = action.payload;
    },
    setPointOfSaleName(
      state: RotaRootSliceState,
      action: PayloadAction<string | undefined>
    ) {
      state.pointOfSaleName = action.payload;
    },
    setSelectedDate(state: RotaRootSliceState, action: PayloadAction<Date>) {
      state.selectedDate = action.payload;
    },
    setIsLoading(
      state: RotaRootSliceState,
      action: PayloadAction<{
        key: 'turniPointOfSale' | 'turniUsers' | 'pointOfSales';
        value: boolean;
      }>
    ) {
      (state.isLoading as any)[action.payload.key] = action.payload.value;
    },
    setTurniFilter(
      state: RotaRootSliceState,
      action: PayloadAction<'calendar' | 'shift' | undefined>
    ) {
      state.turniFilter = action.payload;
    },
    setPopUpRota(
      state: RotaRootSliceState,
      action: PayloadAction<
        | {
            id: string;
            userId?: string;
            userName?: string;
            calendarType: 'pointOfSale' | 'user';
            date: Date;
            calendar: {
              extraId?: string;
              isClosed: boolean;
              isExtra: boolean;
              isHoliday: boolean;
              extraCalendarType?: number;
              startTime1: string | null;
              startTime2: string | null;
              endTime1: string | null;
              endTime2: string | null;
            };
            shift: {
              extraId?: string;
              isClosed: boolean;
              isExtra: boolean;
              isHoliday: boolean;
              extraCalendarType?: number;
              startTime1: string | null;
              startTime2: string | null;
              endTime1: string | null;
              endTime2: string | null;
            };
            defaultShift?: {
              startTime1?: string;
              startTime2?: string;
              endTime1?: string;
              endTime2?: string;
            };
            defaultCalendar?: {
              startTime1?: string;
              startTime2?: string;
              endTime1?: string;
              endTime2?: string;
            };
          }
        | undefined
      >
    ) {
      state.popUpRota = action.payload;
    },

    setTurnoCopiato(
      state: RotaRootSliceState,
      action: PayloadAction<
        | {
            startTime1?: string;
            startTime2?: string;
            endTime1?: string;
            endTime2?: string;
          }
        | undefined
      >
    ) {
      state.turnoCopiato = action.payload;
    },
  },
});

export const isTurniLoading = (state: RootState): boolean =>
  state.rotaRoot.isLoading.turniPointOfSale ||
  state.rotaRoot.isLoading.turniUsers ||
  state.rotaRoot.isLoading.pointOfSales;

export const {
  setPointOfSaleId,
  setPointOfSaleName,
  setFetchPointOfSale,
  setSelectedDate,
  setIsLoading,
  setTurniFilter,
  setPopUpRota,
  setRotaView,
  setTurnoCopiato,
} = RotaRootSlice.actions;

export default RotaRootSlice.reducer;
