import {
  FC,
  memo,
  useCallback,
  useDeferredValue,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHolidays } from '../../data_fetching/Holidays/Holidays';
import {
  BluAsyncDialog,
  BluContainerBlock,
  BluLoader,
  BluTitle,
} from '@bludata/components';
import { Button, Card, Stack, Typography } from '@mui/material';
import { Holidays } from './UI/Holidays/Holidays';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import SaveIcon from '@mui/icons-material/Save';
import { AppDispatch, RootState } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import {
  setCompanySettings,
  setMode,
  setPopUpCalendarManagement,
} from './store/GeneralRoot';
import { companySettingsManagement } from '../../data_fetching/CompanySettings/CompanySettingsManagement';
import { resetAndChangeHolidays } from './store/Holiday';
import {
  getInitialCompanySettings,
  getInitialHolidays,
  setInitialCompanySettings,
  setInitialHolidays,
} from './utility_objects/white-cache';
import { useCompanySettings } from '../../data_fetching/CompanySettings/CompanySettings';
import { GeneraliCompanySettings } from './UI/CompanySettings/CompanySettings';
import { PopUpCalendarManagement } from './UI/PopUpCalendarManagement';

interface GeneralInnerInterface {
  software: string;
  refreshToken?: () => void;
}

export const GeneralInner: FC<GeneralInnerInterface> = memo(
  ({ software, refreshToken }) => {
    const dispatch = useDispatch<AppDispatch>();
    const queryClient = useQueryClient();
    const [loading, setLoading] = useState(false);
    const {
      mode,
      popUpCalendarManagement,
      isSingleCalendarManagement,
      companyColorEnum,
      visibilityEnum,
      isManualAppointmentBlock,
    } = useSelector((state: RootState) => state.bluBookingGeneral);

    const { holidays, DeleteHolidays, ModifyHolidays } = useSelector(
      (state: RootState) => state.bluBookingHoliday
    );

    const { data: holidaysResult, isLoading: holidayIsLoading } = useHolidays();
    const holidaysList = useDeferredValue(holidaysResult);

    const { data: companyDetails, isLoading: companyIsLoading } =
      useCompanySettings();
    const company = useDeferredValue(companyDetails);

    useEffect(() => {
      if (holidaysList) {
        dispatch(resetAndChangeHolidays(holidaysList));
        setInitialHolidays(holidaysList);
      }
      if (company) {
        dispatch(setCompanySettings(company));
        setInitialCompanySettings(company);
      }
    }, [company, dispatch, holidaysList]);

    const handleOnSave = useCallback(async () => {
      try {
        setLoading(true);
        const { isSingleCalendarManagement: oldIsSingleCalendarManagement } =
          getInitialCompanySettings();
        if (!oldIsSingleCalendarManagement && isSingleCalendarManagement) {
          setLoading(false);
          dispatch(setPopUpCalendarManagement(true));
          return;
        }

        const result = await companySettingsManagement({
          companySettings: {
            isSingleCalendarManagement,
            companyColorEnum,
            visibilityEnum,
            isManualAppointmentBlock,
          },
          holidays,
          DeleteHolidays,
          ModifyHolidays,
        });

        setLoading(false);
        try {
          (window as any).disableMenuBluBooking(false);
        } catch {
          /* empty */
        }
        dispatch(setMode('view'));
        if (result) {
          queryClient.invalidateQueries(['holidays']);
          queryClient.removeQueries(['holidays'], {
            type: 'inactive',
          });
          queryClient.invalidateQueries(['company-settings']);
          queryClient.removeQueries(['company-settings'], {
            type: 'inactive',
          });

          queryClient.invalidateQueries(['user', 'me', 'LoginData']);
          queryClient.removeQueries(['user', 'me', 'LoginData'], {
            type: 'inactive',
          });

          if (software === 'f10' || software === 'fw') {
            BluAsyncDialog({
              title: 'Attenzione',
              type: 'info',
              message: 'Per vedere le modifiche devi rifare il login.',
              sx: { '& .MuiDialog-paper': { maxWidth: '370px' } },
              hideDeclineButton: true,
              confimButton: 'Chiudi',
            });
          }
          if (software === 'bb') refreshToken();

          return;
        }
      } catch (e) {
        console.error(e);
      }
      BluAsyncDialog({
        title: 'Attenzione',
        type: 'error',
        message: 'Errore durante la modifica delle impostazioni.',
        sx: { '& .MuiDialog-paper': { maxWidth: '370px' } },
        hideDeclineButton: true,
        confimButton: 'Chiudi',
      });

      return;
    }, [
      DeleteHolidays,
      ModifyHolidays,
      companyColorEnum,
      dispatch,
      holidays,
      isManualAppointmentBlock,
      isSingleCalendarManagement,
      queryClient,
      refreshToken,
      software,
      visibilityEnum,
    ]);

    const handleCancel = useCallback(() => {
      dispatch(setMode('view'));
      dispatch(resetAndChangeHolidays(getInitialHolidays()));
      dispatch(setCompanySettings(getInitialCompanySettings()));
      try {
        (window as any).disableMenuBluBooking(false);
      } catch {
        /* empty */
      }
    }, [dispatch]);

    const handleModify = useCallback(() => {
      dispatch(setMode('edit'));
      try {
        (window as any).disableMenuBluBooking(true);
      } catch {
        /* empty */
      }
    }, [dispatch]);

    const GetPopUp = useMemo(() => {
      if (popUpCalendarManagement) return <PopUpCalendarManagement />;
      return null;
    }, [popUpCalendarManagement]);

    const GetButtons = useMemo(() => {
      if (mode === 'view')
        return (
          <Button
            variant="outlined"
            startIcon={<ModeEditIcon />}
            onClick={handleModify}
            sx={{ width: 'fit-content' }}
          >
            Modifica
          </Button>
        );
      return (
        <>
          <Button
            variant="outlined"
            onClick={handleCancel}
            sx={{ width: 'fit-content' }}
          >
            Annulla
          </Button>
          <Button
            startIcon={<SaveIcon />}
            variant="contained"
            onClick={handleOnSave}
          >
            Salva
          </Button>
        </>
      );
    }, [handleCancel, handleModify, handleOnSave, mode]);

    return (
      <BluContainerBlock
        sx={{
          m: '4px',
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          overflow: 'hidden',
        }}
      >
        {GetPopUp}
        <BluLoader open={loading || holidayIsLoading || companyIsLoading} />
        <Stack direction="column" spacing={1} sx={{ height: '100%' }}>
          <GeneraliCompanySettings />

          <BluTitle text={'Festività'} />
          <Typography>
            In questa sezione è possibile configurare le Festività
          </Typography>
          <Holidays />

          <Stack direction={'row'} spacing={0.5} justifyContent="end">
            {GetButtons}
          </Stack>
        </Stack>
      </BluContainerBlock>
    );
  }
);
