import { Avatar, Button, Stack, Typography } from '@mui/material';
import { FC, memo, useEffect } from 'react';
import ImageDefault from '../../../../assets/Bludata-WhiteMode.svg';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { setFaviconFile, setLogoFile } from '../../lib/white-cache';
import { useFormContext, useWatch } from 'react-hook-form';
import { BluTooltip } from '@bludata/components';

interface IconInterface {
  disabled: boolean;
}

export const Icon: FC<IconInterface> = memo(({ disabled }) => {
  const [logo, favicon] = useWatch({ name: ['logo', 'favicon'] });
  const { setValue, trigger } = useFormContext();

  return (
    <Stack direction="column">
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        flex="1"
        sx={{ minWidth: 400 }}
      >
        <Avatar
          variant="square"
          sx={{ width: 256, height: 'auto', p: 3 }}
          src={logo ?? ImageDefault}
        />

        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          <Button
            color="primary"
            disabled={disabled}
            component="label"
            startIcon={<FileUploadIcon />}
            onClick={() => {}}
          >
            Carica
            <input
              accept="image/png, image/jpeg, image/jpg"
              type="file"
              hidden
              onChange={(e: any) => {
                const fileReader = new FileReader();
                fileReader.readAsDataURL(e.target.files[0]);
                fileReader.onloadend = async (event: any) => {
                  if (favicon === undefined) {
                    setLogoFile(e.target.files[0]);
                    setFaviconFile(e.target.files[0]);
                    setValue('favicon', event.target.result);
                    setValue('logo', event.target.result);
                    trigger(['favicon', 'logo']);
                    return;
                  }
                  setLogoFile(e.target.files[0]);
                  setValue('logo', event.target.result);
                  trigger('logo');
                };
              }}
            />
          </Button>
        </Stack>
      </Stack>

      <Stack
        sx={{ minWidth: 400 }}
        justifyContent="center"
        alignItems="center"
        gap={1}
      >
        <Stack justifyContent="center" alignItems="center">
          <Stack direction="row" justifyContent="center" alignItems="center">
            <Typography
              color="text.primary"
              sx={{ width: '145px', flex: 1, pl: '20px' }}
            >
              Favicon
            </Typography>
            <BluTooltip
              text={`Una favicon (abbreviazione di "favorite icon") è una piccola icona grafica associata a un sito web. Questa icona viene visualizzata nella scheda del browser accanto al titolo della pagina, nei segnalibri e negli elenchi dei preferiti, oltre a essere utilizzata in altre aree come la barra degli indirizzi. \nLa favicon aiuta a identificare visualmente un sito web, migliorando l'esperienza dell'utente e rendendo il sito facilmente riconoscibile.\n Se la Favicon non viene caricata al suo posto verrà utilizzato il logo.`}
              sx={{
                p: '2px',
                backgroundColor: '#f5f5f5', // Colore di sfondo predefinito
                '.dark-mode-bludata &': {
                  backgroundColor: '#404040', // Colore di sfondo in modalità dark
                },

                '& svg': { width: '15px!important', height: '15px!important' },
              }}
            />
          </Stack>
          <Typography
            sx={{
              fontSize: '13px',
              color: 'var(--disabled-color)',
              pl: '30px',
            }}
          >
            Dimensione ottimale 48x48px
          </Typography>
        </Stack>
        <Stack flex="1" flexDirection="row" gap="10px">
          <div
            style={{
              width: '32px',
              height: '32px',
              display: 'flex',
              justifyContent: 'center',
              border: '1px solid #d9d9d9',
            }}
          >
            {favicon && (
              <img
                style={{
                  width: '32px',
                  height: '32px',
                  margin: 'auto',
                }}
                alt="Favicon"
                src={favicon}
                // src="./src/assets/eye-svgrepo-com.svg"
                // data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTAiIGhlaWdodD0iNTAiIHZpZXdCb3g9IjAgMCA1MCA1MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTQxLjY2NjggMTIuNUwxOC43NTAyIDM1LjQxNjdMOC4zMzM1IDI1IiBzdHJva2U9IiMyMTk2NTMiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHJlY3QgeD0iMC41IiB5PSIwLjUiIHdpZHRoPSI0OSIgaGVpZ2h0PSI0OSIgcng9IjI0LjUiIHN0cm9rZT0iIzIxOTY1MyIvPgo8L3N2Zz4K
              />
            )}
          </div>

          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            <Button
              color="primary"
              disabled={disabled}
              component="label"
              startIcon={<FileUploadIcon />}
              onClick={() => {}}
            >
              Carica
              <input
                accept="image/png, image/jpeg, image/jpg, image/x-icon"
                // accept="image/*"
                type="file"
                hidden
                onChange={(e: any) => {
                  const fileReader = new FileReader();
                  fileReader.readAsDataURL(e.target.files[0]);
                  fileReader.onloadend = async (event: any) => {
                    setFaviconFile(e.target.files[0]);
                    setValue('favicon', event.target.result);
                    trigger('favicon');
                  };
                }}
              />
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
});
