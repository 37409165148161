import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import 'dayjs/locale/it';
import {
  getCookie,
  setCookie,
} from 'packages/blu-booking/src/utility_objects/CookieManager';
import {
  removePointOfSale,
  removeService,
  removeStudio,
  selectPointOfSale,
  selectService,
  selectStudio,
  selectZoomValue,
  setUser,
  setSelectedDate,
  setClient,
} from '../../../utility_objects/StoreFunctions';
import { StudiSelectedFiltersState } from './interface';

const initialState: StudiSelectedFiltersState = {
  view: getCookie('studioCalendarView') || 'Day',
  selectedDate: new Date(),
  selectedServices: [],
  selectedStudi: [],
  selectedUsers: [],
  zoom: {
    value: 150,
    interval: 60,
    slotCount: 4,
  },
  zoomValue: 150,
};

const StudioCalendarSlice = createSlice({
  name: 'BluBookingStudioCalendar',
  initialState,
  reducers: {
    StudioCalendarSetView: (
      state: StudiSelectedFiltersState,
      action: PayloadAction<'Day' | 'TimelineDay'>
    ) => {
      state.view = action.payload;
      setCookie('studioCalendarView', action.payload);
    },

    StudioCalendarSetSelectedDate: setSelectedDate,
    StudioCalendarSelectZoomValue: selectZoomValue,
    // ? ADD
    StudioCalendarSelectPointOfSale: selectPointOfSale,
    StudioCalendarSetService: selectService,
    StudioCalendarSelectStudio: selectStudio,
    StudioCalendarSetUser: setUser,
    StudioCalendarSetClient: setClient,
    // ? REMOVE
    StudioCalendarRemoveStudio: removeStudio,
    StudioCalendarRemoveService: removeService,
    StudioCalendarRemovePointOfSale: removePointOfSale,

    StudioCalendarResetSelected: (
      state: StudiSelectedFiltersState,
      action: PayloadAction<string>
    ) => {
      return { ...state, [action.payload]: [] };
    },
    StudioCalendarReset: () => initialState,
  },
});

export const {
  StudioCalendarSetView,
  StudioCalendarSetSelectedDate,
  StudioCalendarSelectZoomValue,
  StudioCalendarSelectPointOfSale,
  StudioCalendarSetService,
  StudioCalendarSelectStudio,
  StudioCalendarRemoveStudio,
  StudioCalendarRemoveService,
  StudioCalendarRemovePointOfSale,
  StudioCalendarResetSelected,
  StudioCalendarSetUser,
  StudioCalendarSetClient,
  StudioCalendarReset,
} = StudioCalendarSlice.actions;

export default StudioCalendarSlice.reducer;
