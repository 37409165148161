import dayjs from 'dayjs';
import { AppointmentCompleteInterface } from 'packages/blu-booking/src/interfaces/AppointmentComplete';
import { FC, memo, useCallback, useState } from 'react';
import {
  DataGridPremium,
  GridFooterContainer,
  GridRenderCellParams,
  GridValueFormatterParams,
} from '@mui/x-data-grid-premium';
import { appointmentStatus } from '../../../utility_objects/AppointmentStatus';
import {
  Stack,
  Typography,
  IconButton,
  Button,
  MenuItem,
  SvgIcon,
} from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { BluAsyncDialog, BluSelect } from '@bludata/components';
import { getStatusIcon } from '../../../utility_objects/StatusIcon';
import { nanoid } from '@reduxjs/toolkit';

interface AppointmentsNotConfirmGridInterface {
  appointments: AppointmentCompleteInterface[];
  pageNumber: number;
  totalPages: number;
  handlePageNumberChange: (value: number) => void;
  setSelectedAppointment: (value: AppointmentCompleteInterface) => void;
  setAppointment: (value: AppointmentCompleteInterface) => void;
}

export const AppointmentsNotConfirmGrid: FC<AppointmentsNotConfirmGridInterface> =
  memo(
    ({
      appointments,
      pageNumber,
      totalPages,
      handlePageNumberChange,
      setSelectedAppointment,
      setAppointment,
    }) => {
      const columns = [
        {
          field: 'id',
          hide: true,
        },

        {
          field: 'service',
          headerName: 'Tipo di appuntamento',
          flex: 1,
          minWidth: 120,
          editable: false,
          valueFormatter: (params: GridValueFormatterParams) =>
            params.value?.name,
        },
        {
          field: 'pointOfSale',
          headerName: 'Punto Vendita',
          flex: 1,
          minWidth: 100,
          editable: false,
          valueFormatter: (params: GridValueFormatterParams) =>
            params.value?.branch,
        },
        {
          field: 'username',
          headerName: 'Utente',
          width: 100,
          editable: false,
        },
        {
          field: 'studio',
          headerName: 'Studio',
          width: 100,
          editable: false,
          valueFormatter: (params: GridValueFormatterParams) =>
            params.value?.name,
        },
        {
          field: 'name',
          headerName: 'Nome',
          width: 100,
          editable: false,
        },
        {
          field: 'surname',
          headerName: 'Cognome',
          width: 100,
          editable: false,
        },
        {
          field: 'date',
          headerName: 'Data',
          width: 100,
          align: 'center',
          headerAlign: 'center',
          // valueFormatter: (params: GridValueFormatterParams) => {
          //   console.log(params.api.getRow);
          //   return dayjs(params?.value).format('DD/MM/YYYY');
          // },
          renderCell: (params: GridRenderCellParams) => {
            return dayjs(params?.row?.startTime).format('DD/MM/YYYY');
          },
        },
        {
          field: 'startTime',
          headerName: 'Ora inizio',
          width: 80,
          align: 'center',
          headerAlign: 'center',
          valueFormatter: (params: GridValueFormatterParams) =>
            dayjs(params?.value).format('HH:mm'),
        },
        {
          field: 'endTime',
          headerName: 'Ora fine',
          width: 80,
          align: 'center',
          headerAlign: 'center',
          valueFormatter: (params: GridValueFormatterParams) =>
            dayjs(params?.value).format('HH:mm'),
        },
        {
          field: 'status',
          headerName: '',
          align: 'center',
          headerAlign: 'center',
          width: 110,
          renderCell: (params: GridRenderCellParams) => (
            <Button
              sx={{ p: '0 8px' }}
              variant="contained"
              onClick={() => {
                setAppointment(params.row);
              }}
            >
              Conferma
            </Button>
            // <RenderStatus
            //   {...params}
            //   handleValidationChange={handleValidationChange}
            // />
          ),
        },
      ];

      return (
        <DataGridPremium
          sx={{
            minHeight: 454,
            '& .status': {
              padding: '2px',
            },
          }}
          columns={columns as any}
          rows={appointments ?? []}
          onRowClick={(event: any) => {
            setSelectedAppointment(event?.row);
            //   action(event?.row);
          }}
          components={{
            Footer: () => {
              return (
                <GridFooterContainer>
                  <Stack
                    flex="1"
                    direction="row"
                    gap={1}
                    justifyContent="flex-end"
                    sx={{ pr: 1 }}
                  >
                    {totalPages > 1 && (
                      <Stack direction="row" gap={1} alignItems="center">
                        <Typography>{`Pagina ${pageNumber} di ${
                          totalPages ?? 1
                        }`}</Typography>
                        <IconButton
                          disabled={pageNumber === 1}
                          onClick={() => handlePageNumberChange(pageNumber - 1)}
                        >
                          <KeyboardArrowLeftIcon />
                        </IconButton>
                        <IconButton
                          disabled={pageNumber >= totalPages}
                          onClick={() => handlePageNumberChange(pageNumber + 1)}
                        >
                          <KeyboardArrowRightIcon />
                        </IconButton>
                      </Stack>
                    )}
                  </Stack>
                </GridFooterContainer>
              );
            },
          }}
        />
      );
    }
  );

interface RenderStatusInterface extends GridRenderCellParams {
  handleValidationChange: (appointmentId: string, value: number) => void;
}

export const RenderStatus: FC<RenderStatusInterface> = memo(
  ({ row, value, handleValidationChange }) => {
    const [status, setStatus] = useState<number>(value ?? 0);

    const handleOnChange = useCallback(
      (event: any) => {
        setStatus(event.target.value);
        BluAsyncDialog({
          title: 'Attenzione!',
          message:
            "Sei sicuro di voler forzare la validazione dell'appuntamento?",
          confimButton: 'Si',
          declineButton: 'No',
          type: 'info',
          sx: { '& .MuiDialog-paper': { maxWidth: '370px' } },
          onConfirmClick: async () => {
            handleValidationChange(row?.id, event.target.value);
          },
        });
      },
      [handleValidationChange, row?.id]
    );

    return (
      <BluSelect
        label="Slot OK?"
        displayEmpty
        value={status}
        renderValue={(value: any) => (
          <Stack flex="1" direction="row" gap={2}>
            <SvgIcon
              component={getStatusIcon(value)}
              sx={{
                fontSize: '18px',
                pt: '2px',
                pb: '1px',
              }}
            />
            {appointmentStatus[value]}
          </Stack>
        )}
        InputProps={{
          autoComplete: 'new-password',
        }}
        onChange={handleOnChange}
      >
        {Object.keys(appointmentStatus).map((key: any) => {
          return (
            <MenuItem
              key={nanoid()}
              value={key}
              disabled={key === '0' && value !== 0}
            >
              <Stack flex="1" direction="row" gap={2}>
                <SvgIcon
                  component={getStatusIcon(key)}
                  sx={{
                    fontSize: '18px',
                    pt: '2px',
                    pb: '1px',
                  }}
                />
                {appointmentStatus[key]}
              </Stack>
            </MenuItem>
          );
        })}
      </BluSelect>
    );
  }
);
