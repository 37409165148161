/* eslint-disable @typescript-eslint/no-explicit-any */
import { BluSelect } from '@bludata/components';
import {
  ListItemText,
  MenuItem,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import React, { FC, useCallback } from 'react';
import { memo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Else, If, Then } from 'react-if';

interface ShopSignInterface {
  shopSigns: any;
}

export const ShopSign: FC<ShopSignInterface> = memo(({ shopSigns }) => {
  const shopSignId = useWatch({ name: 'shopSignId' });
  const { setValue, trigger } = useFormContext();

  const getData = useCallback(() => {
    return Object.values(shopSigns) ?? [];
  }, [shopSigns]);

  return (
    <Stack sx={{ width: '75%', pb: '10px' }}>
      <Typography variant="subtitle2">Layout</Typography>
      <BluSelect
        displayEmpty
        title="Layout"
        value={shopSignId}
        onChange={(event: SelectChangeEvent) => {
          setValue('shopSignId', event.target.value);
          trigger();
        }}
        renderValue={(value: any) => (
          <div style={{ paddingLeft: '7px' }}>
            <If condition={value === ''}>
              <Then>
                <div style={{ color: '#9a9a9a' }}>Seleziona un layout</div>
              </Then>
              <Else>{shopSigns[value]?.name}</Else>
            </If>
          </div>
        )}
        width="49%"
      >
        {getData().map(({ id, name }: any) => (
          <MenuItem key={nanoid()} value={id}>
            <ListItemText primary={name} />
          </MenuItem>
        ))}
        <MenuItem
          key={nanoid()}
          value={''}
          sx={{ height: '36px', display: 'none' }}
        ></MenuItem>
      </BluSelect>
    </Stack>
  );
});
