import { FC, memo } from 'react';
import { useSelector } from 'react-redux';
import { BluTextField } from '@bludata/components';
import { IconButton, InputAdornment, Stack, Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ShopSign } from './UI/ShopSign';
import { User } from './UI/User';
import { Service } from './UI/Service';
import { PointOfSale } from './UI/PointOfSale';
import { Studi } from './UI/Studio';
import { ShopSignLinkInterface } from 'packages/blu-booking/src/interfaces/ShopSignLink';
import { SearchPopUpForm } from './UI/SearchPopup/SearchPopup';
import { Note } from './UI/Note';
import { RootState } from 'packages/blu-booking/src/store';
import { ShopSignObject } from 'packages/blu-booking/src/interfaces/ShopSign';

interface CreateLinkPopupFormInterface {
  shopSigns: ShopSignObject;
  link?: ShopSignLinkInterface;
  setAlert: (message: string) => void;
  isFirst: boolean;
}

export const CreateLinkPopupForm: FC<CreateLinkPopupFormInterface> = memo(
  ({ shopSigns, link, setAlert, isFirst }) => {
    const { searchCreateLinkPopUp } = useSelector(
      (state: RootState) => state.bluBookingCreateLink
    );

    // const dispatch = useDispatch<AppDispatch>();

    // const { getClient, focusWeb } = getConfig();
    // const { reset, getValues, trigger } = useFormContext();

    // const client = async () => {
    //   const c = await getClient();
    //   if (c !== null) {
    //     reset({
    //       ...getValues(),
    //       ...c,
    //     });
    //     trigger();
    //   }
    // };

    return (
      <Stack flex="1" sx={{ overflowY: 'hidden', pb: 1 }}>
        {isFirst && (
          <Typography sx={{ pb: 1 }}>
            Puoi condividere il form di prenotazione in pagine web, email,
            social, etc. utilizzando il link che hai creato. Crea un link
            personalizzato, precompilato per il servizio che intendi proporre.
            In questo modo il cliente non dovrà inserire nel form di
            prenotazione tutte le informazioni, ma solo quelle che riterrai
            necessarie. Assegna una descrizione al link per poterlo
            identificare. Esempio di descrizione: “Controllo della vista”.
          </Typography>
        )}
        <form id="creationForm">
          {searchCreateLinkPopUp && <SearchPopUpForm />}
          <Note />

          <ShopSign shopSigns={shopSigns} />
          <Stack
            flex="1"
            flexDirection="column"
            sx={{
              border: '1px solid rgba(187, 187, 187, 1)',
              borderRadius: '4px',
              p: '10px',
            }}
          >
            <Stack
              gap={1}
              flexWrap="wrap"
              sx={{ width: '100%' }}
              flexDirection="unset"
            >
              <User />
              <Service />
            </Stack>
            <Stack
              gap={1}
              flexWrap="wrap"
              sx={{ pt: '10px', width: '100%' }}
              flexDirection="unset"
            >
              <PointOfSale />
              <Studi />
            </Stack>
          </Stack>
          {/* <Stack
      flex="1"
      flexDirection="column"
      sx={{
        p: '20px 10px 10px 10px',
      }}
    >
      <Stack flexDirection="row" gap="2%" sx={{ width: '100%' }}>
        <Surname />
        <Name />
        <IconButton
          sx={{ width: '40px', height: '40px', marginTop: 'auto' }}
          onClick={() =>
            focusWeb && getClient !== undefined
              ? client()
              : dispatch(setSearchCreateLinkPopUp(true))
          }
        >
          <SearchTwoToneIcon />
        </IconButton>
      </Stack>
      <Stack flexDirection="row" gap="2%" sx={{ pt: '10px', width: '100%' }}>
        <Telephone />
        <Email />
      </Stack> */}
          <Stack flex="1">
            {link?.shortLink && link?.shortLink !== '' && (
              <>
                <Typography variant="subtitle2" sx={{ p: '3px 0px' }}>
                  Link
                </Typography>
                <BluTextField
                  value={`${link?.urlLink ?? ''}${link?.shortLink ?? ''}`}
                  inputProps={{
                    readOnly: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          color="primary"
                          sx={{
                            right: 0,
                            position: 'absolute',
                          }}
                          size="small"
                          onClick={(e) => {
                            navigator.clipboard.writeText(
                              `${link?.urlLink ?? ''}${link?.shortLink ?? ''}`
                            );
                            setAlert('Link copiato');
                          }}
                        >
                          <ContentCopyIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </>
            )}
          </Stack>
        </form>
      </Stack>
    );
  }
);
