import dayjs from 'dayjs';
import { AppointmentCompleteInterface } from 'packages/blu-booking/src/interfaces/AppointmentComplete';
import { FC, memo, useCallback, useState } from 'react';
import {
  DataGridPremium,
  GridFooterContainer,
  GridRenderCellParams,
  GridValueFormatterParams,
} from '@mui/x-data-grid-premium';
import { slotOKStatus } from '../../../utility_objects/SlotOkStatus';
import { appointmentStatus } from '../../../utility_objects/AppointmentStatus';
import { Stack, Typography, IconButton, MenuItem, Button } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { BluAsyncDialog, BluSelect } from '@bludata/components';
import { nanoid } from '@reduxjs/toolkit';

interface AppointmentSlotNotOkGridInterface {
  appointments: AppointmentCompleteInterface[];
  pageNumber: number;
  totalPages: number;
  handlePageNumberChange: (value: number) => void;
  setSelectedAppointment: (value: AppointmentCompleteInterface) => void;
  handleValidationChange: (value: string) => void;
}

export const RenderStatusSlotOk: FC<GridRenderCellParams> = memo(
  ({ value }) => {
    const [statusSlotOk, setStatusSlotOk] = useState<number>(value ?? 1);

    const handleOnChange = useCallback((event: any) => {
      BluAsyncDialog({
        title: 'Attenzione!',
        message:
          "Sei sicuro di voler forzare la validazione dell'appuntamento?",
        confimButton: 'Si',
        declineButton: 'No',
        type: 'warning',
        sx: { '& .MuiDialog-paper': { maxWidth: '370px' } },
        onConfirmClick: () => {
          setStatusSlotOk(event.target.value);
        },
      });
    }, []);

    return (
      <BluSelect
        label="Slot OK?"
        displayEmpty
        value={statusSlotOk}
        renderValue={(selected) => {
          if (selected > 0 && selected < 3)
            // eslint-disable-next-line react/jsx-no-useless-fragment
            return <>{slotOKStatus[selected]}</>;

          // eslint-disable-next-line react/jsx-no-useless-fragment
          return <></>;
        }}
        InputProps={{
          autoComplete: 'new-password',
        }}
        onChange={handleOnChange}
      >
        <MenuItem key={nanoid()} value={1}>
          <Typography>{slotOKStatus[1]}</Typography>
        </MenuItem>
        <MenuItem key={nanoid()} value={2}>
          <Typography>{slotOKStatus[2]}</Typography>
        </MenuItem>
      </BluSelect>
    );
  }
);

export const AppointmentSlotNotOkGrid: FC<AppointmentSlotNotOkGridInterface> =
  memo(
    ({
      appointments,
      pageNumber,
      totalPages,
      handlePageNumberChange,
      setSelectedAppointment,
      handleValidationChange,
    }) => {
      const columns = [
        {
          field: 'id',
          hide: true,
        },

        {
          field: 'service',
          headerName: 'Tipo di appuntamento',
          flex: 1,
          minWidth: 120,
          editable: false,
          valueFormatter: (params: GridValueFormatterParams) =>
            params.value?.name,
        },
        {
          field: 'username',
          headerName: 'Utente',
          width: 100,
          editable: false,
        },
        {
          field: 'studio',
          headerName: 'Studio',
          width: 100,
          editable: false,
          valueFormatter: (params: GridValueFormatterParams) =>
            params.value?.name,
        },
        {
          field: 'name',
          headerName: 'Nome',
          width: 100,
          editable: false,
        },
        {
          field: 'surname',
          headerName: 'Cognome',
          width: 100,
          editable: false,
        },
        {
          field: 'date',
          headerName: 'Data',
          width: 100,
          align: 'center',
          headerAlign: 'center',
          // valueFormatter: (params: GridValueFormatterParams) => {
          //   console.log(params.api.getRow);
          //   return dayjs(params?.value).format('DD/MM/YYYY');
          // },
          renderCell: (params: GridRenderCellParams) => {
            return dayjs(params?.row?.startTime).format('DD/MM/YYYY');
          },
        },
        {
          field: 'startTime',
          headerName: 'Ora inizio',
          width: 80,
          align: 'center',
          headerAlign: 'center',
          valueFormatter: (params: GridValueFormatterParams) =>
            dayjs(params?.value).format('HH:mm'),
        },
        {
          field: 'endTime',
          headerName: 'Ora fine',
          width: 80,
          align: 'center',
          headerAlign: 'center',
          valueFormatter: (params: GridValueFormatterParams) =>
            dayjs(params?.value).format('HH:mm'),
        },
        {
          field: 'status',
          headerName: 'Stato',
          width: 100,
          valueFormatter: (params: GridValueFormatterParams) =>
            appointmentStatus[params?.value],
        },
        {
          field: 'slotOkStatus',
          headerName: '',
          width: 80,
          renderCell: (params: GridRenderCellParams) => {
            return (
              <div
                style={{
                  color:
                    params.value === 2 ? undefined : 'var(--accent-dark-color)',
                }}
              >
                Non valido
              </div>
            );
          },
        },
        {
          field: 'Validità',
          headerName: 'Validità',
          // flex: 1,
          // minWidth: 100,
          width: 140,
          // hide: slotOK !== 1,
          // cellClassName: 'slokOkStatus',
          align: 'center',
          headerAlign: 'center',
          // valueFormatter: (params: GridValueFormatterParams) =>
          //   slotOKStatus[params?.value],

          renderCell: (params: GridRenderCellParams) => {
            // <RenderStatusSlotOk {...params} />;
            const handleOnChange = (event: any) => {
              BluAsyncDialog({
                title: 'Attenzione!',
                message:
                  "Sei sicuro di voler forzare la validazione dell'appuntamento?",
                confimButton: 'Si',
                declineButton: 'No',
                type: 'info',
                sx: { '& .MuiDialog-paper': { maxWidth: '370px' } },
                onConfirmClick: async () => {
                  handleValidationChange(params?.row?.id);
                },
              });
            };
            if (params?.row?.slotOkStatus === 2) return 'Forzato';
            return (
              <Button
                sx={{ p: '0 8px' }}
                variant="outlined"
                onClick={handleOnChange}
              >
                Forza validità
              </Button>
            );
          },
        },
      ];
      return (
        <DataGridPremium
          sx={{
            minHeight: 454,
            '& .slokOkStatus': {
              padding: '2px',
            },
          }}
          columns={columns as any}
          rows={appointments ?? []}
          onRowClick={(event: any) => {
            setSelectedAppointment(event?.row);
            //   action(event?.row);
          }}
          components={{
            Footer: () => {
              return (
                <GridFooterContainer>
                  <Stack
                    flex="1"
                    direction="row"
                    gap={1}
                    justifyContent="flex-end"
                    sx={{ pr: 1 }}
                  >
                    {totalPages > 1 && (
                      <Stack direction="row" gap={1} alignItems="center">
                        <Typography>{`Pagina ${pageNumber} di ${
                          totalPages ?? 1
                        }`}</Typography>
                        <IconButton
                          disabled={pageNumber === 1}
                          onClick={() => handlePageNumberChange(pageNumber - 1)}
                        >
                          <KeyboardArrowLeftIcon />
                        </IconButton>
                        <IconButton
                          disabled={pageNumber >= totalPages}
                          onClick={() => handlePageNumberChange(pageNumber + 1)}
                        >
                          <KeyboardArrowRightIcon />
                        </IconButton>
                      </Stack>
                    )}
                  </Stack>
                </GridFooterContainer>
              );
            },
          }}
        />
      );
    }
  );
