/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Typography, useTheme } from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import moment from 'moment';
import {
  memo,
  useCallback,
  useDeferredValue,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import hexToRgba from 'hex-to-rgba';
import { useSelector } from 'react-redux';
import { useAppSelector } from 'packages/blu-booking/src/store';
import { bluBookingLoginData } from 'packages/blu-booking/src/store/root';
import { useAppointmentSlotDetails } from 'packages/blu-booking/src/data_fetching/AppointmentSlot/AppointmentSlotDetails';
import { userAvailability } from 'packages/blu-booking/src/data_fetching/User/UserAvailability';
import { studioAvailability } from 'packages/blu-booking/src/data_fetching/Studio/StudioAvailability';
import dayjs from 'dayjs';
import { appointmentSlotIsOk } from 'packages/blu-booking/src/data_fetching/AppointmentSlot/AppointmentSlotIsOk';

interface HourPickerInterface {
  selectedDate: string;
  setSelectedDate: (value: string) => void;
}

export const HourPicker = memo(function HourPicker({
  selectedDate,
  setSelectedDate,
}: HourPickerInterface): JSX.Element {
  const maxBookingValue = 200;

  const { setValue, trigger } = useFormContext();
  const [
    serviceId,
    pointOfSaleId,
    userId,
    selectedDay,
    duration,
    studioId,
    studioRequired,
    Id,
    initialStart,
    initialEnd,
  ] = useWatch({
    name: [
      'serviceId',
      'pointOfSaleId',
      'userId',
      'timeSlotDate',
      'duration',
      'studioId',
      'studioRequired',
      'Id',
      'initialStart',
      'initialEnd',
    ],
  });

  const momentDaySelected = moment(selectedDate, 'YYYY/MM/DD');
  const maxDayValue = moment().add(maxBookingValue, 'hours');
  // const hourSelected = moment(start).format("HH:mm");
  const { isManualAppointmentBlock } = useAppSelector(bluBookingLoginData);

  const ref = useRef<HTMLDivElement>(null);
  const [h] = useState<number>(0);

  const theme = useTheme();

  const [timeslots, setTimeSlots] = useState<string[]>([]);

  const { data, isLoading } = useAppointmentSlotDetails({
    appointmentId: Id,
    serviceId,
    pointOfSaleId,
    Date: selectedDate,
    userId,
    studioId,
    durationValue: duration,
  });
  const deferredValue = useDeferredValue(data);

  // eslint-disable-next-line react-hooks/exhaustive-deps, @typescript-eslint/no-explicit-any
  const handleClick = async (hour: any) => {
    const start = moment(selectedDate);
    const [shour, minute] = hour.split(':');
    start.set('hour', parseInt(shour));
    start.set('minute', parseInt(minute));
    setValue('timeSlotDate', new Date(start.format('YYYY/MM/DD')));
    setValue('start', start);
    setValue('end', start.clone().add(duration, 'minutes'));
    setValue('searchDisponibility', false);
    setSelectedDate(moment().format('YYYY/MM/DD'));

    if (userId === '') {
      const u = await userAvailability({
        pointOfSaleId,
        serviceId,
        startTime: start.format('YYYY-MM-DDTHH:mm:ss'),
        endTime: start
          .clone()
          .add(duration, 'minutes')
          .format('YYYY-MM-DDTHH:mm:ss'),
      });
      setValue('userId', u);
    }
    if (studioId === '' && studioRequired) {
      const s = await studioAvailability({
        pointOfSaleId,
        serviceId,
        startTime: start.format('YYYY-MM-DDTHH:mm:ss'),
        endTime: start
          .clone()
          .add(duration, 'minutes')
          .format('YYYY-MM-DDTHH:mm:ss'),
      });
      setValue('studioId', s);
    }
    setValue('cercaDisponibilitaUsed', true);

    trigger();
  };

  useEffect(() => {
    const getValue = async (data: string[]) => {
      let result = data;

      if (
        Id &&
        Id !== '' &&
        initialStart &&
        selectedDate &&
        dayjs(initialStart).format('YYYY-MM-DD') ===
          dayjs(selectedDate).format('YYYY-MM-DD')
      ) {
        const s = dayjs(initialStart);
        const e = dayjs(initialEnd);
        const isOk = await appointmentSlotIsOk({
          appointmentId: Id,
          serviceId,
          pointOfSaleId,
          userId,
          studioId,
          startTime: s.format('YYYY-MM-DD HH:mm'),
          endTime: e.format('YYYY-MM-DD HH:mm'),
        });
        if (isOk.isOk === 0) {
          const hour = s.format('HH:mm');
          const find = result.find((x) => x === hour);
          if (find === undefined) {
            result.push(hour);
            result = result.sort();
          }
        }
      }
      setTimeSlots(result);
    };

    if (data) getValue(data);
  }, [
    Id,
    data,
    duration,
    initialEnd,
    initialStart,
    pointOfSaleId,
    selectedDate,
    selectedDay,
    serviceId,
    studioId,
    userId,
  ]);

  const HoursContainer = useMemo((): JSX.Element => {
    if ((timeslots ?? []).length > 0) {
      return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {timeslots?.map((hour: string) => (
            <div
              key={nanoid()}
              className="hour-container"
              style={{
                backgroundColor: hexToRgba(
                  theme.palette.primary.main,
                  theme.palette.mode === 'dark' ? 0.3 : 0.12
                ),
                // hourSelected !== hour
                //   ? hexToRgba(
                //       theme.palette.primary.main,
                //       theme.palette.mode === "dark" ? 0.3 : 0.12
                //     )
                //   : theme.palette.primary.main,
                color: theme.palette.text.primary,
                // hourSelected !== hour ? theme.palette.text.primary : "#fff",
                cursor: 'pointer',
              }}
              onClick={() => handleClick(hour)}
            >
              <Typography variant="body2">{hour}</Typography>
            </div>
          ))}
        </>
      );
    } else if (momentDaySelected.isSameOrAfter(maxDayValue)) {
      return (
        <Typography style={{ textAlign: 'center' }}>
          {'Non è presente nessun orario nel giorno selezionato'}
        </Typography>
      );
    }
    return (
      <Typography style={{ textAlign: 'center' }}>
        {'Non è presente nessun orario nel giorno selezionato'}
      </Typography>
    );
  }, [
    handleClick,
    maxDayValue,
    momentDaySelected,
    theme.palette.mode,
    theme.palette.primary.main,
    theme.palette.text.primary,
    timeslots,
  ]);

  const Content = useMemo(() => {
    return (
      <div style={{ minHeight: h }}>
        <div className="hour-picker-container" ref={ref}>
          {HoursContainer}
        </div>
      </div>
    );
  }, [h, HoursContainer]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{!isLoading && <div>{Content}</div>}</>;
});
