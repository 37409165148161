import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import { Stack } from '@mui/system';
import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { Unit } from '../../common/Unit';
import { CustomNumberField } from '../../common/CustomNumberField';
import { useWatch, useFormContext } from 'react-hook-form';
import { AppointmentProcess } from './AppointmentProcess';

interface AppointmentConfigurationInterface {
  disabled: boolean;
  software: string;
}

export const AppointmentConfiguration: FC<
  AppointmentConfigurationInterface
> = ({ disabled, software }) => {
  const isUserSelectable = useWatch({ name: 'isUserSelectable' });
  const { setValue, trigger } = useFormContext();
  const { mode } = useSelector((state: RootState) => state.layoutRoot);

  const [isGoogleMapsActive] = useWatch({
    name: ['isGoogleMapsActive', 'isSmsActive'],
  });

  useEffect(() => {
    if (mode === 'view') return;
    const allElements = document.querySelectorAll('*');
    allElements.forEach((element) => {
      element.classList.remove('e-disabled');
    });
  }, [mode]);

  const FieldWithUnit = ({
    children,
  }: {
    children: JSX.Element[] | JSX.Element;
  }) => (
    <div style={{ display: 'flex', flex: 1 }}>
      <Stack
        flex={1}
        alignItems="flex-end"
        direction="row"
        gap={1}
        sx={{ minWidth: '220px' }}
      >
        {children}
      </Stack>
    </div>
  );

  return (
    <Stack flex={1} gap={1}>
      <Stack
        flexDirection="unset"
        flexWrap="wrap"
        gap={1}
        sx={{ width: '100%' }}
      >
        <FieldWithUnit>
          <CustomNumberField
            disabled={disabled}
            name="minBookingValue"
            label="Tempo minimo per effettuare la prenotazione online"
            tooltip=" Definisce il tempo minimo per effettuare una prenotazione online. Ad esempio, selezionando 60 minuti come tempo minimo, i clienti potranno effettuare le prenotazioni solo dopo un'ora dal momento corrente"
          />
          <Unit name="minBookingUnit" disabled={disabled} />
        </FieldWithUnit>
        <FieldWithUnit>
          <CustomNumberField
            disabled={disabled}
            name="maxBookingValue"
            label="Tempo massimo per effettuare la prenotazione online                  "
            tooltip="Definisce il tempo massimo per effettuare una prenotazione online. Ad esempio, selezionando 2 mesi come tempo massimo, i clienti potranno effettuare le prenotazioni massimo a 2 mesi dal momento corrente."
          />
          <Unit name="maxBookingUnit" disabled={disabled} />
        </FieldWithUnit>
      </Stack>
      <Stack
        flexDirection="unset"
        flexWrap="wrap"
        gap={1}
        sx={{ width: '100%' }}
      >
        <FieldWithUnit>
          <CustomNumberField
            disabled={disabled}
            name="timeSlot"
            label="Intervallo fasce orari appuntamenti"
            tooltip=" Rappresenta gli slot orari per le prenotazioni. Ad esempio, con un intervallo di 30 minuti, sarà possibile selezionare orari come 9:00, 9:30, 10:00 e così via, a seconda degli orari disponibili per i punti vendita e gli utenti."
          />

          <TextField
            disabled
            value="Minuti"
            sx={{
              width: 120,
            }}
          />
        </FieldWithUnit>

        <FieldWithUnit>
          <CustomNumberField
            disabled={disabled}
            name="minCancellationValue"
            label="Termine di cancellazione appuntamenti"
            tooltip="Stabilisce il tempo massimo per cui un cliente può cancellare un appuntamento. Se impostata su 24 ore, ad esempio, i clienti non potranno cancellare l'appuntamento se mancano meno di 24 ore. La cancellazione può essere effettuata da un utente interno tramite la console.                  "
          />
          <Unit name="minCancellationUnit" disabled={disabled} />
        </FieldWithUnit>
      </Stack>

      <Stack
        flexDirection="unset"
        flexWrap="wrap"
        gap={1}
        alignItems="flex-end"
        sx={{ width: '100%' }}
      >
        <CustomNumberField
          disabled={disabled}
          name="maxRequestableAppointmentByDay"
          label="N° massimo di appuntamenti online giornalieri per cliente"
          tooltip="Rappresenta il numero massimo di appuntamenti al giorno che un cliente può prenotare."
        />
        <AppointmentProcess disabled={disabled} />
        <FormControlLabel
          control={
            <Checkbox
              disabled={disabled}
              checked={isUserSelectable}
              onChange={(e) => {
                setValue('isUserSelectable', e.target.checked);
                trigger('isUserSelectable');
              }}
            />
          }
          label="Utente selezionabile nella prenotazione"
        />
        <FormControlLabel
          disabled={disabled}
          control={
            <Checkbox
              checked={isGoogleMapsActive ?? false}
              onChange={(event: any) => {
                setValue('isGoogleMapsActive', event.target.checked);
              }}
            />
          }
          label="Abilita Google Maps"
        />
      </Stack>
    </Stack>
  );
};
