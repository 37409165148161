import { TextField, Theme, ThemeProvider } from '@mui/material';
import {
  DesktopDatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers-pro';
import dayjs from 'dayjs';
import overrideBludataTheme from './theme';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePickerInterface } from './interface';
import { memo, useCallback } from 'react';
import BluAsyncDialog from '../AsyncDialog';

const BluDatePicker = memo(function BluDatePicker({
  value,
  onChange,
  format = 'DD/MM/YYYY',
  textField,
  locale = 'it',
  ...rest
}: DatePickerInterface) {
  const handleBlur = useCallback(
    async (event) => {
      if (!value) return;
      const dateVal = dayjs(value);
      if (dateVal && !dateVal.isValid()) {
        await BluAsyncDialog({
          title: 'Attenzione',
          message: 'Formato della data non valido.',
          type: 'error',
          confimButton: 'Chiudi',
          hideDeclineButton: true,
        });
      }
    },
    [value]
  );

  return (
    <ThemeProvider theme={(theme: Theme) => overrideBludataTheme(theme)}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
        <DesktopDatePicker
          inputFormat="DD/MM/YYYY"
          value={dayjs(value)}
          onChange={onChange}
          {...rest}
          renderInput={(params: any) => (
            <TextField
              {...params}
              fullWidth
              {...textField}
              inputProps={{
                ...params.inputProps,
                ...textField?.inputProps,
              }}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <>
                    {params.InputProps.startAdornment}
                    {textField?.InputProps?.startAdornment}
                  </>
                ),
                endAdornment: (
                  <>
                    {params.InputProps.endAdornment}
                    {textField?.InputProps?.endAdornment}
                  </>
                ),
              }}
              onBlur={(e) => {
                params?.onBlur && params.onBlur();
                textField?.onBlur && textField.onBlur(e);
                handleBlur(e);
              }}
            />
          )}
        />
      </LocalizationProvider>
    </ThemeProvider>
  );
});

export default BluDatePicker;
