import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
} from '@mui/material';
import React, { useCallback } from 'react';
import { memo } from 'react';
import WarningIcon from '@mui/icons-material/ErrorTwoTone';

interface EmailNotSetPopupProps {
  open: boolean;
  type: string;
  action: () => void;
  cancelAction: () => void;
}

export const EmailNotSetPopup = memo(
  ({ open, type, action, cancelAction }: EmailNotSetPopupProps) => {
    const getContent = useCallback(() => {
      if (type === 'delete')
        return 'Il cliente non riceverà la conferma di cancellazione.';
      if (type === 'create')
        return 'Il cliente non riceverà la conferma di creazione.';
      return 'Il cliente non riceverà la conferma di modifica.';
    }, [type]);

    const getButtonContent = useCallback(() => {
      if (type === 'delete') return 'Sì, cancella appuntamento';
      if (type === 'create') return 'Sì, crea appuntamento';
      return 'Sì, modifica appuntamento';
    }, [type]);

    return (
      <Dialog open={open} maxWidth="xs" fullScreen={false}>
        <DialogContent>
          <div style={{ textAlign: 'center' }}>
            <WarningIcon
              style={{
                fontSize: '6.0rem',
                color: 'var(--base-light-color)',
              }}
            />
            <Typography variant="h5" fontWeight="bold">
              Attenzione!
            </Typography>
            <Typography variant="subtitle1" fontWeight="bold">
              Mancano dei dati per poter notificare il cliente.
            </Typography>
            <Typography variant="body1">{getContent()}</Typography>
          </div>
        </DialogContent>
        <DialogActions>
          <Stack flexDirection="row" gap="10px" sx={{ width: '100%' }}>
            <div style={{ flex: 1 }}>
              <Button
                variant="outlined"
                sx={{ textAlign: 'center' }}
                onClick={cancelAction}
              >
                <div style={{ width: '100%', textAlign: 'center' }}>
                  Annulla
                </div>
              </Button>
            </div>

            <Button
              variant="contained"
              color="primary"
              sx={{ textAlign: 'center' }}
              onClick={action}
            >
              <div style={{ width: '100%', textAlign: 'center' }}>
                {getButtonContent()}
              </div>
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    );
  }
);
