import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Divider,
  Stack,
  TextField,
  DialogActions,
  Button,
} from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { memo, useCallback, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { nanoid } from 'nanoid';
import { BluAsyncDialog } from '@bludata/components';
import { AppDispatch, RootState } from '../../../../../../store';
import {
  setExtraHours,
  setOpenAddPeriodPopUp,
} from '../../../../store/extra-hours';

export const AddPeriod = memo(() => {
  const { extra, openAddPeriodPopUp } = useSelector(
    (state: RootState) => state.newPointOfSaleExtraHours
  );
  const today = dayjs().set('hour', 0).set('minute', 0).set('second', 0);
  const [startDate, setStartDate] = useState<Dayjs | null>(today);
  const [endDate, setEndDate] = useState<Dayjs | null>(today.add(1, 'day'));
  const dispatch: AppDispatch = useDispatch();

  const handleSave = useCallback(() => {
    if (startDate && endDate && startDate.isValid() && endDate.isValid()) {
      const temp: any = { ...extra };
      temp[nanoid()] = {
        startDate: startDate,
        endDate: endDate,
        extraCalendarType: 0,
        shift: {
          isClosed: true,
          startTime1: null,
          startTime2: null,
          endTime1: null,
          endTime2: null,
        },
        calendar: {
          isClosed: true,
          startTime1: null,
          startTime2: null,
          endTime1: null,
          endTime2: null,
        },
      };

      dispatch(setExtraHours({ value: { ...temp } }));
      dispatch(setOpenAddPeriodPopUp(false));

      return;
    }

    BluAsyncDialog({
      title: 'Attenzione',
      type: 'error',
      message: 'Date inserite non valide',
    });
  }, [dispatch, endDate, extra, startDate]);

  const handleClose = useCallback(() => {
    dispatch(setOpenAddPeriodPopUp(false));
  }, [dispatch]);

  return (
    <Dialog
      open={openAddPeriodPopUp}
      fullScreen={false}
      //   onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Aggiungi un periodo di chiusura
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Divider sx={{ opacity: 0, mb: '24px!important' }} />
            <Stack direction="row" spacing={0.5}>
              <DatePicker
                renderInput={(props) => <TextField size="small" {...props} />}
                label="Dal"
                value={startDate}
                onChange={(newValue) => {
                  setStartDate(newValue);
                }}
                inputFormat="DD/MM/YYYY" // Imposta il formato della data
              />
              <DatePicker
                renderInput={(props) => <TextField size="small" {...props} />}
                label="Al"
                value={endDate}
                maxDate={startDate?.add(31, 'days')}
                minDate={startDate}
                onChange={(newValue) => {
                  setEndDate(newValue);
                }}
                inputFormat="DD/MM/YYYY" // Imposta il formato della data
              />
            </Stack>
          </LocalizationProvider>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant={'outlined'} onClick={handleClose}>
          Annulla
        </Button>
        <Button onClick={handleSave}>Conferma</Button>
      </DialogActions>
    </Dialog>
  );
});
