import { FC, memo, useCallback, useMemo, useState } from 'react';
import {
  Fade,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from 'react-redux';
import {
  AppDispatch,
  RootState,
} from 'packages/configurazione-components/src/store';
import { useFormContext, useWatch } from 'react-hook-form';
import { setTurnoCopiato } from '../../../store/root';

export const ContextMenu: FC<{
  calendarType: 'Calendar' | 'Shift';
}> = memo(({ calendarType }): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const { turnoCopiato } = useSelector((state: RootState) => state.rotaRoot);
  const dispatch: AppDispatch = useDispatch();

  const open = Boolean(anchorEl);

  const type = useWatch({
    name: `type${calendarType}`,
  });

  const { watch, reset, trigger } = useFormContext();

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>): void => {
      setAnchorEl(event?.currentTarget);
    },
    []
  );

  const handleClose = useCallback((): void => {
    setAnchorEl(null);
  }, []);

  const handleCopyClick = useCallback(() => {
    const data = watch();

    const turno = {
      startTime1: data[`startTime1${calendarType}`],
      startTime2: data[`startTime2${calendarType}`],
      endTime1: data[`endTime1${calendarType}`],
      endTime2: data[`endTime2${calendarType}`],
    };
    dispatch(setTurnoCopiato(turno));
    handleClose();
  }, [dispatch, handleClose, calendarType, watch]);

  const handlePasteClick = useCallback(() => {
    reset({
      ...watch(),
      [`startTime1${calendarType}`]: turnoCopiato?.startTime1,
      [`startTime2${calendarType}`]: turnoCopiato?.startTime2,
      [`endTime1${calendarType}`]: turnoCopiato?.endTime1,
      [`endTime2${calendarType}`]: turnoCopiato?.endTime2,
    });
    trigger();
    handleClose();
  }, [calendarType, handleClose, reset, trigger, turnoCopiato, watch]);

  const handleDeleteClick = useCallback(() => {
    reset({
      ...watch(),
      [`startTime1${calendarType}`]: null,
      [`startTime2${calendarType}`]: null,
      [`endTime1${calendarType}`]: null,
      [`endTime2${calendarType}`]: null,
    });
    trigger();
    handleClose();
  }, [calendarType, handleClose, reset, trigger, watch]);

  const MenuContainer = useMemo(() => {
    return (
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          key="download-ics"
          value="download-ics"
          style={{
            paddingTop: '2px',
            paddingBottom: '2px',
          }}
          onClick={handleCopyClick}
        >
          <ListItemIcon>
            <ContentCopyIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Copia</ListItemText>
        </MenuItem>
        <MenuItem
          key="google-calendar"
          value="google-calendar"
          style={{
            paddingTop: '2px',
            paddingBottom: '2px',
          }}
          disabled={turnoCopiato === undefined}
          onClick={handlePasteClick}
        >
          <ListItemIcon>
            <ContentPasteGoIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Incolla</ListItemText>
        </MenuItem>
        <MenuItem
          key="yahoo"
          value="yahoo"
          style={{
            paddingTop: '2px',
            paddingBottom: '2px',
          }}
          onClick={handleDeleteClick}
        >
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Cancella</ListItemText>
        </MenuItem>
      </Menu>
    );
  }, [
    anchorEl,
    open,
    handleClose,
    handleCopyClick,
    turnoCopiato,
    handlePasteClick,
    handleDeleteClick,
  ]);

  return (
    <>
      <IconButton
        onClick={handleClick}
        disabled={
          type === 'default' || type === 'closed' || type === 'extra-closed'
        }
        sx={{ width: '30px', height: '30px' }}
      >
        <MoreVertIcon />
      </IconButton>
      {MenuContainer}
    </>
  );
});
