import { FC, memo } from 'react';
import { Stack } from '@mui/material';
import { BluContainerBlock, BluTitle } from '@bludata/components';

interface HeaderInterface {
  title: string;
  children?: JSX.Element | JSX.Element[];
}

export const Header: FC<HeaderInterface> = memo(({ title, children }) => {
  return (
    <BluContainerBlock
      sx={{
        height: '46px',
        display: 'flex',
        alignItems: 'center',
        m: '4px 4px 0px 4px',
      }}
    >
      <Stack flexDirection="row" flex="1" alignItems="center">
        <BluTitle sx={{ flex: 1 }} text={title} />
        {children}
      </Stack>
    </BluContainerBlock>
  );
});
