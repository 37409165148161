import '../../styles/SearchPopup.css';

import { FC, memo, useCallback, useEffect } from 'react';
import React from 'react';
import {
  DataGridPremium,
  GridRenderCellParams,
  GridRowId,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import moment from 'moment';
import axios from 'axios';
import { Clienti_table_interface } from 'packages/calendar/src/interfaces/clienti-table';
import useTableLoader from '@bludata/hook-usetableloader';

interface DisplayResultsFocusWebInterface {
  value: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action?: (value: any) => void;
  enterAction?: (value: any) => void;

  keyDown?: string;
  setKeyDown?: (value: string) => void;
}

export const DisplayResultsFocusWeb: FC<DisplayResultsFocusWebInterface> = memo(
  ({ value, action = () => null, enterAction, setKeyDown, keyDown }) => {
    const today = moment();
    const filters = [
      {
        property: 'cognome',
        operator: '!=',
        value: '< CLIENTE OCCASIONALE >',
      },
    ];
    if (value && value !== '')
      filters.push({
        property: 'cognome,nome,cognome2',

        operator: 'fulltext',

        value: value,
      });

    const {
      loading: loadingClienti,
      data: clientiList,
      fetchTableData: fetchClienti,
    } = useTableLoader<Clienti_table_interface>({
      tablename: 'clientilista',
      axios: axios,
      filters: filters as any,
      sort: [
        { property: 'cognome', direction: 'ASC' },

        { property: 'nome', direction: 'ASC' },
      ],
      pagination: {
        page: 0,
        pageSize: 100,
      },
    });

    useEffect(() => {
      fetchClienti();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const apiRef = useGridApiRef();

    useEffect(() => {
      if (clientiList && clientiList.length > 0) {
        const selectedRowIds = Array.from(
          apiRef.current.getSelectedRows().keys()
        );
        const selectedRowId = selectedRowIds[0];

        const currentRowIndex = clientiList.findIndex(
          (row) => row?.id === selectedRowId
        );
        if (currentRowIndex === -1) {
          apiRef.current.selectRow(clientiList[0]?.id);
          apiRef.current.setSelectionModel([clientiList[0]?.id]);
        }
      }
    }, [apiRef, clientiList]);

    const gestioneSelezioneArrows = useCallback(
      (id: GridRowId, next: boolean) => {
        if (clientiList) {
          if (next) {
            const currentRowIndex = clientiList.findIndex(
              (row) => row?.id === id
            );

            if (currentRowIndex < clientiList.length - 1) {
              const newSelectedRowId = clientiList[currentRowIndex + 1]?.id;

              apiRef.current.selectRow(newSelectedRowId);
              apiRef.current.setSelectionModel([newSelectedRowId]);
            }
          } else {
            const currentRowIndex = clientiList.findIndex(
              (row) => row?.id === id
            );
            if (currentRowIndex > 0) {
              const newSelectedRowId = clientiList[currentRowIndex - 1]?.id;
              apiRef.current.selectRow(newSelectedRowId);
              apiRef.current.setSelectionModel([newSelectedRowId]);
            }
          }
        }
      },
      [apiRef, clientiList]
    );

    useEffect(() => {
      if (keyDown) {
        const selectedRowIds = Array.from(
          apiRef.current.getSelectedRows().keys()
        );
        const selectedRowId = selectedRowIds[0];

        if (keyDown === 'Enter') {
          const selectedRow = apiRef.current.getRow(selectedRowId);
          if (!selectedRow) return;
          console.log('ON enter', selectedRow);
          enterAction(selectedRow);
        } else if (keyDown === 'ArrowUp') {
          gestioneSelezioneArrows(selectedRowId, false);
          // Handle arrow up key press
        } else if (keyDown === 'ArrowDown') {
          // Handle arrow down key press
          gestioneSelezioneArrows(selectedRowId, true);
        }
        setKeyDown(undefined);
      }
    }, [apiRef, enterAction, gestioneSelezioneArrows, keyDown, setKeyDown]);
    //"1995-11-21T00:00:00"

    return (
      <DataGridPremium
        columns={[
          {
            field: 'cognome',
            headerName: 'Cognome',
            flex: 1,
            editable: false,
            sortable: false,
            disableColumnMenu: true,
          },
          {
            field: 'nome',
            headerName: 'Nome',
            flex: 1,
            editable: false,
            sortable: false,
            disableColumnMenu: true,
          },
          {
            field: 'cognome2',
            headerName: 'Cognome 2',
            width: 130,
            editable: false,
            sortable: false,
            disableColumnMenu: true,
          },
          {
            field: 'dataNascita',
            align: 'center',
            headerAlign: 'center',
            headerName: 'Età',
            width: 60,
            editable: false,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: GridRenderCellParams) => {
              const dataNString = params.value;
              if (dataNString) {
                const dataN = moment(dataNString);
                const eta = today.diff(dataN, 'years');
                return eta;
              }

              return '';
            },
          },
          {
            field: 'indirizzo',
            headerName: 'Indirizzo',
            flex: 1,
            editable: false,
            sortable: false,
            disableColumnMenu: true,
          },
          {
            field: 'citta',
            headerName: 'Città',
            flex: 1,
            editable: false,
            sortable: false,
            disableColumnMenu: true,
          },
          {
            field: 'eMail',
            headerName: 'Email',
            flex: 1,
            editable: false,
            sortable: false,
            disableColumnMenu: true,
          },
          {
            field: 'telefono',
            headerName: 'Telefono',
            flex: 1,
            editable: false,
            sortable: false,
            disableColumnMenu: true,

            renderCell: (params: GridRenderCellParams) => {
              if (params.row.telefoninoGsm) return params.row.telefoninoGsm;

              return params.value;
            },
          },
        ]}
        rows={clientiList ? clientiList : []}
        sx={{
          height: '290.5px',
          '& .MuiDataGrid-row': {
            cursor: 'pointer',
            minHeight: '50px !important',
          },
        }}
        apiRef={apiRef}
        loading={loadingClienti && clientiList === undefined}
        density="compact"
        disableColumnMenu
        disableMultipleSelection
        hideFooter
        editMode="row"
        getRowHeight={() => 'auto'}
        onProcessRowUpdateError={(error) => console.log(error)}
        experimentalFeatures={{ newEditingApi: true }}
        onRowClick={(event: any) => {
          action(event?.row);
        }}
      />
    );
  }
);
