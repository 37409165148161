import { Button, Typography } from '@mui/material';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import React from 'react';
import { TableResult } from './display-results';
import { BluDialog, BluTextField } from '@bludata/components';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { AppDispatch, RootState } from 'packages/blu-booking/src/store';
import { getConfig } from '../../../../utility_objects/WhiteCache';
import { setSearchPopupForm } from '../../../../store/AppointmentForm';

export const SearchPopUpForm = memo(() => {
  console.log('SearchPopUpForm');
  const [cliente, setCliente] = useState<string>('');
  const { searchPopupForm } = useSelector(
    (state: RootState) => state.bluBookingAppointmentForm
  );
  const dispatch = useDispatch<AppDispatch>();
  const { reset, getValues, trigger, watch } = useFormContext();
  const { focus10 } = getConfig();

  const [keyDown, setKeyDown] = useState<string | undefined>();

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    event.stopPropagation();
    if (event.key === 'Escape') {
      dispatch(setSearchPopupForm(false));
    }
    if (
      event.key !== 'Enter' &&
      event.key !== 'ArrowUp' &&
      event.key !== 'ArrowDown'
    )
      return;
    setKeyDown(event.key);
  };

  const onClientClick = useCallback(
    (value: any) => {
      const data = watch();
      let email = value.email || '';
      let telephone = value.telephone || '';

      if (
        (data.name ?? '').toUpperCase() === (value?.name ?? '').toUpperCase() &&
        (data.surname ?? '').toUpperCase() ===
          (value?.surname ?? '').toUpperCase()
      ) {
        if (data.email && data.email !== '') email = data.email || '';

        if (data.telephone && data.telephone !== '')
          telephone = data.telephone || '';
      }

      reset({
        ...getValues(),
        ...value,
        name: value.name || '',
        surname: value.surname || '',
        telephone,
        email,
      });
      trigger();
      dispatch(setSearchPopupForm(false));
    },
    [dispatch, getValues, reset, trigger, watch]
  );

  return (
    <BluDialog
      open={searchPopupForm}
      zIndex={400}
      dialogTitle="Ricerca cliente"
      fullScreen={false}
      onKeyDown={handleKeyDown}
      autoFocus
      dialogActions={
        <Button
          variant="outlined"
          color="primary"
          onClick={() => dispatch(setSearchPopupForm(false))}
        >
          Chiudi
        </Button>
      }
      dialogContent={
        <div style={{ minWidth: focus10 ? '800px' : '50vw' }}>
          <Typography variant="subtitle2" sx={{ p: '3px 0px' }}>
            Ricerca cliente
          </Typography>
          <Search setValue={(v: string) => setCliente(v)} />
          <div style={{ height: '20px' }} />
          <TableResult
            value={cliente}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            action={onClientClick}
            keyDown={keyDown}
            setKeyDown={setKeyDown}
          />
          <div style={{ height: '20px' }} />
        </div>
      }
    />
  );
});

interface SearchProps {
  setValue: (v: string) => void;
}

const Search = memo(({ setValue }: SearchProps) => {
  const [value, set] = useState<string>('');

  useEffect(() => {
    const delayFn = setTimeout(() => {
      setValue(value);
    }, 250);

    return () => {
      clearTimeout(delayFn);
    };
  }, [setValue, value]);

  return (
    <BluTextField
      value={value}
      name="Ricerca"
      autoComplete="off" // Disabilita il completamento automatico
      autoFocus
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange={(event: any) => set(event.target.value)}
      InputProps={{
        startAdornment: <SearchTwoToneIcon />,
      }}
    />
  );
});
