/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-prototype-builtins */
import { Divider, Fade, Menu, MenuItem } from '@mui/material';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SubMenu } from './SubMenu';
import { getConfig } from '../../utility_objects/WhiteCache';
import { AppDispatch, RootState } from 'packages/blu-booking/src/store';
import { Appointment_interface } from 'packages/blu-booking/src/interfaces/Appointment';
import { setOpenForm } from '../../store/AppointmentForm';
import { setEmailPopup, setRightClick } from '../../store/Calendar';
import { getAppointmentDetails } from '../../../../data_fetching/Appointment/AppointmentDetails';
import { setRefetch } from '../../store/Appointment';
import { checkIsTelephone } from 'packages/blu-booking/src/utility_objects/CheckIsTelephone';

const disableRightClick = (event: any) => event.preventDefault();

interface ContextMenuInterface {
  createCliente?: (
    clientData: {
      cognome: string; // dimensione:30
      nome: string;
      telefono: string;
      telefoninoGsm: string;
      eMail: string;
    },
    appointmentId: string
  ) => void;
  isTabCliente?: boolean;
}

export const ContextMenu: FC<ContextMenuInterface> = memo(
  ({ createCliente, isTabCliente = false }) => {
    const { rightClick } = useSelector(
      (state: RootState) => state.bluBookingCalendars
    );
    const dispatch = useDispatch<AppDispatch>();
    const { sessionProfilo, focusWeb, selezioneCliente } = getConfig();

    const handleClose = (): void => {
      dispatch(setRightClick(undefined));
    };

    const handleClientClick = useCallback(async () => {
      if (!rightClick?.externalCustomerCode) return;
      if (focusWeb && selezioneCliente) {
        handleClose();
        selezioneCliente(rightClick?.externalCustomerCode);
        return;
      }

      if (
        (window as any).hasOwnProperty('agenda10') &&
        (window as any).agenda10.hasOwnProperty('gotoCliente')
      ) {
        dispatch(setRightClick(undefined));
        (window as any).agenda10.gotoCliente(rightClick?.externalCustomerCode);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, focusWeb, rightClick?.externalCustomerCode]);

    const handleCreateClient = useCallback(async () => {
      if (focusWeb && createCliente) {
        handleClose();
        const isTelephoneNumber = checkIsTelephone(rightClick?.telephone);
        createCliente(
          {
            cognome: rightClick?.surname,
            nome: rightClick?.name,
            telefono: !isTelephoneNumber ? rightClick?.telephone : undefined,
            telefoninoGsm: isTelephoneNumber
              ? rightClick?.telephone
              : undefined,
            eMail: rightClick?.email,
          },
          rightClick?.Id
        );
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, focusWeb, rightClick?.externalCustomerCode]);

    const deleteAction = useCallback(async () => {
      dispatch(setRightClick(undefined));

      if (rightClick)
        dispatch(
          setEmailPopup({
            id: rightClick.Id,
            appointment: rightClick as Appointment_interface,
            emailSet:
              rightClick?.email !== undefined && rightClick?.email !== '',
            type: 'delete',
          })
        );
      return;
    }, [dispatch, rightClick]);

    const modify = useCallback(async () => {
      dispatch(setRightClick(undefined));

      if (rightClick) {
        const data = await getAppointmentDetails(rightClick?.Id);
        dispatch(setOpenForm({ ...data, userUsername: rightClick?.username }));
      }
      // dispatch(setOpenForm(props));
    }, [dispatch, rightClick]);

    const handleRefresh = useCallback(() => {
      dispatch(setRightClick(undefined));
      dispatch(setRefetch());
    }, [dispatch]);

    useEffect(() => {
      if (rightClick !== undefined)
        window.addEventListener('contextmenu', disableRightClick);
      else window.removeEventListener('contextmenu', disableRightClick);
    }, [rightClick]);

    const GetClientMenuItem = useMemo(() => {
      if (isTabCliente) return null;
      if (
        window.hasOwnProperty('agenda10') &&
        (window as any).agenda10.hasOwnProperty('gotoCliente')
      ) {
        return (
          <MenuItem
            key="client"
            value="client"
            style={{
              paddingTop: '2px',
              paddingBottom: '2px',
              width: '180px',
            }}
            disabled={!rightClick?.externalCustomerCode}
            onClick={handleClientClick}
          >
            Apri scheda cliente
          </MenuItem>
        );
      }
      if (focusWeb) {
        if (rightClick?.externalCustomerCode)
          return (
            <MenuItem
              key="client"
              value="client"
              style={{
                paddingTop: '2px',
                paddingBottom: '2px',
                width: '180px',
              }}
              onClick={handleClientClick}
            >
              Apri scheda cliente
            </MenuItem>
          );
        return (
          <MenuItem
            key="client"
            value="client"
            style={{
              paddingTop: '2px',
              paddingBottom: '2px',
              width: '180px',
            }}
            onClick={handleCreateClient}
          >
            Crea cliente
          </MenuItem>
        );
      }
    }, [
      focusWeb,
      handleClientClick,
      handleCreateClient,
      isTabCliente,
      rightClick?.externalCustomerCode,
    ]);

    return (
      <Menu
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={document.getElementById('root')}
        open={
          rightClick !== undefined &&
          rightClick?.y !== undefined &&
          rightClick?.x !== undefined
        }
        onClose={handleClose}
        TransitionComponent={Fade}
        sx={{
          position: 'absolute',
          display: rightClick?.y && rightClick?.x ? 'initial' : 'none',
          top: rightClick?.y ?? 0,
          left: rightClick?.x ?? 0,
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {/* {((window.hasOwnProperty('agenda10') &&
          (window as any).agenda10.hasOwnProperty('gotoCliente')) ||
          focusWeb) && (
          <MenuItem
            key="client"
            value="client"
            style={{
              paddingTop: '2px',
              paddingBottom: '2px',
              width: '180px',
            }}
            disabled={!rightClick?.externalCustomerCode}
            onClick={handleClientClick}
          >
            Apri scheda cliente
          </MenuItem>
        )} */}
        {GetClientMenuItem}
        {(sessionProfilo?.agendaModifica ?? true) && <SubMenu />}
        <Divider style={{ margin: '0' }} />
        {(sessionProfilo?.agendaModifica ?? true) && (
          <MenuItem
            key="modify"
            value="modify"
            style={{
              paddingTop: '2px',
              paddingBottom: '2px',
              width: '180px',
            }}
            onClick={modify}
          >
            Modifica
          </MenuItem>
        )}
        {(sessionProfilo?.agendaCancellazione ?? true) && (
          <MenuItem
            key="delete"
            value="delete"
            style={{
              paddingTop: '2px',
              paddingBottom: '2px',
              width: '180px',
            }}
            onClick={deleteAction}
          >
            Elimina
          </MenuItem>
        )}
        <Divider style={{ margin: '0' }} />
        <MenuItem
          key="update"
          value="update"
          style={{
            paddingTop: '2px',
            paddingBottom: '2px',
            width: '180px',
          }}
          onClick={handleRefresh}
        >
          Aggiorna
        </MenuItem>
      </Menu>
    );
  }
);
