import { object, string, boolean, number } from 'yup';
import 'yup-phone-lite';
import { checkProvacyNotice } from '../utils/default-privacy-notice';

const phoneRegExp = /^[+]?\d*([\s.-]?\d+)*$/;

export const schema = object().shape({
  isTestingActive: boolean(),
  companyId: string(),
  name: string()
    .required('Il campo layout è obbligatorio')
    .min(1, 'Il campo layout è obbligatorio')
    .max(50, 'Il campo layout è troppo lungo'),
  email: string()
    .required('Il campo mail è obbligatorio')
    .min(1, 'Il campo mail è obbligatorio')
    .email('Mail non valida')
    .max(100, 'Il campo mail è troppo lungo'),
  telephone: string()
    .required('Il campo telefono è obbligatorio')
    .min(1, 'Il campo telefono è obbligatorio')
    //.phone("IT", "Telefono non valido")
    .matches(phoneRegExp, {
      message: 'Telefono non valido',
      excludeEmptyString: true,
    })
    .max(50, 'Il campo telefono è troppo lungo'),
  whatsappNumber: string()
    .nullable()
    .matches(phoneRegExp, {
      message: 'Numero whatsapp non valido',
      excludeEmptyString: true,
    })
    .max(50, 'Il numero di whatsapp è troppo lungo'),
  webSiteLink: string()
    .required('Il campo sito internet link è obbligatorio')
    .min(1, 'Il campo sito internet link è obbligatorio')
    .max(200, 'Il campo sito internet link è troppo lungo'),
  logo: string().required('Aggiungi un logo').min(1, 'Aggiungi un logo'),
  favicon: string()
    .required('Aggiungi una favicon')
    .min(1, 'Aggiungi una favicon'),
  address: string()
    .required('Il campo indirizzo è obbligatorio')
    .min(1, 'Il campo indirizzo è obbligatorio')
    .max(70, 'Il campo indirizzo è troppo lungo'),
  city: string()
    .required('Il campo città è obbligatorio')
    .min(1, 'Il campo città è obbligatorio')
    .max(60, 'Il campo città è troppo lungo'),
  region: string()
    .required('Il campo provincia è obbligatorio')
    .min(1, 'Il campo provincia è obbligatorio')
    .max(50, 'Il campo provincia è troppo lungo'),
  fax: string().max(20, 'Il campo fax è troppo lungo'),
  postalCode: string()
    .required('Il campo codice postale è obbligatorio')
    .min(1, 'Il campo codice postale è obbligatorio')
    .max(9, 'Il campo codice postale è troppo lungo'),
  vatCode: string()
    .required('Il campo partita iva è obbligatorio')
    .min(1, 'Il campo partita iva è obbligatorio')
    .max(150, 'Il campo partita iva è troppo lungo'),
  pec: string().email('Pec non valida').max(100, 'Il campo pec è troppo lungo'),
  minBookingValue: number(),
  minBookingUnit: number(),
  maxBookingValue: number(),
  maxBookingUnit: number(),
  minCancellationValue: number(),
  minCancellationUnit: number(),
  timeSlot: number(),
  isRecallOn: boolean(),
  recallValue: number(),
  recallUnit: number(),
  isSmsActive: boolean(),
  maxRequestableAppointmentByDay: number(),
  isGoogleMapsActive: boolean(),
  isUserSelectable: boolean(),
  token: string(),
  appointmentProcess: number(),
  privacyNotice: string()
    .required(
      'Il campo informativa sul trattamento dei dati personali è obbligatorio'
    )
    .test(
      'Test compilazione',
      'Il campo informativa sul trattamento dei dati personali risulta non compilato',
      (value) => {
        if (!value || value === '') return false;
        return !checkProvacyNotice.test(value);
      }
    ),
  privacyPolicy: string().required('Il campo privacy policy è obbligatorio'),
});
