import axios from 'axios';
import { CompanySettingsInterface } from '../../interfaces/CompanySettings';
import { HolidayInterface } from '../../interfaces/Holiday';

interface ManagementCompanySettingsInterface {
  companySettings: CompanySettingsInterface;
  holidays: HolidayInterface[];
  DeleteHolidays: HolidayInterface[];
  ModifyHolidays: HolidayInterface[];
  useShiftPointOfSale?: boolean;
  useShiftUser?: boolean;
}

export const companySettingsManagement = async ({
  companySettings,
  holidays,
  DeleteHolidays,
  ModifyHolidays,
  useShiftPointOfSale = true,
  useShiftUser = true,
}: ManagementCompanySettingsInterface): Promise<boolean> => {
  try {
    await axios.put('/api/General/Management', {
      updateHolidayManagementRequest: {
        CreateHolidays: holidays
          ?.filter((row: HolidayInterface) => row?.id === undefined)
          .flat(),
        DeleteHolidays,
        ModifyHolidays,
      },
      updateCompanySettingsRequest: {
        ...companySettings,
        useShiftPointOfSale,
        useShiftUser,
      },
    });
    return true;
  } catch (error) {
    console.error('ERROR MANAGEMENT COMPANY SETTINGS:', error);
    return false;
  }
};
// 0.3.185
