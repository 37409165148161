import {
  Paper,
  Theme,
  ThemeProvider,
  createTheme,
  styled,
} from '@mui/material';
import { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import hexToRgba from 'hex-to-rgba';
import { RootState } from '../../../../store';

const borderColor = (
  palette: any,
  selected = false,
  hover = false
): string | undefined => {
  if (selected || hover) {
    if (palette.card?.enableBorder !== undefined) {
      return palette.card?.enableBorder;
    }
    return palette.primary.main;
  }
  return palette.card.border;
};

const boxBackgroundColor = (
  palette: any,
  selected = false
): string | undefined => {
  if (selected) {
    if (palette.card?.enable !== undefined) {
      return palette.card?.enable;
    }
    return hexToRgba(
      palette.primary.main,
      palette.mode === 'dark' ? 0.3 : 0.12
    );
  }
  return palette.card.main;
};

export const InnerBox = styled('div')(
  // eslint-disable-next-line @typescript-eslint/member-delimiter-style
  ({
    theme: { palette },
    selected,
    borderWidth = 1,
    borderWidthEnable = 2,
  }: {
    theme: Theme;
    selected: boolean;
    borderWidth?: number;
    borderWidthEnable?: number;
  }) => {
    return {
      width: '100%',
      height: '70px',

      padding: selected ? 0 : Math.abs(borderWidthEnable - borderWidth),
      borderWidth: selected ? borderWidthEnable : borderWidth,
      borderStyle: 'solid',
      borderRadius: 'inherit',
      borderColor: borderColor(palette, selected),
      backgroundColor: boxBackgroundColor(palette, selected),
      '&:hover': {
        borderColor: borderColor(palette, selected, true),
        padding: 0,
        borderWidth: borderWidthEnable,
      },
    };
  }
);

export const ParentBox = styled('div')(
  // eslint-disable-next-line @typescript-eslint/member-delimiter-style
  ({
    borderRadius,
    staticType = false,
  }: {
    staticType?: boolean;
    borderRadius: number;
  }) => ({
    borderRadius,
    height: 'fit-content',
    margin: 'auto',
    width: '50%',
    textAlign: 'left',
    cursor: 'pointer',
  })
);

export const TestBox = memo(() => {
  const palette = useSelector((state: RootState) => state.layoutPalette);
  const { rectangle } = useSelector(
    (state: RootState) => state.layoutCustomTheme
  );
  const getTheme = useCallback(() => {
    return createTheme({ palette });
  }, [palette]);

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        minHeight: '280px',
        display: 'flex',
        flexDirection: 'column',
        // justifyContent: "center",
      }}
    >
      <ThemeProvider theme={getTheme()}>
        <Paper
          sx={{
            display: 'flex',
            flex: 1,
            borderRadius: 0,
            p: '10px 5px',
            justifyItems: 'center',
            flexDirection: 'column',
            rowGap: '10px',
          }}
          elevation={0}
        >
          <ParentBox borderRadius={rectangle.borderRadius}>
            <InnerBox {...rectangle} selected={true} theme={getTheme()} />
          </ParentBox>
          <ParentBox borderRadius={rectangle.borderRadius}>
            <InnerBox {...rectangle} selected={false} theme={getTheme()} />
          </ParentBox>
          {/* <Box
            sx={{
              width: "50%",
              height: "70px",
              border: `1px solid ${palette.card.border}`,
              backgroundColor: palette.card.main,
              borderRadius: "8px",
              margin: "auto",
              mt: "0px",
              mb: "0px",
              boxSizing: "border-box",
            }}
          ></Box> */}
        </Paper>
      </ThemeProvider>
    </div>
  );
});
