/* eslint-disable @typescript-eslint/no-explicit-any */
import { BluLoader } from '@bludata/components';
import { FC, memo } from 'react';

interface CalendarLoaderInterface {
  open: boolean;
}

export const CalendarLoader: FC<CalendarLoaderInterface> = memo(({ open }) => {
  const getHeightWidth = (): {
    height: any;
    width: any;
    top: number;
    left: number;
  } => {
    const container = document.getElementsByClassName(
      'e-table-container'
    ) as any;
    if (container !== undefined && container.length > 0) {
      const bc = container[0].getBoundingClientRect();
      return {
        width: bc.width,
        height: bc.height,
        top: bc.top,
        left: bc.left,
      };
    }
    return {
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
    };
  };
  return <BluLoader open={open} sx={{ ...getHeightWidth() }} />;
});
