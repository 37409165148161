/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Stack, Typography } from '@mui/material';
import { memo, useCallback, useEffect, useState } from 'react';
import { BluDialog, BluLoader } from '@bludata/components';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import {
  DataGridPremium,
  GridRowId,
  GridSelectionModel,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import dayjs from 'dayjs';
import { useQueryClient } from '@tanstack/react-query';
import { useAppointmentsClient } from 'packages/blu-booking/src/data_fetching/Appointment/AppointmentsClient';
import { appointmentStatus } from '../../../utility_objects/AppointmentStatus';
import { setConfig } from '../../../utility_objects/WhiteCache';

interface SearchPopUpProps {
  client: any;
  setSearchPopUp: (value: boolean) => void;
  searchPopUp: boolean;
  setSelectedDate: (value: Date) => void;
}

export const SearchPopUp = memo(
  ({
    client,
    searchPopUp,
    setSearchPopUp,
    setSelectedDate,
  }: SearchPopUpProps) => {
    const apiRef = useGridApiRef();
    const { services } = useSelector(
      (state: RootState) => state.bluBookingCalendarFilters
    );

    const [appointment, setAppointment] = useState<any | undefined>(undefined);
    const { data, isLoading } = useAppointmentsClient({
      externalCustomerCode: client.codicePersonale,
      unregistered: false,
    });
    const queryClient = useQueryClient();

    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>(
      []
    );

    const handleCercaClick = useCallback(() => {
      if (appointment !== undefined) {
        setConfig('underline', appointment?.id ?? '');
        setSelectedDate(
          dayjs(appointment.date, 'DD-MM-YYYY').startOf('month').toDate()
        );

        queryClient.invalidateQueries(['appointment-complete'], {
          type: 'active',
        });
        queryClient.removeQueries(['appointment-complete'], {
          type: 'inactive',
        });

        setAppointment(undefined);
      }
      setSearchPopUp(false);
      setConfig('manageOpenSearch', false);
    }, [appointment, queryClient, setSearchPopUp, setSelectedDate]);

    const gestioneSelezioneArrows = (next: boolean) => {
      const currentIndex = data.findIndex((c) => c.id === appointment.id);
      let newIndex = next ? currentIndex + 1 : currentIndex - 1;
      if (newIndex < 0) {
        newIndex = 0;
      } else if (newIndex >= data.length) {
        newIndex = data.length - 1;
      }
      const newSelection = data[newIndex]?.id as GridRowId;
      setAppointment(data[newIndex]);
      setSelectionModel([newSelection]);
      apiRef.current?.setCellFocus(newSelection, 'serviceId');
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
      event.stopPropagation();

      if (event.key === 'ArrowUp') {
        gestioneSelezioneArrows(false);
        // Handle arrow up key press
      } else if (event.key === 'ArrowDown') {
        // Handle arrow down key press
        gestioneSelezioneArrows(true);
      } else if (event.key === 'Enter') {
        handleCercaClick();
        setTimeout(() => {
          setConfig('manageOpenSearch', false);
        }, 100);
      } else if (event.key === 'Escape') {
        setSearchPopUp(false);
        setConfig('manageOpenSearch', false);
      }
      return;
    };

    useEffect(() => {
      if (data && !appointment && searchPopUp) {
        setAppointment(data[0]);
        setSelectionModel([data[0]?.id as GridRowId]);
        if (
          apiRef !== null &&
          apiRef?.current !== null &&
          apiRef.current?.setCellFocus !== undefined
        )
          apiRef.current?.setCellFocus(data[0]?.id as GridRowId, 'serviceId');
      }
    }, [apiRef, appointment, data, searchPopUp, selectionModel.length]);

    return (
      <BluDialog
        open={searchPopUp}
        fullScreen={false}
        maxWidth={'md'}
        zIndex={400}
        onKeyDown={handleKeyDown}
        draggable
        dialogTitle="Ricerca appuntamento"
        dialogActions={
          <Stack direction="row" flex="1" sx={{ width: '100%' }} gap={1}>
            <Stack flex="1" direction="row" columnGap="10px"></Stack>
            <Button
              variant="outlined"
              color="primary"
              sx={{ width: 'fit-content' }}
              onClick={() => {
                setSearchPopUp(false);
                setConfig('manageOpenSearch', false);
              }}
            >
              Chiudi
            </Button>

            <Button
              color="primary"
              sx={{ width: 'fit-content' }}
              onClick={() => handleCercaClick()}
              disabled={appointment === undefined}
            >
              Visualizza
            </Button>
          </Stack>
        }
        dialogContent={
          <div>
            <BluLoader open={isLoading} />
            <div>
              <Typography variant="subtitle2" sx={{ p: '3px 0px' }}>
                {`Scegli un appuntamento di ${client?.nome} ${client?.cognome}`}
              </Typography>
              <div style={{ height: '10px' }} />

              <DataGridPremium
                apiRef={apiRef}
                hideFooter
                columns={[
                  {
                    field: 'id',
                    hide: true,
                  },
                  {
                    field: 'serviceId',
                    headerName: 'Tipo di appuntamento',
                    flex: 1,
                    editable: false,
                    valueFormatter: (params: any) =>
                      services && params?.value in services
                        ? services[params?.value]?.name ?? ''
                        : '',
                  },
                  {
                    field: 'date',
                    headerName: 'Data',
                    width: 100,
                  },
                  {
                    field: 'startTime',
                    headerName: 'Ora inizio',
                    width: 100,
                    valueFormatter: (params: any) =>
                      dayjs(params?.value).format('HH:mm'),
                  },
                  {
                    field: 'endTime',
                    headerName: 'Ora fine',
                    width: 100,
                    valueFormatter: (params: any) =>
                      dayjs(params?.value).format('HH:mm'),
                  },
                  {
                    field: 'status',
                    headerName: 'Stato',
                    width: 100,
                    valueFormatter: (params: any) =>
                      appointmentStatus[params?.value],
                  },
                ]}
                rows={data ?? []}
                sx={{
                  height: '290.5px',
                  '& .MuiDataGrid-row': {
                    cursor: 'pointer',
                    minHeight: '50px !important',
                  },
                }}
                density="compact"
                disableColumnMenu
                disableMultipleSelection
                getRowHeight={() => 'auto'}
                onProcessRowUpdateError={(error) => console.log(error)}
                onRowClick={(event: any) => {
                  setAppointment(event?.row);
                }}
                onRowDoubleClick={(event: any) => {
                  setAppointment(event?.row);
                  handleCercaClick();
                }}
                onSelectionModelChange={setSelectionModel}
                selectionModel={selectionModel}
              />
            </div>
          </div>
        }
      />
    );
  }
);
