import { BluDialog } from '@bludata/components';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material';
import { RootState } from 'packages/blu-booking/src/store';
import { useMemo, useState } from 'react';
import { memo } from 'react';
import { useSelector } from 'react-redux';

interface EmailPopUpProps {
  open: boolean;
  appointmentId: string;
  pointOfSaleId: string;
  action: (sendMail: boolean) => void;
  cancelAction: () => void;
}

export const EmailPopUpForm = memo(
  ({
    open,
    appointmentId,
    pointOfSaleId,
    action,
    cancelAction,
  }: EmailPopUpProps) => {
    const [sendMail, setSendMail] = useState<boolean>(true);
    const { pointOfSales } = useSelector(
      (state: RootState) => state.bluBookingCalendarFilters
    );

    const CheckInvioMail = useMemo(() => {
      let isBluBookingActive = false;
      let hasLayout = false;

      if (pointOfSaleId in pointOfSales) {
        isBluBookingActive =
          pointOfSales[pointOfSaleId]?.isBluBookingActive ?? false;
        hasLayout = pointOfSales[pointOfSaleId]?.shopSignId !== null;
      }
      if (!hasLayout) setSendMail(false);
      if (isBluBookingActive && hasLayout)
        return (
          <FormControlLabel
            label={
              <Typography variant="body1">Invia notifica al cliente</Typography>
            }
            control={
              <Checkbox
                checked={sendMail}
                onChange={() => setSendMail(!sendMail)}
              />
            }
          />
        );
      return null;
    }, [pointOfSaleId, pointOfSales, sendMail]);

    return (
      <BluDialog
        open={open}
        maxWidth="xs"
        dialogTitle={'Conferma appuntamento'}
        dialogContent={
          <>
            <Typography variant="body1" fontWeight="bold">
              Sei sicuro di voler confermare l'appuntamento?
            </Typography>
            {CheckInvioMail}
          </>
        }
        dialogActions={
          <Stack flexDirection="row" gap="10px" sx={{ width: '100%' }}>
            <div style={{ flex: 1 }}>
              <Button
                variant="outlined"
                sx={{ textAlign: 'center' }}
                onClick={cancelAction}
              >
                <div style={{ width: '100%', textAlign: 'center' }}>
                  Annulla
                </div>
              </Button>
            </div>

            <Button
              variant="contained"
              color="primary"
              sx={{ textAlign: 'center' }}
              onClick={() => action(sendMail)}
            >
              <div style={{ width: '100%', textAlign: 'center' }}>
                Sì, conferma appuntamento
              </div>
            </Button>
          </Stack>
        }
        zIndex={500}
      />
    );
  }
);
