/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

interface studiFilterProps {
  lockService: boolean;
  lockPointOfSale: boolean;
  serviceId?: string;
  pointOfSaleId?: string;
  shopSignId?: string;
}

export const studiFilter = async (
  lockService: boolean,
  lockPointOfSale: boolean,
  serviceId?: string,
  pointOfSaleId?: string,
  shopSignId?: string
): Promise<{ id: string; name: string }[]> => {
  try {
    const params: any = { shopSignId };
    if (lockService && serviceId !== '') params.serviceId = serviceId;
    if (lockPointOfSale && pointOfSaleId !== '')
      params.pointOfSaleId = pointOfSaleId;
    const {
      data: { data },
    } = await axios.get(`/api/Studio/Filter`, {
      params,
    });

    return data;
  } catch (err) {
    return [];
  }
};

export const useStudiFilter = (
  {
    lockService,
    lockPointOfSale,
    serviceId,
    pointOfSaleId,
    shopSignId,
  }: studiFilterProps,
  key = ''
) => {
  return useQuery({
    queryFn: async () =>
      await studiFilter(
        lockService,
        lockPointOfSale,
        serviceId,
        pointOfSaleId,
        shopSignId
      ),
    queryKey: [
      'studi',
      'filter',
      lockService ? serviceId : '',
      lockPointOfSale ? pointOfSaleId : '',
      shopSignId ?? '',
      key,
    ],
    retry: 2,
    retryDelay: 180000,
    cacheTime: 300000,
    staleTime: 300000,
    refetchInterval: 300000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
};
