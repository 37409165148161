/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import axios from 'axios';
import { ServiceInterface, ServicesObject } from '../../interfaces/Service';
import {
  defaultColors,
  mapColor,
  restMapColor,
} from '../../utility_objects/ColorManager';

const getServicesUI = async (): Promise<ServiceInterface[]> => {
  try {
    const {
      data: { data },
    } = await axios.get('/api/Service/UI');
    return data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const useServicesUI = ({
  options = {},
  key = '',
}: {
  options?: UseQueryOptions;
  key?: string;
}) => {
  return useQuery({
    queryFn: async () => getServicesUI(),
    queryKey: ['services', 'ui', key],
    retry: 2,
    retryDelay: 180000,
    cacheTime: 0,
    refetchInterval: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    select: normalize,
    ...options,
  });
};

const normalize = (data: any): ServicesObject => {
  restMapColor();
  const result: any = {};
  for (const service of data) {
    if (service?.color !== null && service.color !== undefined) {
      mapColor[service.id] = service.color;
      result[service.id] = {
        ...service,
      };
    } else {
      mapColor[service.id] = defaultColors[data.indexOf(service)];
      result[service.id] = {
        ...service,
        color: mapColor[service.id],
      };
    }
  }
  return result;
};
