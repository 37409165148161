/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import 'moment/locale/it';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
  IconButton,
  Skeleton,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import moment, { Moment } from 'moment';
import { CSSProperties, memo, useDeferredValue, useMemo } from 'react';
import { Else, If, Then } from 'react-if';
import { useFormContext, useWatch } from 'react-hook-form';
import { useAppointmentSlot } from 'packages/blu-booking/src/data_fetching/AppointmentSlot/AppointmentSlot';
import { DateSlotInterface } from 'packages/blu-booking/src/interfaces/DateSlot';

interface WeekPickerProps {
  selectedDate: string;
  setSelectedDate: (value: string) => void;
  startDate: Moment;
  setStartDate: (value: Moment) => void;
  range: number;
}

export const WeekPicker = memo(function WeekPicker({
  selectedDate,
  setSelectedDate,
  startDate,
  setStartDate,
  range,
}: WeekPickerProps): JSX.Element {
  const { setValue } = useFormContext();
  const [type, where, userId, studioId] = useWatch({
    name: ['serviceId', 'pointOfSaleId', 'userId', 'studioId'],
  });

  const endDate = startDate.clone().add(range, 'days');
  const { data, isLoading } = useAppointmentSlot(
    startDate.format('YYYY/MM/DD'),
    endDate.format('YYYY/MM/DD'),
    type,
    where,
    studioId,
    userId
  );
  const deferredValue = useDeferredValue(data);

  // ? Do call of forward week to remove on change loading
  const followWeeekStart = startDate.clone().add(range + 1, 'days');
  const followWeeekEnd = endDate.clone().add(range + 1, 'days');
  useAppointmentSlot(
    followWeeekStart.format('YYYY/MM/DD'),
    followWeeekEnd.format('YYYY/MM/DD'),
    type,
    where,
    studioId,
    userId
  );

  const Dates = useMemo((): JSX.Element => {
    if (deferredValue !== undefined) {
      return (
        <WeekPickerInner
          data={deferredValue}
          selectedDate={selectedDate}
          setSelectedDate={(value: string) => setSelectedDate(value)}
        />
      );
    }
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }, [deferredValue, selectedDate, setSelectedDate]);

  return (
    <div className="week-picker">
      <IconButton
        disabled={!moment().isBefore(startDate)}
        onClick={() => {
          const newStart = startDate.clone().subtract(range + 1, 'days');
          setSelectedDate(newStart.format('YYYY/MM/DD'));
          setStartDate(newStart);
          // setValue("timeSlotDate", newStart);
        }}
        className="date-container "
      >
        <KeyboardArrowLeftIcon style={{ fontSize: '35px' }} />
      </IconButton>

      <If condition={isLoading}>
        <Then>
          <LoadingDateContainer />
        </Then>
        <Else>{Dates}</Else>
      </If>
      <IconButton
        onClick={() => {
          const newStart = startDate.clone().add(range + 1, 'days');
          setSelectedDate(newStart.format('YYYY/MM/DD'));
          setStartDate(newStart);
          // setValue("timeSlotDate", newStart);
        }}
        className="date-container "
      >
        <KeyboardArrowRightIcon style={{ fontSize: '35px' }} />
      </IconButton>
    </div>
  );
});

interface WeekPickerInnerProps {
  selectedDate: string;
  setSelectedDate: (value: string) => void;
  data?: DateSlotInterface;
}

export const WeekPickerInner = memo(function WeekPickerInner({
  data,
  selectedDate,
  setSelectedDate,
}: WeekPickerInnerProps): JSX.Element {
  const theme = useTheme();
  const { watch, setValue } = useFormContext();
  return (
    <If condition={data !== undefined}>
      <Then>
        {data !== undefined &&
          Object.keys(data).map((date: string) => {
            return (
              <DateContainer
                key={nanoid()}
                data={data[date]}
                date={date}
                theme={theme}
                dispatch={(date: any) => {
                  // setValue("timeSlotDate", new Date(date));
                  setSelectedDate(date);
                }}
                selected={date === selectedDate}
              />
            );
          })}
      </Then>
    </If>
  );
});

interface DateContainerProps {
  data: any;
  date: string;
  theme: Theme;
  dispatch: (date: any) => void;
  selected?: boolean;
}
// ? Date Component
const DateContainer = memo(function DateContainer({
  data,
  date,
  theme,
  dispatch,
  selected = false,
}: DateContainerProps): JSX.Element {
  const { daysOfWeek, day, active = false } = data;
  const dow: string = daysOfWeek.toString();
  const style: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    aspectRatio: '1/1',
  };
  if (selected) {
    style.backgroundColor = theme?.palette.primary.main;
    style.color = '#fff';
  }
  const dayName: { [x: string]: string } = {
    '0': 'Dom',
    '1': 'Lun',
    '2': 'Mar',
    '3': 'Mer',
    '4': 'Gio',
    '5': 'Ven',
    '6': 'Sab',
  };
  return (
    <IconButton
      disabled={active === false}
      onClick={() => dispatch(date)}
      style={style}
      className="date-container "
    >
      <Typography variant="body2" sx={{ color: 'inherit !important' }}>
        {dayName[dow]}
      </Typography>
      <Typography
        variant="body1"
        fontWeight={700}
        sx={{ color: 'inherit !important' }}
      >
        {day}
      </Typography>
    </IconButton>
  );
});

const LoadingDateContainer = memo(function LoadingDateContainer(): JSX.Element {
  const row = [];

  for (let i = 0; i <= 6; i++) {
    row.push(
      <IconButton key={nanoid()} className="date-container ">
        <Skeleton variant="circular" width="100%" height="100%" />
      </IconButton>
    );
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{row}</>;
});
