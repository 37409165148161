import { Stack, Button, Divider, Typography, Box } from '@mui/material';
import moment from 'moment';
import { FC, memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../store';
import { FormProvider, useForm } from 'react-hook-form';
import { BluAsyncDialog, BluDialog, BluLoader } from '@bludata/components';
import { setPopUpRota, setTurnoCopiato } from '../../store/root';
import SaveIcon from '@mui/icons-material/Save';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './schema';
import { getInitialValues } from './utils/initial-values';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Shift } from './features/shift';
import { Calendar } from './features/calendar';
import { manageTurniPointOfSale } from './utils/turni-point-of-sale-management';
import { manageTurniUser } from './utils/turni-user-management';
import { useQueryClient } from '@tanstack/react-query';
import { SelectType } from './components/selectType';

interface RotaPopUpInterface {
  isSingleCalendarManagement: boolean;
  id: string;
  userId?: string;
  userName?: string;
  calendarType: 'pointOfSale' | 'user';
  date: Date;
  calendar: {
    extraId?: string;
    isClosed: boolean;
    isExtra: boolean;
    isHoliday: boolean;
    extraCalendarType?: number;
    startTime1: string | null;
    startTime2: string | null;
    endTime1: string | null;
    endTime2: string | null;
  };
  shift: {
    extraId?: string;
    isClosed: boolean;
    isExtra: boolean;
    isHoliday: boolean;
    extraCalendarType?: number;
    startTime1: string | null;
    startTime2: string | null;
    endTime1: string | null;
    endTime2: string | null;
  };
  defaultShift?: {
    startTime1?: string;
    startTime2?: string;
    endTime1?: string;
    endTime2?: string;
  };
  defaultCalendar?: {
    startTime1?: string;
    startTime2?: string;
    endTime1?: string;
    endTime2?: string;
  };
}
export const RotaPopUp: FC<RotaPopUpInterface> = memo(
  ({
    isSingleCalendarManagement,
    id,
    userId,
    userName,
    calendarType,
    date,
    calendar,
    shift,
    defaultShift,
    defaultCalendar,
  }) => {
    const { pointOfSaleName, pointOfSaleId } = useSelector(
      (state: RootState) => state.rotaRoot
    );
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<boolean>(false);
    const dispatch = useDispatch<AppDispatch>();
    const queryClient = useQueryClient();
    const initialValues = getInitialValues({
      date,
      calendar,
      shift,
      defaultCalendar,
      defaultShift,
      isSingleCalendarManagement,
    });

    const methods = useForm({
      mode: 'onChange',
      reValidateMode: 'onChange',
      defaultValues: initialValues,
      resolver: yupResolver(schema),
    });

    const { errors } = methods.formState;

    const DialogTitle = useCallback(() => {
      if (calendarType === 'pointOfSale')
        return `Orario del giorno ${moment(date).format(
          'DD/MM/YYYY'
        )} per il punto vendita ${pointOfSaleName}`;

      return `Orario del giorno ${moment(date).format(
        'DD/MM/YYYY'
      )} per l'utente ${userName}`;
    }, [calendarType, pointOfSaleName, date, userName]);

    const getExampleContent = useCallback(() => {
      if (calendarType === 'pointOfSale')
        return `Cambia l'orario di lavoro per questo giorno. Puoi sovrascrivere
                l'orario di lavoro predefinito. Ad esempio, potresti voler aprire il
                negozio in una giornata festiva.`;
      return `Cambia l'orario di lavoro per questo giorno. Puoi sovrascrivere
            l'orario di lavoro predefinito. Ad esempio, potresti dover
            lavorare 1 giorno per coprire qualcun altro.`;
    }, [calendarType]);

    useEffect(() => {
      if (errorMessage)
        BluAsyncDialog({
          title: 'Attenzione!',
          message: Object.values(errors)
            .map(({ message }: any) => `<li>${message}</li>`)
            .join(''),
          confimButton: 'Chiudi',
          hideDeclineButton: true,
          type: 'warning',
          sx: { '& .MuiDialog-paper': { maxWidth: '370px' } },
          onConfirmClick: () => setErrorMessage(false),
        });
    }, [errorMessage, errors]);

    const handleSave = useCallback(
      async (data: any, calendarType: string) => {
        let result = false;
        dispatch(setTurnoCopiato(undefined));

        if (calendarType === 'pointOfSale') {
          result = await manageTurniPointOfSale({
            pointOfSaleId: pointOfSaleId,
            isSingleCalendarManagement,
            initialDataCalendar: {
              startTime1: initialValues?.startTime1Calendar
                ? initialValues.startTime1Calendar.toDate()
                : null,
              startTime2: initialValues?.startTime2Calendar
                ? initialValues.startTime2Calendar.toDate()
                : null,
              endTime1: initialValues?.endTime1Calendar
                ? initialValues.endTime1Calendar.toDate()
                : null,
              endTime2: initialValues?.endTime2Calendar
                ? initialValues.endTime2Calendar.toDate()
                : null,
            },
            initialDataShift: {
              startTime1: initialValues?.startTime1Shift
                ? initialValues.startTime1Shift.toDate()
                : null,
              startTime2: initialValues?.startTime2Shift
                ? initialValues.startTime2Shift.toDate()
                : null,
              endTime1: initialValues?.endTime1Shift
                ? initialValues.endTime1Shift.toDate()
                : null,
              endTime2: initialValues?.endTime2Shift
                ? initialValues.endTime2Shift.toDate()
                : null,
            },
            date,
            extraShiftId: shift?.extraId,
            startTime1Shift: data?.startTime1Shift
              ? data.startTime1Shift.toDate()
              : null,
            startTime2Shift: data?.startTime2Shift
              ? data.startTime2Shift.toDate()
              : null,
            endTime1Shift: data?.endTime1Shift
              ? data.endTime1Shift.toDate()
              : null,
            endTime2Shift: data?.endTime2Shift
              ? data.endTime2Shift.toDate()
              : null,
            typeShift: data.typeShift,
            initialTypeShift: data.initialTypeShift,

            extraCalendarId: calendar?.extraId,
            startTime1Calendar: data?.startTime1Calendar
              ? data.startTime1Calendar.toDate()
              : null,
            startTime2Calendar: data?.startTime2Calendar
              ? data.startTime2Calendar.toDate()
              : null,
            endTime1Calendar: data?.endTime1Calendar
              ? data.endTime1Calendar.toDate()
              : null,
            endTime2Calendar: data?.endTime2Calendar
              ? data.endTime2Calendar.toDate()
              : null,
            typeCalendar: data.typeCalendar,
            initialTypeCalendar: data.initialTypeCalendar,
          });
        } else {
          result = await manageTurniUser({
            pointOfSaleId: pointOfSaleId,
            userId: userId,
            isSingleCalendarManagement,
            initialDataCalendar: {
              startTime1: initialValues?.startTime1Calendar
                ? initialValues.startTime1Calendar.toDate()
                : null,
              startTime2: initialValues?.startTime2Calendar
                ? initialValues.startTime2Calendar.toDate()
                : null,
              endTime1: initialValues?.endTime1Calendar
                ? initialValues.endTime1Calendar.toDate()
                : null,
              endTime2: initialValues?.endTime2Calendar
                ? initialValues.endTime2Calendar.toDate()
                : null,
            },
            initialDataShift: {
              startTime1: initialValues?.startTime1Shift
                ? initialValues.startTime1Shift.toDate()
                : null,
              startTime2: initialValues?.startTime2Shift
                ? initialValues.startTime2Shift.toDate()
                : null,
              endTime1: initialValues?.endTime1Shift
                ? initialValues.endTime1Shift.toDate()
                : null,
              endTime2: initialValues?.endTime2Shift
                ? initialValues.endTime2Shift.toDate()
                : null,
            },
            date,

            extraShiftId: shift?.extraId,
            startTime1Shift: data?.startTime1Shift
              ? data.startTime1Shift.toDate()
              : null,
            startTime2Shift: data?.startTime2Shift
              ? data.startTime2Shift.toDate()
              : null,
            endTime1Shift: data?.endTime1Shift
              ? data.endTime1Shift.toDate()
              : null,
            endTime2Shift: data?.endTime2Shift
              ? data.endTime2Shift.toDate()
              : null,
            typeShift: data.typeShift,
            initialTypeShift: data.initialTypeShift,
            shiftExtraCalendarType: data.shiftExtraCalendarType,
            initialShiftExtraCalendarType: data.initialShiftExtraCalendarType,

            extraCalendarId: calendar?.extraId,
            startTime1Calendar: data?.startTime1Calendar
              ? data.startTime1Calendar.toDate()
              : null,
            startTime2Calendar: data?.startTime2Calendar
              ? data.startTime2Calendar.toDate()
              : null,
            endTime1Calendar: data?.endTime1Calendar
              ? data.endTime1Calendar.toDate()
              : null,
            endTime2Calendar: data?.endTime2Calendar
              ? data.endTime2Calendar.toDate()
              : null,
            typeCalendar: data.typeCalendar,
            initialTypeCalendar: data.initialTypeCalendar,
            calendarExtraCalendarType: data.calendarExtraCalendarType,
            initialCalendarExtraCalendarType:
              data.initialCalendarExtraCalendarType,
          });
        }
        setLoading(false);

        if (result) {
          dispatch(setPopUpRota(undefined));
          queryClient.invalidateQueries(['turni-user'], {
            type: 'active',
          });
          queryClient.removeQueries(['turni-user'], {
            type: 'inactive',
          });
          queryClient.invalidateQueries(['turni-point-of-sale'], {
            type: 'active',
          });
          queryClient.removeQueries(['turni-point-of-sale'], {
            type: 'inactive',
          });
          queryClient.invalidateQueries(['turni-user-day'], {
            type: 'active',
          });
          queryClient.removeQueries(['turni-user-day'], {
            type: 'inactive',
          });
          queryClient.invalidateQueries(['turni-point-of-sale-day'], {
            type: 'active',
          });
          queryClient.removeQueries(['turni-point-of-sale-day'], {
            type: 'inactive',
          });
          queryClient.invalidateQueries(['holiday'], {
            type: 'active',
          });
          queryClient.removeQueries(['holiday'], {
            type: 'inactive',
          });
          return;
        }

        BluAsyncDialog({
          title: 'Attenzione',
          type: 'error',
          message: 'Errore durante la modifica degli orari.',
          hideDeclineButton: true,
          confimButton: 'Chiudi',
          sx: { '& .MuiDialog-paper': { maxWidth: '370px' } },
        });
      },
      [
        calendar?.extraId,
        date,
        dispatch,
        initialValues.endTime1Calendar,
        initialValues.endTime1Shift,
        initialValues.endTime2Calendar,
        initialValues.endTime2Shift,
        initialValues.startTime1Calendar,
        initialValues.startTime1Shift,
        initialValues.startTime2Calendar,
        initialValues.startTime2Shift,
        isSingleCalendarManagement,
        pointOfSaleId,
        queryClient,
        shift?.extraId,
        userId,
      ]
    );

    const handleSaveChecks = useCallback(async () => {
      setLoading(true);
      const trig = await methods.trigger();
      if (!trig) {
        setLoading(false);
        setErrorMessage(true);
        return;
      }

      const data = methods.watch();
      const checkCalendar =
        data.typeCalendar !== 'default' &&
        data.typeCalendar !== 'closed' &&
        data?.startTime1Calendar?.format('HH:mm') ===
          defaultCalendar?.startTime1 &&
        data?.startTime2Calendar?.format('HH:mm') ===
          defaultCalendar?.startTime2 &&
        data?.endTime1Calendar?.format('HH:mm') === defaultCalendar?.endTime1 &&
        data?.endTime2Calendar?.format('HH:mm') === defaultCalendar?.endTime2;
      const checkShift = isSingleCalendarManagement
        ? false
        : data.typeShift !== 'default' &&
          data.typeShift !== 'closed' &&
          data?.startTime1Shift?.format('HH:mm') === defaultShift?.startTime1 &&
          data?.startTime2Shift?.format('HH:mm') === defaultShift?.startTime2 &&
          data?.endTime1Shift?.format('HH:mm') === defaultShift?.endTime1 &&
          data?.endTime2Shift?.format('HH:mm') === defaultShift?.endTime2;

      if (checkShift || checkCalendar) {
        BluAsyncDialog({
          title: 'Attenzione!',
          message: "Hai modificato il tipo di turno senza cambiare l'orario!",
          confimButton: 'Si',
          declineButton: 'No',
          onDeclineClick: () => setLoading(false),
          type: 'warning',
          sx: { '& .MuiDialog-paper': { maxWidth: '370px' } },
          onConfirmClick: () => handleSave(data, calendarType),
        });
        return;
      }
      setLoading(false);
      handleSave(data, calendarType);
    }, [
      calendarType,
      defaultCalendar?.endTime1,
      defaultCalendar?.endTime2,
      defaultCalendar?.startTime1,
      defaultCalendar?.startTime2,
      defaultShift?.endTime1,
      defaultShift?.endTime2,
      defaultShift?.startTime1,
      defaultShift?.startTime2,
      handleSave,
      isSingleCalendarManagement,
      methods,
    ]);

    return (
      <FormProvider {...methods}>
        <form id="creationForm" style={{ width: 'inherit' }}>
          <BluDialog
            className="service-popup"
            open={true}
            maxWidth="sm"
            fullScreen={false}
            zIndex={200}
            draggable
            dialogTitle={DialogTitle()}
            dialogContent={
              <Stack flex="1" sx={{ p: '5px 10px' }} gap={1}>
                <BluLoader open={loading} sx={{ zIndex: '1400' }} />

                <Typography>{getExampleContent()}</Typography>
                <Divider sx={{ mt: 2 }} />
                <SelectType turniType={calendarType} />
                <Stack flex="1" sx={{ pt: 2, pb: 2 }}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={'it'}
                  >
                    {!isSingleCalendarManagement && (
                      <>
                        <Shift turniType={calendarType} date={date} />
                        <Box sx={{ p: '20px 0px' }} />
                      </>
                    )}

                    <Calendar turniType={calendarType} date={date} />
                  </LocalizationProvider>
                </Stack>
              </Stack>
            }
            sx={{
              '& .MuiDialogContent-root': {
                pb: '0px !important',
                p: 0,
                height: 'fit-content',
              },
              '& .MuiDialog-paper': {
                height: 'fit-content !important',
                minHeight: 'unset !important',
              },
              zIndex: 200,
            }}
            dialogActions={
              <Stack flex="1" justifyContent="end" direction="row" gap={1}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    dispatch(setTurnoCopiato(undefined));
                    dispatch(setPopUpRota(undefined));
                  }}
                >
                  Annulla
                </Button>
                <Button startIcon={<SaveIcon />} onClick={handleSaveChecks}>
                  Salva
                </Button>
              </Stack>
            }
          />
        </form>
      </FormProvider>
    );
  }
);
