import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { AppointmentNotificationInterface } from '../../interfaces/AppointmentNotification';

export const getAppointmentsNotification =
  async (): Promise<AppointmentNotificationInterface> => {
    try {
      const {
        data: { data },
      } = await axios.get('/api/Appointment/v2/Notification');

      return data;
    } catch (error) {
      console.log(error);
      return {
        errorCodeCount: 0,
        notConfirmedCount: 0,
        notOkSlotCount: 0,
      };
    }
  };

export const useAppointmentsNotification = (key = '') => {
  return useQuery({
    queryFn: async () => await getAppointmentsNotification(),
    queryKey: ['appointment-notification', key],
    retry: 2,
    retryDelay: 180000,
    cacheTime: 0,
    staleTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: true,
  });
};
