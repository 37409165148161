import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import 'dayjs/locale/it';

export interface AppointmentFormSliceState {
  lockPointOfSale: boolean;
  lockService: boolean;
  lockUser: boolean;
  lockStudio: boolean;
  hasStudio: boolean;
  searchDisponibility: boolean;
  openForm?: any;
  submit: string;
  emailPopupForm: string;
  emailNotSetForm: string;
  searchPopupForm: boolean;
  formLoader: boolean;
  errorPopup: boolean;
  shopSignIsRecallOn: boolean;
}

const initialState: AppointmentFormSliceState = {
  lockPointOfSale: true,
  lockService: true,
  lockUser: true,
  lockStudio: true,
  hasStudio: true,
  searchDisponibility: false,
  submit: '',
  emailPopupForm: '',
  emailNotSetForm: '',
  searchPopupForm: false,
  formLoader: false,
  errorPopup: false,
  shopSignIsRecallOn: false,
};

const FormSlice = createSlice({
  name: 'BluBookingAppointmentForm',
  initialState,
  reducers: {
    setLockPointOfSale(
      state: AppointmentFormSliceState,
      action: PayloadAction<boolean>
    ) {
      if (state.lockPointOfSale !== action.payload)
        state.lockPointOfSale = action.payload;
    },
    setLockService(
      state: AppointmentFormSliceState,
      action: PayloadAction<boolean>
    ) {
      if (state.lockService !== action.payload)
        state.lockService = action.payload;
    },
    setLockUser(
      state: AppointmentFormSliceState,
      action: PayloadAction<boolean>
    ) {
      if (state.lockUser !== action.payload) state.lockUser = action.payload;
    },

    setLockStudio(
      state: AppointmentFormSliceState,
      action: PayloadAction<boolean>
    ) {
      if (state.lockStudio !== action.payload)
        state.lockStudio = action.payload;
    },
    setLocks(state: AppointmentFormSliceState) {
      if (!state.lockPointOfSale) state.lockPointOfSale = true;
      if (!state.lockService) state.lockService = true;
      if (!state.lockUser) state.lockUser = true;
      if (!state.lockStudio) state.lockStudio = true;
    },
    setHasStudio(
      state: AppointmentFormSliceState,
      action: PayloadAction<boolean>
    ) {
      state.hasStudio = action.payload;
    },
    setSearchDisponibility(
      state: AppointmentFormSliceState,
      action: PayloadAction<boolean>
    ) {
      state.searchDisponibility = action.payload;
    },

    setOpenForm: (
      state: AppointmentFormSliceState,
      action: PayloadAction<any | undefined>
    ) => {
      state.openForm = action.payload;
    },
    setSubmit(state: AppointmentFormSliceState, action: PayloadAction<string>) {
      state.submit = action.payload;
    },
    setEmailPopupForm(
      state: AppointmentFormSliceState,
      action: PayloadAction<'' | 'create' | 'modify' | 'delete'>
    ) {
      state.emailPopupForm = action.payload;
    },
    setEmailNotSetForm(
      state: AppointmentFormSliceState,
      action: PayloadAction<'' | 'create' | 'modify' | 'delete'>
    ) {
      state.emailNotSetForm = action.payload;
    },
    setSearchPopupForm(
      state: AppointmentFormSliceState,
      action: PayloadAction<boolean>
    ) {
      state.searchPopupForm = action.payload;
    },
    setFormLoader(
      state: AppointmentFormSliceState,
      action: PayloadAction<boolean>
    ) {
      state.formLoader = action.payload;
    },
    setErrorPopup(
      state: AppointmentFormSliceState,
      action: PayloadAction<boolean>
    ) {
      state.errorPopup = action.payload;
    },
    setShopSignIsRecallOn(
      state: AppointmentFormSliceState,
      action: PayloadAction<boolean>
    ) {
      state.shopSignIsRecallOn = action.payload;
    },
    resetForm: () => initialState,
  },
});

export const {
  setLockPointOfSale,
  setLockService,
  setLockUser,
  setLockStudio,
  setLocks,
  setHasStudio,
  setSearchDisponibility,
  setOpenForm,
  setSubmit,
  setEmailPopupForm,
  setEmailNotSetForm,
  setSearchPopupForm,
  setFormLoader,
  setErrorPopup,
  resetForm,
  setShopSignIsRecallOn,
} = FormSlice.actions;

export default FormSlice.reducer;
