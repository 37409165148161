/* eslint-disable @typescript-eslint/no-explicit-any */
import { BluAutoComplete } from '@bludata/components';
import { Box, Typography } from '@mui/material';
import { useStudiFilter } from 'packages/blu-booking/src/data_fetching/Studio/StudiFilter';
import { RootState } from 'packages/blu-booking/src/store';
import React, { useCallback, useDeferredValue, useEffect } from 'react';
import { memo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';

export const Studi = memo(() => {
  const stu = useWatch({ name: 'studioId' });
  const { setValue, trigger } = useFormContext();

  const service = useWatch({ name: 'serviceId' });
  const pointOfSale = useWatch({ name: 'pointOfSaleId' });
  const shopSignId = useWatch({ name: 'shopSignId' });

  const { data: result, isLoading } = useStudiFilter({
    lockPointOfSale: true,
    lockService: true,
    pointOfSaleId: pointOfSale,
    serviceId: service,
    shopSignId,
  });

  const data = useDeferredValue(result);

  useEffect(() => {
    if (data === undefined) return;
    if (data.length === 0) return;

    const find = data.find((studio) => studio.id === stu);
    if (find === undefined || pointOfSale === '') {
      setValue('studioId', '');
    }
  }, [data, pointOfSale, setValue, stu]);

  const getData = useCallback(() => {
    return data ?? [];
  }, [data]);

  const handleOnChange = useCallback(
    (_: any, value: any) => {
      if (value === null) {
        setValue('studioId', '');
        trigger();

        return;
      }
      setValue('studioId', value.id);
      trigger();
    },
    [setValue, trigger]
  );

  return (
    <StudiInner
      pointOfSaleId={pointOfSale}
      watch={stu}
      data={getData()}
      isLoading={isLoading}
      handleOnChange={handleOnChange}
    />
  );
});

interface StudiInnerProps {
  watch: any;
  isLoading: boolean;
  data: any;
  pointOfSaleId: string;
  handleOnChange: (event: any, value: any) => void;
}

const StudiInner = memo(
  ({
    pointOfSaleId,
    watch,
    isLoading,
    data,
    handleOnChange,
  }: StudiInnerProps) => {
    const { studi } = useSelector(
      (state: RootState) => state.bluBookingCalendarFilters
    );

    const getValue = useCallback(
      () => (studi !== undefined && watch in studi ? studi[watch] : null),
      [studi, watch]
    );
    return (
      <div style={{ width: '49%' }}>
        <Typography
          variant="subtitle2"
          sx={{
            color:
              pointOfSaleId === undefined || pointOfSaleId === ''
                ? 'var(--disabled-color)'
                : undefined,
          }}
        >
          Studio
        </Typography>
        <BluAutoComplete
          value={getValue()}
          data={data}
          width="49%"
          textField={{
            placeholder: 'Seleziona uno studio',
          }}
          disabled={pointOfSaleId === undefined || pointOfSaleId === ''}
          onChange={(event: any, value: any) => handleOnChange(event, value)}
          loading={isLoading}
          renderOption={(props: any, option: any) => (
            <Box component="li" {...props}>
              {option.name}
            </Box>
          )}
          isOptionEqualToValue={(option: any, value: any) =>
            option.id === value.id
          }
          getOptionLabel={(option: any) => `${option.name}`}
        />
      </div>
    );
  }
);
