import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { ShopSignInterface } from '../../../interfaces/shop-sign';
import { getPath } from '../lib/white-cache';
import { nanoid } from '@reduxjs/toolkit';

const getShopSignDetails = async (
  id = ''
): Promise<ShopSignInterface | null> => {
  if (id === '' || id === 'new') return null;
  try {
    const {
      data: { data },
    } = await axios.get(`${getPath()}/ShopSign/${id}`);

    return {
      ...data,
      recallUnit: 1,
      favicon:
        data?.favicon && data?.favicon !== '' && data?.favicon !== 'null'
          ? `${data?.favicon}?cacheTime=${nanoid()}`
          : null,
      logo:
        data?.logo && data?.logo !== '' && data?.logo !== 'null'
          ? `${data?.logo}?cacheTime=${nanoid()}`
          : null,
    };
  } catch (error) {
    return null;
  }
};

export const useShopSignDetails = (id = '', key = '') => {
  return useQuery({
    queryFn: async () => getShopSignDetails(id),
    queryKey: ['details', 'shop-sign', id, key],
    retry: 2,
    retryDelay: 180000,
    cacheTime: 0,
    staleTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
};
