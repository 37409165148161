import '../../styles/AppointmentForm.css';

import { memo, useCallback, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './utility_objects/Schema';
import { getInitialData } from './utility_objects/InitialData';
import {
  BluAlert,
  BluAsyncDialog,
  BluDialog,
  BluLoader,
} from '@bludata/components';
import { Button, Stack } from '@mui/material';
import { LinkList } from './LinkList';
// import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import SaveIcon from '@mui/icons-material/Save';
import { useQueryClient } from '@tanstack/react-query';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  AppDispatch,
  RootState,
  useAppSelector,
} from 'packages/blu-booking/src/store';
import { updateShopSignLink } from 'packages/blu-booking/src/data_fetching/ShopSign/ShopSignLinkUpdate';
import { createShopSignLink } from 'packages/blu-booking/src/data_fetching/ShopSign/ShopSignLinkCreate';
import { setLink, setOpenClientPopUp } from '../../store/CreateLink';
import { deleteShopSignLink } from 'packages/blu-booking/src/data_fetching/ShopSign/ShopSignLinkDelete';
import { CreateLinkPopupForm } from './CreateLinkPopupForm';
import { ShopSignObject } from 'packages/blu-booking/src/interfaces/ShopSign';

interface CreateLinkPopUpInterface {
  calendar?: string;
  shopSigns: ShopSignObject;
}

export const CreateLinkPopUp = memo(
  ({
    calendar = 'default',
    shopSigns,
  }: CreateLinkPopUpInterface): JSX.Element => {
    const dispatch = useDispatch<AppDispatch>();
    const [step, setStep] = useState<number>(0);
    const [isFirst, setIsFirst] = useState<boolean>(false);
    const [alert, setAlert] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    const { link } = useSelector(
      (state: RootState) => state.bluBookingCreateLink
    );
    const initialValues = getInitialData(calendar, shopSigns);
    const queryClient = useQueryClient();

    const methods = useForm({
      mode: 'onChange',
      reValidateMode: 'onChange',
      defaultValues: initialValues,
      resolver: yupResolver(schema),
    });
    const { reset } = methods;

    const handleSave = useCallback(async () => {
      setLoading(true);
      const data = methods.watch();
      let result: string | undefined;
      if (data?.id && data?.id !== '') result = await updateShopSignLink(data);
      else result = await createShopSignLink(data);
      setLoading(false);
      if (result) {
        queryClient.invalidateQueries(['shop-sign-link'], {
          type: 'active',
        });
        queryClient.removeQueries(['shop-sign-link'], {
          type: 'inactive',
        });
        dispatch(setLink({ ...data, id: result }));
        // reset(initialValues);

        setStep(0);

        return;
      }
      BluAsyncDialog({
        title: 'Attenzione',
        type: 'error',
        message:
          data?.id && data?.id !== ''
            ? 'Errore durante la creazione del link personalizzato.'
            : 'Errore durante la modifica del link personalizzato.',
        hideDeclineButton: true,
        confimButton: 'Chiudi',
        sx: { '& .MuiDialog-paper': { maxWidth: '370px' } },
      });
    }, [dispatch, methods, queryClient]);

    const GetContent = useMemo(() => {
      if (!step)
        return (
          <LinkList
            initialData={initialValues}
            setStep={setStep}
            setAlert={setAlert}
            setLoading={setLoading}
            setIsFirst={setIsFirst}
          />
        );
      return (
        <CreateLinkPopupForm
          link={link}
          shopSigns={shopSigns}
          setAlert={setAlert}
          isFirst={isFirst}
        />
      );
    }, [initialValues, isFirst, link, shopSigns, step]);

    const createNew = useCallback(() => {
      dispatch(setLink(undefined));
      reset(initialValues);

      setStep(1);
    }, [dispatch, initialValues, reset]);

    const deleteShortLink = useCallback(async () => {
      BluAsyncDialog({
        title: 'Attenzione',
        type: 'error',
        message: 'Sei sicuro di voler cancellare il link personalizzato?',
        confimButton: 'Conferma',
        onConfirmClick: async () => {
          if (!link) return;
          setLoading(true);
          const result = await deleteShopSignLink(link.id);
          if (!result) {
            setLoading(false);

            setAlert(
              'Errore durante la cancellazione del Link personalizzato '
            );
            return;
          }

          queryClient.invalidateQueries(['shop-sign-link'], {
            type: 'active',
          });
          queryClient.removeQueries(['shop-sign-link'], {
            type: 'inactive',
          });
          dispatch(setLink(undefined));
          reset(initialValues);
          setLoading(false);
        },
        declineButton: 'Annulla',
        sx: { '& .MuiDialog-paper': { maxWidth: '370px' } },
      });
    }, [dispatch, initialValues, link, queryClient, reset]);

    const GetActions = useMemo(() => {
      if (!step)
        return (
          <Stack flex="1" flexDirection="row" gap={1}>
            <Stack flex="1" flexDirection="row" gap={1}>
              <Button
                variant="outlined"
                onClick={() => {
                  dispatch(setOpenClientPopUp(false));
                }}
              >
                Annulla
              </Button>
            </Stack>
            <Button
              color="primary"
              startIcon={<AddIcon />}
              variant="contained"
              onClick={createNew}
            >
              Nuovo
            </Button>
            <Button
              variant="outlined"
              startIcon={<ModeEditIcon />}
              disabled={link === undefined}
              onClick={() => {
                setStep(1);
              }}
            >
              Modifica
            </Button>
            <Button
              variant="contained"
              color="error"
              startIcon={<DeleteIcon />}
              disabled={link === undefined}
              onClick={deleteShortLink}
            >
              Elimina
            </Button>
          </Stack>
        );
      return (
        <Stack flex="1" flexDirection="row" gap={1}>
          <Stack flex="1" flexDirection="row" gap={1}>
            {isFirst ? (
              <Button
                variant="outlined"
                onClick={() => {
                  dispatch(setOpenClientPopUp(false));
                }}
              >
                Annulla
              </Button>
            ) : (
              <Button
                variant="outlined"
                onClick={() => {
                  // dispatch(setOpenClientPopUp(false));
                  setStep(0);
                }}
              >
                Indietro
              </Button>
            )}
          </Stack>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              reset(link ?? initialValues);
            }}
          >
            Azzera
          </Button>
          <Button
            variant="contained"
            startIcon={<SaveIcon />}
            // disabled={link === undefined}
            onClick={() => {
              handleSave();
            }}
          >
            Salva
          </Button>
        </Stack>
      );
    }, [
      createNew,
      deleteShortLink,
      dispatch,
      handleSave,
      initialValues,
      isFirst,
      link,
      reset,
      step,
    ]);

    return (
      <FormProvider {...methods}>
        <BluDialog
          className="appointment-form"
          open={true}
          fullScreen={false}
          zIndex={300}
          disableEnforceFocus
          disableAutoFocus
          disableRestoreFocus
          draggable
          maxWidth="xl"
          dialogTitle={'Crea nuovo link'}
          dialogContent={
            <>
              <BluLoader open={loading} sx={{ zIndex: '1400' }} />
              <BluAlert
                show={alert !== ''}
                text={alert}
                handleClose={() => {
                  setAlert('');
                }}
                type={'info'}
              />
              {GetContent}
            </>
          }
          sx={{
            '& .MuiDialogContent-root': {
              pb: '0px !important',
            },
            // "& .MuiDialog-paper": {
            //   width: "90vw",
            // },
          }}
          // eslint-disable-next-line react/jsx-no-useless-fragment
          dialogActions={<>{GetActions}</>}
        />
      </FormProvider>
    );
  }
);
