import { BluTitle } from '@bludata/components';
import { Box, Button, Slider, styled } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';
import { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ZoomItem } from '../../interfaces/Zoom';

interface stepProps {
  [x: string]: {
    value: number;
    interval: number;
    slotCount: number;
    next?: string;
    previous?: string;
  };
}

const step: stepProps = {
  '200': {
    value: 200,
    interval: 60,
    slotCount: 6,
    previous: '150',
  },
  '150': {
    value: 150,
    interval: 60,
    slotCount: 4,
    next: '200',
    previous: '125',
  },
  '125': {
    value: 125,
    interval: 60,
    slotCount: 3,
    next: '150',
    previous: '100',
  },
  '100': {
    value: 100,
    interval: 60,
    slotCount: 2,
    next: '125',
    previous: '50',
  },
  '50': {
    value: 50,
    interval: 60,
    slotCount: 1,
    next: '100',
    previous: '25',
  },
  '25': {
    value: 25,
    interval: 120,
    slotCount: 1,
    next: '50',
  },
};

const marks = Object.keys(step)
  .map((key: string) => {
    return {
      value: step[key as keyof typeof step]?.value ?? 100,
    };
  })
  .flat();

interface ZoomProps {
  value: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  change: (value: ZoomItem) => any;
}

export const Zoom = memo(function Zoom({
  value,
  change,
}: ZoomProps): JSX.Element {
  const dispatch = useDispatch();

  const handleChange = (_: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number' && newValue.toString() in step)
      //  DefaultCalendarSelectZoomValue(
      // step[newValue.toString() as keyof typeof step]
      // )
      dispatch(change(step[newValue.toString()]));
  };

  const handleClick = useCallback(
    (button: string) => {
      const val = step[value.toString()];
      if (button === '+' && val?.next !== undefined)
        dispatch(change(step[val?.next]));
      else if (button === '-' && val?.previous !== undefined)
        dispatch(change(step[val?.previous]));
    },
    [change, dispatch, value]
  );
  return (
    <Stack>
      <BluTitle sx={{ p: '5px 2px' }} text="Zoom" />
      <Stack
        flex="1"
        flexDirection="row"
        justifyContent="center"
        gap="10px"
        style={{ padding: '0 2px' }}
      >
        <Button
          variant="outlined"
          sx={{
            minWidth: 'unset',
            p: '0px',
            height: 'fit-content',
            textAlign: 'center',
          }}
          onClick={() => handleClick('-')}
        >
          <div style={{ width: '28px', textAlign: 'center' }}>-</div>
        </Button>

        <PrettoSlider
          value={value}
          valueLabelFormat={(value: number) => `${value}%`}
          sx={{ flex: 1 }}
          step={null}
          onChange={handleChange}
          valueLabelDisplay="auto"
          marks={marks}
          max={204}
        />
        <Button
          variant="outlined"
          sx={{
            minWidth: 'unset',
            p: '0',
            height: 'fit-content',
            textAlign: 'center',
          }}
          onClick={() => handleClick('+')}
        >
          <div style={{ width: '28px', textAlign: 'center' }}>+</div>
        </Button>
      </Stack>
    </Stack>
  );
});

const PrettoSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.primary.main,
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: theme.palette.primary.main,
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
}));
