import axios from 'axios';
// eslint-disable-next-line @typescript-eslint/no-var-requires
// const md5 = require('md5');
import md5 from 'md5';

export const checkPassword = async (password: string): Promise<boolean> => {
  try {
    const {
      data: { data },
    } = await axios.post('api/Authenticate/CheckPassword', {
      password: md5(password),
    });

    if (data) {
      return data.passwordIsOk;
    }

    return false;
  } catch (error) {
    console.log(error);
    return false;
  }
};
