import { BluColorPicker, BluNumberField } from '@bludata/components';
import { FormControlLabel, Stack, Switch, Typography } from '@mui/material';
import React, { FC, useCallback } from 'react';
import { memo, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../store';
import { setComponentsValue } from '../../store/components';
import { FontConfiguration } from '../Titles/font-configuration';

interface ConfigButtonInterface {
  disabled: boolean;
}

export const ConfigButton: FC<ConfigButtonInterface> = memo(({ disabled }) => {
  const components = useSelector((state: RootState) => state.layoutComponents);
  const { primary, text } = useSelector(
    (state: RootState) => state.layoutPalette
  );
  const { button } = useSelector((state: RootState) => state.layoutFont);
  const dispatch = useDispatch<AppDispatch>();

  const BorderRadius = useMemo(() => {
    let data =
      components?.MuiButton?.styleOverrides?.root?.borderRadius?.replace(
        'px',
        ''
      ) || '';

    if (data !== '') data = parseFloat(data);
    return (
      <Stack flex="1">
        <Typography>Raggio bordo (px)</Typography>
        <BluNumberField
          disabled={disabled}
          value={data}
          onChange={(event: any) =>
            dispatch(
              setComponentsValue({
                ...components,
                MuiButton: {
                  ...components?.MuiButton,
                  styleOverrides: {
                    ...components?.MuiButton?.styleOverrides,
                    root: {
                      ...components?.MuiButton?.styleOverrides?.root,
                      borderRadius: event ? `${event}px` : '',
                    },
                  },
                },
              })
            )
          }
        />
      </Stack>
    );
  }, [components, disabled, dispatch]);

  const getBoxShadowValue = useCallback(() => {
    return !(components?.MuiButton?.defaultProps?.disableElevation ?? false);
  }, [components?.MuiButton?.defaultProps?.disableElevation]);

  const handleBowShadowChange = useCallback(
    (event: any) => {
      dispatch(
        setComponentsValue({
          ...components,
          MuiButton: {
            ...components?.MuiButton,
            defaultProps: {
              disableElevation: !(
                components?.MuiButton?.defaultProps?.disableElevation ?? false
              ),
            },
          },
        })
      );
    },
    [components, dispatch]
  );

  return (
    <Stack display="flex" flex="1" sx={{ pr: '10px' }} gap="10px">
      <Stack
        gap="10px"
        flexWrap="wrap"
        sx={{ width: '100%' }}
        flexDirection="unset"
      >
        <Stack flex="1">
          <Typography>Sfondo</Typography>
          <BluColorPicker
            disabled={disabled}
            value={
              components?.MuiButton?.styleOverrides?.root?.backgroundColor ??
              primary.main
            }
            onChange={(args: any) =>
              dispatch(
                setComponentsValue({
                  ...components,

                  MuiButton: {
                    ...components?.MuiButton,
                    styleOverrides: {
                      ...components?.MuiButton?.styleOverrides,
                      root: {
                        ...components?.MuiButton?.styleOverrides?.root,
                        backgroundColor: args.currentValue.hex,
                      },
                    },
                  },
                })
              )
            }
          />
        </Stack>
        <Stack flex="1">
          <Typography>Testo</Typography>
          <BluColorPicker
            disabled={disabled}
            value={
              components?.MuiButton?.styleOverrides?.root?.color ?? text.primary
            }
            onChange={(args: any) =>
              dispatch(
                setComponentsValue({
                  ...components,
                  MuiButton: {
                    ...components?.MuiButton,
                    styleOverrides: {
                      ...components?.MuiButton?.styleOverrides,
                      root: {
                        ...components?.MuiButton?.styleOverrides?.root,
                        color: args.currentValue.hex,
                      },
                    },
                  },
                })
              )
            }
          />
        </Stack>
      </Stack>
      <Stack
        flexWrap="wrap"
        gap="10px"
        sx={{ width: '100%' }}
        flexDirection="unset"
      >
        <Stack flex="1">
          <Typography>Sfondo al passaggio del mouse</Typography>
          <BluColorPicker
            disabled={disabled}
            value={
              components?.MuiButton?.styleOverrides?.root['&:hover']
                ?.backgroundColor ?? primary.main
            }
            onChange={(args: any) =>
              dispatch(
                setComponentsValue({
                  ...components,
                  MuiButton: {
                    ...components?.MuiButton,
                    styleOverrides: {
                      ...components?.MuiButton?.styleOverrides,
                      root: {
                        ...components?.MuiButton?.styleOverrides?.root,
                        '&:hover': {
                          ...components?.MuiButton?.styleOverrides?.root[
                            '&:hover'
                          ],
                          backgroundColor: args.currentValue.hex,
                        },
                      },
                    },
                  },
                })
              )
            }
          />
        </Stack>
        <Stack flex="1">
          <Typography>Testo al passaggio del mouse</Typography>
          <BluColorPicker
            disabled={disabled}
            value={
              components?.MuiButton?.styleOverrides?.root['&:hover']?.color ??
              text.primary
            }
            onChange={(args: any) =>
              dispatch(
                setComponentsValue({
                  ...components,
                  MuiButton: {
                    ...components?.MuiButton,
                    styleOverrides: {
                      ...components?.MuiButton?.styleOverrides,
                      root: {
                        ...components?.MuiButton?.styleOverrides?.root,
                        '&:hover': {
                          ...components?.MuiButton?.styleOverrides?.root[
                            '&:hover'
                          ],
                          color: args.currentValue.hex,
                        },
                      },
                    },
                  },
                })
              )
            }
          />
        </Stack>
      </Stack>
      <Stack display="flex" flex="1">
        <FontConfiguration disabled={disabled} id="button" font={button} />
      </Stack>
      {/* <Stack
        gap="2%"
        flexWrap="wrap"
        sx={{ width: "100%", pt: "10px" }}
        flexDirection="unset"
      >
        <Stack flex="1">
          <Typography>Larghezza (px)</Typography>
          <BluNumberField
            disabled={disabled}
            value={components?.MuiButton?.styleOverrides?.root?.width ?? "auto"}
            onChange={() => {}}
          />
        </Stack>
        <Stack flex="1">
          <Typography>Altezza (px)</Typography>
          <BluNumberField
            disabled={disabled}
            value={
              components?.MuiButton?.styleOverrides?.root?.height ?? "auto"
            }
            onChange={() => {}}
          />
        </Stack>
      </Stack> */}
      <Stack
        gap="2%"
        flexWrap="wrap"
        sx={{ width: '100%' }}
        flexDirection="unset"
      >
        {BorderRadius}
        <Stack flex="1" justifyContent="end">
          <FormControlLabel
            control={
              <Switch
                checked={getBoxShadowValue()}
                onChange={handleBowShadowChange}
              />
            }
            label="Ombra"
          />
        </Stack>
      </Stack>
    </Stack>
  );
});
