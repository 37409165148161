/* eslint-disable @typescript-eslint/no-explicit-any */
import { BluSelect, BluTitle } from '@bludata/components';
import {
  Box,
  Checkbox,
  Chip,
  ListItemText,
  MenuItem,
  Typography,
} from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import { StudioInterface } from 'packages/blu-booking/src/interfaces/Studio';
import { RootState } from 'packages/blu-booking/src/store';
import { FC, memo, useCallback } from 'react';
import { Case, Default, Else, If, Switch, Then } from 'react-if';
import { useSelector } from 'react-redux';

interface StudiFilterInterface {
  selectedStudio: string[];
  studi: StudioInterface[];
  removeStudio: (value: string) => void;
  addStudio: (value: string) => void;
  resetStudio: (key: string) => void;
}

export const StudiFilter: FC<StudiFilterInterface> = memo(function StudiFilter({
  selectedStudio,
  studi,
  removeStudio,
  addStudio,
  resetStudio,
}): JSX.Element {
  const { requestState } = useSelector(
    (state: RootState) => state.bluBookingCalendarFilters
  );

  const getLoader = useCallback(
    () => requestState === 'loading',
    [requestState]
  );

  const getStudi = useCallback(() => {
    return studi.filter((studi) => studi.isActive);
  }, [studi]);

  return (
    <>
      <BluTitle sx={{ p: '5px 0px' }} text="Studi" />
      <Switch>
        <Case condition={requestState === 'error'}>
          <Typography variant="body2">
            Errore durante il caricamento degli Studi
          </Typography>
        </Case>
        <Default>
          <BluSelect
            displayEmpty
            multiple
            autoComplete="off" // Disabilita il completamento automatico
            value={selectedStudio}
            loading={getLoader()}
            renderValue={(selected) => {
              return (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  <If condition={requestState === 'loading'}>
                    <Then></Then>
                    <Else>
                      <If
                        condition={
                          selected.length === 0 ||
                          selected.length === getStudi().length
                        }
                      >
                        <Then>
                          <Chip
                            key={'allServicesSelected'}
                            label="Tutti gli studi"
                            size="small"
                          />
                        </Then>
                        <Else>
                          {selected.map((value: any) => (
                            <Chip
                              key={value}
                              size="small"
                              label={
                                studi.find((studio) => studio.id === value)
                                  ?.name
                              }
                            />
                          ))}
                        </Else>
                      </If>
                    </Else>
                  </If>
                </Box>
              );
            }}
            InputProps={{
              autoComplete: 'new-password',
            }}
          >
            {getStudi().map((studio) => (
              <CustomItem
                key={nanoid()}
                name={studio.name}
                id={studio.id}
                check={selectedStudio.indexOf(studio.id) > -1}
                full={selectedStudio.length === getStudi().length - 1}
                removeStudio={(id: string) => removeStudio(id)}
                resetStudio={() => resetStudio('selectedStudi')}
                addStudio={(id: string) => addStudio(id)}
              />
            ))}
            <MenuItem
              key={nanoid()}
              onClick={() => resetStudio('selectedStudi')}
            >
              <Checkbox
                checked={selectedStudio.length === 0}
                style={{ padding: 0 }}
              />
              <ListItemText primary="Tutti gli studi" />
            </MenuItem>
          </BluSelect>
        </Default>
      </Switch>
    </>
  );
});

interface CustomItemProps {
  name: string;
  id: string;
  check: boolean;
  full: boolean;
  removeStudio: (id: string) => void;
  resetStudio: () => void;
  addStudio: (id: string) => void;
}

const CustomItem = memo(
  ({
    name,
    id,
    check,
    full,
    removeStudio,
    resetStudio,
    addStudio,
  }: CustomItemProps): JSX.Element => {
    return (
      <MenuItem
        key={id}
        value={id}
        onClick={() => {
          if (check) {
            removeStudio(id);
          } else if (full) {
            resetStudio();
          } else {
            addStudio(id);
          }
        }}
      >
        <Checkbox checked={check} style={{ padding: 0 }} />
        <ListItemText primary={name} />
      </MenuItem>
    );
  }
);
