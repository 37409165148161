/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { mapColor } from '../../utility_objects/ColorManager';

interface serviceProps {
  userId?: string;
  pointOfSaleId?: string;
  lockPointOfSale?: boolean;
  lockUser: boolean;
  filtersLoaded?: boolean;
  shopSignId?: string;
}

export const servicesFilter = async ({
  lockPointOfSale,
  lockUser,
  userId,
  pointOfSaleId,
  shopSignId,
}: serviceProps): Promise<
  { id: string; name: string; hasStudio: boolean; icons?: string[] }[]
> => {
  try {
    const params: any = {
      shopSignId,
    };
    if (lockPointOfSale && pointOfSaleId !== '')
      params.pointOfSaleId = pointOfSaleId;
    if (lockUser && userId !== '') params.userId = userId;
    const {
      data: { data },
    } = await axios.get(`/api/Service/Filter`, { params });
    return data
      .map((service: any) => {
        return {
          ...service,
          color:
            service?.color !== null && service.color !== undefined
              ? service.color
              : mapColor[service.id],
        };
      })
      .flat();
  } catch (err) {
    console.trace(err);
    return [];
  }
};

export const useServicesFilter = (
  {
    lockPointOfSale,
    lockUser,
    userId,
    pointOfSaleId,
    shopSignId,
    filtersLoaded = true,
  }: serviceProps,
  key = ''
) => {
  return useQuery({
    queryFn: async () =>
      await servicesFilter({
        lockPointOfSale,
        lockUser,
        userId,
        pointOfSaleId,
        shopSignId,
      }),
    queryKey: [
      'services',
      'filter',
      lockUser ? userId : '',
      lockPointOfSale ? pointOfSaleId : '',
      shopSignId ?? '',
      key,
    ],
    enabled: filtersLoaded,
    retry: 2,
    retryDelay: 180000,
    cacheTime: 300000,
    staleTime: 300000,
    refetchInterval: 300000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
};
