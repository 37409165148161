import { TableHead, TableRow, TableCell, useTheme, Stack } from '@mui/material';
import { FC, memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { nanoid } from 'nanoid';
import dayjs from 'dayjs';

interface TableHeaderInterface {}
export const TableHeader: FC<TableHeaderInterface> = memo(() => {
  const { selectedDate } = useSelector((state: RootState) => state.rotaRoot);
  const theme = useTheme();
  console.log(
    'theme?.palette?.mode',
    theme?.palette?.mode === 'dark'
      ? 'var(--background-color) !important'
      : '#f0f0f0 !important'
  );

  const GetHeader = useMemo(() => {
    const endOfMonth = dayjs(selectedDate).endOf('month');
    const days = Array.from(
      { length: endOfMonth.get('date') },
      (_, i) => i + 1
    );
    return (
      <>
        {days.map((el: number) => {
          const date = dayjs(selectedDate).set('date', el);

          return (
            <TableCell
              id={`calendar-point-of-sale-header-${el}`}
              key={nanoid()}
              align="center"
              style={{
                width: '100px ',
                height: '40px',
                padding: 0,
                margin: 0,
                borderTop: '1px solid',
                borderColor: 'var(--divider-color)',
                boxSizing: 'border-box',
                zIndex: 0,

                // backgroundColor: "var(--closed-color)",
                // backgroundColor: "var(--background-color)!important",

                fontWeight: dayjs().isSame(date, 'day') ? 'bold' : undefined,
                color: dayjs().isSame(date, 'day')
                  ? // ? "var(--base-color)"
                    '#e3165b'
                  : undefined,
              }}
            >
              <Stack
                flex="1"
                sx={{
                  p: 0,
                  backgroundColor:
                    theme?.palette?.mode === 'dark'
                      ? 'var(--background-color) !important'
                      : '#f0f0f0 !important',
                  height: '38px',
                  textAlign: 'center',
                }}
                justifyContent={'center'}
              >
                {date.format('D ddd')}
              </Stack>
            </TableCell>
          );
        })}
      </>
    );
  }, [selectedDate, theme?.palette?.mode]);

  return (
    <TableHead>
      <TableRow
        style={
          {
            // backgroundColor: "var(--closed-color)",
          }
        }
      >
        {GetHeader}
      </TableRow>
    </TableHead>
  );
});
