/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  IconButton,
  Popper,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  memo,
  useCallback,
  useDeferredValue,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CloseIcon from '@mui/icons-material/Close';
import { MenuContainer } from './menu-items';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import {
  AppDispatch,
  RootState,
  useAppSelector,
} from 'packages/blu-booking/src/store';
import { bluBookingLoginData } from 'packages/blu-booking/src/store/root';
import { useUsersFilter } from 'packages/blu-booking/src/data_fetching/User/UserFilter';
import { setLockUser } from '../../../../store/AppointmentForm';

let allFilters = true;

export const User = memo(() => {
  // ! VARIABLES
  const { setValue, trigger } = useFormContext();
  const service = useWatch({ name: 'serviceId' });
  const pointOfSale = useWatch({ name: 'pointOfSaleId' });
  const user = useWatch({ name: 'userId' });
  const userActive = useWatch({ name: 'userActive' });
  const [maxHeight, setMaxHeight] = useState<number | 'auto'>('auto');

  const { users } = useSelector(
    (state: RootState) => state.bluBookingCalendarFilters
  );

  const { lockPointOfSale, lockService, openForm } = useSelector(
    (state: RootState) => state.bluBookingAppointmentForm
  );
  const { visibilityUserEnum, isManualAppointmentBlock, userId } =
    useAppSelector(bluBookingLoginData);

  // ? Popper anchor determines position
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  // ? Value of text field
  const [textfieldValue, setTextFieldValue] = useState<string>('');

  // ? Prevent popper to close on Other button click
  const [clickWithoutClose, setClickWithoutClose] = useState<boolean>(false);

  const [keyDown, setKeyDown] = useState<string | undefined>();

  // ? Ref of text field
  const inputRef = useRef<HTMLElement>(null);

  const dispatch = useDispatch<AppDispatch>();

  // ? Call for GET point of sale associated
  const { data: result } = useUsersFilter({
    lockService,
    lockPointOfSale,
    serviceId: service,
    pointOfSaleId: pointOfSale,
  });

  const data = useDeferredValue(result);

  // ! END

  // ! FUNCTIONS
  useEffect(() => {
    if (users !== undefined) {
      if (user in users) setTextFieldValue(`${users[user]?.username}`);
      else if (user === '') {
        if (visibilityUserEnum !== 4) setTextFieldValue('');
        else if (data) {
          if (userId && userId in users) {
            setTextFieldValue(users[userId].username);
            setValue('userId', users[userId].id);
            setValue('userActive', true);
          }
        }
      } else if (openForm?.userUsername && openForm.userUsername !== '') {
        setTextFieldValue(openForm?.userUsername);
        setValue('userActive', false);
      }
    }
  }, [
    data,
    openForm.userUsername,
    setValue,
    user,
    userId,
    users,
    visibilityUserEnum,
  ]);

  useEffect(() => {
    if (anchorEl) {
      const rect = anchorEl.getBoundingClientRect();
      const spaceBelow = window.innerHeight - rect.bottom; // Spazio sotto il campo
      const spaceAbove = rect.top; // Spazio sopra il campo
      const availableSpace = Math.max(spaceBelow, spaceAbove); // Usa lo spazio maggiore

      setMaxHeight(availableSpace - 10); // Imposta il maxHeight (con un limite massimo di 300px)
    }
  }, [anchorEl]);

  // ? Handle change on text field
  const handleOnChange = useCallback((event: any) => {
    if (allFilters) allFilters = false;
    setTextFieldValue(event.target.value);
  }, []);

  // ? handle open popper on focus input
  const handleClick = useCallback((): void => {
    if (visibilityUserEnum !== 4) setAnchorEl(inputRef?.current);
  }, [visibilityUserEnum]);

  // ? handle close popper
  const handleClose = useCallback((): void => {
    if (textfieldValue !== '' && textfieldValue !== openForm?.userUsername) {
      const set = Object.values(users).find(
        (p) => `${p.username}` === textfieldValue
      );
      if (set === undefined && user !== '')
        setTextFieldValue(`${users[user].username}`);
    }
    allFilters = true;
    setAnchorEl(null);
  }, [openForm?.userUsername, textfieldValue, user, users]);

  // ? handle blur on input
  const manageOnBlur = useCallback(() => {
    if (textfieldValue === '') {
      setValue('userId', '');
      setValue('userActive', true);
      trigger();
    }
    if (!clickWithoutClose) handleClose();
    else setClickWithoutClose(false);
    // else otherCLicked = false;
  }, [clickWithoutClose, handleClose, setValue, textfieldValue, trigger]);

  // ? erase input value
  const handleErase = useCallback(() => {
    setClickWithoutClose(true);
    setTextFieldValue('');
    setValue('userId', '');
    setValue('userActive', true);
    trigger();
  }, [setValue, trigger]);

  // ? Handle click on MenuItem
  const handleItemCLick = useCallback(
    (id: string, username: string, lock = false) => {
      if (id === null) {
        setValue('userId', '');
        trigger();
        return;
      }
      setValue('userId', id);
      setTextFieldValue(`${username}`);
      if (!userActive) setValue('userActive', true);
      dispatch(setLockUser(lock));
      setValue('cercaDisponibilitaUsed', false);

      trigger();
    },
    [dispatch, setValue, trigger, userActive]
  );

  // ? get options
  const getOptions = useCallback(() => {
    const temp = JSON.parse(JSON.stringify(users));
    let result = {
      association: [],
      unassociation: [],
    };
    if (isManualAppointmentBlock || visibilityUserEnum === 4) {
      if (data) {
        if (data.length)
          result = {
            association: data as any,
            unassociation: [],
          };
      }
    } else if (data !== undefined) {
      if (data.length === 0)
        result = {
          ...result,
          unassociation: Object.values(users).filter(
            (u: any) => u.isActive && u.hasAgenda
          ) as any,
        };
      else {
        for (const userValue of data) delete temp[userValue.id];
        result = {
          association: data as any,
          unassociation: Object.values(temp).filter(
            (u: any) => u.isActive && u.hasAgenda
          ) as any,
        };
      }
    }
    if (!allFilters)
      return {
        association: result.association.filter((value: any) => {
          const reg = new RegExp(textfieldValue, 'i');
          return reg.test(`${value.username}`);
        }),
        unassociation: result.unassociation.filter((value) => {
          const reg = new RegExp(textfieldValue, 'i');
          return reg.test(`${(value as any).username}`);
        }),
      };
    return result;
  }, [
    data,
    isManualAppointmentBlock,
    textfieldValue,
    users,
    visibilityUserEnum,
  ]);

  // ? Erase icon
  const Erase = useMemo(() => {
    if (open)
      return (
        <IconButton
          sx={{ width: '24px', height: '24px', p: 0, m: 0 }}
          onMouseDown={handleErase}
          onClick={() => inputRef.current?.focus()}
        >
          <CloseIcon sx={{ fontSize: '20px' }} />
        </IconButton>
      );
    return null;
  }, [handleErase, open]);

  // ? Arrow open and close
  const Arrow = useMemo(() => {
    if (open)
      return (
        <IconButton
          sx={{ width: '24px', height: '24px', p: 0, m: 0 }}
          onClick={handleClose}
          disabled={visibilityUserEnum === 4}
        >
          <ArrowDropUpIcon sx={{ cursor: 'pointer' }} />
        </IconButton>
      );
    return (
      <IconButton
        sx={{ width: '24px', height: '24px', p: 0, m: 0 }}
        onClick={(event) => {
          handleClick();
          inputRef.current?.focus();
        }}
        disabled={visibilityUserEnum === 4}
      >
        <ArrowDropDownIcon />
      </IconButton>
    );
  }, [handleClick, handleClose, open, visibilityUserEnum]);

  const GetError = useMemo(() => {
    if (!userActive) {
      return (
        <Tooltip title={"L'utente selezionato non è attivo"}>
          <ErrorTwoToneIcon color="error" />
        </Tooltip>
      );
    }
    return null;
  }, [userActive]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    event.stopPropagation();
    if (
      event.key === 'Enter' ||
      event.key === 'ArrowUp' ||
      event.key === 'ArrowDown'
    ) {
      if (!open) {
        handleClick();
        return;
      }
    }
    if (event.key === 'Escape') {
      handleClose();
    }
    if (
      event.key !== 'ArrowUp' &&
      event.key !== 'ArrowDown' &&
      event.key !== 'Enter'
    )
      return;
    setKeyDown(event.key);
  };
  // ! END

  return (
    <Box style={{ width: '49%' }} onKeyDown={handleKeyDown}>
      <Typography variant="subtitle2" sx={{ pt: '3px' }}>
        Utente
      </Typography>
      <TextField
        inputRef={inputRef}
        id="point_of_sale_selector"
        onBlur={manageOnBlur}
        onFocus={handleClick}
        value={textfieldValue}
        placeholder="Seleziona un utente"
        error={!userActive}
        InputProps={{
          autoComplete: 'off',
          readOnly: visibilityUserEnum === 4,
          startAdornment: (
            <Stack direction="row" pr={1} justifyItems="center"></Stack>
          ),
          endAdornment: (
            <>
              {Erase}
              {GetError}
              {Arrow}
            </>
          ),
        }}
        onChange={handleOnChange}
        fullWidth
      />
      <Popper
        open={open}
        style={{ zIndex: 1301, width: 'fit-content' }}
        anchorEl={anchorEl}
        placement="bottom-start"
        modifiers={[
          {
            name: 'preventOverflow',
            options: {
              boundary: 'window', // Impedisce che il popper esca dalla finestra
            },
          },
          {
            name: 'flip',
            options: {
              fallbackPlacements: ['top-start', 'bottom-start'], // Permette di invertire la posizione se necessario
            },
          },
        ]}
      >
        <Box
          sx={{
            maxHeight: maxHeight !== 'auto' ? `${maxHeight}px` : maxHeight, // Altezza dinamica
            overflowY: 'auto', // Abilita lo scroll se necessario
            background: 'white',
          }}
        >
          <MenuContainer
            userId={user}
            keyDown={keyDown}
            setKeyDown={setKeyDown}
            options={getOptions()}
            textfieldValue={textfieldValue}
            width={inputRef?.current?.clientWidth}
            focus={() => inputRef?.current?.focus()}
            handleClick={handleItemCLick}
            setClickWithoutClose={(v: boolean) => setClickWithoutClose(v)}
            handleClose={handleClose}
          />
        </Box>
      </Popper>
    </Box>
  );
});
