import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import React, { FC, ReactElement, useCallback, useRef } from 'react';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import { useReactToPrint } from 'react-to-print';
import '../../styles/print.css';
import { render } from 'react-dom';

interface CommonProps {
  show: boolean;
  handleClose: () => void;
  text: string | ReactElement;
  title?: string;
  handleConfirm?: () => void;
  maxWidth?: 'sm' | 'md' | 'lg';
  print?: {
    printHead: ReactElement;
    printFooter: ReactElement;
  };
}

interface LoadingProps extends CommonProps {
  type: 'loading';
  loadingText: string;
}

interface NonLoadingProps extends CommonProps {
  type?: 'warning' | 'error' | 'info' | 'success';
  loadingText?: never;
}

export type BluAlertProps = LoadingProps | NonLoadingProps;

const iconDimensions = { fontSize: '80px', mb: '10px' };

const BluAlert: FC<BluAlertProps> = React.memo(
  (props) => {
    const { handleClose, type, handleConfirm } = props;
    const handleCloseTap = useCallback(() => {
      handleClose();
    }, [handleClose]);

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
      content: () => {
        const newPage = document.createElement('div');
        const contentClone = (componentRef.current as any).cloneNode(true);
        const container = (
          <div>
            {props.print?.printHead}
            <div id="content-blualert"></div>
            {props.print?.printFooter}
          </div>
        );
        render(container, newPage);
        newPage
          .querySelectorAll("[id^='content-blualert']")[0]
          .appendChild(contentClone);
        return newPage;
      },
    });

    let testo: string | ReactElement | ReactElement[] = props.text;

    if (typeof props.text === 'string') {
      testo = props.text
        .split('\n')
        .map((item, i) => <Typography key={i}>{item}</Typography>);
    }

    const handleKeyDown = useCallback(
      (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter' && handleConfirm) {
          handleConfirm();
        }
        if (event.key === 'Escape' && handleConfirm) {
          handleClose();
        }
      },
      [handleConfirm, handleClose]
    );

    return (
      <Dialog
        onKeyDown={handleKeyDown}
        open={props.show}
        fullWidth
        fullScreen={false}
        maxWidth={props.maxWidth ? props.maxWidth : 'xs'}
        className="bludata-blu-alert"
      >
        <DialogTitle
          style={{
            backgroundColor: 'var(--background-color)',
            color: 'var(--color)',
            textAlign: 'center',
          }}
        >
          {!!type && (
            <Stack flexDirection="column" alignItems="center">
              {type === 'warning' && (
                <WarningAmberIcon color="warning" sx={iconDimensions} />
              )}
              {type === 'info' && (
                <InfoOutlinedIcon color="primary" sx={iconDimensions} />
              )}
              {type === 'error' && (
                <ErrorOutlineOutlinedIcon color="error" sx={iconDimensions} />
              )}
              {type === 'success' && (
                <CheckCircleIcon color="success" sx={iconDimensions} />
              )}
            </Stack>
          )}

          {props.title && props.title}
        </DialogTitle>
        <DialogContent ref={componentRef}>
          <DialogContentText component="div" sx={{ color: 'var(--color)' }}>
            {testo}

            {type === 'loading' && (
              <Stack
                spacing={2}
                direction={'column'}
                sx={{ overflow: 'hidden' }}
                flex={1}
                alignItems={'center'}
              >
                <CircularProgress
                  color="primary"
                  thickness={1.5}
                  size={'60px'}
                />
                <Typography>{props.loadingText}</Typography>
              </Stack>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Stack
            direction={'row'}
            justifyContent="flex-start"
            alignItems="center"
            flex={1}
            spacing={0.5}
          >
            {props.print && (
              <IconButton onClick={handlePrint}>
                <LocalPrintshopIcon />
              </IconButton>
            )}
            <div style={{ flex: 1 }}></div>
            <Button
              sx={{ justifyContent: 'center', minWidth: '80px' }}
              variant="outlined"
              onClick={handleCloseTap}
            >
              {handleConfirm ? 'No' : 'Chiudi'}
            </Button>
            {handleConfirm && (
              <Button
                sx={{ justifyContent: 'center', minWidth: '80px' }}
                variant="outlined"
                onClick={handleConfirm}
              >
                Sì
              </Button>
            )}
          </Stack>
        </DialogActions>
      </Dialog>
    );
  },
  (oldProps, newProps) => {
    return (
      oldProps.show === newProps.show &&
      oldProps.text === newProps.text &&
      oldProps.title === newProps.title &&
      oldProps.handleClose === newProps.handleClose &&
      oldProps.handleConfirm === newProps.handleConfirm &&
      oldProps.maxWidth === newProps.maxWidth &&
      oldProps.type === newProps.type
    );
  }
);
export default BluAlert;
