import { BluAlert, BluDialog, BluLoader, BluTitle } from '@bludata/components';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import LockIcon from '@mui/icons-material/Lock';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import User_Interfaces from '../../../interfaces/user_interfaces';
import ImageDefault from '../../../assets/utentebase.png';
import BadgeIcon from '@mui/icons-material/Badge';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import PasswordDialog from './PasswordDialog';
import updatePassword from './api/updatePassword';
import updatePhoto from './api/updatePhoto';
import { eraseCookie } from '../../../lib/cookie';
import { setPhoto } from '../../../store/root';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import isImageValid from '../utils/isImageValid';
import { useUserMe } from '../../../api/user-me';
import { useQueryClient } from '@tanstack/react-query';

const UserDialog: FC<{ show: boolean; onClose: () => void }> = (props) => {
  const { show, onClose } = props;
  const [error, setError] = useState('');
  const [logout, setLogout] = useState(false);
  const [user, setUser] = useState<User_Interfaces>();
  const [openPasswordDialog, setOpenPasswordDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageSrc, setImageSrc] = useState(ImageDefault);
  const dispatch = useDispatch();
  const { photo, software } = useSelector((state: RootState) => state.root);
  const { data, isLoading } = useUserMe();
  const queryClient = useQueryClient();

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    if (data) setUser(data);
  }, [data]);

  useEffect(() => {
    if (photo) {
      isImageValid(photo, (isValid: any) => {
        if (isValid) {
          setImageSrc(photo);
        } else {
          setImageSrc(ImageDefault);
        }
      });
    }
  }, [photo]);

  console.log(software);

  return (
    <>
      <BluAlert
        show={!!error}
        handleClose={() => {
          setError('');
        }}
        text={error}
        type="error"
      />

      <BluAlert
        show={logout}
        handleClose={() => {
          setLogout((x) => !x);
        }}
        handleConfirm={() => {
          eraseCookie('BluBookingUserPhoto');
          eraseCookie('BluBookingUserAdmin');
          eraseCookie('BluBookingpointOfSaleId');
          eraseCookie('BluBookingUserPhoto');
          eraseCookie('BluBookingToken');
          eraseCookie('BluBookingRefreshToken');
          window.location.reload();
        }}
        text={
          'Sei sicuro di voler uscire da Blu Booking?\nTutte le modifiche non salvate andranno perse.'
        }
        type="warning"
      />
      <PasswordDialog
        openPasswordDialog={openPasswordDialog}
        closeDialog={(oldPassword, newPassword) => {
          setLoading(true);
          if (oldPassword && newPassword) {
            updatePassword(oldPassword, newPassword)
              .then(() => {
                setOpenPasswordDialog(false);
                setLoading(false);
                queryClient.invalidateQueries(['user', 'me'], {
                  type: 'active',
                });
                queryClient.removeQueries(['user', 'me'], {
                  type: 'inactive',
                });
              })
              .catch((err) => {
                setOpenPasswordDialog(false);
                setLoading(false);
                setError(err);
              });
          } else {
            setLoading(false);
            setOpenPasswordDialog(false);
          }
        }}
      />
      <BluDialog
        open={show}
        zIndex={1}
        maxWidth={'sm'}
        draggable
        dialogContent={
          user && (
            <Stack direction={'row'}>
              <BluLoader open={loading || isLoading} />
              <Stack direction="column" flex={1}>
                <Divider sx={{ opacity: 0, mb: '12px!important' }} />
                <Typography variant="h6">
                  <b>Profilo di {user?.username}</b>
                </Typography>
                <Divider sx={{ opacity: 0, mb: '12px!important' }} />
                <BluTitle text="Informazioni" />
                <FormControlLabel
                  sx={{ cursor: 'default' }}
                  control={
                    <Checkbox disabled name="agenda" checked={user.hasAgenda} />
                  }
                  label={
                    <Stack
                      direction={'row'}
                      justifyContent="center"
                      alignItems="center"
                    >
                      Utente agenda
                      <Box
                        sx={{
                          ml: 1,
                          width: 15,
                          height: 15,
                          borderRadius: '50%',
                          border: '1px solid var(--divider-color)',
                          background: user.color ?? '#FFFFFF',
                        }}
                      />
                    </Stack>
                  }
                />
                <Typography variant="body1">
                  Sigla: <b>{user.sign}</b>
                </Typography>
                {user.pointOfSaleData && (
                  <Typography variant="body1">
                    Punto vendita: <b>{user.pointOfSaleData.name}</b>
                  </Typography>
                )}
                <Divider sx={{ opacity: 0, mb: '12px!important' }} />
                <BluTitle text="Contatti" />
                <Typography variant="body1">
                  Email:<b>{user.email}</b>
                </Typography>
                <Typography variant="body1">
                  Telefono:<b>{user.telephone}</b>
                </Typography>
                <Typography variant="body1">
                  Cellulare:<b>{user.cellular}</b>
                </Typography>
                <Divider sx={{ opacity: 0, mb: '12px!important' }} />
                {user.pointOfSaleData && (
                  <>
                    <BluTitle text="Indirizzo" />

                    <Typography variant="body1">
                      Indirizzo: <b>{user.pointOfSaleData.address}</b>
                    </Typography>
                  </>
                )}
              </Stack>
              <Stack
                flex={1}
                maxWidth={350}
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <>
                  <Avatar
                    sx={{
                      width: 128,
                      height: 128,
                      border: '2px solid var(--base-color)',
                    }}
                    src={imageSrc}
                  />
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                  >
                    {software === 2 && (
                      <>
                        <Button
                          color="primary"
                          component="label"
                          startIcon={<FileUploadIcon />}
                          onClick={() => null}
                        >
                          Carica
                          <input
                            accept="image/png, image/jpeg, image/jpg"
                            type="file"
                            hidden
                            onChange={(e: any) => {
                              if (e.target.files.length === 0) return;
                              setLoading(true);
                              const fileReader = new FileReader();

                              if (e.target.files[0].size > 5 * 1048576) {
                                setError('La dimensione massima è 5 mb');
                                return;
                              }

                              fileReader.readAsDataURL(e.target.files[0]);
                              fileReader.onloadend = async (event: any) => {
                                updatePhoto(
                                  user.id,
                                  user.companyId,
                                  false,
                                  e.target.files[0]
                                )
                                  .then((res) => {
                                    dispatch(setPhoto(event.target.result));
                                    queryClient.invalidateQueries(
                                      ['user', 'me'],
                                      {
                                        type: 'active',
                                      }
                                    );
                                    queryClient.removeQueries(['user', 'me'], {
                                      type: 'inactive',
                                    });
                                    setLoading(false);
                                  })
                                  .catch((err) => {
                                    setError(err);
                                    setLoading(false);
                                  });
                              };
                            }}
                          />
                        </Button>
                        <Button
                          color="primary"
                          variant="outlined"
                          startIcon={<BadgeIcon />}
                          onClick={() => {
                            setLoading(true);
                            updatePhoto(
                              user.id,
                              user.companyId,
                              true,
                              undefined
                            )
                              .then(() => {
                                queryClient.invalidateQueries(['user', 'me'], {
                                  type: 'active',
                                });
                                queryClient.removeQueries(['user', 'me'], {
                                  type: 'inactive',
                                });
                                dispatch(setPhoto(ImageDefault));
                                setLoading(false);
                              })
                              .catch((err) => {
                                setError(err);
                                setLoading(false);
                              });
                          }}
                        >
                          Default
                        </Button>
                      </>
                    )}
                  </Stack>
                </>
              </Stack>
            </Stack>
          )
        }
        dialogTitle={
          <Stack
            direction={'row'}
            justifyContent={'left'}
            alignItems={'center'}
            spacing={0.5}
          >
            <PersonIcon />
            <BluTitle
              sx={{ color: 'var(--reverse-color)' }}
              text={'Informazioni utente'}
            />
          </Stack>
        }
        dialogActions={
          <>
            <Button
              variant="outlined"
              startIcon={<LogoutIcon />}
              onClick={() => {
                setLogout((x) => !x);
              }}
            >
              Logout
            </Button>
            {software === 2 && (
              <Button
                variant="outlined"
                startIcon={<LockIcon />}
                onClick={() => {
                  setOpenPasswordDialog(true);
                }}
              >
                Cambia password
              </Button>
            )}
            <Box flex={1}></Box>
            <Button onClick={handleClose} variant="outlined">
              Chiudi
            </Button>
          </>
        }
      />
    </>
  );
};

export default UserDialog;
