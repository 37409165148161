import 'yup-phone-lite';
import controlloPivaCodFisc from '../../../../../util/controlloPivaCodFisc';
import { object, string } from 'yup';

const phoneRegExp = /^[+]?\d*([\s.-]?\d+)*$/;

export const schema = object()
  .shape({
    telephone: string()
      .matches(phoneRegExp, {
        message: 'Campo errato in Informazioni generali: Telefono',
        excludeEmptyString: true,
      })
      .max(15, 'Campo troppo lungo in Informazioni generali: Telefono')
      .nullable(),
    email: string()
      .email('Campo errato in Informazioni generali: Email')
      .max(50, 'Campo troppo lungo in Informazioni generali: Email')
      .nullable(),
    fax: string()
      .matches(phoneRegExp, {
        message: 'Campo errato in Informazioni generali: Fax',
        excludeEmptyString: true,
      })
      .nullable()
      .max(15, 'Campo troppo lungo in Informazioni generali: Fax')
      .nullable(),
    internalCode: string()
      .max(12, 'Campo troppo lungo in Informazioni generali: Sigla')
      .nullable(),
    name: string()
      .max(80, 'Campo troppo lungo in Informazioni generali: Ragione sociale')
      .nullable(),
    nameOnline: string().nullable(),
    address: string()
      .max(70, 'Campo troppo lungo in Informazioni generali: Indirizzo')
      .nullable(),
    city: string()
      .max(60, 'Campo troppo lungo in Informazioni generali: Città')
      .nullable(),
    region: string()
      .max(50, 'Campo troppo lungo in Informazioni generali: Provincia')
      .nullable(),
    taxCode: string()
      .test(
        'codcieFiscale',
        'Campo errato in Informazioni generali: Codice Fiscale',
        (value) => {
          return !value
            ? true
            : controlloPivaCodFisc(value || '', true, false) === true;
        }
      )
      .nullable(),
    vatNumber: string()
      .test(
        'partitaIva',
        'Campo errato in Informazioni generali: Partita IVA',
        (value) => {
          return !value
            ? true
            : controlloPivaCodFisc(value || '', false, false) === true;
        }
      )
      .nullable(),
    postalCode: string()
      .matches(/^[0-9\s]*$/, 'Il Campo deve essere un numeri: CAP')
      .nullable(),
  })
  .required();
