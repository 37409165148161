import {
  Box,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import { useCallback, useState } from 'react';
import { memo } from 'react';
import { RootState } from '../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { BluAlert } from '@bludata/components';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import Markdown from 'react-markdown';
import { content } from './content';
import { MessageStatusEnum } from 'packages/blu-booking/src/interfaces/Notifications';
import { setMessageStatusFilter, setPage } from '../../store/Notifications';

export const MessageStatusFilter = memo(() => {
  const [open, setOpen] = useState(false);
  const { messageStatusFilter } = useSelector(
    (state: RootState) => state.bluBookingNotifications
  );
  const dispatch = useDispatch();

  const handleMessageTypeFilterChange = useCallback(
    (event: SelectChangeEvent) => {
      dispatch(setPage(1));

      if (event.target.value === '6') {
        dispatch(setMessageStatusFilter(undefined));
        return;
      }
      dispatch(
        setMessageStatusFilter(
          parseInt(event.target.value) as MessageStatusEnum
        )
      );
    },
    [dispatch]
  );

  const getValue = useCallback(() => {
    if (messageStatusFilter !== undefined) {
      return messageStatusFilter.toString();
    }
    return '6';
  }, [messageStatusFilter]);

  return (
    <Stack>
      <BluAlert
        show={open}
        maxWidth="md"
        text={
          <Stack flex="1">
            <Markdown className="reactMarkDown">{content}</Markdown>
          </Stack>
        }
        handleClose={() => setOpen(false)}
        type="info"
        title="Informazioni"
      />
      <Stack direction="row">
        <Stack flex="1">
          <Typography>Stato del messaggio</Typography>
        </Stack>
        <IconButton
          sx={{
            p: '2px',
            backgroundColor: '#f5f5f5', // Colore di sfondo predefinito
            '.dark-mode-bludata &': {
              backgroundColor: '#404040', // Colore di sfondo in modalità dark
            },

            '& svg': { width: '15px!important', height: '15px!important' },
          }}
          size="small"
          onClick={() => {
            setOpen(true);
          }}
        >
          <QuestionMarkIcon />
        </IconButton>
      </Stack>

      <Select
        value={getValue()}
        onChange={handleMessageTypeFilterChange}
        sx={{ width: 200 }}
      >
        <MenuItem value={MessageStatusEnum['error']}>
          <Stack flex="1" direction="row" gap="1">
            <Box
              sx={{
                width: '20px',
                height: '20px',
                borderRadius: '50%',
                bgcolor: '#fee11a',
                mr: '5px',
              }}
            />
            Dati mancanti
          </Stack>
        </MenuItem>
        <MenuItem value={MessageStatusEnum['sendingerror']}>
          <Stack flex="1" direction="row" gap="1">
            <Box
              sx={{
                width: '20px',
                height: '20px',
                borderRadius: '50%',
                bgcolor: 'rgba(255, 43, 43, 0.8)',
                mr: '5px',
              }}
            />
            Errore invio
          </Stack>
        </MenuItem>
        <MenuItem value={MessageStatusEnum['success']}>
          <Stack flex="1" direction="row" gap="1">
            <Box
              sx={{
                width: '20px',
                height: '20px',
                borderRadius: '50%',
                bgcolor: 'var(--confirm-color)',
                mr: '5px',
              }}
            />
            Successo
          </Stack>
        </MenuItem>
        <MenuItem value={MessageStatusEnum['pending']}>
          <Stack flex="1" direction="row" gap="1">
            <Box
              sx={{
                width: '20px',
                height: '20px',
                borderRadius: '50%',
                bgcolor: '#64b5f6',
                mr: '5px',
              }}
            />
            In attesa
          </Stack>
        </MenuItem>
        <MenuItem value={MessageStatusEnum['skipped']}>Invio saltato</MenuItem>
        <MenuItem value={'6'}>Tutti</MenuItem>
      </Select>
    </Stack>
  );
});
