import { FC, memo, useCallback, useEffect, useState } from 'react';
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { BluContainerBlock, BluTitle } from '@bludata/components';
import { Header } from '../../UI/Header';
import { Search } from './components/Search';
import { StudiGrid } from './components/Grid';
import { StudiPopup } from './components/Popup';
import AddIcon from '@mui/icons-material/Add';
import ModeIcon from '@mui/icons-material/Mode';
import { AppDispatch, RootState } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentStudio, setOpenForm } from './store/root';
import WebAssetIcon from '@mui/icons-material/WebAsset';

interface ServicePageInterface {
  root?: string;
}

export const StudioPage: FC<ServicePageInterface> = memo(({ root }) => {
  const [search, setSearch] = useState<string>('');
  const [gridSearch, setGridSearch] = useState<string>('');
  const [active, setActive] = useState<boolean>(false);

  const { currentStudio } = useSelector((state: RootState) => state.studiRoot);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const delayFn = setTimeout(() => {
      if (search !== gridSearch) setGridSearch(search);
    }, 250);

    return () => {
      clearTimeout(delayFn);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const handleOnChange = useCallback((event: any) => {
    setSearch(event.target.value);
  }, []);

  const handleOnChangeActive = useCallback((event: any) => {
    setActive(event.target.checked);
  }, []);

  return (
    <>
      <Header title={`${root ?? ''}Studi`} />
      <BluContainerBlock
        sx={{
          m: '4px',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          flex: '1',
        }}
      >
        <>
          <StudiPopup />
          <BluTitle text={'Lista studi'} />
          <Stack spacing={1}>
            <Typography color="text.primary" sx={{ paddingTop: '5px' }}>
              In questa sezione è possibile configurare l'elenco degli studi
            </Typography>
            <div>
              <Typography component="span" color="text.primary">
                Premi il tasto&nbsp;
              </Typography>
              <Typography
                component="span"
                color="text.primary"
                sx={{ fontWeight: 'bold' }}
              >
                Scheda&nbsp;
              </Typography>
              <Typography component="span" color="text.primary">
                per visualizzare le impostazioni di un studio e&nbsp;
              </Typography>
              <Typography
                component="span"
                color="text.primary"
                sx={{ fontWeight: 'bold' }}
              >
                Modifica&nbsp;
              </Typography>
              <Typography component="span" color="text.primary">
                per modificarne uno di esistente.
              </Typography>
            </div>
          </Stack>
          <Divider sx={{ m: '7px 0 7px 0' }} />
          <Search
            search={search}
            handleOnChange={handleOnChange}
            active={active}
            handleOnChangeActive={handleOnChangeActive}
          />
          <StudiGrid search={gridSearch} active={active} />
          <Stack direction="row" sx={{ mt: '8px' }}>
            <Box flex={1}></Box>
            <Stack direction="row" spacing={0.5}>
              <Button
                color="primary"
                startIcon={<AddIcon />}
                variant="contained"
                onClick={() => {
                  dispatch(setCurrentStudio(undefined));
                  dispatch(setOpenForm('new'));
                }}
              >
                Nuovo
              </Button>
              <Button
                color="primary"
                startIcon={<ModeIcon />}
                variant="outlined"
                disabled={currentStudio === undefined}
                onClick={() => dispatch(setOpenForm('modify'))}
              >
                Modifica
              </Button>
              <Button
                color="primary"
                startIcon={<WebAssetIcon />}
                variant="outlined"
                disabled={currentStudio === undefined}
                onClick={() => dispatch(setOpenForm('info'))}
              >
                Scheda
              </Button>
            </Stack>
          </Stack>
        </>
      </BluContainerBlock>
    </>
  );
});
