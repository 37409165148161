import {
  Stack,
  Button,
  InputLabel,
  FormControlLabel,
  Typography,
  FormControl,
  RadioGroup,
  Radio,
} from '@mui/material';
import { FC, memo, useCallback, useMemo, useState } from 'react';
import { BluDatePicker, BluDialog, BluLoader } from '@bludata/components';
import { PointOfSaleImport } from './PointOfSale';
import axios from 'axios';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { getRotaPath } from '../../lib/white-cache';
import PointOfSale_interface from '../../../nuova-point-of-sales/interfaces/PointOfSale_interface';
import { ExportSuccess } from './ExportSuccess';
import { ExportError } from './ExportError';
// import { fileSave, supported } from "browser-fs-access";

// function base64ToBlob(base64: any, mime: any) {
//   const byteCharacters = atob(base64);
//   const byteNumbers = new Array(byteCharacters.length);
//   for (let i = 0; i < byteCharacters.length; i++) {
//     byteNumbers[i] = byteCharacters.charCodeAt(i);
//   }
//   const byteArray = new Uint8Array(byteNumbers);
//   return new Blob([byteArray], { type: mime });
// }

interface ExportPopupInterface {
  isSingleCalendarManagement: boolean;
  pointOfSale?: string;
  setExportFile: (value: boolean) => void;
  pointOfSales?: PointOfSale_interface[];
}
export const ExportPopup: FC<ExportPopupInterface> = memo(
  ({
    isSingleCalendarManagement,
    pointOfSale,
    setExportFile,
    pointOfSales,
  }) => {
    const [pointOfSaleId, setPointOfSaleId] = useState<string>(
      pointOfSale ?? ''
    );
    const [orarioStandard, setOrariStandard] = useState<boolean>(true);
    const [startDate, setStartDate] = useState<Dayjs>(dayjs());
    const [endDate, setEndDate] = useState<Dayjs>(dayjs().add(7, 'day'));
    const [loading, setLoading] = useState<boolean>(false);
    const [step, setStep] = useState<string>('default');

    const handleSave = useCallback(async () => {
      try {
        if (startDate && endDate) {
          setLoading(true);
          let url = 'DualCalendarExport';
          if (isSingleCalendarManagement) url = 'SharedCalendarExport';

          let pointOfSaleName: any = '';
          if (pointOfSales && pointOfSaleId) {
            const pointOfSale = pointOfSales.find(
              (s) => s.id === pointOfSaleId
            );
            pointOfSaleName = pointOfSale?.branch;
          }
          const { data } = await axios.get(
            `${getRotaPath()}/${url}/Export/${`export_${pointOfSaleName}.xlsx`}`,
            {
              headers: {
                'Content-Type': 'application/vnd.ms-excel',
              },
              params: {
                pointOfSaleId,
                startDate: orarioStandard
                  ? undefined
                  : startDate.format('YYYY-MM-DD'),
                endDate: orarioStandard
                  ? undefined
                  : endDate.format('YYYY-MM-DD'),
              },
            }
          );
          // const { isFocus10 } = getRotaWhiteCache();

          // if (supported && !isFocus10) {
          //   try {
          //     const blob = base64ToBlob(
          //       data,
          //       "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          //     );

          //     const response = await fileSave(blob, {
          //       fileName: `orari_${
          //         orarioStandard ? "predefiniti" : "personalizzati"
          //       }_${pointOfSaleName}.xlsx`,
          //       extensions: [".xlsx"],
          //     });
          //     console.log("response", response);
          //   } catch (error: any) {
          //     console.error(error);
          //     console.log("MESSAGE", error?.message);
          //     if (error?.message === "The user aborted a request.") {
          //       setLoading(false);
          //       return;
          //     }
          //   }
          // } else {
          const a = document.createElement('a');
          a.href = `data:application/octet-stream;base64,${data}`;
          a.download = `orari_${
            orarioStandard ? 'predefiniti' : 'personalizzati'
          }_${pointOfSaleName}.xlsx`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          URL.revokeObjectURL(data);
          // }
          // saveAs(blob, `orari_${pointOfSaleName}.xlsx`);

          setLoading(false);
          setStep('success');
          return;
        }
      } catch (error) {
        console.error(error);
      }
      setStep('error');

      // BluAsyncDialog({
      //   title: "Attenzione",
      //   type: "error",
      //   message: "Errore durante l'esportazione del calendario.",
      //   hideDeclineButton: true,
      //   confimButton: "Chiudi",
      //   sx: { "& .MuiDialog-paper": { maxWidth: "370px" } },
      // });
    }, [
      endDate,
      isSingleCalendarManagement,
      orarioStandard,
      pointOfSaleId,
      pointOfSales,
      startDate,
    ]);

    const handleValueChange = useCallback((newValue: any, name: string) => {
      if (name === 'endDate') setEndDate(newValue);
      else setStartDate(newValue);
    }, []);

    const GetContent = useMemo(() => {
      if (step === 'default')
        return (
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'it'}>
            <Typography
              fontSize="20px"
              fontWeight="bold"
              sx={{ p: '15px 0 10px 0' }}
              // align="center"
            >
              Esporta calendario
            </Typography>
            <Typography>
              Seleziona la filiale / punto vendita di cui vuoi esportare il
              calendario in formato xlxs. Scegli se esportare il calendario
              predefinito oppure il calendario che hai utilizzato in un periodo
              specifico di tempo.
            </Typography>
            <BluLoader open={loading} sx={{ zIndex: '1400' }} />
            <PointOfSaleImport
              pointOfSaleId={pointOfSaleId}
              setPointOfSaleId={setPointOfSaleId}
              setLoading={setLoading}
              pointOfSales={pointOfSales}
            />
            <Stack sx={{ pb: '10px' }}>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={orarioStandard ? 'predefined' : 'custom'}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setOrariStandard(
                      (event.target as HTMLInputElement).value === 'predefined'
                    );
                  }}
                >
                  <FormControlLabel
                    value="predefined"
                    control={<Radio />}
                    label="Orario predefinito"
                  />
                  <FormControlLabel
                    value="custom"
                    control={<Radio />}
                    label="Orario personalizzato"
                  />
                </RadioGroup>
              </FormControl>

              {!orarioStandard && (
                <Stack direction="row" flex="1" gap={1} sx={{ p: '0 30px' }}>
                  <Stack flex="1">
                    <InputLabel>Data inizio</InputLabel>

                    <BluDatePicker
                      value={startDate}
                      disabled={orarioStandard}
                      // minDate={new Date()}
                      onChange={(newValue: any) =>
                        handleValueChange(newValue, 'startDate')
                      }
                    />
                  </Stack>
                  <Stack flex="1">
                    <InputLabel>Data fine</InputLabel>
                    <BluDatePicker
                      value={endDate}
                      minDate={startDate.clone().add(1, 'day').toDate()}
                      maxDate={startDate.clone().add(31, 'day').toDate()}
                      disabled={orarioStandard}
                      onChange={(newValue: any) =>
                        handleValueChange(newValue, 'endDate')
                      }
                    />
                  </Stack>
                </Stack>
              )}
            </Stack>
          </LocalizationProvider>
        );
      else if (step === 'success') return <ExportSuccess />;
      return <ExportError />;
    }, [
      endDate,
      handleValueChange,
      loading,
      orarioStandard,
      pointOfSaleId,
      pointOfSales,
      startDate,
      step,
    ]);

    const GetActions = useMemo(() => {
      if (step === 'default')
        return (
          <Stack flex="1" justifyContent="end" direction="row" gap={1}>
            <Stack flex="1">
              <Button
                variant="outlined"
                sx={{ width: 'fit-content' }}
                onClick={() => setExportFile(false)}
              >
                Annulla
              </Button>
            </Stack>
            <Button
              disabled={
                !(
                  startDate !== null &&
                  startDate.isValid() &&
                  endDate !== null &&
                  endDate?.isValid()
                )
              }
              onClick={handleSave}
            >
              Esporta
            </Button>
          </Stack>
        );
      return (
        <Stack flex="1" justifyContent="end" direction="row" gap={1}>
          <Button variant="outlined" onClick={() => setExportFile(false)}>
            Chiudi
          </Button>
        </Stack>
      );
    }, [endDate, handleSave, setExportFile, startDate, step]);

    return (
      <BluDialog
        className="service-popup"
        open={true}
        maxWidth="xs"
        zIndex={200}
        fullScreen={false}
        draggable
        dialogContent={
          <Stack flex="1" sx={{ p: '5px 20px' }} gap={1}>
            {GetContent}
          </Stack>
        }
        sx={{
          '& .MuiDialogContent-root': {
            pb: '0px !important',
            p: 0,
            height: 'fit-content',
          },
          '& .MuiDialog-paper': {
            height: 'fit-content !important',
            minHeight: 'unset !important',
          },
          zIndex: 200,
        }}
        dialogActions={<>{GetActions}</>}
      />
    );
  }
);
