/* eslint-disable @typescript-eslint/no-explicit-any */
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import {
  MenuItem,
  SelectChangeEvent,
  SvgIcon,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import dayjs from 'dayjs';
import { memo, useMemo } from 'react';
import { BluSelect } from '@bludata/components';
import { nanoid } from '@reduxjs/toolkit';
import { getConfig } from '../../../utility_objects/WhiteCache';
import { getStatusIcon } from '../../../utility_objects/StatusIcon';
import { appointmentStatus } from '../../../utility_objects/AppointmentStatus';

interface WhenInterface {
  startTime?: string;
  endTime?: string;
  color?: string;
  status: number;
  handleStatusChange: (status: string) => void;
}

export const When = memo(
  ({
    startTime,
    endTime,
    color,
    status,
    handleStatusChange,
  }: WhenInterface) => {
    const { sessionProfilo } = getConfig();

    const When = useMemo(() => {
      if (startTime !== undefined && endTime !== undefined) {
        const start = dayjs(startTime);
        const end = dayjs(endTime);

        return (
          <Stack flexDirection="row" gap="15px">
            <CalendarMonthIcon
              sx={{
                color:
                  color !== 'white' && color !== '#000000' ? color : 'black',
              }}
            />
            <Stack flexDirection="column">
              <Typography variant="body1">
                {start.format('DD MMMM YYYY')}
              </Typography>
              <Typography variant="body2">{`Dalle ore ${start.format(
                'HH:mm'
              )} alle ore ${end.format('HH:mm')}`}</Typography>
              <Stack flex="1" direction="row">
                <Typography variant="body2" sx={{ pt: 0.5, pr: 1 }}>
                  {'Stato: '}
                </Typography>
                <BluSelect
                  name="status"
                  value={status}
                  disabled={!sessionProfilo.agendaModifica}
                  sx={{ color: status === 0 ? 'red' : undefined }}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  renderValue={(value: any) => (
                    <Stack flex="1" direction="row" gap={2}>
                      <SvgIcon
                        component={getStatusIcon(value)}
                        sx={{
                          fontSize: '18px',
                          pt: '2px',
                          pb: '1px',
                        }}
                      />
                      {appointmentStatus[value]}
                    </Stack>
                  )}
                  onChange={(event: SelectChangeEvent) =>
                    handleStatusChange(event.target.value)
                  }
                  style={{ height: '30px', width: '210px' }}
                >
                  {Object.keys(appointmentStatus).map((key: any) => {
                    return (
                      <MenuItem
                        key={nanoid()}
                        value={key}
                        // disabled={key === "0" && status !== 0}
                        sx={{
                          color:
                            key === '0' && status !== 0
                              ? 'var(--disabled-color)'
                              : undefined,
                        }}
                      >
                        <Stack flex="1" direction="row" gap={2}>
                          <SvgIcon
                            component={getStatusIcon(key)}
                            sx={{
                              fontSize: '18px',
                              pt: '2px',
                              pb: '1px',
                            }}
                          />
                          {appointmentStatus[key]}
                        </Stack>
                      </MenuItem>
                    );
                  })}
                </BluSelect>
              </Stack>
            </Stack>
          </Stack>
        );
      }
      return null;
    }, [
      color,
      endTime,
      handleStatusChange,
      sessionProfilo.agendaModifica,
      startTime,
      status,
    ]);

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{When}</>;
  }
);
