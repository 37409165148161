import './conditions.css';

import { Paper, Stack, SvgIcon, Typography } from '@mui/material';
import BluBooking from '../../assets/BluBooking.svg';
import FocusWeb from '../../assets/FocusWeb.svg';
import '../../components/footer/footer.css';
import Markdown from 'react-markdown';
import { content } from './content';
import remarkGfm from 'remark-gfm';

const Conditions = () => {
  return (
    <Stack
      flex={1}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <div className="background-layer"></div>
      <Paper
        elevation={1}
        sx={{
          width: '80vw',
          height: '80vh',
          zIndex: 10,
          backgroundColor: 'white',
          position: 'relative',
        }}
      >
        <Stack
          flexDirection="column"
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            overflow: 'auto',
          }}
        >
          <Stack flex="1" sx={{ p: '20px' }}>
            <Stack direction="row" mb={2} alignItems={'center'}>
              <Stack direction="column">
                <Typography variant="h5">
                  <b>Blu Booking - Condizioni generali di contratto </b>
                </Typography>
                <Typography variant="body2" component="em">
                  Aggiornata il: 29/01/2024
                </Typography>
              </Stack>
              <div style={{ flex: 1 }} />
              <Stack gap={1}>
                <img
                  src={BluBooking}
                  onClick={() => {
                    window.location.href = 'https://console.ilmioottico.it/';
                  }}
                  style={{
                    height: '20px',
                    width: 'fit-content',
                    cursor: 'pointer',
                  }}
                  alt="Icona Blu Booking"
                />
                <img
                  src={FocusWeb}
                  onClick={() => {
                    window.location.href = 'https://www.bludata.com/';
                  }}
                  style={{
                    height: '20px',
                    width: 'fit-content',
                    cursor: 'pointer',
                  }}
                  alt="Icona Focus Web"
                />
                {/* <img
                src={BluBookingIcon}
                alt="Icona Blu Booking"
                onClick={() => {
                  window.location.href = "https://console.ilmioottico.it/";
                }}
                style={{
                  backgroundColor: "white",
                  width: "auto",
                  height: "50px",
                  cursor: "pointer",
                }}
              /> */}
                {/* <img
                  src={
                    'https://www.bludata.com/wp-content/uploads/2019/07/logo_bludata.png'
                  }
                  alt="Icona Focus Web"
                  onClick={() => {
                    window.location.href = 'https://www.bludata.com/';
                  }}
                  style={{
                    backgroundColor: 'white',
                    marginLeft: '10px',
                    width: 'auto',
                    height: '30px',
                    cursor: 'pointer',
                  }}
                /> */}
              </Stack>
            </Stack>
            <Markdown className="reactMarkDown" remarkPlugins={[remarkGfm]}>
              {content}
            </Markdown>
          </Stack>
        </Stack>
      </Paper>
    </Stack>
  );
};

export default Conditions;
