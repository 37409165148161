import { Box, SvgIcon, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { memo, useCallback, useMemo } from 'react';
import React from 'react';
import { Buttons } from './UI/Buttons';
import { Client } from './UI/Client';
import { Service } from './UI/Service';
import { When } from './UI/When';
import { Operator } from './UI/Operator';
import { Where } from './UI/Where';
import { useDispatch, useSelector } from 'react-redux';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import { If, Then } from 'react-if';
import StarIcon from '@mui/icons-material/Star';
import BluBooking from '../../../../resources/BluBookingFaviconBlue.svg';
import IMOIcon from '../../../../resources/imo.png';
import { AppDispatch, RootState } from 'packages/blu-booking/src/store';
import { setOpenLoader } from 'packages/blu-booking/src/store/root';
import { appointmentUpdate } from 'packages/blu-booking/src/data_fetching/Appointment/AppointmentUpdate';
import { setRefetch } from '../../store/Appointment';
import { setEmailPopup } from '../../store/Calendar';
import { Appointment_interface } from 'packages/blu-booking/src/interfaces/Appointment';
import { errorsMail } from 'packages/blu-booking/src/utility_objects/ErrorMail';
import {
  lightenHexColor,
  wc_hex_is_light,
} from 'packages/blu-booking/src/utility_objects/ColorManager';
import hexToRgba from 'hex-to-rgba';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CalendarEventQuickInfo = memo((props: any) => {
  const {
    isImportant,
    id,
    userId,
    pointOfSaleId,
    studioId,
    name,
    surname,
    email,
    telephone,
    serviceId,
    startTime,
    endTime,
    emailErrorCode,
    color,
    username,
    status,
    serviceIcons,
    externalCustomerCode,
  } = props;

  let lighterColor = lightenHexColor(color);
  if (lighterColor === undefined) {
    lighterColor = hexToRgba('var(--base-color)', 0.4);
  }
  const dispatch = useDispatch<AppDispatch>();
  const { appointments } = useSelector(
    (state: RootState) => state.bluBookingaAppointments
  );

  const handleClick = useCallback(
    async (st: string): Promise<void> => {
      if (status !== 0 && st === '0') return;
      const findIndex: number = appointments.findIndex(
        (app: Appointment_interface) => app.Id === props.Id
      );

      if (findIndex === -1) return;

      if (status !== 0) {
        dispatch(setOpenLoader(true));
        const result = await appointmentUpdate(props?.id ?? '', {
          ...props,
          hasToSendEmail: false,
          timeSlotDate: props.StartTime,
          start: props.StartTime,
          end: props.EndTime,
          status: parseInt(st),
        });
        dispatch(setOpenLoader(false));
        if (result) {
          dispatch(setRefetch());
        }
        return;
      }

      dispatch(
        setEmailPopup({
          id: props.id,
          emailSet:
            props?.status === 1 &&
            props.email !== undefined &&
            props.email !== '',
          type: 'modify',
          appointment: {
            ...props,
            status: parseInt(st),
          } as Appointment_interface,
          findIndex: findIndex,
          prevData: props as Appointment_interface,
        })
      );
    },
    [appointments, dispatch, props, status]
  );

  const GetIcons = useMemo(() => {
    if (isImportant)
      return (
        <Stack flexDirection="row" gap="15px">
          <StarIcon
            style={{
              color: 'black',
            }}
          />
          <Stack flexDirection="column">
            <Typography variant="body1" fontWeight="bold">
              Importante
            </Typography>
          </Stack>
        </Stack>
      );
    return null;
  }, [isImportant]);

  const GetCreatedForm = useMemo(() => {
    // ? Creato tramite B2C
    if (props.appointmentCreatedFrom === 1)
      return (
        <Stack flexDirection="row" gap="15px">
          {/* <LanguageIcon sx={{ color: props.color }} /> */}
          <img
            alt="logo Blu Booking"
            src={BluBooking}
            style={{
              width: '25px',
              height: '25px',
            }}
            // viewBox="0 0 283.46 283.46"
          />

          <Typography variant="body1">
            Appuntamento creato tramite il web
          </Typography>
        </Stack>
      );

    // ? Creato tramite IMO
    if (props.appointmentCreatedFrom === 2)
      return (
        <Stack flexDirection="row" gap="15px">
          <img
            alt="logo IMO"
            src={IMOIcon}
            style={{
              width: '20px',
              height: '20px',
              paddingTop: '2px',
              paddingLeft: '2px',
              paddingBottom: '1px',
              borderRadius: '50%',
            }}
          />
          <Typography variant="body1">
            Appuntamento creato tramite l'app
          </Typography>
        </Stack>
      );
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.appointmentCreatedFrom]);

  const getErrors = useMemo(() => {
    if (emailErrorCode !== undefined && emailErrorCode !== null)
      return (
        <Stack flexDirection="row" gap="15px">
          <ErrorTwoToneIcon color="error" />
          <Stack flexDirection="column">
            <If
              condition={
                emailErrorCode !== undefined && emailErrorCode !== null
              }
            >
              <Then>
                <Typography variant="body1">{`${
                  emailErrorCode !== undefined &&
                  emailErrorCode !== null &&
                  emailErrorCode in errorsMail
                    ? errorsMail[emailErrorCode]
                    : 'Errore mail'
                }`}</Typography>
              </Then>
            </If>
          </Stack>
        </Stack>
      );
    return null;
  }, [emailErrorCode]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box',
        width: '100%',
      }}
    >
      {id && (
        <Stack flexDirection="column">
          <Box
            sx={{
              backgroundColor: lighterColor,
              width: '100%',
              p: '4px 10px 10px 10px',
              boxSizing: 'border-box',
              color: wc_hex_is_light(lighterColor) ? 'black' : 'white',
            }}
          >
            <Stack flexDirection="column">
              {userId && <Buttons userId={userId} {...props} />}
              {(name !== undefined || surname !== undefined) && (
                <Client
                  name={name}
                  surname={surname}
                  email={email}
                  telephone={telephone}
                  externalCustomerCode={externalCustomerCode}
                />
              )}
            </Stack>
          </Box>
          <Stack flexDirection="column" sx={{ p: '2px 10px 10px 10px' }}>
            {lighterColor && serviceId && (
              <Service
                color={lighterColor}
                serviceId={serviceId}
                icons={serviceIcons}
              />
            )}
            {startTime && endTime && lighterColor && (
              <When
                startTime={startTime}
                endTime={endTime}
                color={lighterColor}
                status={status}
                handleStatusChange={handleClick}
              />
            )}
            {userId && <Operator username={username} color={lighterColor} />}
            {lighterColor && studioId && pointOfSaleId && (
              <Where
                color={lighterColor}
                studioId={studioId}
                pointOfSaleId={pointOfSaleId}
              />
            )}
            {GetCreatedForm}

            {GetIcons}
            {getErrors}
          </Stack>
        </Stack>
      )}
    </div>
  );
});
