/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import axios from 'axios';
import { AppointmentSlotOkInterface } from '../../interfaces/AppointmentSlotOk';

export const appointmentSlotIsOk = async ({
  appointmentId,
  startTime,
  endTime,
  serviceId,
  pointOfSaleId,
  studioId,
  userId,
}: {
  appointmentId?: string;
  startTime: string;
  endTime: string;
  serviceId?: string;
  pointOfSaleId?: string;
  studioId?: string;
  userId?: string;
}): Promise<AppointmentSlotOkInterface> => {
  try {
    const {
      data: { data },
    } = await axios.get(`/api/AppointmentSlot/IsNewSlotOk/`, {
      params: {
        appointmentId,
        startTime,
        endTime,
        serviceId,
        pointOfSaleId,
        studioId,
        userId,
      },
    });

    return data;
  } catch (err) {
    console.trace(err);
    return {
      isOk: 1,
    };
  }
};
