import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { AppointmentsPaginatedInterface } from '../../interfaces/AppointmentPaginatedFilters';
import { AppointmentPaginatedInterface } from '../../interfaces/AppointmentPaginated';

export const getAppointmentsPaginated = async ({
  pointOfSaleIds,
  userIds,
  serviceIds,
  studioIds,
  externalCustomerCode,
  startDate,
  endDate,
  slotOkStatus,
  hasErrorCode,
  status,
  pageNumber,
  pageSize,
}: AppointmentsPaginatedInterface): Promise<AppointmentPaginatedInterface> => {
  try {
    const {
      data: { data },
    } = await axios.post('/api/Appointment/GetAll/Complete/Paginated', {
      pointOfSaleIds: pointOfSaleIds ? [pointOfSaleIds] : [],
      userIds,
      serviceIds,
      studioIds,
      externalCustomerCode,
      startDate,
      endDate,
      slotOkStatus,
      hasErrorCode,
      status,
      pageNumber,
      pageSize,
    });

    return data;
  } catch (error) {
    console.log(error);
    return {
      items: [],
      pageNumber: 1,
      pageSize: 10,
      totalItems: 0,
      totalPages: 1,
    };
  }
};

export const useAppointmentsPaginated = (
  {
    pointOfSaleIds,
    userIds,
    serviceIds,
    studioIds,
    externalCustomerCode,
    startDate,
    endDate,
    slotOkStatus,
    hasErrorCode,
    status,
    pageNumber,
    pageSize,
  }: AppointmentsPaginatedInterface,
  key = ''
) => {
  return useQuery({
    queryFn: async () =>
      await getAppointmentsPaginated({
        pointOfSaleIds,
        userIds,
        serviceIds,
        studioIds,
        externalCustomerCode,
        startDate,
        endDate,
        slotOkStatus,
        hasErrorCode,
        status,
        pageNumber,
        pageSize,
      }),
    queryKey: [
      'appointments-paginated',
      pointOfSaleIds,
      userIds,
      serviceIds,
      studioIds,
      externalCustomerCode,
      startDate,
      endDate,
      slotOkStatus,
      hasErrorCode,
      status,
      pageNumber,
      pageSize,
    ],
    retry: 2,
    cacheTime: 0,
    staleTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: true,
  });
};
