import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import 'dayjs/locale/it';
import { ShopSignLinkInterface } from '../../../interfaces/Interfaces';

export interface CreateLinkSliceSliceState {
  searchCreateLinkPopUp: boolean;
  openClientPopUp: boolean;
  link?: ShopSignLinkInterface;
}

const initialState: CreateLinkSliceSliceState = {
  searchCreateLinkPopUp: false,
  openClientPopUp: false,
};

const CreateLinkSlice = createSlice({
  name: 'BluBookingCreateLink',
  initialState,
  reducers: {
    setSearchCreateLinkPopUp: (
      state: CreateLinkSliceSliceState,
      action: PayloadAction<boolean>
    ) => {
      state.searchCreateLinkPopUp = action.payload;
    },

    setOpenClientPopUp: (
      state: CreateLinkSliceSliceState,
      action: PayloadAction<boolean>
    ) => {
      state.openClientPopUp = action.payload;
    },

    setLink: (
      state: CreateLinkSliceSliceState,
      action: PayloadAction<ShopSignLinkInterface | undefined>
    ) => {
      state.link = action.payload;
    },

    resetForm: () => initialState,
  },
});

export const {
  setSearchCreateLinkPopUp,
  setOpenClientPopUp,
  resetForm,
  setLink,
} = CreateLinkSlice.actions;

export default CreateLinkSlice.reducer;
