import axios from 'axios';
import md5 from 'md5';

export const updatePassword = (
  oldPasswordValue: string,
  newPasswordValue: string
) => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const oldPassword: string = md5(oldPasswordValue);
  const newPassword = md5(newPasswordValue);
  return new Promise<any>((resolve, reject) => {
    axios
      .post(`api/Authenticate/change-password`, { oldPassword, newPassword })
      .then((response: any) => {
        resolve(true);
      })
      .catch((error) => {
        reject(error.response.data.Message);
      });
  });
};
export default updatePassword;
