/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Divider, MenuItem, Stack, Typography } from '@mui/material';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { If, Then, Else } from 'react-if';
import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { nanoid } from '@reduxjs/toolkit';

interface MenuContainerInterface {
  options: any;
  textfieldValue: string;
  width?: number;
  focus: () => void;
  handleClick: (id: string, name: string, lock?: boolean) => void;
  setClickWithoutClose: (v: boolean) => void;

  pointOfSaleId?: string;
  keyDown?: string;
  setKeyDown?: (value: string) => void;
  handleClose?: () => void;
}

export const MenuContainer = memo(
  ({
    options,
    textfieldValue,
    width,
    focus,
    handleClick,
    setClickWithoutClose,

    keyDown,
    setKeyDown,
    handleClose,
    pointOfSaleId,
  }: MenuContainerInterface) => {
    const [unAssociationOpen, setUnAssociationOpen] = useState<boolean>(false);
    const [underlined, setUnderlined] = useState<string>(pointOfSaleId);

    useEffect(() => {
      if (!unAssociationOpen && (options?.unassociation ?? []).length > 0) {
        const isUnassociated =
          options?.unassociation.find(
            (v: any) => v.branch === textfieldValue
          ) !== undefined;
        setUnAssociationOpen(isUnassociated);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const AssociatedRow = useMemo(() => {
      if (options?.association)
        return (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {options?.association.map((value: any) => (
              <MenuItem
                key={nanoid()}
                selected={value.branch === textfieldValue}
                sx={{
                  backgroundColor:
                    value.id === underlined && underlined !== pointOfSaleId
                      ? 'rgba(0, 0, 0, 0.04)'
                      : undefined,
                }}
                onMouseDown={() => handleClick(value.id, value.branch, true)}
              >
                <Typography color="text.primary">{value.branch}</Typography>
              </MenuItem>
            ))}
          </>
        );
      return null;
    }, [
      options?.association,
      textfieldValue,
      underlined,
      pointOfSaleId,
      handleClick,
    ]);

    const UnAssociateRow = useMemo(() => {
      if (options?.unassociation === undefined) return null;
      if (options?.unassociation.length === 0) return null;
      return [
        <Divider key={nanoid()} />,
        <MenuItem
          onMouseDown={() => {
            setClickWithoutClose(true);
            setUnAssociationOpen(!unAssociationOpen);
          }}
          onClick={() => focus()}
          selected={
            !unAssociationOpen &&
            options?.unassociation.find(
              (v: any) => v.branch === textfieldValue
            ) !== undefined
          }
          sx={{ pt: '10px', pb: '10px' }}
        >
          <Stack direction="row" flex="1">
            <Box sx={{ display: 'flex', flex: '1' }}>
              <Typography color="text.primary">Altro...</Typography>
            </Box>
            <If condition={unAssociationOpen}>
              <Then>
                <ExpandMoreIcon />
              </Then>
              <Else>
                <ExpandLessIcon />
              </Else>
            </If>
          </Stack>
        </MenuItem>,
        <If condition={unAssociationOpen}>
          <Then>
            {options?.unassociation &&
              options?.unassociation.map((value: any) => (
                <MenuItem
                  key={nanoid()}
                  selected={value.branch === textfieldValue}
                  onMouseDown={() => handleClick(value.id, value.branch, false)}
                  sx={{
                    pl: '30px',
                    color: 'lighgrey',
                    backgroundColor:
                      value.id === underlined && underlined !== pointOfSaleId
                        ? 'rgba(0, 0, 0, 0.04)'
                        : undefined,
                  }}
                >
                  <Typography color="text.primary">{value.branch}</Typography>
                </MenuItem>
              ))}
          </Then>
        </If>,
      ];
    }, [
      options?.unassociation,
      unAssociationOpen,
      setClickWithoutClose,
      focus,
      textfieldValue,
      underlined,
      pointOfSaleId,
      handleClick,
    ]);

    const gestioneSelezioneArrows = useCallback(
      (index: number, next: boolean, lock: boolean) => {
        if (options) {
          let data = options?.association;
          if (!lock) {
            data = options?.unassociation;
          }
          if (next) {
            if (index < data.length - 1) {
              const newSelectedRow = data[index + 1];
              setUnderlined(newSelectedRow.id);
            } else if (lock && (options?.unassociation ?? []).length > 0) {
              setUnAssociationOpen(true);
              const newSelectedRow = options?.unassociation[0];
              setUnderlined(newSelectedRow.id);
            } else if (
              index === data.length - 1 &&
              (options?.association ?? []).length > 0
            ) {
              setUnAssociationOpen(false);
              const newSelectedRow = options?.association[0];
              setUnderlined(newSelectedRow.id);
            }
          } else {
            if (index > 0) {
              const newSelectedRow = data[index - 1];
              setUnderlined(newSelectedRow.id);
            } else if (!lock && (options?.association ?? []).length > 0) {
              setUnAssociationOpen(false);
              const newSelectedRow =
                options?.association[options?.association.length - 1];
              setUnderlined(newSelectedRow.id);
            } else if (
              index === 0 &&
              (options?.unassociation ?? []).length > 0
            ) {
              setUnAssociationOpen(true);

              const newSelectedRow =
                options?.unassociation[options?.unassociation.length - 1];
              setUnderlined(newSelectedRow.id);
            }
          }
        }
      },
      [options]
    );

    useEffect(() => {
      if (keyDown) {
        let currentIndex = options?.association.findIndex(
          (value) => value.id === underlined
        );

        let lock = true;
        if (currentIndex === -1) {
          currentIndex = options?.unassociation.findIndex(
            (value) => value.id === underlined
          );
          lock = false;
        }

        if (keyDown === 'Enter') {
          if (currentIndex !== -1) {
            const data = lock ? options?.association : options?.unassociation;
            const newSelectedRow = data[currentIndex];
            handleClick(newSelectedRow.id, newSelectedRow.branch, lock);
            handleClose();
          }
        }

        if (currentIndex === -1) lock = true;

        if (keyDown === 'ArrowUp') {
          gestioneSelezioneArrows(currentIndex, false, lock);
          // Handle arrow up key press
        } else if (keyDown === 'ArrowDown') {
          // Handle arrow down key press
          gestioneSelezioneArrows(currentIndex, true, lock);
        }
        setKeyDown(undefined);
      }
    }, [
      gestioneSelezioneArrows,
      handleClick,
      handleClose,
      keyDown,
      options?.association,
      options?.unassociation,
      setKeyDown,
      textfieldValue,
      underlined,
    ]);

    return (
      <Box
        sx={{
          minWidth: width ? width + 30 : 'auto',
          maxHeight: '400px',
          overflowY: 'auto',

          border: '1px solid var(--divider-color)',
          bgcolor: 'background.paper',
        }}
      >
        <Stack flexDirection="column" flex="1">
          {AssociatedRow}
        </Stack>
        {UnAssociateRow}
      </Box>
    );
  }
);
