/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  IconButton,
  Popper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  memo,
  useCallback,
  useDeferredValue,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CloseIcon from '@mui/icons-material/Close';
import { MenuContainer } from './menu-items';
import {
  AppDispatch,
  RootState,
  useAppSelector,
} from 'packages/blu-booking/src/store';
import { useServicesFilter } from 'packages/blu-booking/src/data_fetching/Service/ServicesFilter';
import { bluBookingLoginData } from 'packages/blu-booking/src/store/root';
import {
  setHasStudio,
  setLockService,
} from '../../../../store/AppointmentForm';
import { ServiceIconConverter } from 'packages/blu-booking/src/utility_objects/ServiceIconConverter';

let allFilters = true;

export const Service = memo(() => {
  // ! VARIABLES
  const { setValue, trigger, watch } = useFormContext();
  const service = useWatch({ name: 'serviceId' });
  const pointOfSale = useWatch({ name: 'pointOfSaleId' });
  const user = useWatch({ name: 'userId' });
  const serviceIcons = useWatch({ name: 'icons' });
  const [maxHeight, setMaxHeight] = useState<number | 'auto'>('auto');

  const { services } = useSelector(
    (state: RootState) => state.bluBookingCalendarFilters
  );

  const { lockPointOfSale, lockUser } = useSelector(
    (state: RootState) => state.bluBookingAppointmentForm
  );

  // ? Popper anchor determines position
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  // ? Value of text field
  const [textfieldValue, setTextFieldValue] = useState<string>('');
  // const [serviceIcons, setServiceIcons] = useState<string[]>([]);

  // ? Prevent popper to close on Other button click
  const [clickWithoutClose, setClickWithoutClose] = useState<boolean>(false);

  // ? Ref of text field
  const inputRef = useRef<HTMLElement>(null);

  const dispatch = useDispatch<AppDispatch>();

  // ? Call for GET point of sale associated
  const { data: result } = useServicesFilter({
    lockPointOfSale,
    lockUser,
    userId: user,
    pointOfSaleId: pointOfSale,
  });
  const data = useDeferredValue(result);
  const { isManualAppointmentBlock } = useAppSelector(bluBookingLoginData);

  const [keyDown, setKeyDown] = useState<string | undefined>();

  // ! END

  // ! FUNCTIONS
  useEffect(() => {
    if (services !== undefined)
      if (service in services) {
        setTextFieldValue(services[service]?.name);
        if (
          (serviceIcons ?? []).length === 0 &&
          (services[service]?.icons ?? [])?.length > 0
        )
          setValue('icons', services[service]?.icons ?? []);
      }
    if (service === '') {
      setTextFieldValue('');
    }
  }, [service, serviceIcons, serviceIcons.length, services, setValue]);

  useEffect(() => {
    if (anchorEl) {
      const rect = anchorEl.getBoundingClientRect();
      const spaceBelow = window.innerHeight - rect.bottom; // Spazio sotto il campo
      const spaceAbove = rect.top; // Spazio sopra il campo
      const availableSpace = Math.max(spaceBelow, spaceAbove); // Usa lo spazio maggiore

      setMaxHeight(availableSpace - 10); // Imposta il maxHeight (con un limite massimo di 300px)
    }
  }, [anchorEl]);

  // ? Handle change on text field
  const handleOnChange = useCallback((event: any) => {
    if (allFilters) allFilters = false;
    setTextFieldValue(event.target.value);
  }, []);

  // ? handle open popper on focus input
  const handleClick = useCallback((): void => {
    setAnchorEl(inputRef?.current);
  }, []);

  // ? handle close popper
  const handleClose = useCallback((): void => {
    if (textfieldValue !== '') {
      const set = Object.values(services).find(
        (s) => s.name === textfieldValue
      );
      if (set === undefined && service !== '') {
        setTextFieldValue(services[service].name);
      }
    }
    allFilters = true;
    setAnchorEl(null);
  }, [service, services, textfieldValue]);

  // ? handle blur on input
  const manageOnBlur = useCallback(() => {
    if (textfieldValue === '') {
      setValue('serviceId', '');
      setValue('icons', []);
      trigger();
    }
    if (!clickWithoutClose) handleClose();
    else setClickWithoutClose(false);
    // else otherCLicked = false;
  }, [clickWithoutClose, handleClose, setValue, textfieldValue, trigger]);

  // ? erase input value
  const handleErase = useCallback(() => {
    setClickWithoutClose(true);
    setTextFieldValue('');
    setValue('serviceId', '');
    setValue('icons', []);
    trigger();
  }, [setValue, trigger]);

  // ? Handle click on MenuItem
  const handleItemCLick = useCallback(
    (
      id: string,
      name: string,
      durationValue: number,
      hasStudio: boolean,
      icons?: string[],
      lock = false
    ) => {
      if (id === null) {
        setValue('serviceId', '');
        trigger();

        return;
      }
      setValue('serviceId', id);
      setValue('studioRequired', hasStudio);
      setTextFieldValue(name);
      setValue('icons', icons ?? []);
      const durationSet = watch('durationSet');
      if (!durationSet) setValue('duration', durationValue);
      dispatch(setLockService(lock));
      dispatch(setHasStudio(hasStudio));
      setValue('cercaDisponibilitaUsed', false);
      trigger();
    },
    [dispatch, setValue, trigger, watch]
  );

  // ? get options
  const getOptions = useCallback(() => {
    const temp = JSON.parse(JSON.stringify(services));
    let result = {
      association: [],
      unassociation: [],
    };
    if (isManualAppointmentBlock) {
      if (data) {
        if (data.length)
          return {
            association: data,
            unassociation: [],
          };
      }
      return result;
    }
    if (data !== undefined) {
      if (data.length === 0)
        return {
          ...result,
          unassociation: Object.values(services),
        };
      else {
        for (const serviceValue of data) delete temp[serviceValue.id];
        result = {
          association: data as any,
          unassociation: Object.values(temp),
        };
      }
    }
    if (!allFilters)
      return {
        association: result.association.filter((value: any) => {
          const reg = new RegExp(textfieldValue, 'i');
          return reg.test(value.name);
        }),
        unassociation: result.unassociation.filter((value) => {
          const reg = new RegExp(textfieldValue, 'i');
          return reg.test((value as any).name);
        }),
      };
    return result;
  }, [data, isManualAppointmentBlock, services, textfieldValue]);

  // ? Erase icon
  const Erase = useMemo(() => {
    if (open)
      return (
        <IconButton
          sx={{ width: '24px', height: '24px', p: 0, m: 0 }}
          onMouseDown={handleErase}
          onClick={() => inputRef.current?.focus()}
        >
          <CloseIcon sx={{ fontSize: '20px' }} />
        </IconButton>
      );
    return null;
  }, [handleErase, open]);

  // ? Arrow open and close
  const Arrow = useMemo(() => {
    if (open)
      return (
        <IconButton
          sx={{ width: '24px', height: '24px', p: 0, m: 0 }}
          onClick={handleClose}
        >
          <ArrowDropUpIcon sx={{ cursor: 'pointer' }} />
        </IconButton>
      );
    return (
      <IconButton
        sx={{ width: '24px', height: '24px', p: 0, m: 0 }}
        onClick={(event) => {
          handleClick();
          inputRef.current?.focus();
        }}
      >
        <ArrowDropDownIcon />
      </IconButton>
    );
  }, [handleClick, handleClose, open]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    event.stopPropagation();
    if (
      event.key === 'Enter' ||
      event.key === 'ArrowUp' ||
      event.key === 'ArrowDown'
    ) {
      if (!open) {
        handleClick();
        return;
      }
    }
    if (event.key === 'Escape') {
      handleClose();
    }
    if (
      event.key !== 'ArrowUp' &&
      event.key !== 'ArrowDown' &&
      event.key !== 'Enter'
    )
      return;
    setKeyDown(event.key);
  };
  // ! END

  return (
    <Box style={{ width: '49%' }} onKeyDown={handleKeyDown}>
      <Typography variant="subtitle2" sx={{ pt: '3px' }}>
        Servizio
      </Typography>
      <TextField
        inputRef={inputRef}
        id="point_of_sale_selector"
        onBlur={manageOnBlur}
        onFocus={handleClick}
        value={textfieldValue}
        placeholder="Seleziona un servizio"
        InputProps={{
          autoComplete: 'off',
          startAdornment: serviceIcons ? (
            <Stack direction="row" pr={1} justifyItems="center">
              {(serviceIcons ?? []).map((v: any) => (
                <ServiceIconConverter
                  name={v}
                  sx={{
                    fontSize: '18px',
                    pb: '4px',
                  }}
                />
              ))}
            </Stack>
          ) : undefined,
          endAdornment: (
            <>
              {Erase}
              {Arrow}
            </>
          ),
        }}
        onChange={handleOnChange}
        fullWidth
      />
      <Popper
        open={open}
        style={{ zIndex: 1301, width: 'fit-content', height: 'fit-content' }}
        anchorEl={anchorEl}
        placement="bottom-start"
        modifiers={[
          {
            name: 'preventOverflow',
            options: {
              boundary: 'window', // Impedisce che il popper esca dalla finestra
            },
          },
          {
            name: 'flip',
            options: {
              fallbackPlacements: ['top-start', 'bottom-start'], // Permette di invertire la posizione se necessario
            },
          },
        ]}
      >
        <Box
          sx={{
            maxHeight: maxHeight !== 'auto' ? `${maxHeight}px` : maxHeight, // Altezza dinamica
            overflowY: 'auto', // Abilita lo scroll se necessario
            background: 'white',
          }}
        >
          <MenuContainer
            serviceId={service}
            keyDown={keyDown}
            setKeyDown={setKeyDown}
            handleClose={handleClose}
            options={getOptions()}
            icons={serviceIcons}
            textfieldValue={textfieldValue}
            width={inputRef?.current?.clientWidth}
            focus={() => inputRef?.current?.focus()}
            handleClick={handleItemCLick}
            setClickWithoutClose={(v: boolean) => setClickWithoutClose(v)}
          />
        </Box>
      </Popper>
    </Box>
  );
});
