/* eslint-disable @typescript-eslint/no-explicit-any */
import dayjs from 'dayjs';
/**
 *
 * @param data
 * @param type
 * @param calendarPointOfSale
 * @param shiftPointOfSale
 * @returns 0 not found in shift and calenda
 * 1 not found in calendar
 * 2 found in shift and calendar
 */
export const isOpen = (
  data: Date | undefined,
  type?: string,
  calendarPointOfSale?: any,
  shiftPointOfSale?: any
): number => {
  if (!calendarPointOfSale && !shiftPointOfSale) return 0;
  const check = (calendar: any) =>
    calendar &&
    ((time >= calendar.startTime1 && time < calendar.endTime1) ||
      (time >= calendar.startTime2 && time < calendar.endTime2));
  const time = dayjs(data).format('HH:mm:ss');
  const date = dayjs(data).format('YYYY-MM-DD');
  const calendarPointOfSaleWorkHours =
    calendarPointOfSale && date in calendarPointOfSale
      ? calendarPointOfSale[date]
      : undefined;
  const shiftPointOfSaleWorkHours =
    shiftPointOfSale && date in shiftPointOfSale
      ? shiftPointOfSale[date]
      : undefined;

  // ? CHECK DAY OPEN
  if (type && type === 'monthCells') {
    if (calendarPointOfSaleWorkHours) return 2;
    else if (shiftPointOfSale) return 1;
    else return 0;
  }

  const checkCalendar = check(calendarPointOfSaleWorkHours);
  const checkShift = check(shiftPointOfSaleWorkHours);
  if (checkCalendar) {
    return 2;
  } else if (checkShift) return 1;
  return 0;
};
