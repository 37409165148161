import React, { FC, useCallback } from 'react';
import BluAlert from './Alert';

const networkErrorString = 'Errore di connessione al server.';

interface ErrorDialogProps {
  show: boolean;
  handleClose: () => void;
  text?: string;
}

/**
 * Componente BludataAlertNetworkError: visualizza un alert di errore di rete.
 *
 * Questo componente è responsabile di mostrare un messaggio di errore quando si verifica un errore di rete.
 * Utilizza il componente BluAlert per visualizzare l'alert.
 *
 * @param {ErrorDialogProps} props - Le proprietà passate al componente.
 * @param {boolean} props.show - Determina se l'alert deve essere visualizzato.
 * @param {() => void} props.handleClose - Funzione da eseguire quando l'alert viene chiuso.
 * @param {string} [props.text] - Il testo dell'errore da visualizzare. Se non fornito, verrà utilizzato un messaggio di errore di rete predefinito.
 *
 * @example
 * <BludataAlertNetworkError
 *   show={true}
 *   handleClose={() => console.log('Alert chiuso')}
 *   text="Errore di connessione"
 * />
 */
const BludataAlertNetworkError: FC<ErrorDialogProps> = React.memo((props) => {
  const { handleClose } = props;
  // Funzione che gestisce la chiusura dell'alert.
  const handleCloseTap = useCallback(() => {
    handleClose();
  }, [handleClose]);
  // Determina il testo dell'errore da visualizzare.
  let errorText = props.text || networkErrorString;

  // Se il testo dell'errore contiene "bad request", utilizza il messaggio di errore di rete predefinito.
  if (errorText.toLowerCase().indexOf('bad request') >= 0) {
    errorText = networkErrorString;
  }

  return (
    <BluAlert
      title="Attenzione"
      type="error"
      show={props.show}
      handleClose={handleCloseTap}
      text={errorText}
    />
  );
});

export default BludataAlertNetworkError;
