/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

interface pointOfSaleFilteredProps {
  lockService: boolean;
  lockUser: boolean;
  serviceId?: string;
  userId?: string;
  filtersLoaded?: boolean;
  shopSignId?: string;
}

export const pointOfSalesFiltered = async (
  lockService: boolean,
  lockUser: boolean,
  serviceId?: string,
  userId?: string,
  shopSignId?: string
): Promise<{ id: string; name: string }[]> => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const params: any = { shopSignId };
    if (lockService && serviceId !== '') params.serviceId = serviceId;
    if (lockUser && userId !== '') params.userId = userId;
    const {
      data: { data },
    } = await axios.get(`/api/PointOfSale/Filter`, {
      params,
    });

    return data;
  } catch (err) {
    console.trace(err);
    return [];
  }
};

export const usePointOfSalesFiltered = (
  {
    lockService,
    lockUser,
    serviceId,
    userId,
    shopSignId,
    filtersLoaded = true,
  }: pointOfSaleFilteredProps,
  key = ''
) => {
  return useQuery({
    queryFn: async () =>
      await pointOfSalesFiltered(
        lockService,
        lockUser,
        serviceId,
        userId,
        shopSignId
      ),
    queryKey: [
      'point-of-sales',
      'filter',
      lockService ? serviceId : '',
      lockUser ? userId : '',
      shopSignId ?? '',
      key,
    ],
    enabled: filtersLoaded,
    retry: 2,
    retryDelay: 180000,
    cacheTime: 300000,
    staleTime: 300000,
    refetchInterval: 300000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
};
