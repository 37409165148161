/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import axios from 'axios';
import { ShopSignInterface } from '../../interfaces/ShopSign';

const getShopSign = async (): Promise<ShopSignInterface[]> => {
  try {
    const {
      data: { data },
    } = await axios.get('/api/ShopSign');
    return data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const useShopSign = ({
  options = {},
  key = '',
}: {
  options: UseQueryOptions | undefined;
  key: string | undefined;
}) => {
  return useQuery({
    queryFn: async () => getShopSign(),
    queryKey: ['shop-sign', key],
    retry: 2,
    retryDelay: 180000,
    cacheTime: 0,
    staleTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    ...options,
  });
};
