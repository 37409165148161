import 'dayjs/locale/it';
import '../../styles/base-theme.css';
import '../../styles/theme-color-cloud.css';
import '../../styles/scrollbar.css';
import '../../styles/screenRotation.css';
import '../../styles/SyncfusionTheme/material.css';
import '../../styles/SyncfusionTheme/material-dark.css';

import { ThemeProvider } from '@mui/material/styles';
import { PaletteMode } from '@mui/material';
import { memo, ReactNode, useEffect } from 'react';
import { getBludataTheme } from './theme';
import { LicenseInfo } from '@mui/x-data-grid-premium';
import loadSyncfusionLanguage from './language';
import dayjs from 'dayjs';
import { registerLicense } from '@syncfusion/ej2-base';
import { MUI_license, Syncfusion_license } from '../../consts/licenses';

interface BluThemeProviderInterface {
  themeMode?: PaletteMode;
  children: JSX.Element[] | JSX.Element | ReactNode;
}

LicenseInfo.setLicenseKey(MUI_license);
registerLicense(Syncfusion_license);
loadSyncfusionLanguage();
dayjs.locale('it');

const BluCloudThemeProvider = memo(function BluThemeProvider({
  themeMode = 'light',
  children,
}: BluThemeProviderInterface): JSX.Element {
  useEffect(() => {
    let themeChrome = document.head.querySelector('meta[name="theme-color"]');
    if (!themeChrome) {
      const metaTag = document.createElement('meta');
      metaTag.name = 'theme-color';
      document.head.appendChild(metaTag);
      themeChrome = metaTag;
    }

    try {
      const htmlTag = document.getElementsByTagName('html')[0];
      htmlTag.setAttribute('dark-mode', `${themeMode === 'dark'}`);
      htmlTag.setAttribute('theme', 'cloud');
    } catch (error) {
      console.error(error);
    }
    themeChrome.setAttribute(
      'content',
      themeMode === 'dark' ? '#ffffff' : '#303030'
    );

    try {
      let bodyClass = document.body?.className ?? '';
      bodyClass = bodyClass.replace(`syncfusion-dark-theme`, '');
      bodyClass = bodyClass.replace(`syncfusion-light-theme`, '');
      document.body.className = `${bodyClass} syncfusion-${themeMode}-theme`;
      document.body.classList.toggle('dark-mode-bludata', themeMode === 'dark');
    } catch (error) {
      console.error(error);
    }
  }, [themeMode]);
  return (
    <ThemeProvider theme={getBludataTheme(themeMode)}>{children}</ThemeProvider>
  );
});

export default BluCloudThemeProvider;
