import { BluColorPicker, BluTitle } from '@bludata/components';
import { Stack, Typography } from '@mui/material';
import { FC, memo, useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { getUsersWhiteCache } from '../../../../lib/white-cache';
import { CustomCheckbox } from '../../common/CustomCheckBox';

interface AgendaUserInterface {
  mode: 'modify' | 'info' | 'new';
}

export const AgendaUser: FC<AgendaUserInterface> = memo(({ mode }) => {
  const color = useWatch({ name: 'color' });
  const { isFocus10, software, isFocusWeb } = getUsersWhiteCache();

  const { setValue } = useFormContext();

  const isDisabled = useCallback(() => {
    if (mode === 'info') return true;
    if (isFocus10) return true;
    if (software === 'bb') return false;
    if (isFocusWeb) return false;
    return true;
  }, [isFocus10, isFocusWeb, mode, software]);

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="left"
      flex={1}
      spacing={1}
      sx={{ pt: '20px' }}
    >
      <BluTitle text={'Agenda'} />
      <Stack
        gap={2}
        direction="row"
        justifyContent="start"
        alignItems="center"
        sx={{ width: '100%' }}
      >
        <Stack flex="1">
          <Typography>Colore</Typography>
          <BluColorPicker
            disabled={isDisabled()}
            value={color ?? 'white'}
            onChange={(event: any) => setValue('color', event.currentValue.hex)}
          />
        </Stack>
        <CustomCheckbox
          name={'hasAgenda'}
          disabled={mode === 'info' || isFocus10}
          label="Agenda attiva "
        />
      </Stack>
    </Stack>
  );
});
