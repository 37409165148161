/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import {
  PointOfSaleCheckSlotOk,
  PointOfSalesCheckSlotOkObj,
} from '../../interfaces/PointOfSaleCheckSlotOk';

export const getPointOfSalesCheckSlotOk = async (): Promise<
  PointOfSaleCheckSlotOk[]
> => {
  try {
    const {
      data: { data },
    } = await axios.get(`/api/PointOfSale/GetAllCheckSlotUpdatedAt`);

    return data;
  } catch (err) {
    console.trace(err);
    return [];
  }
};

export const usePointOfSalesCheckSlotOk = (key = '') => {
  return useQuery({
    queryFn: async () => await getPointOfSalesCheckSlotOk(),
    queryKey: ['point-of-sales', 'check-slot-ok', key],
    retry: 2,
    retryDelay: 180000,
    cacheTime: 0,
    staleTime: 0,
    refetchInterval: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    select: normalize,
  });
};

const normalize = (
  data: PointOfSaleCheckSlotOk[]
): PointOfSalesCheckSlotOkObj => {
  const result: PointOfSalesCheckSlotOkObj = {};
  for (const pos of data) {
    result[pos.pointOfSaleId] = {
      ...pos,
    };
  }
  return result;
};
