import React from 'react';
import { useDrag } from 'react-dnd';
import { CardData } from './interfaces';
import { Typography } from '@mui/material';

interface CardProps {
  card: CardData;
}

const NotificationCard: React.FC<CardProps> = ({ card }) => {
  const [{ isDragging }, drag] = useDrag({
    type: 'CARD',
    item: card,
    canDrag: card.isDraggable ?? true, // Controlla se la card è trascinabile
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  return (
    <div
      ref={drag}
      className="kanban-notification-card"
      style={{
        opacity: isDragging ? 0.5 : 1,
        padding: '15px 0px',
        borderRadius: '5px',
        cursor: card.isDraggable ? 'grab' : undefined,
      }}
    >
      <Typography textAlign="center">{card.label}</Typography>
    </div>
  );
};

export default NotificationCard;
