/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, memo, useMemo } from 'react';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { CalendarsInterface } from '../../interfaces/Calendars';
import { ContextMenu } from '../ContextMenu/ContextMenu';
import { setConfig } from '../../utility_objects/WhiteCache';
import { InitialDataLoading } from './InitialDataLoading';
import { useSelector } from 'react-redux';
import { RootState } from 'packages/blu-booking/src/store';
import { AppointmentForm } from '../AppointmentForm/AppointmentForm';
import { CreateLinkPopUp } from '../CreateLinkPopup/CreateLinkPopup';
import { BluLoader } from '@bludata/components';
import { EmailPopUp } from '../EmailPopup/EmailPopup';
import { Stack } from '@mui/material';

interface InitialConfigurationInterface extends CalendarsInterface {
  calendarType: 'default' | 'personal' | 'studio' | 'user';
  children: JSX.Element | JSX.Element[];
}

export const InitialConfiguration: FC<InitialConfigurationInterface> = memo(
  function DefaultCalendar({
    token,
    url,
    user,
    pointOfSale,
    getClient,
    focus10 = false,
    focusWeb = false,
    focusCloud = false,
    isBluBookingActive,
    isManualAppointmentBlock,
    selezioneCliente,
    visibilityUserEnum,
    children,
    calendarType,
    createCliente,
  }) {
    const { openLoader } = useSelector(
      (state: RootState) => state.bluBookingRoot
    );
    const { openClientPopUp } = useSelector(
      (state: RootState) => state.bluBookingCreateLink
    );
    const { openForm } = useSelector(
      (state: RootState) => state.bluBookingAppointmentForm
    );
    const { shopSigns } = useSelector(
      (state: RootState) => state.bluBookingCalendarFilters
    );

    if (url !== undefined) axios.defaults.baseURL = url;

    let bearerToken: string | undefined;
    if (token !== undefined) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      bearerToken = token;
    } else if (
      axios.defaults.headers.common['Authorization'] !== null &&
      axios.defaults.headers.common['Authorization'] !== undefined
    ) {
      bearerToken = (
        axios.defaults.headers.common['Authorization'] as string
      ).replace('Bearer ', '');
    }

    let accessToken: any;
    try {
      if (bearerToken !== undefined) accessToken = jwt_decode(bearerToken);

      if (isBluBookingActive === undefined && accessToken !== undefined)
        setConfig(
          'isBluBookingActive',
          accessToken?.IsBluBookingActive === 'True'
        );
      else setConfig('isBluBookingActive', isBluBookingActive);
    } catch (error) {
      // empty
    }

    setConfig('getClient', getClient);
    setConfig('focus10', focus10);
    setConfig('focusWeb', focusWeb);
    setConfig('focusCloud', focusCloud);

    const sessionData = JSON.parse(
      sessionStorage.getItem('sessionData') ?? '{}'
    );
    setConfig('sessionProfilo', {
      agendaInserimento: sessionData?.agendaInserimento ?? true,
      agendaModifica: sessionData?.agendaModifica ?? true,
      agendaCancellazione: sessionData?.agendaCancellazione ?? true,
      turniInserimento: sessionData?.turniInserimento ?? true,
      turniModifica: sessionData?.turniModifica ?? true,
      turniCancellazione: sessionData?.turniCancellazione ?? true,
    });

    if (selezioneCliente !== undefined)
      setConfig('selezioneCliente', selezioneCliente);

    let visbilityEnum: number | undefined;
    if (visibilityUserEnum === undefined)
      visbilityEnum = sessionData?.visibilityUserEnum
        ? parseInt(sessionData?.visibilityUserEnum)
        : undefined;
    else visbilityEnum = visibilityUserEnum;

    const GetAppointmentFormPopup = useMemo(() => {
      if (openForm)
        return <AppointmentForm calendar={calendarType} openForm={openForm} />;
      return null;
    }, [calendarType, openForm]);

    const GetCreateLinkPopup = useMemo(() => {
      if (openClientPopUp && shopSigns)
        return (
          <CreateLinkPopUp calendar={calendarType} shopSigns={shopSigns} />
        );
      return null;
    }, [calendarType, openClientPopUp, shopSigns]);

    return (
      <Stack
        key={calendarType}
        flex={1}
        flexDirection="row"
        sx={{ backgroundColor: 'var(--hovered-background-color)' }}
        id="BluBookingCalendar"
      >
        <InitialDataLoading
          visibilityUserEnum={visbilityEnum}
          isManualAppointmentBlock={
            isManualAppointmentBlock ?? sessionData?.isManualAppointmentBlock
          }
          pointOfSaleId={pointOfSale}
          userId={user}
        />
        <ContextMenu createCliente={createCliente} />
        <BluLoader open={openLoader} zIndex={1400} />
        <EmailPopUp />
        {GetAppointmentFormPopup}
        {GetCreateLinkPopup}
        {children}
      </Stack>
    );
  }
);
