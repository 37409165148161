import {
  HtmlEditor,
  Image,
  Inject,
  Link,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
  ToolbarType,
} from '@syncfusion/ej2-react-richtexteditor';
import React from 'react';
import { HtmlEditorInterface } from './interface';
import { it } from './language/it';
import { L10n } from '@syncfusion/ej2-base';

L10n.load(it);
const BluHtmlEditor = ({
  value,
  change,
  children,
  ...rest
}: HtmlEditorInterface) => {
  return (
    <RichTextEditorComponent
      enablePersistence={false}
      height={rest?.height ?? 450}
      change={change}
      locale="it"
      toolbarSettings={{
        items: rest?.toolbarSettings ?? [
          'Bold',
          'Italic',
          'Underline',
          'FontName',
          'FontSize',
          'FontColor',
          'BackgroundColor',
          'LowerCase',
          'UpperCase',
          '|',
          'Formats',
          'Alignments',
          'OrderedList',
          'UnorderedList',
          'Outdent',
          'Indent',
          '|',
          'CreateLink',
          'Image',
          '|',
          'Undo',
          'Redo',
        ],
        type: ToolbarType.Expand,
      }}
      {...rest}
    >
      <Inject services={[Toolbar, Image, Link, HtmlEditor, QuickToolbar]} />
    </RichTextEditorComponent>
  );
};

export default BluHtmlEditor;
