import axios from 'axios';
import { memo } from 'react';
import { Focus10Page } from './page';
import {
  getRequestInterceptorId,
  getResponseInterceptorId,
} from '../../lib/interceptors';
import { MemoryRouter } from 'react-router-dom';

export const Focus10BluBooking = memo(() => {
  const { url, token } = JSON.parse(
    sessionStorage.getItem('sessionData') ?? '{}'
  );

  // const sessionUrl = sessionStorage.getItem("sessionUrl");
  // const sessionToken = sessionStorage.getItem("sessionToken");

  axios.defaults.baseURL = url ? url : import.meta.env.VITE_NX_PUBLIC_API_URL;

  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else if (import.meta.env.DEV) {
    axios.defaults.headers.common['Authorization'] =
      'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJDb21wYW55SWQiOiJhMmNhMDU0My1kNTc0LTQ5YzItYTVkNi0wOGRiOGRhZWQ2YzEiLCJVc2VySWQiOiI2MTg5OWFhMy1jNjg5LTQyZDQtOWZmMy00ZGM0YzBiM2VkYTciLCJQb2ludE9mU2FsZUlkIjoiNWQ3OGY1YmQtZjgxOS00Y2ZlLTVmMWYtMDhkYmNmYWQ1MmY3IiwiSXNCbHVCb29raW5nQWN0aXZlIjoiVHJ1ZSIsIklkZW50aWZpY2F0aW9uQ29kZSI6IjE2NTZBLTFBMDRRRCIsIlVzZXJuYW1lIjoiUGllcmFudG9uaW8iLCJJc0ZvY3VzUGx1c0FjdGl2ZSI6IlRydWUiLCJWaXNpYmlsaXR5IjoiQ29tcGFueSIsIlZpc2liaWxpdHlWYWx1ZSI6ImEyY2EwNTQzLWQ1NzQtNDljMi1hNWQ2LTA4ZGI4ZGFlZDZjMSIsImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvcm9sZSI6WyJVc2VyIiwiQWRtaW4iLCJBZG1pbkxvY2FsIl0sIlNvZnR3YXJlRW51bSI6IjEiLCJleHAiOjE3MzQzODE1OTl9.R3c0NnhdZUlmPNMmdpf1OWBnTzu2msW34X0-Q1kjWxQ';
  }
  const requestInterceptorId = getRequestInterceptorId();
  const responseInterceptorId = getResponseInterceptorId();
  if (requestInterceptorId)
    axios.interceptors.request.eject(requestInterceptorId);
  if (responseInterceptorId)
    axios.interceptors.response.eject(responseInterceptorId);

  return <Focus10Page />;
});
