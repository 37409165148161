import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from '@mui/material';
import React, { useRef } from 'react';
import { FC, memo } from 'react';
import { BluDialogInterface } from './interface';
import { useReactToPrint } from 'react-to-print';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import '../../styles/print.css';
import { render } from 'react-dom';

const BluDialog: FC<BluDialogInterface> = memo(
  ({
    open,
    zIndex,
    dialogTitle,
    dialogTitleProps,
    dialogContent,
    dialogContentProps,
    dialogActions,
    dialogActionsProps,
    draggable,
    print,
    ...rest
  }) => {
    const componentRef = useRef();

    const handlePrint = useReactToPrint({
      content: () => {
        const newPage = document.createElement('div');
        const contentClone = (componentRef.current as any).cloneNode(true);
        const container = (
          <div>
            {print!.printHead}
            <div id="content-bludialog"></div>
            {print!.printFooter}
          </div>
        );
        render(container, newPage);
        newPage
          .querySelectorAll("[id^='content-bludialog']")[0]
          .appendChild(contentClone);
        return newPage;
      },
    });

    return (
      <Dialog open={open} fullWidth maxWidth="xl" {...rest}>
        {dialogTitle && (
          <DialogTitle {...dialogTitleProps}>{dialogTitle}</DialogTitle>
        )}
        <DialogContent ref={componentRef} {...dialogContentProps}>
          {dialogContent}
        </DialogContent>
        <DialogActions {...dialogActionsProps}>
          {print ? (
            <Stack
              direction={'row'}
              justifyContent="flex-start"
              alignItems="center"
              flex={1}
              spacing={0.5}
            >
              <IconButton onClick={handlePrint}>
                <LocalPrintshopIcon />
              </IconButton>
              <div style={{ flex: 1 }}></div>
              {dialogActions}
            </Stack>
          ) : (
            dialogActions
          )}
        </DialogActions>
      </Dialog>
    );
  }
);

export default BluDialog;
