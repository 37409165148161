import { FC, memo, useCallback } from 'react';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';
import { BluTimePicker } from '@bludata/components';
import dayjs from 'dayjs';
import { IconButton, Stack, TextField } from '@mui/material';
import { GridCloseIcon } from '@mui/x-data-grid-premium';

interface RotaPopUpDatePickerInterface {
  name: string;
  calendarType: 'Calendar' | 'Shift';
  errorProperty: string;
  date: Date;
}

export const RotaPopUpDatePicker: FC<RotaPopUpDatePickerInterface> = memo(
  ({ name, calendarType, errorProperty, date }) => {
    const [value, type, defaultValue] = useWatch({
      name: [
        `${name}${calendarType}`,
        `type${calendarType}`,
        `default${name}${calendarType}`,
      ],
    });
    const { setValue, trigger } = useFormContext();
    const { errors } = useFormState();

    const getValue = useCallback(() => {
      if (type === 'closed' || type === 'extra-closed')
        return dayjs(date).set('hour', 0).set('minute', 0).set('second', 0);
      if (type === 'default') {
        return (
          defaultValue ||
          dayjs(date).set('hour', 0).set('minute', 0).set('second', 0)
        );
      }
      return value;
    }, [date, defaultValue, type, value]);

    const handleValueChange = useCallback(
      (newValue: string) => {
        setValue(`${name}${calendarType}`, newValue);
        if (errors && errorProperty in errors) {
          trigger(errorProperty);
        }
      },
      [calendarType, errorProperty, errors, name, setValue, trigger]
    );

    const getReadOnly = useCallback(() => {
      return type === 'default';
    }, [type]);

    const getDisabled = useCallback(() => {
      return type === 'default';
      // return type === "extra-closed" || type === "closed";
    }, [type]);

    const getError = useCallback(() => {
      return errors && errorProperty in errors;
    }, [errorProperty, errors]);

    return (
      <BluTimePicker
        value={getValue()}
        textField={{ error: getError() }}
        readOnly={getReadOnly()}
        disabled={getDisabled()}
        onChange={handleValueChange}
        renderInput={(params: any) => {
          if (
            params.inputProps.value !== '' &&
            params.inputProps.value.length > 1 &&
            value === null &&
            type !== 'default'
          )
            params.inputProps.value = '';
          return (
            <TextField
              {...params}
              fullWidth
              sx={{
                '& .MuiInputAdornment-root': {
                  ml: 0,
                  width: '25px',
                  height: '25px',
                },
                '& .MuiIconButton-root': {
                  width: '25px',
                  height: '25px',
                  p: 0,
                },
              }}
              InputProps={{
                endAdornment: (
                  <Stack direction="row">
                    {value !== null &&
                      value.isValid() &&
                      !getReadOnly() &&
                      !getDisabled() && (
                        <IconButton
                          // sx={{ height: "25px", width: "25px" }}
                          onClick={() => {
                            setValue(`${name}${calendarType}`, null);
                            if (errors && errorProperty in errors) {
                              trigger(errorProperty);
                            }
                          }}
                        >
                          <GridCloseIcon />
                        </IconButton>
                      )}
                    {params?.InputProps?.endAdornment as any}
                  </Stack>
                ),
              }}
            />
          );
        }}
      />
    );
  }
);
