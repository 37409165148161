import { BluTitle } from '@bludata/components';
import {
  Box,
  Card,
  Drawer,
  IconButton,
  List,
  ListItem,
  Stack,
  Typography,
} from '@mui/material';
import { FC, memo, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { setSelectedPage } from '../../store/root';
import { useNavigate } from 'react-router-dom';
import { AppointmentNotificationInterface } from '../../interfaces/AppointmentNotification';

interface NotificationDrawerInterface {
  open: boolean;
  setOpen: (value: boolean) => void;
  notifications: AppointmentNotificationInterface;
}

export const NotificationDrawer: FC<NotificationDrawerInterface> = memo(
  ({ open, setOpen, notifications }) => {
    const { selectedPage } = useSelector((state: RootState) => state.root);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
      window.addEventListener('BluBookingNavigation', (event: any) => {
        navigate(`/${event.detail}`, {
          replace: true,
        });
        dispatch(setSelectedPage(event.detail));
      });

      return () => {
        window.removeEventListener('BluBookingNavigation', (event: any) => {
          navigate(`/${event.detail}`, {
            replace: true,
          });
          dispatch(setSelectedPage(event.detail));
        });
      };

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{ width: 400 }}
          role="presentation"
          // onClick={toggleDrawer(anchor, false)}
          // onKeyDown={toggleDrawer(anchor, false)}
        >
          <Stack flex="1" flexDirection="row" sx={{ pl: 2, pr: 2 }}>
            <BluTitle
              sx={{ flex: 1, fontSize: '22px !important', pt: 2 }}
              text="Notifiche"
            />

            <IconButton sx={{ mt: 1 }} onClick={() => setOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Stack>

          <List>
            {notifications?.notOkSlotCount > 0 && (
              <ListItem>
                <Card
                  elevation={0}
                  sx={{
                    border: '1px solid #a0c7e5',
                    width: '100%',
                    p: 2,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setOpen(false);
                    if (
                      selectedPage !== 'calendar' &&
                      selectedPage !== 'personalCalendar' &&
                      selectedPage !== 'userCalendar' &&
                      selectedPage !== 'studioCalendar'
                    ) {
                      navigate('/calendar', {
                        replace: true,
                      });
                      dispatch(setSelectedPage('calendar'));
                      setTimeout(
                        () =>
                          window.dispatchEvent(
                            new CustomEvent('openAppointmentsSlotNotOkPopup')
                          ),
                        500
                      );
                    } else
                      window.dispatchEvent(
                        new CustomEvent('openAppointmentsSlotNotOkPopup')
                      );
                  }}
                >
                  <Typography>
                    Hai{' '}
                    {notifications?.notOkSlotCount > 1 ? (
                      <>
                        <strong>
                          {` ${notifications?.notOkSlotCount} appuntamenti`}{' '}
                        </strong>
                        {' non validi.'}
                      </>
                    ) : (
                      <>
                        <strong>{` ${notifications?.notOkSlotCount} appuntamento`}</strong>
                        {' non valido.'}
                      </>
                    )}
                  </Typography>
                </Card>
              </ListItem>
            )}
            {notifications?.notConfirmedCount > 0 && (
              <ListItem>
                <Card
                  elevation={0}
                  sx={{
                    border: '1px solid #a0c7e5',
                    width: '100%',
                    p: 2,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setOpen(false);
                    if (
                      selectedPage !== 'calendar' &&
                      selectedPage !== 'personalCalendar' &&
                      selectedPage !== 'userCalendar' &&
                      selectedPage !== 'studioCalendar'
                    ) {
                      navigate('/calendar', {
                        replace: true,
                      });
                      dispatch(setSelectedPage('calendar'));
                      setTimeout(
                        () =>
                          window.dispatchEvent(
                            new CustomEvent('openAppointmentNotConfirmPopup')
                          ),
                        500
                      );
                    } else
                      window.dispatchEvent(
                        new CustomEvent('openAppointmentNotConfirmPopup')
                      );
                  }}
                >
                  <Typography>
                    Hai
                    <strong>
                      {notifications?.notConfirmedCount > 1
                        ? ` ${notifications?.notConfirmedCount} appuntamenti `
                        : ` ${notifications?.notConfirmedCount} appuntamento `}
                    </strong>
                    da confermare.
                  </Typography>
                </Card>
              </ListItem>
            )}

            {notifications?.errorCodeCount > 0 && (
              <ListItem>
                <Card
                  elevation={0}
                  sx={{
                    border: '1px solid #a0c7e5',
                    width: '100%',
                    p: 2,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    // setOpen(false);
                    // if (selectedPage !== 'messageHistory') {
                    //   navigate('/messageHistory', {
                    //     replace: true,
                    //   });
                    //   dispatch(setSelectedPage('messageHistory'));
                    //   setTimeout(
                    //     () =>
                    //       window.dispatchEvent(
                    //         new CustomEvent('appointmentNotificationError')
                    //       ),
                    //     500
                    //   );
                    // } else
                    //   window.dispatchEvent(
                    //     new CustomEvent('appointmentNotificationError')
                    //   );
                    setOpen(false);
                    if (
                      selectedPage !== 'calendar' &&
                      selectedPage !== 'personalCalendar' &&
                      selectedPage !== 'userCalendar' &&
                      selectedPage !== 'studioCalendar'
                    ) {
                      navigate('/calendar', {
                        replace: true,
                      });
                      dispatch(setSelectedPage('calendar'));
                      setTimeout(
                        () =>
                          window.dispatchEvent(
                            new CustomEvent('openAppointmentWithErrorsPopup')
                          ),
                        500
                      );
                    } else
                      window.dispatchEvent(
                        new CustomEvent('openAppointmentWithErrorsPopup')
                      );
                  }}
                >
                  <Typography>
                    Hai
                    <strong>
                      {notifications?.errorCodeCount > 1
                        ? ` ${notifications?.errorCodeCount} appuntamenti `
                        : ` ${notifications?.errorCodeCount} appuntamento `}
                    </strong>
                    con errori.
                  </Typography>
                </Card>
              </ListItem>
            )}
          </List>
        </Box>
      </Drawer>
    );
  }
);

interface NotificationContentInterface {
  notification: any;
}
const errorsMail: { [x: string]: string } = {
  '101': 'email non valida',
  '102': 'contenuto email rilevato come spam',
  '103': 'email annulata dal destinatario',
  '104': 'email non valida',
  '105': 'email non valida',
  '106': 'email non valida',
  '107': "l'IP mittente è bloccato",
  '108': 'sono presenti alcuni errori di validazione',
  '109': 'email non valida',
  '201': 'email non valida',
  '202':
    'la mail del destinatario potrebbe essere temporaneamente non disponibile',
  '203': 'la mail del destinatario ha raggiunto la capacità massima',
  '204':
    'la mail del destinatario potrebbe avere un accesso ristretto oppure non esistere',
  '205': 'la mail del destinatario ha raggiunto la capacità massima',
  '206': 'email non inviata, controlla il destinatario',
  '301': 'email non inviata, controlla il destinatario',
  '302': 'errore temporaneo',
  '303': 'mittente non registrato',
  '304': "errore nell'invio",
  '305': 'template non trovato',
  '306': "l'email continene dei parametri di tracking invalidi",
  '307': 'placeholder troppo grande',
  '308': 'intestazioni personalizzate non valide',
  '309': 'risposta non valida',
  '310': 'mittente non valido',
  '311': 'email contiene una pagina non valida',
  '312': 'email non valida',
  '313': 'email scaduta',
};

const NotificationContent: FC<NotificationContentInterface> = memo(
  ({ notification }) => {
    if (
      notification.emailErrorCode &&
      notification.emailErrorCode in errorsMail
    ) {
      return (
        <Typography sx={{ fontWeight: 'bold', fontSize: '16px' }}>
          {errorsMail[notification.emailErrorCode]}
        </Typography>
      );
    } else if (notification.status !== 1) {
      return (
        <Typography sx={{ fontWeight: 'bold', fontSize: '16px' }}>
          L'appuntamento non confermato
        </Typography>
      );
    }
    return <></>;
  }
);
