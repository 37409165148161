import { BluAutoComplete } from '@bludata/components';
import { Badge, Box, InputLabel, Stack } from '@mui/material';
import dayjs from 'dayjs';
import { PointOfSalesCheckSlotOkObj } from 'packages/blu-booking/src/interfaces/PointOfSaleCheckSlotOk';
import { useAppSelector } from 'packages/blu-booking/src/store';
import { bluBookingLoginData } from 'packages/blu-booking/src/store/root';
import { FC, memo, useCallback, useMemo } from 'react';

interface PointOfSaleCheckInterface {
  pointOfSaleId: string;
  pointOfSaleCheck: PointOfSalesCheckSlotOkObj;
  handleFilterChange: (key: string, value: any) => void;
}

export const PointOfSaleCheck: FC<PointOfSaleCheckInterface> = memo(
  ({ pointOfSaleId, pointOfSaleCheck, handleFilterChange }) => {
    const { pointOfSaleId: pointOfSale } = useAppSelector(bluBookingLoginData);

    const getValue = useCallback(() => {
      console.log('pointOfSaleCheck', pointOfSaleCheck, pointOfSaleId);
      if (pointOfSaleCheck && pointOfSaleId in pointOfSaleCheck)
        return pointOfSaleCheck[pointOfSaleId];
      return '';
    }, [pointOfSaleCheck, pointOfSaleId]);

    const handleChange = useCallback(
      (_: any, newValue: any) => {
        handleFilterChange('pointOfSaleId', newValue?.pointOfSaleId);
      },
      [handleFilterChange]
    );

    const handleColorText = useCallback(() => {
      // if (pointOfSaleCheck && pointOfSaleId in pointOfSaleCheck) {
      //   const { result } = pointOfSaleCheck[pointOfSaleId];
      //   if (
      //     result?.checkSlotUpdatedAt &&
      //     result?.lastCalendarUpdatedAt &&
      //     dayjs(result?.lastCalendarUpdatedAt).isAfter(
      //       dayjs(result?.checkSlotUpdatedAt)
      //     )
      //   )
      //     return 'var(--confirm-pressed-color)';
      // }
      if (!pointOfSaleId || !pointOfSaleCheck) return undefined;
      if (!(pointOfSaleId in pointOfSaleCheck)) return undefined;
      if (pointOfSaleId !== pointOfSale) return 'var(--alert-color)';
      return undefined;
    }, [pointOfSale, pointOfSaleCheck, pointOfSaleId]);

    const GetBadge = useMemo(() => {
      if (!pointOfSaleCheck || !pointOfSaleId) return null;
      let color = 'var(--confirm-pressed-color)';

      if (pointOfSaleCheck && pointOfSaleId in pointOfSaleCheck) {
        const { result } = pointOfSaleCheck[pointOfSaleId];

        if (
          result?.checkSlotUpdatedAt &&
          result?.lastCalendarUpdatedAt &&
          dayjs(result?.lastCalendarUpdatedAt).isAfter(
            dayjs(result?.checkSlotUpdatedAt)
          )
        ) {
          color = 'var(--alert-color)';
        }
      }
      return (
        <Badge
          sx={{
            borderRadius: '50%',
            backgroundColor: color,
            width: 12,
            height: 12,
            border: `thin solid ${color}`,
          }}
          overlap="circular"
        />
      );
    }, [pointOfSaleCheck, pointOfSaleId]);

    return (
      <Stack flex="1">
        <InputLabel>Punto vendita</InputLabel>
        <BluAutoComplete
          size="small"
          disableClearable
          renderOption={(props: any, option: any) => {
            const { result, branch, notOkCount } = option;

            let color = 'var(--confirm-pressed-color)';

            if (
              result?.checkSlotUpdatedAt &&
              result?.lastCalendarUpdatedAt &&
              dayjs(result?.lastCalendarUpdatedAt).isAfter(
                dayjs(result?.checkSlotUpdatedAt)
              )
            ) {
              color = 'var(--alert-color)';
            }
            return (
              <Box component="li" {...props}>
                <Stack
                  flex="1"
                  gap={1}
                  justifyContent="space-between"
                  direction="row"
                  alignItems={'center'}
                >
                  <Stack flex="1">{`(${notOkCount}) ${branch}`}</Stack>
                  <Badge
                    sx={{
                      borderRadius: '50%',
                      backgroundColor: color,
                      width: 12,
                      height: 12,
                      border: `thin solid ${color}`,
                    }}
                    overlap="circular"
                  />
                </Stack>
              </Box>
            );
          }}
          textField={{
            sx: { '.MuiInput-input': { color: handleColorText() } },
            InputProps: {
              endAdornment: (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>{GetBadge}</>
              ),
            },
          }}
          getOptionLabel={(option: any) => {
            return `(${option.notOkCount}) ${option.branch}`;
          }}
          data={Object.values(pointOfSaleCheck ?? {})}
          value={getValue()}
          // defaultValue={CONF.pointOfSale}
          onChange={handleChange}
          isOptionEqualToValue={(option: any, value: any) =>
            option.pointOfSaleId === value.pointOfSaleId
          }
          InputProps={{
            autoComplete: 'new-password',
          }}
        />
      </Stack>
    );
  }
);
