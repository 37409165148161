import SvgIcon from '@mui/material/SvgIcon';
import BagsShopping from '../assets/service-icon/bags-shopping.svg';
import BirthdayCake from '../assets/service-icon/birthday-cake.svg';
import Briefcase from '../assets/service-icon/briefcase.svg';
import Camera from '../assets/service-icon/camera.svg';
import ContactLens from '../assets/service-icon/contact-lens.svg';
import Child from '../assets/service-icon/child.svg';
import ConciergeBell from '../assets/service-icon/concierge-bell.svg';
import Ear from '../assets/service-icon/ear.svg';
import Eye from '../assets/service-icon/eye.svg';
import EyeTest from '../assets/service-icon/eye-test.svg';
import Female from '../assets/service-icon/female.svg';
import FontCase from '../assets/service-icon/font-case.svg';
import Gem from '../assets/service-icon/gem.svg';
import GiftCard from '../assets/service-icon/gift-card.svg';
import GlassCheers from '../assets/service-icon/glass-cheers.svg';
import Glasses from '../assets/service-icon/glasses.svg';
import HandsHeart from '../assets/service-icon/hands-heart.svg';
import HouseReturn from '../assets/service-icon/house-return.svg';
import LowVision from '../assets/service-icon/low-vision.svg';
import Male from '../assets/service-icon/male.svg';
import ParkingCicle from '../assets/service-icon/parking-circle.svg';
import PeopleArrows from '../assets/service-icon/people-arrows.svg';
import Screwdriver from '../assets/service-icon/screwdriver.svg';
import ShoppingCart from '../assets/service-icon/shopping-cart.svg';
import Skiing from '../assets/service-icon/skiing.svg';
import Star from '../assets/service-icon/star.svg';
import Sunglasses from '../assets/service-icon/sunglasses.svg';
import Swimmer from '../assets/service-icon/swimmer.svg';
import TrafficLight from '../assets/service-icon/traffic-light.svg';
import UserCrown from '../assets/service-icon/user-crown.svg';
import UserFriends from '../assets/service-icon/user-friends.svg';
import UserInjured from '../assets/service-icon/user-injured.svg';
import UserMd from '../assets/service-icon/user-md.svg';
import UserShield from '../assets/service-icon/user-shield.svg';
import User from '../assets/service-icon/user.svg';
import Users from '../assets/service-icon/users.svg';
import Watch from '../assets/service-icon/watch.svg';
import Wheelchair from '../assets/service-icon/wheelchair.svg';
import { FC, memo } from 'react';

const iconMap: {
  [key: string]: {
    icon: any;
    viewBox: string;
  };
} = {
  'contact-lens': {
    icon: ContactLens,
    viewBox: '0 0 24 24',
  },
  'font-case': {
    icon: FontCase,
    viewBox: '0 0 640 512',
  },
  eye: {
    icon: Eye,
    viewBox: '0 0 576 512',
  },
  'eye-test': {
    icon: EyeTest,
    viewBox: '0 0 24 24',
  },
  'people-arrows': { icon: PeopleArrows, viewBox: '0 0 576 512' },
  'low-vision': { icon: LowVision, viewBox: '0 0 576 512' },
  'house-return': { icon: HouseReturn, viewBox: '0 0 640 512' },
  screwdriver: { icon: Screwdriver, viewBox: '0 0 512 512' },
  glasses: { icon: Glasses, viewBox: '0 0 576 512' },
  sunglasses: { icon: Sunglasses, viewBox: '0 0 576 512' },
  'bags-shopping': { icon: BagsShopping, viewBox: '0 0 576 512' },
  camera: { icon: Camera, viewBox: '0 0 512 512' },
  gem: { icon: Gem, viewBox: '0 0 576 512' },
  briefcase: { icon: Briefcase, viewBox: '0 0 512 512' },
  ear: { icon: Ear, viewBox: '0 0 384 512' },
  child: { icon: Child, viewBox: '0 0 448 512' },
  'glass-cheers': { icon: GlassCheers, viewBox: '0 0 640 512' },
  star: { icon: Star, viewBox: '0 0 576 512' },
  'traffic-light': { icon: TrafficLight, viewBox: '0 0 384 512' },
  watch: { icon: Watch, viewBox: '0 0 384 512' },
  'concierge-bell': { icon: ConciergeBell, viewBox: '0 0 512 512' },
  'user-md': { icon: UserMd, viewBox: '0 0 448 512' },
  'gift-card': { icon: GiftCard, viewBox: '0 0 576 512' },
  'shopping-cart': { icon: ShoppingCart, viewBox: '0 0 576 512' },
  'user-injured': { icon: UserInjured, viewBox: '0 0 448 512' },
  'user-shield': { icon: UserShield, viewBox: '0 0 640 512' },
  'user-crown': { icon: UserCrown, viewBox: '0 0 448 512' },
  'user-friends': { icon: UserFriends, viewBox: '0 0 640 512' },
  female: { icon: Female, viewBox: '0 0 320 512' },
  male: { icon: Male, viewBox: '0 0 256 512' },
  users: { icon: Users, viewBox: '0 0 640 512' },
  swimmer: { icon: Swimmer, viewBox: '0 0 640 512' },
  skiing: { icon: Skiing, viewBox: '0 0 512 512' },
  user: { icon: User, viewBox: '0 0 448 512' },
  'hands-heart': { icon: HandsHeart, viewBox: '0 0 640 512' },
  'parking-circle': { icon: ParkingCicle, viewBox: '0 0 496 512' },
  'birthday-cake': { icon: BirthdayCake, viewBox: '0 0 448 512' },
  wheelchair: { icon: Wheelchair, viewBox: '0 0 512 512' },
};

export const DynamicIconConverter: FC<{ name: string }> = memo(({ name }) => {
  const IconComponent: { icon: any; viewBox: string } | null =
    name in iconMap ? iconMap[name] : null;

  console.log('icon COmponent', IconComponent, name, iconMap);
  return (
    <img
      src={IconComponent.icon}
      alt={name}
      style={{ color: 'var(--icon-color)', height: '20px', width: '20px' }}
    />
  );
  return IconComponent !== null ? (
    <SvgIcon
      component={IconComponent.icon}
      style={{ color: 'var(--icon-color)', height: '20px', width: '20px' }}
      viewBox={IconComponent.viewBox}
    />
  ) : null;
});
