import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { getUsersWhiteCache } from '../lib/white-cache';
import User_interface from '../interfaces/User_interface';
import { defaultUserBb } from '../utils/default-user-bb';
import { defaultUserFw } from '../utils/default-user-fw';
import { nanoid } from 'nanoid';

export const getUser = async (
  userId?: string,
  mode?: 'modify' | 'info' | 'new'
): Promise<User_interface | null> => {
  const { isFocusWeb } = getUsersWhiteCache();

  const url = isFocusWeb
    ? `/focusapi/global/User/${userId}`
    : `/api/User/${userId}`;

  if (mode === 'new') {
    if (isFocusWeb) return defaultUserFw;
    return defaultUserBb;
  }
  try {
    const { data } = await axios.get(url);

    if (!isFocusWeb)
      return {
        ...data.data,
        userId: data.data?.id,
        photo:
          data.data?.photo &&
          data.data?.photo !== '' &&
          data.data?.photo !== 'null'
            ? `${data.data?.photo}?cacheTime=${nanoid()}`
            : null,
        userPhoto:
          data.data?.photo &&
          data.data?.photo !== '' &&
          data.data?.photo !== 'null'
            ? `${data.data?.photo}?cacheTime=${nanoid()}`
            : null,
      };
    return {
      ...data,
      userId: data?.blubookingId,
      userPhoto: data?.photo,
    };
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const useUser = (
  userId: string,
  mode?: 'modify' | 'info' | 'new',
  key = ''
) => {
  return useQuery({
    queryFn: async () => await getUser(userId, mode),
    queryKey: ['user', userId, key],
    retry: 2,
    retryDelay: 180000,
    cacheTime: 0,
    staleTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
};
