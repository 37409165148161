import { memo, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './schema';
import { Divider, Stack } from '@mui/material';
import React from 'react';
import { BluDialog, BluTitle } from '@bludata/components';
import { Name } from './components/Name';
import { Description } from './components/Description';
import { Active } from './components/Active';
import { PointOfSale } from './components/PointOfSale';
import { Services } from './components/Services';
import { Buttons } from './components/Buttons';
import { useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import {
  setCurrentStudio,
  setDeleteStudio,
  setOpenForm,
} from '../../store/root';
import { getInitialValues } from './utils/initial-value';
import { updateStudio } from './utils/update-studio';
import { AppDispatch } from '../../../../store';
import { setStudioFormLoader } from '../../store/form';
import { StudioFormLoader } from './components/Loader';
import { StudioDeletePopup } from './components/DeletePopup';
// import { DeletePopup } from "../delete-popup";

interface FormInnerProps {
  openForm?: string;
  currentStudio: any;
  data: any;
}

export const StudioForm = memo(
  ({ openForm, currentStudio, data }: FormInnerProps) => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch<AppDispatch>();
    const initialValues = getInitialValues(data);

    const DialogTitle = useCallback(() => {
      if (openForm === 'new') return 'Nuovo studio';
      if (openForm === 'info') return 'Studio';
      return 'Modifica studio';
    }, [openForm]);

    const methods = useForm({
      mode: 'onChange',
      reValidateMode: 'onChange',
      defaultValues: initialValues,
      resolver: yupResolver(schema),
    });

    const submit = useCallback(async () => {
      const data = methods.watch();

      dispatch(setStudioFormLoader(true));
      const result = await updateStudio(
        data,
        currentStudio?.studioId,
        currentStudio?.externalCode
      );

      dispatch(setStudioFormLoader(false));
      if (result) {
        dispatch(setOpenForm(undefined));
        dispatch(setCurrentStudio(undefined));

        // QUERY INVALIDATION
        queryClient.invalidateQueries({ queryKey: ['details', 'studio'] });
        queryClient.invalidateQueries({
          queryKey: ['all-filters', 'studi'],
        });
        queryClient.invalidateQueries({
          queryKey: ['ui', 'studi'],
        });
        queryClient.invalidateQueries(['calendar'], {
          type: 'active',
        });
        queryClient.removeQueries(['calendar'], {
          type: 'inactive',
        });
        queryClient.invalidateQueries(['filter'], {
          type: 'active',
        });
        queryClient.removeQueries(['filter'], {
          type: 'inactive',
        });
      }
    }, [currentStudio, dispatch, methods, queryClient]);

    const handleDelete = useCallback(
      () => dispatch(setDeleteStudio(currentStudio?.studioId)),
      [currentStudio?.studioId, dispatch]
    );

    const Row = ({ children }: { children: JSX.Element[] | JSX.Element }) => (
      <Stack
        gap={1}
        flexWrap="wrap"
        sx={{ width: '100%' }}
        flexDirection="unset"
      >
        {children}
      </Stack>
    );

    return (
      <FormProvider {...methods}>
        <StudioDeletePopup />
        <form id="creationForm" style={{ width: 'inherit' }}>
          <BluDialog
            className="studio-popup"
            open={true}
            zIndex={200}
            draggable
            fullScreen={false}
            dialogTitle={DialogTitle()}
            dialogContent={
              <>
                <StudioFormLoader />

                <div
                  style={{
                    padding: '5px 10px 5px 10px',
                    border: '1px solid var(--divider-color)',
                    borderRadius: '4px',
                    margin: '5px 10px 5px 10px',
                  }}
                >
                  <Divider sx={{ opacity: 0, mb: '20px!important' }} />

                  <BluTitle text="Informazioni generali" />

                  <Row>
                    <Name />
                    <Active />
                  </Row>
                  <Row>
                    <Description />
                  </Row>
                </div>

                <PointOfSale />
                <div style={{ height: '10px' }} />
                <Services />
                <div style={{ height: '10px' }} />
              </>
            }
            sx={{
              '& .MuiDialogContent-root': {
                pb: '0px !important',
                p: 0,
                height: 'fit-content',
              },
              '& .MuiDialog-paper': {
                height: 'fit-content !important',
                minHeight: 'unset !important',
              },
            }}
            dialogActions={
              <Buttons
                initialValues={initialValues}
                handleDelete={handleDelete}
                handleSubmit={submit}
                openForm={openForm}
              />
            }
          />
        </form>
      </FormProvider>
    );
  }
);
