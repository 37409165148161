import { BluTitle } from '@bludata/components';
import { Stack } from '@mui/material';
import { FC, useCallback } from 'react';
import { getPointOfSaleWhiteCache } from '../../../../lib/white-cache';
import { CustomTextField } from '../../common/CustomTextField';
import { CustomCheckbox } from '../../common/CustomCheckBox';
import { CustomInputHtmlArea } from '../../common/CustomHtmlArea';
import { ShopSignSelect } from '../../components/ShopSignSelect/ShopSignSelect';
import { AziendaSelect } from '../../components/AziendaSelect/AziendaSelect';
import { Citta } from '../../components/Citta/Citta';
import { LayoutCrmSelect } from '../../components/CrmLayout/ShopSignSelect';
import { LaboratorioEsterno } from '../../components/LaboratorioEsterno/LaboratorioEsterno';
import { Laboratorio } from '../../components/Laboratorio/Laboratorio';
import { useFormContext } from 'react-hook-form';
import { CustomLogo } from '../../components/Logo/Logo';
import { useAppSelector } from '../../../../../../store';
import { bluBookingLoginData } from '../../../../../../store/slices/root';

export const InformazioniGeneraliFW: FC<{
  mode: 'modify' | 'info' | 'new';
  disabled: boolean;
}> = ({ mode, disabled }): JSX.Element => {
  const { software } = useAppSelector(bluBookingLoginData);

  const { isConsole } = getPointOfSaleWhiteCache();
  const { setValue, trigger } = useFormContext();

  const getTogleEditIsNotDisplay = useCallback(() => {
    if (mode === 'info') return true;
    if (!isConsole) return false;
    if (software === 'bb') return false;
    return true;
  }, [isConsole, mode, software]);

  return (
    <>
      <BluTitle
        text={'Informazioni generali'}
        sx={{ mt: '8px!important', mb: '8px!important' }}
      />
      <Stack direction="column" spacing={1} gap={1}>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-end"
          spacing={2}
          flex={1}
        >
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-end"
            spacing={2}
            sx={{ flex: 1 }}
          >
            <CustomTextField
              name={'branch'}
              disabled={disabled}
              label="Nome punto vendita"
            />

            <CustomTextField
              name={'internalCode'}
              label="Sigla"
              // disabled
              disabled={disabled}
              limit={12}
              inputProps={{
                style: { textTransform: 'uppercase' },
              }}
              onChange={(event: any) => {
                setValue('internalCode', event.target.value.toUpperCase());
                trigger('internalCode');
              }}
              width="165px"
            />
            <AziendaSelect disabled={disabled} />
          </Stack>
        </Stack>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-end"
          spacing={2}
          sx={{ mb: '-3px', flex: 1 }}
        >
          <CustomCheckbox
            name={'isDoNotDisplay'}
            disabled={getTogleEditIsNotDisplay()}
            label="Non visualizzare"
            width="180px"
          />
          <CustomCheckbox
            name={'isActive'}
            disabled={getTogleEditIsNotDisplay()}
            label="Attivo"
            width="100px"
          />
          <CustomCheckbox
            name={'remote'}
            disabled={disabled}
            label="Remoto"
            width="100px"
          />
          <CustomCheckbox
            name={'isSede'}
            disabled={mode !== 'new'}
            label="Sede"
            width="100px"
          />
        </Stack>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <CustomTextField
            name={'name'}
            disabled={disabled}
            label="Ragione sociale"
          />
        </Stack>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <CustomTextField
            name={'address'}
            label="Indirizzo"
            disabled={disabled}
          />
        </Stack>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <CustomTextField name={'address2'} label="" disabled={disabled} />
        </Stack>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Citta disabled={disabled} />

          <CustomTextField
            name={'region'}
            label="Provincia"
            disabled={disabled}
            limit={2}
            inputProps={{
              style: { textTransform: 'uppercase' },
            }}
            width="150px"
          />
          <CustomTextField
            name={'postalCode'}
            label="CAP"
            limit={5}
            disabled={disabled}
          />
        </Stack>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          <CustomTextField
            name={'telephone'}
            disabled={disabled}
            label="Telefono"
          />
          <CustomTextField name={'fax'} disabled={disabled} label="Fax" />
        </Stack>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          <CustomTextField name={'email'} label="Email" disabled={disabled} />

          <Stack
            direction="column"
            justifyContent="center"
            alignItems="start"
            flex="1"
          >
            <Stack
              direction="row"
              justifyContent="end"
              alignItems="end"
              width={'100%'}
              spacing={1}
            >
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="start"
                flex="1"
              >
                <ShopSignSelect disabled={disabled} />
              </Stack>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="start"
                flex="1"
              >
                <LayoutCrmSelect disabled={disabled} />
              </Stack>
            </Stack>
          </Stack>
        </Stack>

        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <CustomTextField
            name={'taxCode'}
            disabled={disabled}
            label="Codice fiscale"
            limit={16}
          />
          <CustomTextField
            name={'vatNumber'}
            disabled={disabled}
            limit={15}
            label="Partita IVA"
          />
          <CustomTextField
            name={'accountingCode'}
            disabled={disabled}
            deletetable
            label="Codice Contabile"
          />
        </Stack>

        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <CustomTextField
            name={'info1'}
            disabled={disabled}
            label="Informazioni aggiuntive"
          />
        </Stack>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <CustomTextField name={'info2'} disabled={disabled} label="" />
        </Stack>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <CustomTextField
            name={'ministryNumber'}
            disabled={disabled}
            label="Numero registrazione Ministero della Salute"
          />
        </Stack>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <CustomTextField
            name={'focusCodiceIstitutoCredito'}
            disabled
            label="Banca di appoggio"
          />
        </Stack>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <CustomTextField name={'sign'} disabled={disabled} label="Insegna" />
        </Stack>

        <Stack
          spacing={1}
          direction="column"
          justifyContent="start"
          alignItems="start"
          sx={{ width: '100%' }}
        >
          <Stack
            spacing={1}
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ width: '100%' }}
          >
            <CustomInputHtmlArea
              name={'notes'}
              label="Note aggiuntive"
              disabled={disabled}
            />
          </Stack>
        </Stack>

        <Stack
          direction="column"
          justifyContent="center"
          alignItems="start"
          flex="1"
          sx={{ pt: 1 }}
          gap={1}
        >
          <BluTitle
            text={'Laboratorio di default'}
            sx={{ mt: '8px!important', mb: '8px!important' }}
          />

          <CustomCheckbox
            name={'internalLaboratory'}
            disabled={disabled}
            label="Laboratorio interno"
            addedChange={() => {
              setValue('focusCodiceLaboratorioEsterno', '');
              setValue('focusCodiceLaboratorio', '');
              trigger([
                'focusCodiceLaboratorioEsterno',
                'focusCodiceLaboratorio',
              ]);
            }}
          />
          {/* fix richiesto da pierantonio il 28 novembre 2023 invertire i campi dei laboratori*/}
          <Laboratorio disabled={disabled} />
          <LaboratorioEsterno disabled={disabled} />
        </Stack>

        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <BluTitle
            text={'Logo'}
            sx={{ mt: '8px!important', mb: '8px!important' }}
          />

          <CustomLogo disabled={disabled} />
        </Stack>
      </Stack>
    </>
  );
};
