import { createTheme, PaletteMode } from '@mui/material';
import { itIT } from '@mui/material/locale';
import { itIT as itGrid } from '@mui/x-data-grid-premium';
import { isTablet } from 'react-device-detect';
import type {} from '@mui/x-data-grid-premium/themeAugmentation';

const checkIpadOs = function () {
  const ua = window.navigator.userAgent;

  if (ua.indexOf('Macintosh') > -1) {
    try {
      document.createEvent('TouchEvent');
      return true; // Il dispositivo è un iPadOS
    } catch (e: any) {
      // Il blocco catch cattura l'errore se l'evento TouchEvent non può essere creato
    }
  }

  return false; // Il dispositivo non è un iPadOS
};

const isTabletView = isTablet || checkIpadOs();

export function getBludataTheme(themeMode: PaletteMode) {
  return createTheme(
    {
      palette: {
        mode: themeMode,
        primary: {
          main: '#0d4da1', // Primary color aggiornato
          light: '#6692d3', // Variante chiara basata su #0d4da1
          dark: '#09397f', // Variante scura basata su #0d4da1
        },
        error: {
          main: '#bc3c44', // colore ext        },
        },
      },
      typography: {
        allVariants: {
          color: 'var(--color)',
        },
        button: {
          textTransform: 'none',
          fontSize: '16px',
          fontWeight: '600',
          letterSpacing: 'none',
        },
      },
      components: {
        MuiDataGrid: {
          styleOverrides: {
            cell: {
              outline: 'none!important',
            },
          },
          defaultProps: {
            density: 'compact',
            localeText: {
              ...itGrid?.components?.MuiDataGrid?.defaultProps?.localeText,
              noRowsLabel: 'Nessun dato disponibile',
              footerTotalRows: 'Righe totali: ',
              MuiTablePagination: {
                ...itGrid?.components?.MuiDataGrid?.defaultProps?.localeText
                  ?.MuiTablePagination,
                labelDisplayedRows: ({ from, to }) => `${from} - ${to}`,
              },
            },
          },
        },
        MuiButton: {
          styleOverrides: {
            root: (options) => {
              const { ownerState, theme } = options;
              const defaultOverride = {
                padding: isTablet ? '0px 8px' : '3px 16px',
                justifyContent: 'start',
                borderRadius: '20px',
              };

              if (
                ownerState.variant === 'outlined' &&
                theme.palette.mode === 'dark'
              ) {
                return {
                  ...defaultOverride,
                  color: 'white',
                  borderColor: 'var(--input-border-color) !important',
                };
              }
              if (
                ownerState.variant === 'text' &&
                theme.palette.mode === 'dark'
              ) {
                return {
                  ...defaultOverride,
                  color: 'white',
                };
              }
              return defaultOverride;
            },
          },
          defaultProps: {
            variant: 'contained',
            disableElevation: true,
          },
        },
        MuiCheckbox: {
          styleOverrides: {
            root: ({ ownerState, theme }) => {
              const defaultOverride = {};
              if (theme.palette.mode === 'dark') {
                return {
                  ...defaultOverride,
                  color: 'var(--color) !important',
                };
              }
              return defaultOverride;
            },
          },
        },
        MuiRadio: {
          styleOverrides: {
            root: ({ ownerState, theme }) => {
              const defaultOverride = {};
              if (theme.palette.mode === 'dark') {
                return {
                  ...defaultOverride,
                  color: 'var(--color) !important',
                };
              }
              return defaultOverride;
            },
          },
        },
        MuiIconButton: {
          styleOverrides: {
            root: ({ theme }) => {
              if (theme.palette.mode === 'dark') {
                return {
                  color: 'white',
                };
              }
              return {};
            },
          },
        },
        MuiPaper: {
          styleOverrides: {
            root: {
              backgroundColor: 'var(--base-foreground-color)',
            },
          },
        },
        MuiDialog: {
          defaultProps: {
            //? HA FIXATO COSE, NON TOCCARE!
            disableAutoFocus: true,
            disableEnforceFocus: true,
            disableRestoreFocus: true,
            fullScreen: isTabletView,
            PaperProps: {
              noValidate: true,
              autoComplete: 'off', // Imposta l'autocomplete per tutti i dialog come form
            },
            sx: {
              '& .MuiDialog-paper': {
                borderRadius: '8px !important',
              },
            },
          },
          styleOverrides: {
            paper: {
              backgroundColor: 'var(--background-color) !important',
              backgroundImage: 'none!important',
            },
          },
        },
        /*        Tema dialog sencha*/ MuiDialogTitle: {
          styleOverrides: {
            root: {
              backgroundColor: 'var(--base-color)',
              color: 'var(--reverse-color)',
              marginBottom: '10px',
              padding: '12px 16px',
              lineHeight: '1.3em',
            },
          },
        },
        MuiTabs: {
          styleOverrides: {
            root: {
              minHeight: 'unset !important',
              '& .MuiTab-root': {
                borderBottom: '1px solid var(--base-color)',
                minHeight: 'unset !important',
                padding: '8px 16px !important',
                borderTopRightRadius: '12px !important',
                borderTopLeftRadius: '0px !important',
              },
              '.bludata-bottom-tab-panel& .MuiTab-root': {
                borderBottom: '1px solid var(--base-color)',
                minHeight: 'unset !important',
                padding: '4px 16px !important',
                borderTopRightRadius: '0px !important',
                borderTopLeftRadius: '0px !important',
              },
              '& .MuiTabs-flexContainer': {
                alignItems: 'end',
              },
              '& .MuiTabs-flexContainer:after': {
                content: '""',
                height: '1px',
                backgroundColor: 'var(--base-color)',
                flex: 1,
              },
              '& .Mui-selected': {
                border: '1px solid var(--base-color)',
                borderTopLeftRadius: '4px',
                borderTopRightRadius: '4px',
                borderBottom: '0px solid',
                color: 'var(--base-color) !important',
              },
            },
            indicator: {
              display: 'none',
            },
          },
        },
        MuiTextField: {
          defaultProps: {
            variant: 'standard',
            autoComplete: 'new-password',
          },
        },
        MuiTypography: {
          defaultProps: {
            variant: 'body2',
          },
        },
        MuiSelect: {
          defaultProps: {
            variant: 'standard',
          },
        },
        MuiFormControlLabel: {
          styleOverrides: {
            label: {
              color: 'var(--color)',
              fontSize: '0.875rem !important',
            },
          },
        },
        MuiInputBase: {
          defaultProps: {
            size: 'small',
            autoComplete: 'new-password',
          },
          styleOverrides: {
            root: {
              marginTop: '0px !important',
              border: '1px solid var(--input-border-color)',
              borderRadius: '4px',
              paddingLeft: '5px',
              paddingTop: '2px',
              paddingRight: '5px',
              fontWeight: 'bold',
              ':before': {
                opacity: '0',
              },
              '& input::placeholder': {
                fontWeight: 400,
                fontStyle: 'italic',
              },
              '& textarea::placeholder': {
                fontWeight: 400,
                fontStyle: 'italic',
              },
            },
          },
        },
        MuiInputLabel: {
          styleOverrides: {
            root: {
              transform: 'none !important',
              color: 'var(--color) !important',
              position: 'relative',
              fontSize: '0.875rem',
            },
          },
        },
        MuiAutocomplete: {
          defaultProps: {
            autoHighlight: true,
          },
        },
      },
    },
    itIT
  );
}
