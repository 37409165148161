import { FC, memo, useCallback, useEffect, useState } from 'react';
import { PointOfSalesInterface } from '../../interfaces/PointOfSale_interface';
import { getInitialForm } from './utils/initial';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './schema/schema';
import { AppDispatch, RootState, useAppSelector } from '../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { isFormLoading, setPointOfSaleOpenForm } from '../../store/root';
import { BluAsyncDialog, BluDialog, BluLoader } from '@bludata/components';
import { Stack, Button, Card } from '@mui/material';
import { If, Then, Else } from 'react-if';
import SaveIcon from '@mui/icons-material/Save';
import ImpostazioniGenerali from './features/ImpostazioniGenerali/ImpostazioniGenerali';
import { Agenda } from './features/Agenda/Agenda';
import { DefaultHours } from './features/DefaultHours/DefaultHours';
import { useQueryClient } from '@tanstack/react-query';
import {
  hasErrordefaultHourValue,
  resetDefaultHours,
} from '../../store/default-hours';
import {
  hasErrorExtraHourValue,
  resetExtraHours,
} from '../../store/extra-hours';
import { updatePointOfSale } from '../../api/update-point-of-sale';
import { createPointOfSale } from '../../api/create-point-of-sale';
import { getPointOfSaleWhiteCache } from '../../lib/white-cache';
import { ExtraHours } from './features/ExtraHours/ExtraHours';
import { bluBookingLoginData } from '../../../../store/slices/root';

interface PointOfSalePopUpFormInterface {
  pointOfSale: PointOfSalesInterface;
  mode: 'modify' | 'info' | 'new';
}

export const PointOfSalePopUpForm: FC<PointOfSalePopUpFormInterface> = memo(
  ({ pointOfSale, mode }) => {
    const initialFormData = getInitialForm(pointOfSale);
    const dispatch: AppDispatch = useDispatch();
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<boolean>(false);
    const queryClient = useQueryClient();
    const errorDefaultHours = useAppSelector(hasErrordefaultHourValue);
    const errorExtraHours = useAppSelector(hasErrorExtraHourValue);
    const isLoadingForm = useAppSelector(isFormLoading);
    const { services, currentPointOfSale } = useSelector(
      (state: RootState) => state.newPointOfSaleRoot
    );
    const { defaultHours } = useSelector(
      (state: RootState) => state.newPointOfSaleDefaultHours
    );

    const { isSingleCalendarManagement } = useAppSelector(bluBookingLoginData);

    const { extra } = useSelector(
      (state: RootState) => state.newPointOfSaleExtraHours
    );

    const methods = useForm({
      mode: 'onChange',
      reValidateMode: 'onChange',
      defaultValues: initialFormData,
      resolver: yupResolver(schema),
    });

    const { errors } = methods.formState;
    const { watch, trigger } = methods;

    useEffect(() => {
      if (errorMessage)
        BluAsyncDialog({
          title: 'Attenzione!',
          message: `${Object.values(errors)
            .map(({ message }: any) => `<li>${message}</li>`)
            .join('')}${
            errorDefaultHours ? '<li>Orario di apertura non valido</li>' : ''
          }${
            errorExtraHours ? '<li>Orario straordinario non valido</li>' : ''
          }`,
          confimButton: 'Chiudi',
          hideDeclineButton: true,
          type: 'warning',
          sx: { '& .MuiDialog-paper': { maxWidth: '370px' } },
          onConfirmClick: () => setErrorMessage(false),
        });
    }, [errorDefaultHours, errorExtraHours, errorMessage, errors]);

    const cancel = useCallback(() => {
      dispatch(setPointOfSaleOpenForm(undefined));
      dispatch(resetDefaultHours());
      dispatch(resetExtraHours());
    }, [dispatch]);

    const save = useCallback(async () => {
      setLoading(true);
      const trig = await trigger();
      if (!trig || errorDefaultHours || errorExtraHours) {
        setLoading(false);
        setErrorMessage(true);
        return;
      }
      const data = watch();
      let result: boolean = false;
      if (mode === 'new') {
        result = await createPointOfSale(
          data,
          defaultHours,
          extra,
          services,
          isSingleCalendarManagement
        );
      } else
        result = await updatePointOfSale(
          currentPointOfSale,
          data,
          defaultHours,
          extra,

          services,
          isSingleCalendarManagement
        );
      setLoading(false);
      if (result) {
        dispatch(setPointOfSaleOpenForm(undefined));
        dispatch(resetDefaultHours());
        dispatch(resetExtraHours());

        queryClient.invalidateQueries(['point-of-sales'], {
          type: 'active',
        });
        queryClient.removeQueries(['point-of-sales'], {
          type: 'inactive',
        });

        queryClient.invalidateQueries(['turni-default-point-of-sale'], {
          type: 'active',
        });
        queryClient.removeQueries(['turni-default-point-of-sale'], {
          type: 'inactive',
        });

        queryClient.invalidateQueries(['turni-extra-point-of-sale'], {
          type: 'active',
        });
        queryClient.removeQueries(['turni-extra-point-of-sale'], {
          type: 'inactive',
        });

        return;
      }
      BluAsyncDialog({
        title: 'Attenzione',
        type: 'error',
        message:
          mode === 'new'
            ? 'Errore durante la creazione del punto vendita.'
            : 'Errore durante la modifica del punto vendita.',
        hideDeclineButton: true,
        confimButton: 'Chiudi',
        sx: { '& .MuiDialog-paper': { maxWidth: '370px' } },
      });
    }, [
      currentPointOfSale,
      defaultHours,
      dispatch,
      errorDefaultHours,
      errorExtraHours,
      extra,
      isSingleCalendarManagement,
      mode,
      queryClient,
      services,
      trigger,
      watch,
    ]);

    return (
      <FormProvider {...methods}>
        <form id="creationForm" style={{ width: 'inherit' }}>
          <BluDialog
            className="service-popup"
            open={true}
            maxWidth="lg"
            zIndex={200}
            draggable
            dialogTitle={'Punto vendita'}
            dialogContent={
              <Stack flex="1" sx={{ p: '10px' }} gap={1}>
                <BluLoader
                  open={loading || isLoadingForm}
                  sx={{ zIndex: '1400' }}
                />
                <Card variant="outlined" sx={{ p: 1 }}>
                  <Stack flex="1" gap={1}>
                    <ImpostazioniGenerali mode={mode} />
                    <Agenda mode={mode} />
                  </Stack>
                </Card>
                <Card variant="outlined" sx={{ p: 1 }}>
                  <DefaultHours mode={mode} />
                  <ExtraHours disabled={mode === 'info'} />
                </Card>
              </Stack>
            }
            sx={{
              '& .MuiDialogContent-root': {
                pb: '0px !important',
                p: 0,
              },
              zIndex: 200,
            }}
            dialogActions={
              <Stack flexDirection="row" flex="1" gap={1} justifyContent="end">
                <If condition={mode !== 'info'}>
                  <Then>
                    <Button variant="outlined" onClick={cancel}>
                      Annulla
                    </Button>
                    <Button
                      startIcon={<SaveIcon />}
                      variant="contained"
                      onClick={save}
                      // disabled={getDisableButton()}
                    >
                      Salva
                    </Button>
                  </Then>
                  <Else>
                    <Button variant="outlined" onClick={cancel}>
                      Chiudi
                    </Button>
                  </Else>
                </If>
              </Stack>
            }
          />
        </form>
      </FormProvider>
    );
  }
);
