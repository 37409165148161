import { Checkbox, FormControlLabel } from '@mui/material';
import { RootState } from 'packages/blu-booking/src/store';
import React, { memo, useCallback, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';

export const IsRecallOn = memo(function IsImportant(): JSX.Element {
  const { shopSignIsRecallOn } = useSelector(
    (state: RootState) => state.bluBookingAppointmentForm
  );
  const isRecallOn = useWatch({ name: 'isRecallOn' });
  const { setValue } = useFormContext();

  const handleChange = useCallback(() => {
    setValue('isRecallOn', !isRecallOn);
  }, [isRecallOn, setValue]);

  const GetCheckBox = useMemo(() => {
    if (shopSignIsRecallOn)
      return (
        <FormControlLabel
          label="Promemoria abilitato"
          sx={{ pb: '5px' }}
          control={
            <Checkbox
              checked={isRecallOn}
              onChange={handleChange}
              sx={{ p: 0 }}
            />
          }
        />
      );
    return null;
  }, [handleChange, isRecallOn, shopSignIsRecallOn]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{GetCheckBox}</>;
});
