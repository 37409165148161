/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, SvgIcon, Tooltip, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import dayjs from 'dayjs';
import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import IMOIcon from '../../../../resources/imo.png';
import BluBooking from '../../../../resources/BluBookingFaviconBlue.svg';
import hexToRgba from 'hex-to-rgba';
import StarIcon from '@mui/icons-material/Star';
import { RootState, useAppSelector } from 'packages/blu-booking/src/store';
import { bluBookingLoginData } from 'packages/blu-booking/src/store/root';
import {
  lightenHexColor,
  mapColor,
  usersMapColor,
  wc_hex_is_light,
} from 'packages/blu-booking/src/utility_objects/ColorManager';
import { errorsMail } from 'packages/blu-booking/src/utility_objects/ErrorMail';
import { ServiceIconConverter } from 'packages/blu-booking/src/utility_objects/ServiceIconConverter';
import { getStatusIcon } from '../../utility_objects/StatusIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/pro-duotone-svg-icons';

export const CalendarEventTemplateWeek = memo((props: any) => {
  const { services, users } = useSelector(
    (state: RootState) => state.bluBookingCalendarFilters
  );
  const { companyColorEnum: companyColor } =
    useAppSelector(bluBookingLoginData);

  const { isTabCliente = false } = props;

  const color = wc_hex_is_light(lightenHexColor(props.color))
    ? 'black'
    : 'white';
  let otherColor;
  if (companyColor) otherColor = mapColor[props.serviceId];
  else otherColor = usersMapColor[props.userId];

  const service =
    services && props.serviceId in services
      ? services[props.serviceId]
      : undefined;
  const user = users && props.userId in users ? users[props.userId] : undefined;

  const GetCreatedForm = useMemo(() => {
    // ? Creato tramite B2C
    if (props.appointmentCreatedFrom === 1)
      return (
        <Tooltip title="Appuntamento creato tramite il web" placement="top">
          <img
            src={BluBooking}
            style={{
              width: '20px',
              height: '20px',
              paddingTop: '2px',
              paddingLeft: '1px',
              paddingRight: '1px',
              paddingBottom: '1px',
            }}
            // viewBox="0 0 283.46 283.46"
            alt=""
          />
        </Tooltip>
      );

    // ? Creato tramite IMO
    if (props.appointmentCreatedFrom === 2)
      return (
        <Tooltip title="Appuntamento creato tramite l'app" placement="top">
          <img
            alt="logo IMO"
            src={IMOIcon}
            style={{
              width: '17px',
              height: '17px',
              paddingTop: '2px',
              paddingLeft: '1px',
              paddingBottom: '1px',
              borderRadius: '50%',
            }}
          />
        </Tooltip>
      );
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.appointmentCreatedFrom, props.color]);

  const ErrorMail = useMemo(() => {
    if (props?.emailErrorCode !== undefined && props?.emailErrorCode !== null)
      return (
        <Tooltip
          title={`${
            props?.emailErrorCode in errorsMail
              ? errorsMail[props?.emailErrorCode]
              : 'errore mail'
          }`}
          placement="right"
        >
          <ErrorTwoToneIcon
            color="error"
            sx={{ fontSize: '18px', pt: '2px', pb: '1px' }}
          />
        </Tooltip>
      );

    return null;
  }, [props?.emailErrorCode]);

  const StatusError = useMemo(() => {
    if (props?.status === 0)
      return (
        <Tooltip title="Appuntamento non confermato" placement="top">
          <ErrorTwoToneIcon
            sx={{ fontSize: '18px', pt: '2px', pb: '1px', color: color }}
          />
        </Tooltip>
      );

    return null;
  }, [color, props?.status]);
  if (isTabCliente) {
    return (
      <Stack
        flexDirection="row"
        sx={{
          height: 'inherit',
          opacity: props?.isReadOnly ? 0.5 : 1,
          color: color,
        }}
      >
        <Stack
          flexDirection="column"
          sx={{ height: 'inherit', width: 'inherit' }}
        >
          <Box
            sx={{
              backgroundColor: otherColor,
              width: '5px',
              height: '100%',
              boxSizing: 'border-box',
            }}
          />
        </Stack>

        <Stack
          flex="1"
          className="template-wrap"
          style={{
            backgroundColor: 'inherit',
            borderRadius: '4px',
            width: '100%',
            paddingTop: '1px',
          }}
        >
          <Stack
            flexDirection="row"
            sx={{ width: '100%', p: '0 3px', boxSizing: 'border-box' }}
          >
            <Stack flexDirection="column" flex={1} sx={{ width: 'inherit' }}>
              {service && (
                <div className="subject">
                  <Stack direction="row" gap={1}>
                    {(props?.serviceIcons ?? []).length > 0 && (
                      <Stack direction="row">
                        {(props?.serviceIcons ?? []).map((value: string) => (
                          <ServiceIconConverter
                            name={value}
                            sx={{ fontSize: '18px', color }}
                          />
                        ))}
                      </Stack>
                    )}
                    <Typography
                      noWrap
                      sx={{ textOverflow: 'ellipsis', color: color }}
                      variant="body2"
                    >{`${service?.name ?? ''}`}</Typography>
                  </Stack>
                </div>
              )}

              <div className="footer"></div>
            </Stack>
            <div
              style={{
                position: 'absolute',
                bottom: 0,
                right: 0,
                backgroundColor:
                  lightenHexColor(props?.color) ??
                  hexToRgba('var(--base-color)', 0.4),
                width: 'fit-content',
                color: color,
              }}
            >
              <Stack flexDirection="row-reverse" gap={0.5}>
                {GetCreatedForm}
                {ErrorMail}
                {StatusError}
                <SvgIcon
                  component={getStatusIcon(props.status)}
                  sx={{
                    fontSize: '18px',
                    pt: '2px',
                    pb: '1px',
                  }}
                />
              </Stack>
            </div>

            {props.isImportant && (
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  backgroundColor:
                    lightenHexColor(props?.color) ??
                    hexToRgba('var(--base-color)', 0.4),
                  width: 'fit-content',
                  color,
                }}
              >
                <StarIcon sx={{ fontSize: '19px' }} />
              </div>
            )}
          </Stack>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack
      flexDirection="row"
      sx={{
        height: 'inherit',
        opacity: props?.isReadOnly ? 0.5 : 1,
        color: color,
      }}
    >
      <Stack
        flexDirection="column"
        sx={{ height: 'inherit', width: 'inherit' }}
      >
        <Box
          sx={{
            backgroundColor: otherColor,
            width: '5px',
            height: '100%',
            boxSizing: 'border-box',
          }}
        />
      </Stack>

      <Stack
        flex="1"
        className="template-wrap"
        style={{
          backgroundColor: 'inherit',
          borderRadius: '4px',
          width: '100%',
          paddingTop: '1px',
        }}
      >
        <Stack
          flexDirection="row"
          sx={{ width: '100%', p: '0 3px', boxSizing: 'border-box' }}
        >
          <Stack flexDirection="column" flex={1} sx={{ width: 'inherit' }}>
            <div className="subject">
              <Stack flexDirection="row" gap={0.5} alignItems="center">
                <Typography
                  sx={{ color: 'inherit' }}
                  variant="subtitle2"
                  fontWeight="bolder"
                  style={{ flex: 1 }}
                >
                  {props.Subject}
                </Typography>
                {props.externalCustomerCode &&
                  props.externalCustomerCode !== '' && (
                    <FontAwesomeIcon
                      icon={faUsers}
                      style={{
                        color: 'inherit',
                        // fontSize: '24px',
                      }}
                    />
                  )}
              </Stack>
            </div>

            {service && (
              <div className="event-description">
                <Stack direction="row" gap={1}>
                  {(props?.serviceIcons ?? []).length > 0 && (
                    <Stack direction="row">
                      {(props?.serviceIcons ?? []).map((value: string) => (
                        <ServiceIconConverter
                          name={value}
                          sx={{ fontSize: '18px', color }}
                        />
                      ))}
                    </Stack>
                  )}
                  <Typography
                    noWrap
                    sx={{ textOverflow: 'ellipsis', color: color }}
                    variant="body2"
                  >{`${service?.name ?? ''}`}</Typography>
                </Stack>
              </div>
            )}

            {user && (
              <div className="event-description">
                <Typography sx={{ color: 'inherit' }} variant="body2">{`${
                  user?.username ?? ''
                }`}</Typography>
              </div>
            )}
            <div className="time">
              <Typography sx={{ color: 'inherit' }} variant="body2">{`${dayjs(
                props.StartTime
              ).format('HH:mm')} - ${dayjs(props.endTime).format(
                'HH:mm'
              )}`}</Typography>
            </div>

            <div className="footer"></div>
          </Stack>
          <div
            style={{
              position: 'absolute',
              bottom: 0,
              right: 0,
              backgroundColor:
                lightenHexColor(props?.color) ??
                hexToRgba('var(--base-color)', 0.4),
              width: 'fit-content',
              color: color,
            }}
          >
            <Stack flexDirection="row-reverse">
              {GetCreatedForm}
              {ErrorMail}
              {StatusError}
              <SvgIcon
                component={getStatusIcon(props.status)}
                sx={{
                  fontSize: '18px',
                  pt: '2px',
                  pb: '1px',
                }}
              />
            </Stack>
          </div>

          {props.isImportant && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                backgroundColor:
                  lightenHexColor(props?.color) ??
                  hexToRgba('var(--base-color)', 0.4),
                width: 'fit-content',
                color,
              }}
            >
              <StarIcon sx={{ fontSize: '19px' }} />
            </div>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
});
