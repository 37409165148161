import { Button, Stack } from '@mui/material';
import { FC, memo, useCallback, useDeferredValue, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BluTitle } from '@bludata/components';
import { AppDispatch, RootState } from 'packages/blu-booking/src/store';
import { setOpenClientPopUp } from '../../store/CreateLink';
import { AppointmentsNotConfirmPopup } from '../AppointmentsNotConfirmPopup/AppointmentsNotConfirmPopup';
import { AppointmentsSlotNotOkPopup } from '../AppointmentsSlotNotOkPopup/AppointmentsSlotNotOkPopup';
import { AppointmentCompleteInterface } from 'packages/blu-booking/src/interfaces/AppointmentComplete';
import { useAppointmentsNotification } from 'packages/blu-booking/src/data_fetching/Notification/AppointmentNotification';
import {
  setAppointmentNotConfirmPopupOpen,
  setAppointmentsSlotNotOkPopupOpen,
  setAppointmentsWithErrorsPopupOpen,
} from '../../store/Calendar';
import { AppointmentsWithErrorsPopup } from '../AppointmentsWithErrors/AppointmentsWithErrorsPopup';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RapidFunctionsInterface {
  pointOfSaleId: string;
  handlePosition: (appointment: AppointmentCompleteInterface) => void;
}

export const RapidFunctions: FC<RapidFunctionsInterface> = memo(
  ({ pointOfSaleId, handlePosition }) => {
    const dispatch = useDispatch<AppDispatch>();
    const { data } = useAppointmentsNotification();
    const notifications = useDeferredValue(data);

    useEffect(() => {
      window.addEventListener('openAppointmentsSlotNotOkPopup', () =>
        dispatch(setAppointmentsSlotNotOkPopupOpen(true))
      );
      window.addEventListener('openAppointmentNotConfirmPopup', () =>
        dispatch(setAppointmentNotConfirmPopupOpen(true))
      );
      window.addEventListener('openAppointmentWithErrorsPopup', () =>
        dispatch(setAppointmentsWithErrorsPopupOpen(true))
      );
      return () => {
        window.removeEventListener('openAppointmentsSlotNotOkPopup', () =>
          dispatch(setAppointmentsSlotNotOkPopupOpen(true))
        );
        window.removeEventListener('openAppointmentNotConfirmPopup', () =>
          dispatch(setAppointmentNotConfirmPopupOpen(true))
        );
        window.removeEventListener('openAppointmentWithErrorsPopup', () =>
          dispatch(setAppointmentsWithErrorsPopupOpen(true))
        );
      };
    }, [dispatch]);

    const { shopSigns } = useSelector(
      (state: RootState) => state.bluBookingCalendarFilters
    );
    const openCreateLink = useCallback(() => {
      dispatch(setOpenClientPopUp(true));
    }, [dispatch]);

    const getDisabled = useCallback(() => {
      return shopSigns && Object.keys(shopSigns).length === 0;
    }, [shopSigns]);
    return (
      <Stack flex="1" gap={1} sx={{ pt: 2 }}>
        <BluTitle text="Funzioni rapide" />
        <Button
          variant="outlined"
          onClick={openCreateLink}
          disabled={getDisabled()}
        >
          Link personalizzato
        </Button>
        <AppointmentsNotConfirmPopup
          notifications={notifications}
          handlePosition={handlePosition}
        />
        <AppointmentsWithErrorsPopup
          notifications={notifications}
          handlePosition={handlePosition}
        />
        <AppointmentsSlotNotOkPopup
          notifications={notifications}
          pointOfSaleId={pointOfSaleId}
          handlePosition={handlePosition}
        />
      </Stack>
    );
  }
);
