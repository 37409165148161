import axios from 'axios';

export const appointmentDelete = async (
  id: string,
  hasToSendEmail = false
): Promise<boolean> => {
  try {
    await axios.delete(`/api/Appointment/${id}`, {
      params: { hasToSendEmail },
    });

    return true;
  } catch (error) {
    return false;
  }
};
