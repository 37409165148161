/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { ShopSignLinkInterface } from '../../interfaces/ShopSignLink';

export const createShopSignLink = async (
  link: ShopSignLinkInterface
): Promise<string | undefined> => {
  try {
    const params: any = {};

    const keys = [
      'shopSignId',
      'pointOfSaleId',
      'userId',
      'serviceId',
      'studioId',
      // "name",
      // "surname",
      // "email",
      // "telephone",
      // "externalCustomerCode",
      'description',
    ];
    for (const key of keys) {
      if ((link as any)[key] && (link as any)[key] !== '')
        params[key] = (link as any)[key];
    }
    const {
      data: { data },
    } = await axios.post(`/api/ShopSignLink`, params);
    return data?.id;
  } catch (err) {
    console.trace(err);
    return undefined;
  }
};
