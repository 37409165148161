import { FC, memo, useMemo } from 'react';
import { Provider } from 'react-redux';
import React from 'react';
import { store } from '../../store';
import { ChatContainer } from './chat-ai';
import { Stack } from '@mui/material';

interface ChatAiInterface {
  threadId?: string | null;
  setThreadId: (newThreadId?: string | null) => void;
  isFocusWeb?: boolean;
}

const BluChatAi: FC<ChatAiInterface> = memo(({ threadId, setThreadId }) => {
  const GetChatContent = useMemo(() => {
    if (threadId)
      return <ChatContainer threadId={threadId} setThreadId={setThreadId} />;
    return <></>;
  }, [setThreadId, threadId]);

  return (
    <Provider store={store}>
      <Stack flex="1">{GetChatContent}</Stack>
    </Provider>
  );
});

export default BluChatAi;
