import '../../../../styles/kanban.css';

import { FC, useCallback } from 'react';
import { CardData, ColumnData } from './Kanban/interfaces';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Column from './Kanban/Column';
import { useFormContext, useWatch } from 'react-hook-form';
import { Stack, Typography } from '@mui/material';
import { TouchBackend } from 'react-dnd-touch-backend';
import {
  DndProvider,
  TouchTransition,
  MouseTransition,
  usePreview,
} from 'react-dnd-multi-backend';

interface KanbanProps {
  disabled: boolean;
  software: string;
}

const HTML5toTouch = {
  backends: [
    {
      id: 'html5',
      backend: HTML5Backend,
      transition: MouseTransition,
    },
    {
      id: 'touch',
      backend: TouchBackend,
      options: { enableMouseEvents: true },
      preview: true,
      transition: TouchTransition,
    },
  ],
};

const MyPreview = () => {
  const preview = usePreview();
  if (!preview.display) {
    return null;
  }
  const { itemType, item, style } = preview;
  return (
    <div
      className="kanban-notification-card"
      style={{
        opacity: 0.5,
        padding: '15px 0px',
        borderRadius: '5px',
        cursor: 'grab',
        width: '200px',
        ...style,
      }}
    >
      <Typography textAlign="center">{(item as any).label}</Typography>
    </div>
  );
  // render your preview
};

const Kanban: FC<KanbanProps> = ({ disabled, software }) => {
  // Distribuisci le card nelle colonne

  const email = useWatch({ name: 'emailPriority' });
  const sms = useWatch({ name: 'smsPriority' });
  const imo = useWatch({ name: 'imoPriority' });

  const { setValue, trigger } = useFormContext();

  const handleDrop = (card: CardData, targetColumnId: number) => {
    console.log();
    switch (card.id) {
      case 1:
        setValue('emailPriority', targetColumnId);
        trigger();
        break;
      case 2:
        setValue('smsPriority', targetColumnId);
        trigger();
        break;
      case 3:
        setValue('imoPriority', targetColumnId);
        trigger();
        break;
      default:
        break;
    }
  };

  const getColumns = useCallback(() => {
    const columns: { [x: string]: ColumnData } = {
      '0': { id: 0, name: 'Non inviare', cards: [] },
      '1': { id: 1, name: 'Step 1', cards: [] },
      '2': { id: 2, name: 'Step 2', cards: [] },
      '3': { id: 3, name: 'Step 3', cards: [] },
    };
    columns[email.toString()].cards.push({
      id: 1,
      value: email,
      label: 'Email',
      isDraggable: !disabled,
    });

    columns[sms.toString()].cards.push({
      id: 2,
      value: sms,
      label: 'SMS',
      isDraggable: !disabled,
    });

    if (software === 'fw') {
      columns[imo.toString()].cards.push({
        id: 3,
        value: imo,
        label: 'IMO',
        isDraggable: !disabled,
      });
    }
    return Object.values(columns);
  }, [disabled, email, imo, sms, software]);

  return (
    <DndProvider options={HTML5toTouch}>
      <MyPreview />
      <Stack
        flex="1"
        direction={'row'}
        justifyContent="center"
        alignItems="start"
        sx={{ width: '100%', p: '0 20px' }}
        gap={2}
      >
        {getColumns().map((column) => (
          <Column key={column.id} column={column} onDrop={handleDrop} />
        ))}
      </Stack>
    </DndProvider>
  );
};

export default Kanban;
