import { Paper, Stack, SxProps, Theme, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import './BludataRightSideSectionMenu.scss';
import { isTablet } from 'react-device-detect';
import { isIpadOs } from '@bludata/utility-functions';

export const minDesktopWidth = 1200;

interface Props {
  children: React.ReactNode | React.ReactNode[];
  sx?: SxProps;
}

function BludataRightSideSectionMenu(props: Props) {
  const { sx } = props;
  // Verifica se la larghezza dello schermo è inferiore a 1000px
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(minDesktopWidth)
  );
  const isTabletView = isSmallScreen || isTablet || isIpadOs;
  const [opened, setOpened] = useState(false);

  const onMenuToggleClick = () => {
    setOpened(!opened);
  };

  if (!isTabletView) {
    return (
      <Paper
        variant="outlined"
        className="bludata-section-right-menu open bludata-section-right-menu-desktop"
        sx={sx}
      >
        <Stack
          className="bludata-section-right-menu-items-container"
          sx={{ mr: '5px !important' }}
        >
          <Stack className="bludata-section-right-menu-items-container-body">
            {props.children}
          </Stack>
        </Stack>
      </Paper>
    );
  }

  return (
    <Paper
      variant="outlined"
      className={'bludata-section-right-menu ' + (opened ? 'open' : '')}
      sx={sx}
    >
      {!opened && (
        <Stack
          onClick={onMenuToggleClick}
          className="bludata-section-right-menu-expander"
        >
          <KeyboardArrowLeftIcon />
          <div
            style={{
              display: 'none',
            }}
          >
            {props.children}
          </div>
        </Stack>
      )}
      {opened && (
        <Stack flex={1} flexDirection="row">
          <Stack
            onClick={onMenuToggleClick}
            className="bludata-section-right-menu-expander"
          >
            <KeyboardArrowRightIcon />
          </Stack>
          <Stack className="bludata-section-right-menu-items-container">
            <Stack className="bludata-section-right-menu-items-container-body">
              {props.children}
            </Stack>
          </Stack>
        </Stack>
      )}
    </Paper>
  );
}

export default BludataRightSideSectionMenu;
