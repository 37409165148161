/* eslint-disable @typescript-eslint/no-explicit-any */
import { useSelector } from 'react-redux';
import { Stack, Typography } from '@mui/material';
import { RootState } from 'packages/blu-booking/src/store';
import { Scheduler } from './UI/Scheduler';
import { CalendarLoader } from '../../UI/CalendarLoader/CalendarLoader';
import { memo } from 'react';
import { useUsersHoursCalendar } from 'packages/blu-booking/src/data_fetching/UserHours/UsersHoursCalendar';
import { useUsersHoursShift } from 'packages/blu-booking/src/data_fetching/UserHours/UsersHoursShift';

export const UserCalendarInner = memo(
  function UserCalendarInner(): JSX.Element {
    const { loadingAppointments } = useSelector(
      (state: RootState) => state.bluBookingaAppointments
    );

    const { filtersLoad } = useSelector(
      (state: RootState) => state.bluBookingCalendarFilters
    );

    const { selectedDate, selectedPointOfSale } = useSelector(
      (state: RootState) => state.bluBookingUserCalendar
    );

    const {
      data: calendarPointOfSale,
      isLoading: isLoadingCalendarPointOfSale,
    } = useUsersHoursCalendar({
      pointOfSaleId: selectedPointOfSale?.id,
      selectedDate,
      options: {
        enabled: filtersLoad ?? false,
        cacheTime: 300000,
        staleTime: 300000,
        refetchInterval: 300000,
      },
    });

    const { data: shiftPointOfSale, isLoading: isLoadingShiftPointOfSale } =
      useUsersHoursShift({
        pointOfSaleId: selectedPointOfSale?.id,
        selectedDate,
        options: {
          enabled: filtersLoad ?? false,
          cacheTime: 300000,
          staleTime: 300000,
          refetchInterval: 300000,
        },
      });

    return (
      <div
        style={{
          height: '100%',
        }}
      >
        {calendarPointOfSale &&
        (calendarPointOfSale as any).length > 0 &&
        shiftPointOfSale &&
        (shiftPointOfSale as any).length > 0 ? (
          <Scheduler
            calendarPointOfSale={calendarPointOfSale}
            shiftPointOfSale={shiftPointOfSale}
          />
        ) : (
          <Stack
            alignItems="center"
            justifyContent="center"
            direction="row"
            flex="1"
            sx={{ height: '100%', width: '100%' }}
          >
            <Typography fontWeight="bold">
              Nessun utente per il punto vendita selezionato
            </Typography>
          </Stack>
        )}
        <CalendarLoader
          open={
            loadingAppointments ||
            isLoadingCalendarPointOfSale ||
            isLoadingShiftPointOfSale
          }
        />
      </div>
    );
  }
);
