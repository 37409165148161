import {
  IconButton,
  Stack,
  TextField,
  Typography,
  SxProps,
} from '@mui/material';
import { NumericFormat } from 'react-number-format';
import ClearIcon from '@mui/icons-material/Clear';
import BluTooltip from '../Tooltip/Tooltip';
import { useState, useEffect } from 'react';

interface Props {
  value: number | null;
  label?: string;
  required?: boolean;
  onChange: (value: number | null) => void;
  min?: number;
  max?: number;
  step?: number;
  decimals?: number;
  sx?: SxProps;
  disabled?: boolean;
  onDelete?: () => void;
  onClick?: (evt?: any) => void;
  onEnter?: () => void;
  endLabel?: string;
  tooltip?: string;
}

const BluNumberField = ({
  value,
  label,
  required,
  onChange,
  min = 0,
  max,
  step,
  decimals = 0,
  sx,
  disabled,
  onDelete,
  onClick,
  onEnter,
  endLabel,
  tooltip,
}: Props) => {
  // Stato locale per il valore temporaneo
  const [tempValue, setTempValue] = useState<number | null>(value);
  const [focused, setFocused] = useState(false);

  // Sincronizza il valore locale se `value` cambia dall'esterno
  useEffect(() => {
    setTempValue(value);
  }, [value]);

  // Definizione dello step dinamico
  const computedStep =
    step ?? (decimals === 0 ? 1 : 1 / Math.pow(10, decimals));

  // Aggiunta dell'asterisco alla label se richiesta
  const labelStr = label ? `${label}${required ? ' *' : ''}` : undefined;

  // Gestione eventi tastiera
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && onEnter) {
      onEnter();
    }
  };

  // Chiamare `onChange` solo quando si esce dal campo
  const handleBlur = () => {
    if (tempValue !== value) {
      let newValue = tempValue;

      if (min && newValue && newValue < min) {
        newValue = min;
      }

      if (max && newValue && newValue > max) {
        newValue = max;
      }
      setTempValue(newValue);
      onChange(newValue);
    }
    setFocused(false);
  };

  let canBeNegative = false;
  if (min && min < 0) {
    canBeNegative = true;
  }

  return (
    <Stack
      sx={{
        ...sx,
        position: 'relative',
        '& .MuiInputBase-root': {
          pr: onDelete ? '32px' : '5px',
          // opacity: disabled ? 0.3 : 1,
          pointerEvents: disabled ? 'none' : 'auto',
        },
      }}
      flexDirection="row"
      alignItems="end"
    >
      <NumericFormat
        customInput={TextField}
        value={tempValue ?? ''}
        label={labelStr}
        decimalScale={decimals}
        fixedDecimalScale={!focused}
        thousandSeparator="."
        decimalSeparator=","
        allowNegative={canBeNegative}
        disabled={disabled}
        inputProps={{
          min,
          max,
          step: computedStep,
        }}
        onValueChange={(values) => {
          const floatValue = values.floatValue ?? null;

          // Evita di impostare -0, ma accetta tutti gli altri numeri
          if (Object.is(floatValue, -0)) {
            return;
          }

          setTempValue(floatValue);
        }}
        onBlur={handleBlur} // Chiama `onChange` solo quando si esce dal campo
        onKeyDown={handleKeyDown}
        onFocus={() => setFocused(true)}
        onClick={onClick}
        fullWidth
      />

      {tooltip && (
        <BluTooltip
          text={tooltip}
          sx={{
            position: 'absolute',
            right: 0,
            top: 0,
            p: '2px',
            backgroundColor: '#f5f5f5',
            '.dark-mode-bludata &': {
              backgroundColor: '#404040',
            },
            '& svg': { width: '15px!important', height: '15px!important' },
          }}
        />
      )}

      {onDelete && value !== null && (
        <IconButton
          sx={{ position: 'absolute', right: 0 }}
          size="small"
          color="primary"
          onClick={() => {
            setTempValue(null);
            onChange(null);
          }}
        >
          <ClearIcon />
        </IconButton>
      )}

      {endLabel && (
        <Typography
          sx={{
            position: 'absolute',
            opacity: 0.6,
            top: 0,
            mt: '6px',
            right: onDelete ? 40 : 10,
          }}
        >
          {endLabel}
        </Typography>
      )}
    </Stack>
  );
};

export default BluNumberField;
