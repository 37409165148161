import { Button, Card, FormControl, Stack, Typography } from '@mui/material';
import { fetchAnalysisRange } from './api/fetchAnalysisRange';
import { FC, useEffect, useMemo, useState } from 'react';
import { setAnalysis } from './api/setAnalysis';
import fetchPointOfSales from './api/fetchPointOfSales';
import fetchLayout from './api/fetchLayout';
import {
  BluAlert,
  BluBar,
  BludataRightSideSectionMenu,
  BluDatePicker,
  BluDoughnut,
  BluLine,
  BluLoader,
  BluStackedBar,
  BluTextField,
  BluTitle,
} from '@bludata/components';
import { nanoid } from '@reduxjs/toolkit';
import moment, { Moment } from 'moment';
import BluAutocomplete from './BluAutocomplete';
import Analisi_inteface from './api/interface/Analisi_inteface';
import PointOfSale_interface from './api/interface/PointOfSale_interface';
import PointOfSale_interface_global from './api/interface/PointOfSale_interface_global';
import ShopSigns_interface from './api/interface/ShopSigns_interface';
import { JSX } from 'react/jsx-runtime';

const Analisi: FC<{ software: 'bb' | 'fw' | 'crm' | 'f10'; root?: string }> = ({
  software,
  root = '',
}): JSX.Element => {
  const [selectedDate, setSelectedDate] = useState<Analisi_inteface>({
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().add(1, 'days').format('YYYY-MM-DD'),
  });

  const [maxMinDate, setMaxMinDate] = useState<Analisi_inteface>({
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().add(1, 'days').format('YYYY-MM-DD'),
  });

  const handleStartDateChange = (date: Moment | null) => {
    if (!date) return;

    const newStartDate = date.format('YYYY-MM-DD');
    let newEndDate = selectedDate.endDate;

    // Check if endDate is same or before the new startDate
    if (moment(selectedDate.endDate).isSameOrBefore(newStartDate)) {
      newEndDate = date.clone().add(1, 'days').format('YYYY-MM-DD');
    }

    setSelectedDate({ startDate: newStartDate, endDate: newEndDate });
  };

  const handleEndDateChange = (date: Moment | null) => {
    if (!date) return;

    // Imposta la data di fine, assicurandosi che non sia prima della data di inizio e non oltre un anno dopo
    let newEndDate = date.format('YYYY-MM-DD');
    if (
      date.isBefore(moment(selectedDate.startDate)) ||
      date.isAfter(moment(selectedDate.startDate).add(1, 'year'))
    ) {
      newEndDate = moment(selectedDate.startDate)
        .add(1, 'year')
        .format('YYYY-MM-DD');
    }

    setSelectedDate((prevState) => ({ ...prevState, endDate: newEndDate }));
  };

  const [pdvs, setPdvs] = useState<
    PointOfSale_interface[] | PointOfSale_interface_global[]
  >([]);
  const [selectedPdv, setSelectedPdv] = useState<
    PointOfSale_interface[] | PointOfSale_interface_global[]
  >([]);
  const [layouts, setLayouts] = useState<ShopSigns_interface[]>([]);
  const [selectedLayout, setSelectedLayout] = useState<ShopSigns_interface[]>(
    []
  );

  const [charts, setCharts] = useState<any>(null);

  const [loading, setLoading] = useState(true);

  const [error, setError] = useState('');

  const handleError = (err: string) => {
    setLoading(true);
    setSelectedPdv(pdvs);
    setSelectedLayout(layouts);
    setError(err);
    setAnalysis(
      selectedDate!.startDate,
      selectedDate!.endDate,
      0,
      software,
      null,
      null
    )
      .then((res) => {
        setCharts(res);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);

    const fetchAnalysisRangeP = new Promise((resolve, reject) => {
      const responeHandle = () => {
        resolve(true);
      };
      fetchAnalysisRange(software)
        .then((res: Analisi_inteface) => {
          const resStartDate = moment(res['startDate']);
          const resEndDate = moment(res['endDate']);

          let newStartDate;
          let newEndDate;

          // Se endDate è nel passato
          if (resEndDate.isBefore(moment())) {
            // Imposta endDate alla data fornita
            newEndDate = resEndDate;
          } else {
            // Se endDate è oggi o nel futuro
            // Imposta endDate a oggi
            newEndDate = moment();
          }

          newStartDate = moment.max(
            resStartDate,
            newEndDate.clone().subtract(1, 'month')
          );

          setSelectedDate({
            startDate: newStartDate.format('YYYY-MM-DD'),
            endDate: newEndDate.format('YYYY-MM-DD'),
          });

          setMaxMinDate({
            startDate: moment(res['startDate']).format('YYYY-MM-DD'),
            endDate: moment(res['endDate']).format('YYYY-MM-DD'),
          });

          setAnalysis(
            newStartDate.format('YYYY-MM-DD'),
            newEndDate.format('YYYY-MM-DD'),
            0,
            software,
            null,
            null
          )
            .then((res) => {
              setCharts(res);
              responeHandle();
            })
            .catch((err) => {
              handleError('Internal Server Error');
              resolve(false);
              setLoading(false);
            });
        })
        .catch((err) => {
          handleError('Internal Server Error');
          resolve(false);
          setLoading(false);
        });
    });

    const fetchLayoutP = new Promise((resolve, reject) => {
      const responeHandle = () => {
        resolve(true);
      };
      fetchLayout(software)
        .then((res) => {
          setLayouts(res);
          responeHandle();
        })
        .catch((err) => {
          handleError('Internal Server Error');
          resolve(false);
          setLoading(false);
        });
    });

    const fetchPointOfSaleP = new Promise((resolve, reject) => {
      const responeHandle = () => {
        resolve(true);
      };
      fetchPointOfSales(software)
        .then((res) => {
          setPdvs(res);
          responeHandle();
        })
        .catch((err) => {
          resolve(false);
          handleError('Internal Server Error');
        });
    });

    Promise.all([fetchPointOfSaleP, fetchLayoutP, fetchAnalysisRangeP])
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        setError('Errore nel caricamento');
        setLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const chartsSection = useMemo(() => {
    console.log(charts, loading);
    if (charts === null && loading === false)
      return (
        <Stack
          flexDirection="row"
          height={'100%'}
          flex={1}
          sx={{ backgroundColor: 'var(--hovered-background-color)' }}
        >
          <Card variant="outlined" sx={{ p: '0px', height: '100%', flex: 1 }}>
            <Stack
              direction="column"
              alignItems="left"
              gap={1}
              sx={{ p: '10px' }}
            >
              <BluTitle text={root + 'Analisi'} />
              <Typography>Nessun grafico disponibile</Typography>
            </Stack>
          </Card>
        </Stack>
      );
    if (!!charts) {
      return (
        <>
          <Card variant="outlined" sx={{ p: '12px' }}>
            <Stack direction="row" alignItems="center" gap={1}>
              <BluTitle text={root + charts.title} />
            </Stack>
          </Card>
          <Card
            variant="outlined"
            sx={{
              p: 1,
              mt: 0.5,
              flex: 1,
              display: 'flex',
              overflowY: 'auto',
            }}
          >
            <Stack direction={'column'} flex={1} spacing={2}>
              <Stack direction="column" flex={1} spacing={2}>
                {charts.sections.map((itemRow: any) => {
                  return (
                    <Stack
                      direction="column"
                      flex={1}
                      sx={{
                        border: '1px solid rgba(0, 0, 0, 0.12)',
                        p: 2,
                        borderRadius: '4px',
                        marginBottom: '10px!important',
                      }}
                    >
                      <BluTitle text={itemRow.title} key={nanoid() + 'title'} />
                      {itemRow.subSections.map((item: any) => {
                        return (
                          <Stack
                            spacing={2}
                            direction={'row'}
                            key={nanoid()}
                            sx={{
                              height: 450 * item.charts.length,
                              position: 'relative',
                            }}
                            flex={1}
                          >
                            {item.charts.map((chart: any) => {
                              return (
                                <Stack
                                  spacing={2}
                                  direction={'column'}
                                  key={nanoid()}
                                  sx={{
                                    flex: 1,
                                    height: 450,
                                    position: 'relative',
                                  }}
                                >
                                  <Stack
                                    spacing={2}
                                    key={nanoid()}
                                    direction={'row'}
                                    sx={{
                                      position: 'absolute',
                                      top: 0,
                                      left: 0,
                                      right: 0,
                                      bottom: 0,
                                    }}
                                    justifyContent="center"
                                  >
                                    {chart.chartType === 'DoughtnutChart' && (
                                      <BluDoughnut
                                        key={nanoid() + 'DoughtnutChart'}
                                        padding={{ p: 15 }}
                                        dataObj={{
                                          labels: chart.value.labels,
                                          datasets: !!chart.value.datasets
                                            .length
                                            ? chart.value.datasets
                                            : [
                                                {
                                                  label: '',
                                                  data: [0],
                                                  backgroundColor: '',
                                                  borderColor: '',
                                                },
                                              ],
                                        }}
                                        legend={{
                                          position: 'bottom',
                                          align: 'center',
                                        }}
                                        title={{
                                          text: chart.title,
                                          fontSize: 20,
                                        }}
                                      />
                                    )}

                                    {chart.chartType === 'LineChart' && (
                                      <BluLine
                                        legend={{
                                          position: 'bottom',
                                          align: 'start',
                                        }}
                                        key={nanoid() + 'LineChart'}
                                        range={{
                                          period: 'month',
                                          value: new Date(),
                                        }}
                                        padding={{ p: 15 }}
                                        dataObj={{
                                          labels: chart.value.labels,
                                          datasets: !!chart.value.datasets
                                            .length
                                            ? chart.value.datasets
                                            : [
                                                {
                                                  label: '',
                                                  data: [0],
                                                  backgroundColor: '',
                                                  borderColor: '',
                                                },
                                              ],
                                        }}
                                        title={{
                                          text: chart.title,
                                          fontSize: 20,
                                        }}
                                      />
                                    )}

                                    {chart.chartType === 'StackedBarChart' && (
                                      <BluStackedBar
                                        legend={{
                                          position: 'bottom',
                                          align: 'start',
                                        }}
                                        key={nanoid() + 'StackedBarChart'}
                                        range={{
                                          period: 'month',
                                          value: new Date(),
                                        }}
                                        padding={{ p: 15 }}
                                        dataObj={{
                                          labels: chart.value.labels,
                                          datasets: chart.value.datasets,
                                        }}
                                        title={{
                                          text: chart.title,
                                          fontSize: 20,
                                        }}
                                      />
                                    )}

                                    {chart.chartType === 'VerticalBarChart' && (
                                      <BluBar
                                        legend={{
                                          position: 'bottom',
                                          align: 'start',
                                        }}
                                        key={nanoid() + 'VerticalBarChart'}
                                        range={{
                                          period: 'month',
                                          value: new Date(),
                                        }}
                                        padding={{ p: 15 }}
                                        dataObj={{
                                          labels: chart.value.labels,
                                          datasets: chart.value.datasets,
                                        }}
                                        title={{
                                          text: chart.title,
                                          fontSize: 20,
                                        }}
                                      />
                                    )}
                                  </Stack>
                                </Stack>
                              );
                            })}
                          </Stack>
                        );
                      })}
                    </Stack>
                  );
                })}
              </Stack>
            </Stack>
          </Card>
        </>
      );
    } else {
      return <></>;
    }
  }, [charts, loading]);

  return (
    <Stack
      flexDirection="row"
      height={'100%'}
      flex={1}
      sx={{ backgroundColor: 'var(--hovered-background-color)' }}
    >
      <BluAlert
        show={!!error}
        text={error}
        handleClose={() => {
          setError('');
        }}
        type={'error'}
      />
      <BluLoader open={loading} />
      <Stack flexDirection="column" flex={1} sx={{ p: 0.5, pr: 0 }}>
        <Stack direction={'column'} sx={{ height: '100%', overflowY: 'auto' }}>
          {chartsSection}
        </Stack>
      </Stack>
      <BludataRightSideSectionMenu sx={{ m: 0.5 }}>
        <Stack sx={{ ml: 0.5, flex: 1, p: 1 }}>
          <Stack
            sx={{ width: '250px' }}
            direction="column"
            gap={1}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <BluTitle text={'Filtri di visualizzazione'} />

            {!!pdvs && !!layouts && (
              <>
                <BluAutocomplete
                  dataList={layouts}
                  selectedList={selectedLayout!}
                  getAutocompleteLabelKey={(option) => `${option.name}`}
                  label={'Layout'}
                  onSelectionChanged={(e) => {
                    setSelectedLayout(e);
                  }}
                />
                <BluAutocomplete
                  dataList={pdvs}
                  selectedList={selectedPdv!}
                  getAutocompleteLabelKey={(option) =>
                    `${
                      option.branch ||
                      option.name ||
                      option.nameOnline ||
                      option.branchName
                    }`
                  }
                  label={'Punti vendita'}
                  onSelectionChanged={(e) => {
                    console.log(e);
                    setSelectedPdv(e);
                  }}
                />
              </>
            )}

            <Typography>Periodo</Typography>
            <Stack direction={'row'} width={'100%'} spacing={1}>
              <FormControl fullWidth>
                <BluDatePicker
                  label="Data inizio"
                  value={moment(selectedDate.startDate)}
                  minDate={moment(maxMinDate.startDate)}
                  maxDate={moment(maxMinDate.endDate)}
                  onChange={handleStartDateChange}
                  renderInput={(params: JSX.IntrinsicAttributes & any) => (
                    <BluTextField {...params} />
                  )}
                />
                <BluDatePicker
                  label="Data fine"
                  value={moment(selectedDate.endDate)}
                  minDate={moment(selectedDate.startDate)}
                  maxDate={moment(maxMinDate.endDate)}
                  onChange={handleEndDateChange}
                  renderInput={(params: JSX.IntrinsicAttributes & any) => (
                    <BluTextField {...params} />
                  )}
                />
              </FormControl>
            </Stack>
            <Button
              fullWidth
              style={{ justifyContent: 'center' }}
              sx={{ mt: '5px' }}
              onClick={() => {
                setLoading(true);
                setAnalysis(
                  selectedDate!.startDate,
                  selectedDate!.endDate,
                  0,
                  software,
                  !!selectedPdv! && selectedPdv.length !== pdvs.length
                    ? selectedPdv.map((item: any) => {
                        return software === 'fw' ? item.blubookingId : item.id;
                      })
                    : null,
                  !!selectedLayout && selectedLayout.length !== layouts.length
                    ? selectedLayout.map((item) => item.id)
                    : null
                )
                  .then((res) => {
                    setCharts(res);
                    setLoading(false);
                  })
                  .catch((err) => {
                    setLoading(false);
                    handleError('Internal Server Error');
                  });
              }}
            >
              Applica filtri
            </Button>
          </Stack>
        </Stack>
      </BludataRightSideSectionMenu>
    </Stack>
  );
};

export default Analisi;
