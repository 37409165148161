import { ShopSignObject } from 'packages/blu-booking/src/interfaces/ShopSign';
import { ShopSignLinkInterface } from 'packages/blu-booking/src/interfaces/ShopSignLink';
import { store } from 'packages/blu-booking/src/store';

export const getInitialData = (
  calendar: string,
  shopSigns: ShopSignObject
): ShopSignLinkInterface => {
  const filters = {
    pointOfSaleId: '',
    serviceId: '',
    userId: '',
    studioId: '',
    shopSignId: '',
  };
  let data;

  switch (calendar) {
    case 'personal':
      data = store.getState().bluBookingPersonalCalendar;
      filters.serviceId =
        (data?.serviceId ?? []).length === 1 ? data?.serviceId[0] : '';
      filters.userId = data.selectedUsers[0].id;
      break;
    case 'studio':
      data = store.getState().bluBookingStudioCalendar;

      filters.serviceId =
        (data?.serviceId ?? []).length === 1 ? data?.serviceId[0] : '';
      filters.pointOfSaleId = data?.selectedPointOfSale.id;
      filters.shopSignId = data?.selectedPointOfSale.shopSignId;

      break;
    case 'user':
      data = store.getState().bluBookingUserCalendar;

      filters.serviceId =
        (data?.serviceId ?? []).length === 1 ? data?.serviceId[0] : '';
      filters.pointOfSaleId = data?.selectedPointOfSale.id;
      filters.shopSignId = data?.selectedPointOfSale.shopSignId;

      break;
    default:
      data = store.getState().bluBookingDefaultCalendar;

      filters.serviceId =
        (data?.serviceId ?? []).length === 1 ? data?.serviceId[0] : '';
      filters.pointOfSaleId = data?.selectedPointOfSale.id;
      filters.studioId =
        (data?.selectedStudi ?? []).length === 1 ? data?.selectedStudi[0] : '';
      filters.userId =
        (data?.selectedUsers ?? []).length === 1
          ? data?.selectedUsers[0].id
          : '';
      filters.shopSignId = data?.selectedPointOfSale.shopSignId;

      break;
  }

  if (filters.shopSignId === '') {
    if (Object.keys(shopSigns).length > 0)
      filters.shopSignId = Object.keys(shopSigns)[0];
  }
  return {
    id: '',
    companyId: '',
    shortLink: '',
    token: '',
    urlLink: '',
    name: '',
    surname: '',
    email: '',
    telephone: '',
    externalCustomerCode: '',
    ...filters,
  };
};
