import axios from 'axios';

export const deleteShopSignLink = async (id: string): Promise<boolean> => {
  // dispatch(setLoadingAppointments(true));
  // const [startDate, endDate] = getRange(selectedDate!, view);
  try {
    await axios.delete(`/api/ShopSignLink/${id}`);

    return true;
  } catch (error) {
    return false;
  }
};
