import { BluTitle } from '@bludata/components';
import {
  Box,
  Card,
  Drawer,
  IconButton,
  List,
  ListItem,
  Stack,
  Typography,
} from '@mui/material';
import { FC, memo, useDeferredValue } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useAppointmentsNotification } from '../../api/AppointmentNotification';

interface NotificationDrawerInterface {
  open: boolean;
  setOpen: (value: boolean) => void;
}

export const NotificationDrawer: FC<NotificationDrawerInterface> = memo(
  ({ open, setOpen }) => {
    const { data } = useAppointmentsNotification();
    const notifications = useDeferredValue(data);

    return (
      <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{ width: 400 }}
          role="presentation"
          // onClick={toggleDrawer(anchor, false)}
          // onKeyDown={toggleDrawer(anchor, false)}
        >
          <Stack flex="1" flexDirection="row" sx={{ pl: 2, pr: 2 }}>
            <BluTitle
              sx={{ flex: 1, fontSize: '22px !important', pt: 2 }}
              text="Notifiche"
            />

            <IconButton sx={{ mt: 1 }} onClick={() => setOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Stack>

          <List>
            {notifications?.notOkSlotCount > 0 && (
              <ListItem>
                <Card
                  elevation={0}
                  sx={{
                    border: '1px solid #a0c7e5',
                    width: '100%',
                    p: 2,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setOpen(false);

                    window.dispatchEvent(
                      new CustomEvent('openAppointmentsSlotNotOkPopup')
                    );
                  }}
                >
                  <Typography>
                    Hai{' '}
                    {notifications?.notOkSlotCount > 1 ? (
                      <>
                        <strong>
                          {` ${notifications?.notOkSlotCount} appuntamenti`}{' '}
                        </strong>
                        {' non validi.'}
                      </>
                    ) : (
                      <>
                        <strong>{` ${notifications?.notOkSlotCount} appuntamento`}</strong>
                        {' non valido.'}
                      </>
                    )}
                  </Typography>
                </Card>
              </ListItem>
            )}
            {notifications?.notConfirmedCount > 0 && (
              <ListItem>
                <Card
                  elevation={0}
                  sx={{
                    border: '1px solid #a0c7e5',
                    width: '100%',
                    p: 2,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setOpen(false);

                    window.dispatchEvent(
                      new CustomEvent('openAppointmentNotConfirmPopup')
                    );
                  }}
                >
                  <Typography>
                    Hai
                    <strong>
                      {notifications?.notConfirmedCount > 1
                        ? ` ${notifications?.notConfirmedCount} appuntamenti `
                        : ` ${notifications?.notConfirmedCount} appuntamento `}
                    </strong>
                    da confermare.
                  </Typography>
                </Card>
              </ListItem>
            )}

            {notifications?.errorCodeCount > 0 && (
              <ListItem>
                <Card
                  elevation={0}
                  sx={{
                    border: '1px solid #a0c7e5',
                    width: '100%',
                    p: 2,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setOpen(false);

                    window.dispatchEvent(
                      new CustomEvent('openAppointmentWithErrorsPopup')
                    );
                  }}
                >
                  <Typography>
                    Hai
                    <strong>
                      {notifications?.errorCodeCount > 1
                        ? ` ${notifications?.errorCodeCount} appuntamenti `
                        : ` ${notifications?.errorCodeCount} appuntamento `}
                    </strong>
                    con errori.
                  </Typography>
                </Card>
              </ListItem>
            )}
          </List>
        </Box>
      </Drawer>
    );
  }
);
