/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import axios from 'axios';
import { StudiObject, StudioInterface } from '../../interfaces/Studio';

const getStudiCalendar = async (): Promise<StudioInterface[]> => {
  try {
    const {
      data: { data },
    } = await axios.get('/api/Studio/UICalendar');

    return data;
  } catch (error) {
    console.log(error);
    return [];
  }
};
export const useStudiCalendar = ({
  options = {},
  key = '',
}: {
  options?: UseQueryOptions;
  key?: string;
}) => {
  return useQuery({
    queryFn: async () => await getStudiCalendar(),
    queryKey: ['studi', 'calendar', key],
    retry: 2,
    retryDelay: 180000,
    cacheTime: 300000,
    refetchInterval: 300000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    select: normalize,
    ...options,
  });
};

const normalize = (data: any): StudiObject => {
  const result: any = {};
  for (const studio of data) {
    result[studio.id] = {
      ...studio,
      studioId: studio.id,
    };
  }
  return result;
};
