/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import axios from 'axios';
import { getAppointmentDateRange } from '../../pages/Calendars/utility_objects/AppointmentDateRange';

export const getUserHoursShift = async (
  userId: string,
  selectedDate: Date,
  view: string
): Promise<any> => {
  try {
    const [startDate, endDate] = getAppointmentDateRange(selectedDate, view);
    const {
      data: { data },
    } = await axios.post(
      `/api/CalendarUser${userId !== undefined ? `/${userId}` : ''}`,
      {
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD'),
      }
    );
    return data;
  } catch (err) {
    console.trace(err);
    return [];
  }
};

export const useUserHoursShift = ({
  userId,
  selectedDate,
  view = 'Day',
  options = {},
  key = '',
}: {
  userId: string;
  selectedDate: Date;
  view?: string;
  options?: UseQueryOptions;
  key?: string; // Add this optional parameter to your useQuery hook.
}) => {
  return useQuery({
    queryFn: async () => await getUserHoursShift(userId, selectedDate, view),
    queryKey: ['user-hours', 'shift', userId, selectedDate, view, key],
    retry: 2,
    retryDelay: 180000,
    cacheTime: 0,
    staleTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    select: normalize,
    ...options,
  });
};

const normalize = (result: any) => {
  const data: any = {
    multi: false,
    keys: [],
    values: {},
  };
  if (result === undefined) return data;

  data.multi = result.length > 1;
  data.keys = [];
  for (const pos of result) {
    data.keys.push(pos.pointOfSaleId);
    for (const list of pos.calendarList)
      data.values[pos.pointOfSaleId] = {
        ...data.values[pos.pointOfSaleId],
        [list.date]: list.hours,
      };
  }
  return data;
};
