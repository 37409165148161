import { useSelector } from 'react-redux';
import { RootState, store } from '../../store';
import SessionExpired from '../../feature/session-expired';
import Login from '../../feature/login';
import { Suspense, memo, useMemo } from 'react';
import { Home } from '../../feature/home';
import { BluLoader } from '@bludata/components';
import { history } from '../history';
import { Focus10BluBooking } from '../../feature/focus10';
import { setFocus10SelectedView } from '../../store/root';
import { ConfigurazioneFocus10BluBooking } from '../../feature/configurazioneFocus10';
import { GoogleCalendarSync } from '../../feature/googleCalendar/googleCalendarSync';
import { GoogleCalendarRedirect } from '../../feature/googleCalendar/googleCalendarRedirect';
import { setF10SelectedPage } from '../../store/configuration-focus10';
import Privacy from '../../feature/privacy/Privacy';
import Conditions from '../../feature/conditions/Conditions';
import { GoogleCalendar } from '../../feature/googleCalendar';
import { BluBookingTerms } from '../../feature/bluBookingTerms';

export const CheckSessionExists = memo((): JSX.Element => {
  const { accessToken, sessionExpired } = useSelector(
    (state: RootState) => state.root
  );

  const Content = useMemo(() => {
    if (sessionExpired) return <SessionExpired />;
    if (accessToken === '') return <Login />;
    if (window.location.search !== '') history.replace({ pathname: '/' });

    return (
      <Suspense fallback={<BluLoader open={true} />}>
        <Home />
      </Suspense>
    );
  }, [accessToken, sessionExpired]);

  return <>{Content}</>;
});

export const CheckFocus10SessionExists = memo((): JSX.Element => {
  const { token, url } = JSON.parse(
    sessionStorage.getItem('sessionData') ?? '{}'
  );

  const Content = useMemo(() => {
    if (import.meta.env.DEV) {
      store.dispatch(setFocus10SelectedView('Calendario appuntamenti'));
      // store.dispatch(setFocus10SelectedView('Rota'));
      //store.dispatch(setFocus10SelectedView("Users"));

      return <Focus10BluBooking />;
    }

    if (token && url) {
      store.dispatch(setFocus10SelectedView('Calendario appuntamenti'));
      return <Focus10BluBooking />;
    }
    if (!token) {
      store.dispatch(setFocus10SelectedView(null));
      return <Focus10BluBooking />;
    }

    history.replace('/');
    return <CheckSessionExists />;
  }, [token, url]);

  return <>{Content}</>;
});

export const CheckConfigurazioneFocus10SessionExists = memo((): JSX.Element => {
  const { token, url } = JSON.parse(
    sessionStorage.getItem('sessionData') ?? '{}'
  );

  const Content = useMemo(() => {
    if (import.meta.env.DEV) {
      store.dispatch(setF10SelectedPage('Users'));
      // store.dispatch(setFocus10SelectedView("Rota"));
      //store.dispatch(setFocus10SelectedView("Users"));

      return <ConfigurazioneFocus10BluBooking />;
    }

    if (token && url) {
      store.dispatch(setF10SelectedPage('generali'));
      return <ConfigurazioneFocus10BluBooking />;
    }
    if (!token) {
      store.dispatch(setF10SelectedPage(''));
      return <ConfigurazioneFocus10BluBooking />;
    }

    history.replace('/');
    return <CheckSessionExists />;
  }, [token, url]);

  return <>{Content}</>;
});

export const AppRoutes = memo(() => {
  console.log('ENTER', window.location.pathname);
  if (window.location.pathname === '/google-calendar-sync')
    return <GoogleCalendarSync />;
  if (window.location.pathname === '/google-calendar-redirect')
    return <GoogleCalendarRedirect />;
  if (window.location.pathname === '/focus10/index.html')
    return <CheckFocus10SessionExists />;
  if (window.location.pathname === '/focus10')
    return <CheckFocus10SessionExists />;
  if (window.location.pathname === '/configurazioneFocus10/index.html')
    return <CheckConfigurazioneFocus10SessionExists />;
  if (window.location.pathname === '/configurazioneFocus10')
    return <CheckConfigurazioneFocus10SessionExists />;
  if (window.location.pathname === '/privacy-policy') return <Privacy />;
  if (window.location.pathname === '/terms-and-conditions')
    return <Conditions />;
  if (window.location.pathname === '/blu-booking') return <BluBookingTerms />;

  if (import.meta.env.DEV && window.location.pathname === '/google-sync')
    return <GoogleCalendar />;

  history.replace({ pathname: '/', search: window.location.search });
  return <CheckSessionExists />;
});
