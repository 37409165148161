/* eslint-disable @typescript-eslint/no-explicit-any */
import { memo } from 'react';
import {
  DataGridPremium,
  GridRenderCellParams,
} from '@mui/x-data-grid-premium';
import moment from 'moment';
import { useSearch } from 'packages/blu-booking/src/data_fetching/Search';

interface TableResultProps {
  value: string;
  focus10: boolean;
  action?: (value: any) => void;
  unregistered: boolean;
}

export const TableResult = memo(
  ({
    value,
    focus10,
    action = (_) => null,
    unregistered,
  }: TableResultProps) => {
    const { data, isLoading } = useSearch(
      value,
      unregistered,
      focus10 && !unregistered ? '100' : '5'
    );
    const today = moment();
    //"1995-11-21T00:00:00"

    return (
      <DataGridPremium
        columns={[
          {
            field: 'surname',
            headerName: 'Cognome',
            flex: 1,
            editable: false,
            sortable: false,
            disableColumnMenu: true,
          },
          {
            field: 'name',
            headerName: 'Nome',
            flex: 1,
            editable: false,
            sortable: false,
            disableColumnMenu: true,
          },
          {
            field: 'cognome2',
            headerName: 'Cognome 2',
            width: 130,
            editable: false,
            hide: !focus10,
            sortable: false,
            disableColumnMenu: true,
          },
          {
            field: 'dataNascita',
            align: 'center',
            headerAlign: 'center',
            headerName: 'Età',
            width: 60,
            editable: false,
            hide: !focus10,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: GridRenderCellParams) => {
              const dataNString = params.value;
              if (dataNString) {
                const dataN = moment(dataNString);
                const eta = today.diff(dataN, 'years');
                return eta;
              }

              return '';
            },
          },
          {
            field: 'indirizzo',
            headerName: 'Indirizzo',
            flex: 1,
            editable: false,
            hide: !focus10,
            sortable: false,
            disableColumnMenu: true,
          },
          {
            field: 'citta',
            headerName: 'Città',
            flex: 1,
            editable: false,
            hide: !focus10,
            sortable: false,
            disableColumnMenu: true,
          },
          {
            field: 'email',
            headerName: 'Email',
            flex: 1,
            editable: false,
            hide: focus10,
            sortable: false,
            disableColumnMenu: true,
          },
          {
            field: 'telephone',
            headerName: 'Telefono',
            flex: 1,
            editable: false,
            hide: focus10,
            sortable: false,
            disableColumnMenu: true,
          },
        ]}
        rows={data ? data : []}
        sx={{
          height: '290.5px',
          '& .MuiDataGrid-row': {
            cursor: 'pointer',
            minHeight: '50px !important',
          },
        }}
        getRowId={(row: any) =>
          `${row?.id}${row?.name}_${row?.surname}_${row?.email}_${row?.telephone}`
        }
        loading={isLoading && data === undefined}
        density="compact"
        disableColumnMenu
        hideFooter
        editMode="row"
        getRowHeight={() => 'auto'}
        onProcessRowUpdateError={(error) => console.log(error)}
        experimentalFeatures={{ newEditingApi: true }}
        onRowClick={(event: any) => {
          action(event?.row);
        }}
      />
    );
  }
);
