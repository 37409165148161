/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  Checkbox,
  FormControlLabel,
  Slide,
  Stack,
  Typography,
} from '@mui/material';
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import { BluDialog, BluTextField } from '@bludata/components';
import { useDispatch, useSelector } from 'react-redux';
import { SelectAppointment } from './SelectAppointment';
import { Else, If, Then } from 'react-if';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { AppDispatch, RootState } from 'packages/blu-booking/src/store';
import { getConfig, setConfig } from '../../utility_objects/WhiteCache';
import { setClientSelected, setSearchPopUp } from '../../store/Calendar';
import { DisplayResultsFocusWeb } from './DisplayResultsFocusWeb';
import { DisplayResults } from './DisplayResults';

interface AppointmentSearchInterface {
  action: (value: any) => void;
}

export const AppointmentSearch: FC<AppointmentSearchInterface> = memo(
  ({ action }) => {
    const [cliente, setCliente] = useState<string>('');
    const [unregistered, setUnregistered] = useState<boolean>(false);
    const { searchPopup, clientSelected } = useSelector(
      (state: RootState) => state.bluBookingCalendars
    );
    const dispatch = useDispatch<AppDispatch>();
    const [row, setRow] = useState<any | undefined>(undefined);
    const [appointment, setAppointment] = useState<any | undefined>(undefined);
    const { focus10, focusWeb } = getConfig();

    const [keyDown, setKeyDown] = useState<string | undefined>();

    const GetResults = useMemo(() => {
      if (focusWeb && !unregistered)
        return (
          <DisplayResultsFocusWeb
            value={cliente}
            keyDown={keyDown}
            setKeyDown={setKeyDown}
            action={(client: any) => {
              setRow({
                ...client,
                surname: client.cognome,
                name: client.nome,
                telephone: client.telefoninoGsm || client.telefonino,
                email: client.eMail,
                externalCustomerCode: client?.codicePersonale ?? null,
                isImo: client?.appAttiva,
              });

              // dispatch(setSearchPopUp(false));
            }}
            enterAction={(row: any) => {
              setRow({
                ...row,
                surname: row.cognome,
                name: row.nome,
                telephone: row.telefoninoGsm || row.telefonino,
                email: row.eMail,
                externalCustomerCode: row?.codicePersonale ?? null,
                isImo: row?.appAttiva,
              });
              console.log('ENTER ACTION', row);
              dispatch(
                setClientSelected({
                  ...row,
                  surname: row.cognome,
                  name: row.nome,
                  telephone: row.telefoninoGsm || row.telefonino,
                  email: row.eMail,
                  externalCustomerCode: row?.codicePersonale ?? null,
                  isImo: row?.appAttiva,
                })
              );
            }}
          />
        );
      return (
        <DisplayResults
          value={cliente}
          keyDown={keyDown}
          setKeyDown={setKeyDown}
          action={(client: any) => {
            setRow(client);

            // dispatch(setSearchPopUp(false));
          }}
          enterAction={(row: any) => {
            setRow(row);
            dispatch(setClientSelected(row));
          }}
          unregistered={unregistered}
        />
      );
    }, [cliente, dispatch, focusWeb, keyDown, unregistered]);

    const handleCercaClick = useCallback(
      (app?: any) => {
        let clickedAppointment = app;
        if (!app) clickedAppointment = appointment;
        if (clickedAppointment) {
          setConfig('underline', clickedAppointment?.id ?? '');

          dispatch(setSearchPopUp(false));

          action(clickedAppointment);

          // setKeyDown(undefined);
          dispatch(setClientSelected(undefined));
          setAppointment(undefined);
          setRow(undefined);
        }
      },
      [action, appointment, dispatch]
    );

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
      event.stopPropagation();
      if (event.key === 'Escape') {
        if (clientSelected) {
          dispatch(setClientSelected(undefined));
        } else {
          dispatch(setSearchPopUp(false));
          dispatch(setClientSelected(undefined));
        }
      }
      if (
        event.key !== 'Enter' &&
        event.key !== 'ArrowUp' &&
        event.key !== 'ArrowDown'
      )
        return;
      setKeyDown(event.key);
    };

    return (
      <BluDialog
        open={searchPopup}
        fullScreen={false}
        maxWidth={'lg'}
        autoFocus
        onKeyDown={handleKeyDown}
        zIndex={400}
        draggable
        dialogTitle="Ricerca cliente"
        dialogActions={
          <Stack direction="row" flex="1" sx={{ width: '100%' }} gap={1}>
            <Stack flex="1" direction="row" columnGap="10px">
              <If condition={clientSelected !== undefined}>
                <Then>
                  <Button
                    variant="outlined"
                    color="primary"
                    sx={{ width: 'fit-content', pl: '5px' }}
                    onClick={() => dispatch(setClientSelected(undefined))}
                  >
                    <KeyboardArrowLeftIcon />
                  </Button>
                </Then>
              </If>
            </Stack>
            <Button
              variant="outlined"
              color="primary"
              sx={{ width: 'fit-content' }}
              onClick={() => {
                dispatch(setSearchPopUp(false));
                dispatch(setClientSelected(undefined));
              }}
            >
              Chiudi
            </Button>
            <If condition={clientSelected === undefined}>
              <Then>
                <Button
                  color="primary"
                  sx={{ width: 'fit-content' }}
                  onClick={() => dispatch(setClientSelected(row))}
                  endIcon={<KeyboardArrowRightIcon />}
                  disabled={row === undefined}
                >
                  {'Avanti'}
                </Button>
              </Then>
              <Else>
                <Button
                  color="primary"
                  sx={{ width: 'fit-content' }}
                  onClick={() => handleCercaClick()}
                  disabled={appointment === undefined}
                >
                  Visualizza nell'agenda
                </Button>
              </Else>
            </If>
          </Stack>
        }
        dialogContent={
          <div style={{ minWidth: focus10 ? '800px' : 'unset' }}>
            <If condition={clientSelected === undefined}>
              <Then>
                <Slide direction="right" in>
                  <div>
                    <Typography variant="subtitle2" sx={{ p: '3px 0px' }}>
                      Ricerca cliente
                    </Typography>
                    <Stack direction="row" gap={2}>
                      <Search setValue={(v: string) => setCliente(v)} />
                      {(focus10 || focusWeb) && (
                        <FormControlLabel
                          label="Non ancora clienti"
                          control={
                            <Checkbox
                              checked={unregistered}
                              onChange={() => setUnregistered(!unregistered)}
                              sx={{ p: 0 }}
                            />
                          }
                        />
                      )}
                    </Stack>
                    <div style={{ height: '20px' }} />

                    {GetResults}
                  </div>
                </Slide>
              </Then>
              <Else>
                <Slide direction="left" in>
                  <div>
                    <SelectAppointment
                      action={(r: any) => setAppointment(r)}
                      unregistered={unregistered}
                      keyDown={keyDown}
                      setKeyDown={setKeyDown}
                      enterAction={(r: any) => {
                        handleCercaClick(r);
                      }}
                    />
                  </div>
                </Slide>
              </Else>
            </If>
          </div>
        }
      />
    );
  }
);

interface SearchProps {
  setValue: (v: string) => void;
}

const Search = memo(({ setValue }: SearchProps) => {
  const [value, set] = useState<string>('');

  useEffect(() => {
    const delayFn = setTimeout(() => {
      setValue(value);
    }, 250);

    return () => {
      clearTimeout(delayFn);
    };
  }, [setValue, value]);

  return (
    <BluTextField
      autoFocus
      value={value}
      name="Ricerca"
      autoComplete="off" // Disabilita il completamento automatico
      onChange={(event: any) => set(event.target.value)}
      InputProps={{
        startAdornment: <SearchTwoToneIcon />,
      }}
    />
  );
});
