import { CompanySettingsInterface } from 'packages/blu-booking/src/interfaces/CompanySettings';
import { HolidayInterface } from 'packages/blu-booking/src/interfaces/Holiday';
import { defaultCompanySettings } from 'packages/blu-booking/src/utility_objects/DefaultCompanySettings';
import { defaultHolidays } from 'packages/blu-booking/src/utility_objects/DefaultHolidays';

let initialHolidays: HolidayInterface[] = defaultHolidays;

let initialCompanySettings: CompanySettingsInterface = defaultCompanySettings;

export const getInitialHolidays = () => initialHolidays;

export const setInitialHolidays = (value: HolidayInterface[]) => {
  initialHolidays = value;
};

export const getInitialCompanySettings = () => initialCompanySettings;

export const setInitialCompanySettings = (value: CompanySettingsInterface) => {
  initialCompanySettings = value;
};
