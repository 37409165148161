import React from 'react';
import { useDrop } from 'react-dnd';
import { CardData, ColumnData } from './interfaces';
import NotificationCard from './Card';
import { Box, Stack, Typography } from '@mui/material';

interface ColumnProps {
  column: ColumnData;
  onDrop: (card: CardData, targetColumnId: number) => void;
}

const Column: React.FC<ColumnProps> = ({ column, onDrop }) => {
  const [{ isOver }, drop] = useDrop({
    accept: 'CARD',
    drop: (item: CardData) => {
      onDrop(item, column.id);
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  return (
    <Stack flex="1" gap={1}>
      <Box
        className="kanban-notification-column"
        style={{
          padding: '8px',
          borderRadius: '4px',
        }}
      >
        <Typography variant="subtitle2" textAlign="center">
          {column.name}
        </Typography>
      </Box>
      <Stack
        className="kanban-notification-column"
        flex={1}
        gap={1}
        ref={drop}
        style={{
          backgroundColor: isOver ? 'var(--closed-color)' : undefined,
          padding: '10px',
          minHeight: '165px',
          borderRadius: '4px',
        }}
      >
        {column.cards.map((card) => (
          <NotificationCard key={card.id} card={card} />
        ))}
      </Stack>
    </Stack>
  );
};

export default Column;
