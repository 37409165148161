/* eslint-disable @typescript-eslint/no-explicit-any */
import './Calendar.css';

import { FC, memo } from 'react';
import { Provider } from 'react-redux';
import { store } from 'packages/blu-booking/src/store';
import { CalendarsInterface } from './interfaces/Calendars';
import { DefaultCalendar as DC } from './pages/Default/DefaultCalendar';
import { PersonalCalendar as PC } from './pages/Personal/PersonalCalendar';
import { StudioCalendar as SC } from './pages/Studio/StudioCalendar';
import { UserCalendar as UC } from './pages/User/UserCalendar';
import { TabClientCalendar as TCC } from './pages/TabClient/TabClientCalendar';
import { InitialConfiguration } from './UI/InitialConfiguration/InitialConfiguration';

export const DefaultCalendar: FC<CalendarsInterface> = memo(
  function DefaultCalendar(props) {
    return (
      <Provider store={store}>
        <InitialConfiguration {...props} calendarType="default">
          <DC />
        </InitialConfiguration>
      </Provider>
    );
  }
);

export const PersonalCalendar: FC<CalendarsInterface> = memo(
  function PersonalCalendar(props) {
    return (
      <Provider store={store}>
        <InitialConfiguration {...props} calendarType="personal">
          <PC />
        </InitialConfiguration>
      </Provider>
    );
  }
);

export const StudioCalendar: FC<CalendarsInterface> = memo(
  function StudioCalendar(props) {
    return (
      <Provider store={store}>
        <InitialConfiguration {...props} calendarType="studio">
          <SC />
        </InitialConfiguration>
      </Provider>
    );
  }
);

export const UserCalendar: FC<CalendarsInterface> = memo(function UserCalendar(
  props
) {
  return (
    <Provider store={store}>
      <InitialConfiguration {...props} calendarType="studio">
        <UC />
      </InitialConfiguration>
    </Provider>
  );
});

interface TabClientCalendarInterface extends CalendarsInterface {
  client: any;
}

export const TabClientCalendar: FC<TabClientCalendarInterface> = memo(
  function UserCalendar(props) {
    return (
      <Provider store={store}>
        <TCC {...props} client={props.client} focusCloud={props.focusCloud} />
      </Provider>
    );
  }
);
