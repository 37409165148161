import {
  Autocomplete,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
} from '@mui/material';
import { FC, memo, useCallback, useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { GridCloseIcon } from '@mui/x-data-grid-premium';
import { nanoid } from 'nanoid';
import { AppDispatch } from '../../../../../../store';
import { useDispatch } from 'react-redux';
import { setIsLoadingForm } from '../../../../store/root';
import useTableLoader from '@bludata/hook-usetableloader';
import axios from 'axios';

interface CittaInterface {
  disabled: boolean;
}

export const Citta: FC<CittaInterface> = memo(({ disabled }) => {
  const city = useWatch({ name: 'city' });
  // const { data, isLoading } = useCitta();
  const { setValue } = useFormContext();
  const [val, setVal] = useState<string>(city ?? '');

  const {
    data,
    fetchTableData: fetchCitta,
    loading: isLoading,
  } = useTableLoader<any>({
    tablename: 'tcomuni',
    axios: axios,
    pagination: {
      page: 0,
      pageSize: 100,
    },
    filters: [{ operator: 'fulltext', property: 'citta', value: val }],
  });

  // const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    fetchCitta();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [val]);

  // useEffect(() => {
  //   dispatch(setIsLoadingForm({ key: "citta", value: isLoading }));
  // }, [dispatch, isLoading]);

  const getOptions = useCallback(() => {
    if (data) return data;
    return [];
  }, [data]);

  const handleOnCange = useCallback(
    (_: any, newValue: any) => {
      const newCity = (data ?? []).find((item) => {
        return `${item.citta}` === newValue?.citta;
      });
      setValue('city', newCity ? `${newCity.citta}` : newValue?.citta);
      setValue('region', newCity ? newCity.provincia : '');
      setValue('postalCode', newCity ? newCity.cap : '');

      // setValue("city", event.target.value);
      // trigger("city");
    },
    [data, setValue]
  );

  const handleErase = useCallback(() => {
    setValue('city', '');
    setVal('');
  }, [setValue]);

  return (
    <Stack
      sx={{
        flex: 1,
      }}
    >
      <Autocomplete
        value={city}
        onChange={handleOnCange}
        options={getOptions()}
        getOptionLabel={(data) => {
          if (typeof data === 'string') return data;
          return data.citta;
        }}
        freeSolo
        disableClearable
        sx={{ flex: 1 }}
        renderOption={(props, option) => {
          return (
            <li {...props} key={nanoid()}>
              {option.citta}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Città"
            disabled={disabled}
            onChange={(event) => setVal(event.target.value)}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                  {city && city !== '' && !disabled && (
                    <InputAdornment position="end">
                      <IconButton
                        color="primary"
                        size="small"
                        onClick={handleErase}
                      >
                        <GridCloseIcon />
                      </IconButton>
                    </InputAdornment>
                  )}
                </>
              ),
            }}
          />
        )}
      />
    </Stack>
  );
});
