import axios from 'axios';
import { defaultCompanySettings } from '../../utility_objects/DefaultCompanySettings';
import { useQuery } from '@tanstack/react-query';

export const companySettings = async (): Promise<{
  companyColorEnum: number;
  visibilityEnum: number;
  isManualAppointmentBlock: boolean;
  isSingleCalendarManagement: boolean;
}> => {
  try {
    const {
      data: { data },
    } = await axios.get(`api/Company/CompanySettings`);
    return data;
  } catch (error) {
    console.log(error);
    return defaultCompanySettings;
  }
};

export const useCompanySettings = (key = '') => {
  return useQuery({
    queryFn: async () => await companySettings(),
    queryKey: ['company-settings', key],
    retry: 2,
    retryDelay: 180000,
    cacheTime: 0,
    staleTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
};
