import { BluTextField } from '@bludata/components';
import { Stack, Tooltip, Typography } from '@mui/material';
import React, { FC, useMemo } from 'react';
import { memo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';

import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import { RootState } from 'packages/blu-booking/src/store';
import { errorsMail } from 'packages/blu-booking/src/utility_objects/ErrorMail';

interface EmailInterface {
  isTabClienti: boolean;
}

export const Email: FC<EmailInterface> = memo(({ isTabClienti }) => {
  const email = useWatch({ name: 'email' });
  const { setValue, trigger } = useFormContext();
  const { openForm } = useSelector(
    (state: RootState) => state.bluBookingAppointmentForm
  );

  const GetErrorMail = useMemo(() => {
    if (
      openForm?.emailErrorCode !== undefined &&
      openForm?.emailErrorCode !== null
    ) {
      return (
        <Tooltip
          title={`${
            openForm?.emailErrorCode in errorsMail
              ? errorsMail[openForm?.emailErrorCode]
              : 'errore mail'
          }`}
        >
          <ErrorTwoToneIcon color="error" />
        </Tooltip>
      );
    }
    return null;
  }, [openForm?.emailErrorCode]);

  return (
    <Stack flex="1">
      <Typography variant="subtitle2" sx={{ pt: '3px' }}>
        Email
      </Typography>
      <BluTextField
        value={email}
        name="Email"
        autoComplete="off" // Disabilita il completamento automatico
        onChange={(
          event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
        ) => {
          setValue('email', event.target.value);
          trigger();
        }}
        InputProps={{
          // eslint-disable-next-line react/jsx-no-useless-fragment
          endAdornment: <>{GetErrorMail}</>,
        }}
      />
    </Stack>
  );
});
