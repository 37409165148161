/* eslint-disable @typescript-eslint/no-explicit-any */

import { setOpenLoader } from 'packages/blu-booking/src/store/root';
import { getConfig } from './WhiteCache';
import { getAppointmentDetails } from 'packages/blu-booking/src/data_fetching/Appointment/AppointmentDetails';
import { store } from 'packages/blu-booking/src/store';
import { setRefetch } from '../store/Appointment';
import { setOpenForm } from '../store/AppointmentForm';
import { setRightClick } from '../store/Calendar';

export const appointmentFormPopupOpen = (
  args: any,
  selectedUsers: any[],
  selectedStudi: string[],
  selectedService: string[],
  pointOfSaleId?: string
) => {
  if (args.data.isReadOnly) {
    args.cancel = true;
    return;
  }

  if (
    (args.type === 'QuickInfo' || args.type === 'ViewEventInfo') &&
    !args.data.Id
  ) {
    args.cancel = true;
  }
  if (args.type === 'EditEventInfo') {
    const element = document.querySelector(
      `[data-id="Appointment_${args.data.Id}"]`
    );
    args.cancel = true;
    const rect = element.getBoundingClientRect();
    const viewportWidth = window.innerWidth; // Larghezza del viewport

    // Calcola se c'è spazio sufficiente a destra
    const spaceRight = viewportWidth - rect.right;

    store.dispatch(
      setRightClick({
        ...args?.data,
        x: spaceRight > 200 ? rect?.right - 15 : rect.left - 100,
        y: rect?.top - 10,
      })
    );
    args.cancel = true;
  }
  if (args.type === 'Editor' || args.type === 'ViewEventInfo') {
    const { sessionProfilo } = getConfig();

    if (args.data?.Id) {
      if (!sessionProfilo.agendaModifica) {
        args.cancel = true;
        return;
      }
      store.dispatch(setOpenLoader(true));
      getAppointmentDetails(args.data?.Id)
        .then((data) => {
          store.dispatch(
            setOpenForm({ ...data, userUsername: args.data?.username })
          );
        })
        .catch(() => {
          store.dispatch(setRefetch());
        })
        .finally(() => {
          store.dispatch(setOpenLoader(false));
        });
    } else {
      if (!sessionProfilo.agendaInserimento) {
        args.cancel = true;
        return;
      }

      // ? nuovo appuntamento
      store.dispatch(
        setOpenForm({
          ...args.data,
          userId: selectedUsers.length === 1 ? selectedUsers[0].id : undefined,
          serviceId:
            selectedService.length === 1 ? selectedService[0] : undefined,
          studioId: selectedStudi.length === 1 ? selectedStudi[0] : undefined,
          pointOfSaleId: pointOfSaleId,
        })
      );
    }
    args.cancel = true;
  }
};
