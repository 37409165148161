/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Day,
  Week,
  Month,
  Agenda,
  ScheduleComponent,
  Inject,
  Resize,
  DragAndDrop,
  ViewDirective,
  ViewsDirective,
  RenderCellEventArgs,
  View,
} from '@syncfusion/ej2-react-schedule';
import { useDispatch, useSelector } from 'react-redux';
import { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import dayjs from 'dayjs';
import { Box, Typography } from '@mui/material';
import { isSafari } from 'react-device-detect';
import { RootState } from 'packages/blu-booking/src/store';
import { getConfig } from '../../../utility_objects/WhiteCache';
import { DefaultCalendarSetSelectedDate } from '../store';
import { setPrintComponent } from '../../../store/Appointment';
import { CalendarEventTemplate } from '../../../UI/CalendarEventTemplate/CalendarEventTemplate';
import { CalendarEventQuickInfo } from '../../../UI/CalendarEventQuickInfo/CalendarEventQuickInfo';
import { appointmentFormPopupOpen } from '../../../utility_objects/AppointmentFormPopupOpen';
import { AppointmentDragDrop } from '../../../utility_objects/AppointmentDragDrop';
import { AppointmentResize } from '../../../utility_objects/AppointmentResize';
import { AppointmentRender } from '../../../utility_objects/AppointmentRender';
import { isOpen } from '../utility_objects/work-hours';
import {
  AppointmentMouseDown,
  AppointmentMouseUp,
} from '../../../utility_objects/AppointmentMouseUp';

interface SchedulerProps {
  calendarPointOfSale: any;
  shiftPointOfSale: any;
}

export const Scheduler = memo(function Scheduler({
  calendarPointOfSale,
  shiftPointOfSale,
}: SchedulerProps): JSX.Element {
  const componentRef = useRef();
  const dispatch = useDispatch();
  const { appointments } = useSelector(
    (state: RootState) => state.bluBookingaAppointments
  );
  const {
    selectedDate,
    view,
    currentView,
    selectedPointOfSale,
    selectedUsers,
    selectedStudi,
    selectedServices,
    zoom,
  } = useSelector((state: RootState) => state.bluBookingDefaultCalendar);

  const { sessionProfilo, focus10 } = getConfig();
  const scheduleObj = useRef<ScheduleComponent>(null);

  useEffect(() => {
    const { underline } = getConfig();

    if (
      scheduleObj?.current &&
      dayjs().isSame(dayjs(selectedDate), 'day') &&
      underline === '' &&
      !focus10
    ) {
      try {
        scheduleObj?.current.scrollTo(
          dayjs().subtract(1, 'hour').minute(0).format('HH:mm')
        );
      } catch (error) {
        /* empty */
      }
    }
  }, [focus10, selectedDate, view, calendarPointOfSale, shiftPointOfSale]);

  const onActionComplete = (args) => {
    if (args.requestType === 'dateNavigate') {
      const schedulerInstance = scheduleObj.current;
      const newDate = schedulerInstance.selectedDate; // Ottieni la nuova data dal componente

      dispatch(DefaultCalendarSetSelectedDate(newDate));
    }
  };

  useEffect(() => {
    if (view !== 'WorkWeek') return;

    const daysOfWeek =
      dayjs(selectedDate).day() !== 0 ? dayjs(selectedDate).day() - 1 : 6;

    const table = document.getElementById('default-schedule');
    if (table === null) return;

    const colgroup = document.getElementsByTagName('colgroup');
    if (colgroup !== null && colgroup.length > 0)
      for (let i = 0; i < colgroup.length; i++) {
        const children = colgroup[i].children;

        if (children && children.length === 7) {
          // ? Toglie i giorni di chiusura dal calendario
          if (calendarPointOfSale && !isSafari) {
            let startRange = dayjs(selectedDate)
              .startOf('date')
              .locale('it')
              .startOf('week');

            let i = 0;
            while (i < 7) {
              if (!(startRange.format('YYYY-MM-DD') in calendarPointOfSale)) {
                const index = startRange.day() !== 0 ? startRange.day() - 1 : 6;
                (children[index] as any).style.width = `0px`;
                (children[index] as any).style.overflow = `hidden`;
              } else if (
                !calendarPointOfSale[startRange.format('YYYY-MM-DD')]
                  ?.startTime1 &&
                !calendarPointOfSale[startRange.format('YYYY-MM-DD')]
                  ?.startTime2
              ) {
                const index = startRange.day() !== 0 ? startRange.day() - 1 : 6;
                (children[index] as any).style.width = `0px`;
                (children[index] as any).style.overflow = `hidden`;
              }
              startRange = startRange.add(1, 'days');
              i++;
            }
          }

          // ? setta larghezza più grande per il giorno selezionato e quello successivo
          const width = table.offsetWidth;
          if (daysOfWeek !== 6)
            if ((children[daysOfWeek + 1] as any).style.width !== '0px')
              (children[daysOfWeek + 1] as any).style.width = `${width / 15}px`;
          (children[daysOfWeek] as any).style.width = `${width / 10}px`;
        }
      }
  });

  const getWorkDaysStartEndHours = useCallback(
    (args: RenderCellEventArgs) => {
      if (
        args.elementType === 'workCells' ||
        args.elementType === 'monthCells'
      ) {
        args.element.id =
          args.date !== null
            ? dayjs(args.date).format('DD/MM/YYYY HH:mm') ?? ''
            : '';
        args.element.addEventListener('mousedown', AppointmentMouseDown, {
          capture: true,
        });
        args.element.addEventListener(
          'mouseup',
          (event: any) => AppointmentMouseUp(event, 'default'),
          {
            capture: true,
          }
        );

        const result = isOpen(
          args.date,
          args.elementType,
          calendarPointOfSale,
          shiftPointOfSale
        );
        if (result === 0)
          (args.element as any).style.backgroundColor = 'var(--closed-color)';
        else if (result === 1)
          (args.element as any).style.backgroundColor =
            'var(--alt-background-color)';
        else
          (args.element as any).className =
            'e-work-cells e-alternate-cells e-work-hours';
      } else if (args.elementType === 'majorSlot')
        args.element.setAttribute('style', 'font-size:14px; font-weight: 500;');
    },
    [calendarPointOfSale, shiftPointOfSale]
  );

  function dateHeaderTemplate(props: any) {
    const date: any = dayjs(props.date);
    const dateFormated = date.format('ddd D');
    const mounthFormated = date.format('MMM');
    return (
      <div
        style={{
          width: '100%',
          textAlign: 'center',
          cursor: 'pointer',
          overflow: 'hidden',
        }}
        onClick={() => dispatch(DefaultCalendarSetSelectedDate(props.date))}
      >
        <Typography
          fontSize="14px"
          fontWeight="500"
          sx={{ color: 'unset' }}
        >{`${dateFormated.charAt(0).toUpperCase()}${dateFormated.slice(1)} ${
          date.date() === 1 && view !== 'Day'
            ? `${mounthFormated.charAt(0).toUpperCase()}${mounthFormated.slice(
                1
              )}`
            : ''
        }`}</Typography>
      </div>
    );
  }

  const getZoom = useCallback(() => {
    return {
      enable: true,
      interval: zoom?.interval,
      slotCount: zoom?.slotCount,
    };
  }, [zoom]);

  useEffect(() => {
    dispatch(setPrintComponent(componentRef.current));
  }, [componentRef, dispatch]);

  const getModules = useMemo(() => {
    if (!sessionProfilo.agendaModifica) return [Day, Week, Month, Agenda];
    return [Day, Week, Month, Agenda, Resize, DragAndDrop];
  }, [sessionProfilo.agendaModifica]);

  return (
    <Box
      id="default-schedule"
      ref={componentRef}
      height={'100%'}
      width={'100%'}
    >
      <ScheduleComponent
        ref={scheduleObj}
        width="100%"
        timeScale={getZoom()}
        height="100%"
        cssClass="default-schedule-cell-dimension"
        selectedDate={selectedDate}
        currentView={currentView as View}
        dateFormat="dd/MM/yyyy"
        locale="it"
        minDate={new Date(2000, 0, 1)}
        actionComplete={onActionComplete}
        eventSettings={{
          dataSource: appointments,
          template: (props: any) => (
            <CalendarEventTemplate {...props} currentView={currentView} />
          ),
        }}
        popupOpen={(args) => {
          appointmentFormPopupOpen(
            args,
            selectedUsers,
            selectedStudi,
            selectedServices,
            selectedPointOfSale?.id
          );
        }}
        dragStop={(args: any) =>
          AppointmentDragDrop(args, appointments, 'default')
        }
        resizeStop={(args: any) => AppointmentResize(args, appointments)}
        dateHeaderTemplate={dateHeaderTemplate}
        firstDayOfWeek={1}
        renderCell={getWorkDaysStartEndHours}
        startHour="05:00"
        eventRendered={(args) => AppointmentRender(args)}
        workDays={[0, 1, 2, 3, 4, 5, 6]}
        quickInfoTemplates={
          {
            header: (_: any) => null,
            content: (props: any) => <CalendarEventQuickInfo {...props} />,
          } as any
        }
      >
        <ViewsDirective>
          <ViewDirective option="Day" interval={view === '3Days' ? 3 : 1} />
          <ViewDirective option="Week" />
          <ViewDirective option="Month" />
          <ViewDirective option="Agenda" />
        </ViewsDirective>
        <Inject services={getModules} />
      </ScheduleComponent>
    </Box>
  );
});
