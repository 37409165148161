import { BluAsyncDialog, BluDialog, BluLoader } from '@bludata/components';
import { Stack, Button, Typography, LinearProgress } from '@mui/material';
import dayjs from 'dayjs';
import { useAppointmentsPaginated } from 'packages/blu-booking/src/data_fetching/Appointment/AppointmentsPaginated';
import {
  FC,
  memo,
  useCallback,
  useDeferredValue,
  useMemo,
  useState,
} from 'react';
import { PointOfSaleCheck } from './components/PointOfSale';
import { SlotOk } from './components/SlotOk';
import { AppointmentSlotNotOkGrid } from './components/AppointmentSlotNotOkGrid';
import { AppointmentCompleteInterface } from 'packages/blu-booking/src/interfaces/AppointmentComplete';
import { setConfig } from '../../utility_objects/WhiteCache';
import { appointmentUpdateSlotOkStatus } from 'packages/blu-booking/src/data_fetching/Appointment/AppointmentUpdateSlotOkStatus';
import { useQueryClient } from '@tanstack/react-query';
import { usePointOfSalesCheckSlotOk } from 'packages/blu-booking/src/data_fetching/PointOfSale/PointOfSalesCheckSlotOk';
import axios from 'axios';
import { AppDispatch } from 'packages/blu-booking/src/store';
import { useDispatch } from 'react-redux';
import { setAppointmentsSlotNotOkPopupOpen } from '../../store/Calendar';
import { setRefetch } from '../../store/Appointment';

interface AppointmentsSlotNotOkPopupInnerInterface {
  pointOfSaleId: string;
  handlePosition: (appointment: AppointmentCompleteInterface) => void;
}

export const AppointmentsSlotNotOkPopupInner: FC<AppointmentsSlotNotOkPopupInnerInterface> =
  memo(({ pointOfSaleId, handlePosition }) => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch<AppDispatch>();

    const { data: posCheck, isLoading: posCheckIsLoading } =
      usePointOfSalesCheckSlotOk();
    const pointOfSaleCheck = useDeferredValue(posCheck);
    const [progress, setProgress] = useState<number>(-1);

    const [filters, setFilters] = useState<{
      pointOfSaleId: string;
      pageNumber: number;
    }>({
      pointOfSaleId: pointOfSaleId,
      pageNumber: 1,
    });
    const [selectedAppointment, setSelectedAppointment] = useState<
      AppointmentCompleteInterface | undefined
    >();
    const [loading, setLoading] = useState<boolean>(false);

    const { data, isLoading } = useAppointmentsPaginated({
      ...filters,
      slotOkStatus: [1, 2],
      pointOfSaleIds: filters.pointOfSaleId,
      userIds: [],
      serviceIds: [],
      studioIds: [],
      startDate: dayjs().format('YYYY-MM-DD'),
      pageSize: 10,
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleChangeFilters = useCallback((key: string, value: any) => {
      setFilters((prev) => ({ ...prev, [key]: value }));
    }, []);

    const handlePositioning = useCallback(() => {
      if (selectedAppointment !== undefined) {
        setConfig('underline', selectedAppointment?.id ?? '');
        handlePosition(selectedAppointment);
        dispatch(setAppointmentsSlotNotOkPopupOpen(false));
      }
    }, [handlePosition, selectedAppointment, dispatch]);

    const handleValidationChange = useCallback(
      async (id: string) => {
        try {
          setLoading(true);
          const result = await appointmentUpdateSlotOkStatus(id, 2);
          setLoading(false);
          if (result) {
            handleChangeFilters('pageNumber', 1);

            queryClient.invalidateQueries(['appointments-paginated'], {
              type: 'active',
            });
            queryClient.removeQueries(['appointments-paginated'], {
              type: 'inactive',
            });

            queryClient.invalidateQueries(['appointment-notification'], {
              type: 'active',
            });
            queryClient.removeQueries(['appointment-notification'], {
              type: 'inactive',
            });

            queryClient.invalidateQueries(['point-of-sales', 'check-slot-ok'], {
              type: 'active',
            });
            queryClient.removeQueries(['point-of-sales', 'check-slot-ok'], {
              type: 'inactive',
            });

            dispatch(setRefetch());

            return;
          }
          BluAsyncDialog({
            title: 'Attenzione',
            type: 'error',
            message: `<pre style='font-family: "Roboto"; white-space: pre-wrap; width: inherit;'>${"Si è verificato un errore durante la validazione dell'appuntamento."}</pre>`,
            hideDeclineButton: true,
            confimButton: 'Ok',

            // sx: { '& .MuiDialog-paper': { maxWidth: '500px' } },
          });
        } catch (error) {
          console.trace(error);
        }
      },
      [handleChangeFilters, queryClient]
    );

    const GetLastUpdateAt = useMemo(() => {
      if (
        pointOfSaleCheck &&
        filters.pointOfSaleId in pointOfSaleCheck &&
        pointOfSaleCheck[filters.pointOfSaleId]?.result &&
        pointOfSaleCheck[filters.pointOfSaleId].result?.lastCalendarUpdatedAt
      ) {
        return (
          <strong>
            {`${dayjs(
              pointOfSaleCheck[filters.pointOfSaleId].result
                ?.lastCalendarUpdatedAt
            ).format('DD/MM/YYYY HH:mm')}
            `}
          </strong>
        );
      }
      return '';
    }, [filters.pointOfSaleId, pointOfSaleCheck]);

    const GetCheckUpdateAt = useMemo(() => {
      if (
        pointOfSaleCheck &&
        filters.pointOfSaleId in pointOfSaleCheck &&
        pointOfSaleCheck[filters.pointOfSaleId]?.result &&
        pointOfSaleCheck[filters.pointOfSaleId].result?.checkSlotUpdatedAt
      ) {
        return (
          <strong>
            {`${dayjs(
              pointOfSaleCheck[filters.pointOfSaleId].result?.checkSlotUpdatedAt
            ).format('DD/MM/YYYY HH:mm')}
            `}
          </strong>
        );
      }
      return '';
    }, [filters.pointOfSaleId, pointOfSaleCheck]);

    const getAvviaControlloDisabled = useCallback(() => {
      if (pointOfSaleCheck && filters.pointOfSaleId in pointOfSaleCheck) {
        const { result } = pointOfSaleCheck[filters.pointOfSaleId];

        if (
          result?.checkSlotUpdatedAt &&
          result?.lastCalendarUpdatedAt &&
          dayjs(result?.lastCalendarUpdatedAt).isAfter(
            dayjs(result?.checkSlotUpdatedAt)
          )
        ) {
          return false;
        }
      }
      return true;
    }, [filters.pointOfSaleId, pointOfSaleCheck]);

    const avviaControllo = useCallback(async () => {
      try {
        await axios({
          url: `/api/PointOfSale/CheckSlot/${filters.pointOfSaleId}`,

          headers: {
            accept: '*',
            'Content-Type': 'multipart/form-data',
          },
          method: 'POST',
          onDownloadProgress: (progressEvent) => {
            const xhr = progressEvent.event.target;
            let { responseText } = xhr;

            try {
              responseText = responseText.split('\n');
              console.log('responseText', responseText);
              const result = parseInt(responseText[responseText.length - 2]);
              // const res = JSON.parse(result);
              setProgress(result);
              console.log('response', result, typeof result);
              if (result === 100) {
                setProgress(-1);
                queryClient.invalidateQueries(['appointments-paginated'], {
                  type: 'active',
                });
                queryClient.removeQueries(['appointments-paginated'], {
                  type: 'inactive',
                });

                queryClient.invalidateQueries(['appointment-notification'], {
                  type: 'active',
                });
                queryClient.removeQueries(['appointment-notification'], {
                  type: 'inactive',
                });

                queryClient.invalidateQueries(
                  ['point-of-sales', 'check-slot-ok'],
                  {
                    type: 'active',
                  }
                );
                queryClient.removeQueries(['point-of-sales', 'check-slot-ok'], {
                  type: 'inactive',
                });
              }
              //   if (res.Status === 2 || res.Status === 3) {
              //     // dispatch(setStep(res.Status === 2 ? "confirm" : "error"));

              //     queryClient.invalidateQueries(['turni-point-of-sale'], {
              //       type: 'active',
              //     });
              //     queryClient.removeQueries(['turni-point-of-sale'], {
              //       type: 'inactive',
              //     });

              //     queryClient.invalidateQueries(['turni-user'], {
              //       type: 'active',
              //     });
              //     queryClient.removeQueries(['turni-user'], {
              //       type: 'inactive',
              //     });
              //   }
            } catch (error) {
              console.error(error);
            }
          },
        });
        return;
      } catch (error) {
        /* empty */
      }
    }, [filters.pointOfSaleId, queryClient]);

    return (
      <BluDialog
        open={true}
        zIndex={300}
        disableEnforceFocus
        disableAutoFocus
        disableRestoreFocus
        fullScreen={false}
        draggable
        maxWidth="lg"
        dialogTitle={'Verifica appuntamenti non validi'}
        dialogContent={
          <Stack gap={1}>
            <BluDialog
              open={progress !== -1}
              // open
              dialogTitle={'Controllo appuntamenti'}
              maxWidth="xs"
              zIndex={400}
              dialogContent={
                <Stack gap={1} sx={{ height: '100px' }}>
                  <Stack sx={{ height: '30px' }} />

                  <Typography>Controllo appuntamenti in corso...</Typography>
                  <LinearProgress
                    variant={progress === -1 ? undefined : 'determinate'}
                    value={progress === -1 ? undefined : progress}
                    sx={{ height: 10 }}
                  />
                </Stack>
              }
            />
            <BluLoader open={isLoading || loading || posCheckIsLoading} />
            <Stack flex="1">
              <Typography fontWeight="bold" fontSize="18px">
                Verifica punti vendita con appuntamenti non validi
              </Typography>
              <Typography sx={{ flex: '1' }}>
                Ultima modifica: <strong>{GetLastUpdateAt}</strong>
              </Typography>
              <Typography sx={{ flex: '1' }}>
                Ultimo check validità appuntamenti:{' '}
                <strong>{GetCheckUpdateAt}</strong>
              </Typography>
            </Stack>
            <Stack direction="row" gap={2} alignItems="end">
              <PointOfSaleCheck
                pointOfSaleId={filters.pointOfSaleId}
                pointOfSaleCheck={pointOfSaleCheck}
                handleFilterChange={handleChangeFilters}
              />
              <Stack flex="1">
                <Button
                  variant="contained"
                  sx={{ width: 'fit-content' }}
                  disabled={getAvviaControlloDisabled()}
                  onClick={avviaControllo}
                >
                  Avvia controllo
                </Button>
              </Stack>
            </Stack>

            <Stack direction="row" gap={1}>
              {/* <SlotOk
                slotOK={filters.slotOkStatus}
                handleChangeFilters={handleChangeFilters}
              /> */}
              <Stack flex="1" />
            </Stack>

            <AppointmentSlotNotOkGrid
              appointments={data?.items ?? []}
              pageNumber={filters?.pageNumber}
              totalPages={data?.totalPages ?? 0}
              handlePageNumberChange={(value: number) => {
                handleChangeFilters('pageNumber', value);
              }}
              setSelectedAppointment={setSelectedAppointment}
              handleValidationChange={handleValidationChange}
            />
          </Stack>
        }
        sx={{
          '& .MuiDialogContent-root': {
            pb: '0px !important',
          },
          // '& .MuiDialog-paper': { maxWidth: '1300px !important' },
        }}
        dialogActions={
          <Stack flex="1" direction="row" gap={1}>
            <Stack flex="1" direction="row" gap={1}></Stack>
            <Button
              variant="outlined"
              sx={{ width: 'fit-content' }}
              onClick={() => dispatch(setAppointmentsSlotNotOkPopupOpen(false))}
            >
              Chiudi
            </Button>
            <Button
              variant="contained"
              sx={{ width: 'fit-content' }}
              disabled={!selectedAppointment}
              onClick={handlePositioning}
            >
              Visualizza nell'agenda
            </Button>
          </Stack>
        }
      />
    );
  });
