import { IconButton, MenuItem, Stack, Typography } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import { BluSelect } from '@bludata/components';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';
import { FC, useCallback } from 'react';
import { getPointOfSaleWhiteCache } from '../../../../lib/white-cache';
import { GridCloseIcon } from '@mui/x-data-grid-premium';

interface CustomSelectInterface {
  disabled?: boolean;
}

export const ShopSignSelect: FC<CustomSelectInterface> = ({ disabled }) => {
  const [shopSignId, blubookingLayoutId] = useWatch({
    name: ['shopSignId', 'blubookingLayoutId'],
  });

  const { setValue, trigger } = useFormContext();
  const { isBluBookingActive, isFocusWeb } = getPointOfSaleWhiteCache();

  const { shopSigns } = useSelector(
    (state: RootState) => state.newPointOfSaleRoot
  );

  const handleOnChange = useCallback(
    (event: any) => {
      if (isFocusWeb) {
        setValue('blubookingLayoutId', event.target.value);
        trigger('blubookingLayoutId');
        return;
      }
      setValue('shopSignId', event.target.value);
      trigger('shopSignId');
    },
    [isFocusWeb, setValue, trigger]
  );

  const getLabel = useCallback(() => {
    return isBluBookingActive ? 'Layout Blu Booking' : 'Layout Agenda';
  }, [isBluBookingActive]);

  const handleErase = useCallback(() => {
    if (isFocusWeb) {
      setValue('blubookingLayoutId', '');
      trigger('blubookingLayoutId');
      return;
    }
    setValue('shopSignId', '');
    trigger('shopSignId');
  }, [isFocusWeb, setValue, trigger]);

  return (
    <Stack sx={{ width: '260px' }}>
      <Typography>{getLabel()}</Typography>
      <Stack flex="1" direction="row">
        <BluSelect
          multiple={false}
          disabled={disabled}
          value={isFocusWeb ? blubookingLayoutId : shopSignId}
          onChange={handleOnChange}
          displayEmpty
          renderValue={(selected: any) => {
            if (selected === '') return 'Nessun Layout associato';
            if (shopSigns && selected in shopSigns)
              return shopSigns[selected].name;
            return selected;
          }}
        >
          {Object.values(shopSigns).map((shopSign: any) => (
            <MenuItem value={shopSign.id}>{shopSign.name}</MenuItem>
          ))}
          <MenuItem value={''}>Nessun Layout associato</MenuItem>
        </BluSelect>
        <IconButton color="primary" size="small" onClick={handleErase}>
          <GridCloseIcon />
        </IconButton>
      </Stack>
    </Stack>
  );
};
