/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  BluAutoComplete,
  BluDatePicker,
  BluTimePicker,
} from '@bludata/components';
import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import { appointmentSlotIsOk } from 'packages/blu-booking/src/data_fetching/AppointmentSlot/AppointmentSlotIsOk';
import { useAppSelector } from 'packages/blu-booking/src/store';
import { bluBookingLoginData } from 'packages/blu-booking/src/store/root';
import React, { useEffect } from 'react';
import { memo, useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

const reg = new RegExp('^[0-9]+$');

export const DateSelection = memo(() => {
  const { setValue, trigger } = useFormContext();
  const start = useWatch({ name: 'start' });
  const end = useWatch({ name: 'end' });
  const duration = useWatch({ name: 'duration' });
  const timeSlotDate = useWatch({ name: 'timeSlotDate' });
  const [Id, serviceId, pointOfSaleId, userId, studioId, studioRequired] =
    useWatch({
      name: [
        'Id',
        'serviceId',
        'pointOfSaleId',
        'userId',
        'studioId',
        'studioRequired',
      ],
    });
  const { isManualAppointmentBlock } = useAppSelector(bluBookingLoginData);

  useEffect(() => {
    const delayFn = setTimeout(async () => {
      if (isManualAppointmentBlock) {
        if (
          serviceId !== '' &&
          pointOfSaleId !== '' &&
          userId !== '' &&
          ((studioRequired && studioId !== '') || true)
        ) {
          const s = new Date(start);

          const isOk = await appointmentSlotIsOk({
            appointmentId: Id,
            serviceId,
            pointOfSaleId,
            userId,
            studioId,
            startTime: dayjs(start).format('YYYY-MM-DD HH:mm'),
            endTime: dayjs(
              new Date(s.getTime() + parseInt(duration) * 60000)
            ).format('YYYY-MM-DD HH:mm'),
          });

          setValue('cercaDisponibilitaUsed', isOk.isOk === 0);
        }
      }
    }, 400);

    return () => {
      clearTimeout(delayFn);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duration, serviceId, pointOfSaleId, userId, studioId]);

  const startChange = useCallback(
    (newValue: string) => {
      setValue('start', newValue);
      const start = new Date(newValue);
      setValue('end', new Date(start.getTime() + duration * 60000));
      trigger();
    },
    [duration, setValue, trigger]
  );

  const endChange = useCallback(
    (newValue: string) => {
      setValue('end', newValue);
      const s = dayjs(start);
      const end = dayjs(newValue);
      if (end.isBefore(s)) setValue('start', end.subtract(duration, 'minutes'));
      else setValue('duration', end.diff(start, 'minute'));
      trigger();
    },
    [duration, setValue, start, trigger]
  );

  const durationChange = useCallback(
    async (newValue: string) => {
      if (!reg.test(newValue)) return;

      // if (isManualAppointmentBlock) {
      //   setValue("duration", parseInt(newValue, 10));

      //   setValue("end", new Date(s.getTime() + parseInt(newValue) * 60000));
      //   // const isOk = await appointmentSlotIsOk({
      //   //   appointmentId: Id,
      //   //   serviceId,
      //   //   pointOfSaleId,
      //   //   userId,
      //   //   studioId,
      //   //   startTime: dayjs(start).format("YYYY-MM-DD HH:mm"),
      //   //   endTime: dayjs(
      //   //     new Date(s.getTime() + parseInt(newValue) * 60000)
      //   //   ).format("YYYY-MM-DD HH:mm"),
      //   // });

      //   // setValue("cercaDisponibilitaUsed", isOk);
      //   return;
      // }

      setValue('duration', parseInt(newValue, 10));
      // setValue("durationSet", true);
      setValue('end', newValue);
      const s = new Date(start);
      setValue('end', new Date(s.getTime() + parseInt(newValue) * 60000));
      trigger();
    },
    [setValue, start, trigger]
  );

  return (
    <>
      <div style={{ width: '40%' }}>
        <Typography variant="subtitle2" sx={{ pt: '3px' }}>
          Data
        </Typography>
        <BluDatePicker
          value={timeSlotDate}
          onChange={(newValue: any) => {
            setValue('timeSlotDate', new Date(newValue.format('YYYY/MM/DD')));
            if (newValue.isValid()) {
              setValue(
                'start',
                new Date(
                  `${newValue.format('YYYY/MM/DD')} ${dayjs(start).format(
                    'HH:mm'
                  )}`
                )
              );
            }
          }}
          readOnly={isManualAppointmentBlock}
        />
      </div>
      <div style={{ flex: 1 }}>
        <Typography variant="subtitle2" sx={{ pt: '3px' }}>
          Inizio
        </Typography>
        <BluTimePicker
          value={start}
          readOnly={isManualAppointmentBlock}
          onChange={(newValue: any) => startChange(newValue)}
        />
      </div>
      <div style={{ flex: 1 }}>
        <Typography variant="subtitle2" sx={{ pt: '3px' }}>
          Durata
        </Typography>

        <BluAutoComplete
          value={duration.toString()}
          data={['15', '30', '45']}
          freeSolo
          // readOnly={isManualAppointmentBlock}
          placeholder="Seleziona una durata"
          onChange={() => setValue('durationSet', true)}
          onInputChange={(_: any, newValue: any) => durationChange(newValue)}
        />
      </div>
      <div style={{ flex: 1 }}>
        <Typography variant="subtitle2" sx={{ pt: '3px' }}>
          Fine
        </Typography>
        <BluTimePicker
          value={end}
          readOnly={isManualAppointmentBlock}
          onChange={(newValue: any) => endChange(newValue)}
        />
      </div>
    </>
  );
});
