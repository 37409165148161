import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import User_Interfaces from '../interfaces/user_interfaces';

export const getUserMe = async (): Promise<User_Interfaces | null> => {
  try {
    const {
      data: { data },
    } = await axios.get(`/api/User/Me`);
    return data;
  } catch (e: any) {
    console.error(e);
    return null;
  }
};

export const useUserMe = (key: string = '') => {
  return useQuery({
    queryFn: async () => await getUserMe(),
    queryKey: ['user', 'me', key],
    retry: 2,
    retryDelay: 180000,
    cacheTime: 0,
    staleTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
};
