import { AxiosStatic, CancelTokenSource } from 'axios';
import { useCallback, useMemo, useState } from 'react';
import { FilterObject } from './lib/FilterObject';
import { SortObject } from './lib/SortObject';
import { getTablePaginationRequestProps } from './lib/getTablePaginationRequestProps';

export interface UseTableLoaderProps {
  tablename: string;
  axios: AxiosStatic;
  filters?: FilterObject[];
  sort?: SortObject[];
  api?: 'focusapi' | 'imoapi' | 'crmapi';
  pagination?: {
    page: number;
    pageSize: number;
  };
  onLoaded?: () => void;
  cancelTokenRef?: React.MutableRefObject<CancelTokenSource | null>;
}

const useTableLoader = function <T>(props: UseTableLoaderProps): {
  loading: boolean;
  data: T[];
  success: boolean;
  total: number;
  fetchTableData: () => void;
} {
  const { filters, sort, pagination, tablename, onLoaded, cancelTokenRef } =
    props;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<T[]>([]);
  const [success, setSuccess] = useState(false);
  const [total, setTotal] = useState(0);
  const api = props.api || 'focusapi';

  const errorHandler = () => {
    setLoading(false);
    setData([]);
    setTotal(0);
    setSuccess(false);
  };

  const parameters = useMemo(() => {
    let params = {
      _dc: new Date().getTime(),
      filter: filters ? JSON.stringify(filters) : null,
      sort: sort ? JSON.stringify(sort) : null,
      page: 0,
      start: 0,
      limit: 99999999,
    };

    if (pagination) {
      params = {
        ...params,
        ...getTablePaginationRequestProps(pagination.page, pagination.pageSize),
      };
    }
    return params;
  }, [filters, sort, pagination]);

  const fetchTableData = useCallback(() => {
    if (cancelTokenRef) {
      if (cancelTokenRef?.current) {
        cancelTokenRef.current.cancel('Operation canceled due to new request.');
      }

      cancelTokenRef.current = props.axios.CancelToken.source();
    }
    setLoading(true);

    props.axios
      .get(`/${api}/tabella/${tablename}`, {
        params: parameters,
        cancelToken: cancelTokenRef?.current.token,
      })
      .then((response) => {
        const { data, total, success } = response.data;

        if (success) {
          setData(data);
          setTotal(total);
          setLoading(false);
          setSuccess(success);
        } else {
          errorHandler();
        }
      })
      .catch((error) => {
        if (props.axios.isCancel(error)) {
          console.log('Request canceled', error.message);
        } else {
          errorHandler();
        }
      })
      .finally(() => {
        onLoaded && onLoaded();
      });
  }, [tablename, parameters, api, cancelTokenRef]);

  return {
    loading,
    data,
    success,
    total,
    fetchTableData,
  };
};

export default useTableLoader;
export type { FilterObject } from './lib/FilterObject';
export type { SortObject } from './lib/SortObject';
export { getTablePaginationRequestProps } from './lib/getTablePaginationRequestProps';
