import '../../styles/sync-date-picker.css'

import { ChangeEvent, memo } from 'react'
import { CalendarComponent } from '@syncfusion/ej2-react-calendars'
import { BluSyncDatePickerInterface } from './interface'
import React from 'react'
import loadCalendarLanguage from './language'

loadCalendarLanguage()

const BluSyncDatePicker = memo(
  ({ date, onChange, padding }: BluSyncDatePickerInterface) => {
    return (
      <div style={{ padding: padding ?? '15px 0' }}>
        <CalendarComponent
          value={date}
          locale="it"
          firstDayOfWeek={1}
          showTodayButton={false}
          onChange={(event: ChangeEvent) => onChange(event)}
        />
      </div>
    )
  }
)

export default BluSyncDatePicker
