/* eslint-disable @typescript-eslint/no-explicit-any */
import { Avatar, Stack, Typography } from '@mui/material';
import {
  ScheduleComponent,
  Inject,
  ViewDirective,
  ViewsDirective,
  RenderCellEventArgs,
  ResourceDirective,
  ResourcesDirective,
  Resize,
  DragAndDrop,
  Day,
  TimelineViews,
  View,
} from '@syncfusion/ej2-react-schedule';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import { RootState } from 'packages/blu-booking/src/store';
import { getConfig } from '../../../utility_objects/WhiteCache';
import {
  AppointmentMouseDown,
  AppointmentMouseUp,
} from '../../../utility_objects/AppointmentMouseUp';
import { CalendarEventTemplate } from '../../../UI/CalendarEventTemplate/CalendarEventTemplate';
import { CalendarEventQuickInfo } from '../../../UI/CalendarEventQuickInfo/CalendarEventQuickInfo';
import { AppointmentRender } from '../../../utility_objects/AppointmentRender';
import { AppointmentResize } from '../../../utility_objects/AppointmentResize';
import { AppointmentDragDrop } from '../../../utility_objects/AppointmentDragDrop';
import { appointmentFormPopupOpen } from '../../../utility_objects/AppointmentFormPopupOpen';
import ImageDefault from '../../../../../resources/utentebase.png';
import { isTablet } from 'react-device-detect';
import { UserCalendarSetSelectedDate } from '../store';

interface SchedulerProps {
  calendarPointOfSale: any;
  shiftPointOfSale: any;
}

export const Scheduler = ({
  calendarPointOfSale,
  shiftPointOfSale,
}: SchedulerProps) => {
  const [calendarPointOfSaleWorkHours, calendarPointOfSaleUsersWork] =
    calendarPointOfSale;
  const [shiftPointOfSaleWorkHours] = shiftPointOfSale;
  const { appointments } = useSelector(
    (state: RootState) => state.bluBookingaAppointments
  );
  const dispatch = useDispatch();

  const {
    selectedDate,
    selectedStudi,
    selectedServices,
    selectedPointOfSale,
    zoom,
    view,
  } = useSelector((state: RootState) => state.bluBookingUserCalendar);
  const { sessionProfilo, focus10 } = getConfig();
  const scheduleObj = useRef<ScheduleComponent>(null);

  useEffect(() => {
    const { underline } = getConfig();

    if (
      scheduleObj?.current &&
      dayjs().isSame(dayjs(selectedDate), 'day') &&
      underline === '' &&
      !focus10
    ) {
      try {
        scheduleObj?.current.scrollTo(
          dayjs().subtract(1, 'hour').minute(0).format('HH:mm')
        );
      } catch (error) {
        /* empty */
      }
    }
  }, [focus10, selectedDate, view, calendarPointOfSale, shiftPointOfSale]);

  const onActionComplete = (args) => {
    if (args.requestType === 'dateNavigate') {
      const schedulerInstance = scheduleObj.current;
      const newDate = schedulerInstance.selectedDate; // Ottieni la nuova data dal componente
      dispatch(UserCalendarSetSelectedDate(newDate));
    }
  };
  // useEffect(() => {
  //   if (scheduleObj?.current && view !== "Day") {
  //     // scheduleObj.current.refreshLayout();
  //     const elem = document.getElementsByClassName("e-content-wrap");
  //     if (elem && elem.length > 0) {
  //       elem[0].dispatchEvent(new Event("resize"));
  //     }
  //   }
  // }, [view]);

  function dateHeaderTemplate(props: any) {
    const date: string = dayjs(props.date).format('ddd D MMMM YYYY');
    return (
      <div style={{ position: 'fixed', width: '100%', marginTop: '-10px' }}>
        <div
          style={{
            // position: "fixed",
            width: 'fit-content',
            fontWeight: 'bold',
            paddingLeft: '10px',
          }}
        >
          <Typography
            fontSize="14px"
            fontWeight="500"
            sx={{ color: 'unset' }}
          >{`${date.charAt(0).toUpperCase()}${date.slice(1)}`}</Typography>
        </div>
      </div>
    );
  }

  const getOpen = useCallback(
    (data: Date | undefined, userIdCalendar: string, type?: string) => {
      if (!calendarPointOfSaleWorkHours && !shiftPointOfSaleWorkHours) return 0;

      const time = dayjs(data).format('HH:mm:ss');
      const check = (calendar: any) =>
        calendar &&
        ((time >= calendar.startTime1 && time < calendar.endTime1) ||
          (time >= calendar.startTime2 && time < calendar.endTime2));
      const calendarPointOfSaleWorkHours1 =
        calendarPointOfSaleWorkHours &&
        userIdCalendar in calendarPointOfSaleWorkHours
          ? calendarPointOfSaleWorkHours[userIdCalendar]
          : undefined;
      const shiftPointOfSaleWorkHours1 =
        shiftPointOfSaleWorkHours && userIdCalendar in shiftPointOfSaleWorkHours
          ? shiftPointOfSaleWorkHours[userIdCalendar]
          : undefined;

      if (type && type === 'monthCells') {
        if (calendarPointOfSaleWorkHours) return 2;
        else if (shiftPointOfSale) return 1;
        else return 0;
      }

      const checkCalendar = check(calendarPointOfSaleWorkHours1);
      const checkShift = check(shiftPointOfSaleWorkHours1);
      if (checkCalendar) {
        return 2;
      } else if (checkShift) return 1;
      return 0;
    },
    [calendarPointOfSaleWorkHours, shiftPointOfSale, shiftPointOfSaleWorkHours]
  );

  const getWorkDaysStartEndHours = useCallback(
    (args: RenderCellEventArgs) => {
      if (
        args.elementType === 'workCells' ||
        args.elementType === 'monthCells'
      ) {
        args.element.id =
          args.date !== null
            ? dayjs(args.date).format('DD/MM/YYYY HH:mm') ?? ''
            : '';

        args.element.addEventListener('mousedown', AppointmentMouseDown, {
          capture: true,
        });
        args.element.addEventListener(
          'mouseup',
          (event: any) => AppointmentMouseUp(event, 'user'),
          {
            capture: true,
          }
        );

        const usersList = calendarPointOfSaleUsersWork
          .map((user: any) => user.userId)
          .flat();
        if (args?.groupIndex === undefined) return;

        args.element.setAttribute('userid', usersList[args.groupIndex]);
        const result = getOpen(
          args.date,
          usersList[args.groupIndex],
          args.elementType
        );
        if (result === 0)
          (args.element as any).style.backgroundColor = 'var(--closed-color)';
        else if (result === 1)
          (args.element as any).style.backgroundColor =
            'var(--alt-background-color)';
        else
          (args.element as any).className =
            'e-work-cells e-alternate-cells e-work-hours';
      } else if (args.elementType === 'majorSlot') {
        //? TimeSlot
        args.element.setAttribute('style', 'font-size:14px; font-weight: 500;');
      }
    },
    [calendarPointOfSaleUsersWork, getOpen]
  );

  const getZoom = useCallback(() => {
    return {
      enable: true,
      interval: zoom?.interval,
      slotCount: zoom?.slotCount,
    };
  }, [zoom]);

  const getUsers = useCallback(() => {
    if (calendarPointOfSaleUsersWork === undefined) return [];

    return calendarPointOfSaleUsersWork;
  }, [calendarPointOfSaleUsersWork]);

  const getGroup = useCallback(() => {
    if (calendarPointOfSaleUsersWork !== undefined)
      if (isTablet)
        return {
          group: { byDate: true, resources: ['Utente'] },
          resourceHeaderTemplate: (props: any) => {
            return (
              <div className="template-wrap">
                <div className="resource-detail">
                  <div
                    className="resource-name"
                    style={{ height: 'fit-content', paddingLeft: '10px' }}
                  >
                    <Stack
                      direction="row"
                      flex="1"
                      gap={1}
                      justifyItems={'center'}
                      justifyContent={'center'}
                    >
                      <Avatar
                        sx={{
                          border: '1px solid var(--base-color)',
                          margin: 'auto',
                        }}
                        alt="Foto utente"
                        src={
                          props.resourceData.photo
                            ? props.resourceData.photo
                            : ImageDefault
                        }
                      />
                      <Stack
                        direction="row"
                        flex="1"
                        gap={1}
                        sx={{ m: 'auto' }}
                      >
                        {props.resourceData.username}
                      </Stack>
                    </Stack>
                  </div>
                </div>
              </div>
            );
          },
        };
      else
        return {
          group: { byDate: true, resources: ['Utente'] },
          resourceHeaderTemplate: (props: any) => {
            return (
              <div style={{ height: '97px', margin: 'auto' }}>
                <Avatar
                  sx={{
                    border: '1px solid var(--base-color)',
                    margin: 'auto',
                  }}
                  alt="Foto utente"
                  src={
                    props.resourceData.photo
                      ? props.resourceData.photo
                      : ImageDefault
                  }
                />
                <div
                  style={{
                    width: '100%',
                    paddingTop: '10px',
                    textAlign: 'center',
                  }}
                >
                  {`${props.resourceData.username}`}
                </div>
              </div>
            );
          },
        };
    return {};
  }, [calendarPointOfSaleUsersWork]);

  const getModules = useCallback(() => {
    if (!sessionProfilo.agendaModifica) return [Day, TimelineViews];
    return [Day, DragAndDrop, Resize, TimelineViews];
  }, [sessionProfilo.agendaModifica]);

  return (
    <ScheduleComponent
      ref={scheduleObj}
      cssClass={`user${view === 'Day' ? '-day' : ''}-schedule-cell-dimension`}
      width="100%"
      height="100%"
      timeScale={getZoom()}
      selectedDate={selectedDate}
      currentView={view as View}
      dateFormat="dd/MM/yyyy"
      locale="it"
      minDate={new Date(2000, 0, 1)}
      actionComplete={onActionComplete}
      eventSettings={{
        dataSource: appointments,
        template: (props: any) => <CalendarEventTemplate {...props} />,
      }}
      popupOpen={(args) =>
        appointmentFormPopupOpen(
          args,
          [{ id: args.data.userId }],
          selectedStudi,
          selectedServices,
          selectedPointOfSale?.id
        )
      }
      dragStop={(args: any) => AppointmentDragDrop(args, appointments, 'user')}
      resizeStop={(args: any) => AppointmentResize(args, appointments)}
      firstDayOfWeek={1}
      renderCell={getWorkDaysStartEndHours}
      startHour="07:00"
      eventRendered={(args) => AppointmentRender(args)}
      // workHours={{ start: "00:00", end: "23:59", highlight: true }}
      dateHeaderTemplate={dateHeaderTemplate}
      quickInfoTemplates={
        {
          header: () => null,
          content: (props: any) => <CalendarEventQuickInfo {...props} />,
          // footer: footerTemplate,
        } as any
      }
      {...getGroup()}
    >
      <ResourcesDirective>
        <ResourceDirective
          field="userId"
          title="Utenti"
          name="Utente"
          allowMultiple={true}
          dataSource={getUsers()}
          textField="name"
          idField="userId"
          colorField="color"
        />
      </ResourcesDirective>
      <ViewsDirective>
        <ViewDirective option="Day" />
        <ViewDirective option="TimelineDay" />
      </ViewsDirective>
      <Inject services={getModules()} />
    </ScheduleComponent>
  );
};
