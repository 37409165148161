/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, memo, useRef } from 'react';
import { Box } from '@mui/material';
import {
  Agenda,
  ScheduleComponent,
  Inject,
  ViewDirective,
  ViewsDirective,
  Month,
} from '@syncfusion/ej2-react-schedule';
import { CalendarEventQuickInfo } from '../../../UI/CalendarEventQuickInfo/CalendarEventQuickInfo';
import { popUpOpen } from '../utils/PopupOpen';
import { useSelector } from 'react-redux';
import { RootState } from 'packages/blu-booking/src/store';
import { eventRendered } from '../utils/EventRendered';
import { CalendarEventTemplate } from '../../../UI/CalendarEventTemplate/CalendarEventTemplate';
import dayjs from 'dayjs';

interface AgendaClienteInterface {
  selectedDate: Date;
  view: 'Month' | 'Agenda';
  client: any;
  pointOfSaleId: string;
  setSelectedDate: (date: Date) => void;
}

export const Scheduler: FC<AgendaClienteInterface> = memo(
  ({ selectedDate, view, client, pointOfSaleId, setSelectedDate }) => {
    const { appointments } = useSelector(
      (state: RootState) => state.bluBookingaAppointments
    );
    const scheduleObj = useRef<ScheduleComponent>(null);

    const onActionComplete = (args) => {
      if (args.requestType === 'dateNavigate') {
        const schedulerInstance = scheduleObj.current;
        let newDate = schedulerInstance.selectedDate; // Ottieni la nuova data dal componente
        newDate = dayjs(newDate).startOf('month').toDate();
        console.log('newDate', newDate);
        setSelectedDate(newDate);
      }
    };

    return (
      <Box id="agenda-cliente-box" height={'100%'} width={'100%'}>
        <ScheduleComponent
          ref={scheduleObj}
          width="100%"
          height="100%"
          cssClass="agenda-cliente"
          selectedDate={selectedDate}
          currentView={view}
          dateFormat="dd/MM/yyyy"
          locale="it"
          minDate={new Date(2000, 0, 1)}
          actionComplete={onActionComplete}
          eventSettings={{
            dataSource: appointments,
            template: (props: any) => (
              <CalendarEventTemplate
                {...props}
                currentView={view}
                isTabCliente
              />
            ),
          }}
          hideEmptyAgendaDays
          agendaDaysCount={30}
          popupOpen={(args) => popUpOpen(args, client, pointOfSaleId)}
          firstDayOfWeek={1}
          startHour="05:00"
          eventRendered={(args) => eventRendered(args)}
          workDays={[0, 1, 2, 3, 4, 5, 6]}
          quickInfoTemplates={
            {
              header: () => null,
              content: (props: any) => <CalendarEventQuickInfo {...props} />,
              // footer: footerTemplate,
            } as any
          }
        >
          <ViewsDirective>
            <ViewDirective option="Month" />
            <ViewDirective option="Agenda" />
          </ViewsDirective>
          <Inject services={[Agenda, Month]} />
        </ScheduleComponent>
      </Box>
    );
  }
);
