import PersonIcon from '@mui/icons-material/Person';
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { FC, memo } from 'react';

interface OperatorInterface {
  username: string;
  color: string;
}

export const Operator: FC<OperatorInterface> = memo(({ username, color }) => {
  return (
    <Stack sx={{ width: '100%', p: '2px 0' }} flexDirection="row" gap="15px">
      <PersonIcon
        sx={{
          color: color !== 'white' && color !== '#000000' ? color : 'black',
        }}
      />
      <Stack flexDirection="column">
        <Typography variant="body1">{`${username}`}</Typography>
      </Stack>
    </Stack>
  );
});
