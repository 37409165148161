/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  DataGridPremium,
  GridFooterContainer,
  GridRenderCellParams,
} from '@mui/x-data-grid-premium';
import { FC, memo, useCallback, useDeferredValue, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { BluAsyncDialog, BluLoader } from '@bludata/components';
import CircleIcon from '@mui/icons-material/Circle';
import { RootState } from 'packages/blu-booking/src/store';
import { useHistoryMessage } from 'packages/blu-booking/src/data_fetching/HistoryMessage/HistoryMessage';
import {
  MessageStatusEnum,
  MessageTypeEnum,
  NotificationsItemInterface,
  NotificationTypeEnum,
} from 'packages/blu-booking/src/interfaces/Notifications';
import { setPage, setPageSize } from '../store/Notifications';
import { errorsMail } from 'packages/blu-booking/src/utility_objects/ErrorMail';
import { notificationWarningConstants } from 'packages/blu-booking/src/utility_objects/NotificationCostants';
import { historyMessageResend } from 'packages/blu-booking/src/data_fetching/HistoryMessage/HistoryMessageResend';
import { useQueryClient } from '@tanstack/react-query';

const StyledDataGrid = styled(DataGridPremium)(({ theme }) => ({
  '& .comunicazione-grid--error': {
    backgroundColor: '#fee11a',
    '&:hover': {
      backgroundColor: '#fee11a',
    },
    '&.Mui-selected': {
      backgroundColor: '#fee11a',
      '&:hover': {
        backgroundColor: '#fee11a',
      },
    },
  },

  '& .comunicazione-grid--sendingerror': {
    backgroundColor: 'rgba(255, 43, 43, 0.8)',
    color: 'white',
    '&:hover': {
      backgroundColor: 'rgba(255, 43, 43, 0.9)',
      color: 'white',
    },
    '&.Mui-selected': {
      backgroundColor: 'rgba(255, 43, 43, 1)',
      color: 'white',
      '&:hover': {
        backgroundColor: 'rgba(255, 43, 43, 1)',
        color: 'white',
      },
    },
  },

  '& .comunicazione-grid--pending': {
    backgroundColor: '#64b5f6',
    color: 'white',
    '&:hover': {
      backgroundColor: '#64b5f6',
      color: 'white',
    },
    '&.Mui-selected': {
      backgroundColor: '#64b5f6',
      color: 'white',
      '&:hover': {
        backgroundColor: '#64b5f6',
        color: 'white',
      },
    },
  },
}));

interface HistoryMessageGridInterface {
  setSelectedAppointment: (
    value: NotificationsItemInterface | undefined
  ) => void;
}

export const HistoryMessageGrid: FC<HistoryMessageGridInterface> = memo(
  ({ setSelectedAppointment }) => {
    const {
      page,
      pageSize,
      clientFilter,
      messageStatusFilter,
      messageTypeFilter,
      notificationTypeFilter,
      fromToday,
    } = useSelector((state: RootState) => state.bluBookingNotifications);
    const dispatch = useDispatch();
    const queryClient = useQueryClient();

    const [loading, setLoading] = useState<boolean>(false);

    const { data, isLoading } = useHistoryMessage({
      pageSize,
      pageNumber: page,
      externalCustomerCode: clientFilter
        ? clientFilter.externalCustomerCode
        : undefined,
      email:
        clientFilter && !clientFilter?.externalCustomerCode
          ? clientFilter.email
          : undefined,
      messageStatus: messageStatusFilter as MessageStatusEnum,
      messageType: messageTypeFilter as MessageTypeEnum,
      notificationType: notificationTypeFilter as NotificationTypeEnum,
      fromToday,
    });

    const result = useDeferredValue(data);

    const getValues = useCallback(() => {
      if (result && result?.items && result.items.length > 0)
        return result.items;
      return [];
    }, [result]);

    const getTotalPage = useCallback(() => {
      if (result && result?.totalPages) return result.totalPages;
      return 1;
    }, [result]);

    const handlePagePrev = useCallback(() => {
      if (result && result?.totalPages && page !== 1) {
        dispatch(setPage(page - 1));
        setSelectedAppointment(undefined);
      }
    }, [dispatch, page, result, setSelectedAppointment]);

    const handlePageNext = useCallback(() => {
      if (result && result?.totalPages && page < result?.totalPages) {
        dispatch(setPage(page + 1));
        setSelectedAppointment(undefined);
      }
    }, [dispatch, page, result, setSelectedAppointment]);

    const disablePagePrevButton = useCallback((): boolean => {
      if (result && result?.totalPages && page === 1) return true;
      return false;
    }, [page, result]);

    const disablePageNextButton = useCallback(() => {
      if (result && result?.totalPages && page >= result?.totalPages)
        return true;
      return false;
    }, [page, result]);

    const handlePageSizeChange = useCallback(
      (event: SelectChangeEvent) => {
        dispatch(setPageSize(parseInt(event.target.value)));
        dispatch(setPage(1));
        setSelectedAppointment(undefined);
      },
      [dispatch, setSelectedAppointment]
    );

    const Columns: any[] = [
      {
        field: 'id',
        hide: true,
      },
      {
        field: 'messageStatus',
        headerName: 'Stato',
        width: 60,
        editable: false,
        valueFormatter: (params: GridRenderCellParams) => {
          switch (params?.value) {
            case 'error':
              return 'Errore';
            case 'sendingerror':
              return 'Errore invio';
            case 'skipped':
              return 'Invio saltato';
            case 'pending':
              return 'In attesa';
            default:
              return 'Successo';
          }
        },
        renderCell: (params: any) => {
          const getColor = () => {
            switch (params?.value) {
              case 'error':
                return '#fee11a';
              case 'sendingerror':
                return 'rgba(255, 43, 43, 0.9)';
              case 'success':
                return 'var(--confirm-color)';
              case 'pending':
                return '#64b5f6';
              default:
                return undefined;
            }
          };
          return (
            <Box
              sx={{
                width: '15px',
                height: '15px',
                borderRadius: '50%',
                margin: 'auto',
                bgcolor: getColor(),
              }}
            ></Box>
          );
        },
      },
      {
        field: 'createdAt',
        headerName: 'Data',
        width: 150,
        valueFormatter: (params: GridRenderCellParams) =>
          moment(params?.value).format('DD/MM/YYYY HH:mm'),
      },

      {
        field: 'messageType',
        headerName: 'Tipo',
        width: 60,
        editable: false,
        valueFormatter: (params: GridRenderCellParams) => {
          switch (params?.value) {
            case 'IMO':
              return 'App';
            case 'SMS':
              return 'SMS';
            default:
              return 'Email';
          }
        },
      },
      {
        field: 'notificationType',
        headerName: 'Notifica',
        width: 110,
        editable: false,
        valueFormatter: (params: GridRenderCellParams) => {
          switch (params?.value) {
            case 'confirmation':
              return 'Conferma';
            case 'modification':
              return 'Modifica';
            case 'cancellation':
              return 'Cancellazione';
            default:
              return 'Reminder';
          }
        },
      },

      {
        field: 'pointOfSale',
        headerName: 'Punto vendita',
        flex: 1,
        minWidth: 130,
        editable: false,
        valueFormatter: (params: GridRenderCellParams) =>
          params?.value ? params?.value?.branch || '' : '',
      },

      {
        field: 'service',
        headerName: 'Servizio',
        flex: 1,
        minWidth: 180,
        editable: false,
        valueFormatter: (params: GridRenderCellParams) =>
          params?.value ? params?.value?.name || '' : '',
      },
      {
        field: 'user',
        headerName: 'Utente',
        flex: 1,
        minWidth: 150,
        editable: false,
        valueFormatter: (params: GridRenderCellParams) =>
          params?.value
            ? `${params?.value?.name || ''} ${params?.value?.surname || ''}`
            : '',
      },
      {
        field: 'name',
        headerName: 'Cliente',
        flex: 1,
        minWidth: 150,
        editable: false,
        renderCell: (params: any) => (
          <Box sx={{ flex: '1' }}>
            {params?.row
              ? `${params?.row?.name || ''} ${params?.row?.surname || ''}`
              : ''}
          </Box>
        ),
      },
      {
        field: 'descriptions',
        headerName: 'Descrizione',
        flex: 2,
        minWidth: 100,
        editable: false,
        valueFormatter: (params: GridRenderCellParams) => params?.value || '',
        renderCell: (params: any) => {
          if (
            params?.row &&
            params?.row?.descriptions &&
            params?.row?.descriptions.length > 1
          )
            return (
              <Stack flex="1" gap={1} sx={{ p: '10px 0' }}>
                {(params?.row?.descriptions ?? []).map((code: string) => {
                  if (params?.row?.messageStatus === 'sendingerror')
                    if (code in errorsMail)
                      return (
                        <Stack direction="row">
                          <CircleIcon
                            sx={{
                              height: '10px',
                              width: '10px',
                              pr: '5px',
                              pt: '5px',
                            }}
                          />
                          <Typography sx={{ wordBreak: 'break-all' }}>
                            {errorsMail[code]}
                          </Typography>
                        </Stack>
                      );
                    else return null;
                  else if (code in notificationWarningConstants)
                    return (
                      <Stack direction="row">
                        <CircleIcon
                          sx={{
                            height: '10px',
                            width: '10px',
                            pr: '5px',
                            pt: '5px',
                          }}
                        />
                        <Typography sx={{ wordBreak: 'break-all' }}>
                          {notificationWarningConstants[code]}
                        </Typography>
                      </Stack>
                    );
                  return null;
                })}
              </Stack>
            );
          return (
            <Stack flex="1" gap={1} sx={{ p: '10px 0' }}>
              {(params?.row?.descriptions ?? []).map((code: string) => {
                if (params?.row?.messageStatus === 'sendingerror')
                  if (code in errorsMail)
                    return (
                      <Typography sx={{ wordBreak: 'break-all' }}>
                        {errorsMail[code]}
                      </Typography>
                    );
                  else return null;
                else if (code in notificationWarningConstants)
                  return (
                    <Typography sx={{ wordBreak: 'break-all' }}>
                      {notificationWarningConstants[code]}
                    </Typography>
                  );
                return null;
              })}
            </Stack>
          );
        },
      },
      {
        field: 'resend',
        headerName: '',
        editable: false,
        renderCell: (params: GridRenderCellParams) => {
          // <RenderStatusSlotOk {...params} />;
          const handleOnChange = async (event: any) => {
            setLoading(true);
            const result = await historyMessageResend(params?.row?.id);
            setLoading(false);
            if (result) {
              dispatch(setPage(1));
              setSelectedAppointment(undefined);
              queryClient.invalidateQueries(['history-message'], {
                type: 'active',
              });
              queryClient.removeQueries(['history-message'], {
                type: 'inactive',
              });
              return;
            }
            BluAsyncDialog({
              title: 'Attenzione',
              type: 'warning',
              message: `<pre style='font-family: "Roboto"; white-space: pre-wrap; width: inherit;'>${"Si è verificato un errore durante l'invio della notifica.\nRiprova più tardi."}</pre>`,
              hideDeclineButton: true,
              confimButton: 'Ok',

              sx: { '& .MuiDialog-paper': { maxWidth: '500px' } },
            });
          };
          return (
            <Button
              sx={{ p: '0 8px' }}
              variant="outlined"
              onClick={handleOnChange}
            >
              Reinvia
            </Button>
          );
        },
      },
    ];

    return (
      <>
        <BluLoader open={isLoading || loading} />

        <StyledDataGrid
          columns={Columns}
          rows={getValues()}
          //   loading={serviceLoad}
          sx={{
            '& .MuiDataGrid-cell': {
              outline: 'none!important',
              minHeight: '50px !important',
            },
            '& .MuiDataGrid-row': {
              minHeight: '50px !important',
            },
          }}
          rowBuffer={50}
          rowThreshold={15}
          density="compact"
          disableColumnMenu
          getEstimatedRowHeight={() => 300}
          getRowHeight={() => 'auto'}
          editMode="cell"
          onProcessRowUpdateError={(error) => console.log(error)}
          experimentalFeatures={{ newEditingApi: true }}
          // getRowClassName={(params) => {
          //   // ! params.row.status
          //   return `comunicazione-grid--${params.row.messageStatus}`;
          // }}
          onRowClick={(params) => {
            if (result) {
              const find = (result?.items ?? []).find(
                (item) => item.id === params.row.id
              );
              if (find) setSelectedAppointment(find);
            }
          }}
          components={{
            Footer: () => (
              <GridFooterContainer>
                <Stack
                  flex="1"
                  direction="row"
                  gap={1}
                  alignItems="center"
                  sx={{ pl: 1, pr: 1 }}
                >
                  <Stack flex="1" direction="row" alignItems="center">
                    <Typography sx={{ pr: 2 }}>{`Visualizza righe`}</Typography>
                    <Select
                      value={pageSize.toString()}
                      onChange={handlePageSizeChange}
                      sx={{ width: 100 }}
                    >
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                      <MenuItem value={30}>30</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                    </Select>
                  </Stack>
                  {!isLoading && (
                    <Typography
                      sx={{ pr: 2 }}
                    >{`Pagina ${page} di ${getTotalPage()}`}</Typography>
                  )}
                  <IconButton
                    onClick={handlePagePrev}
                    disabled={disablePagePrevButton()}
                  >
                    <KeyboardArrowLeftIcon />
                  </IconButton>
                  <IconButton
                    onClick={handlePageNext}
                    disabled={disablePageNextButton()}
                  >
                    <KeyboardArrowRightIcon />
                  </IconButton>
                </Stack>
              </GridFooterContainer>
            ),
          }}
        />
      </>
    );
  }
);
