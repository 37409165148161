/* eslint-disable @typescript-eslint/no-explicit-any */
import { BluSelect } from '@bludata/components';
import {
  Typography,
  MenuItem,
  SelectChangeEvent,
  Stack,
  SvgIcon,
} from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import { memo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { getStatusIcon } from '../../../utility_objects/StatusIcon';
import { appointmentStatus } from '../../../utility_objects/AppointmentStatus';

export const Status = memo(function Status(): JSX.Element {
  const { setValue } = useFormContext();
  const [statusValue, initialStatus] = useWatch({
    name: ['status', 'initialStatus'],
  });
  return (
    <div style={{ width: '65%', paddingBottom: '5px' }}>
      <Typography variant="subtitle2" sx={{ whiteSpace: 'nowrap' }}>
        Stato appuntamento
      </Typography>
      <BluSelect
        name="status"
        value={statusValue}
        renderValue={(value: any) => (
          <Stack flex="1" direction="row" gap={2}>
            <SvgIcon
              component={getStatusIcon(value)}
              sx={{
                fontSize: '18px',
                pt: '2px',
                pb: '1px',
              }}
            />
            {appointmentStatus[value]}
          </Stack>
        )}
        onChange={(event: SelectChangeEvent) => {
          setValue('status', parseInt(event.target.value));
        }}
        style={{ height: '30px' }}
      >
        {Object.keys(appointmentStatus).map((key: any) => {
          return (
            <MenuItem
              key={nanoid()}
              value={key}
              disabled={key === '0' && initialStatus !== 0}
            >
              <Stack flex="1" direction="row" gap={2}>
                <SvgIcon
                  component={getStatusIcon(key)}
                  sx={{
                    fontSize: '18px',
                    pt: '2px',
                    pb: '1px',
                  }}
                />
                {appointmentStatus[key]}
              </Stack>
            </MenuItem>
          );
        })}
      </BluSelect>
    </div>
  );
});
