/* eslint-disable @typescript-eslint/no-explicit-any */
import { BluAutoComplete, BluTitle } from '@bludata/components';
import { Box, Chip, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import hexToRgba from 'hex-to-rgba';
import { RootState } from 'packages/blu-booking/src/store';
import { useServicesFilter } from 'packages/blu-booking/src/data_fetching/Service/ServicesFilter';
import {
  mapColor,
  wc_hex_is_light,
} from 'packages/blu-booking/src/utility_objects/ColorManager';
import { ServiceIconConverter } from 'packages/blu-booking/src/utility_objects/ServiceIconConverter';

interface ServiceFilterInterface {
  selectedService: string[];
  pointOfSaleId?: string;
  addService: (value: string[]) => void;
  resetService: () => void;
}

export const ServiceFilter: FC<ServiceFilterInterface> = memo(
  function ServiceFilter({
    selectedService,
    pointOfSaleId,
    addService,
    resetService,
  }): JSX.Element {
    const { filtersLoad } = useSelector(
      (state: RootState) => state.bluBookingCalendarFilters
    );

    const { data, isLoading } = useServicesFilter({
      lockPointOfSale: true,
      lockUser: false,
      pointOfSaleId,
      filtersLoaded: filtersLoad,
    });

    const getColors = useCallback((option: any) => {
      const color = option.color ?? mapColor[option];

      return {
        backgroundColor: hexToRgba(color, 0.8),
        color: wc_hex_is_light(color) ? 'black' : 'white',
        border: `2px solid ${color}`,
      };
    }, []);

    const getPlaceHolder = useCallback(() => {
      if (selectedService.length <= 0) return 'Tutti i servizi';
      return undefined;
    }, [selectedService.length]);

    const getValue = useCallback(() => {
      return (data ?? []).filter(({ id }: any) => selectedService.includes(id));
    }, [data, selectedService]);

    const handleChange = useCallback(
      (_: any, newValue: any) => {
        const full = data !== undefined && newValue.length === data.length;
        if (full) {
          resetService();
        } else {
          addService(newValue.map(({ id }: { id: string }) => id).flat());
        }
      },
      [addService, data, resetService]
    );

    return (
      <>
        <BluTitle sx={{ p: '5px 0px' }} text="Servizi" />

        <BluAutoComplete
          size="small"
          multiple
          autoComplete="off" // Disabilita il completamento automatico
          renderOption={(props: any, option: any) => {
            return (
              <Box component="li" {...props}>
                <Stack flex="1" direction="row" gap={1}>
                  <Stack flex="1">{option.name}</Stack>
                  {(option?.icons ?? []).length > 0 && (
                    <Stack direction="row">
                      {option.icons.map((v: string) => (
                        <ServiceIconConverter
                          name={v}
                          sx={{ fontSize: '18px', pt: '2px' }}
                        />
                      ))}
                    </Stack>
                  )}
                </Stack>
              </Box>
            );
          }}
          getOptionLabel={(option: any) => `${option.name}`}
          data={data ?? []}
          value={getValue()}
          onChange={handleChange}
          loading={isLoading}
          textField={{
            autoComplete: 'new-password',
            placeholder: getPlaceHolder(),
          }}
          isOptionEqualToValue={(option: any, value: any) =>
            option.id === value.id
          }
          renderTags={(value: any, getTagProps: any) =>
            value.map((option: any, index: any) => {
              return (
                <Chip
                  variant="outlined"
                  label={
                    <Stack direction="row" gap={1}>
                      {(option?.icons ?? []).length > 0 && (
                        <Stack direction="row">
                          {option.icons.map((v: string) => (
                            <ServiceIconConverter
                              name={v}
                              sx={{
                                fontSize: '18px',
                                pt: '2px',
                                color: 'inherit',
                              }}
                            />
                          ))}
                        </Stack>
                      )}
                      <Typography
                        noWrap
                        sx={{ textOverflow: 'ellipsis' }}
                      >{`${option.name}`}</Typography>
                    </Stack>
                  }
                  {...getTagProps({ index })}
                  sx={{ ...getColors(option), maxWidth: 100 }}
                />
              );
            })
          }
        />
      </>
    );
  }
);
