import { BluTitle } from "@bludata/components";
import { Stack } from "@mui/material";
import { FC, useCallback } from "react";
import { getPointOfSaleWhiteCache } from "../../../../lib/white-cache";
import { CustomTextField } from "../../common/CustomTextField";
import { CustomCheckbox } from "../../common/CustomCheckBox";
import { CustomInputHtmlArea } from "../../common/CustomHtmlArea";
import { ShopSignSelect } from "../../components/ShopSignSelect/ShopSignSelect";
import { useAppSelector } from "../../../../../../store";
import { bluBookingLoginData } from "../../../../../../store/slices/root";

export const InformazioniGeneraliBB: FC<{
  mode: "modify" | "info" | "new";
  disabled: boolean;
}> = ({ mode, disabled }): JSX.Element => {
  const { software } = useAppSelector(bluBookingLoginData);

  const { isConsole, isFocus10, isBluBookingActive } =
    getPointOfSaleWhiteCache();

  const getTogleEditIsNotDisplay = useCallback(() => {
    if (mode === "info") return true;
    if (!isConsole) return false;
    if (software === "bb") return false;
    return true;
  }, [isConsole, mode, software]);

  return (
    <>
      <BluTitle
        text={"Informazioni generali"}
        sx={{ mt: "8px!important", mb: "8px!important" }}
      />
      <Stack direction="column" gap={1}>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-end"
          gap={1}
          flex={1}
        >
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-end"
            gap={1}
            sx={{ flex: 1 }}
          >
            {isBluBookingActive && (
              <CustomTextField
                name="nameOnline"
                label="Nome visualizzato online"
                disabled={disabled}
              />
            )}
            <CustomTextField
              name={"branch"}
              disabled={disabled || isFocus10}
              label="Nome punto vendita"
            />
            <CustomTextField
              name={"internalCode"}
              label="Sigla"
              disabled
              limit={12}
              width="165px"
              inputProps={{
                style: { textTransform: "uppercase" },
              }}
            />
          </Stack>
        </Stack>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-end"
          gap={1}
          sx={{ mb: "-3px", flex: 1 }}
        >
          <CustomCheckbox
            name={"isDoNotDisplay"}
            disabled={getTogleEditIsNotDisplay()}
            label="Non visualizzare"
            width="180px"
          />
          <CustomCheckbox
            name={"isActive"}
            disabled={disabled || isFocus10}
            label="Attivo"
            width="150px"
          />
        </Stack>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          gap={1}
        >
          <CustomTextField
            name={"name"}
            disabled={disabled || isFocus10}
            label="Ragione sociale"
          />
        </Stack>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          gap={1}
        >
          <CustomTextField
            name={"address"}
            label="Indirizzo"
            disabled={disabled || isFocus10}
          />
        </Stack>

        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          gap={1}
        >
          <CustomTextField
            name={"city"}
            disabled={disabled || isFocus10}
            label="Città"
          />

          <CustomTextField
            name={"region"}
            label="Provincia"
            limit={2}
            inputProps={{
              style: { textTransform: "uppercase" },
            }}
            width="150px"
            disabled={disabled || isFocus10}
          />
          <CustomTextField
            name={"postalCode"}
            label="CAP"
            limit={5}
            disabled={disabled || isFocus10}
          />
        </Stack>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          gap={1}
        >
          <CustomTextField
            name={"telephone"}
            disabled={disabled || isFocus10}
            label="Telefono"
          />
          <CustomTextField
            name={"fax"}
            disabled={disabled || isFocus10}
            label="Fax"
          />
        </Stack>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          gap={1}
        >
          <CustomTextField
            name={"email"}
            label="Email"
            disabled={disabled || isFocus10}
          />

          <ShopSignSelect disabled={disabled} />
        </Stack>

        <Stack
          gap={1}
          direction="column"
          justifyContent="start"
          alignItems="start"
          sx={{ width: "100%" }}
        >
          <Stack
            gap={1}
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ width: "100%" }}
          >
            <CustomInputHtmlArea
              name={"description"}
              label="Note aggiuntive"
              disabled={disabled}
            />
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};
