import { FC, memo } from 'react';
import axios from 'axios';
import { Provider } from 'react-redux';
import { Stack } from '@mui/material';
import { store } from '../../store';
import { Header } from '../../UI/Header';
import { NotificationsInner } from './NotificationsInner';

interface NotificationsInterface {
  token?: string;
  url?: string;
  focusWeb?: boolean;
  root?: string;
  focus10?: boolean;
}

export const Notifications: FC<NotificationsInterface> = memo(
  ({ url, token, root, focusWeb = false, focus10 = false }) => {
    if (url !== undefined) axios.defaults.baseURL = url;

    if (token !== undefined) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }

    return (
      <Provider store={store}>
        <Stack
          flex={1}
          style={{
            width: '100%',
            height: '100%',
            backgroundColor: 'var(--hovered-background-color)',
          }}
        >
          <Header title={`${root ?? ''}Storico notifiche`} />

          <NotificationsInner focusWeb={focusWeb} focus10={focus10} />
        </Stack>
      </Provider>
    );
  }
);
