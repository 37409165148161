/* eslint-disable @typescript-eslint/no-explicit-any */
import { BluAutoComplete, BluTitle } from '@bludata/components';
import { Box, Chip } from '@mui/material';
import { FC, memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import hexToRgba from 'hex-to-rgba';
import { UserInterface } from 'packages/blu-booking/src/interfaces/User';
import { RootState, useAppSelector } from 'packages/blu-booking/src/store';
import { bluBookingLoginData } from 'packages/blu-booking/src/store/root';
import { useUsersFilter } from 'packages/blu-booking/src/data_fetching/User/UserFilter';
import {
  usersMapColor,
  wc_hex_is_light,
} from 'packages/blu-booking/src/utility_objects/ColorManager';

interface UsersFilterInterface {
  selectedUsers: UserInterface[];
  pointOfSaleId?: string;
  multiple?: boolean;
  addUsers: (value: UserInterface[]) => void;
  resetUsers: () => void;
}

export const UsersFilter: FC<UsersFilterInterface> = memo(function UsersFilter({
  selectedUsers,
  pointOfSaleId,
  multiple = true,
  addUsers,
  resetUsers,
}): JSX.Element {
  const { filtersLoad } = useSelector(
    (state: RootState) => state.bluBookingCalendarFilters
  );
  const { visibilityUserEnum } = useAppSelector(bluBookingLoginData);

  const { data, isLoading } = useUsersFilter({
    lockService: false,
    lockPointOfSale: true,
    pointOfSaleId,
    filtersLoaded: filtersLoad,
  });

  const handleChange = useCallback(
    (_: any, newValue: any) => {
      if (multiple) {
        const full = data !== undefined && newValue.length === data.length;
        if (full && visibilityUserEnum !== 4) resetUsers();
        else addUsers(newValue);
      } else addUsers([newValue]);
    },
    [addUsers, data, multiple, resetUsers, visibilityUserEnum]
  );

  const getValue = useCallback(() => {
    if (multiple) return selectedUsers;
    else if (selectedUsers.length > 0) return selectedUsers[0];
    else return null;
  }, [multiple, selectedUsers]);

  const getColors = useCallback((option: any) => {
    const color = option.color ?? usersMapColor[option.id];
    return {
      backgroundColor: hexToRgba(color, 0.8),
      color: wc_hex_is_light(color) ? 'black' : 'white',
      border: `2px solid ${color}`,
    };
  }, []);

  const getPlaceHolder = useCallback(() => {
    if (selectedUsers.length <= 0) return 'Tutti gli utenti';
    return undefined;
  }, [selectedUsers.length]);

  return (
    <>
      <BluTitle sx={{ p: '5px 0px' }} text="Utenti" />

      <BluAutoComplete
        size="small"
        autoComplete="off" // Disabilita il completamento automatico
        disableClearable={!multiple}
        multiple={multiple}
        renderOption={(props: any, option: any) => (
          <Box component="li" {...props}>
            {option.username}
          </Box>
        )}
        getOptionLabel={(option: any) => `${option.username}`}
        data={data ?? []}
        value={getValue()}
        onChange={handleChange}
        loading={isLoading}
        textField={{
          autoComplete: 'new-password',
          placeholder: getPlaceHolder(),
        }}
        isOptionEqualToValue={(option: any, value: any) =>
          option.id === value.id
        }
        renderTags={(value: any, getTagProps: any) =>
          value.map((option: any, index: any) => {
            return (
              <Chip
                variant="outlined"
                label={`${option.username}`}
                {...getTagProps({ index })}
                sx={getColors(option)}
              />
            );
          })
        }
        // emptyValue="Tutti gli utenti"
      />
    </>
  );
});
