import RoomIcon from '@mui/icons-material/Room';
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { PointOfSaleInterface } from 'packages/blu-booking/src/interfaces/PointOfSale';
import { RootState } from 'packages/blu-booking/src/store';
import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';

interface WhereInterface {
  pointOfSaleId?: string;
  studioId?: string;
  color?: string;
}

export const Where = memo(
  ({ color, pointOfSaleId, studioId }: WhereInterface) => {
    const { pointOfSales, studi } = useSelector(
      (state: RootState) => state.bluBookingCalendarFilters
    );
    const Where = useMemo(() => {
      if (
        color !== undefined &&
        pointOfSaleId !== undefined &&
        studioId !== undefined
      ) {
        const { branch, address, city } = pointOfSales[
          pointOfSaleId
        ] as PointOfSaleInterface;

        const { name: studioName } = studi[studioId];
        return (
          <Stack
            sx={{ width: '100%', p: '2px 0' }}
            flexDirection="row"
            gap="15px"
          >
            <RoomIcon
              sx={{
                color:
                  color !== 'white' && color !== '#000000' ? color : 'black',
              }}
            />
            <Stack flexDirection="column">
              <Typography variant="body1">{branch}</Typography>
              <Typography variant="body2">{`${address} - ${city}`}</Typography>
              <Typography variant="body2">{studioName}</Typography>
            </Stack>
          </Stack>
        );
      }
      return null;
    }, [color, pointOfSaleId, studioId, pointOfSales, studi]);

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{Where}</>;
  }
);
