import {
  FC,
  Suspense,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Stack, Typography } from '@mui/material';
import { AppointmentConfiguration } from './components/AppointmentConfiguration';
import BluAccordion from '../../UI/BluAccordition';
import { General } from './components/General';
import { AppDispatch, RootState } from '../../store';
import { AppointmentResponse } from './components/AppointmentResponse';
import { Privacy } from './components/Privacy';
import { useShopSignDetails } from './api/shop-sign-details';
import { getDefaultShopSign, setDefaultShopSign } from './lib/white-cache';
import { resetPalette, setPalette } from './store/palette';
import { resetFont, setFont } from './store/font';
import { resetComponents, setComponents } from './store/components';
import { Palette } from './components/Palette';
import { GenericText } from './components/GenericText';
import { Titles } from './components/Titles';
import { LayoutButton } from './components/LayoutButton';
import { Publication } from './components/Publication';
import {
  BluAlert,
  BluAsyncDialog,
  BluLoader,
  BluTitle,
} from '@bludata/components';
import { Buttons } from './components/Buttons';
import { LayoutBox } from './components/LayoutBox';
import { resetCustomTheme, setCustomTheme } from './store/custom-theme';
import { getInitialValues } from './utils/initial-values';
import { useQueryClient } from '@tanstack/react-query';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './schema';
import { ShopSignInterface } from '../../interfaces/shop-sign';
import { setLoadLayout, setMode, setShopSignId } from './store/root';
import { manageShopSign } from './api/manage-shop-sign';
import { NotificationConfig } from './components/NotificationConfig';

interface ShopSignComponentInterface {
  shopSignId?: string;
  hasAgenda: boolean;
  software: string;
  isConsole: boolean;
  isBBActive: boolean;
}

export const ShopSign: FC<ShopSignComponentInterface> = memo(
  ({ shopSignId, hasAgenda, software, isConsole, isBBActive }) => {
    const { data, isLoading } = useShopSignDetails(shopSignId);

    const [err, setErr] = useState<string>('');
    const [alert, setAlert] = useState<string>('');

    const getInitialData = useCallback(() => {
      if (!isLoading && data !== undefined) return getInitialValues(data);
      return undefined;
    }, [data, isLoading]);

    const GetForm = useMemo(() => {
      if (!isLoading && data !== undefined)
        return (
          <Suspense fallback={<BluLoader open />}>
            <ShopSignForm
              shopSign={data}
              shopSignId={shopSignId}
              setAlert={setAlert}
              setError={setErr}
              hasAgenda={hasAgenda}
              initialValues={getInitialData()}
              software={software}
              isConsole={isConsole}
              isBBActive={isBBActive}
            />
          </Suspense>
        );
      return <BluLoader open />;
    }, [
      isLoading,
      data,
      shopSignId,
      hasAgenda,
      getInitialData,
      software,
      isConsole,
      isBBActive,
    ]);

    return (
      <>
        <BluAlert
          show={!!alert}
          text={alert}
          handleClose={() => {
            setAlert('');
          }}
          type={'info'}
        />
        <BluAlert
          show={!!err}
          text={err}
          handleClose={() => {
            setErr('');
          }}
          type={'info'}
        />
        {GetForm}
      </>
    );
  }
);

interface ShopSignFormInterface {
  shopSign: ShopSignInterface | null;
  shopSignId?: string;
  initialValues?: ShopSignInterface;
  setAlert: (info: string) => void;
  setError: (err: string) => void;
  hasAgenda: boolean;
  software: string;
  isConsole: boolean;
  isBBActive: boolean;
}

const ShopSignForm: FC<ShopSignFormInterface> = ({
  shopSign,
  shopSignId,
  initialValues,
  setAlert,
  setError,
  hasAgenda,
  software,
  isConsole,
  isBBActive,
}) => {
  const { mode, shopSigns, prevShopSignId } = useSelector(
    (state: RootState) => state.layoutRoot
  );
  const isDisabled = useCallback(() => mode === 'view', [mode]);
  const palette = useSelector((state: RootState) => state.layoutPalette);
  const typography = useSelector((state: RootState) => state.layoutFont);
  const components = useSelector((state: RootState) => state.layoutComponents);
  const customTheme = useSelector(
    (state: RootState) => state.layoutCustomTheme
  );
  const queryClient = useQueryClient();
  const dispatch = useDispatch<AppDispatch>();

  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (shopSign !== null) {
      if ('configurationJson' in shopSign) {
        const config = JSON.parse(shopSign.configurationJson);
        dispatch(setPalette(config));
        dispatch(setFont(config));
        dispatch(setComponents(config));
      }
      if (
        'customConfigurationJson' in shopSign &&
        shopSign.customConfigurationJson !== '' &&
        shopSign.customConfigurationJson !== null
      ) {
        const customConfig = JSON.parse(
          shopSign.customConfigurationJson as any
        );
        dispatch(setCustomTheme(customConfig));
      }
      setDefaultShopSign(shopSign);
    }

    methods.reset(initialValues);
    methods.trigger();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, methods, shopSign]);

  const save = useCallback(async () => {
    dispatch(setLoadLayout(true));

    const data = methods.watch();
    const result = await manageShopSign(
      data,
      palette,
      typography,
      components,
      customTheme,
      software,
      isConsole,
      shopSignId
    );

    dispatch(setLoadLayout(false));
    try {
      (window as any).disableMenuBluBooking(false);
    } catch {}

    if (result !== '') {
      dispatch(setShopSignId(result));
      dispatch(setMode('view'));
      queryClient.invalidateQueries(['details', 'shop-sign']);
      queryClient.invalidateQueries(['all-filters', 'shop-sign']);
      queryClient.invalidateQueries(['shop-sign', 'holiday']);
      queryClient.removeQueries(['shop-sign', 'holiday'], {
        type: 'inactive',
      });
      queryClient.removeQueries(['all-filters', 'shop-sign'], {
        type: 'inactive',
      });
      queryClient.removeQueries(['details', 'shop-sign'], {
        type: 'inactive',
      });
      return;
    }
    setError(
      "Ci dispiace il servizio non è disponibile al momento. Contatta l'assistenza."
    );
  }, [
    components,
    customTheme,
    dispatch,
    isConsole,
    methods,
    palette,
    queryClient,
    setError,
    shopSignId,
    software,
    typography,
  ]);

  const resetValues = useCallback(() => {
    BluAsyncDialog({
      title: 'Attenzione',
      message: 'Vuoi annullare le modifiche effettuate?',
      confimButton: 'Si',
      declineButton: 'No',
      onConfirmClick: () => {
        if (shopSignId !== undefined) {
          const initialShopSign = getDefaultShopSign();
          methods.reset(initialValues);

          if ('configurationJson' in initialShopSign) {
            const config = JSON.parse(initialShopSign.configurationJson);
            if (initialShopSign?.customConfigurationJson) {
              const customConfig = JSON.parse(
                initialShopSign.customConfigurationJson
              );
              dispatch(setCustomTheme(customConfig));
            }
            dispatch(setPalette(config));
            dispatch(setFont(config));
            dispatch(setComponents(config));
            //dispatch(changeHolidays(initialHolidays));
          }
        } else dispatch(setShopSignId(prevShopSignId ?? shopSigns!![0]?.id));
        dispatch(setMode('view'));
        try {
          (window as any).disableMenuBluBooking(false);
        } catch {}
      },

      type: 'warning',
      // maxWidth: false,
      sx: { '& .MuiDialog-paper': { maxWidth: '400px' } },
    });
  }, [dispatch, initialValues, methods, prevShopSignId, shopSignId, shopSigns]);

  return (
    <Suspense>
      <FormProvider {...methods}>
        <Stack
          direction="column"
          justifyContent="flex-start"
          flex="1"
          sx={{ overflowY: 'auto', pb: '20px' }}
        >
          {/* <BluAccordion
            sx={{ mt: "4px!important", mb: "4px!important" }}
            title="Generali"
            defaultExpanded
          > */}
          <Box
            sx={{
              mt: '4px',
              mb: '4px',
              p: '10px 10px 15px 10px',
              border: '1px solid var(--divider-color)',
              borderRadius: '4px',
            }}
          >
            <BluTitle text="Generali" />
            {/* <Typography color="text.primary">
              Impostazioni per la gestione del layout del sito di prenotazione
              online.
            </Typography> */}
            <General
              hasAgenda={hasAgenda}
              disabled={isDisabled()}
              software={software}
              isConsole={isConsole}
              isBBActive={isBBActive}
            />
          </Box>
          {software !== 'bb' && (
            <BluAccordion
              sx={{ mb: '4px!important' }}
              title={'Configurazione notifiche'}
              defaultExpanded
            >
              <NotificationConfig
                disabled={isDisabled()}
                software={software}
                hasAgenda={hasAgenda}
                isBBActive={isBBActive}
              />
            </BluAccordion>
          )}
          <>
            <BluAccordion
              sx={{ mb: '4px!important' }}
              title="Configurazione appuntamenti"
              defaultExpanded
            >
              <AppointmentConfiguration
                software={software}
                disabled={isDisabled()}
              />
            </BluAccordion>
          </>
          <BluAccordion
            sx={{ mb: '4px!important' }}
            title="Configurazione risposta appuntamenti"
            defaultExpanded
          >
            <AppointmentResponse disabled={isDisabled()} />
          </BluAccordion>
          {isBBActive && (
            <>
              <BluAccordion
                sx={{ mb: '4px!important' }}
                title="GDPR - Regolamento 2016/679"
                defaultExpanded
              >
                <Privacy
                  disabled={isDisabled()}
                  privacyNotice={initialValues?.privacyNotice ?? '<p></p>'}
                  privacyPolicy={initialValues?.privacyPolicy ?? '<p></p>'}
                />
              </BluAccordion>
            </>
          )}

          {/*<BluAccordion
                sx={{ mb: "4px!important" }}
                title="Altro"
                defaultExpanded
              >
                <Other disabled={isDisabled()} />
              </BluAccordion>
              <BluAccordion sx={{ mb: "4px!important" }} title="Festività">
            <Holidays shopSignId={shopSignId ?? ""} disabled={isDisabled()} />
          </BluAccordion>*/}

          {isBBActive && (
            <BluAccordion title="Tema" sx={{ mb: '4px!important' }}>
              <Stack gap={2} sx={{ position: 'relative' }}>
                <Stack gap={1}>
                  <BluTitle text="Formattazione pagina" />
                  <Palette disabled={isDisabled()} />
                </Stack>

                <Stack gap={1}>
                  <BluTitle text="Formattazione box" />
                  <LayoutBox disabled={isDisabled()} />
                </Stack>

                <Stack gap={1}>
                  <BluTitle text="Formattazione testo generico" />
                  <GenericText disabled={isDisabled()} />
                </Stack>

                <Stack gap={1}>
                  <BluTitle text="Formattazione titoli" />
                  <Titles disabled={isDisabled()} />
                </Stack>

                <Stack gap={1}>
                  <BluTitle text="Formattazione pulsante" />
                  <LayoutButton disabled={isDisabled()} />
                </Stack>
              </Stack>

              {!isDisabled() && (
                <Stack
                  flexDirection="unset"
                  flexWrap="wrap"
                  justifyContent="end"
                  flex="1"
                  gap={1}
                  sx={{
                    position: 'sticky',
                    bottom: -20,
                    backgroundColor: 'var(--base-foreground-color)',
                    zIndex: 200,
                    pt: 2,
                    pb: 1,
                  }}
                >
                  <Button
                    // disabled={isDisabled()}
                    color="error"
                    onClick={() => {
                      // updateOnButtonClick(`[${campo.toLowerCase()}]`);
                      dispatch(resetCustomTheme());
                      dispatch(resetPalette());
                      dispatch(resetFont());
                      dispatch(resetComponents());
                    }}
                  >
                    Azzera il tema
                  </Button>
                </Stack>
              )}
            </BluAccordion>
          )}
          {isBBActive && mode !== 'create' && (
            <BluAccordion sx={{ mb: '4px!important' }} title="Pubblicazione">
              <Publication setAlert={(value: string) => setAlert(value)} />
            </BluAccordion>
          )}
        </Stack>
        <Buttons save={save} reset={resetValues} shopSignId={shopSignId} />
      </FormProvider>
    </Suspense>
  );
};
