import { Backdrop, CircularProgress } from '@mui/material';
import { FC, memo } from 'react';
import { BluLoaderInterface } from './interface';
import './BluLoader.scss';

const BluLoader: FC<BluLoaderInterface> = memo(
  ({ open, CircularProps, ...rest }) => {
    // return (
    //   <span
    //     style={{ position: 'absolute', margin: '1em' }}
    //     className="loader"
    //   />
    // );

    let loader = (
      <CircularProgress
        color="primary"
        thickness={1.5}
        size={'120px'}
        {...CircularProps}
      />
    );

    if (
      import.meta &&
      (import.meta as any)?.env &&
      (import.meta as any).env.VITE_NX_PUBLIC_YOUR_CLIENT_ID
    ) {
      loader = (
        <div className="loader-bludata">
          <span className="loader-bludata__element"></span>
          <span className="loader-bludata__element"></span>
          <span className="loader-bludata__element"></span>
        </div>
      );
    }

    return (
      <Backdrop
        {...rest}
        sx={{
          display: open ? undefined : 'none',
          zIndex: 9999999999999999, // Imposta un indice z molto alto per assicurarsi che il loader sia sempre in primo piano.
          backgroundColor: 'rgba(0, 0, 0, 0.05)',
          ...rest?.sx,
        }}
        open
      >
        {loader}
      </Backdrop>
    );
  }
);

export default BluLoader;
