import { BluContainerBlock, BluLoader, BluTitle } from '@bludata/components';
import {
  FC,
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Header } from '../../UI/Header';
import { Box, Button, IconButton, Stack, Tooltip } from '@mui/material';
import dayjs from 'dayjs';
import { AppDispatch, RootState } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { CalendarPointOfSale } from './features/CalendarPointOfSale';
import { MonthYearPicker } from './features/MonthYearPicker';
import { Filiale } from './features/Filiale';
import {
  isTurniLoading,
  setFetchPointOfSale,
  setIsLoading,
  setPointOfSaleId,
  setPointOfSaleName,
} from './store/root';
import { CalendarUser } from './features/CalendarUser';
import { useHoliday } from './api/holiday';
import { TurnoType } from './features/TurnoType';
import { RotaPopUp } from './components/RotaPopUp/RotaPopUp';
import { LegendaTurni } from './components/Legenda';
import { ImportPopUp } from './components/ImportPopUp/ImportPopUp';
import { getRotaWhiteCache } from './lib/white-cache';
import { ExportPopup } from './components/exportPopUp/exportPopUp';
import GetAppIcon from '@mui/icons-material/GetApp';
import { usePointOfSaleCalendar } from './api/point-of-sales';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { setImportOpen } from './store/import';
import { CalendarPointOfSaleDay } from './features/CalendarPointOfSaleDay';
import { ViewType } from './features/ViewType';
import { CalendarUserDay } from './features/CalendarUserDay';
import { LegendaUserTurni } from './components/Legenda/LegendaUser';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useQueryClient } from '@tanstack/react-query';

dayjs.locale('it');

interface RotaPageInterface {
  root?: string;
  updateTurni: boolean;
}
export const RotaPage: FC<RotaPageInterface> = memo(
  ({ root = '', updateTurni }) => {
    const { loginData } = useSelector((state: RootState) => state.root);
    const { isSingleCalendarManagement = true } = loginData ?? {};
    const { pointOfSaleId, selectedDate, popUpRota, view } = useSelector(
      (state: RootState) => state.rotaRoot
    );
    const queryClient = useQueryClient();

    const { data: pointOfSales, isLoading: pointOfSaleLoading } =
      usePointOfSaleCalendar();

    const [exportFile, setExportFile] = useState<boolean>(false);
    const { turniFilter } = useSelector((state: RootState) => state.rotaRoot);
    const { turniModifica } = getRotaWhiteCache();
    const dispatch = useDispatch<AppDispatch>();

    const isLoading = useSelector(isTurniLoading);
    const legendPointOfSale = useRef();
    const legendUser = useRef();
    const pointOfSaleGridRef = useRef();
    const [userGridTopHeight, setUserGridTopHeight] = useState<number>();

    const { data: holiday, isLoading: isLoadingHoliday } =
      useHoliday(selectedDate);

    useEffect(() => {
      if (pointOfSales) {
        const pointOfSale = pointOfSales.find((d) => d.id === pointOfSaleId);
        if (pointOfSale) {
          dispatch(setPointOfSaleName(pointOfSale.branch));
          dispatch(setFetchPointOfSale(true));
          return;
        }
        if (pointOfSales.length > 0) {
          dispatch(setPointOfSaleId(pointOfSales[0].id));
          dispatch(setPointOfSaleName(pointOfSales[0].branch));
        }
        dispatch(setFetchPointOfSale(true));
      }
    }, [pointOfSales, dispatch, pointOfSaleId]);

    const getIsLoading = useCallback(() => {
      return isLoading || isLoadingHoliday || pointOfSaleLoading;
    }, [isLoading, isLoadingHoliday, pointOfSaleLoading]);

    const getHoliday = useCallback(() => {
      if (holiday) return holiday;
      return {};
    }, [holiday]);

    const getPointOfSaleGrid = useMemo(() => {
      if (pointOfSaleId) {
        if (view === 'day')
          return (
            <CalendarPointOfSaleDay
              holiday={getHoliday()}
              isSingleCalendarManagement={isSingleCalendarManagement}
            />
          );
        return (
          <CalendarPointOfSale
            holiday={getHoliday()}
            isSingleCalendarManagement={isSingleCalendarManagement}
          />
        );
      }
      return <></>;
    }, [getHoliday, isSingleCalendarManagement, pointOfSaleId, view]);

    const getUsersGrid = useMemo(() => {
      if (pointOfSaleId) {
        if (view === 'day')
          return (
            <CalendarUserDay
              holiday={getHoliday()}
              isSingleCalendarManagement={isSingleCalendarManagement}
            />
          );
        return (
          <CalendarUser
            holiday={getHoliday()}
            isSingleCalendarManagement={isSingleCalendarManagement}
          />
        );
      }
      return <></>;
    }, [getHoliday, isSingleCalendarManagement, pointOfSaleId, view]);

    const GetPopUp = useMemo(() => {
      if (popUpRota && updateTurni)
        return (
          <RotaPopUp
            {...popUpRota}
            isSingleCalendarManagement={isSingleCalendarManagement}
          />
        );
      return <></>;
    }, [isSingleCalendarManagement, popUpRota, updateTurni]);

    const refreshTap = useCallback(() => {
      queryClient.invalidateQueries(['turni-user'], {
        type: 'active',
      });
      queryClient.removeQueries(['turni-user'], {
        type: 'inactive',
      });
      queryClient.invalidateQueries(['turni-point-of-sale'], {
        type: 'active',
      });
      queryClient.removeQueries(['turni-point-of-sale'], {
        type: 'inactive',
      });
      queryClient.invalidateQueries(['turni-user-day'], {
        type: 'active',
      });
      queryClient.removeQueries(['turni-user-day'], {
        type: 'inactive',
      });
      queryClient.invalidateQueries(['turni-point-of-sale-day'], {
        type: 'active',
      });
      queryClient.removeQueries(['turni-point-of-sale-day'], {
        type: 'inactive',
      });
      queryClient.invalidateQueries(['holiday'], {
        type: 'active',
      });
      queryClient.removeQueries(['holiday'], {
        type: 'inactive',
      });
    }, [queryClient]);

    useLayoutEffect(() => {
      let { clientHeight: pointOfSaleGridHeight } =
        pointOfSaleGridRef.current ?? { clientHeight: 200 };
      if (pointOfSaleGridHeight === 0) {
        if (isSingleCalendarManagement) {
          pointOfSaleGridHeight = 113;
        } else {
          pointOfSaleGridHeight = 180;
        }
      }

      setUserGridTopHeight(
        45 +
          (legendPointOfSale?.current ?? { clientHeight: 60 })?.clientHeight +
          pointOfSaleGridHeight +
          (legendUser?.current ?? { clientHeight: 60 })?.clientHeight
      );
    }, [isSingleCalendarManagement, turniFilter, view]);

    return (
      <>
        <BluLoader open={getIsLoading()} />
        <Header title={`${root ?? ''}Turni e orari`} />
        {GetPopUp}

        <ImportPopUp
          pointOfSale={pointOfSaleId}
          isSingleCalendarManagement={isSingleCalendarManagement}
          pointOfSales={pointOfSales}
        />

        {exportFile && (
          <ExportPopup
            pointOfSale={pointOfSaleId}
            setExportFile={setExportFile}
            isSingleCalendarManagement={isSingleCalendarManagement}
            pointOfSales={pointOfSales}
          />
        )}
        <BluContainerBlock
          key="DefaultCalendar1"
          sx={{
            m: '4px',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            flex: '1',
          }}
        >
          <>
            <Stack
              flex="1"
              flexDirection="unset"
              flexWrap="wrap"
              justifyContent="start"
              alignItems="start"
              gap={1}
              sx={{ pt: 1, pb: 2 }}
            >
              <ViewType />
              <MonthYearPicker />
              <Filiale data={pointOfSales} />
              {!isSingleCalendarManagement && <TurnoType />}
              <Stack flex="1" />
              <Tooltip title="Ricarica">
                <IconButton
                  // variant="outlined"
                  color="primary"
                  onClick={refreshTap}
                  sx={{
                    width: '32px',
                    height: '36px',
                    marginTop: 'auto !important',
                    //   pr: "0px",
                    //   minWidth: "32px",
                    //   pl: "2px",
                  }}
                >
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
              {turniModifica && (
                <>
                  <Button
                    variant="outlined"
                    sx={{ height: 'fit-content', marginTop: 'auto !important' }}
                    onClick={() => dispatch(setImportOpen(true))}
                    // disabled={isFocus10}
                    startIcon={<FileUploadIcon />}
                  >
                    Importa
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{ height: 'fit-content', marginTop: 'auto !important' }}
                    startIcon={<GetAppIcon />}
                    // disabled={isFocus10}
                    onClick={() => setExportFile(true)}
                  >
                    Esporta
                  </Button>
                </>
              )}
            </Stack>

            <Stack
              sx={{
                width: '100%',
                height: '100%',
                overflow: 'auto',
                position: 'relative',
              }}
            >
              <Stack
                direction="row"
                sx={{ position: 'sticky', left: 0, pt: '25px' }}
                alignItems={'center'}
                ref={legendPointOfSale}
                gap={1}
              >
                <Stack
                  direction="row"
                  sx={{ position: 'sticky', left: 0 }}
                  flex={1}
                >
                  <BluTitle text="Orari punto vendita" />
                </Stack>
                <LegendaTurni />
              </Stack>

              <Stack
                sx={{
                  position: 'absolute',
                  top: `${
                    5 +
                    (legendPointOfSale?.current ?? { clientHeight: 60 })
                      ?.clientHeight
                  }px`,
                }}
                direction="row"
                ref={pointOfSaleGridRef}
              >
                {getPointOfSaleGrid}
              </Stack>
              <Stack
                sx={{
                  height:
                    (pointOfSaleGridRef.current ?? { clientHeight: '200px' })
                      .clientHeight + 40,
                }}
              />

              <Stack
                direction="row"
                sx={{ position: 'sticky', left: 0 }}
                alignItems={'center'}
                gap={1}
                ref={legendUser}
              >
                <Stack
                  direction="row"
                  sx={{ position: 'sticky', left: 0 }}
                  flex={1}
                >
                  <BluTitle text="Orari utenti" />
                </Stack>

                <LegendaUserTurni />
              </Stack>
              <Stack
                sx={{
                  position: 'absolute',
                  // top:
                  //   view === 'day'
                  //     ? isSingleCalendarManagement || turniFilter
                  //       ? '250px'
                  //       : '285px'
                  //     : isSingleCalendarManagement || turniFilter
                  //     ? '288px'
                  //     : '345px',

                  top: userGridTopHeight,
                }}
                direction="row"
              >
                {getUsersGrid}
              </Stack>
            </Stack>
          </>
        </BluContainerBlock>
      </>
    );
  }
);
