/* eslint-disable react/jsx-no-useless-fragment */
import { Button } from '@mui/material';
import { memo, useCallback, useEffect, useMemo } from 'react';
import AddIcon from '@mui/icons-material/Add';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { getConfig } from '../../utility_objects/WhiteCache';
import { store } from 'packages/blu-booking/src/store';
import { setOpenForm } from '../../store/AppointmentForm';
import { setOpenClientPopUp } from '../../store/CreateLink';
import { Header } from 'packages/blu-booking/src/UI/Header';

interface CalendarHeaderProps {
  title: string;
  id: string;
  children?: JSX.Element;
}

export const CalendarHeader = memo(function CalendarHeader({
  title,
  id,
  children,
}: CalendarHeaderProps): JSX.Element {
  const dispatch = useDispatch();
  const { focus10, sessionProfilo } = getConfig();

  const openEditor = useCallback(() => {
    let data;
    const {
      bluBookingDefaultCalendar,
      bluBookingStudioCalendar,
      bluBookingPersonalCalendar,
      bluBookingUserCalendar,
    } = store.getState();

    switch (id) {
      case 'user':
        data = bluBookingUserCalendar;
        break;
      case 'studio':
        data = bluBookingStudioCalendar;
        break;
      case 'personal':
        data = bluBookingPersonalCalendar;
        break;
      default:
        data = bluBookingDefaultCalendar;
        break;
    }
    const {
      zoom,
      selectedPointOfSale,
      selectedServices,
      selectedStudi,
      selectedUsers,
    } = data;
    const addingMinutes = zoom.interval / zoom.slotCount;

    dispatch(
      setOpenForm({
        StartTime: dayjs(),
        EndTime: dayjs().add(addingMinutes, 'minutes'),
        userId: selectedUsers.length === 1 ? selectedUsers[0].id : undefined,
        serviceId:
          selectedServices.length === 1 ? selectedServices[0] : undefined,
        studioId: selectedStudi.length === 1 ? selectedStudi[0] : undefined,
        pointOfSaleId: selectedPointOfSale?.id,
      })
    );
    // const end = dayjs().add(15, "minutes");
    // dispatch(setOpenForm({ StartTime: date, EndTime: end }));
  }, [dispatch, id]);

  const openCreateLink = useCallback(() => {
    dispatch(setOpenClientPopUp(true));
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener('openNewApointment', openEditor);
    window.addEventListener('openCreateLink', openCreateLink);
    return () => {
      window.removeEventListener('openNewApointment', openEditor);
      window.removeEventListener('openCreateLink', openCreateLink);
    };
  });

  const CreateAppointment = useMemo(() => {
    if (sessionProfilo.agendaInserimento)
      return (
        <Button
          startIcon={<AddIcon />}
          onClick={() => openEditor()}
          variant="contained"
        >
          Nuovo
        </Button>
      );
    return null;
  }, [openEditor, sessionProfilo.agendaInserimento]);

  return <>{!focus10 && <Header title={title}>{CreateAppointment}</Header>}</>;
});
