import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import 'dayjs/locale/it';
import { Appointment_interface } from '../../../interfaces/Interfaces';

export interface CalendarSliceState {
  emailPopup?: {
    id: string;
    emailSet: boolean;
    type: string;
    appointment: Appointment_interface;
    findIndex?: number;
    prevData?: Appointment_interface;
  };
  searchPopup: boolean;
  clientSelected?: {
    name: string;
    surname: string;
    email: string;
    telephone?: string;
    isImo?: boolean;
    externalCustomerCode?: string;
  };
  rightClick?: any;
  appointmentNotConfirmPopupOpen: boolean;
  appointmentsSlotNotOkPopupOpen: boolean;
  appointmentsWithErrorsPopupOpen: boolean;
}

const initialState: CalendarSliceState = {
  searchPopup: false,
  appointmentNotConfirmPopupOpen: false,
  appointmentsSlotNotOkPopupOpen: false,
  appointmentsWithErrorsPopupOpen: false,
};

const CalendarSlice = createSlice({
  name: 'BluBookingCalendar',
  initialState,
  reducers: {
    setEmailPopup(
      state: CalendarSliceState,
      action: PayloadAction<
        | {
            id: string;
            emailSet: boolean;
            type: string;
            appointment: Appointment_interface;
            findIndex?: number;
            prevData?: Appointment_interface;
          }
        | undefined
      >
    ) {
      state.emailPopup = action.payload;
    },

    setSearchPopUp(state: CalendarSliceState, action: PayloadAction<boolean>) {
      state.searchPopup = action.payload;
    },

    setClientSelected(state: CalendarSliceState, action: PayloadAction<any>) {
      console.log('ENTER CLIENT SELECTED', action.payload);
      state.clientSelected = action.payload;
    },
    resetRoot: () => initialState,
    setRightClick: (state: CalendarSliceState, action: PayloadAction<any>) => {
      state.rightClick = action.payload;
    },
    setAppointmentNotConfirmPopupOpen: (
      state: CalendarSliceState,
      action: PayloadAction<boolean>
    ) => {
      state.appointmentNotConfirmPopupOpen = action.payload;
    },
    setAppointmentsSlotNotOkPopupOpen: (
      state: CalendarSliceState,
      action: PayloadAction<boolean>
    ) => {
      state.appointmentsSlotNotOkPopupOpen = action.payload;
    },
    setAppointmentsWithErrorsPopupOpen: (
      state: CalendarSliceState,
      action: PayloadAction<boolean>
    ) => {
      state.appointmentsWithErrorsPopupOpen = action.payload;
    },
  },
});

export const {
  setEmailPopup,
  setSearchPopUp,
  setClientSelected,
  resetRoot,
  setRightClick,
  setAppointmentNotConfirmPopupOpen,
  setAppointmentsSlotNotOkPopupOpen,
  setAppointmentsWithErrorsPopupOpen,
} = CalendarSlice.actions;

export default CalendarSlice.reducer;
