import { IconButton, Stack, TextField, Typography } from '@mui/material';
import { FC, useCallback, useMemo, useState } from 'react';
import { memo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

export const InternalDescription: FC<{ isModify: boolean }> = memo(
  ({ isModify }) => {
    const note = useWatch({ name: 'internalDescription' });
    const { setValue, trigger } = useFormContext();
    const [type, setType] = useState<boolean>(!isModify);

    const handleTypeClick = useCallback(() => {
      setType(!type);
    }, [type]);

    const GetIcon = useMemo(() => {
      if (!type) return <VisibilityIcon />;
      return <VisibilityOffIcon />;
    }, [type]);

    const getValue = useCallback(() => {
      if (type)
        return [...(note ?? '')]
          .map((n: string) => (n === ' ' ? n : '*'))
          .join('');
      return note;
    }, [note, type]);

    return (
      <Stack flex="1">
        <Stack
          flex="1"
          direction="row"
          sx={{ pt: '2px', boxSizing: 'border-box' }}
        >
          <Typography variant="subtitle2" sx={{ flex: 1, pt: '1px' }}>
            {'Note riservate'}
          </Typography>
          <IconButton sx={{ p: 0 }} onClick={handleTypeClick}>
            {GetIcon}
          </IconButton>
        </Stack>
        <TextField
          value={getValue()}
          name="Note interne"
          fullWidth
          multiline
          autoComplete="off" // Disabilita il completamento automatico
          rows={4}
          onChange={(
            event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => {
            setValue('internalDescription', event.target.value);
            trigger();
          }}
          type="password"
          InputProps={{
            readOnly: type,
          }}
        />
      </Stack>
    );
  }
);
