import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import 'dayjs/locale/it';
import {
  getCookie,
  setCookie,
} from 'packages/blu-booking/src/utility_objects/CookieManager';
import {
  removePointOfSale,
  removeService,
  removeStudio,
  selectPointOfSale,
  selectService,
  selectStudio,
  selectZoomValue,
  setUser,
  setSelectedDate,
  setClient,
} from '../../../utility_objects/StoreFunctions';
import { UserSelectedFiltersState } from './interface';

const initialState: UserSelectedFiltersState = {
  view: getCookie('userCalendarView') || 'Day',
  selectedDate: new Date(),
  selectedServices: [],
  selectedStudi: [],
  selectedUsers: [],
  zoom: {
    value: 150,
    interval: 60,
    slotCount: 4,
  },
  zoomValue: 150,
};

const UserCalendarSlice = createSlice({
  name: 'BluBookingUserCalendar',
  initialState,
  reducers: {
    UserCalendarSetView: (
      state: UserSelectedFiltersState,
      action: PayloadAction<'Day' | 'TimelineDay'>
    ) => {
      state.view = action.payload;
      setCookie('userCalendarView', action.payload);
    },

    UserCalendarSetSelectedDate: setSelectedDate,
    UserCalendarSelectZoomValue: selectZoomValue,
    // ? ADD
    UserCalendarSelectPointOfSale: selectPointOfSale,
    UserCalendarSetService: selectService,
    UserCalendarSelectStudio: selectStudio,
    UserCalendarSetUser: setUser,
    UserCalendarSetClient: setClient,
    // ? REMOVE
    UserCalendarRemoveStudio: removeStudio,
    UserCalendarRemoveService: removeService,
    UserCalendarRemovePointOfSale: removePointOfSale,

    UserCalendarResetSelected: (
      state: UserSelectedFiltersState,
      action: PayloadAction<string>
    ) => {
      return { ...state, [action.payload]: [] };
    },
    UserCalendarReset: () => initialState,
  },
});

export const {
  UserCalendarSetSelectedDate,
  UserCalendarSelectZoomValue,
  UserCalendarSelectPointOfSale,
  UserCalendarSetService,
  UserCalendarSelectStudio,
  UserCalendarRemoveStudio,
  UserCalendarRemoveService,
  UserCalendarRemovePointOfSale,
  UserCalendarResetSelected,
  UserCalendarSetUser,
  UserCalendarSetClient,
  UserCalendarReset,
  UserCalendarSetView,
} = UserCalendarSlice.actions;

export default UserCalendarSlice.reducer;
