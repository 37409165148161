interface WhiteCacheInterface {
  pointOfSaleId?: string;
  isBluBookingActive: boolean;
  path: string;
  isFocusWeb: boolean;
  isFocus10: boolean;
  isConsole: boolean;
  isCrm: boolean;
  software: 'fw' | 'f10' | 'bb' | 'crm';
}

let usersWhiteCache: WhiteCacheInterface = {
  isBluBookingActive: true,
  path: '/api',
  isFocusWeb: false,
  isFocus10: false,
  isConsole: false,
  isCrm: false,
  software: 'bb',
};

export const getUsersWhiteCache = () => usersWhiteCache;
export const setUsersWhiteCache = (value: WhiteCacheInterface) => {
  usersWhiteCache = value;
};

export const setKeyUsersWhiteCache = (
  key: keyof WhiteCacheInterface,
  value: any
) => {
  (usersWhiteCache[key] as any) = value;
};

export const getUsersPath = () => usersWhiteCache.path;
