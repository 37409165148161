/* eslint-disable @typescript-eslint/no-explicit-any */
import { Stack } from '@mui/material';
import dayjs from 'dayjs';
import 'dayjs/locale/it';
import { memo, useCallback, useMemo } from 'react';
import {
  ServiceFilter,
  CalendarDaySelection,
  UsersFilter,
} from '../../../UI/AppointmentFilters';
import { RapidFunctions } from '../../../UI/AppointmentFilters/RapidFunctions';
import { Zoom } from '../../../UI/AppointmentFilters/Zoom';
import { ZoomItem } from '../../../interfaces/Zoom';
import { AppDispatch, RootState } from 'packages/blu-booking/src/store';
import { useDispatch, useSelector } from 'react-redux';
import {
  PersonalCalendarResetSelected,
  PersonalCalendarSelectZoomValue,
  PersonalCalendarSetSelectedDate,
  PersonalCalendarSetService,
  PersonalCalendarSetUser,
} from '../store';
import {
  BluContainerBlock,
  BludataRightSideSectionMenu,
  BluSyncDatePicker,
} from '@bludata/components';
import { AppointmentCompleteInterface } from 'packages/blu-booking/src/interfaces/AppointmentComplete';
import { setRefetch } from '../../../store/Appointment';

export const Filters = memo(function Drawer(): JSX.Element {
  const {
    selectedServices,
    selectedPointOfSale,
    selectedDate,
    selectedUsers,
    view,
    zoomValue,
  } = useSelector((state: RootState) => state.bluBookingPersonalCalendar);
  const { users } = useSelector(
    (state: RootState) => state.bluBookingCalendarFilters
  );
  const dispatch = useDispatch<AppDispatch>();

  const getRange = useCallback((): [number, dayjs.ManipulateType] => {
    switch (view) {
      case 'Month':
        return [1, 'months'];
      case 'Week':
        return [1, 'week'];
      case '3Days':
        return [3, 'days'];
      default:
        return [1, 'days'];
    }
  }, [view]);

  const CalendarSelection = useMemo(() => {
    return (
      <CalendarDaySelection
        move={getRange()}
        selectedDate={selectedDate}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setSelectedDate={(value: any) =>
          dispatch(PersonalCalendarSetSelectedDate(value))
        }
      />
    );
  }, [getRange, selectedDate, dispatch]);

  const handlePosition = useCallback(
    (appointment: AppointmentCompleteInterface) => {
      const d = dayjs(selectedDate).format('DD/MM/YYYY');
      const appointmentDate = dayjs(appointment?.startTime);

      if (
        appointment?.user &&
        appointment.user?.id &&
        users &&
        appointment.user.id in users
      )
        dispatch(PersonalCalendarSetUser([users[appointment?.user.id]]));

      if (selectedServices.length > 0)
        dispatch(PersonalCalendarResetSelected('selectedServices'));

      if (d !== appointmentDate.format('DD/MM/YYYY'))
        dispatch(PersonalCalendarSetSelectedDate(appointmentDate.toDate()));
      else dispatch(setRefetch());
    },
    [dispatch, selectedDate, selectedServices.length, users]
  );

  return (
    <BludataRightSideSectionMenu
      sx={{ mt: 0.5, mr: 0.5, mb: 0.5, pr: 0, height: '100%' }}
    >
      <Stack
        flexDirection="column"
        flex={1}
        sx={{ width: 250, height: '100%', p: '8px', pr: '3px' }}
      >
        {CalendarSelection}
        <BluSyncDatePicker
          date={selectedDate}
          onChange={(event: any) =>
            dispatch(PersonalCalendarSetSelectedDate(event.target.value))
          }
        />
        <Stack
          flexDirection="column"
          flex={1}
          sx={{ overflowY: 'auto', overflowX: 'hidden' }}
          spacing={1}
        >
          <ServiceFilter
            selectedService={selectedServices}
            pointOfSaleId={selectedPointOfSale?.id}
            addService={(value: any) =>
              dispatch(PersonalCalendarSetService(value))
            }
            resetService={() =>
              dispatch(PersonalCalendarResetSelected('selectedServices'))
            }
          />

          <UsersFilter
            selectedUsers={selectedUsers}
            multiple={false}
            addUsers={(value: any) => dispatch(PersonalCalendarSetUser(value))}
            resetUsers={() =>
              dispatch(PersonalCalendarResetSelected('selectedUsers'))
            }
          />
          {selectedPointOfSale && (
            <RapidFunctions
              pointOfSaleId={selectedPointOfSale?.pointOfSaleId}
              handlePosition={handlePosition}
            />
          )}
        </Stack>
        <Zoom
          value={zoomValue}
          change={(value: ZoomItem) => PersonalCalendarSelectZoomValue(value)}
        />
      </Stack>
    </BludataRightSideSectionMenu>
  );
});
