/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Typography } from '@mui/material';
import { memo, useCallback, useDeferredValue, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { BluAutoComplete } from '@bludata/components';
import { usePointOfSalesFiltered } from 'packages/blu-booking/src/data_fetching/PointOfSale/PointOfSalesFilter';
import { RootState } from 'packages/blu-booking/src/store';

export const PointOfSale = memo(() => {
  const { getValues, setValue, reset, trigger } = useFormContext();

  const serviceId = useWatch({ name: 'serviceId' });
  const userId = useWatch({ name: 'userId' });
  const pos = useWatch({ name: 'pointOfSaleId' });
  const shopSignId = useWatch({ name: 'shopSignId' });
  const { pointOfSales } = useSelector(
    (state: RootState) => state.bluBookingCalendarFilters
  );

  // ? Call for GET point of sale associated
  const { data: result, isLoading } = usePointOfSalesFiltered({
    lockService: true,
    lockUser: true,
    serviceId,
    userId,
    shopSignId,
  });

  const data = useDeferredValue(result);
  useEffect(() => {
    if (data === undefined) return;
    if (data.length === 0) return;

    const find = data.find((posl) => posl.id === pos);
    if (find === undefined) {
      reset({
        ...getValues(),
        pointOfSaleId: '',
        studioId: '',
      });
    }
  }, [data, getValues, pointOfSales, pos, reset, shopSignId]);

  const getData = useCallback(() => {
    return data;
  }, [data]);

  const handleOnChange = useCallback(
    (_: any, value: any) => {
      if (value === null) {
        setValue('pointOfSaleId', '');

        reset({
          ...getValues(),
          studioId: '',
        });
        trigger();
        return;
      }
      setValue('pointOfSaleId', value.id);

      return;
    },
    [setValue, reset, getValues, trigger]
  );

  return (
    <PointOfSaleInner
      watch={pos}
      data={getData()}
      isLoading={isLoading}
      handleOnChange={handleOnChange}
    />
  );
});

interface PointOfSaleInnerProps {
  watch: any;
  isLoading: boolean;
  data: any;
  handleOnChange: (event: any, value: any) => void;
}

const PointOfSaleInner = memo(
  ({ watch, isLoading, data, handleOnChange }: PointOfSaleInnerProps) => {
    const { pointOfSales }: { [x: string]: any } = useSelector(
      (state: RootState) => state.bluBookingCalendarFilters
    );

    const getValue = useCallback(
      () =>
        pointOfSales !== undefined && watch in pointOfSales
          ? pointOfSales[watch]
          : null,
      [pointOfSales, watch]
    );
    return (
      <div style={{ width: '49%' }}>
        <Typography variant="subtitle2">Punto vendita</Typography>
        <BluAutoComplete
          value={getValue()}
          data={data}
          width="49%"
          textField={{
            placeholder: 'Seleziona un punto vendita',
          }}
          onChange={(event: any, value: any) => handleOnChange(event, value)}
          loading={isLoading}
          renderOption={(props: any, option: any) => (
            <Box component="li" {...props}>
              {option.branch}
            </Box>
          )}
          // disableCloseOnSelect
          isOptionEqualToValue={(option: any, value: any) =>
            option.id === value.id
          }
          getOptionLabel={(option: any) => `${option.branch}`}
        />
      </div>
    );
  }
);
