import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import {
  NotificationTypeEnum,
  MessageTypeEnum,
  MessageStatusEnum,
  NotificationsInterface,
} from '../../interfaces/Notifications';
import dayjs from 'dayjs';

const getHistoryMessage = async (
  pageSize: number,
  pageNumber: number,
  externalCustomerCode?: string,
  email?: string,
  notificationType?: NotificationTypeEnum,
  messageType?: MessageTypeEnum,
  messageStatus?: MessageStatusEnum,
  fromToday?: boolean
): Promise<NotificationsInterface | undefined> => {
  try {
    const {
      data: { data },
    } = await axios.get('api/HistoryMessage', {
      params: {
        pageSize,
        pageNumber,
        externalCustomerCode,
        email,
        notificationType: notificationType,
        messageType: messageType,
        messageStatus: messageStatus,
        startDate: fromToday ? dayjs().format('YYYY-MM-DD') : undefined,
      },
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dati = (data?.items ?? []).map((message: any) => {
      return {
        ...message,
        notificationType: NotificationTypeEnum[message.notificationType],
        messageType: MessageTypeEnum[message.messageType],
        messageStatus: MessageStatusEnum[message.messageStatus],
        ...(message?.appointment ?? {}),
        createdAt: new Date(message.createdAt),
        id: message.id,
        appointmentId: message?.appointment
          ? message?.appointment.id
          : undefined,
      };
    });

    return { ...data, items: dati };
  } catch (error) {
    return undefined;
  }
};

export const useHistoryMessage = ({
  pageSize = 25,
  pageNumber = 1,
  externalCustomerCode,
  email,
  notificationType,
  messageType,
  messageStatus,
  fromToday,
}: {
  pageSize?: number;
  pageNumber?: number;
  externalCustomerCode?: string;
  email?: string;
  notificationType?: NotificationTypeEnum;
  messageType?: MessageTypeEnum;
  messageStatus?: MessageStatusEnum;
  fromToday: boolean;
}) => {
  return useQuery({
    queryFn: async () =>
      await getHistoryMessage(
        pageSize,
        pageNumber,
        externalCustomerCode,
        email,
        notificationType,
        messageType,
        messageStatus,
        fromToday
      ),
    queryKey: [
      'history-message',
      pageSize,
      pageNumber,
      externalCustomerCode,
      email,
      notificationType,
      messageType,
      messageStatus,
      fromToday,
    ],
    retry: 2,
    retryDelay: 180000,
    cacheTime: 0,
    staleTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
};
