import { Badge, Button, Stack } from '@mui/material';
import { FC, memo, useMemo } from 'react';
import { AppointmentCompleteInterface } from 'packages/blu-booking/src/interfaces/AppointmentComplete';
import { AppointmentNotificationInterface } from 'packages/blu-booking/src/interfaces/AppointmentNotification';
import { RootState, AppDispatch } from 'packages/blu-booking/src/store';
import { useSelector, useDispatch } from 'react-redux';
import { setAppointmentsWithErrorsPopupOpen } from '../../store/Calendar';
import { AppointmentsWithErrorsPopupInner } from './AppointmentsWithErrorsPopupInner';

interface AppointmentsWithErrorsPopupInterface {
  notifications: AppointmentNotificationInterface;
  handlePosition: (appointment: AppointmentCompleteInterface) => void;
}

export const AppointmentsWithErrorsPopup: FC<AppointmentsWithErrorsPopupInterface> =
  memo(({ notifications, handlePosition }) => {
    const { appointmentsWithErrorsPopupOpen } = useSelector(
      (state: RootState) => state.bluBookingCalendars
    );
    const dispatch = useDispatch<AppDispatch>();

    const GetPopup = useMemo(() => {
      if (!appointmentsWithErrorsPopupOpen) return null;
      return (
        <AppointmentsWithErrorsPopupInner handlePosition={handlePosition} />
      );
    }, [appointmentsWithErrorsPopupOpen, handlePosition]);

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return (
      <>
        {GetPopup}

        <Button
          variant="outlined"
          onClick={() => dispatch(setAppointmentsWithErrorsPopupOpen(true))}
          // disabled={(data?.notConfirmedCount ?? 0) < 1}
        >
          <Stack
            direction="row"
            gap={1}
            flex="1"
            alignItems="center"
            sx={{ pr: '8px' }}
          >
            <Stack flex="1" sx={{ textAlign: 'left' }}>
              Con errori
            </Stack>
            <Badge
              badgeContent={notifications?.errorCodeCount ?? 0}
              // color={'#E77D22'}
              sx={{
                '& .MuiBadge-badge': {
                  fontWeight: 'bold',
                  fontSize: '15px',
                  minWidth: '40px',
                  lineHeight: '14px',
                  boxShadow:
                    '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)',
                  backgroundColor: 'var(--accent-dark-color)',
                  color: 'white',
                },
              }}
            />
          </Stack>
        </Button>
      </>
    );
  });
