import VisibilityIcon from '@mui/icons-material/Visibility';
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { RootState } from 'packages/blu-booking/src/store';
import { ServiceIconConverter } from 'packages/blu-booking/src/utility_objects/ServiceIconConverter';
import { FC, memo, useMemo } from 'react';
import { useSelector } from 'react-redux';

interface ServiceInterface {
  color?: string;
  serviceId?: string;
  icons?: string[];
}

export const Service: FC<ServiceInterface> = memo(
  ({ color, serviceId, icons = [] }) => {
    const { services } = useSelector(
      (state: RootState) => state.bluBookingCalendarFilters
    );
    const ServiceInner = useMemo(() => {
      if (serviceId !== undefined && color !== undefined) {
        const serviceName = services[serviceId].name;
        return (
          <Stack
            sx={{ width: '100%', p: '2px 0' }}
            flexDirection="row"
            gap="15px"
          >
            <VisibilityIcon
              sx={{
                color:
                  color !== 'white' && color !== '#000000' ? color : 'black',
              }}
            />
            <Stack flex="1" direction="row" gap={1}>
              <Stack flex="1">
                <Typography variant="body1">{serviceName}</Typography>
              </Stack>
              {(icons ?? []).map((value: string) => (
                <ServiceIconConverter name={value} sx={{ fontSize: '18px' }} />
              ))}
            </Stack>
          </Stack>
        );
      }
      return null;
    }, [color, icons, serviceId, services]);

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{ServiceInner}</>;
  }
);
