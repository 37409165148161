import axios from 'axios';
import { Appointment_interface } from '../../interfaces/Interfaces';

export const getAppointmentDetails = async (appointmentId: string) => {
  try {
    const {
      data: { data },
    } = await axios.get<{
      data: Appointment_interface;
    }>(`/api/Appointment/${appointmentId}`);
    if (data === undefined) return undefined;

    return {
      ...data,
      Id: data.id,
      Subject: `${data.surname || ''} ${data.name || ''} `,
      Location: '',
      StartTime: data.startTime,
      EndTime: data.endTime,
      CategoryColor: '#1976d2',
    };
  } catch {
    return undefined;
  }
};
