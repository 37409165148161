import { Link, Paper, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import './login.css';
import '../../components/footer/footer.css';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SessionExpiredIcon from '../../assets/sessione_scaduta.svg';

export interface LoginForm {
  identificationCode: string;
  username: string;
  password: string;
}

const SessionExpired = () => {
  const onSubmit = () => {
    /*

      identificationCode = 1595A-18B4LI
      Username = Pierantonio
      Pass = 12345678

    */
    sessionStorage.clear();
    window.location.reload();
    // dispatch(setSessionExpired(false));

    //dispatch(setAccessToken(""));
    // dispatch(
    //   setLoginData({
    //     username: "Nome utente",
    //     password: "12345678",
    //     identificationCode: "",
    //   })
    // );
  };

  return (
    <Stack
      flex={1}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <div className="background-layer"></div>
      <Paper
        elevation={1}
        sx={{
          width: '450px',
          height: '300px',
          zIndex: 10,
          backgroundColor: 'white',
          position: 'relative',
        }}
      >
        <Stack
          flexDirection="column"
          sx={{
            position: 'absolute',
            top: '20px',
            left: '20px',
            right: '20px',
            bottom: '20px',
          }}
        >
          <div style={{ height: '100px', width: '100%', textAlign: 'center' }}>
            <SessionExpiredIcon />
          </div>
          <Typography
            sx={{ textAlign: 'center' }}
            variant="h5"
            fontWeight="bold"
          >
            Sessione Scaduta
          </Typography>
          <Typography
            sx={{ marginTop: '25px', textAlign: 'center', mb: 1 }}
            fontWeight="bold"
          >
            La sessione di lavoro è scaduta, è necessario riaccedere alla
            console BluBooking per continuare le operazioni.
          </Typography>
          <Link
            sx={{
              marginTop: '25px',
              textAlign: 'center',
              textDecoration: 'none',
              height: 'fit-content',
              cursor: 'pointer',
            }}
            fontWeight="bold"
            onClick={onSubmit}
          >
            <ArrowForwardIcon sx={{ fontSize: '22px', fontWeight: 'bolder' }} />{' '}
            <span
              style={{
                verticalAlign: 'top',
                height: 'fit-content',
                boxSizing: 'border-box',
                // marginTop: "2px",
              }}
            >
              CONTINUA
            </span>
          </Link>
        </Stack>
      </Paper>
    </Stack>
  );
};

export default SessionExpired;
