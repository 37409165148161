import {
  Box,
  Collapse,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material';
import { memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { TreeListItemProps } from './interface';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {
  setAuthenticated,
  setOpenPasswordPopUp,
  setSelectedPage,
  setTreOpen,
} from '../../store/root';
import { useNavigate } from 'react-router-dom';
import { Else, If, Then } from 'react-if';
import { ChildMenu } from './child';
import BludataPasswordRequired from '../../UI/components/BludataPasswordRequired';

export const ParentMenu = memo(
  ({ name, title, icon, protect, children }: TreeListItemProps) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {
      selectedPage,
      treeItemList,
      menuOpened,
      openPasswordPopUp,
      disabledMenu,
      isAdmin,
      isAuthenticated,
    } = useSelector((state: RootState) => state.root);

    const handleClick = useCallback(() => {
      if (!protect) {
        dispatch(setAuthenticated(false));
      }
      if (!disabledMenu) {
        if (protect) {
          if (!treeItemList[name]) {
            dispatch(setOpenPasswordPopUp(true));
            return;
          }
        }
        if (children !== undefined) dispatch(setTreOpen(name));

        if (title === 'Agenda') {
          const nav = localStorage.getItem('BluBookingCalendar') ?? 'calendar';
          navigate(`/${nav}`, {
            replace: true,
          });
          dispatch(setSelectedPage(nav));
        } else {
          navigate(`/${name}`, {
            replace: true,
          });
          dispatch(setSelectedPage(name));
        }
      }
    }, [
      children,
      disabledMenu,
      dispatch,
      name,
      navigate,
      protect,
      title,
      treeItemList,
    ]);

    const isSelected = useCallback(() => {
      if (menuOpened) return false;
      if (selectedPage === name) return true;
      if (children === undefined) return false;
      return children.find((child) => child.id === selectedPage) !== undefined;
    }, [children, menuOpened, name, selectedPage]);

    const Expand = useMemo(() => {
      if (!menuOpened) return <></>;
      if (children === undefined) return <Box sx={{ width: '24px' }}></Box>;
      if (treeItemList[name]) return <ExpandLess color="primary" />;
      else return <ExpandMore color="primary" />;
    }, [children, menuOpened, name, treeItemList]);

    const Icon = useMemo(() => {
      return (
        <ListItemIcon
          sx={{
            minWidth: 'fit-content',
            mr: menuOpened ? '15px' : '0px',
            ml: menuOpened ? '0px' : '8px',
          }}
        >
          {icon}
        </ListItemIcon>
      );
    }, [icon, menuOpened]);

    const Text = useMemo(() => {
      if (!menuOpened) return <></>;
      return (
        <Typography
          variant="subtitle1"
          sx={{
            color: 'var(--base-color)',
            fontWeight: 'normal !important',
            fontSize: '18px !important',
            lineHeight: '20px !important',
          }}
        >
          {title}
        </Typography>
      );
    }, [menuOpened, title]);

    const handlePasswordOk = useCallback(() => {
      dispatch(setTreOpen(name));
      dispatch(setOpenPasswordPopUp(false));
      if (children === undefined) {
        navigate(`/${name}`, {
          replace: true,
        });
        dispatch(setSelectedPage(name));
      }
      const child = children![0];
      navigate(`/${child.id}`, {
        replace: true,
      });
      dispatch(setSelectedPage(child.id));
    }, [children, dispatch, name, navigate]);

    const isDisabled = useCallback(() => {
      if (protect && !isAdmin) return true;
      return disabledMenu;
    }, [disabledMenu, isAdmin, protect]);

    return (
      <ParentWrapper
        menuOpened={menuOpened}
        treeOpen={treeItemList[name]}
        treeItems={children}
        disabled={isDisabled()}
        protect={protect}
        isAuthenticated={isAuthenticated}
        title={title}
      >
        <>
          {protect && (
            <BludataPasswordRequired
              show={openPasswordPopUp}
              handleClose={() => dispatch(setOpenPasswordPopUp(false))}
              handleConfirm={handlePasswordOk}
            />
          )}
          <ListItem
            disablePadding
            onClick={!isDisabled() ? handleClick : () => null}
            selected={isSelected()}
            // onClick={() => {
            //   if (item.protect) {
            //     protectedAction(item.name);
            //   } else {
            //     if (item.children) {
            //       handleExpandConfigurazione();
            //     } else {
            //       action(item.name);
            //     }
            //   }
            // }}
          >
            <ListItemButton
              style={{ padding: '7px 15px' }}
              disabled={isDisabled()}
            >
              {Expand}
              {Icon}
              {Text}
            </ListItemButton>
          </ListItem>
        </>
      </ParentWrapper>
    );
  }
);

interface ParentWrapperInterface {
  menuOpened: boolean;
  treeOpen: boolean;
  treeItems: any;
  children: JSX.Element;
  disabled: boolean;
  protect: boolean;
  isAuthenticated: boolean;
  title: string;
}

const ParentWrapper = memo(
  ({
    menuOpened,
    treeOpen,
    children,
    treeItems,
    disabled,
    protect,
    isAuthenticated,
    title,
  }: ParentWrapperInterface) => {
    const GetTreItems = useMemo(() => {
      return (
        <>
          {treeItems.map((item: any, index: number) => (
            <ChildMenu key={`menuRamo${index}`} {...item} protect={protect} />
          ))}
        </>
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [treeItems]);

    const menuOpenView = useMemo(() => {
      return (
        <>
          {children}
          <Collapse in={treeOpen} timeout={'auto'} unmountOnExit>
            {GetTreItems}
          </Collapse>
        </>
      );
    }, [GetTreItems, children, treeOpen]);

    const menuCloseView = () => {
      return (
        <>
          {protect && !isAuthenticated ? (
            <div>{children}</div>
          ) : (
            <Tooltip
              placement="right-end"
              disableHoverListener={disabled}
              disableTouchListener={disabled}
              componentsProps={{
                popper: {
                  sx: {
                    '& .MuiTooltip-tooltip': {
                      m: '1px !important',
                      p: '0',
                    },
                  },
                },
              }}
              title={<Paper style={{ padding: '5px' }}>{GetTreItems}</Paper>}
            >
              <div>{children}</div>
            </Tooltip>
          )}
        </>
      );
    };
    return (
      <If condition={menuOpened}>
        <Then>{menuOpenView}</Then>
        <Else>{menuCloseView}</Else>
      </If>
    );
  }
);
