/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Button, Stack, Typography } from '@mui/material';
import moment, { Moment } from 'moment';
import { memo, useCallback, useMemo, useState } from 'react';
import { If, Then } from 'react-if';
import { HourPicker } from './HourPicker';
import { WeekPicker } from './WeekPicker';
import { useFormContext, useWatch } from 'react-hook-form';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useSelector } from 'react-redux';
import { RootState } from 'packages/blu-booking/src/store';

export const CercaDisponibilitaButton = memo(() => {
  const { setValue } = useFormContext();
  const service = useWatch({ name: 'serviceId' });
  const pos = useWatch({ name: 'pointOfSaleId' });
  const searchDisponibility = useWatch({ name: 'searchDisponibility' });
  const { lockService, lockUser, lockPointOfSale, lockStudio } = useSelector(
    (state: RootState) => state.bluBookingAppointmentForm
  );
  const disableSlotButton = useCallback(() => {
    return (
      service === '' ||
      pos === '' ||
      !lockStudio ||
      !lockUser ||
      !lockPointOfSale ||
      !lockService
    );
  }, [lockPointOfSale, lockService, lockStudio, lockUser, pos, service]);

  return (
    <Button
      variant="contained"
      sx={{
        height: '30+3px',
        width: '100%',
        mt: '10px',
      }}
      disabled={disableSlotButton()}
      onClick={() => setValue('searchDisponibility', !searchDisponibility)}
    >
      <Stack
        flexDirection="row"
        justifyContent="center"
        style={{ width: '100%', textAlign: 'center' }}
      >
        Cerca disponibilità
        {!searchDisponibility ? (
          <ArrowForwardIcon
            sx={{
              pt: '2px',
              pl: '5px',
            }}
          />
        ) : (
          <ArrowDownwardIcon
            sx={{
              pt: '2px',
              pl: '5px',
            }}
          />
        )}
      </Stack>
    </Button>
  );
});

export const CercaDisponibilita = memo(function DatePicker(): JSX.Element {
  const [searchDisponibility, timeSlotDate] = useWatch({
    name: ['searchDisponibility', 'timeSlotDate'],
  });
  let date = moment(timeSlotDate);
  let firstDayOfWeek = moment(timeSlotDate).weekday(0);
  if (date.isBefore(moment())) {
    date = moment();
    firstDayOfWeek = moment();
  }
  if (firstDayOfWeek.isBefore(moment())) {
    firstDayOfWeek = moment();
  }
  const [selectedDate, setSelectedDate] = useState<string>(
    // moment().format("YYYY/MM/DD")

    date.format('YYYY/MM/DD')
  );

  const [startDate, setStartDate] = useState<Moment>(firstDayOfWeek);

  const GetSlotTime = useMemo(() => {
    if (searchDisponibility)
      return (
        <>
          <div style={{ height: '20px' }} />
          <Box
            sx={{
              width: '90%',
              maxWidth: '80vw',
              border: '1px solid rgba(187, 187, 187, 1)',
              borderRadius: '4px',
              margin: 'auto',
            }}
          >
            <>
              <WeekPickerOuter
                selectedDate={selectedDate}
                setSelectedDate={(value: string) => setSelectedDate(value)}
                startDate={startDate}
                setStartDate={(value: Moment) => setStartDate(value)}
              />
              <DayTitle selectedDate={selectedDate} />
              <HourPicker
                selectedDate={selectedDate}
                setSelectedDate={(value: string) => setSelectedDate(value)}
              />
            </>
          </Box>
          <div style={{ height: '20px' }} />
        </>
      );
    return null;
  }, [searchDisponibility, selectedDate, startDate]);
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{GetSlotTime}</>;
});

const DayTitle = memo(function DayTitle({
  selectedDate,
}: {
  selectedDate: string;
}): JSX.Element {
  return (
    <div className="selected-date">
      <Typography>
        {moment(selectedDate, 'YYYY/MM/DD').format('dddd DD MMMM YYYY')}
      </Typography>
    </div>
  );
});

interface WeekPickerOuterInterface {
  selectedDate: string;
  setSelectedDate: (value: string) => void;
  startDate: Moment;
  setStartDate: (value: Moment) => void;
}

const WeekPickerOuter = memo(function WeekPickerOuter({
  selectedDate,
  setSelectedDate,
  startDate,
  setStartDate,
}: WeekPickerOuterInterface): JSX.Element {
  // const width = useAppSelector(responsiveWidth);

  // const getRange = useCallback((): number => {
  //   if (width > 600) return 6;
  //   else if (width > 500) return 5;
  //   else if (width > 400) return 4;
  //   else if (width > 300) return 3;
  //   else return 2;
  // }, [width]);

  const Week = useMemo(() => {
    return (
      <WeekPicker
        range={6}
        selectedDate={selectedDate}
        setSelectedDate={(value: string) => setSelectedDate(value)}
        startDate={startDate}
        setStartDate={(value: Moment) => setStartDate(value)}
      />
    );
  }, [selectedDate, setSelectedDate, setStartDate, startDate]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{Week}</>;
});
