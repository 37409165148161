/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useRef } from 'react';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import {
  DataGridPremium,
  GridRowId,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import { RootState } from 'packages/blu-booking/src/store';
import { useAppointmentsClient } from 'packages/blu-booking/src/data_fetching/Appointment/AppointmentsClient';
import { appointmentStatus } from '../../utility_objects/AppointmentStatus';

interface SelectAppointmentInterface {
  action: any;
  unregistered: boolean;
  keyDown?: string;
  setKeyDown?: (value: string) => void;
  enterAction?: (value: any) => void;
}

export const SelectAppointment = memo(
  ({
    action,
    unregistered,
    enterAction,
    keyDown,
    setKeyDown,
  }: SelectAppointmentInterface) => {
    const { clientSelected } = useSelector(
      (state: RootState) => state.bluBookingCalendars
    );
    const { services } = useSelector(
      (state: RootState) => state.bluBookingCalendarFilters
    );
    const { data, isLoading } = useAppointmentsClient({
      name: clientSelected?.name,
      surname: clientSelected?.surname,
      email: clientSelected?.email,
      telephone: clientSelected?.telephone,
      externalCustomerCode: clientSelected?.externalCustomerCode,
      unregistered,
    });

    const apiRef = useGridApiRef();

    useEffect(() => {
      if (data && data.length > 0) {
        const selectedRowIds = Array.from(
          apiRef.current.getSelectedRows().keys()
        );
        const selectedRowId = selectedRowIds[0];

        const currentRowIndex = data.findIndex(
          (row) => row?.id === selectedRowId
        );
        // if (boxRef?.current) (boxRef.current as any)?.focus();

        if (currentRowIndex === -1) {
          apiRef.current.setCellFocus(data[0]?.id, 'serviceId');
          apiRef.current.selectRow(data[0]?.id);
          apiRef.current.setSelectionModel([data[0]?.id]);
        }
      }
    }, [apiRef, data]);

    const gestioneSelezioneArrows = useCallback(
      (id: GridRowId, next: boolean) => {
        if (data) {
          if (next) {
            const currentRowIndex = data.findIndex((row) => row?.id === id);

            if (currentRowIndex < data.length - 1) {
              const newSelectedRowId = data[currentRowIndex + 1]?.id;

              apiRef.current.selectRow(newSelectedRowId);
              apiRef.current.setSelectionModel([newSelectedRowId]);
            }
          } else {
            const currentRowIndex = data.findIndex((row) => row?.id === id);
            if (currentRowIndex > 0) {
              const newSelectedRowId = data[currentRowIndex - 1]?.id;
              apiRef.current.selectRow(newSelectedRowId);
              apiRef.current.setSelectionModel([newSelectedRowId]);
            }
          }
        }
      },
      [apiRef, data]
    );

    useEffect(() => {
      if (keyDown) {
        const selectedRowIds = Array.from(
          apiRef.current.getSelectedRows().keys()
        );
        const selectedRowId = selectedRowIds[0];

        if (keyDown === 'Enter') {
          setKeyDown(undefined);
          const selectedRow = apiRef.current.getRow(selectedRowId);
          enterAction(selectedRow);
        } else if (keyDown === 'ArrowUp') {
          gestioneSelezioneArrows(selectedRowId, false);
          // Handle arrow up key press
        } else if (keyDown === 'ArrowDown') {
          // Handle arrow down key press
          gestioneSelezioneArrows(selectedRowId, true);
        }
        setKeyDown(undefined);
      }
    }, [apiRef, enterAction, gestioneSelezioneArrows, keyDown, setKeyDown]);

    return (
      <div>
        <div>
          <Typography variant="subtitle2" sx={{ p: '3px 0px' }}>
            {`Scegli un appuntamento di ${clientSelected?.name} ${clientSelected?.surname}`}
          </Typography>
          <div style={{ height: '20px' }} />
          <DataGridPremium
            apiRef={apiRef}
            hideFooter
            disableMultipleSelection
            loading={isLoading}
            columns={[
              {
                field: 'id',
                hide: true,
              },

              {
                field: 'serviceId',
                headerName: 'Tipo di appuntamento',
                flex: 1,
                editable: false,
                valueFormatter: (params: any) =>
                  services && params?.value in services
                    ? services[params?.value]?.name ?? ''
                    : '',
              },
              {
                field: 'date',
                headerName: 'Data',
                width: 100,
              },
              {
                field: 'startTime',
                headerName: 'Ora inizio',
                width: 100,
                valueFormatter: (params: any) =>
                  dayjs(params?.value).format('HH:mm'),
              },
              {
                field: 'endTime',
                headerName: 'Ora fine',
                width: 100,
                valueFormatter: (params: any) =>
                  dayjs(params?.value).format('HH:mm'),
              },
              {
                field: 'status',
                headerName: 'Stato',
                width: 100,
                valueFormatter: (params: any) =>
                  appointmentStatus[params?.value],
              },
            ]}
            rows={data ?? []}
            sx={{
              height: '290.5px',
              '& .MuiDataGrid-row': {
                cursor: 'pointer',
                minHeight: '50px !important',
              },
            }}
            density="compact"
            disableColumnMenu
            editMode="cell"
            getRowHeight={() => 'auto'}
            onProcessRowUpdateError={(error) => console.log(error)}
            experimentalFeatures={{ newEditingApi: true }}
            onRowClick={(event: any) => {
              action(event?.row);
            }}
          />
          <div style={{ height: '10px' }} />
        </div>
      </div>
    );
  }
);
