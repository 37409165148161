import { BluDialog } from '@bludata/components';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material';
import { AppDispatch, RootState } from 'packages/blu-booking/src/store';
import React, { useMemo, useState } from 'react';
import { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAppointmentsIndex, setRefetch } from '../../store/Appointment';
import { setEmailPopup } from '../../store/Calendar';
import { setOpenLoader } from 'packages/blu-booking/src/store/root';
import { appointmentDelete } from 'packages/blu-booking/src/data_fetching/Appointment/AppointmentDelete';
import { appointmentUpdate } from 'packages/blu-booking/src/data_fetching/Appointment/AppointmentUpdate';

export const EmailPopUp = memo(() => {
  const { emailPopup } = useSelector(
    (state: RootState) => state.bluBookingCalendars
  );
  const dispatch = useDispatch<AppDispatch>();
  const [sendMail, setSendMail] = useState<boolean>(true);
  const { pointOfSales } = useSelector(
    (state: RootState) => state.bluBookingCalendarFilters
  );

  const cancel = useCallback(() => {
    if (emailPopup?.type !== 'delete' && emailPopup?.prevData) {
      dispatch(
        setAppointmentsIndex({
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          index: emailPopup?.findIndex as any,
          appointment: emailPopup?.prevData,
        })
      );
    }
    dispatch(setEmailPopup(undefined));
    setSendMail(true);
  }, [dispatch, emailPopup?.findIndex, emailPopup?.prevData, emailPopup?.type]);

  const deleteAction = useCallback(async () => {
    dispatch(setEmailPopup(undefined));
    dispatch(setOpenLoader(true));
    const result = await appointmentDelete(emailPopup?.id ?? '', sendMail);
    dispatch(setOpenLoader(false));
    if (result) {
      dispatch(setRefetch());
      setSendMail(true);
    }
  }, [dispatch, emailPopup?.id, sendMail]);

  const modifyAction = useCallback(async () => {
    dispatch(setEmailPopup(undefined));
    dispatch(setOpenLoader(true));
    if (emailPopup?.appointment !== undefined) {
      const result = await appointmentUpdate(emailPopup?.id ?? '', {
        ...emailPopup?.appointment,
        hasToSendEmail: sendMail,
        timeSlotDate: emailPopup?.appointment.StartTime,
        start: emailPopup?.appointment.StartTime,
        end: emailPopup?.appointment.EndTime,
      });
      dispatch(setOpenLoader(false));
      if (result) {
        dispatch(setRefetch());
        setSendMail(true);
      }
    }
  }, [dispatch, emailPopup?.appointment, emailPopup?.id, sendMail]);

  const getTitle = useCallback(() => {
    if (emailPopup?.type === 'delete')
      return 'Conferma cancellazione appuntamento';
    if (emailPopup?.type === 'dragdrop') return 'Conferma sposta appuntamento';
    return 'Conferma modifica appuntamento';
  }, [emailPopup?.type]);

  const getContent = useCallback(() => {
    if (emailPopup?.type === 'delete')
      return "Sei sicuro di voler cancellare l'appuntamento?";
    if (emailPopup?.type === 'dragdrop')
      return "Sei sicuro di voler spostare l'appuntamento?";
    return "Sei sicuro di voler modificare l'appuntamento?";
  }, [emailPopup?.type]);

  const getButtonContent = useCallback(() => {
    if (emailPopup?.type === 'delete') return 'Si, cancella appuntamento';
    if (emailPopup?.type === 'create') return 'Si, Sposta appuntamento';
    return 'Si, modifica appuntamento';
  }, [emailPopup?.type]);

  const CheckInvioMail = useMemo(() => {
    if (emailPopup === undefined) return;
    let hasLayout = false;
    const { pointOfSaleId, email, telephone, isIMO } = emailPopup.appointment;
    if (pointOfSaleId in pointOfSales) {
      hasLayout = pointOfSales[pointOfSaleId]?.shopSignId !== null;
    }
    if (!hasLayout) setSendMail(false);
    if (
      hasLayout &&
      ((email && email !== '') || (telephone && telephone !== '') || isIMO)
    )
      return (
        <FormControlLabel
          label={<Typography variant="body1">Notifica il cliente</Typography>}
          control={
            <Checkbox
              checked={sendMail}
              onChange={() => setSendMail(!sendMail)}
            />
          }
        />
      );
    return null;
  }, [emailPopup, pointOfSales, sendMail]);
  return (
    <BluDialog
      open={emailPopup !== undefined}
      dialogTitle={getTitle()}
      fullScreen={false}
      draggable
      maxWidth="xs"
      dialogContent={
        <>
          <Typography variant="body1" fontWeight="bold">
            {getContent()}
          </Typography>
          {CheckInvioMail}
        </>
      }
      dialogActions={
        <Stack flexDirection="row" gap="10px" sx={{ width: '100%' }}>
          <div style={{ flex: 1 }}>
            <Button
              variant="outlined"
              sx={{ textAlign: 'center' }}
              onClick={cancel}
            >
              <div style={{ width: '100%', textAlign: 'center' }}>Annulla</div>
            </Button>
          </div>

          <Button
            variant="contained"
            color="primary"
            sx={{ textAlign: 'center' }}
            onClick={() =>
              emailPopup?.type === 'delete' ? deleteAction() : modifyAction()
            }
          >
            <div style={{ width: '100%', textAlign: 'center' }}>
              {getButtonContent()}
            </div>
          </Button>
        </Stack>
      }
      zIndex={500}
    />
  );
});
