import { AppointmentCompleteInterface } from './AppointmentComplete';

export interface NotificationsInterface {
  pageNumber: number;
  pageSize: number;
  totalPages: number;
  totalItems: number;
  items: NotificationsItemInterface[];
}

export interface NotificationsItemInterface
  extends AppointmentCompleteInterface {
  id: string;
  companyId: string;
  appointmentId: string;
  notificationType: NotificationTypeEnum;
  messageType: MessageTypeEnum;
  messageStatus: MessageStatusEnum;
  descriptions: string[];
  createdAt: Date;
}

export enum NotificationTypeEnum {
  'confirmation',
  'modification',
  'reminder',
  'cancellation',
}

export enum MessageTypeEnum {
  'email',
  'SMS',
  'IMO',
}

// ! dove Error va messo in arancione che significa che non ha inviato perche mancano dati;
// ! sending error errore di invio, se è una mail viene fuori nel campo descrizione il codice infobip per l'errore.
export enum MessageStatusEnum {
  'error',
  'sendingerror',
  'success',
  'skipped',
  'pending',
}
