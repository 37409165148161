import { BluAsyncDialog, BluDialog, BluTextField } from '@bludata/components';
import { Button, Divider, Stack } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { FC, memo, useCallback, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { createUserGoogleCalendar } from '../../../../api/create-google-calendar';
import SyncIcon from '@mui/icons-material/Sync';

interface CalendarConfigurationDialogInterface {
  showDialog: boolean;
  setShowDialog: (value: boolean) => void;
}

export const CalendarConfigurationDialog: FC<CalendarConfigurationDialogInterface> =
  memo(({ showDialog, setShowDialog }) => {
    const [mailToSync, setMailToSync] = useState('');
    const [blubookingId, userId, username] = useWatch({
      name: ['blubookingId', 'id', 'username'],
    });
    const queryClient = useQueryClient();

    const handleClick = useCallback(async () => {
      const result = await createUserGoogleCalendar(
        blubookingId || userId,
        mailToSync
      );
      if (result) {
        queryClient.invalidateQueries(['google-calendar-sync'], {
          type: 'active',
        });
        queryClient.removeQueries(['google-calendar-sync'], {
          type: 'inactive',
        });
        setShowDialog(!showDialog);
        return;
      }
      BluAsyncDialog({
        title: 'Attenzione',
        type: 'error',
        message: 'Errore durante la sincronizzazione.',
        hideDeclineButton: true,
        confimButton: 'Chiudi',
        sx: { '& .MuiDialog-paper': { maxWidth: '370px' } },
      });
    }, [
      blubookingId,
      mailToSync,
      queryClient,
      setShowDialog,
      showDialog,
      userId,
    ]);

    return (
      <BluDialog
        open={showDialog}
        maxWidth={'xs'}
        dialogTitle={'Google Calendar'}
        fullScreen={false}
        dialogActions={
          <>
            <Button
              onClick={() => {
                setShowDialog(!showDialog);
              }}
              variant="outlined"
            >
              Annulla
            </Button>
            <Button
              disabled={
                // eslint-disable-next-line no-useless-escape
                !/^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/.test(mailToSync)
              }
              onClick={handleClick}
              autoFocus
              startIcon={<SyncIcon />}
            >
              Invia
            </Button>
          </>
        }
        dialogContent={
          <Stack direction="column">
            Collega il calendario di {username} a Google Calendar e tutti gli
            appuntamenti dell'agenda verranno sincronizzati automaticamente.
            <Divider sx={{ opacity: 0, mb: '24px!important' }} />
            Inserisci l'indirizzo email del personale il cui calendario deve
            essere sincronizzato. Invieremo un'email di configurazione a breve.
            <Divider sx={{ opacity: 0, mb: '24px!important' }} />
            <BluTextField
              value={mailToSync}
              onChange={(e) => {
                setMailToSync(e.target.value);
              }}
            />
          </Stack>
        }
        zIndex={1}
      />
    );
  });
