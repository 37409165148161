import { ToggleButtonGroup, ToggleButton, Box, Stack } from '@mui/material';
import { AppDispatch, RootState } from 'packages/blu-booking/src/store';
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StudioCalendarSetView } from '../store';

export const Header = memo(function Header(): JSX.Element {
  const dispatch = useDispatch<AppDispatch>();
  const { view } = useSelector(
    (state: RootState) => state.bluBookingStudioCalendar
  );

  return (
    <Box
      sx={{
        height: '38px',
        display: 'flex',
        alignItems: 'center',
        m: '2px 4px 6px 4px',
      }}
    >
      <Stack
        flexDirection="row"
        alignItems="center"
        sx={{ width: '100%' }}
        gap="20px"
      >
        <div style={{ flex: 1 }} />
        <ToggleButtonGroup
          exclusive
          value={view}
          onChange={(_, viewType) => {
            if (!viewType) return;
            dispatch(StudioCalendarSetView(viewType));
          }}
        >
          <ToggleButton size="small" value="Day">
            Verticale
          </ToggleButton>
          <ToggleButton size="small" value="TimelineDay">
            Orizzontale
          </ToggleButton>
        </ToggleButtonGroup>
      </Stack>
    </Box>
  );
});
