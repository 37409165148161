import { IconButton, SxProps } from "@mui/material";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { memo } from "react";

interface Props {
  onClick: () => void;
  sx?: SxProps;
}

/**
 * Funzione BludataInfoButton: renderizza un IconButton con un'icona di punto interrogativo.
 * 
 * @param {Props} props - Le proprietà passate al componente.
 * @param {SxProps} props.sx - Gli stili opzionali da applicare al bottone.
 * @param {() => void} props.onClick - La funzione da eseguire al click del bottone.
 */
function BludataInfoButton(props: Props) {
  // Estrazione delle proprietà sx e onClick dalle props
  const { sx, onClick } = props;

  // Renderizzazione del componente IconButton di MUI
  return (
    <IconButton
      size="small" // Imposta la dimensione del bottone a piccola
      sx={{
        backgroundColor: "#f5f5f5", // Colore di sfondo predefinito
        ".dark-mode-bludata &": {
          backgroundColor: "#404040", // Colore di sfondo in modalità dark
        },
        ...sx, // Applicazione degli stili opzionali passati tramite props
      }}
      onClick={onClick} // Assegnazione della funzione onClick
    >
      <QuestionMarkIcon fontSize="small" />
    </IconButton>
  );
}

export default memo(BludataInfoButton);
