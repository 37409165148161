import { Button, Divider, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { FC, memo, useState } from 'react';
import { CustomTextField } from '../../common/CustomTextField';
import { useFormContext } from 'react-hook-form';
import insertAtIndex from '../../../../util/insertAtIndex';
import { BluTitle } from '@bludata/components';
import Kanban from './kanban';

export const NotificationConfig: FC<{
  disabled: boolean;
  software: string;
  hasAgenda: boolean;
  isBBActive: boolean;
}> = memo(({ disabled, software, hasAgenda, isBBActive }) => {
  const { setValue, trigger, watch } = useFormContext();

  const [currentField, setCurrentField] = useState({ field: '', pos: 0 });

  const campiUnion = [
    'Insegna',
    'Negozio',
    'Nome (cliente)',
    'Cognome (cliente)',
    'Nome (utente)',
    'Cognome (utente)',
    'Data',
    'Ora',
    'Durata',
    'Tipo appuntamento',
    'Descrizione tipo appuntamento',
    'Indirizzo',
    'Città',
    'Link modifica appuntamento',
    'Link cancellazione appuntamento',
  ];

  const updateOnButtonClick = (inserStr: string) => {
    const d = document.getElementById(currentField.field);
    if (d === null) return;
    setValue(
      currentField.field,
      insertAtIndex(
        watch(currentField.field),
        inserStr,
        (d as any).selectionStart
      )
    );

    trigger();
  };

  return (
    <Stack
      direction={'column'}
      justifyContent="center"
      alignItems="start"
      gap={1}
    >
      <Stack
        direction={'column'}
        justifyContent="center"
        alignItems="start"
        width={'100%'}
        spacing={2}
      >
        {isBBActive && (
          <>
            <Typography sx={{ mt: 1 }} variant="body2">
              Configurate come i vostri clienti riceveranno notifiche e
              aggiornamenti sulle prenotazioni. Attraverso questa interfaccia,
              potete impostare le preferenze per garantire che nessuna
              comunicazione importante vada persa. Scegliete se i vostri clienti
              non dovrebbero ricevere notifiche o se preferite che le ricevano
              tramite email, SMS o l'app Il Mio Ottico, in base alla priorità
              definita. Questa configurazione sarà applicata a tutte le
              notifiche riguardanti l'agenda delle prenotazioni.
            </Typography>
            <Stack
              flex="1"
              direction={'row'}
              justifyContent="center"
              alignItems="start"
              sx={{ width: '100%' }}
              gap={1}
            >
              <Kanban disabled={disabled} software={software} />
              {/* <BluKanban
                disabled={disabled}
                smsPriority={'smsPriority'}
                imoPriority={'imoPriority'}
                emailPriority={'emailPriority'}
                software={software}
              /> */}
            </Stack>
            <Divider sx={{ opacity: 0, mb: '10px!important' }} />
            <BluTitle text={'Messaggi'} />
          </>
        )}
        <Stack
          direction={'row'}
          justifyContent="center"
          alignItems="start"
          width={'100%'}
        >
          <Stack
            direction={'column'}
            justifyContent="center"
            alignItems="start"
            width={'100%'}
            spacing={2}
            sx={{ position: 'relative' }}
          >
            <Stack
              flexDirection="unset"
              gap={1}
              flexWrap="wrap"
              sx={{ width: '100%' }}
            >
              <CustomTextField
                multiline={5}
                name="confirmationMessage"
                disabled={disabled}
                label="Messaggio di conferma appuntamento (SMS)"
                pos={(field, pos) => {
                  setCurrentField({ field: field, pos: pos });
                }}
                limit={160}
              />
            </Stack>
            {software === 'fw' && (
              <Stack
                flexDirection="unset"
                gap={1}
                flexWrap="wrap"
                sx={{ width: '100%' }}
              >
                <CustomTextField
                  multiline={5}
                  name="confirmationIMO"
                  disabled={disabled}
                  label="Messaggio di conferma appuntamento Il mio Ottico"
                  pos={(field, pos) => {
                    setCurrentField({ field: field, pos: pos });
                  }}
                />
              </Stack>
            )}
            <Stack
              flexDirection="unset"
              gap={1}
              flexWrap="wrap"
              sx={{ width: '100%' }}
            >
              <CustomTextField
                multiline={5}
                name="modificationMessage"
                disabled={disabled}
                label="Messaggio di modifica appuntamento (SMS)"
                pos={(field, pos) => {
                  setCurrentField({ field: field, pos: pos });
                }}
                limit={160}
              />
            </Stack>
            {software === 'fw' && (
              <Stack
                flexDirection="unset"
                gap={1}
                flexWrap="wrap"
                sx={{ width: '100%' }}
              >
                <CustomTextField
                  multiline={5}
                  name="modificationIMO"
                  disabled={disabled}
                  label="Messaggio di modifica appuntamento Il mio Ottico"
                  pos={(field, pos) => {
                    setCurrentField({ field: field, pos: pos });
                  }}
                />
              </Stack>
            )}
            <Stack
              flexDirection="unset"
              gap={1}
              flexWrap="wrap"
              sx={{ width: '100%' }}
            >
              <CustomTextField
                multiline={5}
                name="reminderMessage"
                disabled={disabled}
                label="Messaggio di reminder appuntamento (SMS)"
                pos={(field, pos) => {
                  setCurrentField({ field: field, pos: pos });
                }}
                limit={160}
              />
            </Stack>
            {software === 'fw' && (
              <Stack
                flexDirection="unset"
                gap={1}
                flexWrap="wrap"
                sx={{ width: '100%' }}
              >
                <CustomTextField
                  multiline={5}
                  name="reminderIMO"
                  disabled={disabled}
                  label="Messaggio di reminder appuntamento Il mio Ottico"
                  pos={(field, pos) => {
                    setCurrentField({ field: field, pos: pos });
                  }}
                />
              </Stack>
            )}
            <Stack
              flexDirection="unset"
              gap={1}
              flexWrap="wrap"
              sx={{ width: '100%' }}
            >
              <CustomTextField
                multiline={5}
                name="cancellationMessage"
                disabled={disabled}
                label="Messaggio di eliminazione appuntamento (SMS)"
                pos={(field, pos) => {
                  setCurrentField({ field: field, pos: pos });
                }}
                limit={160}
              />
            </Stack>
            {software === 'fw' && (
              <Stack
                flexDirection="unset"
                gap={1}
                flexWrap="wrap"
                sx={{ width: '100%' }}
              >
                <CustomTextField
                  multiline={5}
                  name="cancellationIMO"
                  disabled={disabled}
                  label="Messaggio di eliminazione appuntamento Il mio Ottico"
                  pos={(field, pos) => {
                    setCurrentField({ field: field, pos: pos });
                  }}
                />
              </Stack>
            )}
            <Stack
              flexDirection="unset"
              flexWrap="wrap"
              justifyContent="center"
              flex="1"
              gap={1}
              sx={{
                position: !disabled ? 'sticky' : undefined,
                bottom: -20,
                backgroundColor: 'var(--base-foreground-color)',
                zIndex: 200,
                pt: 2,
              }}
            >
              {campiUnion.map((campo: string) => (
                <Button
                  disabled={disabled}
                  variant="outlined"
                  onClick={() => {
                    updateOnButtonClick(`[${campo.toLowerCase()}]`);
                  }}
                >
                  {campo}
                </Button>
              ))}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
});
