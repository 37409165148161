import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { IconButton, InputAdornment, SxProps, TextField } from '@mui/material';
import { ChangeEvent, memo } from 'react';

interface Props {
  ricerca: string;
  placeholder?: string;
  label?: string;
  sx?: SxProps;
  ref?: any;
  onRicercaChange: (arg: string) => void;
}

/**
 * Componente BludataSearchField: un campo di ricerca personalizzato.
 *
 * Props:
 * - ricerca: stringa che rappresenta il valore corrente del campo di ricerca.
 * - placeholder: stringa che viene mostrata nel campo di ricerca quando è vuoto.
 * - label: etichetta del campo di ricerca.
 * - sx: oggetto per la personalizzazione dello stile del componente.
 * - onRicercaChange: funzione che viene chiamata quando il valore del campo di ricerca cambia.
 *
 * Esempio di utilizzo:
 * <BludataSearchField
 *   ricerca={this.state.ricerca}
 *   placeholder="Cerca..."
 *   label="Ricerca"
 *   onRicercaChange={(nuovaRicerca) => this.setState({ ricerca: nuovaRicerca })}
 * />
 */
function BludataSearchField(props: Props) {
  const { ricerca, placeholder, label, sx, ref, onRicercaChange } = props;

  return (
    <TextField
      label={label}
      inputRef={ref || undefined}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: ricerca ? (
          <InputAdornment position="end">
            <IconButton
              size="small"
              onClick={() => {
                onRicercaChange('');
              }}
              edge="end"
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          </InputAdornment>
        ) : null,
      }}
      placeholder={placeholder}
      value={ricerca}
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        onRicercaChange(event.target.value || '');
      }}
      sx={sx}
    />
  );
}

export default memo(BludataSearchField);
