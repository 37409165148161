import {
  ToggleButtonGroup,
  ToggleButton,
  Box,
  Stack,
  IconButton,
  Typography,
} from '@mui/material';
import React from 'react';
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import { BluTitle } from '@bludata/components';
import moment from 'moment';
import { render } from 'react-dom';
import { AppDispatch, RootState } from 'packages/blu-booking/src/store';
import { DefaultCalendarSetView } from '../store';

export const Header = memo(function Header(): JSX.Element {
  const dispatch = useDispatch<AppDispatch>();
  const { view, selectedDate } = useSelector(
    (state: RootState) => state.bluBookingDefaultCalendar
  );
  const { printComponent } = useSelector(
    (state: RootState) => state.bluBookingaAppointments
  );

  const handlePrint = useReactToPrint({
    content: () => {
      const newPage = document.createElement('div');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const contentClone = (printComponent as any).cloneNode(true);

      const container = (
        <div>
          <Stack direction={'row'} flex={1} sx={{ ml: 2, mt: 2, mb: 2 }}>
            <BluTitle
              text={`Calendario appuntamenti del ${moment(selectedDate).format(
                'DD/MM/yyyy'
              )}`}
            />
          </Stack>
          <div
            className="e-schedule e-agenda-view e-agenda-item"
            id="content-blualert"
            style={{ margin: '8px', height: 'fit-content' }}
            dangerouslySetInnerHTML={{
              __html: contentClone.querySelector('.e-content-wrap').innerHTML,
            }}
          ></div>
          <Stack direction="row" justifyContent="flex-end" alignItems="center">
            <Typography m={2} sx={{ color: '#cccccc', fontStyle: 'italic' }}>
              {'Stampato il: ' +
                moment(new Date()).format('DD/MM/yyyy - HH:mm')}
            </Typography>
          </Stack>
        </div>
      );

      render(container, newPage);
      return newPage;
    },
  });

  return (
    <Box
      sx={{
        height: '38px',
        display: 'flex',
        alignItems: 'center',
        m: '2px 4px 6px 4px',
      }}
    >
      <Stack
        flexDirection="row"
        alignItems="center"
        sx={{ width: '100%' }}
        gap="20px"
      >
        <div style={{ flex: 1 }} />
        <ToggleButtonGroup
          exclusive
          value={view}
          onChange={(_, viewType) => {
            if (!viewType) return;
            dispatch(DefaultCalendarSetView(viewType));
          }}
        >
          <ToggleButton size="small" value="Day">
            Giorno
          </ToggleButton>
          <ToggleButton size="small" value="3Days">
            3 Giorni
          </ToggleButton>
          <ToggleButton size="small" value="WorkWeek">
            Lavorativo
          </ToggleButton>
          <ToggleButton size="small" value="Week">
            Settimana
          </ToggleButton>
          <ToggleButton size="small" value="Month">
            Mese
          </ToggleButton>
          <ToggleButton size="small" value="Agenda">
            Agenda
          </ToggleButton>
        </ToggleButtonGroup>
        {view !== 'Agenda' && (
          <IconButton onClick={handlePrint}>
            <LocalPrintshopIcon />
          </IconButton>
        )}
      </Stack>
    </Box>
  );
});
