import { BluAutoComplete } from '@bludata/components';
import {
  Box,
  IconButton,
  InputAdornment,
  InputLabel,
  Stack,
} from '@mui/material';
import { RootState, useAppSelector } from 'packages/blu-booking/src/store';
import { bluBookingLoginData } from 'packages/blu-booking/src/store/root';
import { FC, memo, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { GridCloseIcon } from '@mui/x-data-grid-premium';

interface PointOfSaleCheckInterface {
  pointOfSaleId: string;
  handleFilterChange: (key: string, value: any) => void;
}

export const PointOfSaleCheck: FC<PointOfSaleCheckInterface> = memo(
  ({ pointOfSaleId, handleFilterChange }) => {
    const { pointOfSaleId: pointOfSale } = useAppSelector(bluBookingLoginData);
    const ref = useRef(null);
    const { pointOfSales } = useSelector(
      (state: RootState) => state.bluBookingCalendarFilters
    );

    const getValue = useCallback(() => {
      console.log('GET VALUE', pointOfSaleId);
      if (pointOfSales && pointOfSaleId in pointOfSales)
        return pointOfSales[pointOfSaleId];
      return undefined;
    }, [pointOfSales, pointOfSaleId]);

    const handleChange = useCallback(
      (_: any, newValue: any) => {
        handleFilterChange('pointOfSaleId', newValue?.pointOfSaleId);
      },
      [handleFilterChange]
    );

    const handleColorText = useCallback(() => {
      if (pointOfSaleId === undefined) return undefined;
      if (!(pointOfSaleId in pointOfSales)) return undefined;
      if (pointOfSaleId !== pointOfSale) return 'var(--alert-color)';
      return undefined;
    }, [pointOfSale, pointOfSales, pointOfSaleId]);

    const getPlaceHolder = useCallback(() => {
      if (!pointOfSaleId) return 'Tutti i punti vendita';
      return undefined;
    }, [pointOfSaleId]);

    const handleErase = useCallback(() => {
      handleFilterChange('pointOfSaleId', '');
    }, [handleFilterChange]);

    return (
      <Stack sx={{ width: '300px' }}>
        <InputLabel>Punto vendita</InputLabel>
        <BluAutoComplete
          size="small"
          renderOption={(props: any, option: any) => (
            <Box component="li" {...props}>
              {option.branch}
            </Box>
          )}
          textField={{
            sx: { '.MuiInput-input': { color: handleColorText() } },
            autoComplete: 'new-password',
            placeholder: getPlaceHolder(),
            // InputProps: {
            //   endAdornment: (
            //     // eslint-disable-next-line react/jsx-no-useless-fragment
            //     <>
            //       {pointOfSaleId && (
            //         <InputAdornment position="end">
            //           <IconButton
            //             color="primary"
            //             size="small"
            //             onClick={handleErase}
            //           >
            //             <GridCloseIcon />
            //           </IconButton>
            //         </InputAdornment>
            //       )}
            //     </>
            //   ),
            // },
          }}
          displayEmpty
          getOptionLabel={(option: any) => option.branch}
          data={Object.values(pointOfSales)}
          value={getValue()}
          // defaultValue={CONF.pointOfSale}
          onChange={handleChange}
          isOptionEqualToValue={(option: any, value: any) =>
            option.pointOfSaleId === value.pointOfSaleId
          }
          InputProps={{
            autoComplete: 'new-password',
          }}
        />
      </Stack>
    );
  }
);
