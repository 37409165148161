/* eslint-disable @typescript-eslint/no-explicit-any */
import dayjs from 'dayjs';
import { setAppointmentsIndex, setRefetch } from '../store/Appointment';
import { store } from 'packages/blu-booking/src/store';
import { setEmailPopup } from '../store/Calendar';
import { Appointment_interface } from 'packages/blu-booking/src/interfaces/Appointment';
import { setOpenLoader } from 'packages/blu-booking/src/store/root';
import { setOpenForm } from '../store/AppointmentForm';
import { CheckIsOkAsyncDialog } from './CheckIsSlotOkAsyncDialog';
import { appointmentSlotIsOk } from 'packages/blu-booking/src/data_fetching/AppointmentSlot/AppointmentSlotIsOk';

export const AppointmentDragDrop = async (
  args: any,
  appointments: Appointment_interface[],
  calendarType: string
) => {
  args.cancel = true;

  const findIndex: number = appointments.findIndex(
    (app) => app.Id === args.data.Id
  );
  if (findIndex === -1) return;

  if (appointments[findIndex].isReadOnly) return;

  if (
    (calendarType === 'default' || calendarType === 'personal') &&
    dayjs(args.data.StartTime).format('YYYY-MM-DD HH:mm:ss') ===
      dayjs(appointments[findIndex].StartTime).format('YYYY-MM-DD HH:mm:ss')
  )
    return;

  const difference = dayjs(appointments[findIndex].EndTime).diff(
    dayjs(appointments[findIndex].StartTime),
    'minutes'
  );

  store.dispatch(setOpenLoader(true));
  const isOk = await appointmentSlotIsOk({
    appointmentId: args.data.Id,
    serviceId: args.data.serviceId,
    pointOfSaleId: args.data.pointOfSaleId,
    userId: args.data.userId,
    studioId: args.data.studioId,
    startTime: dayjs(args.data.StartTime).format('YYYY-MM-DD HH:mm'),
    endTime: dayjs(args.data.StartTime)
      .clone()
      .add(difference, 'minutes')
      .format('YYYY-MM-DD HH:mm'),
  });
  store.dispatch(setOpenLoader(false));

  if (isOk.isOk === 1) {
    store.dispatch(
      setAppointmentsIndex({
        index: findIndex,
        appointment: {
          ...args.data,
          endTime: dayjs(args.data.StartTime)
            .clone()
            .add(difference, 'minutes')
            .format('YYYY-MM-DD HH:mm:ss'),
        } as any,
      })
    );
    let text = 'Lo slot scelto non è disponibile! ';

    if (isOk?.isStudioOk === 1 && isOk?.isUserOk > 0) {
      if (isOk?.isUserOk === 1)
        text += `Lo studio è occupato in quell’orario e l'utente ha già un appuntamento programmato.`;
      else
        text += `Lo studio è occupato e l'utente non lavora in quell’orario.`;
    } else if (isOk?.isStudioOk === 1) {
      text += 'Lo studio è occupato in quell’orario.';
    } else if (isOk?.isUserOk === 1) {
      text += 'L’utente ha già un appuntamento programmato in quell’orario.';
    } else if (isOk?.isUserOk === 2) {
      text += 'L’utente non lavora in quell’orario.';
    }
    text +=
      '\nPuoi comunque confermare questo nuovo appuntamento cliccando su "Conferma", oppure scegliere un altro orario che preferisci.';
    CheckIsOkAsyncDialog({
      title: 'Attenzione',
      type: 'warning',
      message: `<pre style='font-family: "Roboto"; white-space: pre-wrap; width: inherit;'>${text}</pre>`,
      confimButton2: 'Conferma',
      confimButton1: 'Cambia orario',
      declineButton: 'Annulla',
      onConfirmClick2: () => {
        store.dispatch(
          setAppointmentsIndex({
            index: findIndex,
            appointment: { ...args.data, slotOkStatus: 2 } as any,
          })
        );

        store.dispatch(
          setEmailPopup({
            id: args.data.id,
            emailSet: args.data.email !== undefined && args.data.email !== '',
            type: 'modify',
            appointment: {
              ...args.data,
              slotOkStatus: 2,
            } as Appointment_interface,
            findIndex: findIndex,
            prevData: appointments[findIndex],
          })
        );
      },
      onConfirmClick1: () => {
        store.dispatch(setOpenForm({ ...args.data }));
      },
      onDeclineClick: () => {
        store.dispatch(setRefetch());
      },
    });
    return;
  }

  store.dispatch(
    setAppointmentsIndex({
      index: findIndex,
      appointment: {
        ...args.data,
        endTime: dayjs(args.data.StartTime)
          .clone()
          .add(difference, 'minutes')
          .format('YYYY-MM-DD HH:mm:ss'),
      } as any,
    })
  );

  store.dispatch(
    setEmailPopup({
      id: args.data.id,
      emailSet: args.data.email !== undefined && args.data.email !== '',
      type: 'dragdrop',
      appointment: args.data as Appointment_interface,
      findIndex: findIndex,
      prevData: appointments[findIndex],
    })
  );
};
