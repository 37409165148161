/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Typography } from '@mui/material';
import { memo, useEffect, useMemo, useState } from 'react';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import React from 'react';
import { TableResult } from './DisplayResults';
import { BluDialog, BluTextField } from '@bludata/components';
import { useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { AppDispatch } from 'packages/blu-booking/src/store';
import { getConfig } from '../../../../utility_objects/WhiteCache';
import { setSearchCreateLinkPopUp } from '../../../../store/CreateLink';

export const SearchPopUpForm = memo(() => {
  const [cliente, setCliente] = useState<string>('GIU');

  const dispatch = useDispatch<AppDispatch>();
  const { reset, getValues } = useFormContext();
  const { focus10 } = getConfig();

  const GetResults = useMemo(() => {
    return (
      <TableResult
        value={cliente}
        action={(value: any) => {
          reset({
            ...getValues(),
            ...value,
            email: value?.email || '',
            name: value?.name || '',
            surname: value?.surname || '',
            telephone: value?.telephone || '',
            externalCustomerCode: value?.externalCustomerCode || '',
          });
          // trigger();
          dispatch(setSearchCreateLinkPopUp(false));
        }}
      />
    );
  }, [cliente, dispatch, getValues, reset]);

  return (
    <BluDialog
      open
      zIndex={400}
      dialogTitle="Ricerca cliente"
      fullScreen={false}
      dialogActions={
        <Button
          variant="outlined"
          color="primary"
          onClick={() => dispatch(setSearchCreateLinkPopUp(false))}
        >
          Chiudi
        </Button>
      }
      dialogContent={
        <div style={{ minWidth: focus10 ? '800px' : '50vw' }}>
          <Typography variant="subtitle2" sx={{ p: '3px 0px' }}>
            Ricerca cliente
          </Typography>
          <Search setValue={(v: string) => setCliente(v)} />
          <div style={{ height: '20px' }} />
          {GetResults}
          <div style={{ height: '20px' }} />
        </div>
      }
    />
  );
});

interface SearchProps {
  setValue: (v: string) => void;
}

const Search = memo(({ setValue }: SearchProps) => {
  const [value, set] = useState<string>('');

  useEffect(() => {
    const delayFn = setTimeout(() => {
      setValue(value);
    }, 250);

    return () => {
      clearTimeout(delayFn);
    };
  }, [setValue, value]);

  return (
    <BluTextField
      // ref={searchRef}
      value={value}
      name="Nome"
      onChange={(event: any) => set(event.target.value)}
      InputProps={{
        startAdornment: <SearchTwoToneIcon />,
      }}
    />
  );
});
