import {
  DataGridPremium,
  GridRenderCellParams,
} from '@mui/x-data-grid-premium';
import { FC, memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getWhiteCache } from '../../../../lib/white-cache';
import { AppDispatch, RootState } from '../../../../store';
import { EnableCell } from './enable-cell';
import { setCurrentService, setOpenForm } from '../../store/root';
import { Badge, Stack } from '@mui/material';
import { nanoid } from 'nanoid';
import { DynamicIconConverter } from '../../../../util/dynamic-icon-converter';

interface ServicesGridInterface {
  search: string;
}

export const ServicesGrid: FC<ServicesGridInterface> = memo(({ search }) => {
  const { services, serviceLoad } = useSelector(
    (state: RootState) => state.serviceRoot
  );
  const dispatch = useDispatch<AppDispatch>();
  const { isBluBookingActive } = getWhiteCache();

  const getValues = useCallback(() => {
    if (services !== undefined)
      return Object.values(services).filter((value) => {
        var reg = new RegExp(search, 'i');
        return reg.test(value.name);
      });
    return [];
  }, [search, services]);

  const Columns: any[] = [
    {
      field: 'id',
      hide: true,
    },
    {
      field: 'icons',
      headerName: 'Icone',
      width: 110,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Stack flex="1" direction="row" gap={1} justifyContent="center">
            {(params.value ?? []).map((item: any) => (
              <DynamicIconConverter name={item} />
            ))}
          </Stack>
        );
      },
    },
    {
      field: 'color',
      headerName: 'Colore',
      width: 70,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: GridRenderCellParams) => {
        return !!params.value ? (
          <Badge
            key={params.value + 'color'}
            sx={{
              borderRadius: '50%',
              backgroundColor: `${params.value}95`,
              width: 30,
              height: 30,
              border: `thin solid ${params.value}`,
            }}
            overlap="circular"
          />
        ) : (
          <Badge
            key={nanoid()}
            sx={{
              borderRadius: '50%',
              backgroundColor: 'white',
              width: 30,
              height: 30,
              border: `thin solid var(--divider-color)`,
            }}
            overlap="circular"
          />
        );
      },
    },
    {
      field: 'name',
      headerName: 'Servizio',
      flex: 1,
      editable: false,
      minWidth: 70,
    },
    {
      field: 'smallDescription',
      headerName: 'Descrizione',
      flex: 1,
      editable: false,
      minWidth: 100,
    },
    {
      field: 'description',
      headerName: 'Descrizione Blu Booking',
      flex: 1.5,
      editable: false,
      hide: !isBluBookingActive,
      minWidth: 170,
    },
    {
      field: 'durationValue',
      headerName: 'Durata',
      type: 'number',
      align: 'center',
      width: 70,
      editable: false,
      sortable: false,
      headerAlign: 'center',
    },
    {
      field: 'isOnlineBooking',
      headerName: 'Prenotazione online',
      align: 'center',
      type: 'boolean',
      width: 180,
      editable: false,
      sortable: false,
      hide: !isBluBookingActive,
      renderCell: EnableCell,
    },
    {
      field: 'hasAutoConfirmation',
      headerName: 'Auto conferma',
      align: 'center',
      type: 'boolean',
      width: 130,
      editable: false,
      sortable: false,
      hide: !isBluBookingActive,
      renderCell: EnableCell,
    },
    {
      field: 'hasStudio',
      headerName: 'Studio necessario',
      align: 'center',
      type: 'boolean',
      width: 170,
      editable: false,
      sortable: false,
      renderCell: EnableCell,
    },
  ];

  return (
    <DataGridPremium
      columns={Columns as any}
      rows={getValues()}
      loading={serviceLoad}
      sx={{
        '& .MuiDataGrid-cell': {
          outline: 'none!important',
        },
        '& .MuiDataGrid-row': {
          cursor: 'pointer',
        },
      }}
      rowBuffer={50}
      rowThreshold={15}
      density="compact"
      disableColumnMenu
      getEstimatedRowHeight={() => 300}
      getRowHeight={() => 'auto'}
      editMode="cell"
      onProcessRowUpdateError={(error) => console.log(error)}
      experimentalFeatures={{ newEditingApi: true }}
      onRowClick={(event: any) => {
        dispatch(setCurrentService(event.id));
      }}
      onRowDoubleClick={() => dispatch(setOpenForm('info'))}
      components={{
        Footer: () => <></>,
      }}
    />
  );
});
