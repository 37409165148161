/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import axios from 'axios';
import { ShopSignLinkInterface } from '../../interfaces/ShopSignLink';

const defaultShopSignLink: ShopSignLinkInterface = {
  id: '',
  companyId: '',
  shortLink: '',
  token: '',
  urlLink: '',
  shopSignId: '',
  shopSignName: '',
  pointOfSaleId: '',
  pointOfSaleName: '',
  serviceId: '',
  serviceName: '',
  userId: '',
  username: '',
  studioId: '',
  studioName: '',
  name: '',
  surname: '',
  email: '',
  telephone: '',
  externalCustomerCode: '',
};

export const shopSignLink = async (): Promise<ShopSignLinkInterface[]> => {
  try {
    const {
      data: { data },
    } = await axios.get(`/api/ShopSignLink`);
    return data;
  } catch (err) {
    console.trace(err);
    return [];
  }
};

export const useShopSignLink = ({
  options = {},
  key = '',
}: {
  options?: UseQueryOptions | undefined;
  key?: string | undefined;
}) => {
  return useQuery({
    queryFn: async () => await shopSignLink(),
    queryKey: ['shop-sign-link', key],
    retry: 2,
    cacheTime: 300000,
    staleTime: 300000,
    refetchInterval: 300000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    select: normalize,
    ...options,
  });
};

const normalize = (data: any): ShopSignLinkInterface[] => {
  const result: ShopSignLinkInterface[] = [];
  for (const shopSignLink of data) {
    result.push({
      ...defaultShopSignLink,
      ...shopSignLink,
    });
  }
  return result;
};
