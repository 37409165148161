import { store } from 'packages/blu-booking/src/store';
import { setOpenForm } from '../../../store/AppointmentForm';
import { getAppointmentDetails } from 'packages/blu-booking/src/data_fetching/Appointment/AppointmentDetails';
import { setOpenLoader } from 'packages/blu-booking/src/store/root';
import { getConfig } from '../../../utility_objects/WhiteCache';
import dayjs from 'dayjs';
import { setRightClick } from '../../../store/Calendar';

export const popUpOpen = (args: any, client: any, pointOfSaleId: string) => {
  if (args.data.isReadOnly) {
    args.cancel = true;
    return;
  }
  if (
    (args.type === 'QuickInfo' || args.type === 'ViewEventInfo') &&
    !args.data.Id
  ) {
    args.cancel = true;
  }
  if (args.type === 'EditEventInfo') {
    const element = document.querySelector(
      `[data-id="Appointment_${args.data.Id}"]`
    );
    args.cancel = true;
    const rect = element.getBoundingClientRect();
    const viewportWidth = window.innerWidth; // Larghezza del viewport

    // Calcola se c'è spazio sufficiente a destra
    const spaceRight = viewportWidth - rect.right;

    store.dispatch(
      setRightClick({
        ...args?.data,
        x: spaceRight > 200 ? rect?.right - 15 : rect.left - 100,
        y: rect?.top - 10,
      })
    );
    args.cancel = true;
  }
  if (args.type === 'Editor' || args.type === 'ViewEventInfo') {
    const { sessionProfilo } = getConfig();
    if (!args.data?.Id) {
      if (!sessionProfilo.agendaInserimento) {
        args.cancel = true;
        return;
      }
      const dateNow = new Date();
      const start = dayjs(args.data?.StartTime)
        .hour(dateNow.getHours())
        .minute(dateNow.getMinutes());
      store.dispatch(
        setOpenForm({
          ...args.data,
          StartTime: start.toString(),
          EndTime: start.add(30, 'minute').toString(),
          duration: 30,
          name: client?.nome,
          surname: client?.cognome,
          externalCustomerCode: client?.codicePersonale,
          telephone:
            client.telefoninoGsm || client.telefono || client.telefono2,
          email: client?.eMail ?? '',
          isIMO: client.appAttiva || false,
          pointOfSaleId: pointOfSaleId,
        })
      );
      args.cancel = true;
      return;
    }
    if (sessionProfilo?.agendaModifica) {
      store.dispatch(setOpenLoader(true));

      getAppointmentDetails(args.data?.Id)
        .then((data) => {
          store.dispatch(
            setOpenForm({ ...data, userUsername: args.data?.username })
          );
        })
        .catch((error) => {
          console.error('Failed to', error);
        })
        .finally(() => {
          store.dispatch(setOpenLoader(false));
        });
    }

    args.cancel = true;
  }
};
