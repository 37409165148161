import { BluTextField } from '@bludata/components';
import { InputAdornment, Stack, Typography } from '@mui/material';
import React, { FC } from 'react';
import { memo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { faUsers } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { capitalizeFirstLetter } from 'packages/blu-booking/src/utility_objects/CapitalizeFirstLetter';

interface NameInterface {
  isTabClienti: boolean;
  externalCustomerCode?: string;
}

export const Name: FC<NameInterface> = memo(
  ({ isTabClienti, externalCustomerCode }) => {
    const name = useWatch({ name: 'name' });
    const { setValue, trigger } = useFormContext();

    return (
      <Stack flex="1">
        <Typography variant="subtitle2" sx={{ pt: '3px' }}>
          Nome
        </Typography>
        <BluTextField
          value={name}
          name="Nome"
          autoComplete="off" // Disabilita il completamento automatico
          inputProps={{ readOnly: isTabClienti }}
          onChange={(
            event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => {
            const value = capitalizeFirstLetter(event.target.value);
            setValue('name', value);
            setValue('externalCustomerCode', undefined);
            trigger();
          }}
          InputProps={{
            endAdornment: (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                {externalCustomerCode && externalCustomerCode !== '' && (
                  <InputAdornment position="end">
                    <FontAwesomeIcon
                      icon={faUsers}
                      style={{
                        color: 'var(--base-color)',
                        // fontSize: '24px',
                      }}
                    />
                  </InputAdornment>
                )}
              </>
            ),
          }}
        />
      </Stack>
    );
  }
);
