import { Card, Box, Button, Stack, Typography, Divider } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import ModeIcon from '@mui/icons-material/Mode';
import { AppDispatch, RootState } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { Header } from '../../UI/Header';
import AddIcon from '@mui/icons-material/Add';
import { useShopSignBasic } from './api/shop-sign-basic';
import {
  setCurrentPointOfSale,
  setPointOfSaleOpenForm,
  setPointOfSalesDatas,
} from './store/root';
import { BluLoader, BluTitle } from '@bludata/components';
import { usePointOfSales } from './api/point-of-sales';
import { PointOfSalesGridFilters } from './components/GridFilters/GridFilters';
import { PointOfSalesGrid } from './components/Grid/Grid';
import { PointOfSalePopUp } from './components/PopUp/PopUp';
import WebAssetIcon from '@mui/icons-material/WebAsset';

interface PointOfSaleInterface {
  root?: string;
}

export const PointOfSalesPage: FC<PointOfSaleInterface> = ({
  root = '',
}): JSX.Element => {
  const dispatch: AppDispatch = useDispatch();

  const { data: pointOfSalesData, isLoading: pointOfSaleIsLoading } =
    usePointOfSales();
  const { data: shopSignsData, isLoading: shopSignIsLoading } =
    useShopSignBasic();

  const { currentPointOfSale, openForm } = useSelector(
    (state: RootState) => state.newPointOfSaleRoot
  );

  useEffect(() => {
    if (pointOfSalesData && shopSignsData) {
      dispatch(
        setPointOfSalesDatas({
          pointOfSales: pointOfSalesData,
          shopSigns: shopSignsData,
          dataLoads: true,
        })
      );
    }
  }, [dispatch, pointOfSalesData, shopSignsData]);

  const getIsLoading = useCallback(() => {
    return shopSignIsLoading || pointOfSaleIsLoading;
  }, [pointOfSaleIsLoading, shopSignIsLoading]);

  const [gridSearch, setGridSearch] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [remoto, setRemoto] = useState(false);

  const GetPopUp = useMemo(() => {
    if (currentPointOfSale !== undefined && openForm)
      return (
        <PointOfSalePopUp pointOfSaleId={currentPointOfSale} mode={openForm} />
      );
    return <></>;
  }, [currentPointOfSale, openForm]);

  return (
    <Stack
      direction="row"
      justifyContent="start"
      alignItems="start"
      flex={1}
      sx={{
        backgroundColor: 'var(--hovered-background-color)',
        height: '100%',
      }}
    >
      <BluLoader open={getIsLoading()} />
      {GetPopUp}

      <Stack flexDirection="column" flex={1} sx={{ height: '100%' }}>
        <Header title={`${root ?? ''}Punti vendita`} />
        <Card variant="outlined" sx={{ height: '100%', p: 1, m: 0.5 }}>
          <Stack direction="column" spacing={1} sx={{ height: '100%' }}>
            <BluTitle text={'Lista punti vendita'} />
            <Typography>
              In questa sezione è possibile configurare l'elenco dei punti
              vendita.
            </Typography>
            <div style={{ display: 'flex' }}>
              <Typography component={'div'}>
                Premi il tasto <b>Scheda</b> per visualizzare le impostazioni di
                un punto vendita e <b>Modifica</b> per modificarne uno di
                esistente.
              </Typography>
            </div>
            <Divider />
            <PointOfSalesGridFilters
              gridSearch={gridSearch}
              setGridSearch={setGridSearch}
              remoto={remoto}
              setRemoto={setRemoto}
              isActive={isActive}
              setIsActive={setIsActive}
            />
            <PointOfSalesGrid
              gridSearch={gridSearch}
              remoto={remoto}
              isActive={isActive}
            />
            <Stack direction="row" sx={{ mt: '8px' }}>
              <Box flex={1}></Box>
              <Stack direction="row" spacing={0.5}>
                <Button
                  color="primary"
                  startIcon={<AddIcon />}
                  // disabled={!(isConsole && software === "bb")}
                  disabled
                  onClick={() => {
                    dispatch(setPointOfSaleOpenForm('new'));
                    dispatch(setCurrentPointOfSale(''));
                  }}
                >
                  Nuovo
                </Button>
                <Button
                  color="primary"
                  startIcon={<ModeIcon />}
                  variant="outlined"
                  disabled={
                    currentPointOfSale === undefined ||
                    currentPointOfSale === ''
                  }
                  onClick={() => {
                    dispatch(setPointOfSaleOpenForm('modify'));
                  }}
                >
                  Modifica
                </Button>
                <Button
                  color="primary"
                  startIcon={<WebAssetIcon />}
                  variant="outlined"
                  onClick={() => {
                    dispatch(setPointOfSaleOpenForm('info'));
                  }}
                  disabled={
                    currentPointOfSale === undefined ||
                    currentPointOfSale === ''
                  }
                >
                  Scheda
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Card>
      </Stack>
    </Stack>
  );
};
