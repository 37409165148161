import { FC, useCallback, useMemo, useState } from "react";
import { Button, Card, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { Header } from "../../UI/Header";
import { BluAsyncDialog, BluLoader, BluTitle } from "@bludata/components";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { useQueryClient } from "@tanstack/react-query";
import SaveIcon from "@mui/icons-material/Save";
import { Holidays } from "./components/Holidays/Holidays";
import { resetAndChangeHolidays } from "./store/holidays";
import {
  setCompanySettings,
  setMode,
  setPopUpCalendarManagement,
} from "./store/root";
import {
  getInitialCompanySettings,
  getInitialHolidays,
} from "./utils/white-cache";
import { GeneraliCompanySettings } from "./components/CompanySettings";
import { managementCompanySettings } from "./api/management-company-settings";
import { PopUpCalendarManagement } from "./components/PopUpCalendarManagement/PopUpCalendarManagement";

const Generali: FC<{
  software: string;
  root?: string;
  refreshToken?: () => void;
}> = (props): JSX.Element => {
  const { refreshToken = () => {} } = props;
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();

  const { mode, isSingleCalendarManagement, popUpCalendarManagement } =
    useSelector((state: RootState) => state.generaliRoot);

  const isDisabled = useCallback(() => {
    return mode === "view";
  }, [mode]);

  const handleOnSave = useCallback(async () => {
    try {
      setLoading(true);
      const { isSingleCalendarManagement: oldIsSingleCalendarManagement } =
        getInitialCompanySettings();
      if (!oldIsSingleCalendarManagement && isSingleCalendarManagement) {
        setLoading(false);
        dispatch(setPopUpCalendarManagement(true));
        return;
      }

      const result = await managementCompanySettings({});

      if (result) {
        setLoading(false);
        dispatch(setMode("view"));
        queryClient.invalidateQueries(["shop-sign", "holiday"]);
        queryClient.removeQueries(["shop-sign", "holiday"], {
          type: "inactive",
        });
        queryClient.invalidateQueries(["company-settings"]);
        queryClient.removeQueries(["company-settings"], {
          type: "inactive",
        });
        try {
          (window as any).disableMenuBluBooking(false);
        } catch {}
        if (props.software === "f10" || props.software === "fw") {
          BluAsyncDialog({
            title: "Attenzione",
            type: "info",
            message: "Per vedere le modifiche devi rifare il login.",
            sx: { "& .MuiDialog-paper": { maxWidth: "370px" } },
            hideDeclineButton: true,
            confimButton: "Chiudi",
          });
        }
        if (props.software === "bb") refreshToken();

        // if (props.software === "fw") {
        //   BluAsyncDialog({
        //     title: "Attenzione",
        //     type: "info",
        //     message:
        //       "La pagina verrrà ricaricata per applicare le modifiche alle nuove configurazioni.",
        //     sx: { "& .MuiDialog-paper": { maxWidth: "370px" } },
        //     hideDeclineButton: true,
        //     confimButton: "Conferma",
        //     onConfirmClick: () => {
        //       try {
        //         //@ts-ignore
        //         window?.Ext.getApplication().setConfig(
        //           "forceQuitBludata",
        //           true
        //         );
        //         //@ts-ignore
        //         window?.bludata.logout();
        //       } catch (error) {}
        //     },
        //   });
        // }
        return;
      }
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
    dispatch(setMode("view"));
    try {
      (window as any).disableMenuBluBooking(false);
    } catch {}
    BluAsyncDialog({
      title: "Attenzione",
      type: "error",
      message: "Errore durante la modifica delle impostazioni.",
      sx: { "& .MuiDialog-paper": { maxWidth: "370px" } },
      hideDeclineButton: true,
      confimButton: "Chiudi",
    });
    try {
      (window as any).disableMenuBluBooking(false);
    } catch {}
    return;
  }, [
    dispatch,
    isSingleCalendarManagement,
    props.software,
    queryClient,
    refreshToken,
  ]);

  const handleCancel = useCallback(() => {
    dispatch(setMode("view"));
    dispatch(resetAndChangeHolidays(getInitialHolidays()));
    dispatch(setCompanySettings(getInitialCompanySettings()));
    try {
      (window as any).disableMenuBluBooking(false);
    } catch {}
  }, [dispatch]);

  const handleModify = useCallback(() => {
    dispatch(setMode("edit"));
    try {
      (window as any).disableMenuBluBooking(true);
    } catch {}
  }, [dispatch]);

  const GetButtons = useMemo(() => {
    if (mode === "view")
      return (
        <Button
          variant="outlined"
          startIcon={<ModeEditIcon />}
          onClick={handleModify}
          sx={{ width: "fit-content" }}
        >
          Modifica
        </Button>
      );
    return (
      <>
        <Button
          variant="outlined"
          onClick={handleCancel}
          sx={{ width: "fit-content" }}
        >
          Annulla
        </Button>
        <Button
          startIcon={<SaveIcon />}
          variant="contained"
          onClick={handleOnSave}
        >
          Salva
        </Button>
      </>
    );
  }, [handleCancel, handleModify, handleOnSave, mode]);

  const GetPopUp = useMemo(() => {
    if (popUpCalendarManagement) return <PopUpCalendarManagement />;
    return <></>;
  }, [popUpCalendarManagement]);

  // ! isSingleCalendarManagement (unico turno o turno di lavoro e turno degli appuntamenti) true se unico turno da vedere come select
  // ! in caso di passaggio da turno di lavoro e turni degli appuntamenti a unico turno da chiedere se si vogliono utilizzare i turno di lavoro o quello degli appuntamenti (sia per punto vendita che per utente) DA FARE POPUP

  return (
    <>
      <Stack
        direction="row"
        justifyContent="start"
        alignItems="start"
        flex={1}
        sx={{
          backgroundColor: "var(--hovered-background-color)",
        }}
      >
        {GetPopUp}
        <BluLoader open={loading} />
        <Stack flexDirection="column" flex={1} sx={{ height: "100%" }}>
          <Header title={`${props?.root ?? ""}Generale`} />
          <Card variant="outlined" sx={{ height: "100%", p: 1, m: 0.5 }}>
            <Stack direction="column" spacing={1} sx={{ height: "100%" }}>
              <GeneraliCompanySettings disabled={isDisabled()} />

              <BluTitle text={"Festività"} />
              <Typography>
                In questa sezione è possibile configurare le Festività
              </Typography>
              <Holidays />

              <Stack direction={"row"} spacing={0.5} justifyContent="end">
                {GetButtons}
              </Stack>
            </Stack>
          </Card>
        </Stack>
      </Stack>
    </>
  );
};

export default Generali;
