import { TextField } from '@mui/material';
import { Stack } from '@mui/system';
import { memo } from 'react';
import BluTooltip from '../Tooltip/Tooltip';
import { TextFieldInterface } from './interface';

const BluTextField = memo(function BluTextField({
  value,
  onChange,
  onBlur,
  tooltip,
  ...rest
}: TextFieldInterface) {
  return (
    <Stack
      sx={{
        position: 'relative',
      }}
      flexDirection="row"
      alignItems="end"
      flex={1}
    >
      <TextField
        fullWidth
        value={value}
        type="text"
        onChange={onChange}
        onBlur={onBlur}
        {...rest}
      />
      {tooltip && (
        <BluTooltip
          text={tooltip}
          sx={{
            position: 'absolute',
            right: 0,
            top: 0,
            p: '2px',
            '& svg': { width: '15px!important', height: '15px!important' },
          }}
        />
      )}
    </Stack>
  );
});

export default BluTextField;
