/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { nanoid } from '@reduxjs/toolkit';
import { getConfig } from '../pages/Calendars/utility_objects/WhiteCache';

const getListaClientiF10Promise = (
  limit: string,
  searchTerm: string | undefined
) => {
  return new Promise<string>((resolve, reject) => {
    (window as any).agenda10.getListaClienti(
      '0',
      limit,
      searchTerm,
      (data: any) => {
        resolve(data);
      }
    );
  });
};

export const search = async (
  limit: string,
  unregistrered: boolean,
  searchTerm?: string
): Promise<
  {
    id: string;
    name: string;
    surname: string;
    email: string;
    telephone: string;
    isImo?: boolean;
    externalCustomerCode?: string;
  }[]
> => {
  try {
    const { focus10 } = getConfig();

    // eslint-disable-next-line no-prototype-builtins
    if (
      focus10 &&
      // eslint-disable-next-line no-prototype-builtins
      (window as any).hasOwnProperty('agenda10') &&
      !unregistrered
    ) {
      const temp = await getListaClientiF10Promise(limit, searchTerm);
      if (temp) {
        return JSON.parse(temp)
          ['data'].map((json: any) => {
            return {
              ...json,
              id: json.id,
              name: json.nome,
              surname: json.cognome,
              telephone: json.cellulare || json.telefono,
              email: json.email,
              externalCustomerCode: json?.codiceCliente ?? null,
              isIMO: json?.appAttiva,
            };
          })
          .flat();
      }
      return [];
    }
    const {
      data: { data },
    } = await axios.get(`/api/Appointment/Customer`, {
      params: { searchTerm },
    });

    return data
      .map((c: any) => {
        return {
          ...c,
          id: nanoid(),
        };
      })
      .flat();
  } catch (err) {
    console.trace(err);
    return [];
  }
};

export const useSearch = (
  searchTerm?: string,
  unregistrered = false,
  limit = '100'
) => {
  return useQuery({
    queryFn: async () => await search(limit, unregistrered, searchTerm),
    queryKey: ['search', searchTerm, unregistrered],
    retry: 2,
    retryDelay: 180000,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: true,
    cacheTime: 0,
    staleTime: 0,
  });
};
