import { FC, memo } from 'react';
import axios from 'axios';
import { Provider } from 'react-redux';
import { store } from '../../store';
import { LicenseInfo } from '@mui/x-license-pro';
import { Stack } from '@mui/material';
// import "./utils/fonts";
import { setWhiteCache } from '../../lib/white-cache';
import jwt_decode from 'jwt-decode';
import { InitShopSign } from './lib/init';
import { LayoutPage } from './page';
import { LayoutLoader } from './components/Loader';
import { setPath } from './lib/white-cache';
import { InitData } from '../../util/init-data';
import { MUI_license } from '@bludata/components';

interface LayoutInterface {
  token?: string;
  url?: string;
  maxBookingValue?: number;
  focusWeb?: boolean;
  root?: string;
  isBluBookingActive?: boolean;
  software?: string;
  hasAgenda?: boolean;
  isConsole?: boolean;
  isBBActive?: boolean;
}

LicenseInfo.setLicenseKey(MUI_license);

const Layout: FC<LayoutInterface> = memo(
  ({
    url,
    token,
    root,
    isBluBookingActive,
    software,
    hasAgenda,
    isConsole,
    isBBActive,
    focusWeb,
  }) => {
    if (url !== undefined) axios.defaults.baseURL = url;

    if (isConsole === true) {
      setPath('/api');
    } else {
      setPath('/diaryapi');
    }

    let bearerToken: string | undefined;
    if (token !== undefined) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      bearerToken = token;
    } else if (
      axios.defaults.headers.common['Authorization'] !== null &&
      axios.defaults.headers.common['Authorization'] !== undefined
    ) {
      bearerToken = (
        axios.defaults.headers.common['Authorization'] as string
      ).replace('Bearer ', '');
    }

    let accessToken: any;
    try {
      if (bearerToken !== undefined) accessToken = jwt_decode(bearerToken);

      if (isBluBookingActive === undefined && accessToken !== undefined)
        setWhiteCache(
          'isBluBookingActive',
          accessToken?.IsBluBookingActive === 'True'
        );
      else {
        if (software === 'fw') {
          setWhiteCache('isBluBookingActive', isBBActive);
        } else {
          setWhiteCache('isBluBookingActive', isBluBookingActive);
        }
      }
    } catch {}

    return (
      <Provider store={store}>
        <InitData software={software} isFocusWeb={focusWeb} />
        <Stack
          style={{
            width: '100%',
            height: '100%',
            backgroundColor: 'var(--hovered-background-color)',
          }}
        >
          <LayoutLoader />
          <InitShopSign />

          <LayoutPage
            hasAgenda={hasAgenda ?? false}
            isConsole={isConsole ?? true}
            isBBActive={isBBActive ?? true}
          />
        </Stack>
      </Provider>
    );
  }
);

export default Layout;
