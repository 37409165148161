import { BluDialog } from '@bludata/components';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material';
import { RootState } from 'packages/blu-booking/src/store';
import React, { useCallback, useMemo, useState } from 'react';
import { memo } from 'react';
import { useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';

interface EmailPopUpProps {
  open: boolean;
  type: string;
  action: (sendMail: boolean) => void;
  cancelAction: () => void;
}

export const EmailPopUpForm = memo(
  ({ open, type, action, cancelAction }: EmailPopUpProps) => {
    const [sendMail, setSendMail] = useState<boolean>(true);
    const pointOfSale = useWatch({ name: 'pointOfSaleId' });
    const { pointOfSales } = useSelector(
      (state: RootState) => state.bluBookingCalendarFilters
    );

    const getTitle = useCallback(() => {
      if (type === 'delete') return 'Conferma cancellazione appuntamento';
      if (type === 'create') return 'Conferma creazione appuntamento';
      return 'Conferma modifica appuntamento';
    }, [type]);

    const getContent = useCallback(() => {
      if (type === 'delete')
        return "Sei sicuro di voler cancellare l'appuntamento?";
      if (type === 'create')
        return "Sei sicuro di voler creare l'appuntamento?";
      return "Sei sicuro di voler modificare l'appuntamento?";
    }, [type]);

    const getButtonContent = useCallback(() => {
      if (type === 'delete') return 'Sì, cancella appuntamento';
      if (type === 'create') return 'Sì, crea appuntamento';
      return 'Sì, modifica appuntamento';
    }, [type]);

    const CheckInvioMail = useMemo(() => {
      let isBluBookingActive = false;
      let hasLayout = false;

      if (pointOfSale in pointOfSales) {
        isBluBookingActive =
          pointOfSales[pointOfSale]?.isBluBookingActive ?? false;
        hasLayout = pointOfSales[pointOfSale]?.shopSignId !== null;
      }
      if (!hasLayout) setSendMail(false);
      if (isBluBookingActive && hasLayout)
        return (
          <FormControlLabel
            label={
              <Typography variant="body1">Invia notifica al cliente</Typography>
            }
            control={
              <Checkbox
                checked={sendMail}
                onChange={() => setSendMail(!sendMail)}
              />
            }
          />
        );
      return null;
    }, [pointOfSale, pointOfSales, sendMail]);

    return (
      <BluDialog
        open={open}
        maxWidth="xs"
        dialogTitle={getTitle()}
        fullScreen={false}
        dialogContent={
          <>
            <Typography variant="body1" fontWeight="bold">
              {getContent()}
            </Typography>
            {CheckInvioMail}
          </>
        }
        dialogActions={
          <Stack flexDirection="row" gap="10px" sx={{ width: '100%' }}>
            <div style={{ flex: 1 }}>
              <Button
                variant="outlined"
                sx={{ textAlign: 'center' }}
                onClick={cancelAction}
              >
                <div style={{ width: '100%', textAlign: 'center' }}>
                  Annulla
                </div>
              </Button>
            </div>

            <Button
              variant="contained"
              color="primary"
              sx={{ textAlign: 'center' }}
              onClick={() => action(sendMail)}
            >
              <div style={{ width: '100%', textAlign: 'center' }}>
                {getButtonContent()}
              </div>
            </Button>
          </Stack>
        }
        zIndex={500}
      />
    );
  }
);
