/* eslint-disable @typescript-eslint/no-explicit-any */
import { memo, useEffect } from 'react';
import {
  DataGridPremium,
  GridRenderCellParams,
} from '@mui/x-data-grid-premium';
import moment from 'moment';
import axios from 'axios';
import useTableLoader from '@bludata/hook-usetableloader';
import { Clienti_table_interface } from '@bludata/common-interfaces';

interface TableResultProps {
  value: string;
  action?: (value: any) => void;
}

export const TableResultFocusWeb = memo(
  ({ value, action = (_) => null }: TableResultProps) => {
    const today = moment();
    const filters = [
      {
        property: 'cognome',
        operator: '!=',
        value: '< CLIENTE OCCASIONALE >',
      },
    ];
    if (value && value !== '')
      filters.push({
        property: 'cognome,nome,cognome2',

        operator: 'fulltext',

        value: value,
      });

    const {
      loading: loadingClienti,
      data: clientiList,
      fetchTableData: fetchClienti,
    } = useTableLoader<Clienti_table_interface>({
      tablename: 'clientilista',
      axios: axios,
      filters: filters as any,
      sort: [
        { property: 'cognome', direction: 'ASC' },

        { property: 'nome', direction: 'ASC' },
      ],
      pagination: {
        page: 0,
        pageSize: 100,
      },
    });

    useEffect(() => {
      fetchClienti();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);
    //"1995-11-21T00:00:00"

    return (
      <DataGridPremium
        columns={[
          {
            field: 'cognome',
            headerName: 'Cognome',
            flex: 1,
            editable: false,
            sortable: false,
            disableColumnMenu: true,
          },
          {
            field: 'nome',
            headerName: 'Nome',
            flex: 1,
            editable: false,
            sortable: false,
            disableColumnMenu: true,
          },
          {
            field: 'cognome2',
            headerName: 'Cognome 2',
            width: 130,
            editable: false,
            sortable: false,
            disableColumnMenu: true,
          },
          {
            field: 'dataNascita',
            align: 'center',
            headerAlign: 'center',
            headerName: 'Età',
            width: 60,
            editable: false,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: GridRenderCellParams) => {
              const dataNString = params.value;
              if (dataNString) {
                const dataN = moment(dataNString);
                const eta = today.diff(dataN, 'years');
                return eta;
              }

              return '';
            },
          },
          {
            field: 'indirizzo',
            headerName: 'Indirizzo',
            flex: 1,
            editable: false,
            sortable: false,
            disableColumnMenu: true,
          },
          {
            field: 'citta',
            headerName: 'Città',
            flex: 1,
            editable: false,
            sortable: false,
            disableColumnMenu: true,
          },
          {
            field: 'eMail',
            headerName: 'Email',
            flex: 1,
            editable: false,
            sortable: false,
            disableColumnMenu: true,
          },
          {
            field: 'telefono',
            headerName: 'Telefono',
            flex: 1,
            editable: false,
            sortable: false,
            disableColumnMenu: true,

            renderCell: (params: GridRenderCellParams) => {
              if (params.row.telefoninoGsm) return params.row.telefoninoGsm;

              return params.value;
            },
          },
        ]}
        rows={clientiList ? clientiList : []}
        sx={{
          height: '290.5px',
          '& .MuiDataGrid-row': {
            cursor: 'pointer',
            minHeight: '50px !important',
          },
        }}
        loading={loadingClienti && clientiList === undefined}
        density="compact"
        disableColumnMenu
        hideFooter
        editMode="row"
        getRowHeight={() => 'auto'}
        onProcessRowUpdateError={(error) => console.log(error)}
        experimentalFeatures={{ newEditingApi: true }}
        onRowClick={(event: any) => {
          action(event?.row);
        }}
      />
    );
  }
);
