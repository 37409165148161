import { memo } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import SquareIcon from '@mui/icons-material/Square';
import { Stack, Typography, Chip, Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';

export const LegendaUserTurni = memo(() => {
  const { view } = useSelector((state: RootState) => state.rotaRoot);

  return (
    <Stack
      flexDirection="unset"
      flexWrap="wrap"
      justifyContent="start"
      alignItems="start"
    >
      <Chip
        avatar={
          <SquareIcon
            sx={{
              color: 'transparent!important',
              borderRadius: '4px',
              border: '1px solid var(--highlight-color)',
              boxSizing: 'border-box',
            }}
          ></SquareIcon>
        }
        label="Orario predefinito"
        variant="outlined"
        sx={{ border: 'none' }}
      />

      <Chip
        avatar={
          <Box
            sx={{
              background: 'var(--closed-color)',
              opacity: 0.5,
              color: 'var(--color)',
              borderRadius: '4px',
              border: '1px solid var(--highlight-color)',
              boxSizing: 'border-box',
              alignContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <ClearIcon sx={{ height: '15px', width: '15px', pt: '2px' }} />
          </Box>
        }
        label="Giorno di chiusura predefinito"
        variant="outlined"
        sx={{ border: 'none' }}
      />

      <Chip
        avatar={
          <SquareIcon
            sx={{
              color: 'transparent!important',
              border: '1px solid #FFd300',
              boxSizing: 'border-box',
              background: '#FFd30020',
              borderRadius: '4px',
            }}
          ></SquareIcon>
        }
        label="Ferie"
        variant="outlined"
        sx={{ border: 'none' }}
      />
      <Chip
        avatar={
          <SquareIcon
            sx={{
              color: 'transparent!important',
              border: '1px solid #E77D22',
              boxSizing: 'border-box',
              background: '#E77D2220',
              borderRadius: '4px',
            }}
          ></SquareIcon>
        }
        label="Permesso"
        variant="outlined"
        sx={{ border: 'none' }}
      />
      <Chip
        avatar={
          <SquareIcon
            sx={{
              color: 'transparent!important',
              border: '1px solid var(--accent-pressed-color)',
              background: '#de5c5c30',
              borderRadius: '4px',
            }}
          ></SquareIcon>
        }
        label="Malattia"
        variant="outlined"
        sx={{ border: 'none' }}
      />
      <Chip
        avatar={
          <SquareIcon
            sx={{
              color: 'transparent!important',
              borderRadius: '4px',
              border: '1px solid #1375c0',
              boxSizing: 'border-box',
              background: '#1375c020',
            }}
          ></SquareIcon>
        }
        label="Altro"
        variant="outlined"
        sx={{ border: 'none' }}
      />
    </Stack>
  );
});
