import { Stack, Typography } from '@mui/material';
import { FC, memo, useMemo } from 'react';
import { RotaPopUpDatePicker } from '../../components/DatePicker';
import { SelectType } from '../../components/selectType';
import { BluTitle } from '@bludata/components';
import { useWatch } from 'react-hook-form';
import { ContextMenu } from '../../components/ContextMenu';

interface ShiftInterface {
  turniType: 'pointOfSale' | 'user';
  date: Date;
}

export const Shift: FC<ShiftInterface> = memo(({ turniType, date }) => {
  const type = useWatch({
    name: `typeShift`,
  });
  const GetContent = useMemo(() => {
    return (
      <Stack flex="1" gap={1}>
        <Stack>
          <Typography>Primo turno</Typography>
          <Stack direction="row" gap={1}>
            <RotaPopUpDatePicker
              name="startTime1"
              calendarType="Shift"
              errorProperty="firstTurnShift"
              date={date}
            />
            <RotaPopUpDatePicker
              name="endTime1"
              calendarType="Shift"
              errorProperty="firstTurnShift"
              date={date}
            />
          </Stack>
        </Stack>

        <Stack>
          <Typography>Secondo turno</Typography>
          <Stack direction="row" gap={1}>
            <RotaPopUpDatePicker
              name="startTime2"
              calendarType="Shift"
              errorProperty="secondTurnShift"
              date={date}
            />
            <RotaPopUpDatePicker
              name="endTime2"
              calendarType="Shift"
              errorProperty="secondTurnShift"
              date={date}
            />
          </Stack>
        </Stack>
      </Stack>
    );
  }, [date]);

  return (
    <Stack flex="1" gap={1}>
      <Stack direction="row" flex="1" gap={1} alignItems="center">
        <Stack flex="1">
          <BluTitle text="Turno di lavoro" />
        </Stack>

        <ContextMenu calendarType="Shift" />
      </Stack>
      {/* <SelectType calendarType="Shift" turniType={turniType} /> */}
      {type !== 'closed' && type !== 'extra-closed' && (
        <Stack>{GetContent}</Stack>
      )}
    </Stack>
  );
});
