import {
  Stack,
  IconButton,
  Tooltip,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';
import dayjs from 'dayjs';
import { FC, memo, useCallback } from 'react';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { BluTitle } from '@bludata/components';
import { getConfig, setConfig } from '../../../utility_objects/WhiteCache';

interface HeaderInterface {
  view: 'Month' | 'Agenda';
  setView: (value: 'Month' | 'Agenda') => void;
  selectedDate: Date;
  setSelectedDate: (date: Date) => void;
  setSearchPopUp: (value: boolean) => void;
}

export const Header: FC<HeaderInterface> = memo(function Header({
  view,
  setView,
  selectedDate,
  setSelectedDate,
  setSearchPopUp,
}): JSX.Element {
  const { focusCloud } = getConfig();

  const getData = useCallback(() => {
    const data = dayjs(selectedDate).locale('it').format('MMMM YYYY');
    return `${data.charAt(0).toUpperCase()}${data.slice(1)}`;
  }, [selectedDate]);

  const prevDate = useCallback(() => {
    setSelectedDate(dayjs(selectedDate).subtract(1, 'month').toDate());
  }, [selectedDate, setSelectedDate]);

  const nextDate = useCallback(() => {
    setSelectedDate(dayjs(selectedDate).add(1, 'month').toDate());
  }, [selectedDate, setSelectedDate]);

  return (
    <Stack direction="row" alignItems="center" sx={{ pb: 1 }} gap={1}>
      <Stack flexDirection="row" alignItems="center" flex="1" gap={1}>
        <Tooltip title="Precedente">
          <IconButton
            color="primary"
            onClick={prevDate}
            sx={{ width: '30px', height: '30px' }}
          >
            <ChevronLeftIcon sx={{ fontSize: '25px' }} />
          </IconButton>
        </Tooltip>
        <BluTitle text={getData()} />
        <Tooltip title="Successivo">
          <IconButton
            color="primary"
            onClick={nextDate}
            sx={{ width: '30px', height: '30px' }}
          >
            <ChevronRightIcon sx={{ fontSize: '25px' }} />
          </IconButton>
        </Tooltip>
      </Stack>

      <IconButton
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onClick={(event: any) => {
          event.preventDefault();
          const { manageOpenSearch } = getConfig();
          console.log('manageOpenSearch', manageOpenSearch);
          if (!manageOpenSearch) {
            setConfig('manageOpenSearch', true);
            setSearchPopUp(true);
          }
        }}
      >
        <SearchTwoToneIcon color="primary" />
      </IconButton>
      {focusCloud && (
        <ToggleButtonGroup
          exclusive
          value={view}
          onChange={(_, viewType) => {
            if (!viewType) return;
            setView(viewType);
          }}
        >
          <ToggleButton size="small" value="Month">
            Mese
          </ToggleButton>
          <ToggleButton size="small" value="Agenda">
            Agenda
          </ToggleButton>
        </ToggleButtonGroup>
      )}
    </Stack>
  );
});
