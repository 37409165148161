import '../../styles/chat-ai.css';

import { FC, memo, useCallback, useEffect } from 'react';
import { Divider, Stack } from '@mui/material';
import { ChatTextField } from './components/chat-textfield';
import { Messages } from './components/messages';
import { useQueryClient } from '@tanstack/react-query';
import React from 'react';
import BluContainerBlock from '../../common/ContainerBlock';
import { useMessages } from './api/get-messages';
import { useThreadInfo } from './api/get-thread';
import { ChatHeader } from './components/header';

interface ChatContainerInterface {
  threadId: string;
  setThreadId: (newThreadId?: string | null) => void;
}

export const ChatContainer: FC<ChatContainerInterface> = memo(
  ({ threadId, setThreadId }) => {
    const queryClient = useQueryClient();
    const { data: messages, isLoading: isMessageLoading } = useMessages(
      threadId || undefined
    );

    const { data: threadInfo } = useThreadInfo(threadId);

    useEffect(() => {
      queryClient.invalidateQueries(['message', threadId], {
        type: 'active',
      });
      queryClient.removeQueries(['message', threadId], {
        type: 'inactive',
      });
    }, [queryClient, threadId]);

    const isFirstMessage = useCallback((): boolean => {
      if (!messages) return false;
      if (!messages.data) return false;
      if (messages?.data.length) return false;
      return true;
    }, [messages]);

    return (
      <>
        <ChatHeader setThreadId={setThreadId} threadInfo={threadInfo} />
        <BluContainerBlock
          sx={{
            m: '4px',
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            overflow: 'hidden',
            pl: 0,
            pr: 0,
          }}
        >
          <Stack flex="1" sx={{ overflowY: 'auto' }}>
            <Messages
              threadId={threadId}
              isMessageLoading={isMessageLoading}
              messages={messages}
            />
          </Stack>
          <Divider />
          <ChatTextField
            threadId={threadId}
            setThreadId={setThreadId}
            isMessageLoading={isMessageLoading}
            threadInfo={threadInfo}
            isFirstMessage={isFirstMessage()}
          />
        </BluContainerBlock>
      </>
    );
  }
);
