import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { LoginDataInterface } from '../interfaces/login-data';

export const getUserMeLoginData =
  async (): Promise<LoginDataInterface | null> => {
    try {
      const {
        data: { data },
      } = await axios.get(`/api/User/Me/LoginData`);
      return data;
    } catch (e) {
      console.error(e);
      return null;
    }
  };

export const useUserMeLoginData = (key = '') => {
  return useQuery({
    queryFn: async () => await getUserMeLoginData(),
    queryKey: ['user', 'me', 'LoginData', key],
    retry: 2,
    retryDelay: 180000,
    staleTime: 86400000,
    cacheTime: 86400000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
};
