/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import dayjs from 'dayjs';

export const appointmentUpdate = async (
  id: string,
  appointment: any
): Promise<boolean> => {
  // dispatch(setLoadingAppointments(true));
  // const [startDate, endDate] = getRange(selectedDate!, view);
  try {
    const startTime = dayjs(appointment.timeSlotDate)
      .set('hour', new Date(appointment.start).getHours())
      .set('minutes', new Date(appointment.start).getMinutes());
    const endTime = dayjs(appointment.timeSlotDate)
      .set('hour', new Date(appointment.end).getHours())
      .set('minutes', new Date(appointment.end).getMinutes());
    await axios.put(`/api/Appointment/${id}`, {
      //   ...appointment,
      description: appointment.description,
      internalDescription: appointment.internalDescription,
      hasToSendEmail: appointment?.hasToSendEmail ?? false,
      startTime: startTime.format('YYYY-MM-DDTHH:mm:ss'),
      endTime: endTime.format('YYYY-MM-DDTHH:mm:ss'),
      isImportant: appointment.isImportant,
      email: appointment.email,
      telephone: appointment.telephone,
      name: appointment.name,
      surname: appointment.surname,
      serviceId: appointment.serviceId,
      userId: appointment.userId,
      pointOfSaleId: appointment.pointOfSaleId,
      studioId: appointment.studioId !== '' ? appointment.studioId : undefined,
      status: appointment.status,
      dateOfBirth: dayjs().format('YYYY-MM-DDTHH:mm:ss'),
      emailErrorCode: appointment.emailErrorCode,

      isIMO: appointment?.isIMO ?? false,
      externalCustomerCode: appointment.externalCustomerCode,
      isRecallOn: appointment?.isRecallOn ?? true,
      serviceIcons: appointment?.icons ?? [],
      slotOkStatus: appointment?.slotOkStatus ?? 1,
    });
    return true;
  } catch (error) {
    console.trace(error);
    return false;
  }
};
