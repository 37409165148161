/* eslint-disable @typescript-eslint/no-explicit-any */
import { store } from 'packages/blu-booking/src/store';
import { AppointmentFormData } from '../../../interfaces/AppointmentFormData';

export const getInitialData = (openEditor: any): AppointmentFormData => {
  const start = new Date();
  let data: AppointmentFormData = {
    status: 1,
    initialStatus: 0,
    hasToSendEmail: true,
    isImportant: false,
    serviceId: '',
    pointOfSaleId: '',
    studioId: '',
    userId: '',
    name: '',
    surname: '',
    email: '',
    telephone: '',
    description: '',
    timeSlotDate: start,
    initialStart: start,
    initialEnd: new Date(start.getTime() + 15 * 60000),
    start,
    duration: 15,
    end: new Date(start.getTime() + 15 * 60000),
    searchDisponibility: false,
    studioRequired: true,
    durationSet: false,
    userActive: true,
    externalCustomerCode: '',
    isIMO: false,
    cercaDisponibilitaUsed: false,
    internalDescription: '',
    isRecallOn: true,
    slotOkStatus: 0,
    icons: [],
  };
  if (openEditor !== undefined) {
    const start = new Date(openEditor.StartTime);
    const end = new Date(openEditor.EndTime);
    let updatedAt, createdAt;
    if (openEditor.updatedAt) updatedAt = new Date(openEditor.updatedAt);
    if (openEditor.createdAt) createdAt = new Date(openEditor.createdAt);
    const {
      bluBookingCalendarFilters: { services },
    } = store.getState();
    data = {
      Id: openEditor?.Id,
      status: openEditor?.status ?? 1,
      initialStatus: openEditor?.status ?? 0,
      hasToSendEmail: true,
      isImportant: openEditor?.isImportant ?? false,
      serviceId: openEditor?.serviceId ?? '',
      pointOfSaleId: openEditor?.pointOfSaleId ?? '',
      studioId: openEditor?.studioId ?? '',
      userId: openEditor?.userId ?? '',
      studioRequired:
        openEditor?.serviceId && openEditor?.serviceId in services
          ? services[openEditor?.serviceId]?.hasStudio ?? false
          : false,
      timeSlotDate: start,
      userActive: true,
      initialStart: start,
      initialEnd: end,
      start,
      duration: (end.getTime() - start.getTime()) / 60000,
      end,
      searchDisponibility: false,
      name: openEditor?.name ?? '',
      surname: openEditor?.surname ?? '',
      email: openEditor?.email ?? '',
      telephone: openEditor?.telephone ?? '',
      description: openEditor?.description ?? '',
      durationSet: false,
      externalCustomerCode: openEditor?.externalCustomerCode,
      isIMO: openEditor?.isIMO ?? false,
      updatedAt,
      createdAt,
      createdById: openEditor?.createdBy
        ? openEditor?.createdBy?.userId
        : undefined,
      createdByUsername: openEditor?.createdBy
        ? openEditor?.createdBy?.username
        : undefined,
      updatedById: openEditor?.updatedBy
        ? openEditor?.updatedBy?.userId
        : undefined,
      updatedByUsername: openEditor?.updatedBy
        ? openEditor?.updatedBy?.username
        : undefined,
      internalDescription: openEditor?.internalDescription ?? '',
      cercaDisponibilitaUsed: openEditor?.Id ? true : false,
      isRecallOn: openEditor?.isRecallOn ?? true,
      icons: openEditor?.serviceIcons ?? [],
      slotOkStatus: openEditor?.slotOkStatus ?? 0,
    };
  }

  return data;
};
