import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import ReactDOM from 'react-dom/client';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { BluCloudThemeProvider, BluThemeProvider } from '@bludata/components';
import { FC } from 'react';
import { getConfig } from './WhiteCache';

const iconDimensions = { fontSize: '80px', mb: '10px' };

interface CheckIsOkAsyncDialogInterface {
  title: string;
  message: string;
  confimButton1?: string;
  confimButton2?: string;
  declineButton?: string;
  hideDeclineButton?: boolean;
  type?: 'warning' | 'error' | 'info';
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  onConfirmClick1?: () => void;
  onConfirmClick2?: () => void;
  onDeclineClick?: () => void;
  [x: string]: any;
}

export const CheckIsOkAsyncDialog = ({
  title,
  message,
  confimButton1,
  confimButton2,
  declineButton,
  hideDeclineButton,
  type,
  maxWidth = 'sm',
  onConfirmClick1 = () => null,
  onConfirmClick2 = () => null,
  onDeclineClick = () => null,
  ...rest
}: CheckIsOkAsyncDialogInterface) => {
  return new Promise<boolean>((resolve, _) => {
    const { focusCloud } = getConfig();
    const newRootDiv = document.createElement('div');
    newRootDiv.id = 'temp-react-async-confirm-container';
    document.body.appendChild(newRootDiv);
    const newRoot = ReactDOM.createRoot(newRootDiv);
    const themeMode =
      window.document.querySelector('html')?.getAttribute('dark-mode') ===
      'true'
        ? 'dark'
        : 'light';

    const destroyTempRoot = () => {
      document.body.removeChild(newRootDiv);
      newRoot.unmount();
    };

    const onConfirm1 = () => {
      resolve(true);
      onConfirmClick1();
      destroyTempRoot();
    };
    const onConfirm2 = () => {
      resolve(true);
      onConfirmClick2();
      destroyTempRoot();
    };
    const onCancel = () => {
      resolve(false);
      onDeclineClick();
      destroyTempRoot();
    };

    const dialogConfirm = (
      <Dialog
        open={true}
        fullWidth
        maxWidth={maxWidth}
        fullScreen={false}
        {...rest}
      >
        <DialogTitle
          style={{
            backgroundColor: 'var(--background-color) !important',
            color: 'var(--color) !important',
          }}
        >
          {type ? (
            <Stack flexDirection="column" alignItems="center">
              {type === 'warning' && (
                <WarningAmberIcon color="warning" sx={iconDimensions} />
              )}
              {type === 'info' && (
                <InfoOutlinedIcon color="primary" sx={iconDimensions} />
              )}
              {type === 'error' && (
                <ErrorOutlineOutlinedIcon color="error" sx={iconDimensions} />
              )}
              {title ? title : 'Attenzione'}
            </Stack>
          ) : (
            <div>{title ? title : 'Attenzione'}</div>
          )}
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2" component="div">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </Typography>
        </DialogContent>
        <DialogActions>
          {!hideDeclineButton && (
            <Button
              variant="outlined"
              sx={{ justifyContent: 'center' }}
              onClick={onCancel}
            >
              {declineButton ? declineButton : 'No'}
            </Button>
          )}
          <Button
            variant="outlined"
            sx={{ justifyContent: 'center' }}
            onClick={onConfirm1}
          >
            {confimButton1 ? confimButton1 : 'Si'}
          </Button>
          <Button
            variant="outlined"
            sx={{ justifyContent: 'center' }}
            onClick={onConfirm2}
          >
            {confimButton2 ? confimButton2 : 'Si'}
          </Button>
        </DialogActions>
      </Dialog>
    );

    // eslint-disable-next-line no-extra-boolean-cast
    if (focusCloud) {
      newRoot.render(
        <BluCloudThemeProvider themeMode={themeMode}>
          {dialogConfirm}
        </BluCloudThemeProvider>
      );
    } else {
      newRoot.render(
        <BluThemeProvider themeMode={themeMode}>
          {dialogConfirm}
        </BluThemeProvider>
      );
    }
  });
};
