/* eslint-disable @typescript-eslint/no-explicit-any */
import './PersonalCalendar.css';

import { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Stack } from '@mui/material';
import { BluContainerBlock } from '@bludata/components';
import {
  PersonalCalendarResetSelected,
  PersonalCalendarSetSelectedDate,
  PersonalCalendarSetUser,
} from './store';
import dayjs from 'dayjs';
import { isSafari } from 'react-device-detect';
import { AppDispatch, RootState } from 'packages/blu-booking/src/store';
import { setConfig } from '../../utility_objects/WhiteCache';
import { setRefetch } from '../../store/Appointment';
import { fetchAppointments } from 'packages/blu-booking/src/data_fetching/Appointment/Appointments';
import { CalendarHeader } from '../../UI/CalendarHeader/CalendarHeader';
import { Header } from './UI/Header';
import { Filters } from './UI/Filters';
import { PersonalCalendarInner } from './PersonalCalendarInner';
import { AppointmentSearch } from '../../UI/AppointmentSearch/AppointmentSearch';

export const PersonalCalendar = memo(function Calendar(): JSX.Element {
  const dispatch = useDispatch<AppDispatch>();
  const { filtersLoad, users } = useSelector(
    (state: RootState) => state.bluBookingCalendarFilters
  );

  const { refetch } = useSelector(
    (state: RootState) => state.bluBookingaAppointments
  );

  const {
    selectedDate,
    view,
    selectedServices: checkedServices,
    selectedUsers: checkedUsers,
    client,
  } = useSelector((state: RootState) => state.bluBookingPersonalCalendar);

  const uderlineNotification = (event: any) => {
    if (event && event.detail) {
      setConfig('underline', event.detail?.id);

      const date = dayjs(selectedDate).format('YYYY/MM/DD');
      const appointmentDate = dayjs(event.detail.startTime).format(
        'YYYY/MM/DD'
      );
      const isSameUser = checkedUsers[0]?.id === event.detail?.userId;

      if (!isSameUser && event.detail?.userId in users)
        dispatch(PersonalCalendarSetUser([users[event.detail?.userId]]));

      if (checkedServices.length > 0)
        dispatch(PersonalCalendarResetSelected('selectedServices'));

      if (date === appointmentDate) {
        dispatch(setRefetch());
        return;
      }
      dispatch(PersonalCalendarSetSelectedDate(event.detail?.startTime));
    }
    return;
  };

  useEffect(() => {
    window.addEventListener('BluBookingNotification', uderlineNotification);

    // window.dispatchEvent(
    //   new CustomEvent("BluBookingDataLoaded", { detail: true })
    // );

    return () => {
      window.removeEventListener(
        'BluBookingNotification',
        uderlineNotification
      );
    };
  });

  useEffect(() => {
    if (filtersLoad)
      dispatch(
        fetchAppointments({
          selectedDate,
          checkedServices,
          checkedUsers: checkedUsers.map((user: any) => user.id).flat(),
          checkedPointOfSales: [],
          checkedStudi: [],
          view,
          email: client?.email,
        })
      );
  }, [selectedDate, checkedServices, checkedUsers, dispatch, view, refetch, client?.email, filtersLoad]);

  const handleSearch = useCallback(
    (appointment: any) => {
      const d = dayjs(selectedDate).format('DD/MM/YYYY');

      if (
        checkedUsers.length > 0 &&
        appointment?.userId &&
        users &&
        checkedUsers[0]?.id !== appointment?.userId &&
        appointment?.userId in users
      )
        dispatch(PersonalCalendarSetUser([users[appointment?.userId]]));

      if (checkedServices.length > 0)
        dispatch(PersonalCalendarResetSelected('selectedServices'));

      if (d !== appointment?.date)
        dispatch(
          PersonalCalendarSetSelectedDate(
            new Date(dayjs(appointment?.date, 'DD/MM/YYYY').format())
          )
        );
      else dispatch(setRefetch());
    },
    [checkedServices.length, checkedUsers, dispatch, selectedDate, users]
  );

  return (
    <>
      <Stack
        key="PersonalCalendar"
        className={isSafari ? 'safari-scroll' : ''}
        flex={1}
        flexDirection="column"
      >
        <AppointmentSearch action={handleSearch} />

        <CalendarHeader title="Agenda / Calendario personale" id="personal" />
        <BluContainerBlock
          key="PersonalCalendar1"
          sx={{
            m: '4px',
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            overflow: 'hidden',
          }}
        >
          <>
            <Header />
            <Box
              sx={{
                backgroundColor: 'var(--base-foreground-color)',
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                overflow: 'hidden',
              }}
            >
              <PersonalCalendarInner />
            </Box>
          </>
        </BluContainerBlock>
      </Stack>
      <Filters />
    </>
  );
});
