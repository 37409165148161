import { MenuItem, Stack, Typography } from '@mui/material';
import { FC, memo, useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { BluSelect } from '@bludata/components';

interface SelectTypeInterface {
  turniType: 'pointOfSale' | 'user';
}

export const SelectType: FC<SelectTypeInterface> = memo(({ turniType }) => {
  const [type, defaultType, extraCalendarType] = useWatch({
    name: [`typeCalendar`, `defaultTypeCalendar`, `calendarExtraCalendarType`],
  });
  const { watch, setValue, reset, trigger } = useFormContext();

  const changeValue = useCallback(
    (t: string) => {
      if (turniType === 'user' && t.includes('extra')) {
        {
          const [newType, newExtraCalendarType] = t.split('__');
          setValue(`typeCalendar`, newType);
          setValue(`typeShift`, newType);
          setValue('calendarExtraCalendarType', parseInt(newExtraCalendarType));
          setValue('shiftExtraCalendarType', parseInt(newExtraCalendarType));

          if (newExtraCalendarType === '2' || newExtraCalendarType === '3') {
            reset({
              ...watch(),
              [`startTime1Calendar`]: null,
              [`startTime2Calendar`]: null,
              [`endTime1Calendar`]: null,
              [`endTime2Calendar`]: null,
              [`startTime1Shift`]: null,
              [`startTime2Shift`]: null,
              [`endTime1Shift`]: null,
              [`endTime2Shift`]: null,
            });
            trigger();
          }
        }

        return;
      }

      setValue(`typeCalendar`, t);
      setValue(`typeShift`, t);
    },
    [reset, setValue, trigger, turniType, watch]
  );

  const GetValue = useCallback(
    (value: string) => {
      if (turniType === 'user') {
        if (value === 'default') return 'Orario predefinito';
        if (value === 'extra__0') return 'Altro';
        if (value === 'extra__1') return 'Permesso';
        if (value === 'extra__2') return 'Malattia';
        if (value === 'extra__3') return 'Ferie';
        if (value === 'extra-closed') return 'Assenza straordinaria';
        if (value === 'closed') return 'Orario predefinito';
      }

      if (defaultType !== 'closed') {
        if (value === 'default') return 'Orario predefinito';
        if (value === 'extra') return 'Apertura straordinaria';
        if (value === 'extra-closed') return 'Chiusura straordinaria';
      }
      if (value === 'closed') return 'Chiusura predefinita';
      if (value === 'extra') return 'Apertura straordinaria';
      return '';
    },
    [defaultType, turniType]
  );

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <Stack flex={1}>
      <Typography>Tipo</Typography>
      {turniType === 'user' ? (
        <BluSelect
          value={type === 'extra' ? `${type}__${extraCalendarType}` : type}
          renderValue={(value: any) => {
            return <>{GetValue(value)}</>;
          }}
          onChange={(event: any) => {
            changeValue(event.target.value);
          }}
        >
          {defaultType === 'closed' && (
            <MenuItem value="closed">Orario predefinito</MenuItem>
          )}
          {defaultType === 'default' && (
            <MenuItem value="default">Orario predefinito</MenuItem>
          )}

          <MenuItem value="extra__0">Altro</MenuItem>
          <MenuItem value="extra__1">Permesso</MenuItem>
          <MenuItem value="extra__2">Malattia</MenuItem>
          <MenuItem value="extra__3">Ferie</MenuItem>
        </BluSelect>
      ) : (
        <BluSelect
          value={type}
          renderValue={(value: any) => {
            return <>{GetValue(value)}</>;
          }}
          onChange={(event: any) => {
            changeValue(event.target.value);
          }}
        >
          {defaultType === 'closed' && (
            <MenuItem value="closed">Chiusura predefinita</MenuItem>
          )}
          {defaultType === 'default' && (
            <MenuItem value="default">Orario predefinito</MenuItem>
          )}

          <MenuItem value="extra">Apertura straordinaria</MenuItem>

          {defaultType === 'default' && (
            <MenuItem value="extra-closed">Chiusura straordinaria</MenuItem>
          )}
        </BluSelect>
      )}
    </Stack>
  );
});
