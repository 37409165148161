import { FC, memo, useCallback, useEffect } from 'react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BluAsyncDialog, BluLoader } from '@bludata/components';
import { useFormContext } from 'react-hook-form';
import { IconButton, Stack, Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  DataGridPremium,
  GridRenderCellParams,
} from '@mui/x-data-grid-premium';
import { useQueryClient } from '@tanstack/react-query';
import { AppDispatch, RootState } from 'packages/blu-booking/src/store';
import { useShopSignLink } from 'packages/blu-booking/src/data_fetching/ShopSign/ShopSignLink';
import { deleteShopSignLink } from 'packages/blu-booking/src/data_fetching/ShopSign/ShopSignLinkDelete';
import { setLink } from '../../store/CreateLink';
import { ShopSignLinkInterface } from 'packages/blu-booking/src/interfaces/ShopSignLink';

interface FormInnerInterface {
  initialData: ShopSignLinkInterface;
  setStep: (value: number) => void;
  setAlert: (value: string) => void;
  setLoading: (value: boolean) => void;
  setIsFirst: (value: boolean) => void;
}

export const LinkList: FC<FormInnerInterface> = memo(
  ({ initialData, setStep, setAlert, setLoading, setIsFirst }) => {
    const { formLoader } = useSelector(
      (state: RootState) => state.bluBookingAppointmentForm
    );

    const queryClient = useQueryClient();
    const { link } = useSelector(
      (state: RootState) => state.bluBookingCreateLink
    );

    const dispatch = useDispatch<AppDispatch>();
    const { reset } = useFormContext();

    const { data, isLoading } = useShopSignLink({});

    useEffect(() => {
      if (
        data &&
        (data as ShopSignLinkInterface[]).length === 0 &&
        !isLoading
      ) {
        setIsFirst(true);
        setStep(1);
      } else setIsFirst(false);
    }, [data, isLoading, setIsFirst, setLoading, setStep]);

    // const createNew = useCallback(() => {
    //   dispatch(setLink(undefined));
    //   reset(initialData);

    //   setStep(1);
    // }, [dispatch, initialData, reset, setStep]);

    const deleteShortLink = useCallback(
      async (params: any) => {
        BluAsyncDialog({
          title: 'Attenzione',
          type: 'error',
          message: 'Sei sicuro di voler cancellare il link personalizzato?',
          confimButton: 'Conferma',
          onConfirmClick: async () => {
            setLoading(true);
            const result = await deleteShopSignLink(params.row.id);
            if (!result) {
              setLoading(false);

              setAlert(
                'Errore durante la cancellazione del Link personalizzato '
              );
              return;
            }

            queryClient.invalidateQueries(['shop-sign-link'], {
              type: 'active',
            });
            queryClient.removeQueries(['shop-sign-link'], {
              type: 'inactive',
            });
            dispatch(setLink(undefined));
            reset(initialData);
            setLoading(false);
          },
          declineButton: 'Annulla',
          sx: { '& .MuiDialog-paper': { maxWidth: '370px' } },
        });
      },
      [dispatch, initialData, queryClient, reset, setAlert, setLoading]
    );

    return (
      <>
        <BluLoader open={formLoader} sx={{ zIndex: '1400' }} />

        <Typography sx={{ pb: 1 }}>
          Puoi condividere il form di prenotazione in pagine web, social, etc.
          utilizzando il link che hai creato. Nella tabella visualizzi i link
          creati. Cliccando sull’icona alla destra del link, puoi copiarlo e
          inserirlo dove desideri.
        </Typography>
        <Stack flex="1" sx={{ height: '500px' }}>
          <DataGridPremium
            columns={[
              {
                field: 'id',
                hide: true,
              },
              {
                field: 'shopSignId',
                hide: true,
              },
              {
                field: 'pointOfSaleId',
                hide: true,
              },
              {
                field: 'servideId',
                hide: true,
              },
              {
                field: 'userId',
                hide: true,
              },
              {
                field: 'studioId',
                hide: true,
              },
              {
                field: 'companyId',
                hide: true,
              },
              {
                field: 'urlLink',
                hide: true,
              },
              {
                field: 'token',
                hide: true,
              },
              {
                field: 'shortLink',
                headerName: 'Link',
                flex: 1,
                hide: true,
                minWidth: 310,
                editable: false,
                sortable: false,
                disableColumnMenu: true,
                renderCell: (params: GridRenderCellParams) => {
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  return <>{`${params.row?.urlLink}${params.value}`}</>;
                },
              },
              {
                field: 'description',
                headerName: 'Titolo',
                flex: 2,
                editable: false,
                sortable: false,
                disableColumnMenu: true,
              },
              {
                field: 'shopSignName',
                headerName: 'Layout',
                flex: 1,
                editable: false,
                sortable: false,
                disableColumnMenu: true,
              },

              {
                field: 'pointOfSaleName',
                headerName: 'Punto vendita',
                flex: 1,
                editable: false,
                sortable: false,
                disableColumnMenu: true,
                hide: true,
              },
              {
                field: 'studioName',
                headerName: 'Studio',
                flex: 1,
                editable: false,
                sortable: false,
                disableColumnMenu: true,
                hide: true,
              },
              {
                field: 'serviceName',
                headerName: 'Servizio',
                flex: 1,
                editable: false,
                sortable: false,
                disableColumnMenu: true,
                hide: true,
              },
              {
                field: 'username',
                headerName: 'Utente',
                editable: false,
                sortable: false,
                disableColumnMenu: true,
                hide: true,
              },
              {
                field: 'surname',
                headerName: 'Cognome',
                editable: false,
                sortable: false,
                disableColumnMenu: true,
                hide: true,
              },
              {
                field: 'name',
                headerName: 'Nome',
                editable: false,
                sortable: false,
                disableColumnMenu: true,
                hide: true,
              },

              {
                field: 'email',
                headerName: 'Email',
                editable: false,
                sortable: false,
                disableColumnMenu: true,
                hide: true,
              },
              {
                field: 'telephone',
                headerName: 'Telefono',
                editable: false,
                sortable: false,
                disableColumnMenu: true,
                hide: true,
              },
              {
                field: 'copy',
                headerName: '',
                sortable: false,
                width: 30,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                renderCell: (params: any) => {
                  return (
                    <IconButton
                      color="primary"
                      size="small"
                      onClick={(e) => {
                        navigator.clipboard.writeText(
                          `${params?.row?.urlLink ?? ''}${
                            params?.row?.shortLink ?? ''
                          }`
                        );
                        setAlert('Link copiato');
                      }}
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  );
                },
              },
            ]}
            rows={data ? (data as ShopSignLinkInterface[]) : []}
            sx={{
              height: '290.5px',
              '& .MuiDataGrid-row': {
                cursor: 'pointer',
                minHeight: '50px !important',
              },
            }}
            loading={isLoading && data === undefined}
            density="compact"
            disableColumnMenu
            hideFooter
            editMode="row"
            getRowHeight={() => 'auto'}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onProcessRowUpdateError={(error: any) => console.log(error)}
            experimentalFeatures={{ newEditingApi: true }}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onRowClick={(event: any) => {
              dispatch(setLink(event?.row));
              reset(event?.row);
            }}
            selectionModel={link?.id}
          />
        </Stack>
      </>
    );
  }
);
