import { IconButton, Stack, TextField } from '@mui/material';
import {} from 'nanoid';
import { memo, useCallback } from 'react';
import { setSelectedDate } from '../../store/root';
import { AppDispatch, RootState } from '../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { BluDatePicker } from '@bludata/components';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export const MonthYearPicker = memo(() => {
  const { selectedDate, view } = useSelector(
    (state: RootState) => state.rotaRoot
  );
  const dispatch = useDispatch<AppDispatch>();

  const handlePickerDayChange = useCallback(
    (e: any) => {
      dispatch(setSelectedDate(e.toDate()));
    },
    [dispatch]
  );

  const handlePickerChange = useCallback(
    (e: any) => {
      const date = dayjs(e);
      if (dayjs().isSame(date, 'month'))
        dispatch(setSelectedDate(dayjs().toDate()));
      else dispatch(setSelectedDate(date.set('date', 1).toDate()));
    },
    [dispatch]
  );

  const prevDate = () => {
    if (view === 'day')
      dispatch(
        setSelectedDate(dayjs(selectedDate).subtract(1, 'days').toDate())
      );
    else
      dispatch(
        setSelectedDate(dayjs(selectedDate).subtract(1, 'month').toDate())
      );
  };

  const nextDate = () => {
    if (view === 'day')
      dispatch(setSelectedDate(dayjs(selectedDate).add(1, 'days').toDate()));
    else
      dispatch(setSelectedDate(dayjs(selectedDate).add(1, 'month').toDate()));
  };

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="start"
      spacing={2}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'it'}>
        {view === 'day' ? (
          <Stack direction="row">
            {/* <Tooltip
              title="Precedente"
              sx={{ width: "30px", height: "30px", mt: "20px" }}
            >
              <IconButton color="primary" onClick={prevDate}>
                <ChevronLeftIcon />
              </IconButton>
            </Tooltip> */}
            <DatePicker
              views={['day', 'month', 'year']}
              label="Seleziona una data"
              minDate={new Date(`${new Date().getFullYear() - 2}-01-01`)}
              maxDate={new Date(`${new Date().getFullYear() + 2}-12-31`)}
              value={selectedDate}
              onChange={handlePickerDayChange}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  sx={{
                    '& .MuiInputAdornment-root': {
                      ml: '0 !important',
                      mr: '8px',
                    },
                    '& .MuiButtonBase-root': {
                      width: '30px',
                      height: '30px',
                    },
                    '& .MuiSvgIcon-root': {
                      width: '25px',
                      height: '25px',
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <>
                        <IconButton
                          color="primary"
                          onClick={prevDate}
                          sx={{ width: '30px', height: '30px' }}
                        >
                          <ChevronLeftIcon width="25px" height="25px" />
                        </IconButton>
                        <IconButton
                          color="primary"
                          onClick={nextDate}
                          sx={{ width: '30px', height: '30px' }}
                        >
                          <ChevronRightIcon width="25px" height="25px" />
                        </IconButton>
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </Stack>
        ) : (
          <DatePicker
            views={['month', 'year']}
            label="Mese e anno"
            minDate={new Date(`${new Date().getFullYear() - 2}-01-01`)}
            maxDate={new Date(`${new Date().getFullYear() + 2}-12-31`)}
            value={selectedDate}
            onChange={handlePickerChange}
            renderInput={(params: any) => (
              <TextField
                {...params}
                sx={{
                  '& .MuiInputAdornment-root': {
                    ml: '0 !important',
                    mr: '8px',
                  },
                  '& .MuiButtonBase-root': {
                    width: '30px',
                    height: '30px',
                  },
                  '& .MuiSvgIcon-root': {
                    width: '25px',
                    height: '25px',
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <>
                      <IconButton
                        color="primary"
                        onClick={prevDate}
                        sx={{ width: '30px', height: '30px' }}
                      >
                        <ChevronLeftIcon width="25px" height="25px" />
                      </IconButton>
                      <IconButton
                        color="primary"
                        onClick={nextDate}
                        sx={{ width: '30px', height: '30px' }}
                      >
                        <ChevronRightIcon width="25px" height="25px" />
                      </IconButton>
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        )}
      </LocalizationProvider>
    </Stack>
  );
});
