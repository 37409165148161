/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import dayjs, { Dayjs } from 'dayjs';
import { DateSlotInterface } from '../../interfaces/DateSlot';

export const appointmentSlot = async (
  startDate: string,
  endDate: string,
  serviceId?: string,
  pointOfSaleId?: string,
  studioId?: string,
  userId?: string
): Promise<Dayjs[]> => {
  try {
    // TOKEN()
    const { data } = await axios.get(`/api/AppointmentSlot/`, {
      params: {
        serviceId,
        pointOfSaleId,
        userId,
        studioId,
        startDate,
        endDate,
      },
    });
    if ('data' in data) {
      return data.data.map(({ date }: any) => dayjs(date, 'YYYY-MM-DD')).flat();
    }
    return [];
  } catch (err) {
    console.trace(err);
    return [];
  }
};

export const useAppointmentSlot = (
  startDate: string,
  endDate: string,
  serviceId?: string,
  pointOfSaleId?: string,
  studioId?: string,
  userId?: string
) => {
  return useQuery({
    queryFn: async () =>
      await appointmentSlot(
        startDate,
        endDate,
        serviceId,
        pointOfSaleId,
        studioId,
        userId
      ),
    queryKey: [
      'appointment-slot',
      `${startDate}-${endDate}`,
      serviceId,
      pointOfSaleId,
      studioId,
      userId,
    ],
    retry: 2,
    retryDelay: 2000,
    useErrorBoundary: true,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: true,
    cacheTime: 0,
    staleTime: 0,
    select: (data: Dayjs[]) => normalize(data, startDate, endDate),
  });
};

const normalize = (
  data: Dayjs[],
  startDate: string,
  endDate: string
): DateSlotInterface => {
  let start = dayjs(startDate);
  const end = dayjs(endDate);

  let result: DateSlotInterface = {};
  while (start.diff(end) <= 0) {
    result = {
      ...result,
      [start.format('YYYY/MM/DD')]: {
        date: start,
        daysOfWeek: start.day(),
        day: start.date(),
        active: false,
      },
    };
    start = start.add(1, 'days');
  }
  for (const date of data) {
    const formattedDate = date.format('YYYY/MM/DD');
    if (formattedDate in result) {
      result[formattedDate as keyof DateSlotInterface].active = true;
    }
  }
  return result;
};
