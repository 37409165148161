import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  MessageStatusEnum,
  MessageTypeEnum,
  NotificationTypeEnum,
} from 'packages/blu-booking/src/interfaces/Notifications';

export interface NotificationsSliceState {
  pageSize: number;
  page: number;
  openClientFilter: boolean;
  clientFilter?: {
    name: string;
    surname: string;
    email: string;
    telephone?: string;
    isImo?: boolean;
    externalCustomerCode?: string;
  };
  messageTypeFilter?: MessageTypeEnum;
  messageStatusFilter?: MessageStatusEnum;
  notificationTypeFilter?: NotificationTypeEnum;
  fromToday: boolean;
}

const initialState: NotificationsSliceState = {
  pageSize: 10,
  page: 1,
  openClientFilter: false,
  fromToday: false,
};

const NotificationsSlice = createSlice({
  name: 'BluBookingNotifications',
  initialState,
  reducers: {
    setPage(state: NotificationsSliceState, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setPageSize(state: NotificationsSliceState, action: PayloadAction<number>) {
      state.pageSize = action.payload;
    },
    setOpenClientFilter(
      state: NotificationsSliceState,
      action: PayloadAction<boolean>
    ) {
      state.openClientFilter = action.payload;
    },
    setClientIdFilter(
      state: NotificationsSliceState,
      action: PayloadAction<
        | {
            name: string;
            surname: string;
            email: string;
            telephone?: string;
            isImo?: boolean;
            externalCustomerCode?: string;
          }
        | undefined
      >
    ): void {
      state.clientFilter = action.payload;
    },
    setMessageTypeFilter(
      state: NotificationsSliceState,
      action: PayloadAction<MessageTypeEnum | undefined>
    ): void {
      state.messageTypeFilter = action.payload;
    },
    setMessageStatusFilter(
      state: NotificationsSliceState,
      action: PayloadAction<MessageStatusEnum | undefined>
    ): void {
      state.messageStatusFilter = action.payload;
    },
    setNotificationTypeFilter(
      state: NotificationsSliceState,
      action: PayloadAction<NotificationTypeEnum | undefined>
    ): void {
      state.notificationTypeFilter = action.payload;
    },
    setFromToday(
      state: NotificationsSliceState,
      action: PayloadAction<boolean>
    ): void {
      state.fromToday = action.payload;
    },
  },
});

export const {
  setOpenClientFilter,
  setPage,
  setPageSize,
  setClientIdFilter,
  setMessageTypeFilter,
  setMessageStatusFilter,
  setNotificationTypeFilter,
  setFromToday,
} = NotificationsSlice.actions;

export default NotificationsSlice.reducer;
