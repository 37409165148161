import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import { Stack } from '@mui/system';
import { memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Appointment_interface } from 'packages/blu-booking/src/interfaces/Appointment';
import { UserInterface } from 'packages/blu-booking/src/interfaces/User';
import { RootState, AppDispatch } from 'packages/blu-booking/src/store';
import { wc_hex_is_light } from 'packages/blu-booking/src/utility_objects/ColorManager';
import { getAppointmentDetails } from 'packages/blu-booking/src/data_fetching/Appointment/AppointmentDetails';
import { getConfig } from '../../../utility_objects/WhiteCache';
import { setOpenForm } from '../../../store/AppointmentForm';
import { setEmailPopup } from '../../../store/Calendar';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Buttons = memo((props: any) => {
  const { userId } = props;
  const { users } = useSelector(
    (state: RootState) => state.bluBookingCalendarFilters
  );
  let user: UserInterface | undefined = undefined;
  if (userId !== undefined) user = users[userId] as UserInterface;
  const dispatch = useDispatch<AppDispatch>();

  const { sessionProfilo } = getConfig();

  const close = useCallback(() => {
    const popups = Array.from(document.getElementsByClassName('e-popup-open'));
    if (popups && popups.length > 0) {
      for (const popup of popups) {
        popup.classList.remove('e-popup-open');
        popup.classList.add('e-popup-close');
      }
    }
  }, []);

  const modify = useCallback(async () => {
    const data = await getAppointmentDetails(props?.Id);
    dispatch(setOpenForm({ ...data, userUsername: props?.username }));
  }, [dispatch, props]);

  const buttonClose = useMemo(() => {
    return (
      <IconButton
        onClick={() => close()}
        sx={{ width: '15px', height: '15px' }}
      >
        <CloseIcon
          style={{
            fontSize: '15px',
            color: 'inherit',
          }}
        />
      </IconButton>
    );
  }, [close]);

  const buttonModify = useMemo(() => {
    if (sessionProfilo.agendaModifica)
      return (
        <IconButton
          onClick={() => modify()}
          sx={{ width: '15px', height: '15px' }}
        >
          <EditIcon
            style={{
              fontSize: '15px',
              color: wc_hex_is_light(user?.color ?? '#000000')
                ? 'black'
                : 'white',
            }}
          />
        </IconButton>
      );
    return null;
  }, [modify, sessionProfilo.agendaModifica, user?.color]);

  const deleteAction = useCallback(async () => {
    dispatch(
      setEmailPopup({
        id: props.Id,
        appointment: props as Appointment_interface,
        emailSet: props?.email !== undefined && props?.email !== '',
        type: 'delete',
      })
    );
    return;
  }, [dispatch, props]);

  const buttonDelete = useMemo(() => {
    if (sessionProfilo.agendaCancellazione)
      return (
        <IconButton
          onClick={deleteAction}
          sx={{ width: '15px', height: '15px' }}
        >
          <DeleteIcon
            style={{
              fontSize: '15px',
              color: wc_hex_is_light(user?.color ?? '#000000')
                ? 'black'
                : 'white',
            }}
          />
        </IconButton>
      );
    return null;
  }, [deleteAction, sessionProfilo.agendaCancellazione, user?.color]);

  return (
    <Stack flexDirection="row" gap="10px">
      <div style={{ flex: 1 }}></div>
      {buttonDelete}
      {buttonModify}
      {buttonClose}
    </Stack>
  );
});
