import {
  Dialog,
  DialogContent,
  DialogContentText,
  Divider,
  Stack,
  DialogActions,
  Button,
  Box,
  Typography,
} from '@mui/material';
import { FC, memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { nanoid } from 'nanoid';
import { BluAutoComplete } from '@bludata/components';
import { AppDispatch, RootState } from '../../../../../../store';
import {
  setAddPointOfSalePopUp,
  setDefaultHours,
} from '../../../../store/default-hours';
import {
  DefaultHoursPointOfSale,
  getDefaultHoursPointOfSale,
} from '../../utils/default-hours-point-of-sale';
import { getUsersWhiteCache } from '../../../../lib/white-cache';

interface AddPointOfSaleInterface {
  setPointOfSaleId: (value: string) => void;
}

export const AddPointOfSale: FC<AddPointOfSaleInterface> = memo(
  ({ setPointOfSaleId }) => {
    const { addPointOfSalePopUp, defaultHours } = useSelector(
      (state: RootState) => state.newUserDefaultHours
    );
    const { isFocusWeb } = getUsersWhiteCache();

    const { pointOfSales } = useSelector(
      (state: RootState) => state.newUsersRoot
    );
    const [filiale, setFiliale] = useState<string | null>(null);
    const dispatch: AppDispatch = useDispatch();

    useEffect(() => {
      if (filiale && defaultHours && filiale in defaultHours) {
        setFiliale(null);
      }
    }, [addPointOfSalePopUp, defaultHours, filiale, pointOfSales]);

    const getValue = useCallback(() => {
      if (filiale && pointOfSales && filiale in pointOfSales) {
        return pointOfSales[filiale];
      }
      return null;
    }, [filiale, pointOfSales]);

    const getOptions = useCallback(() => {
      const keys = Object.keys(defaultHours);

      return Object.values(pointOfSales).filter(
        (value: any) =>
          // keys.indexOf(value.id) === -1 &&
          keys.indexOf(value.pointOfSaleId) === -1
      );
    }, [defaultHours, pointOfSales]);

    const handleSave = useCallback(async () => {
      if (filiale !== null) {
        const temp = { ...defaultHours };
        temp[filiale] = { ...(getDefaultHoursPointOfSale(filiale) as any) };
        dispatch(setDefaultHours(temp));
        setPointOfSaleId(filiale);
        dispatch(setAddPointOfSalePopUp(false));
      }
    }, [defaultHours, dispatch, filiale, setPointOfSaleId]);

    const handleClose = useCallback(() => {
      dispatch(setAddPointOfSalePopUp(false));
    }, [dispatch]);

    return (
      <Dialog
        fullScreen={false}
        open={addPointOfSalePopUp}
        //   onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        {/* <DialogTitle id="alert-dialog-title">
          Seleziona una filiale da aggiungere
        </DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Divider sx={{ opacity: 0, mb: '24px!important' }} />
            <Typography variant="body1" color="var(--color)">
              Seleziona una filiale da aggiungere
            </Typography>
            <Stack direction="row" spacing={0.5}>
              <BluAutoComplete
                disableClearable
                sx={{ minWidth: '400px' }}
                renderOption={(props: any, option: any) => {
                  return (
                    <Box component="li" {...props} key={nanoid()}>
                      {option.branch}
                    </Box>
                  );
                }}
                getOptionLabel={(option: any) => {
                  return option.branch;
                }}
                data={getOptions()}
                value={getValue()}
                onChange={(event: any, newValue: any) => {
                  if (isFocusWeb) setFiliale(newValue?.blubookingId);
                  else setFiliale(newValue?.id);
                }}
                isOptionEqualToValue={(option: any, value: any) => {
                  return option.id === value.id;
                }}
              />
            </Stack>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant={'outlined'} onClick={handleClose}>
            Annulla
          </Button>
          <Button onClick={handleSave}>Conferma</Button>
        </DialogActions>
      </Dialog>
    );
  }
);
