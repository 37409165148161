import { BluDialog, BluTextField } from '@bludata/components';
import { Button, Divider, Stack, Typography } from '@mui/material';
import { FC, useCallback, useState } from 'react';
import { AppDispatch, RootState } from '../../../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { setOpenPasswordDialog } from '../../../../store/root';
import md5 from 'md5';

// const md5 = require('md5');

interface PasswordDialogInterface {
  setPassword: (password: string) => void;
}

const PasswordDialog: FC<PasswordDialogInterface> = ({
  setPassword,
}): JSX.Element => {
  const { openPasswordDialog } = useSelector(
    (state: RootState) => state.newUsersRoot
  );
  const dispatch: AppDispatch = useDispatch();

  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordField, setPasswordField] = useState('');

  const enablePasswordSave = useCallback(() => {
    return passwordField === confirmPassword && passwordField.length >= 8;
  }, [confirmPassword, passwordField]);

  return (
    <BluDialog
      type="warning"
      confirmButton="Aggiorna"
      controllConfirm={false}
      fullScreen={false}
      dialogContent={
        <div style={{ display: 'flex' }}>
          <Stack direction="column" flex={1} mb={1}>
            <Typography mb={1}>
              La nuova password deve contenere almeno 8 caratteri
            </Typography>
            <BluTextField
              id="password"
              type="password"
              label="Password*"
              fullWidth
              value={passwordField}
              onChange={(e) => {
                setPasswordField(e.target.value);
              }}
              inputProps={{
                autocomplete: 'new-password',
              }}
            />
            <Divider sx={{ opacity: 0, mb: '10px!important' }} />
            <BluTextField
              id="confermapassword"
              type="password"
              fullWidth
              value={confirmPassword}
              label="Conferma password*"
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
              inputProps={{
                autocomplete: 'new-password',
              }}
            />
          </Stack>
        </div>
      }
      open={openPasswordDialog}
      dialogTitle={'Cambio password'}
      maxWidth="sm"
      dialogActions={
        <>
          <Button
            onClick={() => {
              setPasswordField('');
              setConfirmPassword('');
              dispatch(setOpenPasswordDialog(false));
              //   props.closeDialog("");
              //setOpenPasswordDialog(false)
            }}
            variant="outlined"
          >
            Annulla
          </Button>
          <Button
            disabled={!enablePasswordSave()}
            onClick={() => {
              setPassword(md5(passwordField));
              setPasswordField('');
              setConfirmPassword('');
              dispatch(setOpenPasswordDialog(false));
            }}
            autoFocus
          >
            Aggiorna
          </Button>
        </>
      }
      zIndex={0}
    />
  );
};

export default PasswordDialog;
