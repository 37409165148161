/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface EnableAllInterface {
  serviceForm: boolean;
}

export interface StudiFormSlice {
  serviceForm: string[];
  enableAll: EnableAllInterface;
  studioFormLoader: boolean;
}

const initialState: StudiFormSlice = {
  serviceForm: [],
  enableAll: {
    serviceForm: false,
  },
  studioFormLoader: false,
};

const StudioForm = createSlice({
  name: 'BluBookingStudioForm',
  initialState,
  reducers: {
    resetFormValues() {
      return {
        serviceForm: [],
        enableAll: {
          serviceForm: false,
        },
        studioFormLoader: false,
      };
    },
    setService(state: StudiFormSlice, action: PayloadAction<string[]>) {
      state.serviceForm = action.payload;
    },

    setMultipleServiceValue(
      state: StudiFormSlice,
      action: PayloadAction<string[]>
    ) {
      state.serviceForm = action.payload;
    },
    setEnableAll(
      state: StudiFormSlice,
      action: PayloadAction<keyof EnableAllInterface>
    ) {
      state.enableAll[action.payload] = !state.enableAll[action.payload];
      const temp = state[action.payload];
      Object.keys(temp).forEach(
        (key: any) =>
          ((temp[key] as any).enable = state.enableAll[action.payload])
      );
      state = {
        ...state,
        [action.payload]: temp,
      };
    },
    setStudioFormLoader(state: StudiFormSlice, action: PayloadAction<boolean>) {
      state.studioFormLoader = action.payload;
    },
  },
});

export const {
  resetFormValues,
  setService,
  setMultipleServiceValue,
  setEnableAll,
  setStudioFormLoader,
} = StudioForm.actions;

export default StudioForm.reducer;
