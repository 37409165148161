import { Button, Stack } from '@mui/material';
import { memo, useCallback, useEffect } from 'react';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';
import { If, Then, Else } from 'react-if';
import { useDispatch, useSelector } from 'react-redux';

import { BluAsyncDialog } from '@bludata/components';
import dayjs from 'dayjs';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import { AppointmentFormData } from '../../../interfaces/AppointmentFormData';
import {
  AppDispatch,
  RootState,
  useAppSelector,
} from 'packages/blu-booking/src/store';
import { bluBookingLoginData } from 'packages/blu-booking/src/store/root';
import {
  setEmailNotSetForm,
  setEmailPopupForm,
  setFormLoader,
  setOpenForm,
} from '../../../store/AppointmentForm';
import { getConfig, setConfig } from '../../../utility_objects/WhiteCache';
import { setRefetch } from '../../../store/Appointment';
import { appointmentSlotIsOk } from 'packages/blu-booking/src/data_fetching/AppointmentSlot/AppointmentSlotIsOk';

interface ButtonsProps {
  isModify: boolean;
  initialValues?: AppointmentFormData;
}

export const Buttons = memo(({ isModify, initialValues }: ButtonsProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { isValid, errors } = useFormState();
  const { reset, watch, setValue } = useFormContext();
  const [
    Id,
    serviceId,
    pointOfSale,
    userId,
    studioId,
    start,
    duration,
    cercaDisponibilitaUsed,
  ] = useWatch({
    name: [
      'Id',
      'serviceId',
      'pointOfSaleId',
      'userId',
      'studioId',
      'start',
      'duration',
      'cercaDisponibilitaUsed',
    ],
  });

  const { pointOfSales } = useSelector(
    (state: RootState) => state.bluBookingCalendarFilters
  );
  const { isManualAppointmentBlock } = useAppSelector(bluBookingLoginData);
  const { sessionProfilo } = getConfig();

  const lastSaveCheck = useCallback(
    (type: '' | 'delete' | 'create' | 'modify' | undefined = 'delete') => {
      let hasLayout = false;
      if (pointOfSale in pointOfSales) {
        hasLayout = pointOfSales[pointOfSale]?.shopSignId !== null;
      }
      if (!hasLayout) {
        dispatch(setEmailPopupForm(type));
        return;
      }
      const data = watch();

      if (
        (data.email && data.email !== '') ||
        (data.telephone && data.telephone !== '') ||
        data.isIMO
      )
        dispatch(setEmailPopupForm(type));
      else dispatch(setEmailNotSetForm(type));
    },
    [dispatch, pointOfSale, pointOfSales, watch]
  );

  const saveData = useCallback(
    async (
      type: '' | 'delete' | 'create' | 'modify' | undefined = 'delete'
    ) => {
      if (!isManualAppointmentBlock) {
        const s = new Date(start);
        dispatch(setFormLoader(true));

        const isOk = await appointmentSlotIsOk({
          appointmentId: Id,
          serviceId,
          pointOfSaleId: pointOfSale,
          userId,
          studioId,
          startTime: dayjs(start).format('YYYY-MM-DD HH:mm'),
          endTime: dayjs(
            new Date(s.getTime() + parseInt(duration) * 60000)
          ).format('YYYY-MM-DD HH:mm'),
        });
        dispatch(setFormLoader(false));

        if (isOk.isOk === 1) {
          let text = 'Lo slot scelto non è disponibile! ';
          // if (isOk?.isStudioOk && isOk?.isUserOk) text += '';
          if (isOk?.isStudioOk === 1 && isOk?.isUserOk > 0) {
            if (isOk?.isUserOk === 1)
              text += `Lo studio è occupato in quell’orario e l'utente ha già un appuntamento programmato.`;
            else
              text += `Lo studio è occupato e l'utente non lavora in quell’orario.`;
          } else if (isOk?.isStudioOk === 1) {
            text += 'Lo studio è occupato in quell’orario.';
          } else if (isOk?.isUserOk === 1) {
            text +=
              'L’utente ha già un appuntamento programmato in quell’orario.';
          } else if (isOk?.isUserOk === 2) {
            text += 'L’utente non lavora in quell’orario.';
          }
          text +=
            '\nPuoi comunque confermare questo nuovo appuntamento cliccando su "Conferma", oppure scegliere un altro orario che preferisci.';
          BluAsyncDialog({
            title: 'Attenzione',
            type: 'warning',
            message: `<pre style='font-family: "Roboto"; white-space: pre-wrap; width: inherit;'>${text}</pre>`,
            confimButton: 'Conferma',
            declineButton: 'Cambia orario',
            onConfirmClick: () => {
              setValue('slotOkStatus', 2);
              lastSaveCheck(type);
            },
            // sx: { '& .MuiDialog-paper': { maxWidth: '500px' } },
          });
          return;
        }
      }
      setValue('slotOkStatus', 0);
      lastSaveCheck(type);
      // const type = !isModify ? "modify" : "create";
    },
    [
      Id,
      dispatch,
      duration,
      isManualAppointmentBlock,
      lastSaveCheck,
      pointOfSale,
      serviceId,
      setValue,
      start,
      studioId,
      userId,
    ]
  );

  const handleSaveCLicked = useCallback(() => {
    if (!isValid) {
      const temp = [
        ...new Set(
          Object.values(errors).map(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ({ message }: any) => `<li>${message}</li>`
          )
        ),
      ];

      // dispatch(setErrorPopup(true));
      BluAsyncDialog({
        title: 'Attenzione!',
        message: temp.join(''),
        confimButton: 'Chiudi',
        hideDeclineButton: true,
        type: 'warning',
        sx: { '& .MuiDialog-paper': { maxWidth: '370px' } },
      });

      return;
    }

    const data = watch();
    if (data.duration >= 240) {
      BluAsyncDialog({
        title: 'Attenzione!',
        message: `L'appuntamento che stai programmando ha una durata prevista superiore a 4 ore.\n Se state creando delle assenze straordinarie vi consigliamo di modificare i turni del personale.`,
        confimButton: 'Conferma',
        declineButton: 'Chiudi',
        onConfirmClick: () => saveData(!isModify ? 'modify' : 'create'),
        // hideDeclineButton: true,
        type: 'warning',
        sx: { '& .MuiDialog-paper': { maxWidth: '370px' } },
      });
      return;
    }
    saveData(!isModify ? 'modify' : 'create');
  }, [errors, isModify, isValid, saveData, watch]);

  const handleDeleteCLicked = useCallback(() => {
    saveData('delete');
  }, [saveData]);

  return (
    <Stack flex="1" flexDirection="row" gap={1}>
      <Stack flex="1" direction="row" gap={1}>
        <If condition={!isModify}>
          <Then>
            {sessionProfilo.agendaCancellazione && (
              <Button
                variant="contained"
                color="error"
                onClick={handleDeleteCLicked}
                startIcon={<DeleteIcon />}
              >
                Elimina
              </Button>
            )}
          </Then>
          <Else>
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                reset(initialValues);
              }}
            >
              Azzera
            </Button>
          </Else>
        </If>
      </Stack>

      <Button
        variant="outlined"
        onClick={() => {
          setConfig('underline', undefined);
          dispatch(setOpenForm(undefined));
          dispatch(setRefetch());
        }}
      >
        Annulla
      </Button>
      <Button
        variant="contained"
        disabled={!cercaDisponibilitaUsed && isManualAppointmentBlock}
        onClick={handleSaveCLicked}
        startIcon={<SaveIcon />}
      >
        Salva
      </Button>
    </Stack>
  );
});
