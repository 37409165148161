import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
} from '@mui/material';
import { memo } from 'react';
import WarningIcon from '@mui/icons-material/ErrorTwoTone';

interface EmailNotSetPopupProps {
  open: boolean;
  action: () => void;
  cancelAction: () => void;
}

export const EmailNotSetPopup = memo(
  ({ open, action, cancelAction }: EmailNotSetPopupProps) => {
    return (
      <Dialog open={open} maxWidth="xs">
        <DialogContent>
          <div style={{ textAlign: 'center' }}>
            <WarningIcon
              style={{
                fontSize: '6.0rem',
                color: 'var(--base-light-color)',
              }}
            />
            <Typography variant="h5" fontWeight="bold">
              Attenzione!
            </Typography>
            <Typography variant="subtitle1" fontWeight="bold">
              Mancano dei dati per poter notificare il cliente.
            </Typography>
            <Typography variant="body1">
              Il cliente non riceverà la conferma dell'appuntamento.
            </Typography>
          </div>
        </DialogContent>
        <DialogActions>
          <Stack flexDirection="row" gap="10px" sx={{ width: '100%' }}>
            <div style={{ flex: 1 }}>
              <Button
                variant="outlined"
                sx={{ textAlign: 'center' }}
                onClick={cancelAction}
              >
                <div style={{ width: '100%', textAlign: 'center' }}>
                  Annulla
                </div>
              </Button>
            </div>

            <Button
              variant="contained"
              color="primary"
              sx={{ textAlign: 'center' }}
              onClick={action}
            >
              <div style={{ width: '100%', textAlign: 'center' }}>
                Sì, conferma appuntamento
              </div>
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    );
  }
);
