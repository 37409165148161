import { BluTextField } from '@bludata/components';
import { Stack, Typography } from '@mui/material';
import { RootState } from 'packages/blu-booking/src/store';
import React, { useCallback } from 'react';
import { memo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';

export const Note = memo(() => {
  const note = useWatch({ name: 'description' });
  const { pointOfSales } = useSelector(
    (state: RootState) => state.bluBookingCalendarFilters
  );
  const pointOfSale = useWatch({ name: 'pointOfSaleId' });
  const { setValue, trigger } = useFormContext();

  const getTitle = useCallback(() => {
    let hasLayout = false;

    if (pointOfSale in pointOfSales) {
      hasLayout = pointOfSales[pointOfSale]?.shopSignId !== null;
    }
    if (hasLayout) return 'Note visualizzate dal cliente';
    return 'Note';
  }, [pointOfSale, pointOfSales]);

  return (
    <Stack flex="1">
      <Typography variant="subtitle2" sx={{ pt: '3px' }}>
        {getTitle()}
      </Typography>
      <BluTextField
        value={note}
        name="Note"
        autoComplete="off" // Disabilita il completamento automatico
        multiline
        rows={4}
        onChange={(
          event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
        ) => {
          setValue('description', event.target.value);
          trigger();
        }}
      />
    </Stack>
  );
});
