/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Dialog,
  DialogContent,
  Typography,
  DialogActions,
  Stack,
  Button,
} from '@mui/material';
import React, { useCallback } from 'react';
import { memo } from 'react';
import { useFormState } from 'react-hook-form';
import WarningIcon from '@mui/icons-material/ErrorTwoTone';
import { useDispatch, useSelector } from 'react-redux';
import { setErrorPopup } from '../../../store/AppointmentForm';
import { AppDispatch, RootState } from 'packages/blu-booking/src/store';

export const ErrorPopup = memo(() => {
  const { errors } = useFormState();
  const { errorPopup } = useSelector(
    (state: RootState) => state.bluBookingAppointmentForm
  );
  const dispatch = useDispatch<AppDispatch>();
  const handleCancel = useCallback(
    () => dispatch(setErrorPopup(false)),
    [dispatch]
  );

  return (
    <Dialog open={errorPopup} maxWidth="md">
      <DialogContent>
        <div style={{ textAlign: 'center' }}>
          <WarningIcon
            style={{
              fontSize: '6.0rem',
              color: 'var(--base-light-color)',
            }}
          />
          <Typography variant="h5" fontWeight="bold" sx={{ pb: '10px' }}>
            Attenzione!
          </Typography>
          <div style={{ textAlign: 'left' }}>
            {[
              ...new Set(
                Object.values(errors)
                  .map(({ message }: any) => message)
                  .flat()
              ),
            ].map((message: any) => (
              <Typography variant="body1">{message}</Typography>
            ))}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Stack
          flexDirection="row"
          gap="10px"
          sx={{ width: '100%', justifyContent: 'center' }}
        >
          <Button
            variant="outlined"
            color="primary"
            sx={{ textAlign: 'center' }}
            onClick={handleCancel}
          >
            <div style={{ width: '100%', textAlign: 'center' }}>Chiudi</div>
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
});
