import axios from 'axios';

export const historyMessageResend = async (id: string): Promise<boolean> => {
  try {
    await axios.post('api/HistoryMessage/SendMessage', {
      id,
    });

    return true;
  } catch (error) {
    return false;
  }
};
