import {
  AppBar,
  Avatar,
  Button,
  IconButton,
  Stack,
  SvgIcon,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useDispatch, useSelector } from 'react-redux';
import { toggleDarkMode, toggleMenuOpened } from '../../store/root';
import LogoutIcon from '@mui/icons-material/Logout';
import { RootState } from '../../store';
import DarkModeTwoToneIcon from '@mui/icons-material/DarkModeTwoTone';
import LightModeTwoToneIcon from '@mui/icons-material/LightModeTwoTone';
import { BluTitle } from '@bludata/components';
import ImageDefault from '../../assets/utentebase.png';
// import BluBookingIcon from '../../assets/BluBooking120.png';
import BluBookingWhite from '../../assets/BluBookingWhite.png';
import BluBookingDark from '../../assets/BluBookingDark.png';
import { useNotifiche } from '../../api/notify';
import { useCallback, useDeferredValue, useEffect, useState } from 'react';
import { NotificationDrawer } from './drawer';
import { BlinkBadge } from './badge';
import { eraseCookie } from '../../lib/cookie';
import UserDialog from './userDialog/UserDialog';
import isImageValid from './utils/isImageValid';
import { useAppointmentsNotification } from '../../api/AppointmentNotification';
import { isTablet } from 'react-device-detect';

const AppHeader = () => {
  // const { data } = useNotifiche();
  const { data } = useAppointmentsNotification();
  const notify = useDeferredValue(data);

  const dispatch = useDispatch();
  const { darkMode, photo, username } = useSelector(
    (state: RootState) => state.root
  );

  const [drawer, setDrawer] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [imageSrc, setImageSrc] = useState(ImageDefault);

  const toggleDrawer = useCallback(() => {
    setDrawer(!drawer);
  }, [drawer]);

  const isDarkModeOn = darkMode === 'dark';

  const onToggleDrawerTap = () => {
    dispatch(toggleMenuOpened());
  };

  const toggleDarkmodeTap = () => {
    dispatch(toggleDarkMode());
  };

  const handleClick = () => {
    /*

      identificationCode = 1595A-18B4LI
      Username = Pierantonio
      Pass = 12345678

    */
    // sessionStorage.removeItem("BluBookingToken");
    //sessionStorage.clear();
    eraseCookie('BluBookingUserPhoto');
    eraseCookie('BluBookingUserAdmin');
    eraseCookie('BluBookingpointOfSaleId');
    eraseCookie('BluBookingUserPhoto');
    eraseCookie('BluBookingToken');
    eraseCookie('BluBookingRefreshToken');

    window.location.reload();
  };

  useEffect(() => {
    if (photo) {
      isImageValid(photo, (isValid: any) => {
        if (isValid) {
          setImageSrc(photo);
        } else {
          setImageSrc(ImageDefault);
        }
      });
    }
  }, [photo]);

  return (
    <>
      <AppBar
        position="static"
        color="default"
        sx={{
          backgroundColor: 'var(--base-foreground-color)',
          backgroundImage: 'none',
          zIndex: 1,
          boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
        }}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="primary"
            aria-label="menu"
            sx={{ mr: 1 }}
            onClick={onToggleDrawerTap}
          >
            <MenuIcon />
          </IconButton>
          {/* <img
            src={BluBookingIcon}
            alt="Icona Blu Booking"
            style={{ backgroundColor: 'white' }}
            width="35px"
          /> */}
          {/* <SvgIcon
            component={BluBooking}
            style={{
              height: '30px',
              width: 'fit-content',
            }}
            viewBox="0 0 220.79 21.82"
          /> */}
          <img
            style={{
              height: '30px',
              width: isTablet ? '300px' : 'fit-content',
            }}
            alt="Blu BookingConsole"
            src={darkMode === 'dark' ? BluBookingDark : BluBookingWhite}
          />

          <Typography
            color="primary"
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, pl: 2 }}
          >
            {/* Console */}
          </Typography>
          <UserDialog
            show={show}
            onClose={() => {
              setShow((x) => !x);
            }}
          />
          <Stack
            direction="row"
            alignItems={'center'}
            onClick={() => {
              setShow((x) => !x);
            }}
            sx={{
              mr: '35px',
              p: 1,
              m: 1,
              cursor: 'pointer',
              borderRadius: '5px',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
              },
            }}
          >
            <Avatar
              sx={{
                border: '2px solid var(--base-color)',
                height: '50px',
                width: '50px',
              }}
              alt="user avatar"
              // "https://avatars.dicebear.com/api/pixel-art/random-avatar.svg"
              src={imageSrc}
            />
            <Stack flexDirection="column" sx={{ ml: '10px' }}>
              <BluTitle
                sx={{
                  color: 'var(--color) !important',
                }}
                text="Benvenuto/a,"
              />
              <BluTitle text={username ?? ''} />
            </Stack>
          </Stack>

          <Tooltip title="Abilita / Disabilita modalità notturna">
            <Button
              onClick={toggleDarkmodeTap}
              sx={{
                // mr: "10px",
                width: '32px',
                height: '32px',
                pl: '0px',
                pr: '0px',
                minWidth: '32px',
                marginRight: '5px',
              }}
              color="primary"
              variant="outlined"
            >
              {isDarkModeOn ? (
                <LightModeTwoToneIcon sx={{ paddingLeft: '4px' }} />
              ) : (
                <DarkModeTwoToneIcon sx={{ paddingLeft: '4px' }} />
              )}
            </Button>
          </Tooltip>
          <Tooltip title="Notifiche">
            <Button
              // startIcon={<LogoutIcon />}
              color="primary"
              onClick={toggleDrawer}
              variant="outlined"
              sx={{
                // mr: "10px",
                width: '32px',
                height: '32px',
                pl: '0px',
                pr: '0px',
                minWidth: '32px',
                marginRight: '5px',
              }}
            >
              <BlinkBadge
                value={
                  (notify?.errorCodeCount ?? 0) +
                  (notify?.notConfirmedCount ?? 0) +
                  (notify?.notOkSlotCount ?? 0)
                }
              />
              {/* <Badge badgeContent={(data ?? []).length} color="error">
                <NotificationsIcon sx={{ paddingLeft: "4px" }} />
              </Badge> */}
            </Button>
          </Tooltip>
          <Tooltip title="Esci">
            <Button
              // startIcon={<LogoutIcon />}
              color="primary"
              onClick={handleClick}
              variant="outlined"
              sx={{
                // mr: "10px",
                width: '32px',
                height: '32px',
                pl: '0px',
                pr: '0px',
                minWidth: '32px',
              }}
            >
              <LogoutIcon sx={{ paddingLeft: '4px' }} />
            </Button>
          </Tooltip>
        </Toolbar>
      </AppBar>

      <NotificationDrawer
        open={drawer}
        setOpen={setDrawer}
        notifications={notify}
      />
    </>
  );
};

export default AppHeader;
