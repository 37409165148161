import {
  LinearProgress,
  Paper,
  Stack,
  ThemeProvider,
  createTheme,
} from '@mui/material';
import { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import ImageDefault from '../../../../assets/Bludata-WhiteMode.svg';
import { useWatch } from 'react-hook-form';
import { RootState } from '../../../../store';

export const TestPalette = memo(() => {
  const palette = useSelector((state: RootState) => state.layoutPalette);
  const { logo } = useSelector((state: RootState) => state.layoutCustomTheme);
  const logoUrl = useWatch({ name: 'logo' });

  const getTheme = useCallback(() => {
    return createTheme({ palette });
  }, [palette]);

  const getHeight = useCallback(() => {
    const result = (logo?.height === undefined ? 70 : logo.height) / 2;
    return result;
  }, [logo.height]);

  const getWidth = useCallback(() => {
    const result = logo?.width === undefined ? undefined : logo.width / 2;
    return result;
  }, [logo.width]);

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        minHeight: '280px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <ThemeProvider theme={getTheme()}>
        <div style={{ backgroundColor: palette.background.default }}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <img
              src={logoUrl ?? ImageDefault}
              alt="logo"
              style={{
                margin: '0 auto',
                height: getHeight(),
                width: getWidth(),
                padding: '10px 0',
                objectFit: 'contain',
              }}
            />
          </div>

          <Stack flexDirection="row"></Stack>
          <LinearProgress
            variant="determinate"
            value={10}
            sx={{
              height: 5,
              backgroundColor: 'rgba(187, 187, 187, 1)',
            }}
          />
        </div>

        <Paper
          sx={{
            display: 'flex',
            flex: 1,
            borderRadius: 0,
            p: '10px 5px',
            justifyItems: 'center',
            flexDirection: 'column',
            rowGap: '10px',
          }}
          elevation={0}
        ></Paper>
        <div
          style={{
            height: '50px',
            backgroundColor: palette.footer.main,
          }}
        ></div>
      </ThemeProvider>
    </div>
  );
});
