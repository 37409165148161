import { RootState } from 'packages/blu-booking/src/store';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { Scheduler } from './UI/Scheduler';
import { CalendarLoader } from '../../UI/CalendarLoader/CalendarLoader';
import { usePointOfSaleHoursCalendar } from 'packages/blu-booking/src/data_fetching/PointOfSaleHours/PointOfSaleHoursCalendar';
import { usePointOfSaleHoursShift } from 'packages/blu-booking/src/data_fetching/PointOfSaleHours/PointOfSaleHoursShift';

export const CalendarDefaultInner = memo(
  function CalendarDefaultInner(): JSX.Element {
    const { loadingAppointments } = useSelector(
      (state: RootState) => state.bluBookingaAppointments
    );

    const { filtersLoad } = useSelector(
      (state: RootState) => state.bluBookingCalendarFilters
    );

    const { selectedDate, view, selectedPointOfSale } = useSelector(
      (state: RootState) => state.bluBookingDefaultCalendar
    );

    const {
      data: calendarPointOfSale,
      isLoading: isLoadingCalendarPointOfSale,
    } = usePointOfSaleHoursCalendar({
      selectedDate,
      view,
      pointOfSaleId: selectedPointOfSale?.id,
      options: {
        enabled: filtersLoad ?? false,
        cacheTime: 300000,
        staleTime: 300000,
        refetchInterval: 300000,
      },
    });

    const { data: shiftPointOfSale, isLoading: isLoadingShiftPointOfSale } =
      usePointOfSaleHoursShift({
        selectedDate,
        view,
        pointOfSaleId: selectedPointOfSale?.id,
        options: {
          enabled: filtersLoad ?? false,
          cacheTime: 300000,
          staleTime: 300000,
          refetchInterval: 300000,
        },
      });

    return (
      <div key="Default" style={{ height: '100%' }}>
        <Scheduler
          calendarPointOfSale={calendarPointOfSale}
          shiftPointOfSale={shiftPointOfSale}
        />

        <CalendarLoader
          open={
            loadingAppointments ||
            isLoadingCalendarPointOfSale ||
            isLoadingShiftPointOfSale
          }
        />
      </div>
    );
  }
);
