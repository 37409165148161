import {
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import React, { useCallback } from 'react';
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setMessageTypeFilter, setPage } from '../store/Notifications';
import { RootState } from 'packages/blu-booking/src/store';
import { MessageTypeEnum } from 'packages/blu-booking/src/interfaces/Notifications';

export const MessageTypeFilter = memo(() => {
  const { messageTypeFilter } = useSelector(
    (state: RootState) => state.bluBookingNotifications
  );
  const dispatch = useDispatch();

  const handleMessageTypeFilterChange = useCallback(
    (event: SelectChangeEvent) => {
      dispatch(setPage(1));

      if (event.target.value === '4') {
        dispatch(setMessageTypeFilter(undefined));
        return;
      }

      dispatch(
        setMessageTypeFilter(parseInt(event.target.value) as MessageTypeEnum)
      );
    },
    [dispatch]
  );

  const getValue = useCallback(() => {
    if (messageTypeFilter !== undefined) {
      return messageTypeFilter.toString();
    }
    return '4';
  }, [messageTypeFilter]);

  return (
    <Stack>
      <Typography>Tipo di messaggio</Typography>
      <Select
        value={getValue()}
        onChange={handleMessageTypeFilterChange}
        sx={{ width: 150 }}
      >
        <MenuItem value={MessageTypeEnum['email']}>Email</MenuItem>
        <MenuItem value={MessageTypeEnum['SMS']}>SMS</MenuItem>
        <MenuItem value={MessageTypeEnum['IMO']}>App</MenuItem>
        <MenuItem value={'4'}>Tutti</MenuItem>
      </Select>
    </Stack>
  );
});
