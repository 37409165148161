import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import ReactDOM from 'react-dom/client';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import BluThemeProvider from '../../layout/theme';
import BluCloudThemeProvider from '../../layout/themeCloud';

const iconDimensions = { fontSize: '80px', mb: '10px' };

interface AsyncConfirmDialogInterface {
  title: string;
  message: string;
  confimButton?: string;
  declineButton?: string;
  hideDeclineButton?: boolean;
  type?: 'warning' | 'error' | 'info';
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  onConfirmClick?: () => void;
  onDeclineClick?: () => void;
  [x: string]: any;
}

export default function BluAsyncDialog({
  title,
  message,
  confimButton,
  declineButton,
  hideDeclineButton,
  type,
  maxWidth = 'sm',
  onConfirmClick = () => {},
  onDeclineClick = () => {},
  ...rest
}: AsyncConfirmDialogInterface) {
  const appRoot = document.getElementById('root');

  if (document.activeElement instanceof HTMLElement) {
    document.activeElement.blur();
  }

  return new Promise<boolean>((resolve, _) => {
    const newRootDiv = document.createElement('div');
    newRootDiv.id = 'temp-react-async-confirm-container';
    document.body.appendChild(newRootDiv);
    const newRoot = ReactDOM.createRoot(newRootDiv);
    const themeMode =
      window.document.querySelector('html')?.getAttribute('dark-mode') ===
      'true'
        ? 'dark'
        : 'light';

    const destroyTempRoot = () => {
      document.body.removeChild(newRootDiv);
      newRoot.unmount();
      appRoot.style.pointerEvents = 'auto';
    };

    const onConfirm = () => {
      resolve(true);
      onConfirmClick();
      destroyTempRoot();
    };
    const onCancel = () => {
      resolve(false);
      onDeclineClick();
      destroyTempRoot();
    };

    const dialogConfirm = (
      <Dialog
        open={true}
        fullWidth
        maxWidth={maxWidth}
        className="bludata-blu-alert"
        fullScreen={false}
        disableAutoFocus={false}
        disableEnforceFocus={false}
        disableRestoreFocus={false}
        {...rest}
      >
        <DialogTitle
          style={{
            backgroundColor: 'var(--background-color) !important',
            color: 'var(--color) !important',
          }}
        >
          {!!type ? (
            <Stack flexDirection="column" alignItems="center">
              {type === 'warning' && (
                <WarningAmberIcon color="warning" sx={iconDimensions} />
              )}
              {type === 'info' && (
                <InfoOutlinedIcon color="primary" sx={iconDimensions} />
              )}
              {type === 'error' && (
                <ErrorOutlineOutlinedIcon color="error" sx={iconDimensions} />
              )}
              {title ? title : 'Attenzione'}
            </Stack>
          ) : (
            <div>{title ? title : 'Attenzione'}</div>
          )}
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2" component="div">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </Typography>
        </DialogContent>
        <DialogActions>
          {!hideDeclineButton && (
            <Button
              variant="outlined"
              sx={{ justifyContent: 'center' }}
              onClick={onCancel}
            >
              {declineButton ? declineButton : 'No'}
            </Button>
          )}
          <Button
            variant="outlined"
            sx={{ justifyContent: 'center' }}
            onClick={onConfirm}
          >
            {confimButton ? confimButton : 'Si'}
          </Button>
        </DialogActions>
      </Dialog>
    );

    if (
      import.meta &&
      (import.meta as any)?.env &&
      (import.meta as any).env.VITE_NX_PUBLIC_YOUR_CLIENT_ID
    ) {
      newRoot.render(
        <BluCloudThemeProvider themeMode={themeMode}>
          {dialogConfirm}
        </BluCloudThemeProvider>
      );
    } else {
      newRoot.render(
        <BluThemeProvider themeMode={themeMode}>
          {dialogConfirm}
        </BluThemeProvider>
      );
    }

    /*setTimeout(() => {
      const dialogButton = document.body.querySelector(
        '.bludata-blu-alert button'
      ) as HTMLButtonElement;
      if (dialogButton) {
        dialogButton.focus();
      }
    }, 200);*/

    appRoot.style.pointerEvents = 'none';
  });
}
