import { memo } from 'react';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SettingsIcon from '@mui/icons-material/Settings';
import { TreeListItemProps } from './interface';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { Box, Drawer, List } from '@mui/material';
import { ParentMenu } from './parent';
// import ExpandLess from "@mui/icons-material/ExpandLess";
// import ExpandMore from "@mui/icons-material/ExpandMore";

const treeItemList: TreeListItemProps[] = [
  {
    name: 'calendar',
    title: 'Agenda',
    icon: <CalendarMonthIcon color="primary" style={{ fontSize: '25px' }} />,
    protect: false,
    children: [
      {
        id: 'calendar',
        name: 'Calendario appuntamenti',
        isCalendar: true,
      },
      {
        id: 'personalCalendar',
        name: 'Calendario personale',
        isCalendar: true,
      },
      {
        id: 'userCalendar',
        name: 'Giornaliero utente',
        isCalendar: true,
      },
      {
        id: 'studioCalendar',
        name: 'Giornaliero studio',
        isCalendar: true,
      },
      {
        id: 'messageHistory',
        name: 'Storico notifiche',
      },
      {
        id: 'turni-degli-appuntamenti',
        name: 'Turni e orari',
      },
      {
        id: 'analisi-degli-appuntamenti',
        name: 'Analisi',
      },
    ],
  },
  {
    name: 'config',
    title: 'Configurazione',
    icon: <SettingsIcon color="primary" style={{ fontSize: '25px' }} />,
    // protect: !(import.meta.env.VITE_NODE_ENV === "development"),
    protect: true,
    children: [
      {
        id: 'generali',
        name: 'Generali',
      },
      {
        id: 'services',
        name: 'Servizi',
      },
      {
        id: 'punti-vendita',
        name: 'Punti vendita',
      },
      {
        id: 'studi',
        name: 'Studi',
      },
      {
        id: 'users',
        name: 'Utenti',
      },
      {
        id: 'shop-signs',
        name: 'Layout',
      },
    ],
  },
];

export const AppDrawer = memo(() => {
  const { menuOpened } = useSelector((state: RootState) => state.root);
  return (
    <Drawer
      open={menuOpened}
      // onClose={onMenuClose}
      variant="permanent"
      sx={{
        backgroundColor: 'black',
        position: 'static',
        width: menuOpened ? '300px' : 'fit-content',
        height: '100%',
        '& .MuiPaper-root': {
          position: 'static',
          backgroundColor: 'var(--base-foreground-color)',
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Box sx={{ height: '100%' }}>
        <List
          sx={{
            flex: 1,
            overflowY: 'auto',
            pt: 0,
            '& .MuiListItemButton-root': {
              height: '46px',
              paddingLeft: '6px !important',
            },
            '& .MuiListItemButton-root:hover': {
              backgroundColor: 'var(--hovered-background-color) !important',
              borderLeft: '6px solid var(--base-color)',
              paddingLeft: '0px !important',
            },
            '& .MuiListItemButton-root.Mui-selected': {
              backgroundColor: 'var(--hovered-background-color) !important',
              borderLeft: '6px solid var(--base-color)',
              paddingLeft: '0px !important',
            },
            '& .MuiListItem-root.Mui-selected': {
              backgroundColor: 'var(--base-color) !important',
            },
            '& .MuiListItem-root.Mui-selected svg': {
              color: 'var(--reverse-color) !important',
            },
            '& .MuiListItem-root.Mui-selected .MuiListItemButton-root:hover': {
              backgroundColor: 'var(--base-color) !important',
            },
          }}
        >
          {treeItemList.map((treeItem: any, index: number) => (
            <ParentMenu key={`menuRamo${index}`} {...treeItem} />
          ))}
        </List>
      </Box>
    </Drawer>
  );
});
