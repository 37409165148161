import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HolidayInterface } from 'packages/blu-booking/src/interfaces/Holiday';

export interface HolidaySlice {
  holidays: HolidayInterface[];
  DeleteHolidays: HolidayInterface[];
  ModifyHolidays: HolidayInterface[];
}

const initialState: HolidaySlice = {
  holidays: [],
  DeleteHolidays: [],
  ModifyHolidays: [],
};

const HolidaySlice = createSlice({
  name: 'BluBookingHoliday',
  initialState,
  reducers: {
    changeHolidays(
      state: HolidaySlice,
      action: PayloadAction<HolidayInterface[]>
    ) {
      state.holidays = action.payload;
    },
    setDeleteHolidays(
      state: HolidaySlice,
      action: PayloadAction<HolidayInterface[]>
    ) {
      state.DeleteHolidays = action.payload;
    },
    setModifyHolidays(
      state: HolidaySlice,
      action: PayloadAction<HolidayInterface[]>
    ) {
      state.ModifyHolidays = action.payload;
    },
    resetAndChangeHolidays(
      _: HolidaySlice,
      action: PayloadAction<HolidayInterface[]>
    ) {
      return {
        holidays: action.payload,
        CreateHolidays: [],
        DeleteHolidays: [],
        ModifyHolidays: [],
      };
    },
  },
});

export const {
  changeHolidays,
  setDeleteHolidays,
  setModifyHolidays,
  resetAndChangeHolidays,
} = HolidaySlice.actions;

export default HolidaySlice.reducer;
