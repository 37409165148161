/* eslint-disable @typescript-eslint/no-explicit-any */
import './UserCalendar.css';

import { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Stack, Typography } from '@mui/material';
import { BluContainerBlock } from '@bludata/components';
import {
  UserCalendarResetSelected,
  UserCalendarSelectPointOfSale,
  UserCalendarSetSelectedDate,
} from './store';
import dayjs from 'dayjs';
import { isSafari } from 'react-device-detect';
import { AppDispatch, RootState } from 'packages/blu-booking/src/store';
import { setConfig } from '../../utility_objects/WhiteCache';
import { setRefetch } from '../../store/Appointment';
import { fetchAppointments } from 'packages/blu-booking/src/data_fetching/Appointment/Appointments';
import { CalendarHeader } from '../../UI/CalendarHeader/CalendarHeader';
import { Header } from './UI/Header';
import { Filters } from './UI/FIlters';
import { UserCalendarInner } from './UserCalendarInner';
import { AppointmentSearch } from '../../UI/AppointmentSearch/AppointmentSearch';

export const UserCalendar = memo(function Calendar(): JSX.Element {
  const dispatch = useDispatch<AppDispatch>();
  const { filtersLoad, pointOfSales } = useSelector(
    (state: RootState) => state.bluBookingCalendarFilters
  );
  const { refetch } = useSelector(
    (state: RootState) => state.bluBookingaAppointments
  );

  const {
    selectedDate,
    selectedServices: checkedServices,
    selectedPointOfSale,
    client,
  } = useSelector((state: RootState) => state.bluBookingUserCalendar);

  const uderlineNotification = (event: any) => {
    if (event && event.detail) {
      setConfig('underline', event.detail?.id);

      const isSamePointOfSale =
        selectedPointOfSale?.id === event.detail?.pointOfSaleId;
      const date = dayjs(selectedDate).format('YYYY/MM/DD');
      const appointmentDate = dayjs(event.detail.startTime).format(
        'YYYY/MM/DD'
      );

      if (!isSamePointOfSale && event.detail?.pointOfSaleId in pointOfSales)
        dispatch(
          UserCalendarSelectPointOfSale(
            pointOfSales[event.detail?.pointOfSaleId]
          )
        );

      if (checkedServices.length > 0)
        dispatch(UserCalendarResetSelected('selectedServices'));

      if (date === appointmentDate) dispatch(setRefetch());
      dispatch(UserCalendarSetSelectedDate(event.detail.startTime));
    }
    return;
  };

  useEffect(() => {
    window.addEventListener('BluBookingNotification', uderlineNotification);

    return () => {
      window.removeEventListener(
        'BluBookingNotification',
        uderlineNotification
      );
    };
  });

  useEffect(() => {
    if (filtersLoad)
      dispatch(
        fetchAppointments({
          selectedDate,
          checkedServices,
          checkedUsers: [],
          checkedPointOfSales: selectedPointOfSale
            ? [selectedPointOfSale.id]
            : [],
          checkedStudi: [],
          view: 'Day',
          email: client?.email,
        })
      );
  }, [selectedDate, checkedServices, selectedPointOfSale, dispatch, refetch, client?.email, filtersLoad]);

  const handleSearch = useCallback(
    (appointment: any) => {
      const d = dayjs(selectedDate).format('DD/MM/YYYY');
      if (d !== appointment?.date)
        dispatch(
          UserCalendarSetSelectedDate(
            new Date(dayjs(appointment?.date, 'DD/MM/YYYY').format())
          )
        );
      if (
        appointment?.pointOfSaleId &&
        pointOfSales &&
        appointment?.pointOfSaleId !== selectedPointOfSale?.id &&
        appointment?.pointOfSaleId in pointOfSales
      )
        dispatch(
          UserCalendarSelectPointOfSale(
            pointOfSales[appointment?.pointOfSaleId]
          )
        );

      if (checkedServices.length > 0)
        dispatch(UserCalendarResetSelected('selectedServices'));

      if (d === appointment?.date) dispatch(setRefetch());
    },
    [
      checkedServices,
      dispatch,
      pointOfSales,
      selectedDate,
      selectedPointOfSale?.id,
    ]
  );

  return (
    <>
      <Stack
        flex={1}
        className={isSafari ? 'safari-scroll' : ''}
        flexDirection="column"
      >
        <AppointmentSearch action={handleSearch} />
        <CalendarHeader title="Agenda / Calendario utente" id="user" />
        <BluContainerBlock
          sx={{
            m: '4px',
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            overflow: 'hidden',
          }}
        >
          {!filtersLoad ||
          (filtersLoad &&
            pointOfSales &&
            Object.keys(pointOfSales).length > 0) ? (
            <>
              <Header />
              <Box
                sx={{
                  backgroundColor: 'var(--base-foreground-color)',
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                  overflow: 'hidden',
                }}
              >
                <UserCalendarInner />
              </Box>
            </>
          ) : (
            <Stack
              alignItems="center"
              justifyContent="center"
              direction="row"
              flex="1"
              sx={{ height: '100%', width: '100%' }}
            >
              <Typography fontWeight="bold">
                Nessun punto vendita visibile dall'utente, controllare
                configurazione.
              </Typography>
            </Stack>
          )}
        </BluContainerBlock>
      </Stack>
      <Filters />
    </>
  );
});
