import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  PointOfSaleInterface,
  StudioInterface,
  ServiceInterface,
  StudioDetailsInterface,
} from 'packages/blu-booking/src/interfaces/Interfaces';

export interface StudioSlice {
  pointOfSales: {
    [x: string]: PointOfSaleInterface;
  };
  studi: {
    [x: string]: StudioInterface;
  };
  services: {
    [x: string]: ServiceInterface;
  };
  studiLoad: boolean;
  openForm?: 'modify' | 'info' | 'new';
  currentStudio?: StudioDetailsInterface;
  deleteStudio?: string;
}

const initialState: StudioSlice = {
  pointOfSales: {},
  studi: {},
  services: {},
  studiLoad: true,
};

const StudioRootSlice = createSlice({
  name: 'BluBookingStudioRoot',
  initialState,
  reducers: {
    setOpenForm(
      state: StudioSlice,
      action: PayloadAction<'modify' | 'info' | 'new' | undefined>
    ) {
      state.openForm = action.payload;
    },
    setCurrentStudio(
      state: StudioSlice,
      action: PayloadAction<undefined | StudioDetailsInterface>
    ) {
      state.currentStudio = action.payload;
    },
    setStudiLoad(state: StudioSlice, action: PayloadAction<boolean>) {
      state.studiLoad = action.payload;
    },
    setDeleteStudio(
      state: StudioSlice,
      action: PayloadAction<string | undefined>
    ) {
      state.deleteStudio = action.payload;
    },
    setStudiData(
      state: StudioSlice,
      action: PayloadAction<{
        pointOfSales: {
          [x: string]: PointOfSaleInterface;
        };
        studi: {
          [x: string]: StudioInterface;
        };
        services: {
          [x: string]: ServiceInterface;
        };
        studiLoad: boolean;
      }>
    ) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const {
  setOpenForm,
  setStudiLoad,
  setCurrentStudio,
  setDeleteStudio,
  setStudiData,
} = StudioRootSlice.actions;

export default StudioRootSlice.reducer;
