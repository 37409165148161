/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import axios from 'axios';
import {
  PointOfSaleInterface,
  PointOfSalesObject,
} from '../../interfaces/PointOfSale';

const getPointOfSalesCalendar = async (): Promise<PointOfSaleInterface[]> => {
  try {
    const {
      data: { data },
    } = await axios.get('/api/PointOfSale/UICalendar');

    return data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const usePointOfSalesCalendar = ({
  options = {},
  key = '',
}: {
  options?: UseQueryOptions;
  key?: string;
}) => {
  return useQuery({
    queryFn: async () => await getPointOfSalesCalendar(),
    queryKey: ['point-of-sales', 'calendar', key],
    retry: 2,
    retryDelay: 180000,
    cacheTime: 300000,
    refetchInterval: 300000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    select: normalize,
    ...options,
  });
};

const normalize = (data: any): PointOfSalesObject => {
  const result: any = {};
  for (const pos of data) {
    result[pos.id] = {
      ...pos,
      pointOfSaleId: pos.id,
    };
  }
  return result;
};
