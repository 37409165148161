/* eslint-disable @typescript-eslint/no-var-requires */
import { loadCldr, L10n } from '@syncfusion/ej2-base';
import { it } from './it';
// import { en } from "./en";

import caGregorian from 'cldr-data/main/it/ca-gregorian.json';
import numbers from 'cldr-data/main/it/numbers.json';
import timeZoneNames from 'cldr-data/main/it/timeZoneNames.json';
import dateFields from 'cldr-data/main/it/dateFields.json';
import languages from 'cldr-data/main/it/languages.json';
import layout from 'cldr-data/main/it/layout.json';
import listPatterns from 'cldr-data/main/it/listPatterns.json';
import localeDisplayNames from 'cldr-data/main/it/localeDisplayNames.json';
import measurementSystemNames from 'cldr-data/main/it/measurementSystemNames.json';
import posix from 'cldr-data/main/it/posix.json';
import scripts from 'cldr-data/main/it/scripts.json';
import territories from 'cldr-data/main/it/territories.json';
import units from 'cldr-data/main/it/units.json';
import variants from 'cldr-data/main/it/variants.json';
import currencies from 'cldr-data/main/it/currencies.json';
import currencyData from 'cldr-data/supplemental/currencyData.json';
import numberingSystems from 'cldr-data/supplemental/numberingSystems.json';

export default function loadSyncfusionLanguage(): void {
  L10n.load(it);
  loadCldr(
    caGregorian,
    numbers,
    timeZoneNames,
    dateFields,
    languages,
    layout,
    listPatterns,
    localeDisplayNames,
    measurementSystemNames,
    numbers, // duplicate in original code; check if needed
    posix,
    scripts,
    territories,
    units,
    variants,
    currencies,
    currencyData,
    numberingSystems
  );
}
