import { Badge, Button, Stack } from '@mui/material';
import { FC, memo, useCallback, useMemo } from 'react';
import { AppointmentsSlotNotOkPopupInner } from './AppointmentsSlotNotOkPopupInner';
import { AppointmentCompleteInterface } from 'packages/blu-booking/src/interfaces/AppointmentComplete';
import { AppointmentNotificationInterface } from 'packages/blu-booking/src/interfaces/AppointmentNotification';
import { AppDispatch, RootState } from 'packages/blu-booking/src/store';
import { useDispatch, useSelector } from 'react-redux';
import {
  setAppointmentNotConfirmPopupOpen,
  setAppointmentsSlotNotOkPopupOpen,
} from '../../store/Calendar';

interface AppointmentsSlotNotOkPopupInterface {
  notifications: AppointmentNotificationInterface;
  pointOfSaleId: string;
  handlePosition: (appointment: AppointmentCompleteInterface) => void;
}

export const AppointmentsSlotNotOkPopup: FC<AppointmentsSlotNotOkPopupInterface> =
  memo(({ notifications, pointOfSaleId, handlePosition }) => {
    const { appointmentsSlotNotOkPopupOpen } = useSelector(
      (state: RootState) => state.bluBookingCalendars
    );
    const dispatch = useDispatch<AppDispatch>();

    const GetPopup = useMemo(() => {
      if (!appointmentsSlotNotOkPopupOpen) return null;
      return (
        <AppointmentsSlotNotOkPopupInner
          pointOfSaleId={pointOfSaleId}
          handlePosition={handlePosition}
        />
      );
    }, [appointmentsSlotNotOkPopupOpen, handlePosition, pointOfSaleId]);

    const getBadgeValue = useCallback(() => {
      return notifications ? notifications?.notOkSlotCount ?? 0 : 0;
    }, [notifications]);

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return (
      <>
        {GetPopup}

        <Button
          variant="outlined"
          onClick={() => dispatch(setAppointmentsSlotNotOkPopupOpen(true))}
          // disabled={isDisabled()}
        >
          <Stack
            direction="row"
            gap={1}
            flex="1"
            alignItems="center"
            sx={{ pr: '8px' }}
          >
            <Stack flex="1" sx={{ textAlign: 'left' }}>
              Non validi
            </Stack>
            <Badge
              badgeContent={getBadgeValue()}
              color={'primary'}
              sx={{
                '& .MuiBadge-badge': {
                  fontWeight: 'bold',
                  fontSize: '15px',
                  minWidth: '40px',
                  lineHeight: '14px',
                  boxShadow:
                    '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)',
                  backgroundColor: '#E77D22',
                  color: 'white',
                },
              }}
            />
          </Stack>
        </Button>
      </>
    );
  });
