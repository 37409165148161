/* eslint-disable @typescript-eslint/no-explicit-any */
import { useSelector } from 'react-redux';
import { Stack, Typography } from '@mui/material';
import { RootState } from 'packages/blu-booking/src/store';
import { Scheduler } from './UI/Scheduler';
import { CalendarLoader } from '../../UI/CalendarLoader/CalendarLoader';
import { usePointOfSaleHoursShift } from 'packages/blu-booking/src/data_fetching/PointOfSaleHours/PointOfSaleHoursShift';
import { usePointOfSaleHoursCalendar } from 'packages/blu-booking/src/data_fetching/PointOfSaleHours/PointOfSaleHoursCalendar';
import { memo, useCallback } from 'react';

export const StudioCalendarInner = memo(
  function StudioCalendarInner(): JSX.Element {
    const { loadingAppointments } = useSelector(
      (state: RootState) => state.bluBookingaAppointments
    );

    const { filtersLoad, studi } = useSelector(
      (state: RootState) => state.bluBookingCalendarFilters
    );

    const { selectedDate, selectedPointOfSale } = useSelector(
      (state: RootState) => state.bluBookingStudioCalendar
    );

    const getPointOfSaleStudi = useCallback(() => {
      if (studi && Object.keys(studi ?? {}).length > 0) {
        return Object.values(studi).filter(
          (studio: any) =>
            studio.pointOfSaleId === selectedPointOfSale?.id && studio.isActive
        );
      }
      return [];
    }, [selectedPointOfSale?.id, studi]);

    const {
      data: calendarPointOfSale,
      isLoading: isLoadingCalendarPointOfSale,
    } = usePointOfSaleHoursCalendar({
      selectedDate,
      view: 'Day',
      pointOfSaleId: selectedPointOfSale?.id,
      options: {
        enabled: filtersLoad ?? false,
        cacheTime: 300000,
        staleTime: 300000,
        refetchInterval: 300000,
      },
    });

    const { data: shiftPointOfSale, isLoading: isLoadingShiftPointOfSale } =
      usePointOfSaleHoursShift({
        selectedDate,
        view: 'Day',
        pointOfSaleId: selectedPointOfSale?.id,
        options: {
          enabled: filtersLoad ?? false,
          cacheTime: 300000,
          staleTime: 300000,
          refetchInterval: 300000,
        },
      });

    return (
      <div style={{ height: '100%' }}>
        {calendarPointOfSale !== undefined &&
        shiftPointOfSale !== undefined &&
        getPointOfSaleStudi().length > 0 ? (
          <Scheduler
            calendarPointOfSale={calendarPointOfSale}
            shiftPointOfSale={shiftPointOfSale}
            studi={getPointOfSaleStudi()}
          />
        ) : (
          <Stack
            alignItems="center"
            justifyContent="center"
            direction="row"
            flex="1"
            sx={{ height: '100%', width: '100%' }}
          >
            <Typography fontWeight="bold">
              Nessuno studio per il punto vendita selezionato
            </Typography>
          </Stack>
        )}
        <CalendarLoader
          open={
            loadingAppointments ||
            isLoadingCalendarPointOfSale ||
            isLoadingShiftPointOfSale
          }
        />
      </div>
    );
  }
);
