import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import 'dayjs/locale/it';
import { PersonalSelectedFiltersState } from './interface';
import {
  getCookie,
  setCookie,
} from 'packages/blu-booking/src/utility_objects/CookieManager';
import {
  removePointOfSale,
  removeService,
  removeStudio,
  selectPointOfSale,
  selectService,
  selectStudio,
  selectZoomValue,
  setSelectedDate,
  setClient,
} from '../../../utility_objects/StoreFunctions';
import { UserInterface } from 'packages/blu-booking/src/interfaces/User';

const getCurrentView = (view?: string) => {
  if (view) {
    if (view === '3Days') return 'Day';
    else if (view === 'WorkWeek') return 'Week';
    return view;
  }
  const storedView = getCookie('personalCalendarView') || 'Week';
  if (storedView === '3Days') return 'Day';
  else if (storedView === 'WorkWeek') return 'Week';
  return storedView;
};

const initialState: PersonalSelectedFiltersState = {
  view: getCookie('personalCalendarView') || 'Week',
  currentView: getCurrentView(),
  selectedDate: new Date(),
  selectedServices: [],
  selectedStudi: [],
  selectedUsers: [],
  zoom: {
    value: 150,
    interval: 60,
    slotCount: 4,
  },
  zoomValue: 150,
};

const PersonalCalendarSlice = createSlice({
  name: 'BluBookingPersonalCalendar',
  initialState,
  reducers: {
    PersonalCalendarSetView(
      state: PersonalSelectedFiltersState,
      action: PayloadAction<string>
    ) {
      state.currentView = getCurrentView(action.payload);
      state.view = action.payload;
      setCookie('personalCalendarView', action.payload);
    },
    PersonalCalendarSetSelectedDate: setSelectedDate,
    PersonalCalendarSelectZoomValue: selectZoomValue,
    // ? ADD
    PersonalCalendarSelectPointOfSale: selectPointOfSale,
    PersonalCalendarSetService: selectService,
    PersonalCalendarSelectStudio: selectStudio,
    PersonalCalendarSetUser: (
      state: PersonalSelectedFiltersState,
      action: PayloadAction<UserInterface[]>
    ) => {
      state.selectedUsers = action.payload;
      state.selectedServices = [];
    },
    PersonalCalendarSetClient: setClient,
    // ? REMOVE
    PersonalCalendarRemoveStudio: removeStudio,
    PersonalCalendarRemoveService: removeService,
    PersonalCalendarRemovePointOfSale: removePointOfSale,

    PersonalCalendarResetSelected: (
      state: PersonalSelectedFiltersState,
      action: PayloadAction<string>
    ) => {
      return { ...state, [action.payload]: [] };
    },
    PersonalCalendarReset: () => initialState,
  },
});

export const {
  PersonalCalendarSetView,
  PersonalCalendarSetSelectedDate,
  PersonalCalendarSelectZoomValue,
  PersonalCalendarSelectPointOfSale,
  PersonalCalendarSetService,
  PersonalCalendarSelectStudio,
  PersonalCalendarRemoveStudio,
  PersonalCalendarRemoveService,
  PersonalCalendarRemovePointOfSale,
  PersonalCalendarResetSelected,
  PersonalCalendarSetUser,
  PersonalCalendarSetClient,
  PersonalCalendarReset,
} = PersonalCalendarSlice.actions;

export default PersonalCalendarSlice.reducer;
