/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  DefaultCalendar,
  PersonalCalendar,
  StudioCalendar,
  UserCalendar,
  Notifications,
} from '@bludata/blu-booking';
import { Box, Stack, Typography } from '@mui/material';
import { Case, Default, Switch } from 'react-if';
import { useDispatch, useSelector } from 'react-redux';
import { Fragment, useEffect, useState } from 'react';
import { AppDispatch, RootState } from '../../store';
import { setFocus10SelectedView } from '../../store/root';
import ModalObserver from './utils/observer';
import { DashboardAgenda, NuovaRota } from '@bludata/configurazione-components';
import { NotificationDrawer } from './drawer';
import { useUserMeLoginData } from '../../api/user-me-login-data';
import { SocketManager } from '@bludata/components';
import { useQueryClient } from '@tanstack/react-query';

export const Focus10Page = () => {
  const { focus10SelectedView } = useSelector((state: RootState) => state.root);
  const { filiale, alpha, admin, software } = JSON.parse(
    sessionStorage.getItem('sessionData') ?? '{}'
  );

  let isAdmin = false;
  if (admin) {
    isAdmin = admin === 'true';
  } else {
    isAdmin = (sessionStorage.getItem('sessionIsAdmin') ?? false) === 'true';
  }

  const { data } = useUserMeLoginData();
  const dispatch = useDispatch<AppDispatch>();
  const [drawer, setDrawer] = useState<boolean>(false);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (data) {
      const socketManager = new SocketManager({
        url: 'wss://notify.bludata.com:443/sgc/auth/url/F03B6B4B0301496498743FCBB48A059B/198F0D18297F4CA18EF5B1E3EA16E3DE',
      });

      if (data.webSocketChannel) {
        socketManager.subscribeSocketChannel(data.webSocketChannel);

        window.addEventListener('socketEvent', (e: any) => {
          if (
            e?.detail &&
            e?.detail?.event?.channel.indexOf('blubooking:') >= 0
          ) {
            queryClient.invalidateQueries(['appointment-notification'], {
              type: 'active',
            });
            queryClient.removeQueries(['appointment-notification'], {
              type: 'inactive',
            });
          }
        });
      }

      return () => {
        if (data.webSocketChannel)
          socketManager.unsubscribeSocketChannel(data.webSocketChannel);
        socketManager.closeSocket();
      };
    }
  }, [data, queryClient]);

  //@ts-ignore
  window.selezionaAgenda = (agenda: string) => {
    //!"Calendario appuntamenti"
    //!"Calendario personale"
    //!"Giornaliero utente"
    //!"Giornaliero studio"

    if (!agenda) return;
    dispatch(setFocus10SelectedView(agenda));
  };

  useEffect(() => {
    window.addEventListener('openNotify', (e: any) => {
      setDrawer((prevState) => !prevState);
    });
    const sessionAlpha = alpha || '70';
    const alphaNumber = parseInt(sessionAlpha);
    const alphaValue = (alphaNumber / 255).toFixed(3);
    const maskColor = document.createElement('style');
    maskColor.innerHTML = `.MuiBackdrop-root {
        background-color: rgba(0, 0, 0, ${alphaValue}) !important;
      }`;
    document.head.appendChild(maskColor);
  }, [alpha]);

  const getClienteF10 = () => {
    return new Promise<{
      name: string;
      surname: string;
      telephone: string;
      email: string;
    } | null>((resolve, reject) => {
      //@ts-ignore
      if (window.hasOwnProperty('agenda10')) {
        //@ts-ignore
        window.agenda10.getCliente(function (jsonCliente) {
          const json = JSON.parse(jsonCliente);

          if (json.hasOwnProperty('codice')) {
            // ? Aggiungere codice
            resolve({
              name: json.nome,
              surname: json.cognome,
              telephone: json.cellulare || json.telefono,
              email: json.email,
            });
          } else {
            resolve(null);
          }
        });
      } else {
        resolve(null);
      }
    });
  };

  const getCliente = async () => {
    //!funzione async che deve ritornare un oggetto con alcuni dati del cliente o null se non viene selezionato nulla
    try {
      const objectCliente = await getClienteF10();

      return objectCliente;
    } catch (e: any) {
      //@ts-ignore
      return null;
    }
  };

  return (
    <Stack
      sx={{
        width: '100%',
      }}
      flexDirection="column"
    >
      <ModalObserver />
      <Box sx={{ flex: 1, position: 'relative' }}>
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
          }}
        >
          {drawer && <NotificationDrawer open={drawer} setOpen={setDrawer} />}
          <Switch>
            <Case condition={focus10SelectedView === 'Calendario appuntamenti'}>
              <DefaultCalendar
                focus10
                darkMode={'light'}
                pointOfSale={filiale}
                getClient={getCliente}
              />
            </Case>
            <Case condition={focus10SelectedView === 'Calendario personale'}>
              <PersonalCalendar
                focus10
                darkMode={'light'}
                pointOfSale={filiale}
                getClient={getCliente}
              />
            </Case>
            <Case condition={focus10SelectedView === 'Giornaliero utente'}>
              <UserCalendar
                focus10
                darkMode={'light'}
                pointOfSale={filiale}
                getClient={getCliente}
              />
            </Case>
            <Case condition={focus10SelectedView === 'Giornaliero studio'}>
              <StudioCalendar
                focus10
                darkMode={'light'}
                pointOfSale={filiale}
                getClient={getCliente}
              />
            </Case>
            <Case condition={focus10SelectedView === 'Rota'}>
              <Fragment key="rota">
                <NuovaRota isAdmin={isAdmin} focus10 />
              </Fragment>
            </Case>
            <Case condition={focus10SelectedView === 'Analisi'}>
              <Fragment key="analisi">
                <DashboardAgenda software="f10" />
              </Fragment>
            </Case>

            <Case condition={focus10SelectedView === 'Storico notifiche'}>
              <Fragment key="message-history">
                <Notifications focus10 />
              </Fragment>
            </Case>

            <Default>
              <Stack
                flex="1"
                justifyContent="center"
                sx={{ textAlign: 'center' }}
              >
                <Typography variant="h4">
                  Agenda {focus10SelectedView} non disponibile
                </Typography>
              </Stack>
            </Default>
          </Switch>
        </Box>
      </Box>
    </Stack>
  );
};
