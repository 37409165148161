import { memo, useDeferredValue } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'packages/blu-booking/src/store';
import { useUserHoursCalendar } from 'packages/blu-booking/src/data_fetching/UserHours/UserHoursCalendar';
import { useUserHoursShift } from 'packages/blu-booking/src/data_fetching/UserHours/UserHoursShift';
import { Scheduler } from './UI/Scheduler';
import { CalendarLoader } from '../../UI/CalendarLoader/CalendarLoader';

export const PersonalCalendarInner = memo(
  function CalendarPersonal(): JSX.Element {
    const { loadingAppointments } = useSelector(
      (state: RootState) => state.bluBookingaAppointments
    );
    const { filtersLoad } = useSelector(
      (state: RootState) => state.bluBookingCalendarFilters
    );

    const { selectedDate, view, selectedUsers } = useSelector(
      (state: RootState) => state.bluBookingPersonalCalendar
    );

    const { data: calendar, isLoading: calendarIsLoading } =
      useUserHoursCalendar({
        selectedDate,
        view,
        userId: selectedUsers[0]?.id,
        options: {
          enabled: filtersLoad ?? false,
          cacheTime: 300000,
          staleTime: 300000,
          refetchInterval: 300000,
        },
      });

    const { data: shift, isLoading: shiftIsLoading } = useUserHoursShift({
      selectedDate,
      view,
      userId: selectedUsers[0]?.id,
      options: {
        enabled: filtersLoad ?? false,
        cacheTime: 300000,
        staleTime: 300000,
        refetchInterval: 300000,
      },
    });

    const calendarPerUser = useDeferredValue(calendar);
    const shiftPerUser = useDeferredValue(shift);

    return (
      <div key="Personal" style={{ height: '100%' }}>
        {calendarPerUser !== undefined && (
          <Scheduler
            calendarPerUser={calendarPerUser}
            shiftPerUser={shiftPerUser}
          />
        )}
        <CalendarLoader
          open={loadingAppointments || calendarIsLoading || shiftIsLoading}
        />
      </div>
    );
  }
);
