import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import {
  PdfViewerComponent,
  Toolbar,
  Magnification,
  Navigation,
  Print,
  Inject,
} from '@syncfusion/ej2-react-pdfviewer';
import { L10n } from '@syncfusion/ej2-base';
import { it } from '../layout/theme/language/it';

interface Props {
  show: boolean;
  url: string;
  title?: string;
  handleClose: () => void;
}

/**
 * Visualizza un dialogo contenente un visualizzatore PDF.
 *
 * Questa funzione componente riceve delle props per controllare la visualizzazione del dialogo,
 * il titolo del documento e l'URL del PDF da visualizzare. Se il dispositivo è mobile, tablet o iPadOS,
 * il PDF viene aperto in una nuova scheda del browser. Altrimenti, viene visualizzato all'interno di un iframe nel dialogo.
 *
 * @param {Props} props - Le proprietà passate al componente.
 * @param {boolean} props.show - Controlla la visibilità del dialogo.
 * @param {string} props.title - Il titolo del documento da visualizzare nel dialogo.
 * @param {string} props.url - L'URL del PDF da visualizzare.
 * @param {() => void} props.handleClose - Funzione da chiamare per chiudere il dialogo.
 *
 * @example
 * <DialogPdfViewer
 *   show={true}
 *   title="Esempio di Documento"
 *   url="https://example.com/document.pdf"
 *   handleClose={() => console.log('Dialogo chiuso')}
 * />
 */

L10n.load(it);

function DialogPdfViewer(props: Props) {
  const { show, title, url } = props;
  // Estrae la parte dell'URL prima del parametro di query (?)
  const path = url.split('?')[0];

  // Estrae il nome del file dall'ultima parte del percorso
  let fileName = path.substring(path.lastIndexOf('/') + 1);
  if (!fileName.includes('.pdf')) {
    fileName = null;
  }
  if (fileName === 'ricerca.pdf') {
    fileName = title + '.pdf';
  }

  // Renderizza il dialogo contenente il visualizzatore PDF.
  return (
    <Dialog open={show} fullWidth maxWidth="xl">
      <DialogTitle>{title || 'Documento'}</DialogTitle>
      <DialogContent
        sx={{
          p: 1,
          display: 'flex',
          height: 800,
          position: 'relative',
        }}
      >
        <PdfViewerComponent
          id="container"
          locale="it"
          documentPath={url}
          downloadFileName={fileName || 'documento.pdf'}
          toolbarSettings={{
            showTooltip: true,
            toolbarItems: [
              'UndoRedoTool',
              'PageNavigationTool',
              'MagnificationTool',
              'PrintOption',
              'DownloadOption',
            ],
          }}
          resourceUrl="https://cdn.syncfusion.com/ej2/23.1.40/dist/ej2-pdfviewer-lib"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
        >
          <Inject services={[Toolbar, Magnification, Navigation, Print]} />
        </PdfViewerComponent>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={props.handleClose}>
          Chiudi
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DialogPdfViewer;
