/* eslint-disable react/jsx-no-useless-fragment */
import {
  Box,
  Button,
  FormControlLabel,
  Switch,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import { FC, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setEmailValid, setPecValid } from '../../store/root';
import { AppDispatch, RootState } from '../../../../store';
import checkMail from '../../api/check-email';
import { Icon } from './icon';
import { CustomTextField } from '../../common/CustomTextField';
import { useFormContext, useWatch } from 'react-hook-form';
import { BluAlert, BluTitle } from '@bludata/components';
import { fetchShopSignReview } from '../../api/fetchShopSignReview';

import AddTaskIcon from '@mui/icons-material/AddTask';
import moment from 'moment';
import {
  PointOfSaleError,
  ServiceError,
  UserError,
} from '../../../nuova-point-of-sales/interfaces/ShopSignReview_interface';

interface GeneralInterface {
  disabled: boolean;
  hasAgenda: boolean;
  software: string;
  isConsole: boolean;
  isBBActive: boolean;
}

export const General: FC<GeneralInterface> = ({
  disabled,
  software,
  hasAgenda,
  isConsole,
  isBBActive,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { mode, emailValid, pecValid, shopSignId } = useSelector(
    (state: RootState) => state.layoutRoot
  );

  const [email, pec, name, city, address, isTestingActive] = useWatch({
    name: [
      'email',
      'pec',
      'name',
      'address',
      'city',
      'isTestingActive',
      'smsPriority',
      'nameOnline',
    ],
  });
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { setValue } = useFormContext();

  const [pointOfSaleErrors, setPointOfSaleErrors] = useState<
    PointOfSaleError[]
  >([]);
  const [serviceErrors, setServiceErrors] = useState<ServiceError[]>([]);
  const [userErrors, setUserErrors] = useState<UserError[]>([]);

  const handleEmailOnBlur = useCallback(() => {
    checkMail(email).then((value) => {
      dispatch(setEmailValid(!value));
    });
  }, [dispatch, email]);

  const handlePecOnBlur = useCallback(() => {
    checkMail(pec).then((value) => {
      dispatch(setPecValid(!value));
    });
  }, [dispatch, pec]);

  const isErrorMail = useCallback(() => {
    return emailValid;
  }, [emailValid]);

  const isErrorPec = useCallback(() => {
    return pecValid;
  }, [pecValid]);

  const getFieldName = (value: string) => {
    let field = '';
    switch (value) {
      case 'nameOnline':
        field = 'Insegna';
        break;
      case 'email':
        field = 'Email';
        break;
      case 'address':
        field = 'Indirizzo';
        break;
      case 'city':
        field = 'Città';
        break;
      case 'telephone':
        field = 'Telefono';
        break;
      case 'smallDescription':
        field = 'Descrizione BluBooking';
        break;
      case 'name':
        field = 'Nome';
        break;
      case 'surname':
        field = 'Utente';
        break;
      default:
        break;
    }
    return field;
  };

  return (
    <Stack flex={1}>
      <BluAlert
        type="error"
        show={!!error}
        text={error}
        handleClose={() => {
          setError('');
        }}
      />

      {!loading && (
        <BluAlert
          show={open}
          print={{
            printHead: (
              <Stack direction={'row'} flex={1} sx={{ ml: 2, mt: 2 }}>
                <BluTitle text={`Verifica dati ${name}`} />
              </Stack>
            ),
            printFooter: (
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                sx={{ position: 'absulte', bottom: 0 }}
              >
                <Typography
                  m={2}
                  sx={{ color: '#cccccc', fontStyle: 'italic' }}
                >
                  {'Stampato il: ' + moment(new Date()).format('DD/MM/yyyy')} -
                  &#169; {new Date().getFullYear()} {name} - {city} - {address}
                </Typography>
              </Stack>
            ),
          }}
          maxWidth={
            pointOfSaleErrors.length ||
            serviceErrors.length ||
            userErrors.length
              ? 'md'
              : 'sm'
          }
          title={
            pointOfSaleErrors.length ||
            serviceErrors.length ||
            userErrors.length
              ? 'Attenzione'
              : 'Successo'
          }
          type={
            pointOfSaleErrors.length ||
            serviceErrors.length ||
            userErrors.length
              ? 'warning'
              : 'success'
          }
          text={
            <>
              {!pointOfSaleErrors.length &&
              !serviceErrors.length &&
              !userErrors.length ? (
                <></>
              ) : (
                <>
                  <Box sx={{ maxHeight: '40vh', overflow: 'auto' }}>
                    {pointOfSaleErrors.length ? (
                      <Box sx={{ mb: 2 }}>
                        <BluTitle text="Punti vendita" />
                        <Typography variant="body1">
                          Campi mancanti nei punti vendita seguenti:
                        </Typography>
                        {pointOfSaleErrors.map((item: PointOfSaleError) => {
                          return (
                            <>
                              <Typography sx={{ mt: 2, ml: 1 }} variant="body1">
                                <b>{item.name}</b>
                              </Typography>
                              {item.errors.map((error: string) => {
                                return (
                                  <li style={{ marginLeft: '10px' }}>
                                    {getFieldName(error)}
                                  </li>
                                );
                              })}
                            </>
                          );
                        })}
                      </Box>
                    ) : (
                      <></>
                    )}
                    {serviceErrors.length ? (
                      <Box sx={{ mb: 2 }}>
                        {' '}
                        <BluTitle text="Servizi" />
                        <Typography variant="body1">
                          Campi mancanti nei servizi seguenti:
                        </Typography>
                        {serviceErrors.map((item: ServiceError) => {
                          return (
                            <>
                              <Typography sx={{ mt: 2, ml: 1 }} variant="body1">
                                <b>{item.smallDescription}</b>
                              </Typography>
                              {item.errors.map((error: string) => {
                                return (
                                  <li style={{ marginLeft: '10px' }}>
                                    {getFieldName(error)}
                                  </li>
                                );
                              })}
                            </>
                          );
                        })}
                      </Box>
                    ) : (
                      <></>
                    )}
                    {userErrors.length ? (
                      <Box sx={{ mb: 2 }}>
                        {' '}
                        <BluTitle text="Utenti" />
                        <Typography variant="body1">
                          Campi mancanti per i seguenti utenti:
                        </Typography>
                        {userErrors.map((item: UserError) => {
                          return (
                            <>
                              <Typography sx={{ mt: 2, ml: 1 }} variant="body1">
                                <b>{item.username}</b>
                              </Typography>
                              {item.errors.map((error: string) => {
                                return (
                                  <li style={{ marginLeft: '10px' }}>
                                    {getFieldName(error)}
                                  </li>
                                );
                              })}
                            </>
                          );
                        })}
                      </Box>
                    ) : (
                      <></>
                    )}
                  </Box>
                </>
              )}
            </>
          }
          handleClose={() => {
            setOpen(false);
          }}
        />
      )}
      <Stack direction="row" sx={{ pt: '10px' }}>
        <Stack flex={1} gap={1}>
          <Stack
            flexDirection="unset"
            gap={1}
            flexWrap="wrap"
            sx={{ width: '100%' }}
          >
            <CustomTextField name="name" disabled={disabled} label="Layout*" />
            <CustomTextField
              name="nameOnline"
              disabled={disabled}
              label="Insegna*"
            />
            <CustomTextField
              name="vatCode"
              disabled={disabled}
              label="Partita iva*"
            />
          </Stack>
          <Stack
            flexDirection="unset"
            gap={1}
            flexWrap="wrap"
            sx={{ width: '100%' }}
          >
            <CustomTextField
              name="telephone"
              disabled={disabled}
              label="Telefono*"
            />
            <CustomTextField
              name="whatsappNumber"
              disabled={disabled}
              label="Whatsapp"
            />
            <CustomTextField name="fax" disabled={disabled} label="Fax" />
          </Stack>
          <Stack
            flexDirection="unset"
            gap={1}
            flexWrap="wrap"
            sx={{ width: '100%' }}
          >
            <CustomTextField
              name="email"
              disabled={disabled}
              label="Mittente email*"
              error={isErrorMail()}
              // helperText={getEmailHelperText()}
              onBlur={handleEmailOnBlur}
            />
            <CustomTextField
              name="pec"
              disabled={disabled}
              label="Pec"
              error={isErrorPec()}
              onBlur={handlePecOnBlur}
            />
          </Stack>
          <Stack
            flexDirection="unset"
            gap={1}
            flexWrap="wrap"
            sx={{ width: '100%' }}
          >
            <CustomTextField
              name="webSiteLink"
              disabled={disabled}
              label="Sito internet link*"
            />
          </Stack>

          <Stack
            flexDirection="unset"
            gap={1}
            flexWrap="wrap"
            sx={{ width: '100%' }}
          >
            <CustomTextField
              name="address"
              disabled={disabled}
              label="Indirizzo*"
            />
            <CustomTextField
              name="city"
              disabled={disabled}
              label="Città*"
              width="130px"
            />

            <CustomTextField
              name="region"
              disabled={disabled}
              label="Provincia*"
              width="80px"
            />
            <CustomTextField
              name="postalCode"
              disabled={disabled}
              label="Codice postale*"
              width="150px"
            />
          </Stack>
          <Stack
            direction={'column'}
            justifyContent="center"
            alignItems="start"
            width={'100%'}
            sx={{ mt: 2 }}
          >
            {isBBActive && (
              <>
                <BluTitle text="Modalità testing" />
                <Typography
                  sx={{ mt: 1, color: 'var(--color)' }}
                  variant="body2"
                >
                  Attiva la modalità 'Testing' per provare la funzione di
                  prenotazione appuntamenti online. In questa modalità, anche se
                  prenoti, modifichi o elimini un appuntamento nell'agenda, il
                  layout non verrà applicato e, di conseguenza, non verranno
                  inviate notifiche al cliente.
                </Typography>

                <FormControlLabel
                  control={
                    <Switch
                      disabled={disabled}
                      checked={isTestingActive}
                      onChange={() =>
                        setValue('isTestingActive', !isTestingActive)
                      }
                    />
                  }
                  label={
                    isTestingActive ? 'Testing attivo' : 'Testing non attivo'
                  }
                />
              </>
            )}
          </Stack>
          <Stack
            direction={'column'}
            justifyContent="center"
            alignItems="start"
            width={'100%'}
            sx={{ mt: 2 }}
          >
            {isBBActive && mode !== 'create' && (
              <>
                <BluTitle text="Controllo layout" />
                <Typography
                  sx={{ mt: 1, color: 'var(--color)' }}
                  variant="body2"
                >
                  Clicca su <b>Verifica dati</b> per controllare i dati inseriti
                  rispetto al layout desiderato. Questo garantirà che i tuoi
                  dati siano formattati correttamente e pronti per essere
                  visualizzati nel sito di prenotazione.
                </Typography>
                <Button
                  sx={{ mt: 2 }}
                  startIcon={<AddTaskIcon />}
                  onClick={() => {
                    setOpen(true);
                    setLoading(true);
                    fetchShopSignReview(shopSignId!)
                      .then((res) => {
                        setPointOfSaleErrors(res.pointOfSaleErrors);
                        setServiceErrors(res.serviceErrors);
                        setUserErrors(res.userErrors);
                        setLoading(false);
                      })
                      .catch(() => {
                        setLoading(false);
                        setError('Internal server error');
                      });
                  }}
                >
                  Verifica dati
                </Button>
              </>
            )}
          </Stack>
        </Stack>
        <Icon disabled={disabled} />
      </Stack>
    </Stack>
  );
};
