import {
  lightenHexColor,
  wc_hex_is_light,
} from 'packages/blu-booking/src/utility_objects/ColorManager';
import { AppointmentRightClick } from '../../../utility_objects/AppointmentRightClick';
import { getConfig, setConfig } from '../../../utility_objects/WhiteCache';
import hexToRgba from 'hex-to-rgba';

export const eventRendered = (args: any) => {
  // console.log('ELEMENT', args.element);
  if (args?.data) {
    if (!args.data.isReadOnly) {
      args.element.addEventListener('contextmenu', (e: MouseEvent) =>
        AppointmentRightClick(e, args.data)
      );
    }
  }
  if (!args.element.classList.contains('e-agenda-item')) {
    args.element.style.borderRadius = '4px';
    let lighterColor = lightenHexColor(args.data?.color);
    let fontColor: string;
    if (lighterColor === undefined) {
      lighterColor = hexToRgba('var(--base-color)', 0.4);
      fontColor = wc_hex_is_light('var(--base-color)') ? 'black' : 'white';
    } else {
      fontColor = wc_hex_is_light(lighterColor) ? 'black' : 'white';
    }

    // args.element.style.backgroundColor = hexToRgba(
    //   args.data?.color ?? "var(--base-color)",
    //   0.4
    // );

    args.element.style.backgroundColor = lighterColor;

    args.element.style.border = `1px solid ${
      args.data?.color ?? 'var(--base-color)'
    }`;
    args.element.style.color = fontColor;
    // if (args.data.isImportant) {
  } else {
    const appointment = args.element.querySelector('.e-appointment');
    appointment.style.borderLeft = `8px solid ${
      args.data?.color ?? 'var(--base-color)'
    }`;

    appointment.style.color = `var(--color)`;
  }

  const { underline } = getConfig();
  if (underline === args.data?.id) {
    // container?.scrollIntoView
    args.element.style.border = '2px solid var(--base-color)';

    setTimeout(() => {
      args.element.scrollIntoView({ block: 'center' });
    }, 100);

    // setTimeout(() => {
    //   args.element.click();
    // }, 500);

    setTimeout(() => {
      args.element.style.border = 'none';

      setConfig('underline', '');
    }, 3000);
  } else if (underline === undefined) {
    setTimeout(() => {
      setConfig('underline', '');
    }, 500);
  }
};
