import { memo } from 'react';
import { CalendarEventTemplateAgenda } from './CalendarEventTemplateAgenda';
import { CalendarEventTemplateWeek } from './CalendarEventTemplateWeek';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CalendarEventTemplate = memo((props: any) => {
  const { currentView } = props;

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {currentView === 'Agenda' ? (
        <CalendarEventTemplateAgenda {...props} />
      ) : (
        <CalendarEventTemplateWeek {...props} />
      )}
    </>
  );
});
