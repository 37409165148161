import { ShopSignInterface } from '../../../interfaces/shop-sign';
import { defaultTextPrivacyNotice } from './default-privacy-notice';

export const getInitialValues = (data: ShopSignInterface | null) => {
  const defaultShopSign: any = {
    isTestingActive: true,
    bluBookingLink: '',
    address: '',
    region: '',
    fax: '',
    whatsappNumber: '',
    postalCode: '',
    vatCode: '',
    pec: '',
    city: '',
    minBookingValue: 60,
    maxBookingValue: 8,
    minBookingUnit: 0,
    maxBookingUnit: 3,
    minCancellationValue: 24,
    minCancellationUnit: 1,
    timeSlot: 30,
    isRecallOn: true,
    recallValue: 24,
    recallUnit: 1,
    maxRequestableAppointmentByDay: 5,
    isUserSelectable: false,
    privacyPolicy: '<p></p>',
    privacyNotice: defaultTextPrivacyNotice,
    isSmsActive: false,
    isGoogleMapsActive: false,

    appointmentProcess: 0,
    smsPriority: 0,
    imoPriority: 0,
    emailPriority: 1,
    nameOnline: '',
    confirmationMessage:
      "Ti confermiamo l'appuntamento ([tipo appuntamento]) il [data] alle [ora] presso il nostro centro ottico in [indirizzo].",
    modificationMessage:
      'Ti confermiamo la modifica del tuo appuntamento ([tipo appuntamento]) il [data] alle [ora] presso il nostro centro ottico in [indirizzo].',
    cancellationMessage:
      "Gentile cliente, l'appuntamento il [data] alle [ora] presso il nostro negozio in [indirizzo] è stato cancellato.",
    reminderMessage:
      "Ti ricordiamo l'appuntamento ([tipo appuntamento]) il [data] alle [ora] presso il nostro centro ottico in [indirizzo].",
    confirmationIMO:
      "Ti confermiamo l'appuntamento ([tipo appuntamento]) il [data] alle [ora] presso il nostro centro ottico in [indirizzo]. Per modificare o cancellare l'appuntamento, visita la sezione 'Prenota'. Per contattarci, scrivi un messaggio qui o chiamaci ai numeri che trovi nella sezione 'Ottico'.",
    modificationIMO:
      "Ti confermiamo la modifica del tuo appuntamento ([tipo appuntamento]) il [data] alle [ora] presso il nostro centro ottico in [indirizzo]. Se desideri modificare o cancellare l'appuntamento, visita la sezione 'Prenota'. Per contattarci, scrivi un messaggio qui o chiamaci ai numeri che trovi nella sezione 'Ottico'.",
    cancellationIMO:
      "Gentile cliente, l'appuntamento il [data] alle [ora] presso il nostro negozio in [indirizzo] è stato cancellato. Per contattarci, scrivi un messaggio qui o chiamaci ai numeri che trovi nella sezione 'Ottico'.",
    reminderIMO:
      "Ti ricordiamo l'appuntamento ([tipo appuntamento]) il [data] alle [ora] presso il nostro centro ottico in [indirizzo]. Per contattarci, invia un messaggio qui o chiamaci ai numeri disponibili nella sezione 'Ottico'.",
  };
  if (data === null) return defaultShopSign;
  return {
    isTestingActive: data?.isTestingActive ?? true,
    bluBookingLink: data?.bluBookingLink,
    companyId: data?.companyId,
    logo: data?.logo,
    favicon: data?.favicon || '',
    name: data?.name || '',
    telephone: data?.telephone || '',
    whatsappNumber: data?.whatsappNumber || '',
    email: data?.email || '',
    token: data.token,
    address: data?.address || '',
    region: data?.region || '',
    fax: data?.fax || '',
    webSiteLink: data.webSiteLink,
    postalCode: data.postalCode,
    vatCode: data.vatCode,
    pec: data.pec,
    city: data.city,
    minBookingValue: data.minBookingValue,
    maxBookingValue: data.maxBookingValue,
    minBookingUnit: data.minBookingUnit,
    maxBookingUnit: data.maxBookingUnit,
    minCancellationValue: data.minCancellationValue,
    minCancellationUnit: data.minCancellationUnit,
    timeSlot: data.timeSlot,
    isRecallOn: data.isRecallOn,
    recallValue: data.recallValue,
    recallUnit: data.recallUnit,
    maxRequestableAppointmentByDay: data.maxRequestableAppointmentByDay,
    isUserSelectable: data.isUserSelectable,
    isSmsActive: data.isSmsActive,
    isGoogleMapsActive: data.isGoogleMapsActive,
    appointmentProcess: data?.appointmentProcess ?? 0,
    privacyNotice: data.privacyNotice ?? '<p></p>',
    privacyPolicy: data.privacyPolicy ?? '<p></p>',

    smsPriority: data?.smsPriority ?? 0,
    imoPriority: data?.imoPriority ?? 0,
    emailPriority: data?.emailPriority ?? 1,
    nameOnline: data.nameOnline,
    confirmationMessage: data.confirmationMessage,
    modificationMessage: data.modificationMessage,
    cancellationMessage: data.cancellationMessage,
    reminderMessage: data.reminderMessage,
    confirmationIMO: data?.confirmationIMO ?? '',
    modificationIMO: data?.modificationIMO ?? '',
    cancellationIMO: data?.cancellationIMO ?? '',
    reminderIMO: data?.reminderIMO ?? '',
  };
};
