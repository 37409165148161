import { IconButton, MenuItem, Stack, Typography } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import { BluSelect } from '@bludata/components';
import { FC, useCallback, useEffect } from 'react';
import { useLayoutCrm } from '../../../../api/layout-crm';
import { setIsLoadingForm } from '../../../../store/root';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../../../store';
import { GridCloseIcon } from '@mui/x-data-grid-premium';

interface LayoutCrmSelectInterface {
  disabled?: boolean;
}

export const LayoutCrmSelect: FC<LayoutCrmSelectInterface> = ({ disabled }) => {
  const crmLayoutId = useWatch({ name: 'crmLayoutId' });
  const { setValue, trigger } = useFormContext();

  const { data, isLoading } = useLayoutCrm();
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    dispatch(setIsLoadingForm({ key: 'layoutCrm', value: isLoading }));
  }, [dispatch, isLoading]);

  const getOptions = useCallback(() => {
    if (data) return data;
    return [];
  }, [data]);

  const handleOnChange = useCallback(
    (event: any) => {
      const find = (data ?? []).find((d) => d.id === event.target.value);
      setValue('crmLayoutId', event.target.value);
      if (find) setValue('crmCodiceLayout', find.codicefiliale);
      trigger(['crmLayoutId', 'crmCodiceLayout']);
    },
    [data, setValue, trigger]
  );

  const handleErase = useCallback(() => {
    setValue('crmLayoutId', '');
    trigger('crmLayoutId');
  }, [setValue, trigger]);

  return (
    <Stack sx={{ width: '260px' }}>
      <Typography>Layout app</Typography>
      <Stack flex="1" direction="row">
        <BluSelect
          multiple={false}
          disabled={disabled}
          value={crmLayoutId}
          onChange={handleOnChange}
          displayEmpty
          renderValue={(selected: any) => {
            if (data && data.length > 0) {
              const find = data.find((d) => d.id === selected);
              if (find) return find.layout;
            }
            return 'Nessun Layout associato' as any;
          }}
        >
          {getOptions().map((shopSign: any) => (
            <MenuItem value={shopSign.id}>{shopSign.layout}</MenuItem>
          ))}
          <MenuItem value={''}>Nessun Layout associato</MenuItem>
        </BluSelect>
        <IconButton color="primary" size="small" onClick={handleErase}>
          <GridCloseIcon />
        </IconButton>
      </Stack>
    </Stack>
  );
};
