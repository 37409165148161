/* eslint-disable @typescript-eslint/no-explicit-any */
import { Stack } from '@mui/material';
import 'dayjs/locale/it';
import { memo, useCallback, useMemo } from 'react';
import {
  BluContainerBlock,
  BludataRightSideSectionMenu,
  BluSyncDatePicker,
} from '@bludata/components';
import { AppDispatch, RootState } from 'packages/blu-booking/src/store';
import { useDispatch, useSelector } from 'react-redux';

import {
  CalendarDaySelection,
  PointOfSaleFilter,
  ServiceFilter,
} from '../../../UI/AppointmentFilters';
import { RapidFunctions } from '../../../UI/AppointmentFilters/RapidFunctions';
import { Zoom } from '../../../UI/AppointmentFilters/Zoom';
import { ZoomItem } from '../../../interfaces/Zoom';
import {
  UserCalendarResetSelected,
  UserCalendarSelectPointOfSale,
  UserCalendarSelectZoomValue,
  UserCalendarSetSelectedDate,
  UserCalendarSetService,
} from '../store';
import { AppointmentCompleteInterface } from 'packages/blu-booking/src/interfaces/AppointmentComplete';
import dayjs from 'dayjs';
import { setRefetch } from '../../../store/Appointment';

export const Filters = memo(function Drawer(): JSX.Element {
  const { selectedServices, selectedPointOfSale, selectedDate, zoomValue } =
    useSelector((state: RootState) => state.bluBookingUserCalendar);
  const { pointOfSales } = useSelector(
    (state: RootState) => state.bluBookingCalendarFilters
  );
  const dispatch = useDispatch<AppDispatch>();

  const CalendarSelection = useMemo(() => {
    return (
      <CalendarDaySelection
        move={[1, 'days']}
        selectedDate={selectedDate}
        setSelectedDate={(value: any) =>
          dispatch(UserCalendarSetSelectedDate(value))
        }
      />
    );
  }, [selectedDate, dispatch]);

  const handlePosition = useCallback(
    (appointment: AppointmentCompleteInterface) => {
      const d = dayjs(selectedDate).format('DD/MM/YYYY');
      const appointmentDate = dayjs(appointment?.startTime);

      if (
        appointment?.pointOfSale &&
        appointment?.pointOfSale?.id &&
        pointOfSales &&
        appointment?.pointOfSale?.id in pointOfSales
      )
        dispatch(
          UserCalendarSelectPointOfSale(
            pointOfSales[appointment?.pointOfSale?.id]
          )
        );

      if (selectedServices.length > 0)
        dispatch(UserCalendarResetSelected('selectedServices'));

      if (d !== appointmentDate.format('DD/MM/YYYY'))
        dispatch(UserCalendarSetSelectedDate(appointmentDate.toDate()));
      else dispatch(setRefetch());
    },
    [dispatch, pointOfSales, selectedDate, selectedServices.length]
  );

  return (
    <BludataRightSideSectionMenu
      sx={{ mt: 0.5, mr: 0.5, mb: 0.5, pr: 0, height: '100%' }}
    >
      <Stack
        flexDirection="column"
        flex={1}
        sx={{ width: 250, height: '100%', p: '8px', pr: '3px' }}
      >
        {CalendarSelection}
        <BluSyncDatePicker
          date={selectedDate}
          onChange={(event: any) =>
            dispatch(UserCalendarSetSelectedDate(event.target.value))
          }
        />
        <Stack
          flexDirection="column"
          flex={1}
          sx={{ overflowY: 'auto', overflowX: 'hidden' }}
          spacing={1}
        >
          <PointOfSaleFilter
            selectedPointOfSales={selectedPointOfSale}
            addPointOfSale={(value: any) =>
              dispatch(UserCalendarSelectPointOfSale(value))
            }
          />
          <ServiceFilter
            selectedService={selectedServices}
            pointOfSaleId={selectedPointOfSale?.id}
            addService={(value: any) => dispatch(UserCalendarSetService(value))}
            resetService={() =>
              dispatch(UserCalendarResetSelected('selectedServices'))
            }
          />
          {selectedPointOfSale && (
            <RapidFunctions
              pointOfSaleId={selectedPointOfSale?.pointOfSaleId}
              handlePosition={handlePosition}
            />
          )}
        </Stack>
        <Zoom
          value={zoomValue}
          change={(value: ZoomItem) => UserCalendarSelectZoomValue(value)}
        />
      </Stack>
    </BludataRightSideSectionMenu>
  );
});
