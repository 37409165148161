/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';

export const appointmentUpdateSlotOkStatus = async (
  appointmentId: string,
  slotOkStatus: number
): Promise<boolean> => {
  try {
    await axios.post('/api/Appointment/SetSlotOkStatus', {
      appointmentId,
      slotOkStatus,
    });
    return true;
  } catch (error) {
    console.trace(error);
    return false;
  }
};
