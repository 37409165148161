import { Button, Paper, TextField, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import './login.css';
import '../../components/footer/footer.css';
import {
  setAccessToken,
  setCompanyColor,
  setIsManualAppointmentBlock,
  setIsSingleCalendarManagement,
  setLoginData,
  setRefreshToken,
  setSoftware,
  setVisibilityUserEnum,
} from '../../store/root';
import axios from 'axios';
import { BluAlert, BluLoader } from '@bludata/components';
import { useEffect, useState } from 'react';
import {
  getRequestInterceptorId,
  getResponseInterceptorId,
} from '../../lib/interceptors';
import { setCookie } from '../../lib/cookie';
import md5 from 'md5';

export interface LoginForm {
  identificationCode: string;
  username: string;
  password: string;
}

const Login = () => {
  const dispatch = useDispatch();

  const [badLogin, setBadLogin] = useState('');
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  // const md5 = require('md5');

  axios.defaults.baseURL = import.meta.env.VITE_NX_PUBLIC_API_URL;
  axios.defaults.headers.common['Authorization'] = undefined;
  const urlParams = new URLSearchParams(window.location.search);

  const requestInterceptorId = getRequestInterceptorId();
  const responseInterceptorId = getResponseInterceptorId();
  if (requestInterceptorId)
    axios.interceptors.request.eject(requestInterceptorId);
  if (responseInterceptorId)
    axios.interceptors.response.eject(responseInterceptorId);

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: {
      username: localStorage.getItem('BluBookingUsername') || '',
      identificationCode:
        localStorage.getItem('BluBookingIdentificationCode') || '',
      password: '',
    },
  });

  useEffect(() => {
    if (urlParams.has('identificationCode')) {
      setValue('identificationCode', urlParams.get('identificationCode') || '');
    }
    if (urlParams.has('username')) {
      setValue('username', urlParams.get('username') || '');
    }
  });

  const onSubmit = (data: LoginForm) => {
    /*

      identificationCode = 1595A-18B4LI
      Username = Pierantonio
      Pass = 12345678

    */
    setLoading(true);

    axios
      .post('/api/authenticate/login', {
        identificationCode: data.identificationCode.trim().toUpperCase(),
        username: data.username.trim(),
        password: md5(data.password),
      })
      .then((res: any) => {
        localStorage.setItem(
          'BluBookingIdentificationCode',
          data.identificationCode.trim().toUpperCase()
        );
        localStorage.setItem('BluBookingUsername', data.username);

        dispatch(setAccessToken(res.data.data.token));
        setCookie('BluBookingToken', res.data.data.token);

        dispatch(setRefreshToken(res.data.data.refreshToken));
        setCookie('BluBookingRefreshToken', res.data.data.refreshToken, 48);

        dispatch(setCompanyColor(res.data.data.companyColorEnum));
        setCookie('BluBookingCompanyColor', res.data.data.companyColorEnum);

        if (res.data.data.photo !== null)
          setCookie('BluBookingUserPhoto', res.data.data.photo);
        setCookie('BluBookingUserAdmin', res.data.data.isAdmin);

        setCookie('BluBookingSoftware', res.data.data.software);

        if (
          res.data.data?.isManualAppointmentBlock !== null &&
          res.data.data?.isManualAppointmentBlock !== undefined
        ) {
          setCookie(
            'BluBookingManualAppointmentBlock',
            res.data.data?.isManualAppointmentBlock ?? false
          );
        }

        if (res.data.data?.webSocketChannel)
          setCookie('BluBookingSocketChannel', res.data.data?.webSocketChannel);
        setCookie('BluBookingpointOfSaleId', res?.data?.data?.pointOfSaleId);

        setCookie(
          'BluBookingIsManualAppointmentBlock',
          res?.data?.data?.isManualAppointmentBlock ?? false
        );
        dispatch(
          setIsManualAppointmentBlock(
            res?.data?.data?.isManualAppointmentBlock ?? false
          )
        );

        setCookie(
          'BluBookingIsSingleCalendarManagement',
          res?.data?.data?.isSingleCalendarManagement ?? true
        );
        dispatch(
          setIsSingleCalendarManagement(
            res?.data?.data?.isSingleCalendarManagement ?? true
          )
        );

        dispatch(setSoftware(res.data.data.software));
        dispatch(setVisibilityUserEnum(res.data.data?.visibilityUserEnum ?? 0));
        setCookie(
          'BluBookingVisibilityUserEnum',
          res?.data?.data?.visibilityUserEnum ?? 0
        );

        dispatch(
          setLoginData({
            photo: res.data.data.photo,
            isAdmin: res.data.data.isAdmin,
            username: res.data.data.username,
            pointOfSaleId: res?.data?.data?.pointOfSaleId,
            socketChannel: res.data.data?.webSocketChannel,
          })
        );
      })
      .catch((err) => {
        setBadLogin('Codice identificativo, utente o password errati');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Stack
      flex={1}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <BluLoader open={loading} />
      <BluAlert
        type="info"
        show={!!badLogin}
        text={badLogin}
        title={'Versioni'}
        handleClose={() => {
          setBadLogin('');
        }}
      />
      <div className="background-layer"></div>
      <Paper
        elevation={1}
        sx={{
          width: '450px',
          height: '350px',
          zIndex: 10,
          backgroundColor: 'white',
          position: 'relative',
        }}
      >
        <Stack
          flexDirection="column"
          sx={{
            position: 'absolute',
            top: '20px',
            left: '20px',
            right: '20px',
            bottom: '20px',
          }}
        >
          <Typography sx={{ textAlign: 'center' }} variant="h5">
            Console Blu Booking
          </Typography>
          <Typography sx={{ marginTop: '25px', mb: 1 }} variant="body1">
            Inserisci le tue credenziali per accedere.
          </Typography>

          <form
            style={{
              flex: 1,
              display: 'flex',
            }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Stack
              flex={1}
              flexDirection="column"
              spacing={1}
              justifyContent="center"
            >
              <Controller
                name="identificationCode"
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <TextField
                    error={!!errors.identificationCode}
                    autoComplete="identificationCode"
                    label="Codice identificativo"
                    {...field}
                    inputProps={{
                      style: { textTransform: 'uppercase' },
                    }}
                  />
                )}
              />
              <Controller
                name="username"
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <TextField
                    error={!!errors.username}
                    autoComplete="username"
                    label="Utente"
                    {...field}
                  />
                )}
              />
              <Controller
                name="password"
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Password"
                    error={!!errors.password}
                    type="password"
                    autoComplete="current-password"
                    {...field}
                  />
                )}
              />
              <Stack flexDirection="row" justifyContent="end">
                <Button
                  // startIcon={<LoginIcon />}
                  type="submit"
                  sx={{ mt: '10px' }}
                  variant="contained"
                >
                  Accedi
                </Button>
              </Stack>
            </Stack>
          </form>
        </Stack>
      </Paper>
      <Stack
        className="my-footer"
        flexDirection="row"
        justifyContent="end"
        alignItems="center"
      >
        <Typography variant="body1" sx={{ marginRight: '10px' }}>
          &#169; {new Date().getFullYear()} Bludata Informatica S.r.l.
        </Typography>
        <img
          style={{
            marginRight: '10px',
            width: 'auto',
          }}
          src="https://focus.bludata.com/logo_bludata_50.png"
          alt="Logo Bludata Informatica S.r.l."
        />
      </Stack>
    </Stack>
  );
};

export default Login;
