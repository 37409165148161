/* eslint-disable @typescript-eslint/no-explicit-any */
import '../../../../styles/SearchPopup.css';

import { memo, useCallback, useEffect } from 'react';
import {
  DataGridPremium,
  GridRenderCellParams,
  GridRowId,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import moment from 'moment';
import { getConfig } from '../../../../utility_objects/WhiteCache';
import { useSearch } from 'packages/blu-booking/src/data_fetching/Search';

interface TableResultProps {
  value: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action?: (value: any) => void;

  keyDown?: string;
  setKeyDown?: (value: string) => void;
}

export const TableResult = memo(
  ({ value, action = (_) => null, keyDown, setKeyDown }: TableResultProps) => {
    const { focus10 } = getConfig();
    const today = moment();
    const apiRef = useGridApiRef();
    const { data, isLoading } = useSearch(value, false, focus10 ? '100' : '5');

    useEffect(() => {
      if (data && data.length > 0) {
        const selectedRowIds = Array.from(
          apiRef.current.getSelectedRows().keys()
        );
        const selectedRowId = selectedRowIds[0];

        const currentRowIndex = data.findIndex(
          (row) => row?.id === selectedRowId
        );
        if (currentRowIndex === -1) {
          apiRef.current.selectRow(data[0]?.id);
          apiRef.current.setSelectionModel([data[0]?.id]);
        }
      }
    }, [apiRef, data]);

    const gestioneSelezioneArrows = useCallback(
      (id: GridRowId, next: boolean) => {
        if (data) {
          if (next) {
            const currentRowIndex = data.findIndex((row) => row?.id === id);

            if (currentRowIndex < data.length - 1) {
              const newSelectedRowId = data[currentRowIndex + 1]?.id;

              apiRef.current.selectRow(newSelectedRowId);
              apiRef.current.setSelectionModel([newSelectedRowId]);
            }
          } else {
            const currentRowIndex = data.findIndex((row) => row?.id === id);
            if (currentRowIndex > 0) {
              const newSelectedRowId = data[currentRowIndex - 1]?.id;
              apiRef.current.selectRow(newSelectedRowId);
              apiRef.current.setSelectionModel([newSelectedRowId]);
            }
          }
        }
      },
      [apiRef, data]
    );

    useEffect(() => {
      if (keyDown) {
        const selectedRowIds = Array.from(
          apiRef.current.getSelectedRows().keys()
        );
        const selectedRowId = selectedRowIds[0];

        if (keyDown === 'Enter') {
          const selectedRow = apiRef.current.getRow(selectedRowId);
          if (selectedRow) action(selectedRow);
        } else if (keyDown === 'ArrowUp') {
          gestioneSelezioneArrows(selectedRowId, false);
          // Handle arrow up key press
        } else if (keyDown === 'ArrowDown') {
          // Handle arrow down key press
          gestioneSelezioneArrows(selectedRowId, true);
        }
        setKeyDown(undefined);
      }
    }, [action, apiRef, gestioneSelezioneArrows, keyDown, setKeyDown]);

    return (
      <DataGridPremium
        columns={[
          {
            field: 'id',
            hide: true,
          },
          {
            field: 'surname',
            headerName: 'Cognome',
            flex: 1,
            editable: false,
            sortable: false,
            disableColumnMenu: true,
          },
          {
            field: 'name',
            headerName: 'Nome',
            flex: 1,
            editable: false,
            sortable: false,
            disableColumnMenu: true,
          },
          {
            field: 'cognome2',
            headerName: 'Cognome 2',
            width: 130,
            editable: false,
            hide: !focus10,
            sortable: false,
            disableColumnMenu: true,
          },
          {
            field: 'dataNascita',
            align: 'center',
            headerAlign: 'center',
            headerName: 'Età',
            width: 60,
            editable: false,
            hide: !focus10,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: GridRenderCellParams) => {
              const dataNString = params.value;
              if (dataNString) {
                const dataN = moment(dataNString);
                const eta = today.diff(dataN, 'years');
                return eta;
              }

              return '';
            },
          },
          {
            field: 'indirizzo',
            headerName: 'Indirizzo',
            flex: 1,
            editable: false,
            hide: !focus10,
            sortable: false,
            disableColumnMenu: true,
          },
          {
            field: 'citta',
            headerName: 'Città',
            flex: 1,
            editable: false,
            hide: !focus10,
            sortable: false,
            disableColumnMenu: true,
          },
          {
            field: 'email',
            headerName: 'Email',
            flex: 1,
            editable: false,
            hide: focus10,
            sortable: false,
            disableColumnMenu: true,
          },
          {
            field: 'telephone',
            headerName: 'Telefono',
            flex: 1,
            editable: false,
            hide: focus10,
            sortable: false,
            disableColumnMenu: true,
          },
        ]}
        apiRef={apiRef}
        rows={data ? data : []}
        sx={{
          height: '290.5px',
          '& .MuiDataGrid-row': {
            cursor: 'pointer',
            minHeight: '50px !important',
          },
        }}
        loading={isLoading && data === undefined}
        density="compact"
        disableColumnMenu
        disableMultipleSelection
        hideFooter
        editMode="row"
        getRowHeight={() => 'auto'}
        onProcessRowUpdateError={(error) => console.log(error)}
        experimentalFeatures={{ newEditingApi: true }}
        onRowClick={(event: any) => {
          action(event?.row);
        }}
      />
    );
  }
);
