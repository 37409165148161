import { Typography } from "@mui/material";
import { memo } from "react";

export const GoogleCalendar = memo(() => {
  return (
    <>
      <Typography>GOOGLE CALENDAR</Typography>
    </>
  );
});
