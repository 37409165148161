import * as React from 'react'
import { IconButton } from '@mui/material'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import { SxProps } from '@mui/material'
import { useState } from 'react'
import BluAlert from '../Alert'

interface AccordioProps {
  text: string
  title?: string
  sx?: SxProps
}

export const BluTooltip = React.memo(
  ({ text, sx, title = 'Informazioni' }: AccordioProps) => {
    const [open, setOpen] = useState(false)
    return (
      <>
        <BluAlert
          show={open}
          text={text}
          handleClose={() => setOpen(false)}
          type="info"
          title={title}
        />
        <IconButton
          sx={sx}
          size="small"
          onClick={() => {
            setOpen(true)
          }}
        >
          <QuestionMarkIcon />
        </IconButton>
      </>
    )
  }
)

export default BluTooltip
