import { faUsers } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { FC, memo, useMemo } from 'react';

interface ClientInterface {
  name?: string;
  surname?: string;
  email?: string;
  telephone?: string;
  externalCustomerCode?: string;
}

export const Client: FC<ClientInterface> = memo(
  ({
    name,
    surname,
    email,
    telephone,
    externalCustomerCode,
  }: ClientInterface) => {
    const ClientInner = useMemo(() => {
      if (name !== undefined || surname !== undefined) {
        return (
          <Stack direction="row" gap="10px">
            <Stack direction="column" flex="1" justifyItems="start">
              <Stack
                flex="1"
                direction="row"
                gap={1}
                alignItems="center"
                sx={{ pb: '5px' }}
              >
                <Typography
                  variant="h6"
                  fontWeight="600"
                  sx={{
                    width: 'fit-content',
                    height: 'fit-content',
                    color: 'inherit',
                  }}
                >{`${surname || ''} ${name || ''}`}</Typography>
                {externalCustomerCode && externalCustomerCode !== '' && (
                  <FontAwesomeIcon
                    icon={faUsers}
                    style={{
                      color: 'inherit',

                      fontSize: '20px',
                    }}
                  />
                )}
              </Stack>
              <Typography
                variant="body1"
                sx={{
                  color: 'inherit',
                }}
              >
                {email}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: 'inherit',
                }}
              >
                {telephone}
              </Typography>
            </Stack>
          </Stack>
        );
      }
      return null;
    }, [name, surname, externalCustomerCode, email, telephone]);
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{ClientInner}</>;
  }
);
