import Services from './pages/services';
import NuovaRota from './pages/nuova-rota';
import { NewPointOfSales } from './pages/nuova-point-of-sales/PointOfSales';
import Layout from './pages/layout';
import Studi from './pages/studi';
import Ge from './pages/generali/Generali';
import An from './pages/analisi/Analisi';
import { NewUsers } from './pages/nuovi-users/Users';
import { FC, memo } from 'react';
import { Provider } from 'react-redux';
import { store } from './store';
import { registerLicense } from '@syncfusion/ej2-base';
import { Syncfusion_license } from '@bludata/components';

registerLicense(Syncfusion_license);

const Generali: FC<{
  software: 'bb' | 'fw' | 'crm' | 'f10';
  root?: string;
  refreshToken?: () => void;
}> = memo(({ software, root, refreshToken }) => {
  return (
    <Provider store={store}>
      <Ge software={software} root={root} refreshToken={refreshToken} />
    </Provider>
  );
});

const DashboardAgenda: FC<{
  software: 'bb' | 'fw' | 'crm' | 'f10';
  root?: string;
}> = memo(({ software, root }) => {
  return (
    <Provider store={store}>
      <An software={software} root={root} />
    </Provider>
  );
});

export {
  Services,
  Layout,
  Studi,
  Generali,
  DashboardAgenda,
  NuovaRota,
  NewPointOfSales,
  NewUsers,
};
