import { object, string, boolean, date, number } from 'yup';
import 'yup-phone-lite';

export const schema = object().shape({
  status: number().default(0),
  hasToSendEmail: boolean().default(true),
  isImportant: boolean().default(false),
  serviceId: string().required('Il servizio è obbligatorio'),
  pointOfSaleId: string().required('Il punto vendita è obbligatorio'),
  studioId: string().when('studioRequired', {
    is: true,
    then: string().required(
      'Lo studio è obbligatorio per questo tipo di appuntamento'
    ),
  }),
  initialStatus: number().default(0),
  durationSet: boolean().default(false),
  studioRequired: boolean().default(true),
  initialStart: date(),
  initialEnd: date(),
  timeSlotDate: date().required(),
  start: date().required(),
  duration: number().required(),
  end: date().required(),
  searchDisponibility: boolean(),
  userId: string().required("L'utente è obbligatorio"),
  userActive: boolean()
    .default(true)
    .oneOf([true], "L'utente selezionato non è attivo"),
  searchOpen: boolean().default(false),
  name: string().when(['email', 'telephone', 'isIMO'], {
    is: (email: string, telephone: string, isIMO: string) =>
      (email && email !== '') || (telephone && telephone !== '') || isIMO,
    then: string().required('Inserire un nome'),
  }),
  surname: string().when(['email', 'telephone', 'isIMO'], {
    is: (email: string, telephone: string, isIMO: string) =>
      (email && email !== '') || (telephone && telephone !== '') || isIMO,
    then: string().required('Inserire un cognome'),
  }),
  nameSurname: string().when(
    ['surname', 'name', 'email', 'telephone', 'isIMO'],
    {
      is: (
        name: string,
        surname: string,
        email: string,
        telephone: string,
        isIMO: string
      ) => {
        if ((email && email !== '') || (telephone && telephone !== '') || isIMO)
          return false;
        return (!name || name === '') && (!surname || surname === '');
      },
      then: string().required('Inserire un nome o un cognome'),
    }
  ),
  email: string().email('Email non valida'),
  externalCustomerCode: string().nullable(),
  isIMO: boolean(),
  telephone: string().phone('IT', 'Telefono non valido'),
  description: string().max(512, 'Il testo risulta troppo lungo'),
  internalDescription: string().max(512, 'Il testo risulta troppo lungo'),
  createdByUsername: string().nullable(),
  createdById: string().nullable(),
  updatedByUsername: string().nullable(),
  updatedById: string().nullable(),
  createdAt: date().nullable(),
  updatedAt: date().nullable(),
  cercaDisponibilitaUsed: boolean().default(false),
  isRecallOn: boolean().default(true),
});
