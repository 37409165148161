import React, { memo } from 'react';

export const BluBookingTerms = memo(() => {
  const storageUrl = import.meta.env.VITE_NX_PUBLIC_STORAGE_URL;

  return (
    <iframe
      title="blu-booking"
      src={`${storageUrl}/websites/BluBooking/index.html`}
      width="100vw"
      height="100vh"
      style={{ width: '100%', height: '100%', overflowY: 'auto' }}
    ></iframe>
  );
});
