import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { Appointment_interface } from '../../interfaces/Appointment';
import { getConfig } from '../../pages/Calendars/utility_objects/WhiteCache';

interface AppointmentsClientInterface {
  name?: string;
  surname?: string;
  email?: string;
  telephone?: string;
  externalCustomerCode?: string;
  unregistered: boolean;
}

export const getAppointmentsClient = async ({
  name,
  surname,
  email,
  telephone,
  externalCustomerCode,
  unregistered,
}: AppointmentsClientInterface): Promise<Appointment_interface[]> => {
  try {
    const { focus10, focusWeb } = getConfig();
    let result = [];
    if ((!focus10 && !focusWeb) || unregistered) {
      const {
        data: { data },
      } = await axios.get(`/api/Appointment/SearchByInfo`, {
        params: {
          name,
          surname,
          email,
          telephone,
        },
      });
      result = data;
    } else {
      const {
        data: { data },
      } = await axios.get(`/api/Appointment/SearchByExternalCustomerCode`, {
        params: {
          externalCustomerCode,
        },
      });
      result = data;
    }

    for (const appointment of result) {
      appointment.date = dayjs(appointment?.startTime).format('DD/MM/YYYY');
    }

    return result;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const useAppointmentsClient = (
  {
    name,
    surname,
    email,
    telephone,
    externalCustomerCode,
    unregistered,
  }: AppointmentsClientInterface,
  key = ''
) => {
  return useQuery({
    queryFn: async () =>
      await getAppointmentsClient({
        name,
        surname,
        email,
        telephone,
        externalCustomerCode,
        unregistered,
      }),
    queryKey: [
      'client-appointments',
      email,
      externalCustomerCode,
      name,
      surname,
      telephone,
      key,
    ],
    retry: 2,
    retryDelay: 180000,
    cacheTime: 0,
    staleTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: true,
  });
};
