import axios from 'axios';
import { getFaviconFile, getLogoFile, getPath } from '../lib/white-cache';
import { ShopSignInterface } from '../../../interfaces/shop-sign';

export const manageShopSign = async (
  data: ShopSignInterface,
  palette: any,
  typography: any,
  components: any,
  customTheme: any,
  software: string,
  isConsole: boolean,
  shopSignId?: string
): Promise<string> => {
  const theme = JSON.stringify({ palette, typography, components });
  const customConfigurationJson = JSON.stringify(customTheme);
  let shopSign: any = {
    ...data,
    configurationJson: theme,
    customConfigurationJson,
    ConfigurationJsonTest: '{}',
    CustomConfigurationJsonTest: '{}',
    imoPriority: isConsole && software === 'bb' ? 0 : data.imoPriority,
    emailPriority: isConsole && software === 'bb' ? 1 : data.emailPriority,
    smsPriority: isConsole && software === 'bb' ? 0 : data.smsPriority,
  };

  const logo = getLogoFile();
  const favicon = getFaviconFile();

  if (shopSignId === undefined || shopSignId === '') {
    //? create
    shopSign = {
      ShopSign: {
        ...shopSign,
        logo: logo === undefined ? shopSign.logo : logo,
        favicon: favicon === undefined ? shopSign.favicon : favicon,
      },
    };
  } else {
    //? modify
    shopSign = {
      ShopSign: {
        ...shopSign,
        logo: logo,
        favicon: favicon,
        ShopSignId: shopSignId,
      },
    };
  }
  if (
    shopSign.ShopSign.whatsappNumber === null ||
    shopSign.ShopSign.whatsappNumber === ''
  )
    delete shopSign.ShopSign.whatsappNumber;
  const body = new FormData();
  for (const key in shopSign.ShopSign) {
    if (Object.prototype.hasOwnProperty.call(shopSign.ShopSign, key)) {
      const element = shopSign.ShopSign[key];
      shopSign['ShopSign.' + key] = element;
      body.append(`ShopSign.${key}`, element);
    }
  }

  delete shopSign.ShopSign;

  try {
    const {
      data: { data: result },
    } = await axios.post(`${getPath()}/ShopSign/Management`, body, {
      headers: {
        // 'application/json' is the modern content-type for JSON, but some
        // older servers may use 'text/json'.
        // See: http://bit.ly/text-json
        'Content-Type': 'multipart/form-data',
      },
    });
    return shopSignId && shopSignId !== '' ? shopSignId : result;
  } catch (err) {
    console.error(err);
    return '';
  }
};
