import dayjs from 'dayjs';

export const getAppointmentDateRange = (day: Date, view: string) => {
  const start = dayjs(day).startOf('date').locale('it');
  const end = dayjs(day).endOf('date').locale('it');
  switch (view) {
    case 'Month':
      return [
        start.startOf('month').subtract(5, 'day'),
        end.endOf('month').add(5, 'day'),
      ];
    case 'Week':
      return [start.startOf('week'), end.endOf('week')];
    case 'WorkWeek':
      return [start.startOf('week'), end.endOf('week')];
    case '3Days':
      return [start, end.add(3, 'days')];

    default:
      return [start, end];
  }
};
