/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import axios from 'axios';
import {
  defaultColors,
  restUsersMapColor,
  usersMapColor,
} from '../../utility_objects/ColorManager';
import { UserInterface, UsersObject } from '../../interfaces/User';

export const getUsersCalendar = async (): Promise<UserInterface[]> => {
  try {
    const {
      data: { data },
    } = await axios.get('/api/User/UICalendar');

    return data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const useUsersCalendar = ({
  options = {},
  key = '',
}: {
  options?: UseQueryOptions;
  key?: string;
}) => {
  return useQuery({
    queryFn: async () => await getUsersCalendar(),
    queryKey: ['calendar', 'users', key],
    retry: 2,
    retryDelay: 180000,
    cacheTime: 300000,
    refetchInterval: 300000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    select: normalize,
    ...options,
  });
};

const normalize = (data: any): UsersObject => {
  restUsersMapColor();
  const result: any = {};
  for (const user of data) {
    if (user?.color !== null && user.color !== undefined) {
      usersMapColor[user.id] = user.color;
      result[user.id] = {
        ...user,
        userId: user.id,
      };
    } else {
      usersMapColor[user.id] = defaultColors[data.indexOf(user)];
      result[user.id] = {
        ...user,
        userId: user.id,
        color: usersMapColor[user.id],
      };
    }
  }
  return result;
};
