import { configureStore } from '@reduxjs/toolkit';
import RootReducer from './root';
import {
  AppointmentsReducer,
  CreateLinkReducer,
  AppointmentFormReducer,
  CalendarsReducer,
  CalendarFiltersReducer,
} from '../pages/Calendars/store/CalendarStore';
import DefaultCalendarReducer from '../pages/Calendars/pages/Default/store';
import PersonalCalendarReducer from '../pages/Calendars/pages/Personal/store';
import StudioCalendarReducer from '../pages/Calendars/pages/Studio/store';
import UserCalendarReducer from '../pages/Calendars/pages/User/store';
import NotificationsReducer from '../pages/Notifications/store/Notifications';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { GeneralHoliday, GeneralRoot } from '../pages/General/store/General';
import { StudioForm, StudioRoot } from '../pages/Studi/store/Studi';

export const store = configureStore({
  reducer: {
    bluBookingRoot: RootReducer,
    bluBookingaAppointments: AppointmentsReducer,
    bluBookingCreateLink: CreateLinkReducer,
    bluBookingAppointmentForm: AppointmentFormReducer,
    bluBookingCalendars: CalendarsReducer,
    bluBookingCalendarFilters: CalendarFiltersReducer,
    bluBookingDefaultCalendar: DefaultCalendarReducer,
    bluBookingPersonalCalendar: PersonalCalendarReducer,
    bluBookingStudioCalendar: StudioCalendarReducer,
    bluBookingUserCalendar: UserCalendarReducer,
    bluBookingNotifications: NotificationsReducer,
    bluBookingGeneral: GeneralRoot,
    bluBookingHoliday: GeneralHoliday,
    bluBookingStudio: StudioRoot,
    bluBookingStudioForm: StudioForm,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
