/* eslint-disable @typescript-eslint/no-explicit-any */
import '../../styles/AppointmentForm.css';

import { memo, useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { getInitialData } from './utility_objects/InitialData';
import { BluDialog, BluLoader } from '@bludata/components';
import dayjs from 'dayjs';
import { AppointmentFormInner } from './AppointmentFormInner';
import { RootState, AppDispatch, store } from 'packages/blu-booking/src/store';
import {
  DefaultCalendarSelectPointOfSale,
  DefaultCalendarSetSelectedDate,
} from '../../pages/Default/store';
import {
  PersonalCalendarSetUser,
  PersonalCalendarSetSelectedDate,
} from '../../pages/Personal/store';
import {
  StudioCalendarSelectPointOfSale,
  StudioCalendarSetSelectedDate,
} from '../../pages/Studio/store';
import {
  UserCalendarSelectPointOfSale,
  UserCalendarSetSelectedDate,
} from '../../pages/User/store';
import { setRefetch } from '../../store/Appointment';
import {
  setEmailPopupForm,
  setEmailNotSetForm,
  setFormLoader,
  setOpenForm,
  setLocks,
} from '../../store/AppointmentForm';
import { setConfig } from '../../utility_objects/WhiteCache';
import { appointmentUpdate } from 'packages/blu-booking/src/data_fetching/Appointment/AppointmentUpdate';
import { appointmentCreate } from 'packages/blu-booking/src/data_fetching/Appointment/AppointmentCreate';
import { appointmentDelete } from 'packages/blu-booking/src/data_fetching/Appointment/AppointmentDelete';
import { EmailPopUpForm } from './UI/EmailPopup';
import { EmailNotSetPopup } from './UI/EmailNotSetPopup';
import { ErrorPopup } from './UI/ErrorPopup';
import { SearchPopUpForm } from './UI/SearchPopup';
import { Buttons } from './UI/Buttons';
import { schema } from './utility_objects/Schema';
import { useQueryClient } from '@tanstack/react-query';

interface AppointmentFormInterface {
  calendar?: string;
  openForm: any;
  setSelectedDate?: (value: Date) => void;
  selectedDate?: Date;
}

export const AppointmentForm = memo(
  ({
    calendar,
    openForm,
    setSelectedDate,
    selectedDate: sD,
  }: AppointmentFormInterface): JSX.Element => {
    const { pointOfSales, users } = useSelector(
      (state: RootState) => state.bluBookingCalendarFilters
    );
    const { formLoader, emailPopupForm, emailNotSetForm } = useSelector(
      (state: RootState) => state.bluBookingAppointmentForm
    );
    const dispatch = useDispatch<AppDispatch>();
    const initialValues = getInitialData(openForm);
    const queryClient = useQueryClient();

    const methods = useForm({
      mode: 'onChange',
      reValidateMode: 'onChange',
      defaultValues: initialValues,
      resolver: yupResolver(schema),
    });

    const { watch } = methods;

    const submit = useCallback(
      async (sendMail: boolean) => {
        dispatch(setEmailPopupForm(''));
        dispatch(setEmailNotSetForm(''));
        const data = watch();
        data.hasToSendEmail = sendMail;
        let result = '';
        dispatch(setFormLoader(true));
        if (openForm?.Id !== undefined) {
          const r = await appointmentUpdate(openForm.Id, {
            ...data,
            emailErrorCode: openForm?.emailErrorCode,
          });
          if (r) result = openForm.Id;
        } else {
          result = await appointmentCreate(data);
        }
        dispatch(setFormLoader(false));
        if (result !== '') {
          setConfig('underline', result);
          dispatch(setOpenForm(undefined));
          let filters;

          // ? Gestione tab client
          if (calendar === 'tab-clienti') {
            const sd = dayjs(sD);
            const tsd = dayjs(data.timeSlotDate);
            if (sd.isSame(tsd, 'month')) dispatch(setRefetch());
            else
              setSelectedDate(
                dayjs(data.timeSlotDate).startOf('month').toDate()
              );
            return;
          }

          switch (calendar) {
            case 'user':
              filters = store.getState().bluBookingUserCalendar;
              break;
            case 'personal':
              filters = store.getState().bluBookingPersonalCalendar;
              break;
            case 'studio':
              filters = store.getState().bluBookingStudioCalendar;
              break;
            default:
              filters = store.getState().bluBookingDefaultCalendar;
              break;
          }

          const selectedDate = filters.selectedDate;
          const isSamePointOfSale =
            filters.selectedPointOfSale?.id === data?.pointOfSaleId;
          const date = dayjs(selectedDate).format('YYYY/MM/DD');
          const appointmentDate = dayjs(data.timeSlotDate).format('YYYY/MM/DD');

          switch (calendar) {
            case 'user':
              if (!isSamePointOfSale && data?.pointOfSaleId in pointOfSales)
                dispatch(
                  UserCalendarSelectPointOfSale(
                    pointOfSales[data?.pointOfSaleId]
                  )
                );
              if (date === appointmentDate) {
                dispatch(setRefetch());
                return;
              }
              dispatch(UserCalendarSetSelectedDate(data.timeSlotDate));
              break;
            case 'personal':
              if (
                filters.selectedUsers[0]?.id !== data?.userId &&
                data?.userId in users
              )
                dispatch(PersonalCalendarSetUser([users[data?.userId]]));
              if (date === appointmentDate) {
                dispatch(setRefetch());
                return;
              }
              dispatch(PersonalCalendarSetSelectedDate(data.timeSlotDate));
              break;
            case 'studio':
              if (!isSamePointOfSale && data?.pointOfSaleId in pointOfSales)
                dispatch(
                  StudioCalendarSelectPointOfSale(
                    pointOfSales[data?.pointOfSaleId]
                  )
                );
              if (date === appointmentDate) {
                dispatch(setRefetch());
                return;
              }
              dispatch(StudioCalendarSetSelectedDate(data.timeSlotDate));
              break;
            default:
              if (!isSamePointOfSale && data?.pointOfSaleId in pointOfSales)
                dispatch(
                  DefaultCalendarSelectPointOfSale(
                    pointOfSales[data?.pointOfSaleId]
                  )
                );
              if (date === appointmentDate) {
                dispatch(setRefetch());
                return;
              }
              dispatch(DefaultCalendarSetSelectedDate(data.timeSlotDate));
              break;
          }

          queryClient.invalidateQueries(['appointment-notification'], {
            type: 'active',
          });
          queryClient.removeQueries(['appointment-notification'], {
            type: 'inactive',
          });
        }
      },
      [
        calendar,
        dispatch,
        openForm.Id,
        openForm?.emailErrorCode,
        pointOfSales,
        queryClient,
        sD,
        setSelectedDate,
        users,
        watch,
      ]
    );

    const deleteAction = useCallback(
      async (sendMail: boolean): Promise<any> => {
        dispatch(setEmailPopupForm(''));
        dispatch(setEmailNotSetForm(''));
        dispatch(setFormLoader(true));
        const result = await appointmentDelete(openForm?.Id, sendMail);
        dispatch(setFormLoader(false));
        if (result) {
          dispatch(setOpenForm(undefined));
          dispatch(setRefetch());
        }
      },
      [dispatch, openForm?.Id]
    );

    const isModify = useCallback(() => {
      return openForm?.Id === undefined;
    }, [openForm]);

    useEffect(() => {
      dispatch(setLocks());
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <FormProvider {...methods}>
        <BluDialog
          className="appointment-form"
          open={true}
          zIndex={300}
          disableEnforceFocus
          disableAutoFocus
          disableRestoreFocus
          draggable
          fullScreen={false}
          maxWidth="md"
          dialogTitle={
            isModify()
              ? 'Agenda: crea nuovo appuntamento'
              : 'Agenda: modifica appuntamento'
          }
          dialogContent={
            <form id="creationForm">
              <BluLoader open={formLoader} sx={{ zIndex: '1400' }} />

              <EmailPopUpForm
                open={emailPopupForm !== ''}
                type={emailPopupForm}
                action={(sendMail: boolean) =>
                  emailPopupForm === 'delete'
                    ? deleteAction(sendMail)
                    : submit(sendMail)
                }
                cancelAction={() => dispatch(setEmailPopupForm(''))}
              />
              <EmailNotSetPopup
                open={emailNotSetForm !== ''}
                type={emailNotSetForm}
                action={() =>
                  emailNotSetForm === 'delete'
                    ? deleteAction(false)
                    : submit(false)
                }
                cancelAction={() => dispatch(setEmailNotSetForm(''))}
              />
              <ErrorPopup />
              <SearchPopUpForm />
              <AppointmentFormInner
                isModify={isModify()}
                isTabClienti={calendar === 'tab-clienti'}
              />
            </form>
          }
          sx={{
            '& .MuiDialogContent-root': {
              pb: '0px !important',
            },
          }}
          dialogActions={
            <Buttons isModify={isModify()} initialValues={initialValues} />
          }
        />
      </FormProvider>
    );
  }
);
