import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { LicenseInfo } from '@mui/x-license-pro';
import { Provider } from 'react-redux';
import { store } from './store';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from './lib/react-query';
import dayjs from 'dayjs';
import { MUI_license } from '@bludata/components';

LicenseInfo.setLicenseKey(MUI_license);

dayjs.locale('it');

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </Provider>
);
