import {
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import { NotificationTypeEnum } from 'packages/blu-booking/src/interfaces/Notifications';
import { RootState } from 'packages/blu-booking/src/store';
import { useCallback } from 'react';
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPage, setNotificationTypeFilter } from '../store/Notifications';

export const NotificationTypeFilter = memo(() => {
  const { notificationTypeFilter } = useSelector(
    (state: RootState) => state.bluBookingNotifications
  );
  const dispatch = useDispatch();

  const handleMessageTypeFilterChange = useCallback(
    (event: SelectChangeEvent) => {
      dispatch(setPage(1));

      if (event.target.value === '5') {
        dispatch(setNotificationTypeFilter(undefined));
        return;
      }

      dispatch(
        setNotificationTypeFilter(
          parseInt(event.target.value) as NotificationTypeEnum
        )
      );
    },
    [dispatch]
  );

  const getValue = useCallback(() => {
    if (notificationTypeFilter !== undefined) {
      return notificationTypeFilter.toString();
    }
    return '5';
  }, [notificationTypeFilter]);

  return (
    <Stack>
      <Typography>Tipo di notifica</Typography>
      <Select
        value={getValue()}
        onChange={handleMessageTypeFilterChange}
        sx={{ width: 150 }}
      >
        <MenuItem value={NotificationTypeEnum['confirmation']}>
          Conferma
        </MenuItem>
        <MenuItem value={NotificationTypeEnum['modification']}>
          Modifica
        </MenuItem>
        <MenuItem value={NotificationTypeEnum['reminder']}>Reminder</MenuItem>
        <MenuItem value={NotificationTypeEnum['cancellation']}>
          Cancellazione
        </MenuItem>
        <MenuItem value={'5'}>Tutti</MenuItem>
      </Select>
    </Stack>
  );
});
