import { BluDialog, BluLoader, BluAsyncDialog } from '@bludata/components';
import { Stack, Button } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useAppointmentsPaginated } from 'packages/blu-booking/src/data_fetching/Appointment/AppointmentsPaginated';
import { AppointmentCompleteInterface } from 'packages/blu-booking/src/interfaces/AppointmentComplete';
import { FC, memo, useCallback, useMemo, useState } from 'react';
import { setConfig } from '../../utility_objects/WhiteCache';
import { PointOfSaleCheck } from './components/PointOfSale';
import { AppointmentsNotConfirmGrid } from './components/AppointmentsNotConfirmGrid';
import { EmailNotSetPopup } from './components/EmailNotSetPopup';
import { EmailPopUpForm } from './components/EmailPopup';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'packages/blu-booking/src/store';
import { appointmentUpdate } from 'packages/blu-booking/src/data_fetching/Appointment/AppointmentUpdate';
import { setAppointmentNotConfirmPopupOpen } from '../../store/Calendar';
import { setRefetch } from '../../store/Appointment';

interface AppointmentsNotConfirmPopupInnerInterface {
  handlePosition: (appointment: AppointmentCompleteInterface) => void;
}

export const AppointmentsNotConfirmPopupInner: FC<AppointmentsNotConfirmPopupInnerInterface> =
  memo(({ handlePosition }) => {
    const dispatch = useDispatch<AppDispatch>();

    const queryClient = useQueryClient();
    const { pointOfSales } = useSelector(
      (state: RootState) => state.bluBookingCalendarFilters
    );
    const [filters, setFilters] = useState<{
      pointOfSaleId?: string;
      pageNumber: number;
    }>({
      pageNumber: 1,
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedAppointment, setSelectedAppointment] = useState<
      AppointmentCompleteInterface | undefined
    >();
    const [appointment, setAppointment] = useState<
      AppointmentCompleteInterface | undefined
    >();
    const { data, isLoading } = useAppointmentsPaginated({
      ...filters,
      pointOfSaleIds: filters.pointOfSaleId,
      userIds: [],
      serviceIds: [],
      studioIds: [],
      status: 0,
      startDate: dayjs().format('YYYY-MM-DD'),
      pageSize: 10,
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleChangeFilters = useCallback((key: string, value: any) => {
      setFilters((prev) => ({ ...prev, [key]: value }));
    }, []);

    const handlePositioning = useCallback(() => {
      if (selectedAppointment !== undefined) {
        setConfig('underline', selectedAppointment?.id ?? '');
        handlePosition(selectedAppointment);
        dispatch(setAppointmentNotConfirmPopupOpen(false));
      }
    }, [handlePosition, selectedAppointment, dispatch]);

    const handleValidationChange = useCallback(
      async (id: string, sendMail: boolean) => {
        try {
          setLoading(true);
          const find = (data?.items ?? []).find((item) => item.id === id);
          const result = await appointmentUpdate(id, {
            serviceId: find.service.id,
            userId: find.user.id,
            pointOfSaleId: find.pointOfSale.id,
            studioId: find.studio ? find.studio?.id : undefined,
            email: find.email,
            telephone: find.telephone,
            name: find.name,
            surname: find.surname,
            description: find.description,
            timeSlotDate: find.startTime,
            start: find.startTime,
            end: find.endTime,
            internalDescription: find.internalDescription,
            status: 1,
            emailErrorCode: find.emailErrorCode,
            isImportant: find.isImportant,
            isIMO: find?.isIMO ?? false,
            externalCustomerCode: find.externalCustomerCode,
            hasToSendEmail: sendMail,
            isRecallOn: find?.isRecallOn ?? true,
            slotOkStatus: find?.slotOkStatus,
          });
          setLoading(false);
          if (result) {
            handleChangeFilters('pageNumber', 1);
            queryClient.invalidateQueries(['appointments-paginated'], {
              type: 'active',
            });
            queryClient.removeQueries(['appointments-paginated'], {
              type: 'inactive',
            });

            queryClient.invalidateQueries(['appointment-notification'], {
              type: 'active',
            });
            queryClient.removeQueries(['appointment-notification'], {
              type: 'inactive',
            });

            dispatch(setRefetch());

            return;
          }
          BluAsyncDialog({
            title: 'Attenzione',
            type: 'error',
            message: `<pre style='font-family: "Roboto"; white-space: pre-wrap; width: inherit;'>${"Si è verificato un errore durante la conferma dell'appuntamento."}</pre>`,
            hideDeclineButton: true,
            confimButton: 'Ok',

            // sx: { '& .MuiDialog-paper': { maxWidth: '500px' } },
          });
        } catch (error) {
          console.trace(error);
        }
      },
      [data?.items, handleChangeFilters, queryClient]
    );

    const GetConfirmPopup = useMemo(() => {
      let hasLayout = false;
      if (!appointment) return null;

      if (
        appointment?.pointOfSale &&
        appointment.pointOfSale?.id in pointOfSales
      ) {
        hasLayout =
          pointOfSales[appointment.pointOfSale?.id]?.shopSignId !== null;
      }
      if (!hasLayout) {
        return (
          <EmailPopUpForm
            open
            appointmentId={appointment.id}
            pointOfSaleId={appointment.pointOfSale?.id}
            action={(hasToSendEmail) => {
              handleValidationChange(appointment.id, hasToSendEmail);
              setAppointment(undefined);
            }}
            cancelAction={() => setAppointment(undefined)}
          />
        );
      }

      if (
        (appointment.email && appointment.email !== '') ||
        (appointment.telephone && appointment.telephone !== '') ||
        appointment.isIMO
      )
        return (
          <EmailPopUpForm
            open
            appointmentId={appointment.id}
            pointOfSaleId={appointment.pointOfSale?.id}
            action={(hasToSendEmail) => {
              handleValidationChange(appointment.id, hasToSendEmail);
              setAppointment(undefined);
            }}
            cancelAction={() => setAppointment(undefined)}
          />
        );
      else
        <EmailNotSetPopup
          open
          action={() => handleValidationChange(appointment.id, false)}
          cancelAction={() => setAppointment(undefined)}
        />;
    }, [appointment, handleValidationChange, pointOfSales]);

    return (
      <BluDialog
        open={true}
        zIndex={300}
        fullScreen={false}
        disableEnforceFocus
        disableAutoFocus
        disableRestoreFocus
        draggable
        maxWidth="lg"
        dialogTitle={'Appuntamenti da confermare'}
        dialogContent={
          <Stack gap={1}>
            {GetConfirmPopup}
            <BluLoader open={isLoading || loading} />
            <Stack direction="row" gap={1}>
              <PointOfSaleCheck
                pointOfSaleId={filters.pointOfSaleId}
                handleFilterChange={handleChangeFilters}
              />
            </Stack>
            <AppointmentsNotConfirmGrid
              appointments={data?.items ?? []}
              pageNumber={filters?.pageNumber}
              totalPages={data?.totalPages ?? 0}
              handlePageNumberChange={(value: number) => {
                handleChangeFilters('pageNumber', value);
              }}
              setSelectedAppointment={setSelectedAppointment}
              setAppointment={setAppointment}
            />
          </Stack>
        }
        sx={{
          '& .MuiDialogContent-root': {
            pb: '0px !important',
          },
          '& .MuiDialog-paper': { maxWidth: '1300px !important' },
        }}
        dialogActions={
          <Stack flex="1" direction="row" gap={1}>
            <Stack flex="1" direction="row" gap={1}></Stack>
            <Button
              variant="outlined"
              sx={{ width: 'fit-content' }}
              onClick={() => dispatch(setAppointmentNotConfirmPopupOpen(false))}
            >
              Chiudi
            </Button>
            <Button
              variant="contained"
              sx={{ width: 'fit-content' }}
              disabled={!selectedAppointment}
              onClick={handlePositioning}
            >
              Visualizza nell'agenda
            </Button>
          </Stack>
        }
      />
    );
  });
