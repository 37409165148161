export const it = {
  it: {
    schedule: {
      day: 'Giorno',
      week: 'Settimana',
      workWeek: 'Settimana lavorativa',
      month: 'Mese',
      agenda: 'Agenda',
      today: 'Oggi',
      noEvents: 'Nessun evento',
      emptyContainer: 'Nessun evento programmato per oggi.',
      allDay: 'Tutto il giorno',
      start: 'Inizio',
      end: 'Fine',
      more: 'Altro',
      close: 'Chiudi',
      cancel: 'Annulla',
      noTitle: '(Senza titolo)',
      delete: 'Elimina',
      deleteEvent: 'Elimina evento',
      deleteMultipleEvent: 'Elimina eventi multipli',
      selectedItems: 'Elementi selezionati',
      deleteSeries: 'Elimina serie',
      edit: 'Modifica',
      editSeries: 'Modifica serie',
      editEvent: 'Modifica evento',
      createEvent: 'Crea evento',
      subject: 'Oggetto',
      addTitle: 'Aggiungi titolo',
      moreDetails: 'Maggiori dettagli',
      save: 'Salva',
      editContent: "Vuoi modificare solo questo evento o l'intera serie?",
      deleteRecurrenceContent:
        "Vuoi eliminare solo questo evento o l'intera serie?",
      deleteContent: 'Sei sicuro di voler eliminare questo evento?',
      newEvent: 'Nuovo evento',
      title: 'Titolo',
      location: 'Località',
      description: 'Descrizione',
      timezone: 'Fuso orario',
      startTimezone: 'Fuso orario di inizio',
      endTimezone: 'Fuso orario di fine',
      repeat: 'Ripeti',
      saveButton: 'Salva',
      cancelButton: 'Annulla',
      deleteButton: 'Elimina',
      recurrence: 'Ricorrenza',
      wrongPattern: 'Il pattern di ricorrenza non è valido.',
      seriesChangeAlert: 'Vuoi cancellare questi eventi della serie?',
      createError:
        "La durata dell'evento deve essere minore della frequenza con cui si verifica. Riduci la durata o modifica il pattern di ricorrenza nella finestra di dialogo Evento ricorrente.",
      sameDayAlert:
        'Due occorrenze dello stesso evento non possono avvenire nello stesso giorno.',
      editRecurrence: 'Modifica ricorrenza',
      repeats: 'Ripetizioni',
      alert: 'Avviso',
      startEndError:
        'La data di fine selezionata avviene prima della data di inizio.',
      invalidDateError: 'Il valore della data inserita non è valido.',
      ok: 'OK',
      occurrence: 'Occorrenza',
      series: 'Serie',
      previous: 'Precedente',
      next: 'Successivo',
      timelineDay: 'Giorno della timeline',
      timelineWeek: 'Settimana della timeline',
      timelineWorkWeek: 'Settimana lavorativa della timeline',
      timelineMonth: 'Mese della timeline',
    },
    recurrenceeditor: {
      none: 'Nessuna',
      daily: 'Quotidiana',
      weekly: 'Settimanale',
      monthly: 'Mensile',
      month: 'Mese',
      yearly: 'Annuale',
      never: 'Mai',
      until: 'Fino a',
      count: 'Contare',
      first: 'Primo',
      second: 'Secondo',
      third: 'Terzo',
      fourth: 'Quarto',
      last: 'Ultimo',
      repeat: 'Frequenza',
      repeatEvery: 'Ripeti ogni',
      on: 'Ripeti il',
      end: 'Fine',
      onDay: 'Il giorno',
      days: 'Giorni',
      weeks: 'Settimane',
      months: 'Mesi',
      years: 'Anni',
      every: 'ogni',
      summaryTimes: 'volte',
      summaryOn: 'il',
      summaryUntil: 'fino a',
      summaryRepeat: 'ripetizioni',
      summaryDay: 'giorni',
      summaryWeek: 'settimane',
      summaryMonth: 'mesi',
      summaryYear: 'anni',
      monthWeek: 'Mese Settimana',
      monthPosition: 'Posizione mensile',
      monthExpander: 'Espansore del mese',
      yearExpander: 'Expander Anno',
      repeatInterval: 'Ripeti intervallo',
    },
    calendar: {
      today: 'Oggi',
    },
    datepicker: {
      today: 'Oggi',
      placeholder: 'Scegli una data',
    },
    daterangepicker: {
      placeholder: 'Scegli un intervallo di date',
      startLabel: "Data d'inizio",
      endLabel: 'Data di fine',
      applyText: 'Applicare',
      cancelText: 'Annulla',
      selectedDays: 'Giorni selezionati',
      days: 'giorni',
      customRange: 'Gamma personalizzata',
    },
    timepicker: {
      placeholder: 'Scegli un orario',
    },
    datetimepicker: {
      today: 'Oggi',
      placeholder: 'Scegli una data e ora',
    },
    richtexteditor: {
      alignments: 'allineamenti',
      justifyLeft: 'Allineare a sinistra',
      justifyCenter: 'Allinea al centro',
      justifyRight: 'Allinea a destra',
      justifyFull: 'Allinea giustificato',
      fontName: 'Nome carattere',
      fontSize: 'Dimensione del font',
      fontColor: 'Colore del carattere',
      backgroundColor: 'Colore di sfondo',
      bold: 'Grassetto',
      italic: 'Corsivo',
      underline: 'Sottolineare',
      strikethrough: 'Barrato',
      clearFormat: 'Cancella formato',
      clearAll: 'Cancella tutto',
      cut: 'Tagliare',
      copy: 'copia',
      paste: 'Incolla',
      unorderedList: 'Elenco puntato',
      orderedList: 'Elenco numerato',
      indent: 'Aumenta rientro',
      outdent: 'Riduci rientro',
      undo: 'Disfare',
      redo: 'Rifare',
      superscript: 'indice',
      subscript: 'deponente',
      createLink: 'Inserisci collegamento ipertestuale',
      openLink: 'Link aperto',
      editLink: 'Modifica collegamento',
      removeLink: 'Rimuovi collegamento',
      image: 'Inserisci immagine',
      replace: 'Sostituire',
      align: 'Allineare',
      caption: 'Didascalia immagine',
      remove: 'Rimuovere',
      insertLink: 'Inserisci collegamento',
      display: 'Schermo',
      altText: 'Testo alternativo',
      dimension: 'Cambia dimensione',
      fullscreen: 'aumentare al massimo',
      maximize: 'aumentare al massimo',
      minimize: 'Minimizzare',
      lowerCase: 'Minuscole',
      upperCase: 'Maiuscolo',
      print: 'Stampa',
      formats: 'formati',
      sourcecode: 'Vista codice',
      preview: 'Anteprima',
      viewside: 'ViewSide',
      insertCode: 'Inserire codice',
      linkText: 'Visualizza testo',
      linkTooltipLabel: 'Titolo',
      linkWebUrl: 'Indirizzo Web',
      linkTitle: 'Inserisci un titolo',
      linkurl: 'http://example.com',
      linkOpenInNewWindow: 'Apri il collegamento in una nuova finestra',
      linkHeader: 'Inserisci collegamento',
      dialogInsert: 'Inserire',
      dialogCancel: 'Annulla',
      dialogUpdate: 'Aggiornare',
      imageHeader: 'Inserisci immagine',
      imageLinkHeader: 'Puoi anche fornire un link dal web',
      mdimageLink: 'Fornisci un URL per la tua immagine',
      imageUploadMessage: "Rilascia l'immagine qui o sfoglia per caricarla",
      imageDeviceUploadMessage: 'Clicca qui per caricare',
      imageAlternateText: 'Testo alternato',
      alternateHeader: 'Testo alternativo',
      browse: 'Navigare',
      imageUrl: 'http://example.com/image.png',
      imageCaption: 'Didascalia',
      imageSizeHeader: "Dimensione dell'immagine",
      imageHeight: 'Altezza',
      imageWidth: 'Larghezza',
      textPlaceholder: 'Inserire il testo',
      inserttablebtn: 'Inserisci tabella',
      tabledialogHeader: 'Inserisci tabella',
      tableWidth: 'Larghezza',
      cellpadding: 'Imbottitura cellulare',
      cellspacing: 'Spaziatura cellulare',
      columns: 'Numero di colonne',
      rows: 'Numero di righe',
      tableRows: 'Righe della tabella',
      tableColumns: 'Colonne della tabella',
      tableCellHorizontalAlign: 'Allinea orizzontale cella cella',
      tableCellVerticalAlign: 'Allinea verticale cella cella',
      createTable: 'Crea tabella',
      removeTable: 'Rimuovi tabella',
      tableHeader: 'Intestazione della tabella',
      tableRemove: 'Tabella Rimuovi',
      tableCellBackground: 'Sfondo delle celle della tabella',
      tableEditProperties: 'Proprietà di modifica tabella',
      styles: 'stili',
      insertColumnLeft: 'Inserisci colonna a sinistra',
      insertColumnRight: 'Inserisci colonna a destra',
      deleteColumn: 'Elimina colonna',
      insertRowBefore: 'Inserisci riga prima',
      insertRowAfter: 'Inserisci riga dopo',
      deleteRow: 'Elimina riga',
      tableEditHeader: 'Modifica tabella',
      TableHeadingText: 'Intestazione',
      TableColText: 'Col',
      imageInsertLinkHeader: 'Inserisci collegamento',
      editImageHeader: 'Modifica immagine',
      alignmentsDropDownLeft: 'Allineare a sinistra',
      alignmentsDropDownCenter: 'Allinea al centro',
      alignmentsDropDownRight: 'Allinea a destra',
      alignmentsDropDownJustify: 'Allinea giustificato',
      imageDisplayDropDownInline: 'In linea',
      imageDisplayDropDownBreak: 'Rompere',
      tableInsertRowDropDownBefore: 'Inserisci la riga prima',
      tableInsertRowDropDownAfter: 'Inserisci riga dopo',
      tableInsertRowDropDownDelete: 'Elimina riga',
      tableInsertColumnDropDownLeft: 'Inserisci colonna a sinistra',
      tableInsertColumnDropDownRight: 'Inserisci colonna a destra',
      tableInsertColumnDropDownDelete: 'Elimina colonna',
      tableVerticalAlignDropDownTop: 'Allinea in alto',
      tableVerticalAlignDropDownMiddle: 'Allinea al centro',
      tableVerticalAlignDropDownBottom: 'Allinea in basso',
      tableStylesDropDownDashedBorder: 'Bordi tratteggiati',
      tableStylesDropDownAlternateRows: 'Righe alternative',
      pasteFormat: 'Incolla formato',
      pasteFormatContent: "Scegli l'azione di formattazione",
      plainText: 'Testo semplice',
      cleanFormat: 'Pulito',
      keepFormat: 'Mantenere',
      pasteDialogOk: 'ok',
      pasteDialogCancel: 'Annulla',
      fileManager: 'File Manager',
      fileDialogHeader: 'Browser di file',
      formatsDropDownParagraph: 'Paragrafo',
      formatsDropDownCode: 'Codice',
      formatsDropDownQuotation: 'Quotazione',
      formatsDropDownHeading1: 'Intestazione 1',
      formatsDropDownHeading2: 'Intestazione 2',
      formatsDropDownHeading3: 'Intestazione 3',
      formatsDropDownHeading4: 'Intestazione 4',
      fontNameSegoeUI: 'SegoeUI',
      fontNameArial: 'Arial',
      fontNameGeorgia: 'Georgia',
      fontNameImpact: 'Impatto',
      fontNameTahoma: 'Tahoma',
      fontNameTimesNewRoman: 'Times New Roman',
      fontNameVerdana: 'Verdana',
      formatsOLListNumber: 'Numero',
      formatsOLListLowerAlpha: 'Alfa inferiore',
      formatsOLListUpperAlpha: 'UpperAlpha',
      formatsOLListLowerRoman: 'LowerRoman',
      formatsOLListUpperRoman: 'UpperRoman',
      formatsOLListLowerGreek: 'greco inferiore',
      formatsULListDisc: 'Disco',
      formatsULListCircle: 'Cerchio',
      formatsULListSquare: 'Piazza',
      formatsOLListNone: 'Nessuna',
      formatsULListNone: 'Nessuna',
      formatPainter: 'Formato pittore',
    },
    PdfViewer: {
      PdfViewer: 'Visualizzatore PDF',
      Cancel: 'Annulla',
      'Download file': 'Scarica file',
      Download: 'Scarica',
      'Enter Password':
        'Questo documento è protetto da password. Si prega di inserire la password.',
      'File Corrupted': 'File Corrotto',
      'File Corrupted Content': 'Il file è corrotto e non può essere aperto.',
      'Fit Page': 'Adatta Pagina',
      'Fit Width': 'Adatta Larghezza',
      Automatic: 'Automatico',
      'Go To First Page': 'Vai alla Prima Pagina',
      'Invalid Password': 'Password Errata. Riprova.',
      'Next Page': 'Pagina Successiva',
      OK: 'OK',
      Open: 'Apri File',
      'Page Number': 'Numero di Pagina Corrente',
      'Previous Page': 'Pagina Precedente',
      'Go To Last Page': "Vai all'Ultima Pagina",
      Zoom: 'Zoom',
      'Zoom In': 'Ingrandisci',
      'Zoom Out': 'Riduci',
      'Page Thumbnails': 'Miniature delle Pagine',
      Bookmarks: 'Segnalibri',
      Print: 'Stampa File',
      'Password Protected': 'Password Richiesta',
      Copy: 'Copia',
      'Text Selection': 'Strumento di Selezione Testo',
      Panning: 'Modalità Panoramica',
      'Text Search': 'Cerca Testo',
      'Find in document': 'Cerca nel Documento',
      'Match case': 'Maiuscole/Minuscole',
      Apply: 'Applica',
      GoToPage: 'Vai alla Pagina',
      'No matches':
        'Il visualizzatore ha terminato la ricerca nel documento. Nessun altro risultato trovato.',
      'No Text Found': 'Testo non Trovato',
      Undo: 'Annulla',
      Redo: 'Ripeti',
      Annotation: 'Aggiungi o Modifica Annotazioni',
      Highlight: 'Evidenzia Testo',
      Underline: 'Sottolinea Testo',
      Strikethrough: 'Testo Barrato',
      Delete: 'Elimina Annotazione',
      Opacity: 'Opacità',
      'Color edit': 'Modifica Colore',
      'Opacity edit': 'Modifica Opacità',
      highlight: 'Evidenzia',
      underline: 'Sottolinea',
      strikethrough: 'Barrato',
      'Server error':
        'Il servizio internet non risponde. Il visualizzatore PDF dipende dal servizio web per tutte le sue funzionalità. Si prega di avviare il servizio web per continuare.',
      'Open text': 'Apri',
      'First text': 'Prima Pagina',
      'Previous text': 'Pagina Precedente',
      'Next text': 'Pagina Successiva',
      'Last text': 'Ultima Pagina',
      'Zoom in text': 'Ingrandisci',
      'Zoom out text': 'Riduci',
      'Selection text': 'Seleziona',
      'Pan text': 'Panoramica',
      'Print text': 'Stampa',
      'Seach text': 'Cerca',
      'Annotation Edit text': 'Modifica Annotazione',
      'Line Thickness': 'Spessore Linea',
      'Line Properties': 'Proprietà della Linea',
      'Start Arrow': 'Inizio Freccia',
      'End Arrow': 'Fine Freccia',
      'Line Style': 'Stile Linea',
      'Fill Color': 'Colore di Riempimento',
      'Line Color': 'Colore della Linea',
      None: 'Nessuno',
      'Open Arrow': 'Aperto',
      'Closed Arrow': 'Chiuso',
      'Round Arrow': 'Arrotondato',
      'Square Arrow': 'Quadrato',
      'Diamond Arrow': 'Diamante',
      Cut: 'Taglia',
      Paste: 'Incolla',
      'Delete Context': 'Elimina',
      Properties: 'Proprietà',
      'Add Stamp': 'Aggiungi Timbro',
      'Add Shapes': 'Aggiungi Forme',
      'Stroke edit': 'Modifica Colore Contorno',
      'Change thickness': 'Cambia Spessore Bordo',
      'Add line': 'Aggiungi Linea',
      'Add arrow': 'Aggiungi Freccia',
      'Add rectangle': 'Aggiungi Rettangolo',
      'Add circle': 'Aggiungi Cerchio',
      'Add polygon': 'Aggiungi Poligono',
      'Add Comments': 'Aggiungi Commenti',
      Comments: 'Commenti',
      'No Comments Yet': 'Nessun Commento Ancora',
      Accepted: 'Accettato',
      Completed: 'Completato',
      Cancelled: 'Annullato',
      Rejected: 'Rifiutato',
      'Leader Length': 'Lunghezza del Leader',
      'Scale Ratio': 'Rapporto di Scala',
      Calibrate: 'Calibra',
      'Calibrate Distance': 'Calibra Distanza',
      'Calibrate Perimeter': 'Calibra Perimetro',
      'Calibrate Area': 'Calibra Area',
      'Calibrate Radius': 'Calibra Raggio',
      'Calibrate Volume': 'Calibra Volume',
      Depth: 'Profondità',
      Closed: 'Chiuso',
      Round: 'Arrotondato',
      Square: 'Quadrato',
      Diamond: 'Diamante',
      Edit: 'Modifica',
      Comment: 'Commento',
      'Comment Panel': 'Pannello dei Commenti',
      'Set Status': 'Imposta Stato',
      Post: 'Posta',
      Page: 'Pagina',
      'Add a comment': 'Aggiungi un Commento',
      'Add a reply': 'Aggiungi una Risposta',
      'Import Annotations': 'Importa Annotazioni',
      'Export Annotations': 'Esporta Annotazioni',
      Add: 'Aggiungi',
      Clear: 'Pulisci',
      Bold: 'Grassetto',
      Italic: 'Corsivo',
      Strikethroughs: 'Barrato',
      Underlines: 'Sottolineato',
      Superscript: 'Apice',
      Subscript: 'Pedice',
      'Align left': 'Allinea a Sinistra',
      'Align right': 'Allinea a Destra',
      Center: 'Centro',
      Justify: 'Giustifica',
      'Font color': 'Colore del Testo',
      'Text Align': 'Allineamento del Testo',
      'Text Properties': 'Proprietà del Testo',
      'Draw Signature': 'Disegna Firma',
      Create: 'Crea',
      'Font family': 'Famiglia di Font',
      'Font size': 'Dimensione del Font',
      'Free Text': 'Testo Libero',
      'Import Failed':
        'Tipo di file non valido o nome del file non valido; si prega di selezionare un file valido',
      'File not found': 'File non trovato nel percorso specificato',
      'Export Failed':
        "Errore durante l'esportazione delle annotazioni; si prega di assicurarsi che le annotazioni siano state aggiunte correttamente",
      Dynamic: 'Dinamico',
      'Standard Business': 'Business Standard',
      'Sign Here': 'Firma Qui',
      'Custom Stamp': 'Timbro Personalizzato',
      InitialFieldDialogHeaderText: 'Aggiungi Iniziali',
      HandwrittenInitialDialogHeaderText: 'Aggiungi Iniziali',
      SignatureFieldDialogHeaderText: 'Aggiungi Firma',
      HandwrittenSignatureDialogHeaderText: 'Aggiungi Firma',
      'Draw-hand Signature': 'Disegna',
      'Type Signature': 'Digita',
      'Upload Signature': 'Carica',
      'Browse Signature Image': 'Sfoglia',
      'Save Signature': 'Salva Firma',
      'Save Initial': 'Salva Iniziali',
      'Highlight context': 'Evidenzia',
      'Underline context': 'Sottolinea',
      'Strikethrough context': 'Barrato',
      FormDesigner: 'Aggiungi e Modifica Campo Modulo',
      SubmitForm: 'Invia Modulo',
      'Search text': 'Cerca',
      'Draw Ink': 'Disegna con Inchiostro',
      Revised: 'Rivisto',
      Reviewed: 'Recensito',
      Received: 'Ricevuto',
      Confidential: 'Riservato',
      Approved: 'Approvato',
      'Not Approved': 'Non Approvato',
      Witness: 'Testimone',
      'Initial Here': 'Iniziali Qui',
      Draft: 'Bozza',
      Final: 'Finale',
      'For Public Release': 'Per il Rilascio Pubblico',
      'Not For Public Release': 'Non per il Rilascio Pubblico',
      'For Comment': 'Per Commento',
      Void: 'Vuoto',
      'Preliminary Results': 'Risultati Preliminari',
      'Information Only': 'Solo Informazioni',
      'Enter Signature as Name': 'Inserisci il Tuo Nome',
      Textbox: 'Casella di Testo',
      Password: 'Password',
      'Check Box': 'Casella di Selezione',
      'Radio Button': 'Pulsante di Opzione',
      Dropdown: 'Menu a Tendina',
      'List Box': 'Casella di Lista',
      Signature: 'Firma',
      'Delete FormField': 'Elimina Campo Modulo',
      'FormDesigner Edit text': 'Aggiungi e Modifica Campo Modulo',
      in: 'in',
      m: 'm',
      ft_in: 'ft',
      ft: 'ft',
      p: 'pt',
      cm: 'cm',
      mm: 'mm',
      pt: 'pt',
      cu: 'cu',
      sq: 'sq',
      General: 'Generale',
      Appearance: 'Aspetto',
      Options: 'Opzioni',
      'Textbox Properties': 'Proprietà Casella di Testo',
      Name: 'Nome',
      Tooltip: 'Descrizione Comandi',
      Value: 'Valore',
      'Form Field Visibility': 'Visibilità Campo Modulo',
      'Read Only': 'Sola Lettura',
      Required: 'Obbligatorio',
      Checked: 'Selezionato',
      'Show Printing': 'Mostra Stampa',
      Formatting: 'Formattazione',
      Fill: 'Riempimento',
      Border: 'Bordo',
      'Border Color': 'Colore del Bordo',
      Thickness: 'Spessore',
      'Max Length': 'Lunghezza Massima',
      'List Item': 'Elemento della Lista',
      'Export Value': 'Valore da Esportare',
      'Dropdown Item List': 'Lista degli Elementi a Tendina',
      'List Box Item List': 'Lista degli Elementi della Casella',
      'Delete Item': 'Elimina',
      Up: 'Su',
      Down: 'Giù',
      Multiline: 'Multilinea',
      Initial: 'Iniziale',
      'Export XFDF': 'Esporta Annotazione in File XFDF',
      'Import XFDF': 'Importa Annotazioni da File XFDF',
    },
  },
};
