/* eslint-disable @typescript-eslint/no-explicit-any */
export const getCookie = (cName: string) => {
  const name = cName + '=';
  const cDecoded = decodeURIComponent(document.cookie); //to be careful
  const cookieList = cDecoded.split('; ');
  const result = cookieList.find((cookie: any) => cookie.indexOf(name) !== -1);
  if (result) return result.substring(name.length);
  return result;
};

export const setCookie = (
  cName: string,
  cValue: any,
  expirationTime: number | undefined = 12
) => {
  const d = new Date();
  d.setTime(d.getTime() + expirationTime * 60 * 60 * 1000); // in ore
  const expires = 'expires=' + d.toUTCString();
  document.cookie = `${cName}=${cValue};${expires};path=/;`;
};

export const eraseCookie = (cName: string) => {
  document.cookie = `${cName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
};
