import { FC, memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { BluContainerBlock, BluTitle } from '@bludata/components';
import { AppDispatch, RootState } from '../../store';
import { PointOfSaleObject } from './interfaces/point-of-sale-object';
import { UserObject } from './interfaces/user-object';
import { setServiceWhiteCache } from './lib/white-cache';
import { Search } from './components/Search';
import { setFormValues } from './store/form';
import { ServicesGrid } from './components/Grid';
import { ServicePopup } from './components/Popup';
import { Header } from '../../UI/Header';
import AddIcon from '@mui/icons-material/Add';
import ModeIcon from '@mui/icons-material/Mode';
import { setOpenForm, setCurrentService } from './store/root';
import WebAssetIcon from '@mui/icons-material/WebAsset';

interface ServicePageInterface {
  root?: string;
}

export const ServicePage: FC<ServicePageInterface> = memo(({ root }) => {
  const { serviceLoad, users, pointOfSales, studi, currentService } =
    useSelector((state: RootState) => state.serviceRoot);
  const dispatch = useDispatch<AppDispatch>();
  const [search, setSearch] = useState<string>('');
  const [gridSearch, setGridSearch] = useState<string>('');

  useEffect(() => {
    if (serviceLoad) return;
    const initialPointOfSales: { [x: string]: boolean } = {};
    const pointOfSalesForm: PointOfSaleObject = {};
    const initialStudi: { [x: string]: boolean } = {};
    const initialUsers: { [x: string]: boolean } = {};
    const usersForm: UserObject = {};

    for (const pointOfSale of Object.values(pointOfSales)) {
      initialPointOfSales[pointOfSale.id] = false;
      pointOfSalesForm[pointOfSale.id] = {
        enable: false,
        id: pointOfSale.id,
        name: pointOfSale.branch,
        address: pointOfSale.address,
        city: pointOfSale.city,
        studi: {},
      };
    }
    for (const studio of Object.values(studi)) {
      initialStudi[studio.id] = false;
      pointOfSalesForm[studio.pointOfSaleId].studi = {
        ...pointOfSalesForm[studio.pointOfSaleId].studi,
        [studio.id]: {
          enable: false,
          id: studio.id,
          name: studio.name,
        },
      };
    }

    for (const user of Object.values(users)) {
      initialUsers[user.id] = false;
      usersForm[user.id] = {
        enable: false,
        id: user.id,
        username: user.username,
        name: user.name,
        surname: user.surname,
        email: user.email,
      };
    }

    setServiceWhiteCache('pointOfSalesForm', pointOfSalesForm);
    setServiceWhiteCache('usersForm', usersForm);
    setServiceWhiteCache('initialPointOfSales', initialPointOfSales);
    setServiceWhiteCache('initialStudi', initialStudi);
    setServiceWhiteCache('initialUsers', initialUsers);
    setServiceWhiteCache('defaultPointOfSales', initialPointOfSales);
    setServiceWhiteCache('defaultStudi', initialStudi);
    setServiceWhiteCache('defaultUsers', initialUsers);
    dispatch(setFormValues({ pointOfSalesForm, usersForm }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceLoad]);

  useEffect(() => {
    const delayFn = setTimeout(() => {
      if (search !== gridSearch) setGridSearch(search);
    }, 300);

    return () => {
      clearTimeout(delayFn);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const handleOnChange = useCallback((event: any) => {
    setSearch(event.target.value);
  }, []);

  return (
    <>
      <Header title={`${root ?? ''}Servizi`} />
      <BluContainerBlock
        sx={{
          m: '4px',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          flex: '1',
        }}
      >
        <>
          <ServicePopup />

          <BluTitle text={'Lista servizi'} />

          <Stack spacing={1}>
            <Typography color="text.primary" sx={{ paddingTop: '5px' }}>
              In questa sezione è possibile configurare l'elenco dei servizi
            </Typography>
            <div>
              <Typography component="span" color="text.primary">
                Premi il tasto&nbsp;
              </Typography>
              <Typography
                component="span"
                color="text.primary"
                sx={{ fontWeight: 'bold' }}
              >
                Scheda&nbsp;
              </Typography>
              <Typography component="span" color="text.primary">
                per visualizzare le impostazioni di un servizio e&nbsp;
              </Typography>
              <Typography
                component="span"
                color="text.primary"
                sx={{ fontWeight: 'bold' }}
              >
                Modifica&nbsp;
              </Typography>
              <Typography component="span" color="text.primary">
                per modificarne uno di esistente.
              </Typography>
            </div>
          </Stack>
          <Divider sx={{ m: '7px 0 7px 0' }} />

          <Search search={search} handleOnChange={handleOnChange} />
          <ServicesGrid search={gridSearch} />
          <Stack direction="row" sx={{ mt: '8px' }}>
            <Box flex={1}></Box>

            <Stack direction="row" spacing={0.5}>
              <Button
                color="primary"
                startIcon={<AddIcon />}
                variant="contained"
                onClick={() => {
                  dispatch(setCurrentService(''));
                  dispatch(setOpenForm('new'));
                }}
              >
                Nuovo
              </Button>
              <Button
                color="primary"
                startIcon={<ModeIcon />}
                variant="outlined"
                disabled={currentService === ''}
                onClick={() => dispatch(setOpenForm('modify'))}
              >
                Modifica
              </Button>
              <Button
                color="primary"
                startIcon={<WebAssetIcon />}
                variant="outlined"
                disabled={currentService === ''}
                onClick={() => dispatch(setOpenForm('info'))}
              >
                Scheda
              </Button>
            </Stack>
          </Stack>
        </>
      </BluContainerBlock>
    </>
  );
});
