import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import React, { memo, useMemo } from 'react';
import { AutoCompleteInterface } from './interface';

const BluAutoComplete = memo(function BluAutoComplete({
  value,
  data = [],
  loading = false,
  multiple = false,
  onChange,
  themeMode = 'light',
  textField,
  ...rest
}: AutoCompleteInterface) {
  const Loading = useMemo(
    () =>
      loading && (
        <CircularProgress
          style={{
            width: '20px',
            height: 'auto',
          }}
        />
      ),
    [loading]
  );

  return (
    <Autocomplete
      multiple={multiple}
      value={value}
      options={data}
      onChange={onChange}
      limitTags={5}
      autoHighlight
      fullWidth
      size="small"
      {...rest}
      sx={{
        mt: '0px !important',
        ...rest.sx,
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            fullWidth
            {...textField}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
              ...textField?.inputProps,
            }}
            sx={{
              '& input': {
                pl: '8px !important',
              },
              // '& ::placeholder': {
              //   color: '#9a9a9a !important',
              //   opacity: '1 !important',
              //   fontWeight: '700 !important',
              //   textDecoration: 'none !important',
              //   fontStyle: 'normal !important',
              // },
              ...textField?.sx,
            }}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  {params.InputProps.startAdornment}
                  {textField?.InputProps?.startAdornment}
                  {Loading}
                </>
              ),
              endAdornment: (
                <>
                  {params.InputProps.endAdornment}
                  {textField?.InputProps?.endAdornment}
                </>
              ),
            }}
          />
        );
      }}
    />
  );
});

export default BluAutoComplete;
