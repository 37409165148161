import { FC, memo } from 'react';
import { Provider } from 'react-redux';
import { store } from '../../store';
import { Stack } from '@mui/material';
import { Header } from '../../UI/Header';
import { GeneralInner } from './GeneralInner';

interface GeneralInterface {
  software: string;
  root?: string;
  refreshToken?: () => void;
}

export const General: FC<GeneralInterface> = memo(
  ({ software, root = '', refreshToken = () => null }): JSX.Element => {
    return (
      <Provider store={store}>
        <Stack
          direction="row"
          justifyContent="start"
          alignItems="start"
          flex={1}
          sx={{
            backgroundColor: 'var(--hovered-background-color)',
          }}
        >
          <Stack flexDirection="column" flex={1} sx={{ height: '100%' }}>
            <Header title={`${root}Generale`} />
            <GeneralInner software={software} refreshToken={refreshToken} />
          </Stack>
        </Stack>
      </Provider>
    );
  }
);
