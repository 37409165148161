import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';
import { LoginDataInterface } from '../interfaces/LoginData';

interface MainState {
  openLoader: boolean;
  loginData?: LoginDataInterface;
}

const initialState: MainState = {
  openLoader: false,
};

const RootSlice = createSlice({
  name: 'BluBookingRoot',
  initialState,
  reducers: {
    setLoginData(
      state: MainState,
      action: PayloadAction<LoginDataInterface | undefined>
    ) {
      state.loginData = action.payload;
    },
    setOpenLoader(state: MainState, action: PayloadAction<boolean>) {
      state.openLoader = action.payload;
    },
  },
});

export const { setLoginData, setOpenLoader } = RootSlice.actions;

export const bluBookingLoginData = (state: RootState): LoginDataInterface =>
  state.bluBookingRoot.loginData ?? {
    pointOfSaleId: '',
    userId: '',
    isAdmin: false,
    webSocketChannel: '',
    photo: '',
    username: '',
    software: 'bb',
    isDemo: true,
    companyColorEnum: 0,
    isManualAppointmentBlock: false,
    isSingleCalendarManagement: true,
    visibilityUserEnum: 0,
  };

export default RootSlice.reducer;
