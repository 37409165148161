import { FC, memo, useState } from 'react';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { BluAlert } from '@bludata/components';
import { IconButton } from '@mui/material';
import Markdown from 'react-markdown';

interface CompanySettingsTooltipInterface {
  text: string;
  maxWidth?: 'sm' | 'md' | 'lg';
}

export const CompanySettingsTooltip: FC<CompanySettingsTooltipInterface> = memo(
  ({ text, maxWidth }) => {
    const [open, setOpen] = useState<boolean>(false);
    return (
      <>
        <BluAlert
          show={open}
          maxWidth={maxWidth}
          text={<Markdown>{text}</Markdown>}
          handleClose={() => setOpen(false)}
          type="info"
          title="Informazioni"
        />
        <IconButton
          size="small"
          onClick={() => {
            setOpen(true);
          }}
          sx={{
            width: '20px',
            height: '20px',
            '& svg': { width: '15px!important', height: '15px!important' },
          }}
        >
          <QuestionMarkIcon />
        </IconButton>
      </>
    );
  }
);
