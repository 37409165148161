import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import 'dayjs/locale/it';
import { DefaultSelectedFiltersState } from './interface';
import {
  getCookie,
  setCookie,
} from 'packages/blu-booking/src/utility_objects/CookieManager';
import {
  removePointOfSale,
  removeService,
  removeStudio,
  selectPointOfSale,
  selectService,
  selectStudio,
  selectZoomValue,
  setSelectedDate,
  setClient,
  setUser,
} from '../../../utility_objects/StoreFunctions';

const getCurrentView = (view?: string) => {
  if (view) {
    if (view === '3Days') return 'Day';
    else if (view === 'WorkWeek') return 'Week';
    return view;
  }
  const storedView = getCookie('defaultCalendarView') || 'Week';
  if (storedView === '3Days') return 'Day';
  else if (storedView === 'WorkWeek') return 'Week';
  return storedView;
};

const initialState: DefaultSelectedFiltersState = {
  view: getCookie('defaultCalendarView') || 'Week',
  currentView: getCurrentView(),
  selectedDate: new Date(),
  selectedServices: [],
  selectedStudi: [],
  selectedUsers: [],
  zoom: {
    value: 150,
    interval: 60,
    slotCount: 4,
  },
  zoomValue: 150,
};

const DefaultCalendarSlice = createSlice({
  name: 'BluBookingDefaultCalendar',
  initialState,
  reducers: {
    DefaultCalendarSetView(
      state: DefaultSelectedFiltersState,
      action: PayloadAction<string>
    ) {
      state.currentView = getCurrentView(action.payload);
      state.view = action.payload;
      setCookie('defaultCalendarView', action.payload);
    },
    DefaultCalendarSetSelectedDate: setSelectedDate,
    DefaultCalendarSelectZoomValue: selectZoomValue,
    // ? ADD
    DefaultCalendarSelectPointOfSale: selectPointOfSale,
    DefaultCalendarSetService: selectService,
    DefaultCalendarSelectStudio: selectStudio,
    DefaultCalendarSetUser: setUser,
    DefaultCalendarSetClient: setClient,

    // ? REMOVE
    DefaultCalendarRemoveStudio: removeStudio,
    DefaultCalendarRemoveService: removeService,
    DefaultCalendarRemovePointOfSale: removePointOfSale,

    DefaultCalendarResetSelected: (
      state: DefaultSelectedFiltersState,
      action: PayloadAction<string>
    ) => {
      return { ...state, [action.payload]: [] };
    },
    DefaultCalendarReset: () => initialState,
  },
});

export const {
  DefaultCalendarSetView,
  DefaultCalendarSetSelectedDate,
  DefaultCalendarSelectZoomValue,
  DefaultCalendarSelectPointOfSale,
  DefaultCalendarSetService,
  DefaultCalendarSelectStudio,
  DefaultCalendarRemoveStudio,
  DefaultCalendarRemoveService,
  DefaultCalendarRemovePointOfSale,
  DefaultCalendarResetSelected,
  DefaultCalendarSetUser,
  DefaultCalendarSetClient,
  DefaultCalendarReset,
} = DefaultCalendarSlice.actions;

export default DefaultCalendarSlice.reducer;
