/**
 * Controlla se il dispositivo in uso è un iPadOS.
 *
 * Questa funzione verifica la presenza di "Macintosh" nella stringa dell'User Agent del browser.
 * Se presente, tenta di creare un evento di tipo "TouchEvent".
 * Se la creazione dell'evento non genera errori, si assume che il dispositivo sia un iPadOS.
 *
 * @returns {boolean} Ritorna true se il dispositivo è identificato come iPadOS, altrimenti false.
 *
 * @example
 * if (checkIpadOs()) {
 *   console.log("Il dispositivo è un iPadOS.");
 * } else {
 *   console.log("Il dispositivo non è un iPadOS.");
 * }
 */
const checkIpadOs = function () {
  const ua = window.navigator.userAgent;

  if (ua.indexOf('Macintosh') > -1) {
    try {
      document.createEvent('TouchEvent');
      return true; // Il dispositivo è un iPadOS
    } catch (e: any) {
      // Il blocco catch cattura l'errore se l'evento TouchEvent non può essere creato
    }
  }

  return false; // Il dispositivo non è un iPadOS
};

export const isIpadOs = checkIpadOs();
