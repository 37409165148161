import { FC, memo } from 'react';
import axios from 'axios';
import { Provider } from 'react-redux';
import { store } from '../../store';
import { LicenseInfo } from '@mui/x-license-pro';
import { Stack } from '@mui/material';
import jwt_decode from 'jwt-decode';
import { setWhiteCache } from '../../lib/white-cache';
import { ServicePage } from './page';
import { InitServiceData } from './lib/init';
import { MUI_license } from '@bludata/components';

interface ServicesInterface {
  token?: string;
  url?: string;
  maxBookingValue?: number;
  focusWeb?: boolean;
  root?: string;
  isBluBookingActive?: boolean;
}

LicenseInfo.setLicenseKey(MUI_license);

const Services: FC<ServicesInterface> = memo(
  ({ url, token, root, isBluBookingActive }) => {
    if (url !== undefined) axios.defaults.baseURL = url;

    let bearerToken: string | undefined;
    if (token !== undefined) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      bearerToken = token;
    } else if (
      axios.defaults.headers.common['Authorization'] !== null &&
      axios.defaults.headers.common['Authorization'] !== undefined
    ) {
      bearerToken = (
        axios.defaults.headers.common['Authorization'] as string
      ).replace('Bearer ', '');
    }

    let accessToken: any;
    try {
      if (bearerToken !== undefined) accessToken = jwt_decode(bearerToken);

      if (isBluBookingActive === undefined && accessToken !== undefined)
        setWhiteCache(
          'isBluBookingActive',
          accessToken?.IsBluBookingActive === 'True'
        );
      else setWhiteCache('isBluBookingActive', isBluBookingActive);
    } catch {}

    return (
      <Provider store={store}>
        <Stack
          flex={1}
          style={{
            width: '100%',
            height: '100%',
            backgroundColor: 'var(--hovered-background-color)',
          }}
        >
          <InitServiceData />
          <ServicePage root={root} />
        </Stack>
      </Provider>
    );
  }
);

export default Services;
