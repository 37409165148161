/* eslint-disable @typescript-eslint/no-explicit-any */
import './SearchClient.css';

import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from '@mui/material';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import React from 'react';
import { BluDialog, BluTextField } from '@bludata/components';
import { useDispatch, useSelector } from 'react-redux';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import { TableResultFocusWeb } from './DisplayResultsFocusWeb';
import { TableResult } from './DisplayResults';
import { GridCloseIcon } from '@mui/x-data-grid-premium';
import {
  setClientIdFilter,
  setPage,
  setOpenClientFilter,
} from '../../store/Notifications';
import { AppDispatch, RootState } from 'packages/blu-booking/src/store';

interface SearchPopUpProps {
  focus10: boolean;
  focusWeb: boolean;
}

export const MessageHistorySearchPopUp = memo(
  ({ focus10, focusWeb }: SearchPopUpProps) => {
    const [cliente, setCliente] = useState<string>('');
    const { openClientFilter, clientFilter } = useSelector(
      (state: RootState) => state.bluBookingNotifications
    );
    const dispatch = useDispatch<AppDispatch>();
    const [row, setRow] = useState<any | undefined>(undefined);
    const [unregistered, setUnregistered] = useState<boolean>(false);

    const GetResults = useMemo(() => {
      if (focusWeb && !unregistered)
        return (
          <TableResultFocusWeb
            value={cliente}
            action={(client: any) => {
              setRow({
                ...client,
                surname: client.cognome,
                name: client.nome,
                telephone: client.telefoninoGsm || client.telefonino,
                email: client.eMail,
                externalCustomerCode: client?.codicePersonale ?? null,
                isImo: client?.appAttiva,
              });
            }}
          />
        );
      return (
        <TableResult
          value={cliente}
          focus10={focus10}
          action={(client: any) => {
            setRow(client);
          }}
          unregistered={unregistered}
        />
      );
    }, [cliente, focus10, focusWeb, unregistered]);

    const handleCercaClick = useCallback(() => {
      if (row) {
        dispatch(setClientIdFilter(row));
        dispatch(setPage(1));
        setRow(undefined);
        dispatch(setOpenClientFilter(false));
      }
    }, [dispatch, row]);

    return (
      <>
        <Stack>
          <Typography>Ricerca cliente</Typography>
          <BluTextField
            size="small"
            value={
              clientFilter ? `${clientFilter.name} ${clientFilter.surname}` : ''
            }
            sx={{
              width: '300px',
              cursor: 'pointer',
              '& .MuiInput-root': {
                cursor: 'pointer !important',
              },
              '& input': {
                cursor: 'pointer !important',
              },
            }}
            onClick={(event: any) => {
              dispatch(setOpenClientFilter(true));
            }}
            inputProps={{
              readOnly: true,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {clientFilter ? (
                    <IconButton
                      color="primary"
                      size="small"
                      onClick={(event: any) => {
                        dispatch(setClientIdFilter(undefined));
                        event.stopPropagation();
                      }}
                    >
                      <GridCloseIcon />
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={(event) => {
                        dispatch(setOpenClientFilter(true));
                        event.stopPropagation();
                      }}
                    >
                      <SearchTwoToneIcon color="primary" />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
        </Stack>

        <BluDialog
          open={openClientFilter}
          fullScreen={false}
          maxWidth={'lg'}
          zIndex={400}
          draggable
          dialogTitle="Ricerca cliente"
          dialogActions={
            <Stack
              direction="row"
              flex="1"
              sx={{ width: '100%' }}
              gap={1}
              justifyContent="end"
            >
              <Button
                variant="outlined"
                color="primary"
                sx={{ width: 'fit-content' }}
                onClick={() =>
                  dispatch(setOpenClientFilter(false)) &&
                  dispatch(setClientIdFilter(undefined))
                }
              >
                Chiudi
              </Button>

              <Button
                color="primary"
                sx={{ width: 'fit-content' }}
                onClick={() => handleCercaClick()}
                disabled={row === undefined}
              >
                {'Cerca'}
              </Button>
            </Stack>
          }
          dialogContent={
            <div style={{ minWidth: focus10 ? '800px' : 'unset' }}>
              <div>
                <Typography variant="subtitle2" sx={{ p: '3px 0px' }}>
                  Ricerca cliente
                </Typography>
                <Stack direction="row" gap={2}>
                  <Search setValue={(v: string) => setCliente(v)} />
                  {(focus10 || focusWeb) && (
                    <FormControlLabel
                      label="Non ancora clienti"
                      control={
                        <Checkbox
                          checked={unregistered}
                          onChange={() => setUnregistered(!unregistered)}
                          sx={{ p: 0 }}
                        />
                      }
                    />
                  )}
                </Stack>
                <div style={{ height: '20px' }} />

                {GetResults}
              </div>
            </div>
          }
        />
      </>
    );
  }
);

interface SearchProps {
  setValue: (v: string) => void;
}

const Search = memo(({ setValue }: SearchProps) => {
  const [value, set] = useState<string>('');
  useEffect(() => {
    const delayFn = setTimeout(() => {
      setValue(value);
    }, 250);

    return () => {
      clearTimeout(delayFn);
    };
  }, [setValue, value]);

  return (
    <BluTextField
      value={value}
      name="Nome"
      onChange={(event: any) => set(event.target.value)}
      InputProps={{
        startAdornment: <SearchTwoToneIcon />,
      }}
    />
  );
});
