import {
  BluAlert,
  BluAsyncDialog,
  BluDialog,
  BluTextField,
} from '@bludata/components';
import { Button, Divider, Stack } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useWatch } from 'react-hook-form';
import SyncIcon from '@mui/icons-material/Sync';
import { deleteUserGoogleCalendar } from '../../../../api/delete-google-calendar';
import { updateUserGoogleCalendar } from '../../../../api/update-google-calendar';

interface CalendarReactivationDialogInterface {
  id: string;
  email: string;
  isConnect: boolean;
  showDialogDisableAndReactivation: boolean;
  setShowDialogDisableAndReactivation: (value: boolean) => void;
}

export const CalendarReactivationDialog: FC<CalendarReactivationDialogInterface> =
  memo(
    ({
      id,
      email,
      isConnect,
      showDialogDisableAndReactivation,
      setShowDialogDisableAndReactivation,
    }) => {
      const [mailToSync, setMailToSync] = useState(email);
      const [blubookingId, userId, username] = useWatch({
        name: ['blubookingId', 'id', 'username'],
      });
      const queryClient = useQueryClient();

      useEffect(() => {
        setMailToSync(email);
      }, [email]);

      const handleClick = useCallback(async () => {
        let result;
        if (isConnect)
          result = await deleteUserGoogleCalendar(blubookingId || userId);
        else
          result = await updateUserGoogleCalendar(
            blubookingId || userId,
            mailToSync,
            id
          );
        if (result) {
          queryClient.invalidateQueries(['google-calendar-sync'], {
            type: 'active',
          });
          queryClient.removeQueries(['google-calendar-sync'], {
            type: 'inactive',
          });
          setShowDialogDisableAndReactivation(
            !showDialogDisableAndReactivation
          );
          return;
        }
        BluAsyncDialog({
          title: 'Attenzione',
          type: 'error',
          message: 'Errore durante la sincronizzazione.',
          hideDeclineButton: true,
          confimButton: 'Chiudi',
          sx: { '& .MuiDialog-paper': { maxWidth: '370px' } },
        });
      }, [
        blubookingId,
        id,
        isConnect,
        mailToSync,
        queryClient,
        setShowDialogDisableAndReactivation,
        showDialogDisableAndReactivation,
        userId,
      ]);

      const GetContent = useMemo(() => {
        if (isConnect)
          return (
            <BluAlert
              type="warning"
              show={showDialogDisableAndReactivation}
              handleClose={() => {
                setShowDialogDisableAndReactivation(false);
              }}
              handleConfirm={handleClick}
              text={
                'Sei sicuro di voler disabilitare la sincronizzazione per questo utente?'
              }
            />
          );
        return (
          <BluDialog
            open={showDialogDisableAndReactivation}
            maxWidth={'xs'}
            fullScreen={false}
            dialogTitle={'Riattivazione Google Calendar'}
            dialogActions={
              <>
                <Button
                  onClick={() => {
                    setShowDialogDisableAndReactivation(false);
                  }}
                  variant="outlined"
                >
                  Annulla
                </Button>
                <Button
                  disabled={
                    // eslint-disable-next-line no-useless-escape
                    !/^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/.test(mailToSync)
                  }
                  onClick={handleClick}
                  autoFocus
                  startIcon={<SyncIcon />}
                >
                  Riattiva
                </Button>
              </>
            }
            dialogContent={
              <Stack direction="column">
                {`Sei sicuro di voler riattivare la sicronizzazione del calendario di
            ${username}?`}
                <Divider sx={{ opacity: 0, mb: '24px!important' }} />
                Reinserisci l'indirizzo email del personale il cui calendario
                deve essere sincronizzato, se differente da quello vecchio.
                <Divider sx={{ opacity: 0, mb: '24px!important' }} />
                <BluTextField
                  value={mailToSync}
                  onChange={(e) => {
                    setMailToSync(e.target.value);
                  }}
                />
              </Stack>
            }
            zIndex={1}
          />
        );
      }, [
        handleClick,
        isConnect,
        mailToSync,
        setShowDialogDisableAndReactivation,
        showDialogDisableAndReactivation,
        username,
      ]);

      return <>{GetContent}</>;
    }
  );
