/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import dayjs from 'dayjs';
import { appointmentSlotIsOk } from './AppointmentSlotIsOk';

export const appointmentSlotDetails = async ({
  Date,
  serviceId,
  pointOfSaleId,
  studioId,
  userId,
  durationValue,
}: {
  Date: string;
  serviceId?: string;
  pointOfSaleId?: string;
  studioId?: string;
  userId?: string;
  durationValue?: number;
}): Promise<string[]> => {
  try {
    const { data } = await axios.get(`/api/AppointmentSlot/Details/`, {
      params: {
        serviceId,
        pointOfSaleId,
        userId,
        studioId,
        date: dayjs(Date).format('YYYY-MM-DD'),
        durationValue,
      },
    });
    if ('data' in data) {
      return data.data.times.map((hour: string) => hour.slice(0, -3)).flat();
    }
    return [];
  } catch (err) {
    console.trace(err);
    return [];
  }
};

export const useAppointmentSlotDetails = ({
  serviceId,
  pointOfSaleId,
  Date,
  userId,
  studioId,
  durationValue,
  appointmentId,
}: {
  Date: string;
  serviceId?: string;
  pointOfSaleId?: string;
  userId?: string;
  appointmentId?: string;
  studioId?: string;
  start?: string;
  durationValue?: number;
}) => {
  return useQuery({
    queryFn: async () =>
      await appointmentSlotDetails({
        Date,
        serviceId,
        pointOfSaleId,
        studioId,
        userId,
        durationValue,
      }),
    queryKey: [
      'appointment-slot-details',
      Date,
      serviceId,
      pointOfSaleId,
      studioId,
      userId,
      durationValue,
    ],
    retry: 2,
    retryDelay: 2000,
    useErrorBoundary: true,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: true,
    cacheTime: 0,
    staleTime: 0,
    select: (data: string[]) =>
      normalize(data, {
        serviceId,
        pointOfSaleId,
        startDate: Date,
        userId,
        studioId,
        durationValue,
        appointmentId,
      }),
    // select: normalize
  });
};

const normalize = (
  data: string[],
  {
    serviceId,
    pointOfSaleId,
    startDate,
    userId,
    studioId,
    durationValue,
    appointmentId,
  }: {
    startDate: string;
    serviceId?: string;
    pointOfSaleId?: string;
    userId?: string;
    appointmentId?: string;
    studioId?: string;
    start?: string;
    durationValue?: number;
  }
): string[] => {
  if (data.length === 0 || Date === undefined) return [];

  return data;
};
