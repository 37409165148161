import { Box } from '@mui/material';
import { FC } from 'react';
import { BluContainerBlockInterface } from './interface';
import '../../styles/container-block.css';

const BluContainerBlock: FC<BluContainerBlockInterface> = ({
  children,
  ...rest
}) => {
  return (
    <Box className="blu-container-block" {...rest}>
      {children}
    </Box>
  );
};

export default BluContainerBlock;
