import PersonOffIcon from '@mui/icons-material/PersonOff';
import PersonIcon from '@mui/icons-material/Person';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';

const statusIcon = [
  QuestionMarkIcon,
  CheckCircleIcon,
  EmojiPeopleIcon,
  PersonOffIcon,
  PersonIcon,
];
export const getStatusIcon = (status: 0 | 1 | 2 | 3 | 4) => {
  return statusIcon[status];
};
