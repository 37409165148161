import { Stack, Typography } from '@mui/material';
import { FC, memo, useCallback, useMemo } from 'react';
import { RotaPopUpDatePicker } from '../../components/DatePicker';
import { SelectType } from '../../components/selectType';
import { useWatch } from 'react-hook-form';
import { BluTitle } from '@bludata/components';
import { ContextMenu } from '../../components/ContextMenu';

interface CalendarInterface {
  turniType: 'pointOfSale' | 'user';
  date: Date;
}

export const Calendar: FC<CalendarInterface> = memo(({ turniType, date }) => {
  const isSingleCalendarManagement = useWatch({
    name: 'isSingleCalendarManagement',
  });
  const type = useWatch({
    name: `typeCalendar`,
  });
  const GetContent = useMemo(() => {
    return (
      <Stack flex="1" gap={1}>
        <Stack>
          <Typography>Primo turno</Typography>
          <Stack direction="row" gap={1}>
            <RotaPopUpDatePicker
              name="startTime1"
              calendarType="Calendar"
              errorProperty="firstTurnCalendar"
              date={date}
            />
            <RotaPopUpDatePicker
              name="endTime1"
              calendarType="Calendar"
              errorProperty="firstTurnCalendar"
              date={date}
            />
          </Stack>
        </Stack>
        <Stack>
          <Typography>Secondo turno</Typography>
          <Stack direction="row" gap={1}>
            <RotaPopUpDatePicker
              name="startTime2"
              calendarType="Calendar"
              errorProperty="secondTurnCalendar"
              date={date}
            />
            <RotaPopUpDatePicker
              name="endTime2"
              calendarType="Calendar"
              errorProperty="secondTurnCalendar"
              date={date}
            />
          </Stack>
        </Stack>
      </Stack>
    );
  }, [date]);

  const getTitle = useCallback(() => {
    return isSingleCalendarManagement ? 'Turno' : 'Turno appuntamento';
  }, [isSingleCalendarManagement]);

  return (
    <Stack flex="1" gap={1}>
      <Stack direction="row" flex="1" gap={1} alignItems="center">
        <Stack flex="1">
          <BluTitle text={getTitle()} />
        </Stack>

        <ContextMenu calendarType="Calendar" />
      </Stack>
      {/* <SelectType calendarType="Calendar" turniType={turniType} /> */}
      {type !== 'closed' && type !== 'extra-closed' && (
        <Stack>{GetContent}</Stack>
      )}
    </Stack>
  );
});
