import { Box, Button, IconButton, Tooltip } from '@mui/material';
import { Stack } from '@mui/system';
import dayjs from 'dayjs';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import RefreshIcon from '@mui/icons-material/Refresh';
import CalendarTodayTwoToneIcon from '@mui/icons-material/CalendarTodayTwoTone';
import { isTablet } from 'react-device-detect';
import { FC, memo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import { AppDispatch } from 'packages/blu-booking/src/store';
import { getConfig } from '../../utility_objects/WhiteCache';
import { setRefetch } from '../../store/Appointment';
import { setSearchPopUp } from '../../store/Calendar';

interface CalendarDaySelectionInterface {
  move: [number, dayjs.ManipulateType];
  selectedDate: Date;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setSelectedDate: (value: any) => void;
}

export const CalendarDaySelection: FC<CalendarDaySelectionInterface> = memo(
  function CalendarDaySelection({
    move,
    selectedDate,
    setSelectedDate,
  }): JSX.Element {
    const dispatch = useDispatch<AppDispatch>();
    const { focus10 } = getConfig();

    const resetDate = () => {
      setSelectedDate(new Date());
    };

    const prevDate = () => {
      setSelectedDate(
        dayjs(selectedDate)
          .subtract(...move)
          .toDate()
      );
    };

    const nextDate = () => {
      setSelectedDate(
        dayjs(selectedDate)
          .add(...move)
          .toDate()
      );
    };

    const refreshTap = () => {
      dispatch(setRefetch());
    };

    useEffect(() => {
      window.addEventListener('refreshCalendar', refreshTap);
      return () => window.removeEventListener('refreshCalendar', refreshTap);
    });

    return (
      <Stack flexDirection="row" alignItems="center" justifyContent="center">
        <Stack direction="row">
          <Tooltip title="Precedente">
            <IconButton color="primary" onClick={prevDate}>
              <ChevronLeftIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Ritorna a oggi">
            {isTablet ? (
              <IconButton color="primary" onClick={resetDate}>
                <CalendarTodayTwoToneIcon />
              </IconButton>
            ) : (
              <div
                style={{
                  borderRadius: '4px',
                }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<CalendarTodayTwoToneIcon />}
                  onClick={resetDate}
                >
                  Oggi
                </Button>
              </div>
            )}
          </Tooltip>
          <Tooltip title="Successivo">
            <IconButton color="primary" onClick={nextDate}>
              <ChevronRightIcon />
            </IconButton>
          </Tooltip>
        </Stack>
        <Box sx={{ flex: 1 }} />
        <IconButton onClick={() => dispatch(setSearchPopUp(true))}>
          <SearchTwoToneIcon color="primary" />
        </IconButton>
        {!focus10 && (
          <Tooltip title="Ricarica">
            <IconButton color="primary" onClick={refreshTap}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
    );
  }
);
