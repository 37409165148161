/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import axios from 'axios';
import dayjs from 'dayjs';

export const getUsersHoursCalendar = async (
  pointOfSaleId: string,
  selectedDate: Date,
  view: string
): Promise<any> => {
  try {
    const {
      data: { data },
    } = await axios.post(`/api/CalendarUser/GetAll`, {
      pointOfSaleId,
      date: dayjs(selectedDate)
        .startOf('date')
        .locale('it')
        .format('YYYY-MM-DD'),
      // endDate,
    });
    return data;
  } catch (err) {
    console.trace(err);
    return [];
  }
};

export const useUsersHoursCalendar = ({
  pointOfSaleId,
  selectedDate,
  view = 'Day',
  options = {},
  key = '',
}: {
  pointOfSaleId?: string;
  selectedDate: Date;
  view?: string;
  options?: UseQueryOptions;
  key?: string; // Add this optional parameter to your useQuery hook.
}) => {
  return useQuery({
    queryFn: async () =>
      await getUsersHoursCalendar(pointOfSaleId, selectedDate, view),
    queryKey: [
      'users-hours',
      'calendar',
      pointOfSaleId,
      selectedDate,
      view,
      key,
    ],
    retry: 2,
    retryDelay: 180000,
    cacheTime: 0,
    staleTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    select: normalize,
    ...options,
  });
};

const normalize = (result: any) => {
  if (result && result.length > 0) {
    const data: any = {};
    const res = [];
    for (const day of result) {
      if (day.calendarList.length) data[day.userId] = day.calendarList[0].hours;
      res.push({ ...day, name: day.username });
    }
    console.log('USERS', data, res);
    return [data, res];
  }
  return [];
};
