/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { mapColor } from '../../utility_objects/ColorManager';

interface userProps {
  serviceId?: string;
  pointOfSaleId?: string;
  lockService: boolean;
  lockPointOfSale: boolean;
  filtersLoaded?: boolean;
  shopSignId?: string;
}

export const userFiltered = async ({
  lockService,
  lockPointOfSale,
  serviceId,
  pointOfSaleId,
  shopSignId,
}: userProps): Promise<{ id: string; name: string }[]> => {
  try {
    const params: any = { shopSignId };

    if (lockService && serviceId !== '') params.serviceId = serviceId;
    if (lockPointOfSale && pointOfSaleId !== '')
      params.pointOfSaleId = pointOfSaleId;
    const {
      data: { data },
    } = await axios.get(`/api/User/Filter`, {
      params,
    });

    return data
      .map((user: any) => {
        return {
          ...user,
          color:
            user?.color !== null && user.color !== undefined
              ? user.color
              : mapColor[user.id],
        };
      })
      .flat();
  } catch (err) {
    console.trace(err);
    return [];
  }
};

export const useUsersFilter = ({
  lockService,
  lockPointOfSale,
  serviceId,
  pointOfSaleId,
  shopSignId,
  filtersLoaded = true,
}: userProps) => {
  return useQuery({
    queryFn: async () =>
      await userFiltered({
        lockService,
        lockPointOfSale,
        serviceId,
        pointOfSaleId,
        shopSignId,
      }),
    queryKey: [
      'filter',
      'user',
      lockService ? serviceId : '',
      lockPointOfSale ? pointOfSaleId : '',
      shopSignId ?? '',
    ],
    enabled: filtersLoaded,
    retry: 2,
    retryDelay: 180000,
    staleTime: 300000,
    cacheTime: 300000,
    refetchInterval: 300000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
};
