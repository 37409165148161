import { FC, memo, useCallback } from 'react';
import { ListItem, ListItemButton, Stack, Typography } from '@mui/material';
import { ThreadInterface } from '../../interfaces/thread';
import dayjs from 'dayjs';
import React from 'react';
import { ThreadMenu } from './thread-menu';

interface ThreadsMenuInterface {
  threadId?: string | null;
  setThreadId: (newThreadId?: string | null) => void;
  threads?: ThreadInterface[];
}

export const ThreadsMenu: FC<ThreadsMenuInterface> = memo(
  ({ threads = [], threadId, setThreadId }) => {
    let week = false,
      month = false,
      more = false;

    const rangeWeek = dayjs().subtract(7, 'day');
    const rangeMonth = dayjs().subtract(30, 'day');

    const getSelected = useCallback(
      (openAiId: string) => openAiId === threadId,
      [threadId]
    );

    const handleClick = useCallback(
      (openAiId?: string) => {
        setThreadId(openAiId);
      },
      [setThreadId]
    );

    return (
      <>
        {threads &&
          threads.map((element: any) => {
            let component: JSX.Element | null = null;
            if (dayjs(element.updatedAt).isAfter(rangeWeek)) {
              if (!week) {
                week = true;
                component = (
                  <Typography
                    sx={{ pl: 2, pb: '1px', color: 'var(--base-color)' }}
                  >
                    Ultimi 7 giorni
                  </Typography>
                );
              }
            } else if (dayjs(element.updatedAt).isAfter(rangeMonth)) {
              if (!month) {
                month = true;
                component = (
                  <Typography
                    sx={{ pl: 2, pb: '1px', color: 'var(--base-color)' }}
                  >
                    Ultimi 30 giorni
                  </Typography>
                );
              }
            } else if (dayjs(element.updatedAt).isBefore(rangeMonth)) {
              if (!more) {
                more = true;
                component = (
                  <Typography
                    sx={{ pl: 2, pb: '1px', color: 'var(--base-color)' }}
                  >
                    Oltre 30 giorni
                  </Typography>
                );
              }
            }
            return (
              <>
                {component}
                <ThreadItem
                  {...element}
                  selected={getSelected(element.openAiId)}
                  handleClick={handleClick}
                  selectedThreadId={threadId}
                  setThreadId={setThreadId}
                />
              </>
            );
          })}
      </>
    );
  }
);

interface ThreadItemInterface extends ThreadInterface {
  selectedThreadId?: string;
  selected: boolean;
  handleClick: (value: string) => void;
  setThreadId: (value?: string) => void;
}

const ThreadItem: FC<ThreadItemInterface> = memo(
  ({
    openAiId,
    description,
    assistantName,
    selected,
    handleClick,
    selectedThreadId,
    setThreadId,
  }) => {
    return (
      <ListItem disablePadding onClick={() => handleClick(openAiId)}>
        <ListItemButton
          style={{ padding: 0, width: '100%', height: '50px' }}
          selected={selected}
        >
          <Stack direction="row" sx={{ width: '100%', pr: 0 }}>
            <Stack sx={{ width: 'calc(100% - 50px)' }}>
              <Typography
                fontSize="13px"
                sx={{ display: 'flex', flex: '1', pl: 1 }}
              >
                {assistantName}
              </Typography>

              <Typography
                fontSize="16px"
                fontWeight="500"
                sx={{
                  pl: 1,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: ' calc(80%)',
                }}
              >
                {description}
              </Typography>
            </Stack>

            <ThreadMenu
              selectedThreadId={selectedThreadId}
              threadId={openAiId}
              setThreadId={setThreadId}
            />
          </Stack>
        </ListItemButton>
      </ListItem>
    );
  }
);
