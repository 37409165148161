import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { nanoid } from '@reduxjs/toolkit';
import { HolidayInterface } from '../../interfaces/Holiday';
import { defaultHolidays } from '../../utility_objects/DefaultHolidays';

const getHolidays = async (shopSignId: string): Promise<HolidayInterface[]> => {
  let result = defaultHolidays;
  try {
    const {
      data: { data },
    } = await axios.get(`/api/Holiday`);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    result = data.map((row: HolidayInterface) => {
      return {
        ...row,
        internalId: nanoid(),
      };
    });
    return result;
  } catch (error) {
    console.log(error);
    return result;
  }
};

export const useHolidays = (shopSignId = '', key = '') => {
  return useQuery({
    queryFn: async () => await getHolidays(shopSignId),
    queryKey: ['holidays', shopSignId, key],
    retry: 2,
    retryDelay: 180000,
    cacheTime: 0,
    staleTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
};
