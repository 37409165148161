import { Card, Box, Button, Stack, Typography, Divider } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import ModeIcon from '@mui/icons-material/Mode';
import { AppDispatch, RootState, useAppSelector } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { Header } from '../../UI/Header';
import AddIcon from '@mui/icons-material/Add';
import { BluLoader, BluTitle } from '@bludata/components';
import { usePointOfSales } from './api/point-of-sales';
import {
  setCurrentUserBluBookingId,
  setCurrentUsers,
  setUsersDatas,
  setUsersOpenForm,
} from './store/root';
import { UsersGridFilters } from './features/GridFilters/GridFilters';
import { UsersGrid } from './features/Grid/Grid';
import { useUsers } from './api/users';
import { UserPopUp } from './features/UserPopUp/UserPopUp';
import { bluBookingLoginData } from '../../store/slices/root';
import WebAssetIcon from '@mui/icons-material/WebAsset';

interface UsersInterface {
  root?: string;
  isConsole?: boolean;
}

export const UsersPage: FC<UsersInterface> = ({
  root = '',
  isConsole = false,
}): JSX.Element => {
  const dispatch: AppDispatch = useDispatch();
  const { software } = useAppSelector(bluBookingLoginData);

  const { data: pointOfSalesData, isLoading: pointOfSaleIsLoading } =
    usePointOfSales();

  const { data: usersData, isLoading: usersIsLoading } = useUsers();

  const { currentUser, openForm } = useSelector(
    (state: RootState) => state.newUsersRoot
  );

  useEffect(() => {
    if (pointOfSalesData && usersData) {
      dispatch(
        setUsersDatas({
          pointOfSales: pointOfSalesData,
          users: usersData as any,
          dataLoads: true,
        })
      );
    }
  }, [dispatch, pointOfSalesData, usersData]);

  const getIsLoading = useCallback(() => {
    return pointOfSaleIsLoading || usersIsLoading;
  }, [pointOfSaleIsLoading, usersIsLoading]);

  const [gridSearch, setGridSearch] = useState('');
  const [filterPointOfSale, setFilterPointOfSale] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [hasAgenda, setHasAgenda] = useState(false);
  const [optician, setOptician] = useState(false);

  const GetPopUp = useMemo(() => {
    if (currentUser !== undefined && openForm)
      return <UserPopUp userId={currentUser} mode={openForm} />;
    return <></>;
  }, [currentUser, openForm]);

  return (
    <Stack
      direction="row"
      justifyContent="start"
      alignItems="start"
      flex={1}
      sx={{
        backgroundColor: 'var(--hovered-background-color)',
        height: '100%',
      }}
    >
      <BluLoader open={getIsLoading()} />
      {GetPopUp}

      <Stack flexDirection="column" flex={1} sx={{ height: '100%' }}>
        <Header title={`${root ?? ''}Utenti`} />
        <Card variant="outlined" sx={{ height: '100%', p: 1, m: 0.5 }}>
          <Stack direction="column" spacing={1} sx={{ height: '100%' }}>
            <BluTitle text={'Lista utenti'} />
            <Typography>
              In questa sezione è possibile configurare l'elenco degli utenti
              abilitati.
            </Typography>
            <div style={{ display: 'flex' }}>
              <Typography component={'div'}>
                Premi il tasto <b>Scheda</b> per visualizzare le impostazioni di
                un utente e <b>Modifica</b> per modificarne uno di esistente.
              </Typography>
            </div>
            <Divider />
            <UsersGridFilters
              gridSearch={gridSearch}
              setGridSearch={setGridSearch}
              hasAgenda={hasAgenda}
              setHasAgenda={setHasAgenda}
              optician={optician}
              setOptician={setOptician}
              isActive={isActive}
              setIsActive={setIsActive}
              filterPointOfSale={filterPointOfSale}
              setFilterPointOfSale={setFilterPointOfSale}
            />
            <UsersGrid
              gridSearch={gridSearch}
              optician={optician}
              isActive={isActive}
              hasAgenda={hasAgenda}
              filterPointOfSale={filterPointOfSale}
            />
            <Stack direction="row" sx={{ mt: '8px' }}>
              <Box flex={1}></Box>
              <Stack direction="row" spacing={0.5}>
                <Button
                  color="primary"
                  startIcon={<AddIcon />}
                  disabled={!(isConsole && software === 'bb')}
                  onClick={() => {
                    dispatch(setUsersOpenForm('new'));
                    dispatch(setCurrentUsers(''));
                    dispatch(setCurrentUserBluBookingId(''));
                  }}
                >
                  Nuovo
                </Button>
                <Button
                  color="primary"
                  startIcon={<ModeIcon />}
                  variant="outlined"
                  disabled={currentUser === undefined || currentUser === ''}
                  onClick={() => {
                    dispatch(setUsersOpenForm('modify'));
                  }}
                >
                  Modifica
                </Button>
                <Button
                  color="primary"
                  startIcon={<WebAssetIcon />}
                  variant="outlined"
                  onClick={() => {
                    dispatch(setUsersOpenForm('info'));
                  }}
                  disabled={currentUser === undefined || currentUser === ''}
                >
                  Scheda
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Card>
      </Stack>
    </Stack>
  );
};
