import { FC } from 'react';
import { store } from '../../store';
import { Provider } from 'react-redux';
import axios from 'axios';
import { setUsersWhiteCache } from './lib/white-cache';
import { UsersPage } from './UsersPage';
import jwtDecode from 'jwt-decode';
import { InitData } from '../../util/init-data';

interface NewUsersInterface {
  software: 'bb' | 'fw' | 'crm' | 'f10';
  root?: string;
  token?: string;
  url?: string;
  isBluBookingActive?: boolean;
  isConsole?: boolean;
  focusWeb?: boolean;
  isFocus10?: boolean;
  isCrm?: boolean;
  isAdmin?: boolean;
  isSingleCalendarManagement?: boolean;
}

export const NewUsers: FC<NewUsersInterface> = ({
  software,
  root = '',
  token,
  url,
  isBluBookingActive,
  isConsole,
  focusWeb,
  isFocus10,
  isCrm,
  isAdmin = false,
  isSingleCalendarManagement,
}): JSX.Element => {
  if (url !== undefined) axios.defaults.baseURL = url;

  if (token !== undefined) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  let isBBActive = false;
  if (isBluBookingActive) {
    isBBActive = isBluBookingActive;
  } else {
    const decoded: any = jwtDecode(
      axios.defaults.headers.common.Authorization!.toString()
    );
    isBBActive = decoded['IsBluBookingActive'] === 'True';
  }

  let isSingleCalendar = isSingleCalendarManagement;
  if (isSingleCalendar === undefined) {
    const jsonSessionProfilo = JSON.parse(
      sessionStorage.getItem('sessionData') || '{}'
    );
    isSingleCalendar = jsonSessionProfilo?.isSingleCalendarManagement;
  }

  setUsersWhiteCache({
    isBluBookingActive: isBBActive,
    path: focusWeb ? '/diaryapi' : '/api',
    isFocusWeb: focusWeb ?? software === 'fw',
    isFocus10: isFocus10 ?? software === 'f10',
    isConsole: isConsole ?? software === 'bb',
    isCrm: isCrm ?? software === 'crm',
    software,
  });

  return (
    <Provider store={store}>
      <InitData
        software={software}
        isSingleCalendarManagement={isSingleCalendar}
        isAdmin={isAdmin}
        isFocusWeb={focusWeb}
      />
      <UsersPage root={root} isConsole={isConsole} />
    </Provider>
  );
};
