/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import 'dayjs/locale/it';
import { Appointment_interface } from '../../../interfaces/Interfaces';

export interface AppointmentSliceState {
  appointments: Appointment_interface[];
  loadingAppointments: boolean;
  printComponent: any;
  refetch: boolean;
}

const initialState: AppointmentSliceState = {
  appointments: [],
  loadingAppointments: false,
  printComponent: null,
  refetch: false,
};

const AppointmentSlice = createSlice({
  name: 'BluBookingAppointment',
  initialState,
  reducers: {
    setRefetch(state: AppointmentSliceState) {
      state.refetch = !state.refetch;
    },

    setAppointments(
      state: AppointmentSliceState,
      action: PayloadAction<Appointment_interface[]>
    ) {
      state.appointments = action.payload;
    },
    setAppointmentsIndex(
      state: AppointmentSliceState,
      action: PayloadAction<{
        index: number;
        appointment: Appointment_interface;
      }>
    ) {
      state.appointments[action.payload.index] = action.payload.appointment;
    },
    setLoadingAppointments(
      state: AppointmentSliceState,
      action: PayloadAction<boolean>
    ) {
      state.loadingAppointments = action.payload;
    },

    setFilters(state: AppointmentSliceState, action: PayloadAction<any>) {
      return {
        ...state,
        ...action.payload,
      };
    },
    setPrintComponent(
      state: AppointmentSliceState,
      action: PayloadAction<any>
    ) {
      state.printComponent = action.payload;
    },
    resetAppointment() {
      return initialState;
    },
  },
});

export const {
  setRefetch,
  setAppointments,
  setLoadingAppointments,
  setAppointmentsIndex,
  setFilters,
  resetAppointment,
  setPrintComponent,
} = AppointmentSlice.actions;

export default AppointmentSlice.reducer;
