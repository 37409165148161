import axios from 'axios';
import { getUsersPath } from '../lib/white-cache';
import { TurniItemInterface } from '../interfaces/default-turni-user';
import dayjs from 'dayjs';
import { CalendarPointOfSaleInterface } from '../interfaces/calendar-point-of-sale';

export const getImportTurniShiftAndCalendarPointOfSale = async (
  pointOfSaleId: string,
  isSingleCalendarManagement: boolean
): Promise<TurniItemInterface> => {
  const getCalendarPointOfSale = async (
    pointOfSaleId: string
  ): Promise<CalendarPointOfSaleInterface[]> => {
    try {
      const {
        data: { data },
      } = await axios.get(
        `${getUsersPath()}/CalendarPointOfSale/PointOfSale/${pointOfSaleId}`
      );
      return data;
    } catch (error) {
      return [];
    }
  };

  const getShiftPointOfSale = async (
    pointOfSaleId: string
  ): Promise<CalendarPointOfSaleInterface[]> => {
    try {
      const {
        data: { data },
      } = await axios.get(
        `${getUsersPath()}/ShiftPointOfSale/PointOfSale/${pointOfSaleId}`
      );
      return data;
    } catch (error) {
      return [];
    }
  };

  try {
    if (isSingleCalendarManagement) {
      const responses = await getCalendarPointOfSale(pointOfSaleId);
      return normalize([[], responses] as any);
    }

    const responses = await Promise.all([
      getCalendarPointOfSale(pointOfSaleId),
      getShiftPointOfSale(pointOfSaleId),
    ]);

    return normalize(responses as any);
  } catch (err) {
    console.error(err);
    return normalize([[], []]);
  }
};

const normalize = (data: any): any => {
  let result: TurniItemInterface = {
    shiftmonday: null,
    shifttuesday: null,
    shiftwednesday: null,
    shiftthursday: null,
    shiftfriday: null,
    shiftsaturday: null,
    shiftsunday: null,

    calendarmonday: null,
    calendartuesday: null,
    calendarwednesday: null,
    calendarthursday: null,
    calendarfriday: null,
    calendarsaturday: null,
    calendarsunday: null,
  };

  try {
    const getResult = (
      turno: CalendarPointOfSaleInterface[],
      type: 'shift' | 'calendar'
    ) => {
      const temp: any = {
        [`${type}monday`]: null,
        [`${type}tuesday`]: null,
        [`${type}wednesday`]: null,
        [`${type}thursday`]: null,
        [`${type}friday`]: null,
        [`${type}saturday`]: null,
        [`${type}sunday`]: null,
      };
      for (const calendar of turno) {
        const isClosed = !(
          (calendar.startTime1 !== null && calendar.endTime1 !== null) ||
          (calendar.startTime2 !== null && calendar.endTime2 !== null)
        );
        if (calendar.hasMonday) {
          temp[`${type}monday`] = {
            id: calendar.id,
            isClosed: isClosed,
            startTime1:
              calendar && calendar?.startTime1
                ? dayjs(calendar?.startTime1, 'HH:mm:ss')
                : null,
            startTime2:
              calendar && calendar?.startTime2
                ? dayjs(calendar?.startTime2, 'HH:mm:ss')
                : null,
            endTime1:
              calendar && calendar?.endTime1
                ? dayjs(calendar?.endTime1, 'HH:mm:ss')
                : null,
            endTime2:
              calendar && calendar?.endTime2
                ? dayjs(calendar?.endTime2, 'HH:mm:ss')
                : null,
          };
        }
        if (calendar.hasTuesday) {
          temp[`${type}tuesday`] = {
            id: calendar.id,
            isClosed: isClosed,
            startTime1:
              calendar && calendar?.startTime1
                ? dayjs(calendar?.startTime1, 'HH:mm:ss')
                : null,
            startTime2:
              calendar && calendar?.startTime2
                ? dayjs(calendar?.startTime2, 'HH:mm:ss')
                : null,
            endTime1:
              calendar && calendar?.endTime1
                ? dayjs(calendar?.endTime1, 'HH:mm:ss')
                : null,
            endTime2:
              calendar && calendar?.endTime2
                ? dayjs(calendar?.endTime2, 'HH:mm:ss')
                : null,
          };
        }
        if (calendar.hasWednesday) {
          temp[`${type}wednesday`] = {
            id: calendar.id,
            isClosed: isClosed,
            startTime1:
              calendar && calendar?.startTime1
                ? dayjs(calendar?.startTime1, 'HH:mm:ss')
                : null,
            startTime2:
              calendar && calendar?.startTime2
                ? dayjs(calendar?.startTime2, 'HH:mm:ss')
                : null,
            endTime1:
              calendar && calendar?.endTime1
                ? dayjs(calendar?.endTime1, 'HH:mm:ss')
                : null,
            endTime2:
              calendar && calendar?.endTime2
                ? dayjs(calendar?.endTime2, 'HH:mm:ss')
                : null,
          };
        }
        if (calendar.hasThursday) {
          temp[`${type}thursday`] = {
            id: calendar.id,
            isClosed: isClosed,
            startTime1:
              calendar && calendar?.startTime1
                ? dayjs(calendar?.startTime1, 'HH:mm:ss')
                : null,
            startTime2:
              calendar && calendar?.startTime2
                ? dayjs(calendar?.startTime2, 'HH:mm:ss')
                : null,
            endTime1:
              calendar && calendar?.endTime1
                ? dayjs(calendar?.endTime1, 'HH:mm:ss')
                : null,
            endTime2:
              calendar && calendar?.endTime2
                ? dayjs(calendar?.endTime2, 'HH:mm:ss')
                : null,
          };
        }
        if (calendar.hasFriday) {
          temp[`${type}friday`] = {
            id: calendar.id,
            isClosed: isClosed,
            startTime1:
              calendar && calendar?.startTime1
                ? dayjs(calendar?.startTime1, 'HH:mm:ss')
                : null,
            startTime2:
              calendar && calendar?.startTime2
                ? dayjs(calendar?.startTime2, 'HH:mm:ss')
                : null,
            endTime1:
              calendar && calendar?.endTime1
                ? dayjs(calendar?.endTime1, 'HH:mm:ss')
                : null,
            endTime2:
              calendar && calendar?.endTime2
                ? dayjs(calendar?.endTime2, 'HH:mm:ss')
                : null,
          };
        }
        if (calendar.hasSaturday) {
          temp[`${type}saturday`] = {
            id: calendar.id,
            isClosed: isClosed,
            startTime1:
              calendar && calendar?.startTime1
                ? dayjs(calendar?.startTime1, 'HH:mm:ss')
                : null,
            startTime2:
              calendar && calendar?.startTime2
                ? dayjs(calendar?.startTime2, 'HH:mm:ss')
                : null,
            endTime1:
              calendar && calendar?.endTime1
                ? dayjs(calendar?.endTime1, 'HH:mm:ss')
                : null,
            endTime2:
              calendar && calendar?.endTime2
                ? dayjs(calendar?.endTime2, 'HH:mm:ss')
                : null,
          };
        }
        if (calendar.hasSunday) {
          temp[`${type}sunday`] = {
            id: calendar.id,
            isClosed: isClosed,
            startTime1:
              calendar && calendar?.startTime1
                ? dayjs(calendar?.startTime1, 'HH:mm:ss')
                : null,
            startTime2:
              calendar && calendar?.startTime2
                ? dayjs(calendar?.startTime2, 'HH:mm:ss')
                : null,
            endTime1:
              calendar && calendar?.endTime1
                ? dayjs(calendar?.endTime1, 'HH:mm:ss')
                : null,
            endTime2:
              calendar && calendar?.endTime2
                ? dayjs(calendar?.endTime2, 'HH:mm:ss')
                : null,
          };
        }
      }
      return temp;
    };
    const [calendar, shift] = data;

    result = {
      ...getResult(calendar, 'calendar'),
      ...getResult(shift, 'shift'),
    };
  } catch (err) {
    console.error('ERROR:', err);
  }

  return result;
};
