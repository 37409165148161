import { memo } from 'react';
import { IconButton, Stack } from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';
import BluTitle from './Title';
import BluContainerBlock from './ContainerBlock';

interface Props {
  showReload: boolean;
  text: string;
  onRefreshTap?: () => void;
}

/**
 * Funzione BludataDefaultMainHeader: renderizza l'intestazione predefinita per le pagine, con opzione di ricarica.
 *
 * @param {Props} props - Le proprietà passate al componente.
 * @param {boolean} props.showReload - Determina se mostrare o meno il bottone di ricarica.
 * @param {string} props.text - Il testo da visualizzare nell'intestazione.
 * @param {() => void} [props.onRefreshTap] - La funzione da eseguire quando si clicca sul bottone di ricarica.
 *
 * Questo componente è utile per fornire una coerenza visiva attraverso le pagine dell'applicazione,
 * offrendo un'intestazione standardizzata che include un titolo e, opzionalmente, un bottone di ricarica.
 *
 * Esempio di utilizzo:
 * <BludataDefaultMainHeader
 *   showReload={true}
 *   text="Pagina Principale"
 *   onRefreshTap={() => console.log('Pagina ricaricata')}
 * />
 *
 * In questo esempio, l'intestazione include un bottone di ricarica che, quando premuto,
 * stamperà un messaggio di log. Questo è particolarmente utile in pagine dove i dati
 * devono essere frequentemente aggiornati senza necessità di ricaricare l'intera pagina.
 */
function BludataDefaultMainHeader(props: Props) {
  const { showReload, text } = props;

  return (
    <BluContainerBlock
      sx={{
        margin: '4px',
        height: '46px',
        display: 'flex !important', // Forza il display a flex per allineare correttamente gli elementi interni
        maxHeight: '46px',
      }}
    >
      <Stack flex={1} flexDirection="row" alignItems="center">
        <BluTitle
          sx={{
            flex: 1, // Assegna tutto lo spazio disponibile al titolo, permettendo al bottone di ricarica di posizionarsi a destra
          }}
          text={text} // Visualizza il testo dell'intestazione
        />
        {showReload && ( // Se showReload è true, mostra il bottone di ricarica
          <IconButton color="primary" onClick={props.onRefreshTap}>
            <SyncIcon />
          </IconButton>
        )}
      </Stack>
    </BluContainerBlock>
  );
}

export default memo(BludataDefaultMainHeader);
