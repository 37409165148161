/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import axios from 'axios';
import { getAppointmentDateRange } from '../../pages/Calendars/utility_objects/AppointmentDateRange';

export const getPointOfSaleHoursShift = async (
  pointOfSaleId: string,
  selectedDate: Date,
  view: string
): Promise<any> => {
  try {
    const [startDate, endDate] = getAppointmentDateRange(selectedDate, view);
    const {
      data: { data },
    } = await axios.post(`/api/ShiftPointOfSale/${pointOfSaleId ?? ''}`, {
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
    });
    return data;
  } catch (err) {
    console.trace(err);
    return [];
  }
};

export const usePointOfSaleHoursShift = ({
  pointOfSaleId,
  selectedDate,
  view = 'Day',
  options = {},
  key = '',
}: {
  pointOfSaleId: string;
  selectedDate: Date;
  view?: string;
  options?: UseQueryOptions;
  key?: string; // Add this optional parameter to your useQuery hook.
}) => {
  return useQuery({
    queryFn: async () =>
      await getPointOfSaleHoursShift(pointOfSaleId, selectedDate, view),
    queryKey: [
      'point-of-sale-hours',
      'shift',
      pointOfSaleId,
      selectedDate,
      view,
      key,
    ],
    retry: 2,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    select: normalize,
    ...options,
  });
};

const normalize = (result: any) => {
  if (result && result.length > 0) {
    const data: any = {};
    for (const day of result) {
      data[day.date] = day.hours;
    }
    return data;
  }
  return {};
};
