import { FC, useCallback, useEffect } from 'react';
import {
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import { BluLoader, BluTitle } from '@bludata/components';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCompanyColorEnum,
  setCompanySettings,
  setIsManualAppointmentBlock,
  setIsSingleCalendarManagement,
  setVisibilityEnum,
} from '../../store/root';
import { useCompanySettings } from '../../api/company-settings';
import { AppDispatch, RootState } from '../../../../store';
import { setInitialCompanySettings } from '../../utils/white-cache';
import { CustomTooltip } from './tooltip';

interface GeneraliCompanySettingsInterface {
  disabled: boolean;
}

export const GeneraliCompanySettings: FC<GeneraliCompanySettingsInterface> = ({
  disabled,
}): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();

  const {
    companyColorEnum,
    visibilityEnum,
    isManualAppointmentBlock,
    isSingleCalendarManagement,
  } = useSelector((state: RootState) => state.generaliRoot);

  const { data, isLoading } = useCompanySettings();

  useEffect(() => {
    if (data) {
      dispatch(setCompanySettings(data));
      setInitialCompanySettings(data);
    }
  }, [data, dispatch]);

  const handleIsManualAppointmentBlockChange = useCallback(
    (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      dispatch(setIsManualAppointmentBlock(checked));
    },
    [dispatch]
  );

  const handleCompanyColorEnumChange = useCallback(
    (event: SelectChangeEvent) => {
      dispatch(setCompanyColorEnum(parseInt(event.target.value)));
    },
    [dispatch]
  );

  const handleVisibilityEnumChange = useCallback(
    (event: SelectChangeEvent) => {
      dispatch(setVisibilityEnum(parseInt(event.target.value)));
    },
    [dispatch]
  );

  const handleIsSingleCalendarManagementChange = useCallback(
    (event: SelectChangeEvent) => {
      dispatch(setIsSingleCalendarManagement(event.target.value === 'unico'));
    },
    [dispatch]
  );

  // ! isSingleCalendarManagement (unico turno o turno di lavoro e turno degli appuntamenti) true se unico turno da vedere come select
  // ! in caso di passaggio da turno di lavoro e turni degli appuntamenti a unico turno da chiedere se si vogliono utilizzare i turno di lavoro o quello degli appuntamenti (sia per punto vendita che per utente) DA FARE POPUP

  return (
    <>
      <BluLoader open={isLoading} />

      <Stack sx={{ pb: 2, width: '500px' }} gap={2}>
        <Stack>
          <BluTitle text={'Impostazioni agenda'} />
          <Stack direction="row" flex="1" gap={1} alignItems="center">
            <Switch
              disabled={disabled}
              checked={isManualAppointmentBlock}
              onChange={handleIsManualAppointmentBlockChange}
            />
            <Typography>Forza ricerca disponibilità</Typography>
            <CustomTooltip
              text={`Attivando questo flag, quando si creano appuntamenti dal calendario, sarà obbligatorio utilizzare la funzione di ricerca disponibilità. &nbsp;   
          Non sarà possibile creare liberamente appuntamenti senza prima aver verificato la disponibilità del partecipante o della risorsa.`}
            />
          </Stack>
        </Stack>

        {/* <BluTitle text={"Impostazione colore agenda"} /> */}
        <Stack>
          <Stack direction="row" flex="1">
            <Stack direction="row" flex="1">
              <Typography>
                Visualizzazione colore principale degli appuntamenti
              </Typography>
            </Stack>
            <CustomTooltip
              maxWidth="sm"
              text={`Questa opzione consente di personalizzare il colore degli appuntamenti nel calendario.  &nbsp;   
            È possibile selezionare il colore principale basato sul 'Servizio' o sull''Utente'.   
            Il colore scelto coprirà l'intero appuntamento, mentre una sottile barra perimetrale indicherà il colore dell'elemento non selezionato (Servizio o Utente, rispettivamente).  &nbsp;   
            Questa funzionalità facilita la distinzione visiva degli appuntamenti, migliorando l'organizzazione e la pianificazione.`}
            />
          </Stack>
          <Select
            value={companyColorEnum.toString()}
            onChange={handleCompanyColorEnumChange}
            sx={{ width: '100%', minWidth: '300px' }}
            disabled={disabled}
          >
            <MenuItem value={0}>Servizi</MenuItem>
            <MenuItem value={1}>Utenti</MenuItem>
          </Select>
        </Stack>

        <Stack>
          <Stack direction="row" flex="1">
            <Stack direction="row" flex="1">
              <InputLabel variant="standard">
                Visibilità default del calendario
              </InputLabel>
            </Stack>
            <CustomTooltip
              maxWidth="sm"
              text={`
Definisce la visualizzazione predefinita del calendario per gli utenti.
Le opzioni includono:   
* **'Punto vendita'**: visualizza solo gli appuntamenti specifici di una singola sede, ovvero quella associata all’utente.
* **'Azienda'**: mostra tutti gli appuntamenti relativi alla azienda associata all’utente; ovvero l’azienda di appartenenza del punto vendita associato.
* **'Layout'**: fornisce una vista personalizzata del calendario basata su impostazioni preselezionate.
* **'Tutte le aziende'**: aggrega e mostra gli appuntamenti di tutti i punti vendita nell'organizzazione.)
          `}
            />
          </Stack>

          <Select
            value={visibilityEnum.toString()}
            onChange={handleVisibilityEnumChange}
            disabled={disabled}
          >
            <MenuItem value={4}>Utente</MenuItem>
            <MenuItem value={0}>Punto vendita</MenuItem>
            <MenuItem value={1}>Azienda</MenuItem>
            <MenuItem value={2}>Layout</MenuItem>
            <MenuItem value={3}>Tutte le aziende</MenuItem>
          </Select>
        </Stack>

        <Stack>
          <Stack direction="row" flex="1">
            <Stack direction="row" flex="1">
              <Typography>Modalità calendario</Typography>
            </Stack>
            <CustomTooltip
              maxWidth="sm"
              text={`Il campo "Modalità calendario" permette di scegliere tra "Turno Unico" e "Turno Diviso".  
               In "Turno Unico", l'orario di lavoro e gli appuntamenti sono gli stessi, mentre in "Turno Diviso" si possono fissare appuntamenti in orari diversi dal turno di lavoro, offrendo flessibilità per gestire gli impegni.   `}
            />
          </Stack>

          <Select
            value={isSingleCalendarManagement ? 'unico' : 'multiplo'}
            onChange={handleIsSingleCalendarManagementChange}
            disabled={disabled}
          >
            <MenuItem value="unico">Turno unico</MenuItem>
            <MenuItem value="multiplo">Turno diviso</MenuItem>
          </Select>
        </Stack>
      </Stack>
    </>
  );
};
