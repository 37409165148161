import { PayloadAction } from '@reduxjs/toolkit';
import {
  PointOfSaleInterface,
  UserInterface,
} from 'packages/blu-booking/src/interfaces/Interfaces';
import { SelectedFiltersState } from '../interfaces/SelectedFilters';
import { ZoomItem } from '../interfaces/Zoom';

// ? Selected Date
export const setSelectedDate = (
  state: SelectedFiltersState,
  action: PayloadAction<Date>
) => {
  state.selectedDate = action.payload;
};

export const selectZoomValue = (
  state: SelectedFiltersState,
  action: PayloadAction<ZoomItem>
) => {
  state.zoomValue = action.payload.value;
  state.zoom = action.payload;
};

// ? ADD
export const selectPointOfSale = (
  state: SelectedFiltersState,
  action: PayloadAction<PointOfSaleInterface>
) => {
  state.selectedPointOfSale = action.payload;
  state.selectedStudi = [];
  state.selectedUsers = [];
  state.selectedServices = [];
};
export const selectService = (
  state: SelectedFiltersState,
  action: PayloadAction<string[]>
) => {
  state.selectedServices = action.payload;
};

export const selectStudio = (
  state: SelectedFiltersState,
  action: PayloadAction<string>
) => {
  state.selectedStudi.push(action.payload);
};

// ? REMOVE
export const removeStudio = (
  state: SelectedFiltersState,
  action: PayloadAction<string>
) => {
  const index = state.selectedStudi.indexOf(action.payload);
  if (index !== -1) {
    state.selectedStudi.splice(index, 1);
  }
};
export const removeService = (
  state: SelectedFiltersState,
  action: PayloadAction<string>
) => {
  const index = state.selectedServices.indexOf(action.payload);
  if (index !== -1) {
    state.selectedServices.splice(index, 1);
  }
};
export const removePointOfSale = (state: SelectedFiltersState) => {
  state.selectedPointOfSale = undefined;
};

export const setUser = (
  state: SelectedFiltersState,
  action: PayloadAction<UserInterface[]>
) => {
  state.selectedUsers = action.payload;
};

export const setClient = (
  state: SelectedFiltersState,
  action: PayloadAction<
    | {
        name: string;
        surname: string;
        email: string;
        telephone?: string;
      }
    | undefined
  >
) => {
  state.client = action.payload;
};

// ? RESET ONE FILTER
export const resetSelected = (
  state: SelectedFiltersState,
  action: PayloadAction<string>
) => {
  return { ...state, [action.payload]: [] };
};
