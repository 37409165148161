import axios from 'axios';

interface StudioAvailabilityInterface {
  pointOfSaleId: string;
  serviceId: string;
  startTime: string;
  endTime: string;
}

export const studioAvailability = async ({
  pointOfSaleId,
  serviceId,
  startTime,
  endTime,
}: StudioAvailabilityInterface): Promise<string> => {
  try {
    const {
      data: { data },
    } = await axios.post(`/api/Studio/Availability`, {
      pointOfSaleId,
      serviceId,
      startTime,
      endTime,
    });

    return data;
  } catch (error) {
    console.error(error);
    return '';
  }
};
