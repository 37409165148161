import { BluAsyncDialog, BluDialog, BluLoader } from '@bludata/components';
import {
  Button,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import { memo, useCallback, useState } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import { useQueryClient } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'packages/blu-booking/src/store';
import {
  setMode,
  setPopUpCalendarManagement,
  setCompanySettings,
} from '../store/GeneralRoot';
import { resetAndChangeHolidays } from '../store/Holiday';
import {
  getInitialHolidays,
  getInitialCompanySettings,
} from '../utility_objects/white-cache';
import { companySettingsManagement } from 'packages/blu-booking/src/data_fetching/CompanySettings/CompanySettingsManagement';

export const PopUpCalendarManagement = memo(() => {
  const [useShiftPointOfSale, setUseShiftPointOfSale] = useState<boolean>(true);
  const [useShiftUser, setUseShiftUser] = useState<boolean>(true);
  const queryClient = useQueryClient();
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(false);

  const {
    isSingleCalendarManagement,
    companyColorEnum,
    visibilityEnum,
    isManualAppointmentBlock,
  } = useSelector((state: RootState) => state.bluBookingGeneral);

  const { holidays, DeleteHolidays, ModifyHolidays } = useSelector(
    (state: RootState) => state.bluBookingHoliday
  );

  const handleUseShiftPointOfSaleChange = useCallback(
    (event: SelectChangeEvent) => {
      setUseShiftPointOfSale(event.target.value === 'shift');
    },
    []
  );

  const handleUseShiftUserChange = useCallback((event: SelectChangeEvent) => {
    setUseShiftUser(event.target.value === 'shift');
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      setLoading(true);
      const result = await companySettingsManagement({
        companySettings: {
          isSingleCalendarManagement,
          companyColorEnum,
          visibilityEnum,
          isManualAppointmentBlock,
        },
        holidays,
        DeleteHolidays,
        ModifyHolidays,
        useShiftPointOfSale,
        useShiftUser,
      });
      setLoading(false);
      try {
        (window as any).disableMenuBluBooking(false);
      } catch {
        /* empty */
      }
      dispatch(setMode('view'));
      dispatch(setPopUpCalendarManagement(false));

      if (result) {
        queryClient.invalidateQueries(['holidays']);
        queryClient.removeQueries(['holidays'], {
          type: 'inactive',
        });
        queryClient.invalidateQueries(['company-settings']);
        queryClient.removeQueries(['company-settings'], {
          type: 'inactive',
        });

        queryClient.invalidateQueries(['user', 'me', 'LoginData']);
        queryClient.removeQueries(['user', 'me', 'LoginData'], {
          type: 'inactive',
        });

        return;
      }
    } catch (e) {
      console.error(e);
    }

    BluAsyncDialog({
      title: 'Attenzione',
      type: 'error',
      message: 'Errore durante la modifica delle impostazioni.',
      sx: { '& .MuiDialog-paper': { maxWidth: '370px' } },
      hideDeclineButton: true,
      confimButton: 'Chiudi',
    });

    return;
  }, [
    DeleteHolidays,
    ModifyHolidays,
    companyColorEnum,
    dispatch,
    holidays,
    isManualAppointmentBlock,
    isSingleCalendarManagement,
    queryClient,
    useShiftPointOfSale,
    useShiftUser,
    visibilityEnum,
  ]);

  const handleCancel = useCallback(() => {
    dispatch(setMode('view'));

    dispatch(resetAndChangeHolidays(getInitialHolidays()));
    dispatch(setCompanySettings(getInitialCompanySettings()));
    dispatch(setPopUpCalendarManagement(false));

    try {
      (window as any).disableMenuBluBooking(false);
    } catch {
      /* empty */
    }
  }, [dispatch]);

  return (
    <BluDialog
      open
      zIndex={200}
      dialogTitle="Cambio Modalità Calendario"
      maxWidth="xs"
      fullScreen={false}
      dialogContent={
        <Stack gap={1} spacing={1}>
          <BluLoader open={loading} />
          <Typography>
            {`Se stai cambiando da "Turno Diviso" a "Turno Unico", seleziona quale orario desideri mantenere. Le opzioni sono:`}
          </Typography>
          <Typography>
            <li>
              Orari punti vendita: Utilizza gli orari impostati per i punti
              vendita come orario unico.
            </li>

            <li>
              Orari utenti: Preferisci gli orari di disponibilità degli utenti
              come orario unico.
            </li>
          </Typography>

          <Typography>
            {`Questa impostazione sincronizzerà gli orari di lavoro e di appuntamento, facendo sì che entrambi rispecchino l'orario selezionato. Questa scelta si applicherà universalmente sia ai punti vendita che agli utenti per garantire uniformità.`}
          </Typography>
          <Stack>
            <Typography>Orari punti vendita</Typography>
            <Select
              value={useShiftPointOfSale ? 'shift' : 'calendar'}
              onChange={handleUseShiftPointOfSaleChange}
              // sx={{ width: 300 }}
            >
              <MenuItem value="shift">Turno lavorativo</MenuItem>
              <MenuItem value="calendar">Turno appuntamento</MenuItem>
            </Select>
          </Stack>
          <Stack>
            <Typography>Orari utenti</Typography>
            <Select
              value={useShiftUser ? 'shift' : 'calendar'}
              onChange={handleUseShiftUserChange}
              // sx={{ width: 300 }}
            >
              <MenuItem value="shift">Turno lavorativo</MenuItem>
              <MenuItem value="calendar">Turno appuntamento</MenuItem>
            </Select>
          </Stack>
        </Stack>
      }
      dialogActions={
        <Stack flex="1" flexDirection="row" gap="10px" justifyContent="end">
          <Button variant="outlined" onClick={handleCancel}>
            Annulla
          </Button>
          <Button
            startIcon={<SaveIcon />}
            variant="contained"
            // disabled={getDisableButton()}
            onClick={handleSubmit}
          >
            Salva
          </Button>
        </Stack>
      }
    />
  );
});
