import { FC, memo, useCallback, useMemo, useState } from 'react';
import { Dayjs } from 'dayjs';
import {
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { BluDatePicker, BluTimePicker } from '@bludata/components';
import { useDispatch } from 'react-redux';
import { AppDispatch, useAppSelector } from '../../../../../../store';
import {
  extraHourValueError,
  setExtraHourValue,
} from '../../../../store/extra-hours';
import { getUsersWhiteCache } from '../../../../lib/white-cache';
import { GridCloseIcon } from '@mui/x-data-grid-premium';
import { bluBookingLoginData } from '../../../../../../store/slices/root';

interface EditRowInterface {
  row: any;
}

export const EditRow: FC<EditRowInterface> = memo(({ row }) => {
  const { pointOfSaleId, extraKey } = row;
  const dispatch: AppDispatch = useDispatch();
  const { isSingleCalendarManagement } = useAppSelector(bluBookingLoginData);

  const [startDate, setStartDate] = useState<Dayjs | null>(
    row.startDate || null
  );
  const [endDate, setEndDate] = useState<Dayjs | null>(row.endDate || null);

  const [calendarTypeEnum, setCalendarTypeEnum] = useState<number>(
    row.extraCalendarType || 0
  );
  const [shiftStartTime1, setShiftStartTime1] = useState<Dayjs | null>(
    row.shift?.startTime1 || null
  );
  const [shiftEndTime1, setShiftEndTime1] = useState<Dayjs | null>(
    row.shift?.endTime1 || null
  );
  const [shiftStartTime2, setShiftStartTime2] = useState<Dayjs | null>(
    row.shift?.startTime2 || null
  );
  const [shiftEndTime2, setShiftEndTime2] = useState<Dayjs | null>(
    row.shift?.endTime2 || null
  );

  const [calendarStartTime1, setCalendarStartTime1] = useState<Dayjs | null>(
    row.calendar?.startTime1 || null
  );
  const [calendarEndTime1, setCalendarEndTime1] = useState<Dayjs | null>(
    row.calendar?.endTime1 || null
  );
  const [calendarStartTime2, setCalendarStartTime2] = useState<Dayjs | null>(
    row.calendar?.startTime2 || null
  );
  const [calendarEndTime2, setCalendarEndTime2] = useState<Dayjs | null>(
    row.calendar?.endTime2 || null
  );

  const errorShift1 = useAppSelector((state) =>
    extraHourValueError(state, 'shift', extraKey, 1, pointOfSaleId)
  );
  const errorShift2 = useAppSelector((state) =>
    extraHourValueError(state, 'shift', extraKey, 2, pointOfSaleId)
  );

  const errorCalendar1 = useAppSelector((state) =>
    extraHourValueError(state, 'calendar', extraKey, 1, pointOfSaleId)
  );
  const errorCalendar2 = useAppSelector((state) =>
    extraHourValueError(state, 'calendar', extraKey, 2, pointOfSaleId)
  );

  const handleCalendarTypeEnumChange = useCallback(
    (event: SelectChangeEvent) => {
      setCalendarTypeEnum(parseInt(event.target.value));
      const temp: any = {
        ...row,
        shift: {
          ...row.shift,
        },
        calendar: {
          ...row.calendar,
        },
      };

      temp.extraCalendarType = parseInt(event.target.value);

      dispatch(
        setExtraHourValue({
          value: temp,
          keyChanged: extraKey,
          pointOfSaleId,
        })
      );
    },
    [dispatch, extraKey, pointOfSaleId, row]
  );

  const handleDateChange = useCallback(
    (newValue: any, properties: 'start' | 'end'): void => {
      if (properties === 'start') {
        setStartDate(newValue);
        if (
          newValue !== null &&
          newValue.isValid() &&
          newValue.isAfter(endDate)
        ) {
          setEndDate(newValue);
        }
      } else setEndDate(newValue);

      if (newValue && newValue.isValid()) {
        const temp: any = { ...row };

        if (properties === 'start') {
          temp.startDate = newValue;
          if (newValue.isAfter(temp.endDate)) temp.endDate = newValue;
        } else temp.endDate = newValue;

        dispatch(
          setExtraHourValue({
            value: temp,
            keyChanged: extraKey,
            pointOfSaleId,
          })
        );
      }
    },
    [dispatch, endDate, extraKey, pointOfSaleId, row]
  );

  const handleTimeChange = useCallback(
    (
      newValue: any,
      setTime: (value: any) => void,
      calendarType: 'shift' | 'calendar',
      name: string
    ): void => {
      setTime(newValue);

      const temp: any = {
        ...row,
        shift: {
          ...row.shift,
        },
        calendar: {
          ...row.calendar,
        },
      };
      temp[calendarType][name] =
        newValue && newValue.isValid() ? newValue : null;

      dispatch(
        setExtraHourValue({
          value: { ...temp },
          keyChanged: extraKey,
          pointOfSaleId,
          turniType: calendarType,
        })
      );
    },
    [dispatch, extraKey, pointOfSaleId, row]
  );

  const GetShift = useMemo(() => {
    return (
      <Stack flex="1">
        <Typography variant="subtitle1" fontWeight="bold">
          {isSingleCalendarManagement ? 'Orari' : 'Orari di lavoro'}
        </Typography>

        <Stack flex="1" sx={{ p: '0 8px' }} gap={0.5}>
          <Stack flex="1" direction="row" gap={1}>
            <Stack flex="1">
              <InputLabel>Primo turno</InputLabel>

              <Stack flex="1" direction="row" gap={1}>
                <Stack flex="1">
                  <BluTimePicker
                    key={'shiftStartTime1'}
                    clearable
                    value={shiftStartTime1}
                    onChange={(newValue: any) =>
                      handleTimeChange(
                        newValue,
                        setShiftStartTime1,
                        'shift',
                        'startTime1'
                      )
                    }
                    renderInput={(params: any) => {
                      if (
                        params.inputProps.value !== '' &&
                        params.inputProps.value.length > 1 &&
                        shiftStartTime1 === null
                      )
                        params.inputProps.value = '';
                      return (
                        <TextField
                          {...params}
                          error={errorShift1}
                          sx={{
                            '& .MuiInputAdornment-root': {
                              ml: 0,
                              width: '25px',
                              height: '25px',
                            },
                            '& .MuiIconButton-root': {
                              width: '25px',
                              height: '25px',
                              p: 0,
                            },
                          }}
                          InputProps={{
                            endAdornment: (
                              <Stack direction="row">
                                {shiftStartTime1 !== null &&
                                  shiftStartTime1.isValid() && (
                                    <IconButton
                                      // sx={{ height: "25px", width: "25px" }}
                                      onClick={() => {
                                        handleTimeChange(
                                          null,
                                          setShiftStartTime1,
                                          'shift',
                                          'startTime1'
                                        );
                                      }}
                                    >
                                      <GridCloseIcon />
                                    </IconButton>
                                  )}
                                {params?.InputProps?.endAdornment as any}
                              </Stack>
                            ),
                          }}
                        />
                      );
                    }}
                  />
                </Stack>
                <Stack flex="1">
                  <BluTimePicker
                    key="shiftEndTime1"
                    value={shiftEndTime1}
                    onChange={(newValue: any) =>
                      handleTimeChange(
                        newValue,
                        setShiftEndTime1,
                        'shift',
                        'endTime1'
                      )
                    }
                    renderInput={(params: any) => {
                      if (
                        params.inputProps.value !== '' &&
                        params.inputProps.value.length > 1 &&
                        shiftEndTime1 === null
                      )
                        params.inputProps.value = '';
                      return (
                        <TextField
                          {...params}
                          error={errorShift1}
                          sx={{
                            '& .MuiInputAdornment-root': {
                              ml: 0,
                              width: '25px',
                              height: '25px',
                            },
                            '& .MuiIconButton-root': {
                              width: '25px',
                              height: '25px',
                              p: 0,
                            },
                          }}
                          InputProps={{
                            endAdornment: (
                              <Stack direction="row">
                                {shiftEndTime1 !== null &&
                                  shiftEndTime1.isValid() && (
                                    <IconButton
                                      // sx={{ height: "25px", width: "25px" }}
                                      onClick={() => {
                                        handleTimeChange(
                                          null,
                                          setShiftEndTime1,
                                          'shift',
                                          'endTime1'
                                        );
                                      }}
                                    >
                                      <GridCloseIcon />
                                    </IconButton>
                                  )}
                                {params?.InputProps?.endAdornment as any}
                              </Stack>
                            ),
                          }}
                        />
                      );
                    }}
                  />
                </Stack>
              </Stack>
            </Stack>

            <Stack flex="1">
              <InputLabel>Secondo turno</InputLabel>
              <Stack flex="1" direction="row" gap={1}>
                <Stack flex="1">
                  <BluTimePicker
                    key="shiftStartTime2"
                    value={shiftStartTime2}
                    onChange={(newValue: any) =>
                      handleTimeChange(
                        newValue,
                        setShiftStartTime2,
                        'shift',
                        'startTime2'
                      )
                    }
                    renderInput={(params: any) => {
                      if (
                        params.inputProps.value !== '' &&
                        params.inputProps.value.length > 1 &&
                        shiftStartTime2 === null
                      )
                        params.inputProps.value = '';
                      return (
                        <TextField
                          {...params}
                          error={errorShift2}
                          sx={{
                            '& .MuiInputAdornment-root': {
                              ml: 0,
                              width: '25px',
                              height: '25px',
                            },
                            '& .MuiIconButton-root': {
                              width: '25px',
                              height: '25px',
                              p: 0,
                            },
                          }}
                          InputProps={{
                            endAdornment: (
                              <Stack direction="row">
                                {shiftStartTime2 !== null &&
                                  shiftStartTime2.isValid() && (
                                    <IconButton
                                      // sx={{ height: "25px", width: "25px" }}
                                      onClick={() => {
                                        handleTimeChange(
                                          null,
                                          setShiftStartTime2,
                                          'shift',
                                          'startTime2'
                                        );
                                      }}
                                    >
                                      <GridCloseIcon />
                                    </IconButton>
                                  )}
                                {params?.InputProps?.endAdornment as any}
                              </Stack>
                            ),
                          }}
                        />
                      );
                    }}
                  />
                </Stack>
                <Stack flex="1">
                  <BluTimePicker
                    key="shiftEndTime2"
                    value={shiftEndTime2}
                    onChange={(newValue: any) =>
                      handleTimeChange(
                        newValue,
                        setShiftEndTime2,
                        'shift',
                        'endTime2'
                      )
                    }
                    renderInput={(params: any) => {
                      if (
                        params.inputProps.value !== '' &&
                        params.inputProps.value.length > 1 &&
                        shiftEndTime2 === null
                      )
                        params.inputProps.value = '';
                      return (
                        <TextField
                          {...params}
                          error={errorShift2}
                          sx={{
                            '& .MuiInputAdornment-root': {
                              ml: 0,
                              width: '25px',
                              height: '25px',
                            },
                            '& .MuiIconButton-root': {
                              width: '25px',
                              height: '25px',
                              p: 0,
                            },
                          }}
                          InputProps={{
                            endAdornment: (
                              <Stack direction="row">
                                {shiftEndTime2 !== null &&
                                  shiftEndTime2.isValid() && (
                                    <IconButton
                                      // sx={{ height: "25px", width: "25px" }}
                                      onClick={() => {
                                        handleTimeChange(
                                          null,
                                          setShiftEndTime2,
                                          'shift',
                                          'endTime2'
                                        );
                                      }}
                                    >
                                      <GridCloseIcon />
                                    </IconButton>
                                  )}
                                {params?.InputProps?.endAdornment as any}
                              </Stack>
                            ),
                          }}
                        />
                      );
                    }}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    );
  }, [
    errorShift1,
    errorShift2,
    handleTimeChange,
    isSingleCalendarManagement,
    shiftEndTime1,
    shiftEndTime2,
    shiftStartTime1,
    shiftStartTime2,
  ]);

  const GetCalendar = useMemo(() => {
    if (isSingleCalendarManagement) return null;

    return (
      <Stack flex="1">
        <Typography variant="subtitle1" fontWeight="bold">
          Orari appuntamento
        </Typography>
        <Stack flex="1" sx={{ p: '0 8px' }} gap={0.5}>
          <Stack flex="1" direction="row" gap={1}>
            <Stack flex="1">
              <InputLabel>Primo turno</InputLabel>

              <Stack flex="1" direction="row" gap={1}>
                <Stack flex="1">
                  <BluTimePicker
                    value={calendarStartTime1}
                    onChange={(newValue: any) =>
                      handleTimeChange(
                        newValue,
                        setCalendarStartTime1,
                        'calendar',
                        'startTime1'
                      )
                    }
                    renderInput={(params: any) => {
                      if (
                        params.inputProps.value !== '' &&
                        params.inputProps.value.length > 1 &&
                        calendarStartTime1 === null
                      )
                        params.inputProps.value = '';
                      return (
                        <TextField
                          {...params}
                          error={errorCalendar1}
                          sx={{
                            '& .MuiInputAdornment-root': {
                              ml: 0,
                              width: '25px',
                              height: '25px',
                            },
                            '& .MuiIconButton-root': {
                              width: '25px',
                              height: '25px',
                              p: 0,
                            },
                          }}
                          InputProps={{
                            endAdornment: (
                              <Stack direction="row">
                                {calendarStartTime1 !== null &&
                                  calendarStartTime1.isValid() && (
                                    <IconButton
                                      // sx={{ height: "25px", width: "25px" }}
                                      onClick={() => {
                                        handleTimeChange(
                                          null,
                                          setCalendarStartTime1,
                                          'calendar',
                                          'startTime1'
                                        );
                                      }}
                                    >
                                      <GridCloseIcon />
                                    </IconButton>
                                  )}
                                {params?.InputProps?.endAdornment as any}
                              </Stack>
                            ),
                          }}
                        />
                      );
                    }}
                  />
                </Stack>
                <Stack flex="1">
                  <BluTimePicker
                    value={calendarEndTime1}
                    onChange={(newValue: any) =>
                      handleTimeChange(
                        newValue,
                        setCalendarEndTime1,
                        'calendar',
                        'endTime1'
                      )
                    }
                    renderInput={(params: any) => {
                      if (
                        params.inputProps.value !== '' &&
                        params.inputProps.value.length > 1 &&
                        calendarEndTime1 === null
                      )
                        params.inputProps.value = '';
                      return (
                        <TextField
                          {...params}
                          error={errorCalendar1}
                          sx={{
                            '& .MuiInputAdornment-root': {
                              ml: 0,
                              width: '25px',
                              height: '25px',
                            },
                            '& .MuiIconButton-root': {
                              width: '25px',
                              height: '25px',
                              p: 0,
                            },
                          }}
                          InputProps={{
                            endAdornment: (
                              <Stack direction="row">
                                {calendarEndTime1 !== null &&
                                  calendarEndTime1.isValid() && (
                                    <IconButton
                                      // sx={{ height: "25px", width: "25px" }}
                                      onClick={() => {
                                        handleTimeChange(
                                          null,
                                          setCalendarEndTime1,
                                          'calendar',
                                          'endTime1'
                                        );
                                      }}
                                    >
                                      <GridCloseIcon />
                                    </IconButton>
                                  )}
                                {params?.InputProps?.endAdornment as any}
                              </Stack>
                            ),
                          }}
                        />
                      );
                    }}
                  />
                </Stack>
              </Stack>
            </Stack>
            <Stack flex="1">
              <InputLabel>Secondo turno</InputLabel>
              <Stack flex="1" direction="row" gap={1}>
                <Stack flex="1">
                  <BluTimePicker
                    value={calendarStartTime2}
                    onChange={(newValue: any) =>
                      handleTimeChange(
                        newValue,
                        setCalendarStartTime2,
                        'calendar',
                        'startTime2'
                      )
                    }
                    renderInput={(params: any) => {
                      if (
                        params.inputProps.value !== '' &&
                        params.inputProps.value.length > 1 &&
                        calendarStartTime2 === null
                      )
                        params.inputProps.value = '';
                      return (
                        <TextField
                          {...params}
                          error={errorCalendar2}
                          sx={{
                            '& .MuiInputAdornment-root': {
                              ml: 0,
                              width: '25px',
                              height: '25px',
                            },
                            '& .MuiIconButton-root': {
                              width: '25px',
                              height: '25px',
                              p: 0,
                            },
                          }}
                          InputProps={{
                            endAdornment: (
                              <Stack direction="row">
                                {calendarStartTime2 !== null &&
                                  calendarStartTime2.isValid() && (
                                    <IconButton
                                      // sx={{ height: "25px", width: "25px" }}
                                      onClick={() => {
                                        handleTimeChange(
                                          null,
                                          setCalendarStartTime2,
                                          'calendar',
                                          'startTime2'
                                        );
                                      }}
                                    >
                                      <GridCloseIcon />
                                    </IconButton>
                                  )}
                                {params?.InputProps?.endAdornment as any}
                              </Stack>
                            ),
                          }}
                        />
                      );
                    }}
                  />
                </Stack>
                <Stack flex="1">
                  <BluTimePicker
                    value={calendarEndTime2}
                    onChange={(newValue: any) =>
                      handleTimeChange(
                        newValue,
                        setCalendarEndTime2,
                        'calendar',
                        'endTime2'
                      )
                    }
                    renderInput={(params: any) => {
                      if (
                        params.inputProps.value !== '' &&
                        params.inputProps.value.length > 1 &&
                        calendarEndTime2 === null
                      )
                        params.inputProps.value = '';
                      return (
                        <TextField
                          {...params}
                          error={errorCalendar2}
                          sx={{
                            '& .MuiInputAdornment-root': {
                              ml: 0,
                              width: '25px',
                              height: '25px',
                            },
                            '& .MuiIconButton-root': {
                              width: '25px',
                              height: '25px',
                              p: 0,
                            },
                          }}
                          InputProps={{
                            endAdornment: (
                              <Stack direction="row">
                                {calendarEndTime2 !== null &&
                                  calendarEndTime2.isValid() && (
                                    <IconButton
                                      // sx={{ height: "25px", width: "25px" }}
                                      onClick={() => {
                                        handleTimeChange(
                                          null,
                                          setCalendarEndTime2,
                                          'calendar',
                                          'endTime2'
                                        );
                                      }}
                                    >
                                      <GridCloseIcon />
                                    </IconButton>
                                  )}
                                {params?.InputProps?.endAdornment as any}
                              </Stack>
                            ),
                          }}
                        />
                      );
                    }}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    );
  }, [
    calendarEndTime1,
    calendarEndTime2,
    calendarStartTime1,
    calendarStartTime2,
    errorCalendar1,
    errorCalendar2,
    handleTimeChange,
    isSingleCalendarManagement,
  ]);

  return (
    <Stack
      flex="1"
      sx={{
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        height: '100%',
        boxSizing: 'border-box',
        p: 1,
      }}
      gap={1}
    >
      <Stack flex="1" direction="row" gap={1}>
        <Stack flex="1">
          <InputLabel>Data inizio</InputLabel>
          <BluDatePicker
            value={startDate}
            onChange={(newValue: any) => handleDateChange(newValue, 'start')}
          />
        </Stack>
        <Stack flex="1">
          <InputLabel>Data fine</InputLabel>
          <BluDatePicker
            value={endDate}
            onChange={(newValue: any) => handleDateChange(newValue, 'end')}
          />
        </Stack>
      </Stack>
      <Stack flex="1">
        <InputLabel>Motivazione</InputLabel>
        <Select
          value={calendarTypeEnum.toString()}
          onChange={handleCalendarTypeEnumChange}
          sx={{ width: '100%' }}
        >
          <MenuItem value={0}>Altro</MenuItem>
          <MenuItem value={1}>Permesso</MenuItem>
          <MenuItem value={2}>Malattia</MenuItem>
          <MenuItem value={3}>Ferie</MenuItem>
        </Select>
      </Stack>
      {GetShift}
      {GetCalendar}
    </Stack>
  );
});
