import { FC, memo } from 'react';
import axios from 'axios';
import { Provider } from 'react-redux';
import { store } from '../../store';
import { LicenseInfo } from '@mui/x-license-pro';
import { Stack } from '@mui/material';
import jwt_decode from 'jwt-decode';
import { RotaPage } from './page';
import { setKeyRotaWhiteCache } from './lib/white-cache';
import { InitData } from '../../util/init-data';
import { MUI_license } from '@bludata/components';

interface NuovaRotaInterface {
  token?: string;
  url?: string;
  focusWeb?: boolean;
  focus10?: boolean;
  root?: string;
  isBluBookingActive?: boolean;
  pointOfSaleId?: string;
  isAdmin: boolean;
  isConsole?: boolean;
  isSingleCalendarManagement?: boolean;
}

LicenseInfo.setLicenseKey(MUI_license);

const NuovaRota: FC<NuovaRotaInterface> = memo(
  ({
    url,
    token,
    root,
    isBluBookingActive,
    isAdmin,
    pointOfSaleId,
    focusWeb = false,
    focus10 = false,
    isConsole = false,
    isSingleCalendarManagement,
  }) => {
    if (url !== undefined) axios.defaults.baseURL = url;

    let bearerToken: string | undefined;
    if (token !== undefined) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      bearerToken = token;
    } else if (
      axios.defaults.headers.common['Authorization'] !== null &&
      axios.defaults.headers.common['Authorization'] !== undefined
    ) {
      bearerToken = (
        axios.defaults.headers.common['Authorization'] as string
      ).replace('Bearer ', '');
    }

    setKeyRotaWhiteCache('isFocus10', focus10);
    setKeyRotaWhiteCache('isFocusWeb', focusWeb);

    let accessToken: any;
    try {
      if (bearerToken !== undefined) accessToken = jwt_decode(bearerToken);

      if (isBluBookingActive === undefined && accessToken !== undefined)
        setKeyRotaWhiteCache(
          'isBluBookingActive',
          accessToken?.IsBluBookingActive === 'True'
        );
      else setKeyRotaWhiteCache('isBluBookingActive', isBluBookingActive);
    } catch {}

    // try {
    //   if (pointOfSaleId) {
    //     setKeyRotaWhiteCache("pointOfSaleId", pointOfSaleId);
    //     store.dispatch(setPointOfSaleId(pointOfSaleId));
    //   } else {
    //     const sessionFiliale =
    //       sessionStorage.getItem("sessionFiliale") || undefined;
    //     setKeyRotaWhiteCache("pointOfSaleId", sessionFiliale);
    //     store.dispatch(setPointOfSaleId(sessionFiliale));
    //   }
    // } catch {}

    let updateTurni = true;
    // let isSingleCalendar = true;
    try {
      // if (isSingleCalendarManagement !== undefined)
      //   isSingleCalendar = isSingleCalendarManagement;
      // else {
      //   const jsonSessionProfilo = JSON.parse(
      //     sessionStorage.getItem("sessionData") || "{}"
      //   );
      //   isSingleCalendar =
      //     jsonSessionProfilo?.isSingleCalendarManagement ?? true;
      // }
      if (isConsole) {
        updateTurni = isAdmin ?? true;
      } else {
        const jsonSessionProfilo = JSON.parse(
          sessionStorage.getItem('sessionData') || '{}'
        );
        updateTurni = jsonSessionProfilo?.turniModifica ?? true;
      }
      setKeyRotaWhiteCache('turniModifica', updateTurni);
    } catch {}

    try {
      if (focusWeb) setKeyRotaWhiteCache('path', '/diaryapi');
    } catch {}

    return (
      <Provider store={store}>
        <Stack
          flex="1"
          style={{
            height: '100%',
            width: '100%',
            backgroundColor: 'var(--hovered-background-color)',
          }}
        >
          <InitData
            isSingleCalendarManagement={isSingleCalendarManagement}
            isFocusWeb={focusWeb}
          />
          {/* <InitServiceData /> */}
          <RotaPage root={root} updateTurni={updateTurni} />
        </Stack>
      </Provider>
    );
  }
);

export default NuovaRota;
