export const getTablePaginationRequestProps = (
  page: number,
  pageSize: number
) => {
  return {
    page: page,
    start: page * pageSize,
    limit: pageSize,
  };
};
