/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import 'dayjs/locale/it';
import {
  PointOfSaleInterface,
  StudioInterface,
  UserInterface,
  ServiceInterface,
  ShopSignInterface,
} from 'packages/blu-booking/src/interfaces/Interfaces';

export interface FilterObj {
  keys: string[];
  values: {
    [x: string]:
      | PointOfSaleInterface
      | StudioInterface
      | UserInterface
      | ServiceInterface
      | ShopSignInterface;
  };
}
export interface FiltersState {
  pointOfSales: {
    [x: string]: PointOfSaleInterface;
  };
  services: {
    [x: string]: ServiceInterface;
  };
  studi: {
    [x: string]: StudioInterface;
  };
  users: {
    [x: string]: UserInterface;
  };
  shopSigns: {
    [x: string]: ShopSignInterface;
  };
  requestState: string;
  filtersLoad: boolean;
}

const initialState: FiltersState = {
  pointOfSales: {},
  services: {},
  studi: {},
  users: {},
  shopSigns: {},
  requestState: 'loading',
  filtersLoad: false,
};

const FiltersSlice = createSlice({
  name: 'BluBookingCalendarFilters',
  initialState,
  reducers: {
    setFilter(
      state: FiltersState,
      action: PayloadAction<{
        key: keyof FiltersState;
        value: any;
      }>
    ) {
      state = {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    },
    setInitFilters(
      state: FiltersState,
      action: PayloadAction<{
        services: any;
        pointOfSales: any;
        studi: any;
        users: any;
        shopSigns: any;
        requestState: string;
        filtersLoad: boolean;
      }>
    ) {
      console.log('action.payload', action.payload);
      return {
        ...state,
        ...action.payload,
      };
    },
    setRequestState(state: FiltersState, action: PayloadAction<string>) {
      state.requestState = action.payload;
    },
    setFiltersLoad(state: FiltersState, action: PayloadAction<boolean>) {
      state.filtersLoad = action.payload;
    },
    resetFilters: () => initialState,
  },
});

export const {
  setInitFilters,
  setFilter,
  setRequestState,
  setFiltersLoad,
  resetFilters,
} = FiltersSlice.actions;

export default FiltersSlice.reducer;
