import { Menu, MenuItem, Stack, SvgIcon } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { memo } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useDispatch, useSelector } from 'react-redux';
import { nanoid } from '@reduxjs/toolkit';
import { Appointment_interface } from 'packages/blu-booking/src/interfaces/Appointment';
import { RootState, AppDispatch } from 'packages/blu-booking/src/store';
import { setOpenLoader } from 'packages/blu-booking/src/store/root';

import { setRefetch } from '../../store/Appointment';
import { setRightClick, setEmailPopup } from '../../store/Calendar';
import { appointmentStatus } from '../../utility_objects/AppointmentStatus';
import { appointmentUpdate } from 'packages/blu-booking/src/data_fetching/Appointment/AppointmentUpdate';
import { getStatusIcon } from '../../utility_objects/StatusIcon';

export const SubMenu = memo(() => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [hover, setHover] = useState(false);
  const [hoverParent, setHoverParent] = useState(false);

  const status = { ...appointmentStatus };

  const { rightClick } = useSelector(
    (state: RootState) => state.bluBookingCalendars
  );
  const { appointments } = useSelector(
    (state: RootState) => state.bluBookingaAppointments
  );
  const dispatch = useDispatch<AppDispatch>();

  const CloseAll = useCallback((): void => {
    setAnchorEl(null);
    dispatch(setRightClick(undefined));
  }, [dispatch]);

  const handleClick = useCallback(
    async (status: string): Promise<void> => {
      CloseAll();

      if (
        rightClick?.status !== undefined &&
        status !== rightClick?.status.toString()
      ) {
        const findIndex: number = appointments.findIndex(
          (app) => app.Id === rightClick.Id
        );

        if (findIndex === -1) return;

        if (rightClick?.status !== 0) {
          dispatch(setOpenLoader(true));
          const result = await appointmentUpdate(rightClick?.id ?? '', {
            ...rightClick,
            hasToSendEmail: false,
            timeSlotDate: rightClick.StartTime,
            start: rightClick.StartTime,
            end: rightClick.EndTime,
            status: parseInt(status),
          });
          dispatch(setOpenLoader(false));
          if (result) {
            dispatch(setRefetch());
          }
          return;
        }

        dispatch(
          setEmailPopup({
            id: rightClick.id,
            emailSet:
              rightClick?.status === 1 &&
              rightClick.email !== undefined &&
              rightClick.email !== '',
            type: 'modify',
            appointment: {
              ...rightClick,
              status: parseInt(status),
            } as Appointment_interface,
            findIndex: findIndex,
            prevData: rightClick as Appointment_interface,
          })
        );
      }
    },
    [CloseAll, appointments, dispatch, rightClick]
  );

  const handleHover = useCallback(() => {
    setHover(true);
  }, []);

  const handleHoverParent = useCallback(
    (event: any) => {
      if (anchorEl !== event.currentTarget) setAnchorEl(event.currentTarget);
      setHoverParent(true);
    },
    [anchorEl]
  );

  const handleCloseHover = useCallback(() => {
    setHover(false);
  }, []);

  const handleParentCloseHover = useCallback(async () => {
    setHoverParent(false);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleCloseMenu = useCallback(() => {
    if (!hover && !hoverParent) handleClose();
  }, [handleClose, hover, hoverParent]);

  useEffect(() => {
    const timer = setTimeout(() => {
      handleCloseMenu();
    }, 50);
    return () => clearTimeout(timer);
  }, [handleCloseMenu, hover, hoverParent]);

  return (
    <>
      <MenuItem
        key="change-state"
        value="change-state"
        style={{
          paddingTop: '2px',
          paddingBottom: '2px',
          width: '180px',
        }}
        onMouseEnter={handleHoverParent}
        onMouseLeave={handleParentCloseHover}
      >
        <Stack direction="row" flex="1">
          Cambia stato
        </Stack>
        <ArrowForwardIosIcon sx={{ fontSize: '15px' }} />
      </MenuItem>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onMouseEnter={handleHover}
        onMouseLeave={handleCloseHover}
        MenuListProps={{
          style: { pointerEvents: 'auto' },
        }}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        sx={{
          pointerEvents: 'none',
        }}
      >
        {Object.keys(status).map((key: any) => {
          return (
            <MenuItem
              key={nanoid()}
              value={key}
              selected={
                rightClick?.status !== undefined &&
                key === rightClick?.status.toString()
              }
              onClick={() => handleClick(key)}
              disabled={key === '0' && rightClick?.status !== 0}
            >
              <Stack flex="1" direction="row" gap={2}>
                <SvgIcon
                  component={getStatusIcon(key)}
                  sx={{
                    fontSize: '18px',
                    pt: '2px',
                    pb: '1px',
                  }}
                />
                {status[key]}
              </Stack>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
});
