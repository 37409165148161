import axios from 'axios';
import { AppDispatch, store } from '../../store';
import {
  Appointment_interface,
  Appointment_server_interface,
} from '../../interfaces/Interfaces';
import {
  setAppointments,
  setLoadingAppointments,
} from '../../pages/Calendars/store/Appointment';
import { mapColor, usersMapColor } from '../../utility_objects/ColorManager';
import { getAppointmentDateRange } from '../../pages/Calendars/utility_objects/AppointmentDateRange';
import dayjs from 'dayjs';

interface fetchAppointmentsParams {
  selectedDate: Date;
  checkedServices: string[];
  checkedStudi: string[];
  checkedUsers: string[];
  checkedPointOfSales: string[];
  view?: string;
  externalCustomerCode?: string;
  email?: string;
  isTabClient?: boolean;
}

export const fetchAppointments = ({
  selectedDate,
  checkedServices: serviceIds,
  checkedStudi: studioIds,
  checkedUsers: userIds,
  checkedPointOfSales: pointOfSaleIds,
  view = 'Day',
  email = '',
  externalCustomerCode,
  isTabClient = false,
}: fetchAppointmentsParams) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoadingAppointments(true));
    let startDate, endDate;
    if (!isTabClient)
      [startDate, endDate] = getAppointmentDateRange(selectedDate, view);
    else {
      startDate = dayjs(selectedDate).format('YYYY-MM-DD');
      endDate = dayjs(selectedDate)
        .endOf('month')
        .add(1, 'day')
        .format('YYYY-MM-DD');
    }
    const { loginData } = store.getState().bluBookingRoot;
    const { companyColorEnum: companyColor } = loginData ?? {};
    try {
      const {
        data: { data },
      } = await axios.post<{
        data: Appointment_server_interface[];
      }>('/api/Appointment/GetAll', {
        startDate,
        endDate,
        serviceIds,
        studioIds,
        userIds,
        pointOfSaleIds,
        email: email !== '' ? email : undefined,
        externalCustomerCode,
      });
      const result: Appointment_interface[] = data
        .map((element, index: number) => {
          let checkColor = element?.color;
          if (checkColor === null || checkColor === undefined) {
            if (companyColor) checkColor = usersMapColor[element.userId];
            else checkColor = mapColor[element.serviceId];
          }
          return {
            ...element,
            Id: element.id,
            Subject: `${element.surname || ''} ${element.name || ''} `,
            Location: 'Bludata informatica S.r.l.',
            StartTime: element.startTime,
            EndTime: element.endTime,
            CategoryColor: index % 2 === 0 ? '#5e3c58' : '#009246',
            color: checkColor,
          };
        })
        .flat();
      dispatch(setLoadingAppointments(false));
      dispatch(setAppointments(result));
    } catch (error) {
      console.error(error);
      dispatch(setLoadingAppointments(false));
      dispatch(setAppointments([]));
    }
  };
};
