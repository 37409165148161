import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Divider,
  Stack,
  DialogActions,
  Button,
  Box,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { FC, memo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { nanoid } from 'nanoid';
import {
  BluAsyncDialog,
  BluAutoComplete,
  BluLoader,
} from '@bludata/components';
import { AppDispatch, RootState } from '../../../../../../store';
import {
  setDefaultHours,
  setImportDefaultHoursPopUp,
} from '../../../../store/default-hours';
import { getImportTurniShiftAndCalendarPointOfSale } from '../../../../api/import-turni-default-point-of-sale';
import { getUsersWhiteCache } from '../../../../lib/white-cache';

interface ImportDefaultHoursInterface {
  pointOfSaleId: string;
  isSingleCalendarManagement: boolean;
}

export const ImportDefaultHours: FC<ImportDefaultHoursInterface> = memo(
  ({ isSingleCalendarManagement, pointOfSaleId }) => {
    const { importDefaultHoursPopUp, defaultHours } = useSelector(
      (state: RootState) => state.newUserDefaultHours
    );
    const { isFocusWeb } = getUsersWhiteCache();

    const { pointOfSales } = useSelector(
      (state: RootState) => state.newUsersRoot
    );
    const [filiale, setFiliale] = useState<string | null>(null);
    const [load, setload] = useState<boolean>(false);
    const dispatch: AppDispatch = useDispatch();

    const getValue = useCallback(() => {
      if (filiale && pointOfSales && filiale in pointOfSales) {
        return pointOfSales[filiale];
      }
      return null;
    }, [filiale, pointOfSales]);

    const getOptions = useCallback(() => {
      // return Object.values(pointOfSales).filter(
      //   (value: any) => value.id !== pointOfSaleId
      // );

      return Object.values(pointOfSales);
    }, [pointOfSales]);

    const handleSave = useCallback(async () => {
      if (filiale !== null) {
        setload(true);
        const result = await getImportTurniShiftAndCalendarPointOfSale(
          filiale,
          isSingleCalendarManagement
        );
        setload(false);
        const temp = { ...defaultHours };
        if (temp && pointOfSaleId in temp) {
          temp[pointOfSaleId] = result;
          dispatch(setDefaultHours(temp));
          dispatch(setImportDefaultHoursPopUp(false));
        }

        return;
      }
      BluAsyncDialog({
        title: 'Attenzione',
        type: 'error',
        message: 'Date inserite non valide',
      });
    }, [
      defaultHours,
      dispatch,
      filiale,
      isSingleCalendarManagement,
      pointOfSaleId,
    ]);

    const handleClose = useCallback(() => {
      dispatch(setImportDefaultHoursPopUp(false));
    }, [dispatch]);

    return (
      <Dialog
        fullScreen={false}
        open={importDefaultHoursPopUp}
        //   onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Seleziona una filiale da cui vuoi importare gli orari
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <BluLoader open={load} sx={{ zIndex: '1400' }} />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Divider sx={{ opacity: 0, mb: '24px!important' }} />
              <Stack direction="row" spacing={0.5}>
                <BluAutoComplete
                  size="small"
                  disableClearable
                  renderOption={(props: any, option: any) => {
                    return (
                      <Box component="li" {...props} key={nanoid()}>
                        {option.branch}
                      </Box>
                    );
                  }}
                  getOptionLabel={(option: any) => {
                    return option.branch;
                  }}
                  data={getOptions()}
                  value={getValue()}
                  onChange={(event: any, newValue: any) => {
                    if (isFocusWeb) setFiliale(newValue?.blubookingId);
                    else setFiliale(newValue?.id);
                  }}
                  isOptionEqualToValue={(option: any, value: any) => {
                    return option.id === value.id;
                  }}
                />
              </Stack>
            </LocalizationProvider>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant={'outlined'} onClick={handleClose}>
            Annulla
          </Button>
          <Button onClick={handleSave}>Conferma</Button>
        </DialogActions>
      </Dialog>
    );
  }
);
