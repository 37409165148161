/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Stack, Typography } from '@mui/material';
import { memo, useCallback, useDeferredValue, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { BluAutoComplete } from '@bludata/components';
import { useServicesFilter } from 'packages/blu-booking/src/data_fetching/Service/ServicesFilter';
import { RootState } from 'packages/blu-booking/src/store';
import { ServiceIconConverter } from 'packages/blu-booking/src/utility_objects/ServiceIconConverter';

export const Service = memo(() => {
  const { getValues, setValue, reset, trigger } = useFormContext();

  const service = useWatch({ name: 'serviceId' });
  const pointOfSale = useWatch({ name: 'pointOfSaleId' });
  const user = useWatch({ name: 'userId' });
  const shopSignId = useWatch({ name: 'shopSignId' });

  const { data: result, isLoading } = useServicesFilter({
    lockPointOfSale: true,
    lockUser: true,
    userId: user,
    pointOfSaleId: pointOfSale,
    shopSignId,
  });

  const data = useDeferredValue(result);

  useEffect(() => {
    if (data === undefined) return;
    if (data.length === 0) return;

    const find = data.find((posl) => posl.id === service);
    if (find === undefined) {
      setValue('serviceId', '');
    }
  }, [data, getValues, reset, service, setValue, user]);

  const getData = useCallback(() => {
    return data ?? [];
  }, [data]);

  const handleOnChange = useCallback(
    (_: any, value: any) => {
      if (value === null) {
        setValue('serviceId', '');

        trigger();
        return;
      }
      setValue('serviceId', value.id);
      trigger();

      return;
    },
    [setValue, trigger]
  );

  return (
    <ServiceInner
      watch={service}
      data={getData()}
      isLoading={isLoading}
      handleOnChange={handleOnChange}
    />
  );
});

interface PointOfSaleInnerProps {
  watch: any;
  isLoading: boolean;
  data: any;
  handleOnChange: (event: any, value: any) => void;
}

const ServiceInner = memo(
  ({ watch, isLoading, data, handleOnChange }: PointOfSaleInnerProps) => {
    const { services } = useSelector(
      (state: RootState) => state.bluBookingCalendarFilters
    );

    const getValue = useCallback(() => {
      return services && watch in services ? services[watch] : null;
    }, [services, watch]);

    const getIcons = useCallback(() => {
      return services && watch in services
        ? services[watch]?.icons ?? []
        : undefined;
    }, [services, watch]);

    return (
      <div style={{ width: '49%' }}>
        <Typography variant="subtitle2">Servizio</Typography>
        <BluAutoComplete
          value={getValue()}
          data={data}
          width="49%"
          textField={{
            placeholder: 'Seleziona un servizio',
            InputProps: {
              startAdornment: getIcons() ? (
                <Stack direction="row">
                  {(getIcons() ?? []).map((v) => (
                    <ServiceIconConverter name={v} sx={{ fontSize: '18px' }} />
                  ))}
                </Stack>
              ) : undefined,
            },
          }}
          onChange={(event: any, value: any) => handleOnChange(event, value)}
          loading={isLoading}
          renderOption={(props: any, option: any) => (
            <Box component="li" {...props}>
              <Stack flex="1" direction="row" gap={1}>
                <Stack flex="1">{option.name}</Stack>
                {(option?.icons ?? []).length > 0 && (
                  <Stack direction="row">
                    {option.icons.map((v: string) => (
                      <ServiceIconConverter
                        name={v}
                        sx={{ fontSize: '18px', pt: '2px' }}
                      />
                    ))}
                  </Stack>
                )}
              </Stack>
            </Box>
          )}
          // disableCloseOnSelect
          isOptionEqualToValue={(option: any, value: any) =>
            option.id === value.id
          }
          getOptionLabel={(option: any) => `${option.name}`}
          renderTags={(value: any, getTagProps: any) => {
            return (
              <Stack flex="1" direction="row" gap={1}>
                {(value?.icons ?? []).map((i: string) => (
                  <ServiceIconConverter name={i} />
                ))}
                {`${value.name}`}
              </Stack>
            );
          }}
        />
      </div>
    );
  }
);
