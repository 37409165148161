import { BluDialog, BluLoader, BluAsyncDialog } from '@bludata/components';
import { Stack, Button } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useAppointmentsPaginated } from 'packages/blu-booking/src/data_fetching/Appointment/AppointmentsPaginated';
import { AppointmentCompleteInterface } from 'packages/blu-booking/src/interfaces/AppointmentComplete';
import { FC, memo, useCallback, useState } from 'react';
import { setConfig } from '../../utility_objects/WhiteCache';
import { PointOfSaleCheck } from './components/PointOfSale';
import { AppointmentsWithErrorsGrid } from './components/AppointmentsWithErrorsGrid';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'packages/blu-booking/src/store';
import { setAppointmentsWithErrorsPopupOpen } from '../../store/Calendar';
import { historyMessageResend } from 'packages/blu-booking/src/data_fetching/HistoryMessage/HistoryMessageResend';
import { setRefetch } from '../../store/Appointment';

interface AppointmentsWithErrorsPopupInnerInterface {
  handlePosition: (appointment: AppointmentCompleteInterface) => void;
}

export const AppointmentsWithErrorsPopupInner: FC<AppointmentsWithErrorsPopupInnerInterface> =
  memo(({ handlePosition }) => {
    const dispatch = useDispatch<AppDispatch>();

    const queryClient = useQueryClient();

    const [filters, setFilters] = useState<{
      pointOfSaleId?: string;
      pageNumber: number;
    }>({
      pageNumber: 1,
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedAppointment, setSelectedAppointment] = useState<
      AppointmentCompleteInterface | undefined
    >();

    const { data, isLoading } = useAppointmentsPaginated({
      ...filters,
      pointOfSaleIds: filters.pointOfSaleId,
      userIds: [],
      serviceIds: [],
      studioIds: [],
      hasErrorCode: true,
      startDate: dayjs().format('YYYY-MM-DD'),
      pageSize: 10,
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleChangeFilters = useCallback((key: string, value: any) => {
      setFilters((prev) => ({ ...prev, [key]: value }));
    }, []);

    const handlePositioning = useCallback(() => {
      if (selectedAppointment !== undefined) {
        setConfig('underline', selectedAppointment?.id ?? '');
        handlePosition(selectedAppointment);
        dispatch(setAppointmentsWithErrorsPopupOpen(false));
      }
    }, [handlePosition, selectedAppointment, dispatch]);

    const handleResend = useCallback(
      async (id: string) => {
        try {
          setLoading(true);
          const result = await historyMessageResend(id);
          setLoading(false);
          if (result) {
            handleChangeFilters('pageNumber', 1);
            queryClient.invalidateQueries(['appointments-paginated'], {
              type: 'active',
            });
            queryClient.removeQueries(['appointments-paginated'], {
              type: 'inactive',
            });

            queryClient.invalidateQueries(['appointment-notification'], {
              type: 'active',
            });
            queryClient.removeQueries(['appointment-notification'], {
              type: 'inactive',
            });

            dispatch(setRefetch());

            return;
          }
          BluAsyncDialog({
            title: 'Attenzione',
            type: 'error',
            message: `<pre style='font-family: "Roboto"; white-space: pre-wrap; width: inherit;'>${"Si è verificato un errore durante la conferma dell'appuntamento."}</pre>`,
            hideDeclineButton: true,
            confimButton: 'Ok',

            // sx: { '& .MuiDialog-paper': { maxWidth: '500px' } },
          });
        } catch (error) {
          console.trace(error);
        }
      },
      [dispatch, handleChangeFilters, queryClient]
    );

    return (
      <BluDialog
        open={true}
        zIndex={300}
        disableEnforceFocus
        disableAutoFocus
        disableRestoreFocus
        fullScreen={false}
        draggable
        maxWidth="lg"
        dialogTitle={'Appuntamenti con errori'}
        dialogContent={
          <Stack gap={1}>
            <BluLoader open={isLoading || loading} />
            <Stack direction="row" gap={1}>
              <PointOfSaleCheck
                pointOfSaleId={filters.pointOfSaleId}
                handleFilterChange={handleChangeFilters}
              />
            </Stack>
            <AppointmentsWithErrorsGrid
              appointments={data?.items ?? []}
              pageNumber={filters?.pageNumber}
              totalPages={data?.totalPages ?? 0}
              handlePageNumberChange={(value: number) => {
                handleChangeFilters('pageNumber', value);
              }}
              setSelectedAppointment={setSelectedAppointment}
              handleResend={handleResend}
            />
          </Stack>
        }
        sx={{
          '& .MuiDialogContent-root': {
            pb: '0px !important',
          },
          '& .MuiDialog-paper': { maxWidth: '1300px !important' },
        }}
        dialogActions={
          <Stack flex="1" direction="row" gap={1}>
            <Stack flex="1" direction="row" gap={1}></Stack>
            <Button
              variant="outlined"
              sx={{ width: 'fit-content' }}
              onClick={() =>
                dispatch(setAppointmentsWithErrorsPopupOpen(false))
              }
            >
              Chiudi
            </Button>
            <Button
              variant="contained"
              sx={{ width: 'fit-content' }}
              disabled={!selectedAppointment}
              onClick={handlePositioning}
            >
              Visualizza nell'agenda
            </Button>
          </Stack>
        }
      />
    );
  });
