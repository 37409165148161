export const defaultColors = [
  '#77dd77',
  '#b19cd9',
  '#f49ac2',
  '#c5d0e6',
  '#f7cac9',
  '#d4af37',
  '#f5b7b1',
  '#c8a2c8',
  '#f8c3cd',
  '#e6e6fa',
  '#f0e68c',
  '#dda0dd',
  '#f5d0c5',
  '#e0ffff',
  '#ffb6c1',
  '#d8bfd8',
  '#faebd7',
  '#ffdead',
  '#e6e6fa',
  '#ffb347',
  '#f0ffff',
  '#ff69b4',
  '#e6e6fa',
  '#ff7f50',
  '#f0fff0',
  '#ff00ff',
  '#fafad2',
  '#ff69b4',
  '#fffacd',
  '#dda0dd',
  '#ffe4c4',
  '#dda0dd',
  '#fffafa',
  '#f08080',
  '#fff5ee',
  '#dda0dd',
  '#fffafa',
  '#ffa07a',
  '#fffacd',
  '#dda0dd',
  '#ffffe0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#77dd77',
  '#ffd1dc',
  '#b19cd9',
  '#f49ac2',
  '#c5d0e6',
  '#f7cac9',
  '#d4af37',
  '#f5b7b1',
  '#c8a2c8',
  '#f8c3cd',
  '#e6e6fa',
  '#f0e68c',
  '#dda0dd',
  '#f5d0c5',
  '#e0ffff',
  '#ffb6c1',
  '#d8bfd8',
  '#faebd7',
  '#ffdead',
  '#e6e6fa',
  '#ffb347',
  '#f0ffff',
  '#ff69b4',
  '#e6e6fa',
  '#ff7f50',
  '#f0fff0',
  '#ff00ff',
  '#fafad2',
  '#ff69b4',
  '#fffacd',
  '#dda0dd',
  '#77dd77',
  '#b19cd9',
  '#f49ac2',
  '#c5d0e6',
  '#f7cac9',
  '#d4af37',
  '#f5b7b1',
  '#c8a2c8',
  '#f8c3cd',
  '#e6e6fa',
  '#f0e68c',
  '#dda0dd',
  '#f5d0c5',
  '#e0ffff',
  '#ffb6c1',
  '#d8bfd8',
  '#faebd7',
  '#ffdead',
  '#e6e6fa',
  '#ffb347',
  '#f0ffff',
  '#ff69b4',
  '#e6e6fa',
  '#ff7f50',
  '#f0fff0',
  '#ff00ff',
  '#fafad2',
  '#ff69b4',
  '#fffacd',
  '#dda0dd',
  '#ffe4c4',
  '#dda0dd',
  '#fffafa',
  '#f08080',
  '#fff5ee',
  '#dda0dd',
  '#fffafa',
  '#ffa07a',
  '#fffacd',
  '#dda0dd',
  '#ffffe0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#77dd77',
  '#ffd1dc',
  '#b19cd9',
  '#f49ac2',
  '#c5d0e6',
  '#f7cac9',
  '#d4af37',
  '#f5b7b1',
  '#c8a2c8',
  '#f8c3cd',
  '#e6e6fa',
  '#f0e68c',
  '#dda0dd',
  '#f5d0c5',
  '#e0ffff',
  '#ffb6c1',
  '#d8bfd8',
  '#faebd7',
  '#ffdead',
  '#e6e6fa',
  '#ffb347',
  '#f0ffff',
  '#ff69b4',
  '#e6e6fa',
  '#ff7f50',
  '#f0fff0',
  '#ff00ff',
  '#fafad2',
  '#ff69b4',
  '#fffacd',
  '#dda0dd',
  '#77dd77',
  '#b19cd9',
  '#f49ac2',
  '#c5d0e6',
  '#f7cac9',
  '#d4af37',
  '#f5b7b1',
  '#c8a2c8',
  '#f8c3cd',
  '#e6e6fa',
  '#f0e68c',
  '#dda0dd',
  '#f5d0c5',
  '#e0ffff',
  '#ffb6c1',
  '#d8bfd8',
  '#faebd7',
  '#ffdead',
  '#e6e6fa',
  '#ffb347',
  '#f0ffff',
  '#ff69b4',
  '#e6e6fa',
  '#ff7f50',
  '#f0fff0',
  '#ff00ff',
  '#fafad2',
  '#ff69b4',
  '#fffacd',
  '#dda0dd',
  '#ffe4c4',
  '#dda0dd',
  '#fffafa',
  '#f08080',
  '#fff5ee',
  '#dda0dd',
  '#fffafa',
  '#ffa07a',
  '#fffacd',
  '#dda0dd',
  '#ffffe0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#77dd77',
  '#ffd1dc',
  '#b19cd9',
  '#f49ac2',
  '#c5d0e6',
  '#f7cac9',
  '#d4af37',
  '#f5b7b1',
  '#c8a2c8',
  '#f8c3cd',
  '#e6e6fa',
  '#f0e68c',
  '#dda0dd',
  '#f5d0c5',
  '#e0ffff',
  '#ffb6c1',
  '#d8bfd8',
  '#faebd7',
  '#ffdead',
  '#e6e6fa',
  '#ffb347',
  '#f0ffff',
  '#ff69b4',
  '#e6e6fa',
  '#ff7f50',
  '#f0fff0',
  '#ff00ff',
  '#fafad2',
  '#ff69b4',
  '#fffacd',
  '#dda0dd',
  '#77dd77',
  '#b19cd9',
  '#f49ac2',
  '#c5d0e6',
  '#f7cac9',
  '#d4af37',
  '#f5b7b1',
  '#c8a2c8',
  '#f8c3cd',
  '#e6e6fa',
  '#f0e68c',
  '#dda0dd',
  '#f5d0c5',
  '#e0ffff',
  '#ffb6c1',
  '#d8bfd8',
  '#faebd7',
  '#ffdead',
  '#e6e6fa',
  '#ffb347',
  '#f0ffff',
  '#ff69b4',
  '#e6e6fa',
  '#ff7f50',
  '#f0fff0',
  '#ff00ff',
  '#fafad2',
  '#ff69b4',
  '#fffacd',
  '#dda0dd',
  '#ffe4c4',
  '#dda0dd',
  '#fffafa',
  '#f08080',
  '#fff5ee',
  '#dda0dd',
  '#fffafa',
  '#ffa07a',
  '#fffacd',
  '#dda0dd',
  '#ffffe0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#77dd77',
  '#ffd1dc',
  '#b19cd9',
  '#f49ac2',
  '#c5d0e6',
  '#f7cac9',
  '#d4af37',
  '#f5b7b1',
  '#c8a2c8',
  '#f8c3cd',
  '#e6e6fa',
  '#f0e68c',
  '#dda0dd',
  '#f5d0c5',
  '#e0ffff',
  '#ffb6c1',
  '#d8bfd8',
  '#faebd7',
  '#ffdead',
  '#e6e6fa',
  '#ffb347',
  '#f0ffff',
  '#ff69b4',
  '#e6e6fa',
  '#ff7f50',
  '#f0fff0',
  '#ff00ff',
  '#fafad2',
  '#ff69b4',
  '#fffacd',
  '#dda0dd',
  '#77dd77',
  '#b19cd9',
  '#f49ac2',
  '#c5d0e6',
  '#f7cac9',
  '#d4af37',
  '#f5b7b1',
  '#c8a2c8',
  '#f8c3cd',
  '#e6e6fa',
  '#f0e68c',
  '#dda0dd',
  '#f5d0c5',
  '#e0ffff',
  '#ffb6c1',
  '#d8bfd8',
  '#faebd7',
  '#ffdead',
  '#e6e6fa',
  '#ffb347',
  '#f0ffff',
  '#ff69b4',
  '#e6e6fa',
  '#ff7f50',
  '#f0fff0',
  '#ff00ff',
  '#fafad2',
  '#ff69b4',
  '#fffacd',
  '#dda0dd',
  '#ffe4c4',
  '#dda0dd',
  '#fffafa',
  '#f08080',
  '#fff5ee',
  '#dda0dd',
  '#fffafa',
  '#ffa07a',
  '#fffacd',
  '#dda0dd',
  '#ffffe0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#77dd77',
  '#ffd1dc',
  '#b19cd9',
  '#f49ac2',
  '#c5d0e6',
  '#f7cac9',
  '#d4af37',
  '#f5b7b1',
  '#c8a2c8',
  '#f8c3cd',
  '#e6e6fa',
  '#f0e68c',
  '#dda0dd',
  '#f5d0c5',
  '#e0ffff',
  '#ffb6c1',
  '#d8bfd8',
  '#faebd7',
  '#ffdead',
  '#e6e6fa',
  '#ffb347',
  '#f0ffff',
  '#ff69b4',
  '#e6e6fa',
  '#ff7f50',
  '#f0fff0',
  '#ff00ff',
  '#fafad2',
  '#ff69b4',
  '#fffacd',
  '#dda0dd',
  '#77dd77',
  '#b19cd9',
  '#f49ac2',
  '#c5d0e6',
  '#f7cac9',
  '#d4af37',
  '#f5b7b1',
  '#c8a2c8',
  '#f8c3cd',
  '#e6e6fa',
  '#f0e68c',
  '#dda0dd',
  '#f5d0c5',
  '#e0ffff',
  '#ffb6c1',
  '#d8bfd8',
  '#faebd7',
  '#ffdead',
  '#e6e6fa',
  '#ffb347',
  '#f0ffff',
  '#ff69b4',
  '#e6e6fa',
  '#ff7f50',
  '#f0fff0',
  '#ff00ff',
  '#fafad2',
  '#ff69b4',
  '#fffacd',
  '#dda0dd',
  '#ffe4c4',
  '#dda0dd',
  '#fffafa',
  '#f08080',
  '#fff5ee',
  '#dda0dd',
  '#fffafa',
  '#ffa07a',
  '#fffacd',
  '#dda0dd',
  '#ffffe0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#dda0dd',
  '#fffff0',
  '#77dd77',
  '#ffd1dc',
  '#b19cd9',
  '#f49ac2',
  '#c5d0e6',
  '#f7cac9',
  '#d4af37',
  '#f5b7b1',
  '#c8a2c8',
  '#f8c3cd',
  '#e6e6fa',
  '#f0e68c',
  '#dda0dd',
  '#f5d0c5',
  '#e0ffff',
  '#ffb6c1',
  '#d8bfd8',
  '#faebd7',
  '#ffdead',
  '#e6e6fa',
  '#ffb347',
  '#f0ffff',
  '#ff69b4',
  '#e6e6fa',
  '#ff7f50',
  '#f0fff0',
  '#ff00ff',
  '#fafad2',
  '#ff69b4',
  '#fffacd',
  '#dda0dd',
];

export let mapColor: {
  [x: string]: string;
} = {};
export const restMapColor = () => {
  mapColor = {};
};

export let usersMapColor: {
  [x: string]: string;
} = {};
export const restUsersMapColor = () => {
  usersMapColor = {};
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function wc_hex_is_light(c: any): boolean {
  let color = c;
  let r, g, b;
  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {
    // If HEX --> store the red, green, blue values in separate variables
    color = color.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    );

    r = color[1];
    g = color[2];
    b = color[3];
  } else {
    // If RGB --> Convert it to HEX: http://gist.github.com/983661
    color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'));

    r = color >> 16;
    g = (color >> 8) & 255;
    b = color & 255;
  }

  // HSP equation from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) {
    return true;
  } else {
    return false;
  }
}

export const lightenHexColor = (hex?: string) => {
  if (hex === undefined) return;
  // Assicurarsi che il valore esadecimale sia in formato 6 caratteri
  const amount = 50;
  // let amount = 70;
  hex = hex.replace(/^#/, '');
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }

  // Convertire il valore esadecimale in RGB
  let r = parseInt(hex.slice(0, 2), 16);
  let g = parseInt(hex.slice(2, 4), 16);
  let b = parseInt(hex.slice(4, 6), 16);

  // Schiarire ogni canale colore
  r = Math.min(r + amount, 255);
  g = Math.min(g + amount, 255);
  b = Math.min(b + amount, 255);
  // Convertire nuovamente in esadecimale e restituire
  const lighterHex = ((1 << 24) + (r << 16) + (g << 8) + b)
    .toString(16)
    .slice(1)
    .toUpperCase();
  return `#${lighterHex}`;
};
