/* eslint-disable @typescript-eslint/no-explicit-any */
import './TabClientCalendar.css';

import { FC, memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Stack } from '@mui/material';
import dayjs from 'dayjs';
import {
  AppDispatch,
  RootState,
  useAppSelector,
} from 'packages/blu-booking/src/store';
import { getConfig } from '../../utility_objects/WhiteCache';
import { fetchAppointments } from 'packages/blu-booking/src/data_fetching/Appointment/Appointments';
import AddIcon from '@mui/icons-material/Add';
import { Header } from './UI/Header';
import { setOpenForm } from '../../store/AppointmentForm';
import { bluBookingLoginData } from 'packages/blu-booking/src/store/root';
import { Scheduler } from './UI/Scheduler';
import { SearchPopUp } from './UI/SearchPopup';

interface TabClientInnerCalendarinterface {
  client: any;
  selectedDate: Date;
  setSelectedDate: (value: Date) => void;
  view: 'Month' | 'Agenda';
  setView: (value: 'Month' | 'Agenda') => void;
}

export const TabClientInnerCalendar: FC<TabClientInnerCalendarinterface> = memo(
  function Calendar({
    client,
    selectedDate,
    setSelectedDate,
    view,
    setView,
  }): JSX.Element {
    const dispatch = useDispatch<AppDispatch>();
    const [searchPopUp, setSearchPopUp] = useState(false);

    const { refetch } = useSelector(
      (state: RootState) => state.bluBookingaAppointments
    );
    const {
      sessionProfilo: { agendaInserimento },
    } = getConfig();
    const { pointOfSaleId } = useAppSelector(bluBookingLoginData);

    const { filtersLoad } = useSelector(
      (state: RootState) => state.bluBookingCalendarFilters
    );

    useEffect(() => {
      if (filtersLoad)
        dispatch(
          fetchAppointments({
            selectedDate,
            checkedServices: [],
            checkedStudi: [],
            checkedUsers: [],
            checkedPointOfSales: undefined,
            view: 'Month',
            externalCustomerCode: client?.codicePersonale,
            isTabClient: true,
          })
        );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, filtersLoad, selectedDate, refetch]);

    const openEditor = useCallback(() => {
      let startTime = dayjs(selectedDate);
      if (startTime.isBefore(dayjs())) {
        startTime = dayjs();
      }
      dispatch(
        setOpenForm({
          StartTime: startTime,
          EndTime: startTime.add(30, 'minutes'),
          name: client?.nome,
          surname: client?.cognome,
          externalCustomerCode: client?.codicePersonale,
          telephone:
            client.telefoninoGsm || client.telefono || client.telefono2,
          email: client?.eMail ?? '',
          isIMO: client.appAttiva || false,
          pointOfSaleId: pointOfSaleId,
        })
      );
    }, [
      client.appAttiva,
      client?.codicePersonale,
      client?.cognome,
      client?.eMail,
      client?.nome,
      client.telefoninoGsm,
      client.telefono,
      client.telefono2,
      dispatch,
      pointOfSaleId,
      selectedDate,
    ]);

    return (
      <Stack flex={1} flexDirection="column" spacing={2} padding={1}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            overflow: 'hidden',
          }}
        >
          {searchPopUp && (
            <SearchPopUp
              client={client}
              searchPopUp={searchPopUp}
              setSearchPopUp={setSearchPopUp}
              setSelectedDate={setSelectedDate}
            />
          )}

          <Header
            view={view}
            setView={setView}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            setSearchPopUp={setSearchPopUp}
          />
          <Box
            sx={{
              backgroundColor: 'var(--base-foreground-color)',
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              overflow: 'hidden',
            }}
          >
            <div key="Default" style={{ height: '100%' }}>
              <Scheduler
                selectedDate={selectedDate}
                view={view}
                pointOfSaleId={pointOfSaleId}
                client={client}
                setSelectedDate={setSelectedDate}
              />
            </div>
          </Box>
          <Stack direction="row" gap={1} sx={{ width: '100%', mt: 1 }}>
            <Stack flex="1"></Stack>
            {(agendaInserimento ?? true) && (
              <Button
                startIcon={<AddIcon />}
                onClick={() => openEditor()}
                variant="contained"
              >
                Aggiungi
              </Button>
            )}
          </Stack>
        </Box>
      </Stack>
    );
  }
);
