/* eslint-disable @typescript-eslint/no-explicit-any */
import { usePointOfSalesCalendar } from 'packages/blu-booking/src/data_fetching/PointOfSale/PointOfSalesCalendar';
import { useServicesUI } from 'packages/blu-booking/src/data_fetching/Service/ServicesUI';
import { useShopSign } from 'packages/blu-booking/src/data_fetching/ShopSign/ShopSign';
import { useStudiCalendar } from 'packages/blu-booking/src/data_fetching/Studio/StudiCalendar';
import { useUsersCalendar } from 'packages/blu-booking/src/data_fetching/User/UserCalendar';
import { useUserMe } from 'packages/blu-booking/src/data_fetching/User/UserMe';
import { softwareEnum } from 'packages/blu-booking/src/interfaces/LoginData';
import { ShopSignObject } from 'packages/blu-booking/src/interfaces/ShopSign';
import { AppDispatch } from 'packages/blu-booking/src/store';
import { setLoginData } from 'packages/blu-booking/src/store/root';
import { FC } from 'react';
import { memo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getConfig, setConfig } from '../../utility_objects/WhiteCache';
import { DefaultCalendarSelectPointOfSale } from '../../pages/Default/store';
import { StudioCalendarSelectPointOfSale } from '../../pages/Studio/store';
import { UserCalendarSelectPointOfSale } from '../../pages/User/store';
import {
  PersonalCalendarSelectPointOfSale,
  PersonalCalendarSetUser,
} from '../../pages/Personal/store';
import {
  setFiltersLoad,
  setInitFilters,
  setRequestState,
} from '../../store/CalendarFilters';

interface InitialDataLoadingInterface {
  pointOfSaleId?: string;
  userId?: string;
  companyColorEnum?: number;
  software?: string | 'bb' | 'fw' | 'crm' | 'f10';
  isSingleCalendarManagement?: boolean;
  isAdmin?: boolean;
  isManualAppointmentBlock?: boolean;
  visibilityUserEnum?: number;
}

export const InitialDataLoading: FC<InitialDataLoadingInterface> = memo(
  ({
    pointOfSaleId,
    userId,
    software,
    isSingleCalendarManagement,
    isAdmin,
    companyColorEnum,
    isManualAppointmentBlock,
    visibilityUserEnum,
  }) => {
    const dispatch = useDispatch<AppDispatch>();

    const { isLoading: userMeLoading, data: userMe } = useUserMe();
    const { isLoading: serviceLoading, data: services } = useServicesUI({
      options: { cacheTime: 300000, refetchInterval: 300000 },
      key: 'calendar',
    });
    const { isLoading: posLoading, data: pointOfSales } =
      usePointOfSalesCalendar({});
    const { isLoading: studioLoading, data: studi } = useStudiCalendar({});
    const { isLoading: userLoading, data: users } = useUsersCalendar({});
    const { isLoading: shopSignLoading, data: shopSigns } = useShopSign({
      options: {
        cacheTime: 300000,
        staleTime: 300000,
        refetchInterval: 300000,
        select: (data: any): ShopSignObject => {
          const result: any = {};
          for (const shopsign of data) {
            result[shopsign?.id] = shopsign;
          }
          return result;
        },
      },
      key: 'Calendar',
    });

    useEffect(() => {
      if (
        !serviceLoading &&
        !userLoading &&
        !posLoading &&
        !studioLoading &&
        !shopSignLoading &&
        !userMeLoading &&
        pointOfSales !== undefined &&
        users !== undefined
      ) {
        try {
          dispatch(
            setLoginData({
              ...userMe,
              pointOfSaleId: pointOfSaleId ?? userMe?.pointOfSaleId ?? '',
              userId: userId ?? userMe?.userId ?? '',
              software:
                software !== undefined
                  ? software
                  : softwareEnum[(userMe?.software as any) ?? 0],
              isSingleCalendarManagement:
                isSingleCalendarManagement ??
                userMe?.isSingleCalendarManagement ??
                true,
              isAdmin: isAdmin ?? userMe?.isAdmin ?? false,
              companyColorEnum:
                companyColorEnum ?? userMe?.companyColorEnum ?? 0,
              isManualAppointmentBlock:
                isManualAppointmentBlock ??
                userMe?.isManualAppointmentBlock ??
                false,
              visibilityUserEnum:
                visibilityUserEnum ?? userMe?.visibilityUserEnum ?? 0,
            } as any)
          );
          const pointOfSale = pointOfSaleId ?? userMe?.pointOfSaleId;
          const user = userId ?? userMe?.userId;
          const { firstLoad } = getConfig();

          if (!firstLoad) {
            const pos: any = pointOfSales;
            if (pointOfSale && pointOfSale in pos) {
              // set Calendar Studio filter the first poin of sale
              dispatch(DefaultCalendarSelectPointOfSale(pos[pointOfSale]));
              dispatch(StudioCalendarSelectPointOfSale(pos[pointOfSale]));
              dispatch(UserCalendarSelectPointOfSale(pos[pointOfSale]));

              dispatch(PersonalCalendarSelectPointOfSale(pos[pointOfSale]));
            } else {
              const keys = Object.keys(pos);
              if (keys.length > 0) {
                dispatch(DefaultCalendarSelectPointOfSale(pos[keys[0]]));
                dispatch(StudioCalendarSelectPointOfSale(pos[keys[0]]));
                dispatch(UserCalendarSelectPointOfSale(pos[keys[0]]));
                dispatch(PersonalCalendarSelectPointOfSale(pos[keys[0]]));
              }
            }

            const u: any = users;

            if (u !== undefined)
              if (user && user.replace(' ', '') in u) {
                dispatch(PersonalCalendarSetUser([u[user]]));
              } else {
                const uKeys = Object.keys(u);
                if (uKeys.length > 0) {
                  dispatch(PersonalCalendarSetUser([u[uKeys[0]]]));
                }
              }
            setConfig('firstLoad', true);
          }

          dispatch(
            setInitFilters({
              services,
              pointOfSales,
              studi,
              users,
              shopSigns,
              filtersLoad: true,
              requestState: '',
            })
          );

          window.dispatchEvent(
            new CustomEvent('BluBookingDataLoaded', { detail: true })
          );
        } catch (e) {
          console.error(e);
          dispatch(setFiltersLoad(true));
          dispatch(setRequestState(''));
        }
      }
    }, [
      companyColorEnum,
      dispatch,
      isAdmin,
      isManualAppointmentBlock,
      isSingleCalendarManagement,
      pointOfSaleId,
      pointOfSales,
      posLoading,
      serviceLoading,
      services,
      shopSignLoading,
      shopSigns,
      software,
      studi,
      studioLoading,
      userId,
      userLoading,
      userMe,
      userMeLoading,
      users,
      visibilityUserEnum,
    ]);

    return null;
  }
);
