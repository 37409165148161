import { Checkbox, FormControlLabel } from '@mui/material';
import { memo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

export const IsImportant = memo(function IsImportant(): JSX.Element {
  const isImportant = useWatch({ name: 'isImportant' });
  const { setValue } = useFormContext();

  const handleChange = () => {
    setValue('isImportant', !isImportant);
  };
  return (
    <FormControlLabel
      label="Importante"
      sx={{ pb: '5px' }}
      control={
        <Checkbox checked={isImportant} onChange={handleChange} sx={{ p: 0 }} />
      }
    />
  );
});
