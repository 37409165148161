import '../../styles/title.css';

import { FC, memo } from 'react';
import { Typography } from '@mui/material';
import { BluTitleInterface } from './interface';

const BluTitle: FC<BluTitleInterface> = memo(
  ({ text, ...rest }) => {
    return (
      <Typography variant="subtitle1" className="blu-title" {...rest}>
        {text}
      </Typography>
    );
  },
  (prev, next) => prev.text === next.text
);

export default BluTitle;
