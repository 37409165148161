import { Box, SvgIcon, Tooltip, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import dayjs from 'dayjs';
import { memo, useMemo } from 'react';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import IMOIcon from '../../../../resources/imo.png';
// import BluBookingIcon from '../../../../resources/BluBooking64.png';
import BluBooking from '../../../../resources/BluBookingFaviconBlue.svg';
import StarIcon from '@mui/icons-material/Star';
import { errorsMail } from 'packages/blu-booking/src/utility_objects/ErrorMail';
import { ServiceIconConverter } from 'packages/blu-booking/src/utility_objects/ServiceIconConverter';
import { useSelector } from 'react-redux';
import { RootState } from 'packages/blu-booking/src/store';
import { getStatusIcon } from '../../utility_objects/StatusIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/pro-duotone-svg-icons';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CalendarEventTemplateAgenda = memo((props: any) => {
  const { services } = useSelector(
    (state: RootState) => state.bluBookingCalendarFilters
  );

  const service =
    services && props.serviceId in services
      ? services[props.serviceId]
      : undefined;

  const GetCreatedForm = useMemo(() => {
    // ? Creato tramite B2C
    if (props.appointmentCreatedFrom === 1)
      return (
        <Tooltip title="Appuntamento creato tramite il web" placement="top">
          <img
            src={BluBooking}
            style={{
              width: '20px',
              height: '20px',
              paddingTop: '2px',
              paddingLeft: '1px',
              paddingRight: '1px',
              paddingBottom: '1px',
            }}
            // viewBox="0 0 283.46 283.46"
            alt=""
          />
        </Tooltip>
      );

    // ? Creato tramite IMO
    if (props.appointmentCreatedFrom === 2)
      return (
        <Tooltip title="Appuntamento creato tramite l'app" placement="top">
          <img
            alt="logo IMO"
            src={IMOIcon}
            style={{
              width: '17px',
              height: '17px',
              paddingTop: '2px',
              paddingLeft: '1px',
              paddingBottom: '1px',
              borderRadius: '50%',
            }}
          />
        </Tooltip>
      );
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.appointmentCreatedFrom, props.color]);

  const ErrorMail = useMemo(() => {
    if (props?.emailErrorCode !== undefined && props?.emailErrorCode !== null)
      return (
        <Tooltip
          title={`${
            props?.emailErrorCode in errorsMail
              ? errorsMail[props?.emailErrorCode]
              : 'errore mail'
          }`}
          placement="right"
        >
          <ErrorTwoToneIcon
            color="error"
            sx={{ fontSize: '18px', pt: '2px', pb: '1px' }}
          />
        </Tooltip>
      );

    return null;
  }, [props?.emailErrorCode]);

  const StatusError = useMemo(() => {
    if (props?.status === 0)
      return (
        <Tooltip title="Appuntamento non confermato" placement="top">
          <ErrorTwoToneIcon
            sx={{
              fontSize: '18px',
              pt: '2px',
              pb: '1px',
              color: 'var(--color)',
            }}
          />
        </Tooltip>
      );

    return null;
  }, [props?.status]);

  return (
    <Stack
      flexDirection="row"
      sx={{
        height: 'inherit',
        opacity: props?.isReadOnly ? 0.5 : 1,
        minWidth: '200px',
      }}
    >
      <Stack
        flexDirection="column"
        sx={{ height: 'inherit', width: 'inherit' }}
      >
        <Box
          sx={{
            width: '5px',
            height: '100%',
            boxSizing: 'border-box',
          }}
        />
      </Stack>

      <Stack
        flex="1"
        className="template-wrap"
        style={{
          backgroundColor: 'inherit',
          borderRadius: '4px',
          position: 'relative',
          width: '100%',
          paddingTop: '1px',
        }}
      >
        <Stack
          flexDirection="row"
          sx={{ width: '100%', p: '0 3px', boxSizing: 'border-box' }}
        >
          <Stack flexDirection="column" flex={1} sx={{ width: 'inherit' }}>
            <div className="subject">
              <Stack flexDirection="row" gap={0.5} alignItems="center">
                <Typography
                  variant="subtitle2"
                  fontWeight="bolder"
                  style={{ flex: 1 }}
                >
                  {props.Subject}
                </Typography>
                {props.externalCustomerCode &&
                  props.externalCustomerCode !== '' && (
                    <FontAwesomeIcon
                      icon={faUsers}
                      style={{
                        color: 'var(--base-color)',
                        // fontSize: '24px',
                      }}
                    />
                  )}
              </Stack>
            </div>

            {service && (
              <div className="event-description">
                <Stack direction="row" gap={1}>
                  {(props?.serviceIcons ?? []).length > 0 && (
                    <Stack direction="row">
                      {(props?.serviceIcons ?? []).map((value: string) => (
                        <ServiceIconConverter
                          name={value}
                          sx={{ fontSize: '18px', color: 'var(--color)' }}
                        />
                      ))}
                    </Stack>
                  )}
                  <Typography
                    noWrap
                    sx={{ textOverflow: 'ellipsis' }}
                    variant="body2"
                  >{`${service?.name ?? ''}`}</Typography>
                </Stack>
              </div>
            )}

            {props?.username && (
              <div className="event-description">
                <Typography variant="body2">{`${
                  props?.username ?? ''
                }`}</Typography>
              </div>
            )}
            <div className="time">
              <Typography variant="body2">{`${dayjs(props.StartTime).format(
                'HH:mm'
              )} - ${dayjs(props.endTime).format('HH:mm')}`}</Typography>
            </div>

            <div className="footer"></div>
          </Stack>
          <div
            style={{
              position: 'absolute',
              bottom: 0,
              right: 0,

              width: 'fit-content',
              color: 'var(--color)',
            }}
          >
            <Stack flexDirection="row-reverse" gap={0.5}>
              {GetCreatedForm}
              {ErrorMail}
              {StatusError}
              <SvgIcon
                component={getStatusIcon(props.status)}
                sx={{
                  fontSize: '18px',
                  pt: '2px',
                  pb: '1px',
                }}
              />
            </Stack>
          </div>

          {props.isImportant && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                right: 0,

                width: 'fit-content',
                color: 'var(--color)',
              }}
            >
              <StarIcon sx={{ fontSize: '19px' }} />
            </div>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
});
