import dayjs from 'dayjs';
import { AppointmentCompleteInterface } from 'packages/blu-booking/src/interfaces/AppointmentComplete';
import { FC, memo } from 'react';
import {
  DataGridPremium,
  GridFooterContainer,
  GridRenderCellParams,
  GridValueFormatterParams,
} from '@mui/x-data-grid-premium';
import { Stack, Typography, IconButton, Button } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { BluAsyncDialog, BludataInfoButton } from '@bludata/components';
import { errorsMail } from 'packages/blu-booking/src/utility_objects/ErrorMail';

interface AppointmentsWithErrorsGridInterface {
  appointments: AppointmentCompleteInterface[];
  pageNumber: number;
  totalPages: number;
  handlePageNumberChange: (value: number) => void;
  setSelectedAppointment: (value: AppointmentCompleteInterface) => void;
  handleResend: (id: string) => void;
}

export const AppointmentsWithErrorsGrid: FC<AppointmentsWithErrorsGridInterface> =
  memo(
    ({
      appointments,
      pageNumber,
      totalPages,
      handlePageNumberChange,
      setSelectedAppointment,
      handleResend,
    }) => {
      const columns = [
        {
          field: 'id',
          hide: true,
        },

        {
          field: 'service',
          headerName: 'Tipo di appuntamento',
          flex: 1,
          minWidth: 120,
          editable: false,
          valueFormatter: (params: GridValueFormatterParams) =>
            params.value?.name,
        },
        {
          field: 'pointOfSale',
          headerName: 'Punto Vendita',
          flex: 1,
          minWidth: 100,
          editable: false,
          valueFormatter: (params: GridValueFormatterParams) =>
            params.value?.branch,
        },
        {
          field: 'username',
          headerName: 'Utente',
          width: 100,
          editable: false,
        },
        {
          field: 'studio',
          headerName: 'Studio',
          width: 100,
          editable: false,
          valueFormatter: (params: GridValueFormatterParams) =>
            params.value?.name,
        },
        {
          field: 'name',
          headerName: 'Nome',
          width: 100,
          editable: false,
        },
        {
          field: 'surname',
          headerName: 'Cognome',
          width: 100,
          editable: false,
        },
        {
          field: 'date',
          headerName: 'Data',
          width: 100,
          align: 'center',
          headerAlign: 'center',
          // valueFormatter: (params: GridValueFormatterParams) => {
          //   console.log(params.api.getRow);
          //   return dayjs(params?.value).format('DD/MM/YYYY');
          // },
          renderCell: (params: GridRenderCellParams) => {
            return dayjs(params?.row?.startTime).format('DD/MM/YYYY');
          },
        },
        {
          field: 'startTime',
          headerName: 'Ora inizio',
          width: 80,
          align: 'center',
          headerAlign: 'center',
          valueFormatter: (params: GridValueFormatterParams) =>
            dayjs(params?.value).format('HH:mm'),
        },
        {
          field: 'endTime',
          headerName: 'Ora fine',
          width: 80,
          align: 'center',
          headerAlign: 'center',
          valueFormatter: (params: GridValueFormatterParams) =>
            dayjs(params?.value).format('HH:mm'),
        },
        {
          field: 'info',
          headerName: '',
          width: 40,
          align: 'center',
          renderCell: (params: GridRenderCellParams) => {
            const onButtonInfoClick = () => {
              const { emailErrorCode } = params.row;
              BluAsyncDialog({
                title: 'Errore appuntamento',
                type: 'info',
                hideDeclineButton: true,
                confimButton: 'Chiudi',
                message:
                  emailErrorCode in errorsMail
                    ? errorsMail[emailErrorCode]
                    : 'errore mail',
              });
            };
            return <BludataInfoButton onClick={onButtonInfoClick} />;
          },
        },
        {
          field: 'resend',
          headerName: '',
          editable: false,
          renderCell: (params: GridRenderCellParams) => {
            // <RenderStatusSlotOk {...params} />;
            const handleOnChange = async (event: any) =>
              handleResend(params?.row?.id);
            return (
              <Button
                sx={{ p: '0 8px' }}
                variant="outlined"
                onClick={handleOnChange}
              >
                Reinvia
              </Button>
            );
          },
        },
      ];

      return (
        <DataGridPremium
          sx={{
            minHeight: 454,
            '& .status': {
              padding: '2px',
            },
          }}
          columns={columns as any}
          rows={appointments ?? []}
          onRowClick={(event: any) => {
            setSelectedAppointment(event?.row);
            //   action(event?.row);
          }}
          components={{
            Footer: () => {
              return (
                <GridFooterContainer>
                  <Stack
                    flex="1"
                    direction="row"
                    gap={1}
                    justifyContent="flex-end"
                    sx={{ pr: 1 }}
                  >
                    {totalPages > 1 && (
                      <Stack direction="row" gap={1} alignItems="center">
                        <Typography>{`Pagina ${pageNumber} di ${
                          totalPages ?? 1
                        }`}</Typography>
                        <IconButton
                          disabled={pageNumber === 1}
                          onClick={() => handlePageNumberChange(pageNumber - 1)}
                        >
                          <KeyboardArrowLeftIcon />
                        </IconButton>
                        <IconButton
                          disabled={pageNumber >= totalPages}
                          onClick={() => handlePageNumberChange(pageNumber + 1)}
                        >
                          <KeyboardArrowRightIcon />
                        </IconButton>
                      </Stack>
                    )}
                  </Stack>
                </GridFooterContainer>
              );
            },
          }}
        />
      );
    }
  );
