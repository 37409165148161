/* eslint-disable @typescript-eslint/no-explicit-any */
import { BluAutoComplete, BluTitle } from '@bludata/components';
import { Box, Typography } from '@mui/material';
import { PointOfSaleInterface } from 'packages/blu-booking/src/interfaces/PointOfSale';
import { RootState, useAppSelector } from 'packages/blu-booking/src/store';
import { bluBookingLoginData } from 'packages/blu-booking/src/store/root';
import { FC, memo, useCallback } from 'react';
import { Case, Default, Switch } from 'react-if';
import { useSelector } from 'react-redux';

interface PointOfSaleFilterInterface {
  selectedPointOfSales?: PointOfSaleInterface;
  addPointOfSale: (value: PointOfSaleInterface) => void;
}

export const PointOfSaleFilter: FC<PointOfSaleFilterInterface> = memo(
  function PointOfSaleFilter({
    selectedPointOfSales,
    addPointOfSale,
  }): JSX.Element {
    const { pointOfSaleId: pointOfSale } = useAppSelector(bluBookingLoginData);

    const { pointOfSales, requestState } = useSelector(
      (state: RootState) => state.bluBookingCalendarFilters
    );
    const handleChange = useCallback(
      (_: any, newValue: any) => {
        addPointOfSale(newValue);
      },
      [addPointOfSale]
    );

    const handleColorText = useCallback(() => {
      if (pointOfSale === undefined) return undefined;
      if (!(pointOfSale in pointOfSales)) return undefined;
      if (selectedPointOfSales?.id !== pointOfSale) return 'var(--alert-color)';
      return undefined;
    }, [pointOfSale, pointOfSales, selectedPointOfSales?.id]);

    return (
      <>
        <BluTitle sx={{ p: '5px 0px' }} text="Punti vendita" />
        <Switch>
          <Case condition={requestState === 'error'}>
            <Typography variant="body2">
              Errore durante il caricamento dei punti vendita
            </Typography>
          </Case>
          <Default>
            {selectedPointOfSales !== undefined ? (
              <BluAutoComplete
                size="small"
                autoComplete="off" // Disabilita il completamento automatico
                disableClearable
                renderOption={(props: any, option: any) => (
                  <Box component="li" {...props}>
                    {option.branch}
                  </Box>
                )}
                textField={{
                  sx: { '.MuiInput-input': { color: handleColorText() } },
                }}
                getOptionLabel={(option: any) => option.branch}
                data={Object.values(pointOfSales)}
                value={selectedPointOfSales}
                // defaultValue={CONF.pointOfSale}
                onChange={handleChange}
                loading={requestState === 'loading'}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                InputProps={{
                  autoComplete: 'new-password',
                }}
              />
            ) : (
              <BluAutoComplete
                size="small"
                disableClearable
                data={[]}
                value={null}
                onChange={handleChange}
                loading={requestState === 'loading'}
              />
            )}
          </Default>
        </Switch>
      </>
    );
  }
);
