/* eslint-disable @typescript-eslint/no-explicit-any */

import { FC, memo, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'packages/blu-booking/src/store';
import { CalendarsInterface } from '../../interfaces/Calendars';
import { ContextMenu } from '../../UI/ContextMenu/ContextMenu';
import BluLoader from 'packages/components/src/common/Loader';
import { EmailPopUp } from '../../UI/EmailPopup/EmailPopup';
import { Stack } from '@mui/material';
import { InitialDataLoading } from '../../UI/InitialConfiguration/InitialDataLoading';
import axios from 'axios';
import { CreateLinkPopUp } from '../../UI/CreateLinkPopup/CreateLinkPopup';
import { setConfig } from '../../utility_objects/WhiteCache';
import { AppointmentForm } from '../../UI/AppointmentForm/AppointmentForm';
import jwt_decode from 'jwt-decode';
import { TabClientInnerCalendar } from './TabClientCalendarInner';
import dayjs from 'dayjs';
import { setRefetch } from '../../store/Appointment';
import { CalendarLoader } from '../../UI/CalendarLoader/CalendarLoader';

interface TabClientCalendarInterface extends CalendarsInterface {
  client: any;
  focusCloud?: boolean;
}

export const TabClientCalendar: FC<TabClientCalendarInterface> = memo(
  function Calendar({
    token,
    url,
    user,
    pointOfSale,
    getClient,
    focus10 = false,
    focusWeb = false,
    isBluBookingActive,
    isManualAppointmentBlock,
    selezioneCliente,
    visibilityUserEnum,
    client,
    focusCloud = false,
  }) {
    const [selectedDate, setSelectedDate] = useState<Date>(
      dayjs().startOf('month').toDate()
    );

    const [view, setView] = useState<'Month' | 'Agenda'>(
      focusCloud ? 'Month' : 'Agenda'
    );

    const { openLoader } = useSelector(
      (state: RootState) => state.bluBookingRoot
    );
    const { loadingAppointments } = useSelector(
      (state: RootState) => state.bluBookingaAppointments
    );
    const { openForm } = useSelector(
      (state: RootState) => state.bluBookingAppointmentForm
    );

    if (url !== undefined) axios.defaults.baseURL = url;

    let bearerToken: string | undefined;
    if (token !== undefined) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      bearerToken = token;
    } else if (
      axios.defaults.headers.common['Authorization'] !== null &&
      axios.defaults.headers.common['Authorization'] !== undefined
    ) {
      bearerToken = (
        axios.defaults.headers.common['Authorization'] as string
      ).replace('Bearer ', '');
    }

    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
      dispatch(setRefetch());
    }, [client, dispatch]);

    let accessToken: any;
    try {
      if (bearerToken !== undefined) accessToken = jwt_decode(bearerToken);

      if (isBluBookingActive === undefined && accessToken !== undefined)
        setConfig(
          'isBluBookingActive',
          accessToken?.IsBluBookingActive === 'True'
        );
      else setConfig('isBluBookingActive', isBluBookingActive);
    } catch (error) {
      // empty
    }

    setConfig('getClient', getClient);
    setConfig('focus10', false);
    setConfig('focusWeb', true);
    setConfig('focusCloud', focusCloud);

    const sessionData = JSON.parse(
      sessionStorage.getItem('sessionData') ?? '{}'
    );
    setConfig('sessionProfilo', {
      agendaInserimento: sessionData?.agendaInserimento ?? true,
      agendaModifica: sessionData?.agendaModifica ?? true,
      agendaCancellazione: sessionData?.agendaCancellazione ?? true,
      turniInserimento: sessionData?.turniInserimento ?? true,
      turniModifica: sessionData?.turniModifica ?? true,
      turniCancellazione: sessionData?.turniCancellazione ?? true,
    });

    if (selezioneCliente !== undefined)
      setConfig('selezioneCliente', selezioneCliente);

    let visbilityEnum: number | undefined;
    if (visibilityUserEnum === undefined)
      visbilityEnum = sessionData?.visibilityUserEnum
        ? parseInt(sessionData?.visibilityUserEnum)
        : undefined;
    else visbilityEnum = visibilityUserEnum;

    const GetAppointmentFormPopup = useMemo(() => {
      if (openForm)
        return (
          <AppointmentForm
            calendar="tab-clienti"
            openForm={openForm}
            setSelectedDate={setSelectedDate}
          />
        );
      return null;
    }, [openForm]);

    return (
      <>
        <InitialDataLoading
          visibilityUserEnum={visbilityEnum}
          isManualAppointmentBlock={
            isManualAppointmentBlock ?? sessionData?.isManualAppointmentBlock
          }
          pointOfSaleId={pointOfSale}
          userId={user}
        />
        <ContextMenu isTabCliente />
        <CalendarLoader open={loadingAppointments || openLoader} />
        <EmailPopUp />
        {GetAppointmentFormPopup}
        <TabClientInnerCalendar
          client={client}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          view={view}
          setView={setView}
        />
      </>
    );
  }
);
