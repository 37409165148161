import axios from 'axios';
import { memo } from 'react';
import { ConfigurazioneFocus10Page } from './page';
import {
  getRequestInterceptorId,
  getResponseInterceptorId,
} from '../../lib/interceptors';

export const ConfigurazioneFocus10BluBooking = memo(() => {
  const { url, token } = JSON.parse(
    sessionStorage.getItem('sessionData') ?? '{}'
  );

  axios.defaults.baseURL = url ? url : import.meta.env.VITE_NX_PUBLIC_API_URL;

  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else if (import.meta.env.DEV) {
    axios.defaults.headers.common['Authorization'] =
      'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJDb21wYW55SWQiOiI0MzU1NTYyYS1jYTVmLTQyZTItNDhlZi0wOGRiNWI2MDQ5MjIiLCJVc2VySWQiOiIwNDk1Njc4Mi01M2IxLTQ4NTUtOGFhMS05OGQxZGQ2MTUxMTUiLCJJc0JsdUJvb2tpbmdBY3RpdmUiOiJUcnVlIiwiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS93cy8yMDA4LzA2L2lkZW50aXR5L2NsYWltcy9yb2xlIjpbIlVzZXIiLCJBZG1pbiJdLCJleHAiOjE3MTc2NjIzMTV9.9VYxCKX-2_GuklEc7QGHiLyYRP3B4-XpxyOTCSHZHpo';
  }
  const requestInterceptorId = getRequestInterceptorId();
  const responseInterceptorId = getResponseInterceptorId();
  if (requestInterceptorId)
    axios.interceptors.request.eject(requestInterceptorId);
  if (responseInterceptorId)
    axios.interceptors.response.eject(responseInterceptorId);

  return (
    <>
      <ConfigurazioneFocus10Page />
    </>
  );
});
