/* eslint-disable @typescript-eslint/no-explicit-any */
import dayjs from 'dayjs';
import { getConfig } from './WhiteCache';
import { setOpenForm } from '../store/AppointmentForm';
import { store } from 'packages/blu-booking/src/store';

let startClick: string | undefined = undefined;

export const AppointmentMouseDown = (event: any) => {
  startClick = event.target.id;
  return event;
};

export const AppointmentMouseUp = (
  event: any,
  calendarType: 'default' | 'user' | 'studio' | 'personal'
) => {
  const { sessionProfilo } = getConfig();

  if (!sessionProfilo.agendaModifica) return;
  let filters;
  switch (calendarType) {
    case 'user':
      filters = store.getState().bluBookingUserCalendar;
      break;
    case 'personal':
      filters = store.getState().bluBookingPersonalCalendar;
      break;
    case 'studio':
      filters = store.getState().bluBookingStudioCalendar;
      break;
    default:
      filters = store.getState().bluBookingDefaultCalendar;
      break;
  }
  let userId =
    (filters?.selectedUsers ?? []).length === 1
      ? filters?.selectedUsers[0].id
      : undefined;
  if (calendarType === 'user') userId = event.target.getAttribute('userid');

  let studioId =
    (filters?.selectedStudi ?? []).length === 1
      ? filters?.selectedStudi[0]
      : undefined;
  if (calendarType === 'studio')
    studioId = event.target.getAttribute('studioid');
  if (startClick !== undefined && startClick !== event.target.id) {
    store.dispatch(
      setOpenForm({
        studioId,
        serviceId:
          (filters?.selectedServices ?? []).length === 1
            ? filters?.selectedServices[0]
            : undefined,
        userId,
        pointOfSaleId:
          filters?.selectedPointOfSale !== undefined &&
          calendarType !== 'personal'
            ? filters.selectedPointOfSale?.id
            : undefined,
        StartTime: dayjs(startClick, 'DD/MM/YYYY HH:mm'),
        EndTime: dayjs(event.target.id, 'DD/MM/YYYY HH:mm'),
      })
    );
    startClick = undefined;
  }
  return event;
};
