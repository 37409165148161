import { ChangeEvent, FC, memo, useCallback, useEffect, useState } from 'react';
import { Box, Button, Checkbox, FormControlLabel, Stack } from '@mui/material';
import { BluContainerBlock } from '@bludata/components';
import { MessageHistorySearchPopUp } from './UI/SearchClient/SearchClient';
import { NotificationTypeFilter } from './UI/NotificationType';
import { MessageTypeFilter } from './UI/MessageTypeFilter';
import { MessageStatusFilter } from './UI/MessageStatusFilter/MessageStatusFiter';
import { HistoryMessageGrid } from './UI/HistoryMessageGrid';
import {
  MessageStatusEnum,
  NotificationsItemInterface,
} from '../../interfaces/Notifications';
import { useDispatch, useSelector } from 'react-redux';
import { setFromToday, setMessageStatusFilter } from './store/Notifications';
import { RootState } from '../../store';
import { setConfig } from '../Calendars/utility_objects/WhiteCache';
import dayjs from 'dayjs';
import {
  DefaultCalendarSelectPointOfSale,
  DefaultCalendarResetSelected,
  DefaultCalendarSetSelectedDate,
} from '../Calendars/pages/Default/store';
import { setRefetch } from '../Calendars/store/Appointment';

interface NotificationsInnerInterface {
  focusWeb?: boolean;
  focus10?: boolean;
}

export const NotificationsInner: FC<NotificationsInnerInterface> = memo(
  ({ focusWeb = false, focus10 = false }) => {
    const [selectedAppointment, setSelectedAppointment] = useState<
      NotificationsItemInterface | undefined
    >();
    const { fromToday } = useSelector(
      (state: RootState) => state.bluBookingNotifications
    );
    const dispatch = useDispatch();

    useEffect(() => {
      window.addEventListener('appointmentNotificationError', () => {
        dispatch(setMessageStatusFilter(MessageStatusEnum.sendingerror));
        dispatch(setFromToday(true));
      });

      return () => {
        window.removeEventListener('appointmentNotificationError', () => {
          dispatch(setMessageStatusFilter(MessageStatusEnum.sendingerror));
          dispatch(setFromToday(true));
        });
      };
    }, [dispatch]);

    const { selectedServices, selectedStudi, selectedDate, selectedUsers } =
      useSelector((state: RootState) => state.bluBookingDefaultCalendar);
    const { pointOfSales } = useSelector(
      (state: RootState) => state.bluBookingCalendarFilters
    );

    const handlePosition = useCallback(() => {
      if (selectedAppointment) {
        setConfig('underline', selectedAppointment.appointmentId);

        const d = dayjs(selectedDate).format('DD/MM/YYYY');
        const appointmentDate = dayjs(selectedAppointment?.startTime);

        if (
          selectedAppointment?.pointOfSale &&
          selectedAppointment?.pointOfSale?.id &&
          pointOfSales &&
          selectedAppointment?.pointOfSale?.id in pointOfSales
        )
          dispatch(
            DefaultCalendarSelectPointOfSale(
              pointOfSales[selectedAppointment?.pointOfSale?.id]
            )
          );
        if (selectedStudi.length > 0)
          dispatch(DefaultCalendarResetSelected('selectedStudi'));

        if (selectedServices.length > 0)
          dispatch(DefaultCalendarResetSelected('selectedServices'));

        if (selectedUsers.length > 0)
          dispatch(DefaultCalendarResetSelected('selectedUsers'));

        if (d !== appointmentDate.format('DD/MM/YYYY'))
          dispatch(DefaultCalendarSetSelectedDate(appointmentDate.toDate()));
        else dispatch(setRefetch());
        window.dispatchEvent(
          new CustomEvent('BluBookingNavigation', { detail: 'calendar' })
        );
      }
    }, [
      dispatch,
      pointOfSales,
      selectedAppointment,
      selectedDate,
      selectedServices.length,
      selectedStudi.length,
      selectedUsers.length,
    ]);

    return (
      <BluContainerBlock
        sx={{
          m: '4px',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          flex: '1',
        }}
      >
        <>
          <Stack
            flexDirection="unset"
            flexWrap="wrap"
            gap={1}
            sx={{ pt: 2, pb: 2 }}
          >
            <MessageHistorySearchPopUp focus10={focus10} focusWeb={focusWeb} />
            <NotificationTypeFilter />

            <MessageTypeFilter />
            <MessageStatusFilter />
            <FormControlLabel
              checked={fromToday}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                dispatch(setFromToday(e.target.checked));
              }}
              sx={{ pl: 1, marginTop: 'auto !important' }}
              control={<Checkbox />}
              label="Da oggi"
            />
          </Stack>

          <HistoryMessageGrid setSelectedAppointment={setSelectedAppointment} />
          <Stack direction="row" sx={{ mt: '8px' }}>
            <Box flex={1}></Box>
            <Stack direction="row" spacing={0.5}>
              <Button
                color="primary"
                disabled={selectedAppointment === undefined || focus10} // in focus 10 non posso cambiare pagina
                onClick={handlePosition}
              >
                Visualizza nell'agenda
              </Button>
            </Stack>
          </Stack>
        </>
      </BluContainerBlock>
    );
  }
);
