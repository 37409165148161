import { BluTextField } from '@bludata/components';
import { Typography } from '@mui/material';
import React from 'react';
import { memo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

export const Note = memo(() => {
  const note = useWatch({ name: 'description' });
  const { setValue, trigger } = useFormContext();

  return (
    <div style={{ width: '100%', paddingBottom: '8px' }}>
      <Typography variant="subtitle2" sx={{ p: '10px 0px 0 0px' }}>
        Titolo
      </Typography>
      <BluTextField
        value={note}
        name="Note"
        onChange={(
          event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
        ) => {
          setValue('description', event.target.value);
          trigger();
        }}
      />
    </div>
  );
});
