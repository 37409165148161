import { BluTextField } from '@bludata/components';
import { Stack, InputAdornment, IconButton } from '@mui/material';
import { GridCloseIcon } from '@mui/x-data-grid-premium';
import { FC, memo, useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

interface CustomTextFieldInterface {
  name: string;
  helperText?: string;
  label?: string;
  disabled?: boolean;
  width?: string;
  multiline?: number;
  limit?: number;
  [x: string]: any;
  pos?: (field: string, pos: number) => void;
}

export const CustomTextField: FC<CustomTextFieldInterface> = memo(
  ({
    name,
    helperText = '',
    label = '',
    disabled = false,
    width,
    multiline,
    pos,
    limit = 1000000000,
    ...rest
  }) => {
    const field = useWatch({ name });
    const { setValue, trigger } = useFormContext();

    const handleOnChange = useCallback(
      (e: any) => {
        if (e.target.value && e.target.value.length > limit) return;
        setValue(name, e.target.value);
        trigger(name);
      },
      [limit, name, setValue, trigger]
    );

    const handleErase = useCallback(() => {
      setValue(name, '');
      trigger(name);
    }, [name, setValue, trigger]);

    return (
      <Stack
        sx={{
          minWidth: width ? width : '150px',
          width: width,
          flex: width ? undefined : 1,
        }}
      >
        <BluTextField
          id={name}
          disabled={disabled}
          value={field ?? ''}
          label={label}
          helperText={helperText}
          multiline={!!multiline}
          rows={multiline ? multiline : 1}
          InputProps={{
            endAdornment: (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                {field && field !== '' && !disabled && (
                  <InputAdornment position="end">
                    <IconButton
                      color="primary"
                      size="small"
                      onClick={handleErase}
                    >
                      <GridCloseIcon />
                    </IconButton>
                  </InputAdornment>
                )}
              </>
            ),
          }}
          onChange={handleOnChange}
          {...rest}
        />
      </Stack>
    );
  }
);
